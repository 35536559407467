import request from "@/utils/request-future"

export const API = {
  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['V2BrandAnnualReportDefaultPagedArray']>}
   */
  v2BrandBrandAnnualReportIndex: (params) => {
    return request(`/v2/brand/brand/annual_reports/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['V2BrandAnnualReportDefault']>}
   */
  v2BrandBrandAnnualReportShow: (id) => {
    return request(`/v2/brand/brand/annual_reports/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['V2BrandAnnualReportDefault']>}
   */
  v2BrandBrandAnnualReportRenderImage: (id) => {
    return request(`/v2/brand/brand/annual_reports/${id}/render_image`, {
      method: "PUT",
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandBrandAnnualReportDownloadCount: (id) => {
    return request(`/v2/brand/brand/annual_reports/${id}/download_count`, {
      method: "PUT",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['BrandConnectionDefaultPagedArray']>}
   */
  v2BrandBrandConnectionIndex: (params) => {
    return request(`/v2/brand/brand/connections/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandBrandConnectionCreateRequest"]) => Promise<import('./data').default['BrandConnectionDefault']>}
   */
  v2BrandBrandConnectionCreate: (data) => {
    return request(`/v2/brand/brand/connections/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['BrandConnectionDefault']>}
   */
  v2BrandBrandConnectionShow: (id) => {
    return request(`/v2/brand/brand/connections/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2BrandBrandConnectionUpdateRequest"]) => Promise<import('./data').default['BrandConnectionDefault']>}
   */
  v2BrandBrandConnectionUpdate: (id, data) => {
    return request(`/v2/brand/brand/connections/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandBrandConnectionDestroy: (id) => {
    return request(`/v2/brand/brand/connections/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(connectionId: number) => Promise<import('./data').default['BrandConnectionDefault']>}
   */
  v2BrandBrandConnectionMoveUp: (connectionId) => {
    return request(`/v2/brand/brand/connections/${connectionId}/move_up`, {
      method: "PATCH",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, active?: boolean, query?: string, section?: string, source?: string, ids?: (any)[]}) => Promise<import('./data').default['BrandFitnessTestMetricOptionPagedArray']>}
   */
  v2BrandBrandFitnessTestMetricOptions: (params) => {
    return request(`/v2/brand/brand/fitness_test_metrics/options`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, active?: boolean, query?: string, section?: string, source?: string}) => Promise<import('./data').default['BrandFitnessTestMetricDefaultPagedArray']>}
   */
  v2BrandBrandFitnessTestMetricIndex: (params) => {
    return request(`/v2/brand/brand/fitness_test_metrics/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandBrandFitnessTestMetricCreateRequest"]) => Promise<import('./data').default['BrandFitnessTestMetricDefault']>}
   */
  v2BrandBrandFitnessTestMetricCreate: (data) => {
    return request(`/v2/brand/brand/fitness_test_metrics/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['BrandFitnessTestMetricDefault']>}
   */
  v2BrandBrandFitnessTestMetricShow: (id) => {
    return request(`/v2/brand/brand/fitness_test_metrics/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2BrandBrandFitnessTestMetricUpdateRequest"]) => Promise<import('./data').default['BrandFitnessTestMetricDefault']>}
   */
  v2BrandBrandFitnessTestMetricUpdate: (id, data) => {
    return request(`/v2/brand/brand/fitness_test_metrics/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandBrandFitnessTestMetricDestroy: (id) => {
    return request(`/v2/brand/brand/fitness_test_metrics/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(fitnessTestMetricId: number) => Promise<import('./data').default['BrandFitnessTestMetricDefault']>}
   */
  v2BrandBrandFitnessTestMetricMoveUp: (fitnessTestMetricId) => {
    return request(
      `/v2/brand/brand/fitness_test_metrics/${fitnessTestMetricId}/move_up`,
      { method: "PATCH", version: "v2" }
    )
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, active?: boolean}) => Promise<import('./data').default['BrandMpTabDefaultPagedArray']>}
   */
  v2BrandBrandMpTabIndex: (params) => {
    return request(`/v2/brand/brand/mp_tabs/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandBrandMpTabCreateRequest"]) => Promise<import('./data').default['BrandMpTabDefault']>}
   */
  v2BrandBrandMpTabCreate: (data) => {
    return request(`/v2/brand/brand/mp_tabs`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['BrandMpTabDefault']>}
   */
  v2BrandBrandMpTabShow: (id) => {
    return request(`/v2/brand/brand/mp_tabs/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2BrandBrandMpTabUpdateRequest"]) => Promise<import('./data').default['BrandMpTabDefault']>}
   */
  v2BrandBrandMpTabUpdate: (id, data) => {
    return request(`/v2/brand/brand/mp_tabs/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandBrandMpTabDestroy: (id) => {
    return request(`/v2/brand/brand/mp_tabs/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(mpTabId: number) => Promise<import('./data').default['BrandMpTabDefault']>}
   */
  v2BrandBrandMpTabMoveUp: (mpTabId) => {
    return request(`/v2/brand/brand/mp_tabs/${mpTabId}/move_up`, {
      method: "PATCH",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, cancellationType?: string, from?: string, to?: string, productType?: string, studioId?: number}) => Promise<import('./data').default['V2BrandCourseCourseCancellationPagedArray']>}
   */
  v2BrandCourseCourseCancellation: (params) => {
    return request(`/v2/brand/course/courses/cancellation`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string, studioId?: number, duration?: (any)[]}) => Promise<import('./data').default['V2BrandEliteMemberActivePagedArray']>}
   */
  v2BrandEliteMemberActive: (params) => {
    return request(`/v2/brand/elite/members/active`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {studioId?: number, tag?: string, query?: string, tags?: (any)[]}) => Promise<import('./data').default['V2BrandEliteMemberInactive'][]>}
   */
  v2BrandEliteMemberInactive: (params) => {
    return request(`/v2/brand/elite/members/inactive`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, studioId?: number, tag?: string, query?: string, tags?: (any)[]}) => Promise<import('./data').default['V2BrandEliteMemberChrunedPagedArray']>}
   */
  v2BrandEliteMemberChruned: (params) => {
    return request(`/v2/brand/elite/members/chruned`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string, tags?: (any)[]}) => Promise<import('./data').default['V2BrandEliteMemberAllPagedArray']>}
   */
  v2BrandEliteMemberAll: (params) => {
    return request(`/v2/brand/elite/members/all`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string}) => Promise<import('./data').default['V2BrandEliteMemberUnassignedPagedArray']>}
   */
  v2BrandEliteMemberUnassigned: (params) => {
    return request(`/v2/brand/elite/members/unassigned`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, active?: boolean}) => Promise<import('./data').default['FitnessTestDeviceDefaultPagedArray']>}
   */
  v2BrandFitnessTestDeviceIndex: (params) => {
    return request(`/v2/brand/fitness_test/devices/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandFitnessTestDeviceCreateRequest"]) => Promise<import('./data').default['FitnessTestDeviceDefault']>}
   */
  v2BrandFitnessTestDeviceCreate: (data) => {
    return request(`/v2/brand/fitness_test/devices/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['FitnessTestDeviceDefault']>}
   */
  v2BrandFitnessTestDeviceShow: (id) => {
    return request(`/v2/brand/fitness_test/devices/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2BrandFitnessTestDeviceUpdateRequest"]) => Promise<import('./data').default['FitnessTestDeviceDefault']>}
   */
  v2BrandFitnessTestDeviceUpdate: (id, data) => {
    return request(`/v2/brand/fitness_test/devices/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandFitnessTestDeviceSendBindingMsgRequest"]) => Promise<import('./data').default['any']>}
   */
  v2BrandFitnessTestDeviceSendBindingMsg: (data) => {
    return request(`/v2/brand/fitness_test/devices/send_binding_msg`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandFitnessTestDeviceUnbindClient: (id) => {
    return request(`/v2/brand/fitness_test/devices/${id}/unbind_client`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, memberId: number}) => Promise<import('./data').default['V2FitnessTestRecordDefaultPagedArray']>}
   */
  v2BrandFitnessTestRecordIndex: (params) => {
    return request(`/v2/brand/fitness_test/records/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandFitnessTestRecordCreateRequest"]) => Promise<import('./data').default['V2FitnessTestRecordDefault']>}
   */
  v2BrandFitnessTestRecordCreate: (data) => {
    return request(`/v2/brand/fitness_test/records/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['V2FitnessTestRecordDetail']>}
   */
  v2BrandFitnessTestRecordShow: (id) => {
    return request(`/v2/brand/fitness_test/records/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandFitnessTestRecordDestroy: (id) => {
    return request(`/v2/brand/fitness_test/records/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(memberId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['V2MemberCoachRecordDefaultPagedArray']>}
   */
  v2BrandMemberCoachRecordIndex: (memberId, params) => {
    return request(`/v2/brand/members/${memberId}/coach_records/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string, active?: boolean, purchased?: boolean, noAccountAvailable?: boolean, trained?: boolean, productType?: string, studioIds?: (any)[]}) => Promise<import('./data').default['V2BrandMemberMemberDefaultPagedArray']>}
   */
  v2BrandMemberMemberIndex: (params) => {
    return request(`/v2/brand/member/members/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, accountTypes?: (any)[]}) => Promise<import('./data').default['V2BrandMemberMemberWithAccountsPagedArray']>}
   */
  v2BrandMemberMemberAccountAvailable: (params) => {
    return request(`/v2/brand/member/members/account_available`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  v2BrandMemberMemberAccountAvailableSummary: () => {
    return request(`/v2/brand/member/members/account_available_summary`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, churnType?: string, query?: string, tag?: string}) => Promise<import('./data').default['V2BrandMemberMemberWithChurnInfoPagedArray']>}
   */
  v2BrandMemberMemberChurnAlert: (params) => {
    return request(`/v2/brand/member/members/churn_alert`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  v2BrandMemberMemberChurnAlertSummary: () => {
    return request(`/v2/brand/member/members/churn_alert_summary`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, minChurnDuration?: number, maxChurnDuration?: number, minChurnDays?: number, maxChurnDays?: number}) => Promise<import('./data').default['V2BrandMemberMemberChurnedPagedArray']>}
   */
  v2BrandMemberMemberChurned: (params) => {
    return request(`/v2/brand/member/members/churned`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['V2BrandMemberMemberDefaultPagedArray']>}
   */
  v2BrandMemberMemberUnassigned: (params) => {
    return request(`/v2/brand/member/members/unassigned`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string}) => Promise<import('./data').default['V2BrandMemberMemberDefaultPagedArray']>}
   */
  v2BrandMemberMemberNotActivate: (params) => {
    return request(`/v2/brand/member/members/not_activate`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string, productType?: string, duration?: (any)[]}) => Promise<import('./data').default['V2BrandMemberMemberDefaultPagedArray']>}
   */
  v2BrandMemberMemberNewMember: (params) => {
    return request(`/v2/brand/member/members/new_member`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string, productType?: string, studioId?: number, duration?: (any)[]}) => Promise<import('./data').default['V2BrandMemberBookingDefaultPagedArray']>}
   */
  v2BrandMemberMemberNewMemberBooking: (params) => {
    return request(`/v2/brand/member/members/new_member_booking`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string, productType?: string, studioId?: number}) => Promise<import('./data').default['V2BrandMemberMemberDefaultPagedArray']>}
   */
  v2BrandMemberMemberActive: (params) => {
    return request(`/v2/brand/member/members/active`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string, productType?: string, studioId?: number, accountAvailable?: boolean}) => Promise<import('./data').default['V2BrandMemberMemberDefaultPagedArray']>}
   */
  v2BrandMemberMemberInactive: (params) => {
    return request(`/v2/brand/member/members/inactive`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  v2BrandMemberMemberActiveSummary: () => {
    return request(`/v2/brand/member/members/active_summary`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  v2BrandMemberMemberInactiveSummary: () => {
    return request(`/v2/brand/member/members/inactive_summary`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, from?: string, to?: string, adminId?: number, actionType?: string}) => Promise<import('./data').default['MembershipAccountLogDefaultPagedArray']>}
   */
  v2BrandMembershipAccountLogIndex: (params) => {
    return request(`/v2/brand/membership/account_logs/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['BrandAdminNotificationDefaultPagedArray']>}
   */
  v2BrandNotificationIndex: (params) => {
    return request(`/v2/brand/notifications/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['BrandAdminNotificationDefault']>}
   */
  v2BrandNotificationShow: (id) => {
    return request(`/v2/brand/notifications/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['BrandAdminNotificationInbox']>}
   */
  v2BrandNotificationInbox: () => {
    return request(`/v2/brand/notifications/inbox`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(notificationId: number) => Promise<import('./data').default['any']>}
   */
  v2BrandNotificationRead: (notificationId) => {
    return request(`/v2/brand/notifications/${notificationId}/read`, {
      method: "PUT",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, type: string, query?: string, productType?: string, cardType?: string, cardIds?: (any)[]}, data?: definitions["V2BrandOperatingAccountQueryAccountsRequest"]) => Promise<import('./data').default['V2BrandMembershipAccountOperatingPagedArray']>}
   */
  v2BrandOperatingAccountQueryAccounts: (params, data) => {
    return request(`/v2/brand/operating/accounts`, {
      method: "GET",
      params,
      data,
      version: "v2",
    })
  },

  /**
   * @type {(params: {type: string, query?: string, productType?: string, cardType?: string, cardIds?: (any)[]}, data?: definitions["V2BrandOperatingAccountPatchActivateRequest"]) => Promise<import('./data').default['MembershipAccountDefault']>}
   */
  v2BrandOperatingAccountPatchActivate: (params, data) => {
    return request(`/v2/brand/operating/accounts/patch_activate`, {
      method: "PATCH",
      params,
      data,
      version: "v2",
    })
  },

  /**
   * @type {(params: {type: string, query?: string, productType?: string, cardType?: string, cardIds?: (any)[]}, data?: definitions["V2BrandOperatingAccountPatchExtendValidityPeriodRequest"]) => Promise<import('./data').default['MembershipAccountDefault']>}
   */
  v2BrandOperatingAccountPatchExtendValidityPeriod: (params, data) => {
    return request(
      `/v2/brand/operating/accounts/patch_extend_validity_period`,
      { method: "PATCH", params, data, version: "v2" }
    )
  },

  /**
   * @type {(params: {type: string, query?: string, productType?: string, cardType?: string, cardIds?: (any)[]}, data?: definitions["V2BrandOperatingAccountPatchPauseRequest"]) => Promise<import('./data').default['MembershipAccountDefault']>}
   */
  v2BrandOperatingAccountPatchPause: (params, data) => {
    return request(`/v2/brand/operating/accounts/patch_pause`, {
      method: "PATCH",
      params,
      data,
      version: "v2",
    })
  },

  /**
   * @type {(params: {type: string, query?: string, productType?: string, cardType?: string, cardIds?: (any)[]}, data?: definitions["V2BrandOperatingAccountPatchChangeCoachRequest"]) => Promise<import('./data').default['MembershipAccountDefault']>}
   */
  v2BrandOperatingAccountPatchChangeCoach: (params, data) => {
    return request(`/v2/brand/operating/accounts/patch_change_coach`, {
      method: "PATCH",
      params,
      data,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, recordType?: string, from?: string, to?: string, query?: string}) => Promise<import('./data').default['V2BrandOperatingPrepayPurchaseDefaultPagedArray']>}
   */
  v2BrandOperatingPrepayPurchaseIndex: (params) => {
    return request(`/v2/brand/operating/prepay_purchases`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {recordType?: string, from?: string, to?: string, query?: string}) => Promise<import('./data').default['any']>}
   */
  v2BrandOperatingPrepayPurchaseSummary: (params) => {
    return request(`/v2/brand/operating/prepay_purchases/summary`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {from: string, to: string, coachId?: number, studioId?: number, productType?: string, productId?: number}) => Promise<import('./data').default['any']>}
   */
  v2BrandOperatingReportSummary: (params) => {
    return request(`/v2/brand/operating/report/summary`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {from: string, to: string, productType: string, coachId?: number, studioId?: number, productId?: number}) => Promise<import('./data').default['any']>}
   */
  v2BrandOperatingReportTopTrainingMembers: (params) => {
    return request(`/v2/brand/operating/report/top_training_members`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {from: string, to: string, productType: string, coachId?: number, studioId?: number, productId?: number}) => Promise<import('./data').default['undefined']>}
   */
  v2BrandOperatingReportExportTopTrainingMembers: (params) => {
    return request(`/v2/brand/report/finances/export_top_training_members`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandOrderCreateRequest"]) => Promise<import('./data').default['OrderDefault']>}
   */
  v2BrandOrderCreate: (data) => {
    return request(`/v2/brand/orders/`, { method: "POST", data, version: "v2" })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, partnerId?: number, query?: string, productIds?: (any)[], from?: string, to?: string}) => Promise<import('./data').default['PartnerConsumptionRecordDefaultPagedArray']>}
   */
  v2BrandPartnerConsumptionRecordIndex: (params) => {
    return request(`/v2/brand/partner_consumption_records/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PartnerConsumptionRecordDefault']>}
   */
  v2BrandPartnerConsumptionRecordShow: (id) => {
    return request(`/v2/brand/partner_consumption_records/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2BrandPartnerConsumptionRecordUpdateRequest"]) => Promise<import('./data').default['PartnerConsumptionRecordDefault']>}
   */
  v2BrandPartnerConsumptionRecordUpdate: (id, data) => {
    return request(`/v2/brand/partner_consumption_records/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandPartnerConsumptionRecordDestroy: (id) => {
    return request(`/v2/brand/partner_consumption_records/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(params: {partnerId?: number, query?: string, productIds?: (any)[], from?: string, to?: string}) => Promise<import('./data').default['any']>}
   */
  v2BrandPartnerConsumptionRecordSummary: (params) => {
    return request(`/v2/brand/partner_consumption_records/summary`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {partnerId?: number, query?: string, productIds?: (any)[], from?: string, to?: string}) => Promise<import('./data').default['undefined']>}
   */
  v2BrandPartnerConsumptionRecordExport: (params) => {
    return request(`/v2/brand/partner_consumption_records/export`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandPartnerConsumptionRecordConsumeRequest"]) => Promise<import('./data').default['PartnerConsumptionRecordDefault']>}
   */
  v2BrandPartnerConsumptionRecordConsume: (data) => {
    return request(`/v2/brand/partner_consumption_records/consume`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, partnerId?: number, active?: boolean, ids?: (any)[]}) => Promise<import('./data').default['PartnerProductOptionPagedArray']>}
   */
  v2BrandPartnerProductOptions: (params) => {
    return request(`/v2/brand/partner_products/options`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, partnerId?: number, active?: boolean}) => Promise<import('./data').default['PartnerProductDefaultPagedArray']>}
   */
  v2BrandPartnerProductIndex: (params) => {
    return request(`/v2/brand/partner_products/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandPartnerProductCreateRequest"]) => Promise<import('./data').default['PartnerProductDefault']>}
   */
  v2BrandPartnerProductCreate: (data) => {
    return request(`/v2/brand/partner_products/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PartnerProductDefault']>}
   */
  v2BrandPartnerProductShow: (id) => {
    return request(`/v2/brand/partner_products/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2BrandPartnerProductUpdateRequest"]) => Promise<import('./data').default['PartnerProductDefault']>}
   */
  v2BrandPartnerProductUpdate: (id, data) => {
    return request(`/v2/brand/partner_products/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandPartnerProductDestroy: (id) => {
    return request(`/v2/brand/partner_products/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(params: {active?: boolean, ids?: (any)[]}) => Promise<import('./data').default['PartnerOption'][]>}
   */
  v2BrandPartnerOptions: (params) => {
    return request(`/v2/brand/partners/options`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, active?: boolean}) => Promise<import('./data').default['PartnerDefaultPagedArray']>}
   */
  v2BrandPartnerIndex: (params) => {
    return request(`/v2/brand/partners/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2BrandPartnerCreateRequest"]) => Promise<import('./data').default['PartnerDefault']>}
   */
  v2BrandPartnerCreate: (data) => {
    return request(`/v2/brand/partners/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PartnerDefault']>}
   */
  v2BrandPartnerShow: (id) => {
    return request(`/v2/brand/partners/${id}`, { method: "GET", version: "v2" })
  },

  /**
   * @type {(id: number, data?: definitions["V2BrandPartnerUpdateRequest"]) => Promise<import('./data').default['PartnerDefault']>}
   */
  v2BrandPartnerUpdate: (id, data) => {
    return request(`/v2/brand/partners/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandPartnerDestroy: (id) => {
    return request(`/v2/brand/partners/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(params: {coachId: number, studioId?: number, from?: string, to?: string, courseType?: string}) => Promise<import('./data').default['V2BrandFinanceCoachCourseDefault']>}
   */
  v2BrandReportFinanceCoachCoursesList: (params) => {
    return request(`/v2/brand/report/finances/coach_courses_list`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, accountType: string, query?: string, duration?: (any)[]}) => Promise<import('./data').default['V2BrandFinanceMembershipRecordDefaultPagedArray']>}
   */
  v2BrandReportFinanceExpirations: (params) => {
    return request(`/v2/brand/report/finances/expirations`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, duration?: (any)[]}) => Promise<import('./data').default['V2BrandCouponMemberCouponDefaultPagedArray']>}
   */
  v2BrandReportFinanceExpiredMemberCoupons: (params) => {
    return request(`/v2/brand/report/finances/expired_member_coupons`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {accountType: string}) => Promise<import('./data').default['V2BrandFinanceMembershipRecordSummary']>}
   */
  v2BrandReportFinanceExpirationsSummary: (params) => {
    return request(`/v2/brand/report/finances/expirations_summary`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['V2BrandCouponMemberCouponSummary']>}
   */
  v2BrandReportFinanceExpiredMemberCouponsSummary: () => {
    return request(`/v2/brand/report/finances/expired_member_coupons_summary`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(params: {accountType: string, query?: string, duration?: (any)[]}) => Promise<import('./data').default['undefined']>}
   */
  v2BrandReportFinanceExportExpirations: (params) => {
    return request(`/v2/brand/report/finances/export_expirations`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {query?: string, duration?: (any)[]}) => Promise<import('./data').default['undefined']>}
   */
  v2BrandReportFinanceExportExpiredMemberCoupons: (params) => {
    return request(`/v2/brand/report/finances/export_expired_member_coupons`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(recordId: number, data?: definitions["V2BrandReportFinanceCancelExpirationRequest"]) => Promise<import('./data').default['any']>}
   */
  v2BrandReportFinanceCancelExpiration: (recordId, data) => {
    return request(`/v2/brand/report/finances/cancel_expiration/${recordId}`, {
      method: "PATCH",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(params: {partnerId?: number, query?: string, productIds?: (any)[], from?: string, to?: string}) => Promise<import('./data').default['any']>}
   */
  v2BrandReportFinancePartners: (params) => {
    return request(`/v2/brand/report/finances/partners`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {partnerId?: number, query?: string, productIds?: (any)[], from?: string, to?: string}) => Promise<import('./data').default['any']>}
   */
  v2BrandReportFinancePartnersSummary: (params) => {
    return request(`/v2/brand/report/finances/partners_summary`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {partnerId?: number, query?: string, productIds?: (any)[], from?: string, to?: string}) => Promise<import('./data').default['undefined']>}
   */
  v2BrandReportFinanceExportPartners: (params) => {
    return request(`/v2/brand/report/finances/export_partners`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(studioId: number, params: {page?: number, pageSize?: number, ids?: (any)[]}) => Promise<import('./data').default['V2StudioCoachOptionPagedArray']>}
   */
  v2BrandStudioCoachOptions: (studioId, params) => {
    return request(`/v2/brand/studio/studios/${studioId}/coaches/options`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(studioId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['V2StudioCoachDefaultPagedArray']>}
   */
  v2BrandStudioCoachIndex: (studioId, params) => {
    return request(`/v2/brand/studio/studios/${studioId}/coaches/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(studioId: number, data?: definitions["V2BrandStudioCoachCreateRequest"]) => Promise<import('./data').default['V2StudioCoachDefault']>}
   */
  v2BrandStudioCoachCreate: (studioId, data) => {
    return request(`/v2/brand/studio/studios/${studioId}/coaches/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['V2StudioCoachDefault']>}
   */
  v2BrandStudioCoachShow: (id) => {
    return request(`/v2/brand/studio/coaches/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2BrandStudioCoachUpdateRequest"]) => Promise<import('./data').default['V2StudioCoachDefault']>}
   */
  v2BrandStudioCoachUpdate: (id, data) => {
    return request(`/v2/brand/studio/coaches/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandStudioCoachDestroy: (id) => {
    return request(`/v2/brand/studio/coaches/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(studioId: number, params: {page?: number, pageSize?: number, ids?: (any)[]}) => Promise<import('./data').default['V2StudioProductOptionPagedArray']>}
   */
  v2BrandStudioProductOptions: (studioId, params) => {
    return request(`/v2/brand/studio/studios/${studioId}/products/options`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(studioId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['V2StudioProductDefaultPagedArray']>}
   */
  v2BrandStudioProductIndex: (studioId, params) => {
    return request(`/v2/brand/studio/studios/${studioId}/products/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(studioId: number, data?: definitions["V2BrandStudioProductCreateRequest"]) => Promise<import('./data').default['V2StudioProductDefault']>}
   */
  v2BrandStudioProductCreate: (studioId, data) => {
    return request(`/v2/brand/studio/studios/${studioId}/products/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['V2StudioProductDefault']>}
   */
  v2BrandStudioProductShow: (id) => {
    return request(`/v2/brand/studio/products/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2BrandStudioProductUpdateRequest"]) => Promise<import('./data').default['V2StudioProductDefault']>}
   */
  v2BrandStudioProductUpdate: (id, data) => {
    return request(`/v2/brand/studio/products/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2BrandStudioProductDestroy: (id) => {
    return request(`/v2/brand/studio/products/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, studioId?: number, query?: string}) => Promise<import('./data').default['V2StudioAccessLogDefaultPagedArray']>}
   */
  v2BrandStudioAccessLogIndex: (params) => {
    return request(`/v2/brand/studio_access_logs/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },
}
