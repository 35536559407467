<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path" v-if="item.title">
        
        <span v-if="item.redirect==='noredirect'||index==levelList.length-1" class="no-redirect">{{item.title}}</span>
        <router-link v-else :to="breadcrumbPath(item)">{{item.title}}</router-link>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  created() {
    this.getBreadcrumb()
  },

  data() {
    return {
      levelList: null,
    }
  },

  watch: {
    $route() {
      this.getBreadcrumb()
    },
  },

  methods: {
    getBreadcrumb() {
      let { breadcrumb } = this.$route.meta
      if (!breadcrumb && this.$route.breadcrumb) {
        breadcrumb = this.$route.breadcrumb
      }
      if (!breadcrumb) {
        return
      }
      // TODO: fix work around
      this.levelList = [...breadcrumb].filter(bc => bc.path !== '/member')
      return
    },

    breadcrumbPath(item) {
      if (item.path) {
        let path = item.path
        let startPos = path.indexOf(':')

        if (startPos > -1) {
          let endPos = path.indexOf('/', startPos)
          if (endPos === -1) {
            endPos = path.length
          }
          let key = path.substring(startPos + 1, endPos)
          path = path.replace(':' + key, this.$route.params[key])
          return path
        }
      }

      return item.redirect || item.path
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
