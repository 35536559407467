var __defProp = Object.defineProperty;
var __markAsModule = (target) => __defProp(target, "__esModule", { value: true });
var __export = (target, all) => {
  __markAsModule(target);
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
__export(exports, {
  definitionRawData: () => definitionRawData,
  idMaps: () => idMaps,
  pathRawData: () => pathRawData
});
const definitionRawData = {
  date: {
    type: "string",
    format: "date",
    example: "2018-01-01",
    properties: {}
  },
  datetime: {
    type: "string",
    format: "date-time",
    example: "2018-08-01T00:00:00.000Z",
    properties: {}
  },
  Pagination: {
    type: "object",
    properties: {
      currentPage: { type: "number" },
      nextPage: { type: "number", nullable: true },
      prevPage: { type: "number", nullable: true },
      totalPages: { type: "number" },
      totalCount: { type: "number" }
    }
  },
  AuthFormPasswordForm: {
    type: "object",
    properties: {
      login: { type: "string", required: true, description: "用户名" },
      password: { type: "string", required: true, description: "密码" }
    }
  },
  AuthFormPhoneCodeForm: {
    type: "object",
    properties: {
      phone: { type: "string", required: true, description: "手机" },
      phoneCode: { type: "string", required: true, description: "短信验证码" }
    }
  },
  AuthFormWechatCodeForm: {
    type: "object",
    properties: {
      code: { type: "string", required: true, description: "微信 code" }
    }
  },
  AuthFormEditPasswordForm: {
    type: "object",
    properties: {
      password: { type: "string", required: true, description: "密码" },
      passwordConfirmation: {
        type: "string",
        required: true,
        description: "重复密码"
      }
    }
  },
  AuthTokenDefault: {
    type: "object",
    properties: { token: { type: "string", description: "凭证" } }
  },
  AttachmentDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      signedId: { type: "string", description: "Signed ID" },
      url: { type: "string", description: "URL" },
      originalUrl: { type: "string", description: "原始文件 URL" },
      filename: { type: "string", description: "文件名" },
      contentType: { type: "string", description: "文件类型" }
    }
  },
  AttachmentForm: {
    type: "object",
    properties: {
      signedId: { type: "string", description: "Signed ID" },
      remoteUrl: {
        type: "string",
        nullable: true,
        description: "文件远程 URL"
      },
      filename: { type: "string", description: "文件名" },
      contentType: { type: "string", description: "文件类型" }
    }
  },
  StudioStudioList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      fullName: { type: "string", description: "门店全称" },
      shortName: { type: "string", description: "门店名称" }
    }
  },
  StudioStudioEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      fullName: { type: "string", description: "门店全称" },
      shortName: { type: "string", description: "门店名称" },
      active: { type: "boolean", description: "激活" },
      eliteTraining: { type: "boolean", description: "可上私教" },
      display: { type: "boolean", description: "展示" },
      address: { type: "string", description: "地址" },
      contactPhone: { type: "string", description: "联系电话" },
      longitude: { type: "string", description: "经度" },
      latitude: { type: "string", description: "维度" },
      gatePassword: { type: "string", description: "门禁密码" }
    }
  },
  CoachCoachList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "姓名" },
      levelId: { type: "number", description: "等级" },
      levelName: { type: "string", description: "Level name TODO" },
      displayName: { type: "string", description: "昵称" }
    }
  },
  CoachCoachEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      phone: { type: "string", description: "手机号" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      name: { type: "string", description: "姓名" },
      displayName: { type: "string", description: "昵称" },
      note: { type: "string", description: "备注" },
      active: { type: "boolean", description: "激活" },
      certified: { type: "boolean", description: "Certified TODO" }
    }
  },
  UserDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      username: { type: "string", description: "用户名" },
      phone: { type: "string", description: "手机" }
    }
  },
  CoachLevelList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" }
    }
  },
  CoachCategoryDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" }
    }
  },
  CoachCategoryForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" }
    }
  },
  CoachLevelDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      category: {
        description: "类型",
        ref: "CoachCategoryDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" }
        }
      },
      categoryId: { type: "number", description: "类型" }
    }
  },
  CoachLevelForm: {
    type: "object",
    properties: {
      categoryId: { type: "number", required: true, description: "类型" },
      name: { type: "string", required: true, description: "名称" }
    }
  },
  CoachCoachDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      phone: { type: "string", description: "手机号" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      name: { type: "string", description: "姓名" },
      displayName: { type: "string", description: "昵称" },
      note: { type: "string", description: "备注" },
      active: { type: "boolean", description: "激活" },
      certified: { type: "boolean", description: "Certified TODO" },
      user: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
        description: "用户"
      },
      userId: { type: "number", description: "用户" },
      profilePhoto: {
        description: "形象照",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      wxQrCode: {
        description: "微信二维码",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      level: {
        description: "等级",
        ref: "CoachLevelDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          category: {
            description: "类型",
            ref: "CoachCategoryDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" }
            }
          },
          categoryId: { type: "number", description: "类型" }
        }
      },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      joinedAt: {
        description: "入职时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      primaryStudio: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/StudioStudioList" }],
        description: "绑定门店"
      },
      scopeOfAccess: {
        type: "string",
        required: true,
        enum: ["mine", "all"],
        "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
        description: "查看排课范围"
      },
      introduction: { type: "string", description: "介绍" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "教练风格"
      },
      certificationList: {
        type: "array",
        items: { type: "string" },
        description: "技能认证"
      },
      styleList: {
        type: "array",
        items: { type: "string" },
        description: "执教风格"
      },
      resumeDisplay: { type: "boolean", description: "执教数据展示" },
      eliteTrainingCapacity: { type: "number", description: "私教人数上限" },
      primaryStudioId: { type: "number", description: "绑定门店" },
      applyToAllProduct: { type: "boolean", description: "授权所有训练产品" },
      levelId: { type: "number", description: "等级" },
      createdAt: {
        description: "加入时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  CoachCoachWithCoachProducts: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      phone: { type: "string", description: "手机号" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      name: { type: "string", description: "姓名" },
      displayName: { type: "string", description: "昵称" },
      note: { type: "string", description: "备注" },
      active: { type: "boolean", description: "激活" },
      certified: { type: "boolean", description: "Certified TODO" },
      applyToAllProduct: { type: "boolean", description: "授权所有训练产品" },
      workoutVerificationsCount: { type: "number", description: "认证课程" },
      productIds: {
        type: "array",
        items: { type: "number" },
        description: "Product ids TODO"
      },
      operatingTagList: {
        type: "array",
        items: { type: "string" },
        description: "服务标签"
      },
      products: {
        type: "array",
        items: { type: "string" },
        description: "授权项目"
      }
    }
  },
  CoachCoachUpdateForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "姓名" },
      displayName: { type: "string", required: true, description: "昵称" },
      phone: { type: "string", required: true, description: "手机号" },
      userId: { type: "number", description: "用户" },
      levelId: { type: "number", required: true, description: "等级" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      joinedAt: {
        description: "入职时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      primaryStudioId: { type: "number", description: "绑定门店" },
      avatar: {
        description: "头像",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      profilePhoto: {
        description: "形象照",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      wxQrCode: {
        description: "微信二维码",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      scopeOfAccess: {
        type: "string",
        required: true,
        enum: ["mine", "all"],
        "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
        description: "查看排课范围"
      },
      introduction: { type: "string", description: "介绍" },
      applyToAllProduct: { type: "boolean", description: "授权所有训练产品" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "教练风格"
      },
      operatingTagList: {
        type: "array",
        items: { type: "string" },
        description: "服务标签"
      },
      certificationList: {
        type: "array",
        items: { type: "string" },
        description: "技能认证"
      },
      styleList: {
        type: "array",
        items: { type: "string" },
        description: "执教风格"
      },
      resumeDisplay: { type: "boolean", description: "执教数据展示" },
      note: { type: "string", description: "备注" },
      eliteTrainingCapacity: { type: "number", description: "私教人数上限" },
      active: { type: "boolean", description: "激活" }
    }
  },
  CoachCoachCreateForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "姓名" },
      displayName: { type: "string", required: true, description: "昵称" },
      phone: { type: "string", required: true, description: "手机号" },
      userId: { type: "number", description: "用户" },
      levelId: { type: "number", required: true, description: "等级" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      joinedAt: {
        description: "入职时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      primaryStudioId: { type: "number", description: "绑定门店" },
      avatar: {
        description: "头像",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      profilePhoto: {
        description: "形象照",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      wxQrCode: {
        description: "微信二维码",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      scopeOfAccess: {
        type: "string",
        required: true,
        enum: ["mine", "all"],
        "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
        description: "查看排课范围"
      },
      introduction: { type: "string", description: "介绍" },
      applyToAllProduct: { type: "boolean", description: "授权所有训练产品" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "教练风格"
      },
      operatingTagList: {
        type: "array",
        items: { type: "string" },
        description: "服务标签"
      },
      certificationList: {
        type: "array",
        items: { type: "string" },
        description: "技能认证"
      },
      styleList: {
        type: "array",
        items: { type: "string" },
        description: "执教风格"
      },
      resumeDisplay: { type: "boolean", description: "执教数据展示" },
      note: { type: "string", description: "备注" },
      eliteTrainingCapacity: { type: "number", description: "私教人数上限" },
      active: { type: "boolean", description: "激活" }
    }
  },
  CoachCoachCoursesSummary: {
    type: "object",
    properties: {
      upcomingCount: { type: "number", description: "排课数" },
      currentMonthFinishedCount: {
        type: "number",
        description: "本月执教课时数"
      },
      totalFinishedCount: { type: "number", description: "总执教课时数" }
    }
  },
  CoachCoachCoursesTrendsItem: {
    type: "object",
    properties: {
      label: { type: "string", description: "标识" },
      data: { type: "number", description: "数据" }
    }
  },
  CoachCoachCoachTag: {
    type: "object",
    properties: {
      name: { type: "string", description: "姓名" },
      count: { type: "number", description: "Count TODO" }
    }
  },
  BrandBrandBrief: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      identifier: { type: "string", description: "识别码" }
    }
  },
  PermissionRoleList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      active: { type: "boolean", description: "激活" },
      manageAllBrands: { type: "boolean", description: "管理所有品牌" }
    }
  },
  BrandAdminDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      login: { type: "string", description: "帐号" },
      brand: {
        description: "品牌",
        ref: "BrandBrandDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          identifier: { type: "string", description: "识别码" },
          serviceProductList: {
            type: "array",
            items: { type: "string" },
            description: "产品类型"
          },
          saasType: {
            type: "string",
            required: false,
            enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
            "x-i18n": {
              zishi_power: "ZISHI POWER",
              zishi_power_kid: "ZISHI POWER KID",
              zishi_power_coach: "ZISHI POWER COACH"
            },
            description: "SaaS类型"
          },
          cancellationDeadline: {
            type: "number",
            description: "默认取消限制（小时）"
          },
          vipGroupTrainingDiscount: {
            type: "number",
            description: "团课储值卡折扣"
          },
          vipFreeTrainingDiscount: {
            type: "number",
            description: "自由训练储值卡折扣"
          },
          vipCampDiscount: { type: "number", description: "训练营储值卡折扣" },
          vipCouponPackageDiscount: {
            type: "number",
            description: "Vip coupon package discount TODO"
          },
          vipMembershipCardDiscount: {
            type: "number",
            description: "Vip membership card discount TODO"
          },
          vipCancellationDeadline: {
            type: "number",
            description: "会员卡约课取消限制（小时）"
          },
          scheduleDisplayStartTime: {
            type: "object",
            nullable: true,
            description: "课表展示开始时间"
          },
          scheduleDisplayEndTime: {
            type: "object",
            nullable: true,
            description: "课表展示结束时间"
          },
          prepayCardName: {
            type: "string",
            nullable: true,
            description: "储值卡名称"
          },
          logo: {
            description: "Logo",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          customType: { type: "string", description: "客户类型" },
          qrCode: {
            description: "小程序码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          role: {
            description: "权限组",
            ref: "PermissionRoleList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              active: { type: "boolean", description: "激活" },
              manageAllBrands: { type: "boolean", description: "管理所有品牌" }
            }
          },
          theme: {
            description: "小程序主题",
            ref: "BrandThemeDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              primaryColor: { type: "string", description: "主题色" },
              secondaryColor: { type: "string", description: "辅助色" }
            }
          },
          payConfig: {
            description: "支付配置",
            ref: "BrandPayConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              mchId: { type: "string", description: "微信商户ID" },
              certUploaded: { type: "boolean", description: "证书已上传" },
              active: { type: "boolean", description: "激活" }
            }
          },
          mpConfig: {
            description: "小程序配置",
            ref: "BrandMpConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              appId: { type: "string", description: "App Id" },
              active: { type: "boolean", description: "激活" }
            }
          },
          wxMessageTemplates: {
            description: "微信通知模板",
            ref: "BrandWxConfigMessageTemplates",
            type: "object",
            properties: {
              courseReminder: {
                type: "string",
                description: "Course reminder TODO"
              },
              bookingCancel: {
                type: "string",
                description: "Booking cancel TODO"
              },
              bookingQueueStatus: {
                type: "string",
                description: "Booking queue status TODO"
              },
              trainingResult: {
                type: "string",
                description: "Training result TODO"
              },
              footprintUploaded: {
                type: "string",
                description: "Footprint uploaded TODO"
              }
            }
          },
          courseMilestone: {
            type: "number",
            reuqired: true,
            description: "课程里程碑"
          },
          coachCourseMilestone: {
            type: "number",
            reuqired: true,
            description: "教练执教里程碑"
          },
          memberBookingMilestone: {
            type: "number",
            reuqired: true,
            description: "用户打卡里程碑"
          },
          communitySetting: {
            description: "社区设置",
            ref: "BrandCommunitySettingDefault",
            type: "object",
            properties: {
              enabled: { type: "number", description: "开启社群" },
              progressEnabled: { type: "boolean", description: "展示社群统计" },
              progressType: {
                type: "string",
                required: false,
                enum: ["", "training", "calories"],
                "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
                description: "社群统计数据类型"
              },
              progressAmplificationFactor: {
                type: "number",
                description: "社群统计数据放大系数"
              },
              leaderboardEnabled: {
                type: "boolean",
                description: "展示排行榜"
              },
              leaderboardTypes: {
                type: "array",
                items: { type: "string" },
                description: "展示排行榜类型"
              },
              tasLeaderboards: {
                type: "array",
                items: { type: "string" },
                description: "智慧屏榜单展示"
              },
              operatingLists: {
                type: "array",
                items: { type: "string" },
                description: "运营列表"
              },
              footprintsEnabled: {
                type: "boolean",
                description: "展示记忆相册"
              },
              footprintMemberCountThreshold: {
                type: "number",
                description: "记忆相册展示人数门槛"
              }
            }
          },
          expiryAlertEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "团课次数不足阈值（低于/次）"
          },
          expiryAlertEliteEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "私教次数不足阈值（低于/次）"
          },
          expiryAlertPrepayAmountThreshold: {
            type: "number",
            reuqired: true,
            description: "余额不足阈值（低于/元）"
          },
          expiryAlertValidityPeriodThreshold: {
            type: "number",
            reuqired: true,
            description: "账户临期阈值（仅剩/天）"
          },
          expiryDate: {
            type: "object",
            nullable: true,
            description: "授权截止"
          },
          newMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "新用户判定阈值（天）"
          },
          inactiveMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "团课不活跃阈值（天）"
          },
          inactiveEliteMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "私教不活跃阈值（天）"
          },
          userTerms: { type: "string", description: "用户协议" },
          hrmEnabled: { type: "boolean", description: "Hrm enabled TODO" },
          grantGateToMemberWithEffectiveAccount: {
            type: "boolean",
            description: "开启用户白名单"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      role: {
        description: "权限组",
        ref: "PermissionRoleList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          active: { type: "boolean", description: "激活" },
          manageAllBrands: { type: "boolean", description: "管理所有品牌" }
        }
      },
      roleId: { type: "number", description: "权限组" },
      coachId: { type: "number", description: "关联教练" },
      manageAllStudios: { type: "boolean", description: "授权所有门店" },
      grantedStudios: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            fullName: { type: "string", description: "门店全称" },
            shortName: { type: "string", description: "门店名称" }
          }
        },
        description: "授权门店",
        ref: "StudioStudioList"
      },
      grantedStudioIds: {
        type: "array",
        items: { type: "number" },
        description: "授权门店"
      },
      manageAllPartners: { type: "boolean", description: "授权所有合作商户" },
      grantedPartnerIds: {
        type: "array",
        items: { type: "number" },
        description: "授权合作商户"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  PermissionFeatureDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      text: { type: "string", description: "Text TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      featureType: { type: "string", description: "Feature type TODO" },
      tips: { type: "string", description: "Tips TODO" },
      parentId: { type: "number", description: "Parent TODO" },
      position: { type: "string", description: "Position TODO" }
    }
  },
  PermissionFeatureIncludeChildren: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      text: { type: "string", description: "Text TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      featureType: { type: "string", description: "Feature type TODO" },
      tips: { type: "string", description: "Tips TODO" },
      parentId: { type: "number", description: "Parent TODO" },
      position: { type: "string", description: "Position TODO" },
      children: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "integer", description: "ID" },
            name: { type: "string", description: "名称" },
            text: { type: "string", description: "Text TODO" },
            fullName: { type: "string", description: "Full name TODO" },
            featureType: { type: "string", description: "Feature type TODO" },
            tips: { type: "string", description: "Tips TODO" },
            parentId: { type: "integer", description: "Parent TODO" },
            position: { type: "string", description: "Position TODO" },
            children: {
              type: "array",
              items: { $ref: "#/definitions/PermissionFeatureIncludeChildren" },
              description: "Children TODO"
            }
          }
        },
        description: "Children TODO",
        ref: "PermissionFeatureIncludeChildren"
      }
    }
  },
  BrandAdminWithGrantedFeatures: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      login: { type: "string", description: "帐号" },
      brand: {
        description: "品牌",
        ref: "BrandBrandDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          identifier: { type: "string", description: "识别码" },
          serviceProductList: {
            type: "array",
            items: { type: "string" },
            description: "产品类型"
          },
          saasType: {
            type: "string",
            required: false,
            enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
            "x-i18n": {
              zishi_power: "ZISHI POWER",
              zishi_power_kid: "ZISHI POWER KID",
              zishi_power_coach: "ZISHI POWER COACH"
            },
            description: "SaaS类型"
          },
          cancellationDeadline: {
            type: "number",
            description: "默认取消限制（小时）"
          },
          vipGroupTrainingDiscount: {
            type: "number",
            description: "团课储值卡折扣"
          },
          vipFreeTrainingDiscount: {
            type: "number",
            description: "自由训练储值卡折扣"
          },
          vipCampDiscount: { type: "number", description: "训练营储值卡折扣" },
          vipCouponPackageDiscount: {
            type: "number",
            description: "Vip coupon package discount TODO"
          },
          vipMembershipCardDiscount: {
            type: "number",
            description: "Vip membership card discount TODO"
          },
          vipCancellationDeadline: {
            type: "number",
            description: "会员卡约课取消限制（小时）"
          },
          scheduleDisplayStartTime: {
            type: "object",
            nullable: true,
            description: "课表展示开始时间"
          },
          scheduleDisplayEndTime: {
            type: "object",
            nullable: true,
            description: "课表展示结束时间"
          },
          prepayCardName: {
            type: "string",
            nullable: true,
            description: "储值卡名称"
          },
          logo: {
            description: "Logo",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          customType: { type: "string", description: "客户类型" },
          qrCode: {
            description: "小程序码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          role: {
            description: "权限组",
            ref: "PermissionRoleList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              active: { type: "boolean", description: "激活" },
              manageAllBrands: { type: "boolean", description: "管理所有品牌" }
            }
          },
          theme: {
            description: "小程序主题",
            ref: "BrandThemeDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              primaryColor: { type: "string", description: "主题色" },
              secondaryColor: { type: "string", description: "辅助色" }
            }
          },
          payConfig: {
            description: "支付配置",
            ref: "BrandPayConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              mchId: { type: "string", description: "微信商户ID" },
              certUploaded: { type: "boolean", description: "证书已上传" },
              active: { type: "boolean", description: "激活" }
            }
          },
          mpConfig: {
            description: "小程序配置",
            ref: "BrandMpConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              appId: { type: "string", description: "App Id" },
              active: { type: "boolean", description: "激活" }
            }
          },
          wxMessageTemplates: {
            description: "微信通知模板",
            ref: "BrandWxConfigMessageTemplates",
            type: "object",
            properties: {
              courseReminder: {
                type: "string",
                description: "Course reminder TODO"
              },
              bookingCancel: {
                type: "string",
                description: "Booking cancel TODO"
              },
              bookingQueueStatus: {
                type: "string",
                description: "Booking queue status TODO"
              },
              trainingResult: {
                type: "string",
                description: "Training result TODO"
              },
              footprintUploaded: {
                type: "string",
                description: "Footprint uploaded TODO"
              }
            }
          },
          courseMilestone: {
            type: "number",
            reuqired: true,
            description: "课程里程碑"
          },
          coachCourseMilestone: {
            type: "number",
            reuqired: true,
            description: "教练执教里程碑"
          },
          memberBookingMilestone: {
            type: "number",
            reuqired: true,
            description: "用户打卡里程碑"
          },
          communitySetting: {
            description: "社区设置",
            ref: "BrandCommunitySettingDefault",
            type: "object",
            properties: {
              enabled: { type: "number", description: "开启社群" },
              progressEnabled: { type: "boolean", description: "展示社群统计" },
              progressType: {
                type: "string",
                required: false,
                enum: ["", "training", "calories"],
                "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
                description: "社群统计数据类型"
              },
              progressAmplificationFactor: {
                type: "number",
                description: "社群统计数据放大系数"
              },
              leaderboardEnabled: {
                type: "boolean",
                description: "展示排行榜"
              },
              leaderboardTypes: {
                type: "array",
                items: { type: "string" },
                description: "展示排行榜类型"
              },
              tasLeaderboards: {
                type: "array",
                items: { type: "string" },
                description: "智慧屏榜单展示"
              },
              operatingLists: {
                type: "array",
                items: { type: "string" },
                description: "运营列表"
              },
              footprintsEnabled: {
                type: "boolean",
                description: "展示记忆相册"
              },
              footprintMemberCountThreshold: {
                type: "number",
                description: "记忆相册展示人数门槛"
              }
            }
          },
          expiryAlertEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "团课次数不足阈值（低于/次）"
          },
          expiryAlertEliteEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "私教次数不足阈值（低于/次）"
          },
          expiryAlertPrepayAmountThreshold: {
            type: "number",
            reuqired: true,
            description: "余额不足阈值（低于/元）"
          },
          expiryAlertValidityPeriodThreshold: {
            type: "number",
            reuqired: true,
            description: "账户临期阈值（仅剩/天）"
          },
          expiryDate: {
            type: "object",
            nullable: true,
            description: "授权截止"
          },
          newMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "新用户判定阈值（天）"
          },
          inactiveMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "团课不活跃阈值（天）"
          },
          inactiveEliteMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "私教不活跃阈值（天）"
          },
          userTerms: { type: "string", description: "用户协议" },
          hrmEnabled: { type: "boolean", description: "Hrm enabled TODO" },
          grantGateToMemberWithEffectiveAccount: {
            type: "boolean",
            description: "开启用户白名单"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      role: {
        description: "权限组",
        ref: "PermissionRoleList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          active: { type: "boolean", description: "激活" },
          manageAllBrands: { type: "boolean", description: "管理所有品牌" }
        }
      },
      roleId: { type: "number", description: "权限组" },
      coachId: { type: "number", description: "关联教练" },
      manageAllStudios: { type: "boolean", description: "授权所有门店" },
      grantedStudios: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            fullName: { type: "string", description: "门店全称" },
            shortName: { type: "string", description: "门店名称" }
          }
        },
        description: "授权门店",
        ref: "StudioStudioList"
      },
      grantedStudioIds: {
        type: "array",
        items: { type: "number" },
        description: "授权门店"
      },
      manageAllPartners: { type: "boolean", description: "授权所有合作商户" },
      grantedPartnerIds: {
        type: "array",
        items: { type: "number" },
        description: "授权合作商户"
      },
      active: { type: "boolean", description: "激活" },
      grantedFeatures: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            text: { type: "string", description: "Text TODO" },
            fullName: { type: "string", description: "Full name TODO" },
            featureType: { type: "string", description: "Feature type TODO" },
            tips: { type: "string", description: "Tips TODO" },
            parentId: { type: "number", description: "Parent TODO" },
            position: { type: "string", description: "Position TODO" }
          }
        },
        description: "授权",
        ref: "PermissionFeatureDefault"
      }
    }
  },
  BrandAdminCreateForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      phone: { type: "string", required: true, description: "手机" },
      password: { type: "string", description: "密码" },
      roleId: { type: "number", required: true, description: "权限组" },
      manageAllStudios: { type: "boolean", description: "授权所有门店" },
      grantedStudioIds: {
        type: "array",
        items: { type: "number" },
        description: "授权门店"
      },
      manageAllPartners: { type: "boolean", description: "授权所有合作商户" },
      grantedPartnerIds: {
        type: "array",
        items: { type: "number" },
        description: "授权合作商户"
      },
      active: { type: "boolean", required: true, description: "激活" }
    }
  },
  BrandAdminUpdateForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      roleId: { type: "number", required: true, description: "权限组" },
      coachId: { type: "number", required: false, description: "关联教练" },
      manageAllStudios: { type: "boolean", description: "授权所有门店" },
      grantedStudioIds: {
        type: "array",
        items: { type: "number" },
        description: "授权门店"
      },
      manageAllPartners: { type: "boolean", description: "授权所有合作商户" },
      grantedPartnerIds: {
        type: "array",
        items: { type: "number" },
        description: "授权合作商户"
      },
      active: { type: "boolean", required: true, description: "激活" }
    }
  },
  PermissionRoleDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      description: { type: "string", description: "描述" },
      manageAllBrands: { type: "boolean", description: "管理所有品牌" },
      allFeaturesGranted: { type: "boolean", description: "所有权限" },
      active: { type: "boolean", description: "激活" },
      featureIds: {
        type: "array",
        items: { type: "number" },
        description: "权限"
      },
      features: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            text: { type: "string", description: "Text TODO" },
            fullName: { type: "string", description: "Full name TODO" },
            featureType: { type: "string", description: "Feature type TODO" },
            tips: { type: "string", description: "Tips TODO" },
            parentId: { type: "number", description: "Parent TODO" },
            position: { type: "string", description: "Position TODO" }
          }
        },
        description: "权限",
        ref: "PermissionFeatureDefault"
      }
    }
  },
  PermissionRoleForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      allFeaturesGranted: {
        type: "boolean",
        required: true,
        description: "所有权限"
      },
      active: { type: "boolean", required: true, description: "激活" },
      featureIds: {
        type: "array",
        items: { type: "number" },
        description: "权限"
      },
      manageAllBrands: {
        type: "boolean",
        required: true,
        description: "管理所有品牌"
      }
    }
  },
  BrandThemeDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      primaryColor: { type: "string", description: "主题色" },
      secondaryColor: { type: "string", description: "辅助色" }
    }
  },
  BrandThemeForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      primaryColor: { type: "string", required: true, description: "主题色" },
      secondaryColor: { type: "string", required: true, description: "辅助色" }
    }
  },
  BrandPayConfigDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      mchId: { type: "string", description: "微信商户ID" },
      certUploaded: { type: "boolean", description: "证书已上传" },
      active: { type: "boolean", description: "激活" }
    }
  },
  BrandPayConfigForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      mchId: { type: "string", description: "微信商户ID" },
      key: { type: "string", description: "API密钥" },
      active: { type: "boolean", description: "激活" }
    }
  },
  BrandMpConfigDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      appId: { type: "string", description: "App Id" },
      active: { type: "boolean", description: "激活" }
    }
  },
  BrandMpConfigForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      appId: { type: "string", description: "App Id" },
      appSecret: { type: "string", description: "App Secret" },
      active: { type: "boolean", description: "激活" }
    }
  },
  BrandWxConfigMessageTemplates: {
    type: "object",
    properties: {
      courseReminder: { type: "string", description: "Course reminder TODO" },
      bookingCancel: { type: "string", description: "Booking cancel TODO" },
      bookingQueueStatus: {
        type: "string",
        description: "Booking queue status TODO"
      },
      trainingResult: { type: "string", description: "Training result TODO" },
      footprintUploaded: {
        type: "string",
        description: "Footprint uploaded TODO"
      }
    }
  },
  BrandCommunitySettingDefault: {
    type: "object",
    properties: {
      enabled: { type: "number", description: "开启社群" },
      progressEnabled: { type: "boolean", description: "展示社群统计" },
      progressType: {
        type: "string",
        required: false,
        enum: ["", "training", "calories"],
        "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
        description: "社群统计数据类型"
      },
      progressAmplificationFactor: {
        type: "number",
        description: "社群统计数据放大系数"
      },
      leaderboardEnabled: { type: "boolean", description: "展示排行榜" },
      leaderboardTypes: {
        type: "array",
        items: { type: "string" },
        description: "展示排行榜类型"
      },
      tasLeaderboards: {
        type: "array",
        items: { type: "string" },
        description: "智慧屏榜单展示"
      },
      operatingLists: {
        type: "array",
        items: { type: "string" },
        description: "运营列表"
      },
      footprintsEnabled: { type: "boolean", description: "展示记忆相册" },
      footprintMemberCountThreshold: {
        type: "number",
        description: "记忆相册展示人数门槛"
      }
    }
  },
  BrandCommunitySettingForm: {
    type: "object",
    properties: {
      progressType: {
        type: "string",
        required: false,
        enum: ["", "training", "calories"],
        "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
        description: "社群统计数据类型"
      },
      leaderboardTypes: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: [
            "calories",
            "training",
            "outdoor_training",
            "cancellation",
            "master",
            "hall_of_fame"
          ],
          "x-i18n": {
            calories: "燃脂榜",
            training: "内卷榜",
            outdoor_training: "户外榜",
            cancellation: "水友榜",
            master: "大神榜",
            hall_of_fame: "名人堂"
          }
        },
        description: "展示排行榜类型"
      },
      leaderboardProductIds: {
        type: "array",
        items: { type: "number" },
        description: "社群统计课程产品"
      },
      tasLeaderboards: {
        type: "array",
        items: { type: "string" },
        description: "智慧屏榜单展示"
      },
      footprintsEnabled: { type: "boolean", description: "展示记忆相册" },
      footprintMemberCountThreshold: {
        type: "number",
        description: "记忆相册展示人数门槛"
      }
    }
  },
  BrandBrandDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      identifier: { type: "string", description: "识别码" },
      serviceProductList: {
        type: "array",
        items: { type: "string" },
        description: "产品类型"
      },
      saasType: {
        type: "string",
        required: false,
        enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
        "x-i18n": {
          zishi_power: "ZISHI POWER",
          zishi_power_kid: "ZISHI POWER KID",
          zishi_power_coach: "ZISHI POWER COACH"
        },
        description: "SaaS类型"
      },
      cancellationDeadline: {
        type: "number",
        description: "默认取消限制（小时）"
      },
      vipGroupTrainingDiscount: {
        type: "number",
        description: "团课储值卡折扣"
      },
      vipFreeTrainingDiscount: {
        type: "number",
        description: "自由训练储值卡折扣"
      },
      vipCampDiscount: { type: "number", description: "训练营储值卡折扣" },
      vipCouponPackageDiscount: {
        type: "number",
        description: "Vip coupon package discount TODO"
      },
      vipMembershipCardDiscount: {
        type: "number",
        description: "Vip membership card discount TODO"
      },
      vipCancellationDeadline: {
        type: "number",
        description: "会员卡约课取消限制（小时）"
      },
      scheduleDisplayStartTime: {
        type: "object",
        nullable: true,
        description: "课表展示开始时间"
      },
      scheduleDisplayEndTime: {
        type: "object",
        nullable: true,
        description: "课表展示结束时间"
      },
      prepayCardName: {
        type: "string",
        nullable: true,
        description: "储值卡名称"
      },
      logo: {
        description: "Logo",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      customType: { type: "string", description: "客户类型" },
      qrCode: {
        description: "小程序码",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      role: {
        description: "权限组",
        ref: "PermissionRoleList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          active: { type: "boolean", description: "激活" },
          manageAllBrands: { type: "boolean", description: "管理所有品牌" }
        }
      },
      theme: {
        description: "小程序主题",
        ref: "BrandThemeDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          primaryColor: { type: "string", description: "主题色" },
          secondaryColor: { type: "string", description: "辅助色" }
        }
      },
      payConfig: {
        description: "支付配置",
        ref: "BrandPayConfigDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          mchId: { type: "string", description: "微信商户ID" },
          certUploaded: { type: "boolean", description: "证书已上传" },
          active: { type: "boolean", description: "激活" }
        }
      },
      mpConfig: {
        description: "小程序配置",
        ref: "BrandMpConfigDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          appId: { type: "string", description: "App Id" },
          active: { type: "boolean", description: "激活" }
        }
      },
      wxMessageTemplates: {
        description: "微信通知模板",
        ref: "BrandWxConfigMessageTemplates",
        type: "object",
        properties: {
          courseReminder: {
            type: "string",
            description: "Course reminder TODO"
          },
          bookingCancel: { type: "string", description: "Booking cancel TODO" },
          bookingQueueStatus: {
            type: "string",
            description: "Booking queue status TODO"
          },
          trainingResult: {
            type: "string",
            description: "Training result TODO"
          },
          footprintUploaded: {
            type: "string",
            description: "Footprint uploaded TODO"
          }
        }
      },
      courseMilestone: {
        type: "number",
        reuqired: true,
        description: "课程里程碑"
      },
      coachCourseMilestone: {
        type: "number",
        reuqired: true,
        description: "教练执教里程碑"
      },
      memberBookingMilestone: {
        type: "number",
        reuqired: true,
        description: "用户打卡里程碑"
      },
      communitySetting: {
        description: "社区设置",
        ref: "BrandCommunitySettingDefault",
        type: "object",
        properties: {
          enabled: { type: "number", description: "开启社群" },
          progressEnabled: { type: "boolean", description: "展示社群统计" },
          progressType: {
            type: "string",
            required: false,
            enum: ["", "training", "calories"],
            "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
            description: "社群统计数据类型"
          },
          progressAmplificationFactor: {
            type: "number",
            description: "社群统计数据放大系数"
          },
          leaderboardEnabled: { type: "boolean", description: "展示排行榜" },
          leaderboardTypes: {
            type: "array",
            items: { type: "string" },
            description: "展示排行榜类型"
          },
          tasLeaderboards: {
            type: "array",
            items: { type: "string" },
            description: "智慧屏榜单展示"
          },
          operatingLists: {
            type: "array",
            items: { type: "string" },
            description: "运营列表"
          },
          footprintsEnabled: { type: "boolean", description: "展示记忆相册" },
          footprintMemberCountThreshold: {
            type: "number",
            description: "记忆相册展示人数门槛"
          }
        }
      },
      expiryAlertEntryThreshold: {
        type: "number",
        reuqired: true,
        description: "团课次数不足阈值（低于/次）"
      },
      expiryAlertEliteEntryThreshold: {
        type: "number",
        reuqired: true,
        description: "私教次数不足阈值（低于/次）"
      },
      expiryAlertPrepayAmountThreshold: {
        type: "number",
        reuqired: true,
        description: "余额不足阈值（低于/元）"
      },
      expiryAlertValidityPeriodThreshold: {
        type: "number",
        reuqired: true,
        description: "账户临期阈值（仅剩/天）"
      },
      expiryDate: { type: "object", nullable: true, description: "授权截止" },
      newMemberThreshold: {
        type: "number",
        reuqired: true,
        description: "新用户判定阈值（天）"
      },
      inactiveMemberThreshold: {
        type: "number",
        reuqired: true,
        description: "团课不活跃阈值（天）"
      },
      inactiveEliteMemberThreshold: {
        type: "number",
        reuqired: true,
        description: "私教不活跃阈值（天）"
      },
      userTerms: { type: "string", description: "用户协议" },
      hrmEnabled: { type: "boolean", description: "Hrm enabled TODO" },
      grantGateToMemberWithEffectiveAccount: {
        type: "boolean",
        description: "开启用户白名单"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  BrandBrandBrandDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      logo: {
        description: "Logo",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      saasType: {
        type: "string",
        required: false,
        enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
        "x-i18n": {
          zishi_power: "ZISHI POWER",
          zishi_power_kid: "ZISHI POWER KID",
          zishi_power_coach: "ZISHI POWER COACH"
        },
        description: "SaaS类型"
      },
      serviceProductList: {
        type: "array",
        items: { type: "string" },
        description: "产品类型"
      },
      scheduleAutoPublish: { type: "boolean", description: "自动发布课表" },
      schedulePublishCycle: {
        type: "string",
        required: false,
        enum: ["weekly", "daily"],
        "x-i18n": { weekly: "按周", daily: "按天" },
        description: "发布规则"
      },
      autoAssignEliteCoach: {
        type: "boolean",
        description: "自动分配责任教练"
      },
      mobileEliteTrainingBooking: {
        type: "boolean",
        description: "私教用户可预约"
      },
      mobileEliteTrainingBookingDeadline: {
        type: "number",
        description: "最晚预约需距课前（小时）"
      },
      mobileCancelEliteTrainingBooking: {
        type: "boolean",
        description: "私教预约用户可取消"
      },
      mobileEliteTrainingCancellationDeadline: {
        type: "number",
        description: "私教用户默认可取消时间（小时）"
      },
      eliteTrainingScheduleInterval: {
        type: "number",
        description: "私教排期时间间隔"
      },
      eliteTrainingScheduleDays: {
        type: "number",
        description: "私教可提前预约天数"
      },
      eliteTrainingCoachCheckIn: {
        type: "boolean",
        description: "私教教练代签到"
      },
      eliteTrainingCoachCancel: {
        type: "boolean",
        description: "私教教练端取消预约"
      },
      eliteTrainingCoachLeave: {
        type: "boolean",
        description: "私教教练端隐藏时段"
      },
      sendSmsToEliteTrainingCoach: { type: "boolean", description: "短信通知" },
      publishWday: {
        type: "number",
        nullable: true,
        description: "课表发布日"
      },
      publishTime: {
        type: "string",
        nullable: true,
        description: "课表发布时间"
      },
      scheduleDisplayStartTime: {
        type: "object",
        nullable: true,
        description: "课表展示开始时间"
      },
      scheduleDisplayEndTime: {
        type: "object",
        nullable: true,
        description: "课表展示结束时间"
      },
      courseMilestone: {
        type: "number",
        reuqired: true,
        description: "课程里程碑"
      },
      coachCourseMilestone: {
        type: "number",
        reuqired: true,
        description: "教练执教里程碑"
      },
      memberBookingMilestone: {
        type: "number",
        reuqired: true,
        description: "用户打卡里程碑"
      },
      expiryAlertEntryThreshold: {
        type: "number",
        reuqired: true,
        description: "团课次数不足阈值（低于/次）"
      },
      expiryAlertEliteEntryThreshold: {
        type: "number",
        reuqired: true,
        description: "私教次数不足阈值（低于/次）"
      },
      expiryAlertPrepayAmountThreshold: {
        type: "number",
        reuqired: true,
        description: "余额不足阈值（低于/元）"
      },
      expiryAlertValidityPeriodThreshold: {
        type: "number",
        reuqired: true,
        description: "账户临期阈值（仅剩/天）"
      },
      churnThreshold: {
        type: "number",
        reuqired: true,
        description: "流失阈值（天）"
      },
      newMemberThreshold: {
        type: "number",
        reuqired: true,
        description: "新用户判定阈值（天）"
      },
      inactiveMemberThreshold: {
        type: "number",
        reuqired: true,
        description: "团课不活跃阈值（天）"
      },
      inactiveEliteMemberThreshold: {
        type: "number",
        reuqired: true,
        description: "私教不活跃阈值（天）"
      },
      userTerms: { type: "string", description: "用户协议" },
      prepayRules: { type: "string", description: "Prepay rules TODO" },
      trainingReportScope: {
        type: "array",
        items: { type: "string" },
        description: "包含数据"
      },
      trainingReport: { type: "boolean", description: "开启训练报告" },
      grantGateToMemberWithEffectiveAccount: {
        type: "boolean",
        description: "开启用户白名单"
      }
    }
  },
  BrandBrandBrandForm: {
    type: "object",
    properties: {
      logo: {
        description: "Logo",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      scheduleAutoPublish: { type: "boolean", description: "自动发布课表" },
      schedulePublishCycle: {
        type: "string",
        required: false,
        enum: ["weekly", "daily"],
        "x-i18n": { weekly: "按周", daily: "按天" },
        description: "发布规则"
      },
      autoAssignEliteCoach: {
        type: "boolean",
        description: "自动分配责任教练"
      },
      userTerms: { type: "string", description: "用户协议" },
      prepayRules: { type: "string", description: "Prepay rules TODO" },
      cancellationDeadline: {
        type: "number",
        description: "默认取消限制（小时）"
      },
      publishWday: {
        type: "number",
        nullable: true,
        description: "课表发布日"
      },
      publishTime: {
        type: "object",
        nullable: true,
        description: "课表发布时间"
      },
      scheduleDisplayStartTime: {
        type: "object",
        nullable: true,
        description: "课表展示开始时间"
      },
      scheduleDisplayEndTime: {
        type: "object",
        nullable: true,
        description: "课表展示结束时间"
      },
      mobileEliteTrainingBooking: {
        type: "boolean",
        description: "私教用户可预约"
      },
      mobileCancelEliteTrainingBooking: {
        type: "boolean",
        description: "私教预约用户可取消"
      },
      mobileEliteTrainingBookingDeadline: {
        type: "number",
        description: "最晚预约需距课前（小时）"
      },
      mobileEliteTrainingCancellationDeadline: {
        type: "number",
        description: "私教用户默认可取消时间（小时）"
      },
      eliteTrainingCoachCheckIn: {
        type: "boolean",
        description: "私教教练代签到"
      },
      eliteTrainingCoachCancel: {
        type: "boolean",
        description: "私教教练端取消预约"
      },
      eliteTrainingCoachLeave: {
        type: "boolean",
        description: "私教教练端隐藏时段"
      },
      eliteTrainingScheduleInterval: {
        type: "number",
        description: "私教排期时间间隔"
      },
      eliteTrainingScheduleDays: {
        type: "number",
        description: "私教可提前预约天数"
      },
      sendSmsToEliteTrainingCoach: { type: "boolean", description: "短信通知" },
      courseMilestone: {
        type: "number",
        reuqired: true,
        description: "课程里程碑"
      },
      coachCourseMilestone: {
        type: "number",
        reuqired: true,
        description: "教练执教里程碑"
      },
      memberBookingMilestone: {
        type: "number",
        reuqired: true,
        description: "用户打卡里程碑"
      },
      expiryAlertEntryThreshold: {
        type: "number",
        reuqired: true,
        description: "团课次数不足阈值（低于/次）"
      },
      expiryAlertEliteEntryThreshold: {
        type: "number",
        reuqired: true,
        description: "私教次数不足阈值（低于/次）"
      },
      expiryAlertPrepayAmountThreshold: {
        type: "number",
        reuqired: true,
        description: "余额不足阈值（低于/元）"
      },
      expiryAlertValidityPeriodThreshold: {
        type: "number",
        reuqired: true,
        description: "账户临期阈值（仅剩/天）"
      },
      churnThreshold: {
        type: "number",
        reuqired: true,
        description: "流失阈值（天）"
      },
      newMemberThreshold: {
        type: "number",
        reuqired: true,
        description: "新用户判定阈值（天）"
      },
      inactiveMemberThreshold: {
        type: "number",
        reuqired: true,
        description: "团课不活跃阈值（天）"
      },
      inactiveEliteMemberThreshold: {
        type: "number",
        reuqired: true,
        description: "私教不活跃阈值（天）"
      },
      trainingReportScope: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["group_training", "elite_training", "free_training", "camp"],
          "x-i18n": {
            group_training: "团课",
            elite_training: "私教",
            free_training: "自由训练",
            camp: "训练营"
          }
        },
        description: "包含数据"
      },
      trainingReport: { type: "boolean", description: "开启训练报告" },
      grantGateToMemberWithEffectiveAccount: {
        type: "boolean",
        description: "开启用户白名单"
      }
    }
  },
  BrandBrandGrantProductsForm: {
    type: "object",
    properties: {
      workoutIds: {
        type: "array",
        items: { type: "string" },
        description: "训练产品"
      },
      expiryDate: { type: "object", nullable: true, description: "授权截止" }
    }
  },
  BrandBrandOperating: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      contactName: { type: "string", description: "联系人" },
      contactPhone: { type: "string", description: "联系电话" },
      productsCount: { type: "number", description: "授权课程数" },
      coachesCount: { type: "number", description: "授权教练人数" },
      productCoursesCount: { type: "number", description: "排课数量" },
      productBookingsCount: { type: "number", description: "上课人次" },
      outlinePermissionsCount: { type: "number", description: "授权套路" },
      tasExpiryDate: { type: "string", description: "智慧屏" },
      serviceProductList: {
        type: "array",
        items: { type: "string" },
        description: "产品类型"
      },
      operatingTagList: {
        type: "array",
        items: { type: "string" },
        description: "服务标签"
      },
      productState: {
        type: "string",
        required: false,
        enum: ["授权中", "有过期"],
        "x-i18n": { 授权中: "授权中", 有过期: "有过期" },
        description: "授权状态"
      }
    }
  },
  CoachCoachOperating: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      phone: { type: "string", description: "手机号" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      name: { type: "string", description: "姓名" },
      displayName: { type: "string", description: "昵称" },
      brand: {
        description: "所属品牌",
        ref: "BrandBrandBrief",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          identifier: { type: "string", description: "识别码" }
        }
      },
      operatingTagList: {
        type: "array",
        items: { type: "string" },
        description: "服务标签"
      },
      productsCount: { type: "number", description: "授权项目" },
      workoutVerificationsCount: { type: "number", description: "认证课程" },
      outlinePermissionsCount: { type: "number", description: "授权套路" },
      note: { type: "string", description: "备注" },
      firstExpiredDate: {
        description: "First expired date TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      createdAt: {
        description: "加入时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      applyToAllProduct: { type: "boolean", description: "授权所有训练产品" },
      productState: {
        type: "string",
        required: false,
        enum: ["授权中", "有过期"],
        "x-i18n": { 授权中: "授权中", 有过期: "有过期" },
        description: "授权状态"
      }
    }
  },
  StudioStudioDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      fullName: { type: "string", description: "门店全称" },
      shortName: { type: "string", description: "门店名称" },
      active: { type: "boolean", description: "激活" },
      eliteTraining: { type: "boolean", description: "可上私教" },
      display: { type: "boolean", description: "展示" },
      address: { type: "string", description: "地址" },
      contactPhone: { type: "string", description: "联系电话" },
      longitude: { type: "string", description: "经度" },
      latitude: { type: "string", description: "维度" },
      gatePassword: { type: "string", description: "门禁密码" },
      businessOpenTime: { type: "string", description: "门店开门时间" },
      businessCloseTime: { type: "string", description: "门店关门时间" },
      traffic: { type: "string", nullable: true, description: "交通指引" },
      gateMacs: {
        type: "array",
        items: { type: "string" },
        description: "门禁MAC地址"
      },
      checkInQrCode: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "签到二维码"
      },
      openGateQrCodes: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "开门二维码"
      },
      coach: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CoachCoachEssential" }
        ],
        description: "店长"
      },
      primaryImage: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "主图"
      },
      images: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "图片",
        ref: "AttachmentDefault"
      },
      checkInUrl: { type: "string", description: "签到URL" },
      adsUrl: { type: "string", description: "广告URL" },
      gateStatus: {
        type: "array",
        items: { type: "boolean" },
        description: "门禁状态"
      }
    }
  },
  StudioStudioForm: {
    type: "object",
    properties: {
      fullName: { type: "string", required: true, description: "门店全称" },
      shortName: { type: "string", required: true, description: "门店名称" },
      businessOpenTime: {
        type: "object",
        required: true,
        description: "门店开门时间"
      },
      businessCloseTime: {
        type: "object",
        required: true,
        description: "门店关门时间"
      },
      contactPhone: { type: "string", description: "联系电话" },
      gatePassword: { type: "string", description: "门禁密码" },
      coachId: { type: "number", description: "店长" },
      gateMacs: { type: "object", nullable: true, description: "门禁MAC地址" },
      traffic: { type: "string", description: "交通指引" },
      address: { type: "string", required: true, description: "地址" },
      longitude: { type: "string", required: true, description: "经度" },
      latitude: { type: "string", required: true, description: "维度" },
      eliteTraining: { type: "boolean", description: "可上私教" },
      active: { type: "boolean", description: "激活" },
      display: { type: "boolean", description: "展示" }
    }
  },
  StudioStudioOpenGates: {
    type: "object",
    properties: {
      successCount: { type: "number", description: "成功数" },
      totalCount: { type: "number", description: "总计" }
    }
  },
  AdAdDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      active: { type: "boolean", description: "激活" },
      url: { type: "string", nullable: true, description: "URL" },
      image: {
        description: "图片",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      position: { type: "number", description: "Position TODO" },
      locationList: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["course", "outdoor_course", "training", "community"],
          "x-i18n": {
            course: "约课页面",
            outdoor_course: "户外约课页面",
            training: "训练页面",
            community: "社群页面"
          }
        },
        description: "展示位置"
      },
      studios: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            fullName: { type: "string", description: "门店全称" },
            shortName: { type: "string", description: "门店名称" }
          }
        },
        description: "关联门店",
        ref: "StudioStudioList"
      },
      applyToAllStudio: { type: "boolean", description: "所有门店可用" }
    }
  },
  AdAdDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      active: { type: "boolean", description: "激活" },
      url: { type: "string", nullable: true, description: "URL" },
      image: {
        description: "图片",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      position: { type: "number", description: "Position TODO" },
      locationList: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["course", "outdoor_course", "training", "community"],
          "x-i18n": {
            course: "约课页面",
            outdoor_course: "户外约课页面",
            training: "训练页面",
            community: "社群页面"
          }
        },
        description: "展示位置"
      },
      studios: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            fullName: { type: "string", description: "门店全称" },
            shortName: { type: "string", description: "门店名称" }
          }
        },
        description: "关联门店",
        ref: "StudioStudioList"
      },
      applyToAllStudio: { type: "boolean", description: "所有门店可用" },
      type: {
        type: "string",
        required: false,
        enum: ["Ad::Banner", "Ad::FrontDeskAd", "Ad::TrainingFlowAd"],
        "x-i18n": {
          "Ad::Banner": "约课平台广告",
          "Ad::FrontDeskAd": "智能广告屏广告",
          "Ad::TrainingFlowAd": "训练辅助系统广告"
        },
        description: "类型"
      },
      duration: { type: "number", description: "时长" },
      studioIds: {
        type: "array",
        items: { type: "number" },
        description: "关联门店"
      },
      imageUrl: { type: "string", description: "图片URL" }
    }
  },
  AdAdForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      type: {
        type: "string",
        required: false,
        enum: ["Ad::Banner", "Ad::FrontDeskAd", "Ad::TrainingFlowAd"],
        "x-i18n": {
          "Ad::Banner": "约课平台广告",
          "Ad::FrontDeskAd": "智能广告屏广告",
          "Ad::TrainingFlowAd": "训练辅助系统广告"
        },
        description: "类型"
      },
      duration: { type: "number", description: "时长" },
      active: { type: "boolean", description: "激活" },
      url: { type: "string", description: "URL" },
      image: {
        description: "图片",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      locationList: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["course", "outdoor_course", "training", "community"],
          "x-i18n": {
            course: "约课页面",
            outdoor_course: "户外约课页面",
            training: "训练页面",
            community: "社群页面"
          }
        },
        description: "展示位置"
      },
      applyToAllStudio: { type: "boolean", description: "所有门店可用" },
      studioIds: {
        type: "array",
        items: { type: "number" },
        description: "关联门店"
      }
    }
  },
  AdAdTas: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      imageUrl: { type: "string", description: "图片URL" }
    }
  },
  AdAdPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            active: { type: "boolean", description: "激活" },
            url: { type: "string", nullable: true, description: "URL" },
            image: {
              description: "图片",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            position: { type: "number", description: "Position TODO" },
            locationList: {
              type: "array",
              items: {
                type: "string",
                required: false,
                enum: ["course", "outdoor_course", "training", "community"],
                "x-i18n": {
                  course: "约课页面",
                  outdoor_course: "户外约课页面",
                  training: "训练页面",
                  community: "社群页面"
                }
              },
              description: "展示位置"
            },
            studios: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" }
                }
              },
              description: "关联门店",
              ref: "StudioStudioList"
            },
            applyToAllStudio: { type: "boolean", description: "所有门店可用" }
          }
        },
        ref: "AdAdDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  BrandAdminPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            login: { type: "string", description: "帐号" },
            brand: {
              description: "品牌",
              ref: "BrandBrandDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                identifier: { type: "string", description: "识别码" },
                serviceProductList: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品类型"
                },
                saasType: {
                  type: "string",
                  required: false,
                  enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
                  "x-i18n": {
                    zishi_power: "ZISHI POWER",
                    zishi_power_kid: "ZISHI POWER KID",
                    zishi_power_coach: "ZISHI POWER COACH"
                  },
                  description: "SaaS类型"
                },
                cancellationDeadline: {
                  type: "number",
                  description: "默认取消限制（小时）"
                },
                vipGroupTrainingDiscount: {
                  type: "number",
                  description: "团课储值卡折扣"
                },
                vipFreeTrainingDiscount: {
                  type: "number",
                  description: "自由训练储值卡折扣"
                },
                vipCampDiscount: {
                  type: "number",
                  description: "训练营储值卡折扣"
                },
                vipCouponPackageDiscount: {
                  type: "number",
                  description: "Vip coupon package discount TODO"
                },
                vipMembershipCardDiscount: {
                  type: "number",
                  description: "Vip membership card discount TODO"
                },
                vipCancellationDeadline: {
                  type: "number",
                  description: "会员卡约课取消限制（小时）"
                },
                scheduleDisplayStartTime: {
                  type: "object",
                  nullable: true,
                  description: "课表展示开始时间"
                },
                scheduleDisplayEndTime: {
                  type: "object",
                  nullable: true,
                  description: "课表展示结束时间"
                },
                prepayCardName: {
                  type: "string",
                  nullable: true,
                  description: "储值卡名称"
                },
                logo: {
                  description: "Logo",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                customType: { type: "string", description: "客户类型" },
                qrCode: {
                  description: "小程序码",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                role: {
                  description: "权限组",
                  ref: "PermissionRoleList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    active: { type: "boolean", description: "激活" },
                    manageAllBrands: {
                      type: "boolean",
                      description: "管理所有品牌"
                    }
                  }
                },
                theme: {
                  description: "小程序主题",
                  ref: "BrandThemeDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    primaryColor: { type: "string", description: "主题色" },
                    secondaryColor: { type: "string", description: "辅助色" }
                  }
                },
                payConfig: {
                  description: "支付配置",
                  ref: "BrandPayConfigDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    mchId: { type: "string", description: "微信商户ID" },
                    certUploaded: {
                      type: "boolean",
                      description: "证书已上传"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                mpConfig: {
                  description: "小程序配置",
                  ref: "BrandMpConfigDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    appId: { type: "string", description: "App Id" },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                wxMessageTemplates: {
                  description: "微信通知模板",
                  ref: "BrandWxConfigMessageTemplates",
                  type: "object",
                  properties: {
                    courseReminder: {
                      type: "string",
                      description: "Course reminder TODO"
                    },
                    bookingCancel: {
                      type: "string",
                      description: "Booking cancel TODO"
                    },
                    bookingQueueStatus: {
                      type: "string",
                      description: "Booking queue status TODO"
                    },
                    trainingResult: {
                      type: "string",
                      description: "Training result TODO"
                    },
                    footprintUploaded: {
                      type: "string",
                      description: "Footprint uploaded TODO"
                    }
                  }
                },
                courseMilestone: {
                  type: "number",
                  reuqired: true,
                  description: "课程里程碑"
                },
                coachCourseMilestone: {
                  type: "number",
                  reuqired: true,
                  description: "教练执教里程碑"
                },
                memberBookingMilestone: {
                  type: "number",
                  reuqired: true,
                  description: "用户打卡里程碑"
                },
                communitySetting: {
                  description: "社区设置",
                  ref: "BrandCommunitySettingDefault",
                  type: "object",
                  properties: {
                    enabled: { type: "number", description: "开启社群" },
                    progressEnabled: {
                      type: "boolean",
                      description: "展示社群统计"
                    },
                    progressType: {
                      type: "string",
                      required: false,
                      enum: ["", "training", "calories"],
                      "x-i18n": {
                        "": "不展示",
                        training: "打卡",
                        calories: "燃脂"
                      },
                      description: "社群统计数据类型"
                    },
                    progressAmplificationFactor: {
                      type: "number",
                      description: "社群统计数据放大系数"
                    },
                    leaderboardEnabled: {
                      type: "boolean",
                      description: "展示排行榜"
                    },
                    leaderboardTypes: {
                      type: "array",
                      items: { type: "string" },
                      description: "展示排行榜类型"
                    },
                    tasLeaderboards: {
                      type: "array",
                      items: { type: "string" },
                      description: "智慧屏榜单展示"
                    },
                    operatingLists: {
                      type: "array",
                      items: { type: "string" },
                      description: "运营列表"
                    },
                    footprintsEnabled: {
                      type: "boolean",
                      description: "展示记忆相册"
                    },
                    footprintMemberCountThreshold: {
                      type: "number",
                      description: "记忆相册展示人数门槛"
                    }
                  }
                },
                expiryAlertEntryThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "团课次数不足阈值（低于/次）"
                },
                expiryAlertEliteEntryThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "私教次数不足阈值（低于/次）"
                },
                expiryAlertPrepayAmountThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "余额不足阈值（低于/元）"
                },
                expiryAlertValidityPeriodThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "账户临期阈值（仅剩/天）"
                },
                expiryDate: {
                  type: "object",
                  nullable: true,
                  description: "授权截止"
                },
                newMemberThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "新用户判定阈值（天）"
                },
                inactiveMemberThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "团课不活跃阈值（天）"
                },
                inactiveEliteMemberThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "私教不活跃阈值（天）"
                },
                userTerms: { type: "string", description: "用户协议" },
                hrmEnabled: {
                  type: "boolean",
                  description: "Hrm enabled TODO"
                },
                grantGateToMemberWithEffectiveAccount: {
                  type: "boolean",
                  description: "开启用户白名单"
                },
                active: { type: "boolean", description: "激活" }
              }
            },
            role: {
              description: "权限组",
              ref: "PermissionRoleList",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                active: { type: "boolean", description: "激活" },
                manageAllBrands: {
                  type: "boolean",
                  description: "管理所有品牌"
                }
              }
            },
            roleId: { type: "number", description: "权限组" },
            coachId: { type: "number", description: "关联教练" },
            manageAllStudios: { type: "boolean", description: "授权所有门店" },
            grantedStudios: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" }
                }
              },
              description: "授权门店",
              ref: "StudioStudioList"
            },
            grantedStudioIds: {
              type: "array",
              items: { type: "number" },
              description: "授权门店"
            },
            manageAllPartners: {
              type: "boolean",
              description: "授权所有合作商户"
            },
            grantedPartnerIds: {
              type: "array",
              items: { type: "number" },
              description: "授权合作商户"
            },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "BrandAdminDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  PermissionRolePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            description: { type: "string", description: "描述" },
            manageAllBrands: { type: "boolean", description: "管理所有品牌" },
            allFeaturesGranted: { type: "boolean", description: "所有权限" },
            active: { type: "boolean", description: "激活" },
            featureIds: {
              type: "array",
              items: { type: "number" },
              description: "权限"
            },
            features: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  text: { type: "string", description: "Text TODO" },
                  fullName: { type: "string", description: "Full name TODO" },
                  featureType: {
                    type: "string",
                    description: "Feature type TODO"
                  },
                  tips: { type: "string", description: "Tips TODO" },
                  parentId: { type: "number", description: "Parent TODO" },
                  position: { type: "string", description: "Position TODO" }
                }
              },
              description: "权限",
              ref: "PermissionFeatureDefault"
            }
          }
        },
        ref: "PermissionRoleDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  BrandBookingSettingDefault: {
    type: "object",
    properties: {
      groupTrainingCoachBook: { type: "boolean", description: "教练代约团课" },
      groupTrainingCoachCheckIn: {
        type: "boolean",
        description: "教练代签到团课"
      },
      bookingQueueEnabled: { type: "boolean", description: "开启满员排位" },
      freeCancelQueuedBooking: {
        type: "boolean",
        description: "排位成功的预约可以随时取消"
      },
      giftBookingEnabled: { type: "boolean", description: "开启赠课" },
      showCourseBookingCount: {
        type: "boolean",
        description: "预约列表预约进度展示"
      },
      showCourseBookings: {
        type: "boolean",
        description: "预约详情预约人展示"
      },
      showCampBookings: {
        type: "boolean",
        description: "训练营详情预约人展示"
      },
      cancellationDeadline: {
        type: "number",
        description: "默认取消限制（小时）"
      }
    }
  },
  MemberMemberEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      userId: { type: "number", description: "用户ID" },
      realName: { type: "string", nullable: true, description: "姓名" },
      phone: { type: "string", nullable: true, description: "手机" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      memberType: { type: "string", description: "会员类型" }
    }
  },
  MemberMemberBirthdayEvent: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      userId: { type: "number", description: "用户ID" },
      realName: { type: "string", nullable: true, description: "姓名" },
      phone: { type: "string", nullable: true, description: "手机" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      memberType: { type: "string", description: "会员类型" },
      parent: {
        description: "家长",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      parentName: { type: "string", description: "家长姓名" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      qrcode: {
        description: "Qrcode TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      }
    }
  },
  MemberMemberDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      userId: { type: "number", description: "用户ID" },
      realName: { type: "string", nullable: true, description: "姓名" },
      phone: { type: "string", nullable: true, description: "手机" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      memberType: { type: "string", description: "会员类型" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      age: { type: "number", description: "年龄" },
      weight: { type: "number", description: "体重" },
      height: { type: "number", description: "Height TODO" },
      maxHr: { type: "number", nullable: true, description: "最大心率" },
      parent: {
        description: "家长",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      profilePhotos: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "形象照",
        ref: "AttachmentDefault"
      },
      joinedHof: { type: "boolean", description: "加入名人堂" },
      joinedHofAt: {
        description: "加入名人堂时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
      hofNotes: { type: "string", description: "名人堂备注" },
      vip: { type: "boolean", description: "vip" },
      active: { type: "boolean", description: "激活" }
    }
  },
  MemberTrainingProfileDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      trainingLevel: { type: "string", description: "运动基础" },
      trainingPurpose: { type: "string", description: "运动目的" },
      traffic: { type: "string", description: "到店方式" },
      channel: { type: "string", description: "来源" },
      primaryStudio: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/StudioStudioList" }],
        description: "常去门店"
      },
      filledAt: {
        description: "填写时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  MemberMemberDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      userId: { type: "number", description: "用户ID" },
      realName: { type: "string", nullable: true, description: "姓名" },
      phone: { type: "string", nullable: true, description: "手机" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      memberType: { type: "string", description: "会员类型" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      age: { type: "number", description: "年龄" },
      weight: { type: "number", description: "体重" },
      height: { type: "number", description: "Height TODO" },
      maxHr: { type: "number", nullable: true, description: "最大心率" },
      parent: { description: "家长" },
      profilePhotos: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "形象照",
        ref: "AttachmentDefault"
      },
      joinedHof: { type: "boolean", description: "加入名人堂" },
      joinedHofAt: {
        description: "加入名人堂时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
      hofNotes: { type: "string", description: "名人堂备注" },
      vip: { type: "boolean", description: "vip" },
      active: { type: "boolean", description: "激活" },
      joinedAt: {
        description: "加入时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      inviter: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MemberMemberEssential" }
        ],
        description: "邀请人"
      },
      coach: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CoachCoachEssential" }
        ],
        description: "责任教练"
      },
      invitationCount: { type: "number", description: "邀请人数" },
      communicationCount: { type: "number", description: "沟通记录数" },
      trainingProfile: {
        description: "新手训练履历",
        ref: "MemberTrainingProfileDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          trainingLevel: { type: "string", description: "运动基础" },
          trainingPurpose: { type: "string", description: "运动目的" },
          traffic: { type: "string", description: "到店方式" },
          channel: { type: "string", description: "来源" },
          primaryStudio: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/StudioStudioList" }
            ],
            description: "常去门店"
          },
          filledAt: {
            description: "填写时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      displayProfilePhoto: { type: "boolean", description: "形象照片" },
      introduction: { type: "string", description: "介绍" }
    }
  },
  MemberMemberForm: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", description: "姓名" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      maxHr: { type: "number", description: "最大心率" },
      weight: { type: "number", description: "体重" },
      height: { type: "number", description: "Height TODO" },
      coachId: { type: "number", description: "责任教练" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      profilePhoto: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "形象照"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      displayProfilePhoto: { type: "boolean", description: "形象照片" },
      introduction: { type: "string", description: "介绍" }
    }
  },
  MemberMemberHofForm: {
    type: "object",
    properties: {
      joinedHof: { type: "boolean", description: "加入名人堂" },
      joinedHofAt: {
        description: "加入名人堂时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      hofNotes: { type: "string", description: "名人堂备注" }
    }
  },
  MemberMemberKidForm: {
    type: "object",
    properties: {
      parentName: { type: "string", description: "家长姓名" },
      parentPhone: { type: "string", description: "家长手机" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", description: "姓名" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      weight: { type: "number", description: "体重" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      }
    }
  },
  MemberMemberCreateForm: {
    type: "object",
    properties: {
      phone: { type: "string", description: "手机" },
      name: { type: "string", description: "昵称" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      }
    }
  },
  MemberMemberInfoForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "昵称" },
      phone: { type: "string", description: "手机" },
      phoneCode: { type: "string", description: "短信验证码" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      weight: { type: "number", description: "体重" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      avatar: {
        description: "头像",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      }
    }
  },
  MemberMemberProfileForm: {
    type: "object",
    properties: {
      trainingLevel: {
        type: "string",
        enum: ["新手上路", "一定基础", "规律运动"],
        description: "运动基础"
      },
      trainingPurpose: {
        type: "string",
        enum: ["改善体质", "减脂需求", "塑形需求", "佛系健身"],
        description: "训练目的"
      },
      traffic: {
        type: "string",
        enum: ["在周边上班，方便到达", "在周边居住，方便到达", "到店不方便"],
        description: "到店方式"
      },
      channel: {
        type: "string",
        enum: ["教练推荐", "朋友推荐", "大众点评", "其他渠道"],
        description: "来源"
      }
    }
  },
  MemberMemberRecoveryForm: {
    type: "object",
    properties: {
      phone: { type: "string", description: "手机" },
      phoneCode: { type: "string", description: "短信验证码" }
    }
  },
  MemberMemberRecoveryPandaForm: {
    type: "object",
    properties: {
      phone: { type: "string", description: "手机" },
      phoneCode: { type: "string", description: "短信验证码" },
      pandaCode: { type: "string", description: "Panda Code" }
    }
  },
  MemberMemberPhoneCodeForm: {
    type: "object",
    properties: { phone: { type: "string", description: "手机" } }
  },
  MemberMemberMemberTag: {
    type: "object",
    properties: {
      name: { type: "string", description: "昵称" },
      count: { type: "number", description: "Count TODO" }
    }
  },
  LeaderboardRankDefault: {
    type: "object",
    properties: {
      member: {
        description: "Member TODO",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      rank: { type: "number", description: "Rank TODO" },
      value: { type: "number", description: "Value TODO" }
    }
  },
  LeaderboardLeaderboardDefault: {
    type: "object",
    properties: {
      name: { type: "string", description: "Name TODO" },
      unit: { type: "string", description: "Unit TODO" },
      tips: { type: "string", description: "Tips TODO" },
      ranks: {
        type: "array",
        items: {
          type: "object",
          properties: {
            member: {
              description: "Member TODO",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            rank: { type: "number", description: "Rank TODO" },
            value: { type: "number", description: "Value TODO" }
          }
        },
        description: "Ranks TODO",
        ref: "LeaderboardRankDefault"
      },
      month: {
        description: "Month TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      year: {
        description: "Year TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      quarter: {
        description: "Quarter TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      maxValue: { type: "number", description: "Max value TODO" }
    }
  },
  CampBookingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      memberId: { type: "number", description: "会员" },
      member: {
        description: "会员",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      workflowState: { type: "string", description: "状态" },
      consumeType: { type: "string", description: "Consume type TODO" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  CampBookingForm: {
    type: "object",
    properties: {
      campId: { type: "number", description: "Camp TODO" },
      memberId: { type: "number", description: "会员" },
      accountId: { type: "number", description: "账户" },
      memberCouponId: { type: "number", description: "代金券" },
      force: { type: "boolean", description: "直接报名" },
      originalPrice: { type: "boolean", description: "是否原价" }
    }
  },
  CampBookingPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            memberId: { type: "number", description: "会员" },
            member: {
              description: "会员",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            workflowState: { type: "string", description: "状态" },
            consumeType: { type: "string", description: "Consume type TODO" },
            createdAt: {
              description: "创建时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "CampBookingDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CampCoursePeriodDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      campId: { type: "number", description: "训练营" },
      weekday: { type: "number", description: "星期" },
      startAt: { type: "string", description: "开始时间" }
    }
  },
  CampCoursePeriodAttributes: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      weekday: { type: "number", description: "星期" },
      startAt: { type: "string", description: "开始时间" },
      _destroy: { type: "number", description: "删除" }
    }
  },
  CampCampEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      className: { type: "string", description: "Class name TODO" },
      fullName: { type: "string", description: "名称" },
      price: { type: "string", description: "价格" },
      bookingsCount: { type: "number", description: "最低报名人数" },
      capacity: { type: "number", description: "容量" },
      courseCount: { type: "number", description: "课程数" },
      finishedCourseCount: { type: "number", description: "已结束课程" },
      startMemberCount: { type: "number", description: "开营人数" },
      studioName: { type: "string", description: "门店" },
      zoneName: { type: "string", description: "场地" },
      coachId: { type: "number", description: "教练" },
      primaryCoach: {
        description: "主教",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      assistantCoachIds: {
        type: "array",
        items: { type: "number" },
        description: "助教"
      },
      joinMidway: { type: "boolean", description: "可否插班" },
      active: { type: "boolean", description: "激活" },
      workflowState: { type: "string", description: "状态" },
      startDate: {
        description: "开营日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      bookEndDate: {
        description: "报名截止日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      coursePeriods: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            campId: { type: "number", description: "训练营" },
            weekday: { type: "number", description: "星期" },
            startAt: { type: "string", description: "开始时间" }
          }
        },
        description: "课程排期",
        ref: "CampCoursePeriodDefault"
      },
      coursePeriodsAttributes: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            campId: { type: "number", description: "训练营" },
            weekday: { type: "number", description: "星期" },
            startAt: { type: "string", description: "开始时间" }
          }
        },
        description: "课程排期",
        ref: "CampCoursePeriodDefault"
      }
    }
  },
  CampCampDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      className: { type: "string", description: "Class name TODO" },
      fullName: { type: "string", description: "名称" },
      price: { type: "string", description: "价格" },
      bookingsCount: { type: "number", description: "最低报名人数" },
      capacity: { type: "number", description: "容量" },
      courseCount: { type: "number", description: "课程数" },
      finishedCourseCount: { type: "number", description: "已结束课程" },
      startMemberCount: { type: "number", description: "开营人数" },
      studioName: { type: "string", description: "门店" },
      zoneName: { type: "string", description: "场地" },
      coachId: { type: "number", description: "教练" },
      primaryCoach: { description: "主教" },
      assistantCoachIds: {
        type: "array",
        items: { type: "number" },
        description: "助教"
      },
      joinMidway: { type: "boolean", description: "可否插班" },
      active: { type: "boolean", description: "激活" },
      workflowState: { type: "string", description: "状态" },
      startDate: {
        description: "开营日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      bookEndDate: {
        description: "报名截止日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      coursePeriods: { description: "课程排期" },
      coursePeriodsAttributes: { description: "课程排期" },
      studioId: { type: "number", description: "门店" },
      zoneId: { type: "number", description: "场地" },
      productId: { type: "number", description: "训练产品" },
      banner: {
        description: "头图",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      note: { type: "string", nullable: true, description: "备注" },
      autoPostpone: { type: "boolean", description: "人数未达标自动延期" },
      intro: { type: "string", nullable: true, description: "介绍" }
    }
  },
  CampCampForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      price: { type: "string", required: true, description: "价格" },
      capacity: { type: "number", required: true, description: "容量" },
      startMemberCount: { type: "number", description: "开营人数" },
      courseCount: { type: "number", description: "课程数" },
      studioId: { type: "number", description: "门店" },
      zoneId: { type: "number", description: "场地" },
      primaryCoachId: { type: "number", description: "主教" },
      assistantCoachIds: {
        type: "array",
        items: { type: "number" },
        description: "助教"
      },
      productId: { type: "number", description: "训练产品" },
      joinMidway: { type: "boolean", description: "可否插班" },
      active: { type: "boolean", description: "激活" },
      startDate: {
        description: "开营日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      bookEndDate: {
        description: "报名截止日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      banner: {
        description: "头图",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      note: { type: "string", nullable: true, description: "备注" },
      intro: { type: "string", nullable: true, description: "介绍" },
      autoPostpone: { type: "boolean", description: "人数未达标自动延期" },
      coursePeriodsAttributes: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            weekday: { type: "number", description: "星期" },
            startAt: { type: "string", description: "开始时间" },
            _destroy: { type: "number", description: "删除" }
          }
        },
        description: "课程排期",
        ref: "CampCoursePeriodAttributes"
      }
    }
  },
  CampCampDuplicateForm: {
    type: "object",
    properties: {
      startDate: {
        description: "开营日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      }
    }
  },
  CampCampUpdateCoursesForm: {
    type: "object",
    properties: { scope: { type: "string", description: "刷新范围" } }
  },
  CampCampPostponeForm: {
    type: "object",
    properties: {
      startDate: {
        description: "开营日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      }
    }
  },
  CampCampPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            className: { type: "string", description: "Class name TODO" },
            fullName: { type: "string", description: "名称" },
            price: { type: "string", description: "价格" },
            bookingsCount: { type: "number", description: "最低报名人数" },
            capacity: { type: "number", description: "容量" },
            courseCount: { type: "number", description: "课程数" },
            finishedCourseCount: { type: "number", description: "已结束课程" },
            startMemberCount: { type: "number", description: "开营人数" },
            studioName: { type: "string", description: "门店" },
            zoneName: { type: "string", description: "场地" },
            coachId: { type: "number", description: "教练" },
            primaryCoach: { description: "主教" },
            assistantCoachIds: {
              type: "array",
              items: { type: "number" },
              description: "助教"
            },
            joinMidway: { type: "boolean", description: "可否插班" },
            active: { type: "boolean", description: "激活" },
            workflowState: { type: "string", description: "状态" },
            startDate: {
              description: "开营日期",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            bookEndDate: {
              description: "报名截止日期",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            coursePeriods: { description: "课程排期" },
            coursePeriodsAttributes: { description: "课程排期" },
            studioId: { type: "number", description: "门店" },
            zoneId: { type: "number", description: "场地" },
            productId: { type: "number", description: "训练产品" },
            banner: {
              description: "头图",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            note: { type: "string", nullable: true, description: "备注" },
            autoPostpone: {
              type: "boolean",
              description: "人数未达标自动延期"
            },
            intro: { type: "string", nullable: true, description: "介绍" }
          }
        },
        ref: "CampCampDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CampCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "英文课程名称" },
      primaryCoach: {
        description: "教练",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      endTime: {
        description: "结束时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      productId: { type: "number", description: "Product TODO" },
      workoutOutlineId: { type: "number", description: "Workout outline TODO" },
      workflowState: { type: "string", description: "Workflow state TODO" }
    }
  },
  CouponCouponList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      validityPeriod: { type: "number", description: "有效期" }
    }
  },
  CourseProductList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      brandId: { type: "number", description: "品牌" },
      name: { type: "string", description: "课程名称" },
      enName: { type: "string", description: "英文名称" },
      usageCn: { type: "string", description: "Usage cn TODO" },
      forKid: { type: "boolean", description: "For kid TODO" },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "Stage list TODO"
      },
      provider: { type: "string", description: "产品品牌" },
      expiryDate: {
        description: "授权截止",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      category: {
        type: "string",
        required: false,
        enum: ["brand", "platform"],
        "x-i18n": { brand: "自研产品", platform: "授权产品" },
        description: "类型"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "类型"
      },
      scheduleRule: { type: "string", description: "Schedule rule TODO" },
      entryPrice: { type: "string", description: "Entry price TODO" },
      active: { type: "boolean", description: "激活" }
    }
  },
  CourseProductDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      brandId: { type: "number", description: "品牌" },
      name: { type: "string", description: "课程名称" },
      enName: { type: "string", description: "英文名称" },
      usageCn: { type: "string", description: "Usage cn TODO" },
      forKid: { type: "boolean", description: "For kid TODO" },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "Stage list TODO"
      },
      provider: { type: "string", description: "产品品牌" },
      expiryDate: {
        description: "授权截止",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      category: {
        type: "string",
        required: false,
        enum: ["brand", "platform"],
        "x-i18n": { brand: "自研产品", platform: "授权产品" },
        description: "类型"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "类型"
      },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "Schedule rule TODO"
      },
      entryPrice: { type: "string", description: "Entry price TODO" },
      active: { type: "boolean", description: "激活" },
      price: { type: "string", description: "价格" },
      prepayPrice: { type: "string", description: "储值卡支付价格" },
      workoutId: { type: "number", description: "训练课程" },
      duration: { type: "number", description: "课程时长" },
      courseFee: { type: "string", description: "主教课时费" },
      assistantCourseFee: { type: "string", description: "助教课时费" },
      trialCourseFee: { type: "string", description: "实习课时费" },
      intro: { type: "string", nullable: true, description: "课程介绍" },
      groundType: {
        type: "string",
        required: false,
        enum: ["indoor", "outdoor"],
        "x-i18n": { indoor: "室内", outdoor: "户外" },
        description: "Ground type TODO"
      },
      academy: { type: "boolean", description: "提供培训" },
      note: { type: "string", description: "授权备注" },
      live: {
        description: "课程Live",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentDefault"
      },
      coachProductId: { type: "number", description: "Coach product TODO" },
      tagList: {
        type: "array",
        items: { type: "string", enum: [] },
        description: "标签"
      },
      paymentMethods: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["wechat", "prepay_card", "membership_card", "coupon"],
          "x-i18n": {
            wechat: "微信支付",
            prepay_card: "储值账户",
            membership_card: "会员卡",
            coupon: "代金券"
          }
        },
        description: "支付方式"
      },
      hasConfirmCourseThreshold: {
        type: "boolean",
        description: "单独设置开课限制"
      },
      confirmCourseThreshold: { type: "number", description: "最低开课人数" },
      confirmCourseDeadline: { type: "number", description: "开课确认时间" },
      defaultCaloriesTarget: { type: "number", description: "默认卡路里目标" },
      tasType: {
        type: "string",
        required: false,
        enum: ["tas", "hrm", "countdown"],
        "x-i18n": { tas: "内容辅助", hrm: "心率辅助", countdown: "时间辅助" },
        description: "辅助系统配置"
      },
      multiScreenVersions: {
        type: "array",
        items: { type: "number" },
        description: "多屏幕适配"
      },
      displayed: { type: "boolean", description: "Displayed TODO" },
      useHrm: { type: "boolean", description: "使用心率" },
      position: { type: "number", description: "排序" }
    }
  },
  CourseProductDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      brandId: { type: "number", description: "品牌" },
      name: { type: "string", description: "课程名称" },
      enName: { type: "string", description: "英文名称" },
      usageCn: { type: "string", description: "Usage cn TODO" },
      forKid: { type: "boolean", description: "For kid TODO" },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "Stage list TODO"
      },
      provider: { type: "string", description: "产品品牌" },
      expiryDate: {
        description: "授权截止",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      category: {
        type: "string",
        required: false,
        enum: ["brand", "platform"],
        "x-i18n": { brand: "自研产品", platform: "授权产品" },
        description: "类型"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "类型"
      },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "Schedule rule TODO"
      },
      entryPrice: { type: "string", description: "Entry price TODO" },
      active: { type: "boolean", description: "激活" },
      price: { type: "string", description: "价格" },
      prepayPrice: { type: "string", description: "储值卡支付价格" },
      workoutId: { type: "number", description: "训练课程" },
      duration: { type: "number", description: "课程时长" },
      courseFee: { type: "string", description: "主教课时费" },
      assistantCourseFee: { type: "string", description: "助教课时费" },
      trialCourseFee: { type: "string", description: "实习课时费" },
      intro: { type: "string", nullable: true, description: "课程介绍" },
      groundType: {
        type: "string",
        required: false,
        enum: ["indoor", "outdoor"],
        "x-i18n": { indoor: "室内", outdoor: "户外" },
        description: "Ground type TODO"
      },
      academy: { type: "boolean", description: "提供培训" },
      note: { type: "string", description: "授权备注" },
      live: {
        description: "课程Live",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentDefault"
      },
      coachProductId: { type: "number", description: "Coach product TODO" },
      tagList: {
        type: "array",
        items: { type: "string", enum: [] },
        description: "标签"
      },
      paymentMethods: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["wechat", "prepay_card", "membership_card", "coupon"],
          "x-i18n": {
            wechat: "微信支付",
            prepay_card: "储值账户",
            membership_card: "会员卡",
            coupon: "代金券"
          }
        },
        description: "支付方式"
      },
      hasConfirmCourseThreshold: {
        type: "boolean",
        description: "单独设置开课限制"
      },
      confirmCourseThreshold: { type: "number", description: "最低开课人数" },
      confirmCourseDeadline: { type: "number", description: "开课确认时间" },
      defaultCaloriesTarget: { type: "number", description: "默认卡路里目标" },
      tasType: {
        type: "string",
        required: false,
        enum: ["tas", "hrm", "countdown"],
        "x-i18n": { tas: "内容辅助", hrm: "心率辅助", countdown: "时间辅助" },
        description: "辅助系统配置"
      },
      multiScreenVersions: {
        type: "array",
        items: { type: "number" },
        description: "多屏幕适配"
      },
      displayed: { type: "boolean", description: "Displayed TODO" },
      useHrm: { type: "boolean", description: "使用心率" },
      position: { type: "number", description: "排序" },
      platformProductEditable: {
        type: "boolean",
        description: "Platform product editable TODO"
      },
      coachingMode: { type: "boolean", description: "Coaching mode TODO" },
      coachingModeTemplate: {
        type: "boolean",
        description: "Coaching mode template TODO"
      },
      enableWod: { type: "boolean", description: "今日训练" },
      showWodOnCourseDate: { type: "boolean", description: "当日展示" },
      studioIds: {
        type: "array",
        items: { type: "number" },
        description: "Studio ids TODO"
      }
    }
  },
  CourseProductPlatformForm: {
    type: "object",
    properties: {
      workoutId: { type: "number", description: "训练课程" },
      expiryDate: { type: "string", description: "授权截止" },
      displayed: { type: "boolean", description: "Displayed TODO" },
      position: { type: "number", description: "排序" },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            signedId: { type: "string", description: "Signed ID" },
            remoteUrl: {
              type: "string",
              nullable: true,
              description: "文件远程 URL"
            },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentForm"
      },
      live: {
        description: "课程Live",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      intro: { type: "string", description: "课程介绍" },
      tagList: {
        type: "array",
        items: { type: "string", enum: [] },
        description: "标签"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  CourseProductBrandForm: {
    type: "object",
    properties: {
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "类型"
      },
      groundType: {
        type: "string",
        required: false,
        enum: ["indoor", "outdoor"],
        "x-i18n": { indoor: "室内", outdoor: "户外" },
        description: "Ground type TODO"
      },
      workoutId: { type: "number", description: "训练课程" },
      name: { type: "string", description: "课程名称" },
      enName: { type: "string", description: "英文名称" },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "Schedule rule TODO"
      },
      price: { type: "string", description: "价格" },
      duration: { type: "number", description: "课程时长" },
      expiryDate: { type: "string", description: "授权截止" },
      courseFee: { type: "string", description: "主教课时费" },
      assistantCourseFee: { type: "string", description: "助教课时费" },
      trialCourseFee: { type: "string", description: "实习课时费" },
      intro: { type: "string", description: "课程介绍" },
      academy: { type: "boolean", description: "提供培训" },
      tasType: {
        type: "string",
        required: false,
        enum: ["tas", "hrm", "countdown"],
        "x-i18n": { tas: "内容辅助", hrm: "心率辅助", countdown: "时间辅助" },
        description: "辅助系统配置"
      },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            signedId: { type: "string", description: "Signed ID" },
            remoteUrl: {
              type: "string",
              nullable: true,
              description: "文件远程 URL"
            },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentForm"
      },
      live: {
        description: "课程Live",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "Stage list TODO"
      },
      enableWod: { type: "boolean", description: "今日训练" },
      showWodOnCourseDate: { type: "boolean", description: "当日展示" },
      paymentMethods: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["wechat", "prepay_card", "membership_card", "coupon"],
          "x-i18n": {
            wechat: "微信支付",
            prepay_card: "储值账户",
            membership_card: "会员卡",
            coupon: "代金券"
          }
        },
        description: "支付方式"
      },
      tagList: {
        type: "array",
        items: { type: "string", enum: [] },
        description: "标签"
      },
      defaultCaloriesTarget: { type: "number", description: "默认卡路里目标" },
      provider: {
        type: "string",
        required: false,
        enum: ["莱美", "其他"],
        "x-i18n": { 莱美: "莱美", 其他: "其他" },
        description: "产品品牌"
      },
      displayed: { type: "boolean", description: "Displayed TODO" },
      position: { type: "number", description: "排序" },
      active: { type: "boolean", description: "激活" }
    }
  },
  CourseProductOperating: {
    type: "object",
    properties: {
      brandName: { type: "string", description: "品牌名称" },
      contactPhone: { type: "string", description: "负责人电话" },
      name: { type: "string", description: "课程名称" },
      expiryDate: {
        description: "授权截止",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      }
    }
  },
  CourseProductCoachProductOperating: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      brandId: { type: "number", description: "品牌" },
      name: { type: "string", description: "课程名称" },
      enName: { type: "string", description: "英文名称" },
      category: {
        type: "string",
        required: false,
        enum: ["brand", "platform"],
        "x-i18n": { brand: "自研产品", platform: "授权产品" },
        description: "类型"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "类型"
      },
      scheduleRule: { type: "string", description: "Schedule rule TODO" },
      workoutId: { type: "number", description: "训练课程" },
      duration: { type: "number", description: "课程时长" },
      expiryDate: {
        description: "授权截止",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      outlinePermissionsCount: { type: "number", description: "授权套路" },
      coachProductId: { type: "number", description: "Coach product TODO" },
      provider: { type: "string", description: "产品品牌" },
      tasType: {
        type: "string",
        required: false,
        enum: ["tas", "hrm", "countdown"],
        "x-i18n": { tas: "内容辅助", hrm: "心率辅助", countdown: "时间辅助" },
        description: "辅助系统配置"
      },
      active: { type: "boolean", description: "激活" },
      position: { type: "number", description: "排序" }
    }
  },
  CouponCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      value: { type: "string", description: "面值" },
      fullDiscount: { type: "boolean", description: "全额代金券" },
      availableTimeRange: {
        type: "array",
        items: { type: "string" },
        description: "可用时段"
      },
      validityPeriod: { type: "number", description: "有效期" },
      usageThreshold: { type: "string", description: "使用门槛" },
      applyToAllStudio: { type: "boolean", description: "所有门店可用" },
      studios: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            fullName: { type: "string", description: "门店全称" },
            shortName: { type: "string", description: "门店名称" }
          }
        },
        description: "应用门店",
        ref: "StudioStudioList"
      },
      applyToAllProduct: { type: "boolean", description: "所有训练产品可用" },
      products: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            brandId: { type: "number", description: "品牌" },
            name: { type: "string", description: "课程名称" },
            enName: { type: "string", description: "英文名称" },
            usageCn: { type: "string", description: "Usage cn TODO" },
            forKid: { type: "boolean", description: "For kid TODO" },
            stageList: {
              type: "array",
              items: { type: "string" },
              description: "Stage list TODO"
            },
            provider: { type: "string", description: "产品品牌" },
            expiryDate: {
              description: "授权截止",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            category: {
              type: "string",
              required: false,
              enum: ["brand", "platform"],
              "x-i18n": { brand: "自研产品", platform: "授权产品" },
              description: "类型"
            },
            productType: {
              type: "string",
              required: false,
              enum: [
                "group_training",
                "elite_training",
                "camp",
                "free_training"
              ],
              "x-i18n": {
                group_training: "团课",
                elite_training: "私教",
                camp: "训练营",
                free_training: "自由训练"
              },
              description: "类型"
            },
            scheduleRule: { type: "string", description: "Schedule rule TODO" },
            entryPrice: { type: "string", description: "Entry price TODO" },
            active: { type: "boolean", description: "激活" }
          }
        },
        description: "应用训练产品",
        ref: "CourseProductList"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  CouponCouponForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      value: { type: "number", description: "面值" },
      fullDiscount: { type: "boolean", description: "全额代金券" },
      availableTimeRange: {
        type: "array",
        items: { type: "string" },
        description: "可用时段"
      },
      validityPeriod: { type: "number", description: "有效期" },
      usageThreshold: { type: "number", description: "使用门槛" },
      applyToAllStudio: { type: "boolean", description: "所有门店可用" },
      studioIds: {
        type: "array",
        items: { type: "number" },
        description: "应用门店"
      },
      applyToAllProduct: { type: "boolean", description: "所有训练产品可用" },
      productIds: {
        type: "array",
        items: { type: "number" },
        description: "应用训练产品"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  CouponCouponExtendValidityPeriodForm: {
    type: "object",
    properties: {
      memberId: { type: "number", description: "Member TODO" },
      couponId: { type: "number", description: "Coupon TODO" },
      validityPeriod: { type: "number", required: true, description: "有效期" }
    }
  },
  CouponCouponRevokeForm: {
    type: "object",
    properties: { couponId: { type: "number", description: "Coupon TODO" } }
  },
  MobileCouponMemberCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      value: { type: "string", description: "面额" },
      effectiveDate: { type: "object", description: "可用日期" },
      expiryDate: { type: "object", description: "过期日期" },
      remainingValidityPeriod: { type: "number", description: "剩余有效期" },
      coupon: {
        description: "代金券",
        ref: "CouponCouponDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          value: { type: "string", description: "面值" },
          fullDiscount: { type: "boolean", description: "全额代金券" },
          availableTimeRange: {
            type: "array",
            items: { type: "string" },
            description: "可用时段"
          },
          validityPeriod: { type: "number", description: "有效期" },
          usageThreshold: { type: "string", description: "使用门槛" },
          applyToAllStudio: { type: "boolean", description: "所有门店可用" },
          studios: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" }
              }
            },
            description: "应用门店",
            ref: "StudioStudioList"
          },
          applyToAllProduct: {
            type: "boolean",
            description: "所有训练产品可用"
          },
          products: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" }
              }
            },
            description: "应用训练产品",
            ref: "CourseProductList"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      available: { type: "boolean", description: "可用" }
    }
  },
  CoachCategoryPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" }
          }
        },
        ref: "CoachCategoryDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CoachCoachPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" },
            user: {
              anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
              description: "用户"
            },
            userId: { type: "number", description: "用户" },
            profilePhoto: {
              description: "形象照",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            wxQrCode: {
              description: "微信二维码",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            level: {
              description: "等级",
              ref: "CoachLevelDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                category: {
                  description: "类型",
                  ref: "CoachCategoryDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" }
                  }
                },
                categoryId: { type: "number", description: "类型" }
              }
            },
            dateOfBirth: {
              description: "生日",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            joinedAt: {
              description: "入职时间",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            primaryStudio: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/StudioStudioList" }
              ],
              description: "绑定门店"
            },
            scopeOfAccess: {
              type: "string",
              required: true,
              enum: ["mine", "all"],
              "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
              description: "查看排课范围"
            },
            introduction: { type: "string", description: "介绍" },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "教练风格"
            },
            certificationList: {
              type: "array",
              items: { type: "string" },
              description: "技能认证"
            },
            styleList: {
              type: "array",
              items: { type: "string" },
              description: "执教风格"
            },
            resumeDisplay: { type: "boolean", description: "执教数据展示" },
            eliteTrainingCapacity: {
              type: "number",
              description: "私教人数上限"
            },
            primaryStudioId: { type: "number", description: "绑定门店" },
            applyToAllProduct: {
              type: "boolean",
              description: "授权所有训练产品"
            },
            levelId: { type: "number", description: "等级" },
            createdAt: {
              description: "加入时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        description: "数据",
        ref: "CoachCoachDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasWorkoutOutlineList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      duration: { type: "number", description: "Duration TODO" },
      instruction: { type: "string", description: "说明" },
      packageSerial: { type: "string", description: "Package serial TODO" },
      packageName: { type: "string", description: "Package name TODO" },
      note: { type: "object", description: "名称" }
    }
  },
  TasWorkoutOutlineBrief: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" }
    }
  },
  TasWorkoutVersionEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "版本名称" },
      courseDuration: { type: "number", description: "课程时长" },
      workflowState: { type: "string", description: "状态" },
      note: { type: "object", description: "备注" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  TasWorkoutVersionDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "版本名称" },
      courseDuration: { type: "number", description: "课程时长" },
      workflowState: { type: "string", description: "状态" },
      note: { type: "object", description: "备注" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      groupCount: { type: "number", description: "分组组数" },
      groupSize: { type: "number", description: "每组人数" },
      groupRule: { type: "string", description: "分组规则" },
      desc: { type: "object", description: "描述" },
      logoUrl: { type: "string", description: "Logo URL" },
      introImagesDuration: { type: "number", description: "介绍图展示时长" },
      hasResultStep: { type: "boolean", description: "是否展示结果页" },
      resultImage: { type: "string", description: "结果图" },
      showCalories: { type: "boolean", description: "展示卡路里" },
      appropriateCrowd: { type: "object", description: "适合人群" },
      courseFeature: { type: "object", description: "课程特色" },
      workoutName: { type: "string", description: "课程产品名称" }
    }
  },
  TasWorkoutVersionTas: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "版本名称" },
      showCalories: { type: "boolean", description: "展示卡路里" },
      desc: { type: "object", description: "描述" },
      logoUrl: { type: "string", description: "Logo URL" },
      courseDuration: { type: "number", description: "课程时长" },
      trainingDuration: { type: "number", description: "训练时长" },
      notUseHrm: { type: "boolean", description: "不使用心率系统" }
    }
  },
  TasWorkoutVersionForm: {
    type: "object",
    properties: {
      rdCoachId: { type: "number", description: "研发教练" },
      courseDuration: { type: "number", description: "课程时长" },
      note: { type: "object", description: "备注" },
      desc: { type: "object", description: "描述" },
      appropriateCrowd: { type: "object", description: "适合人群" },
      groupCount: { type: "number", description: "分组组数" },
      groupSize: { type: "number", description: "每组人数" },
      groupRule: { type: "string", description: "分组规则" },
      hasResultStep: { type: "boolean", description: "是否展示结果页" }
    }
  },
  TasWorkoutOutlineEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "状态"
      },
      brandId: { type: "number", description: "Brand TODO" },
      circuitOutline: { type: "boolean", description: "循环训练" },
      workoutVersion: {
        description: "课程版本",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      active: { type: "boolean", description: "Active TODO" },
      note: { type: "object", description: "名称" }
    }
  },
  TasPracticeDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      instruction: { type: "object", description: "Instruction TODO" },
      regressInstruction: {
        type: "object",
        description: "Regress instruction TODO"
      },
      screenInstruction: {
        type: "object",
        description: "Screen instruction TODO"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      lor: { type: "string", description: "Lor TODO" },
      exercisePattern: { type: "number", description: "Exercise pattern TODO" },
      guided: { type: "boolean", description: "Guided TODO" },
      essential: { type: "boolean", description: "Essential TODO" }
    }
  },
  TasPracticeForm: {
    type: "object",
    properties: {
      exercisePattern: { type: "number", description: "Exercise pattern TODO" },
      exerciseId: { type: "number", description: "Exercise TODO" },
      essential: { type: "boolean", description: "Essential TODO" },
      repeat: { type: "number", description: "Repeat TODO" },
      lor: { type: "string", description: "Lor TODO" },
      instruction: { type: "object", description: "Instruction TODO" }
    }
  },
  TasWorkoutOutlineBrandList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "状态"
      },
      brandId: { type: "number", description: "Brand TODO" },
      circuitOutline: { type: "boolean", description: "循环训练" },
      workoutVersion: {
        description: "课程版本",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      active: { type: "boolean", description: "Active TODO" },
      firstBigCircleId: {
        type: "number",
        description: "First big circle TODO"
      },
      firstScreenId: { type: "number", description: "First screen TODO" },
      firstPractice: {
        description: "First practice TODO",
        ref: "TasPracticeDetail",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          instruction: { type: "object", description: "Instruction TODO" },
          regressInstruction: {
            type: "object",
            description: "Regress instruction TODO"
          },
          screenInstruction: {
            type: "object",
            description: "Screen instruction TODO"
          },
          repeat: { type: "number", description: "Repeat TODO" },
          lor: { type: "string", description: "Lor TODO" },
          exercisePattern: {
            type: "number",
            description: "Exercise pattern TODO"
          },
          guided: { type: "boolean", description: "Guided TODO" },
          essential: { type: "boolean", description: "Essential TODO" }
        }
      },
      note: { type: "object", description: "名称" }
    }
  },
  TasWorkoutOutlineDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "状态"
      },
      brandId: { type: "number", description: "Brand TODO" },
      circuitOutline: { type: "boolean", description: "循环训练" },
      workoutVersion: {
        description: "课程版本",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      active: { type: "boolean", description: "Active TODO" },
      note: { type: "object", description: "名称" },
      bgmFade: { type: "boolean", description: "音乐淡入淡出" },
      playlistStrategy: {
        type: "string",
        description: "Playlist strategy TODO"
      },
      instruction: { type: "object", description: "说明" },
      workoutVersionId: { type: "number", description: "Workout version TODO" },
      packageId: { type: "number", description: "Package TODO" }
    }
  },
  TasStepDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      duration: { type: "number", description: "时长" },
      forecastDuration: {
        type: "number",
        description: "Forecast duration TODO"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      type: { type: "string", description: "Type TODO" },
      zhStepType: { type: "string", description: "Zh step type TODO" },
      beep: { type: "boolean", description: "Beep TODO" },
      playCommand: { type: "boolean", description: "Play command TODO" },
      showNextExercise: {
        type: "boolean",
        description: "Show next exercise TODO"
      },
      volume: { type: "number", nullable: true, description: "Volume TODO" },
      position: { type: "number", description: "排序" }
    }
  },
  TasContentImageDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      file: {
        description: "File TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      position: { type: "number", description: "排序" }
    }
  },
  TasContentImageForm: {
    type: "object",
    properties: {
      file: {
        description: "File TODO",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      position: { type: "number", description: "排序" }
    }
  },
  TasEquipmentDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "En name TODO" },
      code: { type: "string", description: "Code TODO" }
    }
  },
  TasEquipmentDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "En name TODO" },
      code: { type: "string", description: "Code TODO" },
      image: {
        description: "图片",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasEquipmentForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "En name TODO" },
      code: { type: "string", description: "Code TODO" },
      image: {
        description: "图片",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasEquipmentTas: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" }
    }
  },
  PlatformTasExerciseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      name: { type: "string", description: "名称" },
      brandId: { type: "number", description: "Brand TODO" },
      mp4: {
        description: "视频",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coverPicture: {
        description: "封面图",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      presentPicture: {
        description: "缩略图",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      command: {
        description: "口令",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      positionList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: [
            "travelling",
            "standing",
            "sitting",
            "kneeling",
            "prone",
            "supine",
            "side lying",
            "support hold",
            "jump",
            "hinged",
            "Quadruped",
            "other"
          ],
          "x-i18n": {
            travelling: "移动",
            standing: "站姿",
            sitting: "坐姿",
            kneeling: "跪姿",
            prone: "俯卧",
            supine: "仰卧",
            "side lying": "侧卧",
            "support hold": "支撑",
            jump: "跳跃",
            hinged: "髋屈伸",
            Quadruped: "四足支撑",
            other: "其他"
          }
        },
        description: "姿态"
      },
      primaryEquipment: {
        description: "主器械",
        ref: "TasEquipmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          enName: { type: "string", description: "En name TODO" },
          code: { type: "string", description: "Code TODO" }
        }
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  PlatformTasExerciseDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      name: { type: "string", description: "名称" },
      brandId: { type: "number", description: "Brand TODO" },
      mp4: {
        description: "视频",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coverPicture: {
        description: "封面图",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      presentPicture: {
        description: "缩略图",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      command: {
        description: "口令",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      positionList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: [
            "travelling",
            "standing",
            "sitting",
            "kneeling",
            "prone",
            "supine",
            "side lying",
            "support hold",
            "jump",
            "hinged",
            "Quadruped",
            "other"
          ],
          "x-i18n": {
            travelling: "移动",
            standing: "站姿",
            sitting: "坐姿",
            kneeling: "跪姿",
            prone: "俯卧",
            supine: "仰卧",
            "side lying": "侧卧",
            "support hold": "支撑",
            jump: "跳跃",
            hinged: "髋屈伸",
            Quadruped: "四足支撑",
            other: "其他"
          }
        },
        description: "姿态"
      },
      primaryEquipment: { description: "主器械" },
      active: { type: "boolean", description: "激活" },
      enName: { type: "string", description: "英文名称" },
      bodyWeight: { type: "boolean", description: "自重" },
      secondaryEquipment: {
        description: "Secondary equipment TODO",
        ref: "TasEquipmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          enName: { type: "string", description: "En name TODO" },
          code: { type: "string", description: "Code TODO" }
        }
      },
      tips: { type: "object", description: "备注" },
      cnCoachingCues: { type: "object", description: "中文教案" },
      coachingCues: { type: "object", description: "教案" },
      shot: { type: "string", description: "拍摄视角" },
      notGuided: { type: "boolean", description: "不纳入指导" }
    }
  },
  PlatformTasExerciseForm: {
    type: "object",
    properties: {
      serial: { type: "string", description: "编号" },
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "英文名称" },
      mp4: {
        description: "视频",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coverPicture: {
        description: "封面图",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      presentPicture: {
        description: "缩略图",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      command: {
        description: "口令",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      positionList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: [
            "travelling",
            "standing",
            "sitting",
            "kneeling",
            "prone",
            "supine",
            "side lying",
            "support hold",
            "jump",
            "hinged",
            "Quadruped",
            "other"
          ],
          "x-i18n": {
            travelling: "移动",
            standing: "站姿",
            sitting: "坐姿",
            kneeling: "跪姿",
            prone: "俯卧",
            supine: "仰卧",
            "side lying": "侧卧",
            "support hold": "支撑",
            jump: "跳跃",
            hinged: "髋屈伸",
            Quadruped: "四足支撑",
            other: "其他"
          }
        },
        description: "姿态"
      },
      primaryEquipmentId: { type: "number", description: "主器械" },
      bodyWeight: { type: "boolean", description: "自重" },
      coachingCues: { type: "object", description: "教案" },
      cnCoachingCues: { type: "object", description: "中文教案" },
      tips: { type: "object", description: "备注" },
      notGuided: { type: "boolean", description: "不纳入指导" },
      active: { type: "boolean", description: "激活" }
    }
  },
  PlatformTasPracticeDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      instruction: { type: "object", description: "Instruction TODO" },
      regressInstruction: {
        type: "object",
        description: "Regress instruction TODO"
      },
      screenInstruction: {
        type: "object",
        description: "Screen instruction TODO"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      lor: { type: "string", description: "Lor TODO" },
      exercisePattern: { type: "number", description: "Exercise pattern TODO" },
      exercise: {
        description: "Exercise TODO",
        ref: "PlatformTasExerciseDetail",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          serial: { type: "string", description: "编号" },
          name: { type: "string", description: "名称" },
          brandId: { type: "number", description: "Brand TODO" },
          mp4: {
            description: "视频",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          coverPicture: {
            description: "封面图",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          presentPicture: {
            description: "缩略图",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          command: {
            description: "口令",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          positionList: {
            type: "array",
            required: false,
            items: {
              type: "string",
              enum: [
                "travelling",
                "standing",
                "sitting",
                "kneeling",
                "prone",
                "supine",
                "side lying",
                "support hold",
                "jump",
                "hinged",
                "Quadruped",
                "other"
              ],
              "x-i18n": {
                travelling: "移动",
                standing: "站姿",
                sitting: "坐姿",
                kneeling: "跪姿",
                prone: "俯卧",
                supine: "仰卧",
                "side lying": "侧卧",
                "support hold": "支撑",
                jump: "跳跃",
                hinged: "髋屈伸",
                Quadruped: "四足支撑",
                other: "其他"
              }
            },
            description: "姿态"
          },
          primaryEquipment: { description: "主器械" },
          active: { type: "boolean", description: "激活" },
          enName: { type: "string", description: "英文名称" },
          bodyWeight: { type: "boolean", description: "自重" },
          secondaryEquipment: {
            description: "Secondary equipment TODO",
            ref: "TasEquipmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              enName: { type: "string", description: "En name TODO" },
              code: { type: "string", description: "Code TODO" }
            }
          },
          tips: { type: "object", description: "备注" },
          cnCoachingCues: { type: "object", description: "中文教案" },
          coachingCues: { type: "object", description: "教案" },
          shot: { type: "string", description: "拍摄视角" },
          notGuided: { type: "boolean", description: "不纳入指导" }
        }
      },
      guided: { type: "boolean", description: "Guided TODO" },
      stepType: { type: "string", description: "Step type TODO" },
      essential: { type: "boolean", description: "Essential TODO" }
    }
  },
  PlatformTasPracticeForm: {
    type: "object",
    properties: {
      exercisePattern: { type: "number", description: "Exercise pattern TODO" },
      exerciseId: { type: "number", description: "Exercise TODO" },
      essential: { type: "boolean", description: "Essential TODO" },
      screenInstruction: {
        type: "object",
        description: "Screen instruction TODO"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      lor: { type: "string", description: "Lor TODO" },
      instruction: { type: "object", description: "Instruction TODO" }
    }
  },
  PlatformTasScreenDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      stationTransitionDuration: {
        type: "number",
        nullable: true,
        description: "Station transition duration TODO"
      },
      blockTypeName: {
        type: "string",
        nullable: true,
        description: "Block type name TODO"
      },
      blockTypeId: {
        type: "number",
        nullable: true,
        description: "Block type TODO"
      },
      hasSmallCircle: { type: "boolean", description: "Has small circle TODO" },
      smallCircleTransitionDuration: {
        type: "number",
        nullable: true,
        description: "Small circle transition duration TODO"
      },
      smallCircleRepeat: {
        type: "number",
        nullable: true,
        description: "Small circle repeat TODO"
      },
      contentImages: {
        anyOf: [
          { type: "null" },
          {
            type: "array",
            items: { $ref: "#/definitions/TasContentImageDefault" }
          }
        ],
        description: "Content images TODO"
      },
      practices: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            instruction: { type: "object", description: "Instruction TODO" },
            regressInstruction: {
              type: "object",
              description: "Regress instruction TODO"
            },
            screenInstruction: {
              type: "object",
              description: "Screen instruction TODO"
            },
            repeat: { type: "number", description: "Repeat TODO" },
            lor: { type: "string", description: "Lor TODO" },
            exercisePattern: {
              type: "number",
              description: "Exercise pattern TODO"
            },
            exercise: {
              description: "Exercise TODO",
              ref: "PlatformTasExerciseDetail",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                serial: { type: "string", description: "编号" },
                name: { type: "string", description: "名称" },
                brandId: { type: "number", description: "Brand TODO" },
                mp4: {
                  description: "视频",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                coverPicture: {
                  description: "封面图",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                presentPicture: {
                  description: "缩略图",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                command: {
                  description: "口令",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                positionList: {
                  type: "array",
                  required: false,
                  items: {
                    type: "string",
                    enum: [
                      "travelling",
                      "standing",
                      "sitting",
                      "kneeling",
                      "prone",
                      "supine",
                      "side lying",
                      "support hold",
                      "jump",
                      "hinged",
                      "Quadruped",
                      "other"
                    ],
                    "x-i18n": {
                      travelling: "移动",
                      standing: "站姿",
                      sitting: "坐姿",
                      kneeling: "跪姿",
                      prone: "俯卧",
                      supine: "仰卧",
                      "side lying": "侧卧",
                      "support hold": "支撑",
                      jump: "跳跃",
                      hinged: "髋屈伸",
                      Quadruped: "四足支撑",
                      other: "其他"
                    }
                  },
                  description: "姿态"
                },
                primaryEquipment: { description: "主器械" },
                active: { type: "boolean", description: "激活" },
                enName: { type: "string", description: "英文名称" },
                bodyWeight: { type: "boolean", description: "自重" },
                secondaryEquipment: {
                  description: "Secondary equipment TODO",
                  ref: "TasEquipmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    enName: { type: "string", description: "En name TODO" },
                    code: { type: "string", description: "Code TODO" }
                  }
                },
                tips: { type: "object", description: "备注" },
                cnCoachingCues: { type: "object", description: "中文教案" },
                coachingCues: { type: "object", description: "教案" },
                shot: { type: "string", description: "拍摄视角" },
                notGuided: { type: "boolean", description: "不纳入指导" }
              }
            },
            guided: { type: "boolean", description: "Guided TODO" },
            stepType: { type: "string", description: "Step type TODO" },
            essential: { type: "boolean", description: "Essential TODO" }
          }
        },
        description: "Practices TODO",
        ref: "PlatformTasPracticeDetail"
      },
      hasCoachingStep: {
        type: "boolean",
        description: "Has coaching step TODO"
      }
    }
  },
  PlatformTasScreenForm: {
    type: "object",
    properties: {
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      blockTypeId: { type: "number", description: "Block type TODO" },
      hasSmallCircle: { type: "boolean", description: "Has small circle TODO" },
      smallCircleTransitionDuration: {
        type: "number",
        description: "Small circle transition duration TODO"
      },
      smallCircleRepeat: {
        type: "number",
        description: "Small circle repeat TODO"
      },
      hasCoachingStep: {
        type: "boolean",
        description: "Has coaching step TODO"
      }
    }
  },
  TasBigCircleDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "En name TODO" },
      category: { type: "string", description: "类型" },
      displayMode: { type: "string", description: "Display mode TODO" },
      introDuration: { type: "number", description: "Intro duration TODO" },
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      equipmentDuration: {
        type: "number",
        description: "Equipment duration TODO"
      },
      splitRest: { type: "boolean", description: "Split rest TODO" },
      splitTransition: {
        type: "boolean",
        description: "Split transition TODO"
      },
      introMode: { type: "string", description: "Intro mode TODO" },
      note: { type: "object", description: "Note TODO" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tag list TODO"
      },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      distributionDuration: {
        type: "number",
        description: "Distribution duration TODO"
      },
      distributionDisplayMode: {
        type: "string",
        description: "Distribution display mode TODO"
      },
      restDuration: { type: "number", description: "Rest duration TODO" },
      circleTraining: { type: "boolean", description: "Circle training TODO" },
      titleDuration: { type: "number", description: "Title duration TODO" },
      screenTransitionDuration: {
        type: "number",
        description: "Screen transition duration TODO"
      },
      screenDuration: { type: "number", description: "Screen duration TODO" },
      countdownDuration: {
        type: "number",
        description: "Countdown duration TODO"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      duration: { type: "number", description: "时长" },
      trainingModeId: {
        type: "number",
        nullable: true,
        description: "Training mode TODO"
      },
      isCircle: { type: "boolean", description: "Is circle TODO" },
      video: { type: "string", nullable: true, description: "Video TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      hideStationNo: { type: "boolean", description: "Hide station no TODO" },
      stationTransitionShowCalories: {
        type: "boolean",
        description: "Station transition show calories TODO"
      },
      guidedBeforeCourse: {
        type: "boolean",
        description: "Guided before course TODO"
      },
      screens: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            stationTransitionDuration: {
              type: "number",
              nullable: true,
              description: "Station transition duration TODO"
            },
            blockTypeName: {
              type: "string",
              nullable: true,
              description: "Block type name TODO"
            },
            blockTypeId: {
              type: "number",
              nullable: true,
              description: "Block type TODO"
            },
            hasSmallCircle: {
              type: "boolean",
              description: "Has small circle TODO"
            },
            smallCircleTransitionDuration: {
              type: "number",
              nullable: true,
              description: "Small circle transition duration TODO"
            },
            smallCircleRepeat: {
              type: "number",
              nullable: true,
              description: "Small circle repeat TODO"
            },
            contentImages: {
              anyOf: [
                { type: "null" },
                {
                  type: "array",
                  items: { $ref: "#/definitions/TasContentImageDefault" }
                }
              ],
              description: "Content images TODO"
            },
            practices: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  instruction: {
                    type: "object",
                    description: "Instruction TODO"
                  },
                  regressInstruction: {
                    type: "object",
                    description: "Regress instruction TODO"
                  },
                  screenInstruction: {
                    type: "object",
                    description: "Screen instruction TODO"
                  },
                  repeat: { type: "number", description: "Repeat TODO" },
                  lor: { type: "string", description: "Lor TODO" },
                  exercisePattern: {
                    type: "number",
                    description: "Exercise pattern TODO"
                  },
                  exercise: {
                    description: "Exercise TODO",
                    ref: "PlatformTasExerciseDetail",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      serial: { type: "string", description: "编号" },
                      name: { type: "string", description: "名称" },
                      brandId: { type: "number", description: "Brand TODO" },
                      mp4: {
                        description: "视频",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      coverPicture: {
                        description: "封面图",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      presentPicture: {
                        description: "缩略图",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      command: {
                        description: "口令",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      positionList: {
                        type: "array",
                        required: false,
                        items: {
                          type: "string",
                          enum: [
                            "travelling",
                            "standing",
                            "sitting",
                            "kneeling",
                            "prone",
                            "supine",
                            "side lying",
                            "support hold",
                            "jump",
                            "hinged",
                            "Quadruped",
                            "other"
                          ],
                          "x-i18n": {
                            travelling: "移动",
                            standing: "站姿",
                            sitting: "坐姿",
                            kneeling: "跪姿",
                            prone: "俯卧",
                            supine: "仰卧",
                            "side lying": "侧卧",
                            "support hold": "支撑",
                            jump: "跳跃",
                            hinged: "髋屈伸",
                            Quadruped: "四足支撑",
                            other: "其他"
                          }
                        },
                        description: "姿态"
                      },
                      primaryEquipment: { description: "主器械" },
                      active: { type: "boolean", description: "激活" },
                      enName: { type: "string", description: "英文名称" },
                      bodyWeight: { type: "boolean", description: "自重" },
                      secondaryEquipment: {
                        description: "Secondary equipment TODO",
                        ref: "TasEquipmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" },
                          enName: {
                            type: "string",
                            description: "En name TODO"
                          },
                          code: { type: "string", description: "Code TODO" }
                        }
                      },
                      tips: { type: "object", description: "备注" },
                      cnCoachingCues: {
                        type: "object",
                        description: "中文教案"
                      },
                      coachingCues: { type: "object", description: "教案" },
                      shot: { type: "string", description: "拍摄视角" },
                      notGuided: { type: "boolean", description: "不纳入指导" }
                    }
                  },
                  guided: { type: "boolean", description: "Guided TODO" },
                  stepType: { type: "string", description: "Step type TODO" },
                  essential: { type: "boolean", description: "Essential TODO" }
                }
              },
              description: "Practices TODO",
              ref: "PlatformTasPracticeDetail"
            },
            hasCoachingStep: {
              type: "boolean",
              description: "Has coaching step TODO"
            }
          }
        },
        description: "Screens TODO",
        ref: "PlatformTasScreenDetail"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasExerciseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      name: { type: "string", description: "Name TODO" },
      tips: { type: "object", description: "Tips TODO" },
      cnCoachingCues: { type: "object", description: "Cn coaching cues TODO" },
      mp4: {
        description: "Mp4 TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coverPicture: {
        description: "Cover picture TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      command: {
        description: "Command TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      presentPicture: {
        description: "Present picture TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      }
    }
  },
  TasExerciseDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      name: { type: "string", description: "Name TODO" },
      tips: { type: "object", description: "Tips TODO" },
      cnCoachingCues: { type: "object", description: "Cn coaching cues TODO" },
      mp4: {
        description: "Mp4 TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coverPicture: {
        description: "Cover picture TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      command: {
        description: "Command TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      presentPicture: {
        description: "Present picture TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      enName: { type: "string", description: "En name TODO" },
      bodyWeight: { type: "boolean", description: "Body weight TODO" },
      peAmount: {
        type: "number",
        nullable: true,
        description: "Pe amount TODO"
      },
      peWeight: {
        type: "number",
        nullable: true,
        description: "Pe weight TODO"
      },
      seAmount: {
        type: "number",
        nullable: true,
        description: "Se amount TODO"
      },
      seWeight: {
        type: "number",
        nullable: true,
        description: "Se weight TODO"
      },
      positionList: {
        type: "array",
        items: { type: "string" },
        description: "Position list TODO"
      },
      coachingCues: { type: "object", description: "Coaching cues TODO" },
      shot: { type: "string", description: "Shot TODO" },
      active: { type: "boolean", description: "Active TODO" },
      notGuided: { type: "boolean", description: "Not guided TODO" }
    }
  },
  TasExerciseTas: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      name: { type: "string", description: "Name TODO" },
      stationNo: { type: "string", description: "Station no TODO" },
      tips: { type: "object", description: "Tips TODO" },
      cnCoachingCues: { type: "object", description: "Cn coaching cues TODO" },
      notGuided: { type: "boolean", description: "Not guided TODO" },
      command: { type: "string", description: "Command TODO" },
      mp4: { type: "string", description: "Mp4 TODO" },
      coverPicture: { type: "string", description: "Cover picture TODO" },
      presentPicture: { type: "string", description: "Present picture TODO" },
      repeat: { type: "number", description: "Repeat TODO" },
      lor: { type: "string", description: "Lor TODO" },
      instruction: { type: "object", description: "Instruction TODO" }
    }
  },
  TasExerciseEquipment: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      stationNo: { type: "string", description: "Station no TODO" }
    }
  },
  TasExerciseForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "Name TODO" },
      enName: { type: "string", description: "En name TODO" },
      mp4: {
        description: "Mp4 TODO",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coverPicture: {
        description: "Cover picture TODO",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      presentPicture: {
        description: "Present picture TODO",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      command: {
        description: "Command TODO",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      bodyWeight: { type: "boolean", description: "Body weight TODO" },
      primaryEquipmentId: {
        type: "number",
        description: "Primary equipment TODO"
      },
      coachingCues: { type: "object", description: "Coaching cues TODO" },
      cnCoachingCues: { type: "object", description: "Cn coaching cues TODO" },
      tips: { type: "object", description: "Tips TODO" },
      active: { type: "boolean", description: "Active TODO" },
      notGuided: { type: "boolean", description: "Not guided TODO" }
    }
  },
  TasBigCircleWithExercises: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "En name TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      coachingImage: {
        description: "Coaching image TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      exercises: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            serial: { type: "string", description: "Serial TODO" },
            name: { type: "string", description: "Name TODO" },
            stationNo: { type: "string", description: "Station no TODO" },
            tips: { type: "object", description: "Tips TODO" },
            cnCoachingCues: {
              type: "object",
              description: "Cn coaching cues TODO"
            },
            notGuided: { type: "boolean", description: "Not guided TODO" },
            command: { type: "string", description: "Command TODO" },
            mp4: { type: "string", description: "Mp4 TODO" },
            coverPicture: { type: "string", description: "Cover picture TODO" },
            presentPicture: {
              type: "string",
              description: "Present picture TODO"
            },
            repeat: { type: "number", description: "Repeat TODO" },
            lor: { type: "string", description: "Lor TODO" },
            instruction: { type: "object", description: "Instruction TODO" }
          }
        },
        description: "Exercises TODO",
        ref: "TasExerciseTas"
      }
    }
  },
  TasScreenDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      stationTransitionDuration: {
        type: "number",
        nullable: true,
        description: "Station transition duration TODO"
      },
      blockTypeName: {
        type: "string",
        nullable: true,
        description: "Block type name TODO"
      },
      blockTypeId: {
        type: "number",
        nullable: true,
        description: "Block type TODO"
      },
      hasSmallCircle: { type: "boolean", description: "Has small circle TODO" },
      smallCircleTransitionDuration: {
        type: "number",
        nullable: true,
        description: "Small circle transition duration TODO"
      },
      smallCircleRepeat: {
        type: "number",
        nullable: true,
        description: "Small circle repeat TODO"
      },
      contentImages: {
        anyOf: [
          { type: "null" },
          {
            type: "array",
            items: { $ref: "#/definitions/TasContentImageDefault" }
          }
        ],
        description: "Content images TODO"
      },
      hasCoachingStep: {
        type: "boolean",
        description: "Has coaching step TODO"
      }
    }
  },
  TasScreenForm: {
    type: "object",
    properties: {
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      blockTypeId: { type: "number", description: "Block type TODO" },
      hasSmallCircle: { type: "boolean", description: "Has small circle TODO" },
      smallCircleTransitionDuration: {
        type: "number",
        description: "Small circle transition duration TODO"
      },
      smallCircleRepeat: {
        type: "number",
        description: "Small circle repeat TODO"
      },
      hasCoachingStep: {
        type: "boolean",
        description: "Has coaching step TODO"
      }
    }
  },
  TasScreenExercisesWithScreen: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      exercises: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            serial: { type: "string", description: "Serial TODO" },
            name: { type: "string", description: "Name TODO" },
            stationNo: { type: "string", description: "Station no TODO" },
            tips: { type: "object", description: "Tips TODO" },
            cnCoachingCues: {
              type: "object",
              description: "Cn coaching cues TODO"
            },
            notGuided: { type: "boolean", description: "Not guided TODO" },
            command: { type: "string", description: "Command TODO" },
            mp4: { type: "string", description: "Mp4 TODO" },
            coverPicture: { type: "string", description: "Cover picture TODO" },
            presentPicture: {
              type: "string",
              description: "Present picture TODO"
            },
            repeat: { type: "number", description: "Repeat TODO" },
            lor: { type: "string", description: "Lor TODO" },
            instruction: { type: "object", description: "Instruction TODO" }
          }
        },
        description: "Exercises TODO",
        ref: "TasExerciseTas"
      }
    }
  },
  TasScreenPracticesWithScreen: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      blockTypeName: {
        type: "string",
        nullable: true,
        description: "Block type name TODO"
      },
      practices: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            instruction: { type: "object", description: "Instruction TODO" },
            regressInstruction: {
              type: "object",
              description: "Regress instruction TODO"
            },
            screenInstruction: {
              type: "object",
              description: "Screen instruction TODO"
            },
            repeat: { type: "number", description: "Repeat TODO" },
            lor: { type: "string", description: "Lor TODO" },
            exercisePattern: {
              type: "number",
              description: "Exercise pattern TODO"
            },
            exercise: {
              description: "Exercise TODO",
              ref: "PlatformTasExerciseDetail",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                serial: { type: "string", description: "编号" },
                name: { type: "string", description: "名称" },
                brandId: { type: "number", description: "Brand TODO" },
                mp4: {
                  description: "视频",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                coverPicture: {
                  description: "封面图",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                presentPicture: {
                  description: "缩略图",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                command: {
                  description: "口令",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                positionList: {
                  type: "array",
                  required: false,
                  items: {
                    type: "string",
                    enum: [
                      "travelling",
                      "standing",
                      "sitting",
                      "kneeling",
                      "prone",
                      "supine",
                      "side lying",
                      "support hold",
                      "jump",
                      "hinged",
                      "Quadruped",
                      "other"
                    ],
                    "x-i18n": {
                      travelling: "移动",
                      standing: "站姿",
                      sitting: "坐姿",
                      kneeling: "跪姿",
                      prone: "俯卧",
                      supine: "仰卧",
                      "side lying": "侧卧",
                      "support hold": "支撑",
                      jump: "跳跃",
                      hinged: "髋屈伸",
                      Quadruped: "四足支撑",
                      other: "其他"
                    }
                  },
                  description: "姿态"
                },
                primaryEquipment: { description: "主器械" },
                active: { type: "boolean", description: "激活" },
                enName: { type: "string", description: "英文名称" },
                bodyWeight: { type: "boolean", description: "自重" },
                secondaryEquipment: {
                  description: "Secondary equipment TODO",
                  ref: "TasEquipmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    enName: { type: "string", description: "En name TODO" },
                    code: { type: "string", description: "Code TODO" }
                  }
                },
                tips: { type: "object", description: "备注" },
                cnCoachingCues: { type: "object", description: "中文教案" },
                coachingCues: { type: "object", description: "教案" },
                shot: { type: "string", description: "拍摄视角" },
                notGuided: { type: "boolean", description: "不纳入指导" }
              }
            },
            guided: { type: "boolean", description: "Guided TODO" },
            stepType: { type: "string", description: "Step type TODO" },
            essential: { type: "boolean", description: "Essential TODO" }
          }
        },
        description: "Practices TODO",
        ref: "PlatformTasPracticeDetail"
      }
    }
  },
  TasBigCircleExercisesWithScreen: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      screensWithExercises: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            exercises: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  serial: { type: "string", description: "Serial TODO" },
                  name: { type: "string", description: "Name TODO" },
                  stationNo: { type: "string", description: "Station no TODO" },
                  tips: { type: "object", description: "Tips TODO" },
                  cnCoachingCues: {
                    type: "object",
                    description: "Cn coaching cues TODO"
                  },
                  notGuided: {
                    type: "boolean",
                    description: "Not guided TODO"
                  },
                  command: { type: "string", description: "Command TODO" },
                  mp4: { type: "string", description: "Mp4 TODO" },
                  coverPicture: {
                    type: "string",
                    description: "Cover picture TODO"
                  },
                  presentPicture: {
                    type: "string",
                    description: "Present picture TODO"
                  },
                  repeat: { type: "number", description: "Repeat TODO" },
                  lor: { type: "string", description: "Lor TODO" },
                  instruction: {
                    type: "object",
                    description: "Instruction TODO"
                  }
                }
              },
              description: "Exercises TODO",
              ref: "TasExerciseTas"
            }
          }
        },
        description: "Screens with exercises TODO",
        ref: "TasScreenExercisesWithScreen"
      }
    }
  },
  TasBigCircleForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "En name TODO" },
      category: { type: "string", description: "类型" },
      displayMode: { type: "string", description: "Display mode TODO" },
      introDuration: { type: "number", description: "Intro duration TODO" },
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      equipmentDuration: {
        type: "number",
        description: "Equipment duration TODO"
      },
      splitRest: { type: "boolean", description: "Split rest TODO" },
      splitTransition: {
        type: "boolean",
        description: "Split transition TODO"
      },
      introMode: { type: "string", description: "Intro mode TODO" },
      note: { type: "string", description: "Note TODO" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tag list TODO"
      },
      distributionDuration: {
        type: "number",
        description: "Distribution duration TODO"
      },
      distributionDisplayMode: {
        type: "string",
        description: "Distribution display mode TODO"
      },
      restDuration: { type: "number", description: "Rest duration TODO" },
      circleTraining: { type: "boolean", description: "Circle training TODO" },
      titleDuration: { type: "number", description: "Title duration TODO" },
      screenTransitionDuration: {
        type: "number",
        description: "Screen transition duration TODO"
      },
      screenDuration: { type: "number", description: "Screen duration TODO" },
      countdownDuration: {
        type: "number",
        description: "Countdown duration TODO"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      duration: { type: "number", description: "时长" },
      trainingModeId: {
        type: "number",
        nullable: true,
        description: "Training mode TODO"
      },
      isCircle: { type: "boolean", description: "Is circle TODO" },
      video: { type: "string", nullable: true, description: "Video TODO" },
      hideStationNo: { type: "boolean", description: "Hide station no TODO" },
      stationTransitionShowCalories: {
        type: "boolean",
        description: "Station transition show calories TODO"
      },
      guidedBeforeCourse: {
        type: "boolean",
        description: "Guided before course TODO"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasBigCircleTas: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "En name TODO" },
      category: { type: "string", description: "类型" },
      displayMode: { type: "string", description: "Display mode TODO" },
      introDuration: { type: "number", description: "Intro duration TODO" },
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      equipmentDuration: {
        type: "number",
        description: "Equipment duration TODO"
      },
      splitRest: { type: "boolean", description: "Split rest TODO" },
      splitTransition: {
        type: "boolean",
        description: "Split transition TODO"
      },
      introMode: { type: "string", description: "Intro mode TODO" },
      note: { type: "object", description: "Note TODO" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tag list TODO"
      },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      distributionDuration: {
        type: "number",
        description: "Distribution duration TODO"
      },
      distributionDisplayMode: {
        type: "string",
        description: "Distribution display mode TODO"
      },
      restDuration: { type: "number", description: "Rest duration TODO" },
      circleTraining: { type: "boolean", description: "Circle training TODO" },
      titleDuration: { type: "number", description: "Title duration TODO" },
      screenTransitionDuration: {
        type: "number",
        description: "Screen transition duration TODO"
      },
      screenDuration: { type: "number", description: "Screen duration TODO" },
      countdownDuration: {
        type: "number",
        description: "Countdown duration TODO"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      duration: { type: "number", description: "时长" },
      trainingModeId: {
        type: "number",
        nullable: true,
        description: "Training mode TODO"
      },
      isCircle: { type: "boolean", description: "Is circle TODO" },
      video: { type: "string", nullable: true, description: "Video TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      hideStationNo: { type: "boolean", description: "Hide station no TODO" },
      stationTransitionShowCalories: {
        type: "boolean",
        description: "Station transition show calories TODO"
      },
      guidedBeforeCourse: {
        type: "boolean",
        description: "Guided before course TODO"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasPlaylistItemDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      bgmId: { type: "number", description: "Bgm TODO" },
      bigCircleId: { type: "number", description: "Big circle TODO" },
      bgmName: { type: "string", description: "Bgm name TODO" },
      position: { type: "number", description: "排序" },
      category: { type: "string", description: "类型" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tag list TODO"
      },
      count: { type: "number", description: "Count TODO" },
      duration: { type: "number", description: "时长" },
      file: { type: "string", description: "File TODO" },
      offset: { type: "number", description: "Offset TODO" }
    }
  },
  TasBigCircleWithPlaylistItems: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tag list TODO"
      },
      playlistItems: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            bgmId: { type: "number", description: "Bgm TODO" },
            bigCircleId: { type: "number", description: "Big circle TODO" },
            bgmName: { type: "string", description: "Bgm name TODO" },
            position: { type: "number", description: "排序" },
            category: { type: "string", description: "类型" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tag list TODO"
            },
            count: { type: "number", description: "Count TODO" },
            duration: { type: "number", description: "时长" },
            file: { type: "string", description: "File TODO" },
            offset: { type: "number", description: "Offset TODO" }
          }
        },
        description: "Playlist items TODO",
        ref: "TasPlaylistItemDefault"
      }
    }
  },
  TasPlaylistDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      date: {
        description: "Date TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      }
    }
  },
  TasWorkoutOutlineDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "状态"
      },
      brandId: { type: "number", description: "Brand TODO" },
      circuitOutline: { type: "boolean", description: "循环训练" },
      workoutVersion: {
        description: "课程版本",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      active: { type: "boolean", description: "Active TODO" },
      note: { type: "object", description: "名称" },
      bgmFade: { type: "boolean", description: "音乐淡入淡出" },
      playlistStrategy: {
        type: "string",
        description: "Playlist strategy TODO"
      },
      instruction: { type: "string", description: "说明" },
      workoutVersionId: { type: "number", description: "Workout version TODO" },
      packageId: { type: "number", description: "Package TODO" },
      bigCircles: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            enName: { type: "string", description: "En name TODO" },
            category: { type: "string", description: "类型" },
            displayMode: { type: "string", description: "Display mode TODO" },
            introDuration: {
              type: "number",
              description: "Intro duration TODO"
            },
            stationTransitionDuration: {
              type: "number",
              description: "Station transition duration TODO"
            },
            equipmentDuration: {
              type: "number",
              description: "Equipment duration TODO"
            },
            splitRest: { type: "boolean", description: "Split rest TODO" },
            splitTransition: {
              type: "boolean",
              description: "Split transition TODO"
            },
            introMode: { type: "string", description: "Intro mode TODO" },
            note: { type: "object", description: "Note TODO" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tag list TODO"
            },
            coachingImages: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "Coaching images TODO",
              ref: "AttachmentDefault"
            },
            coachingCue: { type: "string", description: "Coaching cue TODO" },
            distributionDuration: {
              type: "number",
              description: "Distribution duration TODO"
            },
            distributionDisplayMode: {
              type: "string",
              description: "Distribution display mode TODO"
            },
            restDuration: { type: "number", description: "Rest duration TODO" },
            circleTraining: {
              type: "boolean",
              description: "Circle training TODO"
            },
            titleDuration: {
              type: "number",
              description: "Title duration TODO"
            },
            screenTransitionDuration: {
              type: "number",
              description: "Screen transition duration TODO"
            },
            screenDuration: {
              type: "number",
              description: "Screen duration TODO"
            },
            countdownDuration: {
              type: "number",
              description: "Countdown duration TODO"
            },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            repeat: { type: "number", description: "Repeat TODO" },
            duration: { type: "number", description: "时长" },
            trainingModeId: {
              type: "number",
              nullable: true,
              description: "Training mode TODO"
            },
            isCircle: { type: "boolean", description: "Is circle TODO" },
            video: {
              type: "string",
              nullable: true,
              description: "Video TODO"
            },
            videoUrl: { type: "string", description: "Video url TODO" },
            hideStationNo: {
              type: "boolean",
              description: "Hide station no TODO"
            },
            stationTransitionShowCalories: {
              type: "boolean",
              description: "Station transition show calories TODO"
            },
            guidedBeforeCourse: {
              type: "boolean",
              description: "Guided before course TODO"
            },
            screens: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  stationTransitionDuration: {
                    type: "number",
                    nullable: true,
                    description: "Station transition duration TODO"
                  },
                  blockTypeName: {
                    type: "string",
                    nullable: true,
                    description: "Block type name TODO"
                  },
                  blockTypeId: {
                    type: "number",
                    nullable: true,
                    description: "Block type TODO"
                  },
                  hasSmallCircle: {
                    type: "boolean",
                    description: "Has small circle TODO"
                  },
                  smallCircleTransitionDuration: {
                    type: "number",
                    nullable: true,
                    description: "Small circle transition duration TODO"
                  },
                  smallCircleRepeat: {
                    type: "number",
                    nullable: true,
                    description: "Small circle repeat TODO"
                  },
                  contentImages: {
                    anyOf: [
                      { type: "null" },
                      {
                        type: "array",
                        items: { $ref: "#/definitions/TasContentImageDefault" }
                      }
                    ],
                    description: "Content images TODO"
                  },
                  practices: {
                    type: "array",
                    items: {
                      type: "object",
                      properties: {
                        id: { type: "number", description: "Id TODO" },
                        instruction: {
                          type: "object",
                          description: "Instruction TODO"
                        },
                        regressInstruction: {
                          type: "object",
                          description: "Regress instruction TODO"
                        },
                        screenInstruction: {
                          type: "object",
                          description: "Screen instruction TODO"
                        },
                        repeat: { type: "number", description: "Repeat TODO" },
                        lor: { type: "string", description: "Lor TODO" },
                        exercisePattern: {
                          type: "number",
                          description: "Exercise pattern TODO"
                        },
                        exercise: {
                          description: "Exercise TODO",
                          ref: "PlatformTasExerciseDetail",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            serial: { type: "string", description: "编号" },
                            name: { type: "string", description: "名称" },
                            brandId: {
                              type: "number",
                              description: "Brand TODO"
                            },
                            mp4: {
                              description: "视频",
                              ref: "AttachmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                signedId: {
                                  type: "string",
                                  description: "Signed ID"
                                },
                                url: { type: "string", description: "URL" },
                                originalUrl: {
                                  type: "string",
                                  description: "原始文件 URL"
                                },
                                filename: {
                                  type: "string",
                                  description: "文件名"
                                },
                                contentType: {
                                  type: "string",
                                  description: "文件类型"
                                }
                              }
                            },
                            coverPicture: {
                              description: "封面图",
                              ref: "AttachmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                signedId: {
                                  type: "string",
                                  description: "Signed ID"
                                },
                                url: { type: "string", description: "URL" },
                                originalUrl: {
                                  type: "string",
                                  description: "原始文件 URL"
                                },
                                filename: {
                                  type: "string",
                                  description: "文件名"
                                },
                                contentType: {
                                  type: "string",
                                  description: "文件类型"
                                }
                              }
                            },
                            presentPicture: {
                              description: "缩略图",
                              ref: "AttachmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                signedId: {
                                  type: "string",
                                  description: "Signed ID"
                                },
                                url: { type: "string", description: "URL" },
                                originalUrl: {
                                  type: "string",
                                  description: "原始文件 URL"
                                },
                                filename: {
                                  type: "string",
                                  description: "文件名"
                                },
                                contentType: {
                                  type: "string",
                                  description: "文件类型"
                                }
                              }
                            },
                            command: {
                              description: "口令",
                              ref: "AttachmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                signedId: {
                                  type: "string",
                                  description: "Signed ID"
                                },
                                url: { type: "string", description: "URL" },
                                originalUrl: {
                                  type: "string",
                                  description: "原始文件 URL"
                                },
                                filename: {
                                  type: "string",
                                  description: "文件名"
                                },
                                contentType: {
                                  type: "string",
                                  description: "文件类型"
                                }
                              }
                            },
                            positionList: {
                              type: "array",
                              required: false,
                              items: {
                                type: "string",
                                enum: [
                                  "travelling",
                                  "standing",
                                  "sitting",
                                  "kneeling",
                                  "prone",
                                  "supine",
                                  "side lying",
                                  "support hold",
                                  "jump",
                                  "hinged",
                                  "Quadruped",
                                  "other"
                                ],
                                "x-i18n": {
                                  travelling: "移动",
                                  standing: "站姿",
                                  sitting: "坐姿",
                                  kneeling: "跪姿",
                                  prone: "俯卧",
                                  supine: "仰卧",
                                  "side lying": "侧卧",
                                  "support hold": "支撑",
                                  jump: "跳跃",
                                  hinged: "髋屈伸",
                                  Quadruped: "四足支撑",
                                  other: "其他"
                                }
                              },
                              description: "姿态"
                            },
                            primaryEquipment: { description: "主器械" },
                            active: { type: "boolean", description: "激活" },
                            enName: { type: "string", description: "英文名称" },
                            bodyWeight: {
                              type: "boolean",
                              description: "自重"
                            },
                            secondaryEquipment: {
                              description: "Secondary equipment TODO",
                              ref: "TasEquipmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                name: { type: "string", description: "名称" },
                                enName: {
                                  type: "string",
                                  description: "En name TODO"
                                },
                                code: {
                                  type: "string",
                                  description: "Code TODO"
                                }
                              }
                            },
                            tips: { type: "object", description: "备注" },
                            cnCoachingCues: {
                              type: "object",
                              description: "中文教案"
                            },
                            coachingCues: {
                              type: "object",
                              description: "教案"
                            },
                            shot: { type: "string", description: "拍摄视角" },
                            notGuided: {
                              type: "boolean",
                              description: "不纳入指导"
                            }
                          }
                        },
                        guided: { type: "boolean", description: "Guided TODO" },
                        stepType: {
                          type: "string",
                          description: "Step type TODO"
                        },
                        essential: {
                          type: "boolean",
                          description: "Essential TODO"
                        }
                      }
                    },
                    description: "Practices TODO",
                    ref: "PlatformTasPracticeDetail"
                  },
                  hasCoachingStep: {
                    type: "boolean",
                    description: "Has coaching step TODO"
                  }
                }
              },
              description: "Screens TODO",
              ref: "PlatformTasScreenDetail"
            },
            active: { type: "boolean", description: "激活" }
          }
        },
        description: "Big circles TODO",
        ref: "TasBigCircleDetail"
      },
      workoutId: { type: "number", description: "Workout TODO" },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      defaultPlaylist: {
        description: "Default playlist TODO",
        ref: "TasPlaylistDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          date: {
            description: "Date TODO",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          }
        }
      },
      updatedAt: {
        description: "Updated at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  TasWorkoutOutlineWithPlaylist: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "状态"
      },
      brandId: { type: "number", description: "Brand TODO" },
      circuitOutline: { type: "boolean", description: "循环训练" },
      workoutVersion: {
        description: "课程版本",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      active: { type: "boolean", description: "Active TODO" },
      note: { type: "object", description: "名称" },
      bgmFade: { type: "boolean", description: "音乐淡入淡出" },
      playlistStrategy: {
        type: "string",
        description: "Playlist strategy TODO"
      },
      instruction: { type: "string", description: "说明" },
      workoutVersionId: { type: "number", description: "Workout version TODO" },
      packageId: { type: "number", description: "Package TODO" },
      bigCircles: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            enName: { type: "string", description: "En name TODO" },
            category: { type: "string", description: "类型" },
            displayMode: { type: "string", description: "Display mode TODO" },
            introDuration: {
              type: "number",
              description: "Intro duration TODO"
            },
            stationTransitionDuration: {
              type: "number",
              description: "Station transition duration TODO"
            },
            equipmentDuration: {
              type: "number",
              description: "Equipment duration TODO"
            },
            splitRest: { type: "boolean", description: "Split rest TODO" },
            splitTransition: {
              type: "boolean",
              description: "Split transition TODO"
            },
            introMode: { type: "string", description: "Intro mode TODO" },
            note: { type: "object", description: "Note TODO" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tag list TODO"
            },
            coachingImages: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "Coaching images TODO",
              ref: "AttachmentDefault"
            },
            coachingCue: { type: "string", description: "Coaching cue TODO" },
            distributionDuration: {
              type: "number",
              description: "Distribution duration TODO"
            },
            distributionDisplayMode: {
              type: "string",
              description: "Distribution display mode TODO"
            },
            restDuration: { type: "number", description: "Rest duration TODO" },
            circleTraining: {
              type: "boolean",
              description: "Circle training TODO"
            },
            titleDuration: {
              type: "number",
              description: "Title duration TODO"
            },
            screenTransitionDuration: {
              type: "number",
              description: "Screen transition duration TODO"
            },
            screenDuration: {
              type: "number",
              description: "Screen duration TODO"
            },
            countdownDuration: {
              type: "number",
              description: "Countdown duration TODO"
            },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            repeat: { type: "number", description: "Repeat TODO" },
            duration: { type: "number", description: "时长" },
            trainingModeId: {
              type: "number",
              nullable: true,
              description: "Training mode TODO"
            },
            isCircle: { type: "boolean", description: "Is circle TODO" },
            video: {
              type: "string",
              nullable: true,
              description: "Video TODO"
            },
            videoUrl: { type: "string", description: "Video url TODO" },
            hideStationNo: {
              type: "boolean",
              description: "Hide station no TODO"
            },
            stationTransitionShowCalories: {
              type: "boolean",
              description: "Station transition show calories TODO"
            },
            guidedBeforeCourse: {
              type: "boolean",
              description: "Guided before course TODO"
            },
            screens: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  stationTransitionDuration: {
                    type: "number",
                    nullable: true,
                    description: "Station transition duration TODO"
                  },
                  blockTypeName: {
                    type: "string",
                    nullable: true,
                    description: "Block type name TODO"
                  },
                  blockTypeId: {
                    type: "number",
                    nullable: true,
                    description: "Block type TODO"
                  },
                  hasSmallCircle: {
                    type: "boolean",
                    description: "Has small circle TODO"
                  },
                  smallCircleTransitionDuration: {
                    type: "number",
                    nullable: true,
                    description: "Small circle transition duration TODO"
                  },
                  smallCircleRepeat: {
                    type: "number",
                    nullable: true,
                    description: "Small circle repeat TODO"
                  },
                  contentImages: {
                    anyOf: [
                      { type: "null" },
                      {
                        type: "array",
                        items: { $ref: "#/definitions/TasContentImageDefault" }
                      }
                    ],
                    description: "Content images TODO"
                  },
                  practices: {
                    type: "array",
                    items: {
                      type: "object",
                      properties: {
                        id: { type: "number", description: "Id TODO" },
                        instruction: {
                          type: "object",
                          description: "Instruction TODO"
                        },
                        regressInstruction: {
                          type: "object",
                          description: "Regress instruction TODO"
                        },
                        screenInstruction: {
                          type: "object",
                          description: "Screen instruction TODO"
                        },
                        repeat: { type: "number", description: "Repeat TODO" },
                        lor: { type: "string", description: "Lor TODO" },
                        exercisePattern: {
                          type: "number",
                          description: "Exercise pattern TODO"
                        },
                        exercise: {
                          description: "Exercise TODO",
                          ref: "PlatformTasExerciseDetail",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            serial: { type: "string", description: "编号" },
                            name: { type: "string", description: "名称" },
                            brandId: {
                              type: "number",
                              description: "Brand TODO"
                            },
                            mp4: {
                              description: "视频",
                              ref: "AttachmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                signedId: {
                                  type: "string",
                                  description: "Signed ID"
                                },
                                url: { type: "string", description: "URL" },
                                originalUrl: {
                                  type: "string",
                                  description: "原始文件 URL"
                                },
                                filename: {
                                  type: "string",
                                  description: "文件名"
                                },
                                contentType: {
                                  type: "string",
                                  description: "文件类型"
                                }
                              }
                            },
                            coverPicture: {
                              description: "封面图",
                              ref: "AttachmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                signedId: {
                                  type: "string",
                                  description: "Signed ID"
                                },
                                url: { type: "string", description: "URL" },
                                originalUrl: {
                                  type: "string",
                                  description: "原始文件 URL"
                                },
                                filename: {
                                  type: "string",
                                  description: "文件名"
                                },
                                contentType: {
                                  type: "string",
                                  description: "文件类型"
                                }
                              }
                            },
                            presentPicture: {
                              description: "缩略图",
                              ref: "AttachmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                signedId: {
                                  type: "string",
                                  description: "Signed ID"
                                },
                                url: { type: "string", description: "URL" },
                                originalUrl: {
                                  type: "string",
                                  description: "原始文件 URL"
                                },
                                filename: {
                                  type: "string",
                                  description: "文件名"
                                },
                                contentType: {
                                  type: "string",
                                  description: "文件类型"
                                }
                              }
                            },
                            command: {
                              description: "口令",
                              ref: "AttachmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                signedId: {
                                  type: "string",
                                  description: "Signed ID"
                                },
                                url: { type: "string", description: "URL" },
                                originalUrl: {
                                  type: "string",
                                  description: "原始文件 URL"
                                },
                                filename: {
                                  type: "string",
                                  description: "文件名"
                                },
                                contentType: {
                                  type: "string",
                                  description: "文件类型"
                                }
                              }
                            },
                            positionList: {
                              type: "array",
                              required: false,
                              items: {
                                type: "string",
                                enum: [
                                  "travelling",
                                  "standing",
                                  "sitting",
                                  "kneeling",
                                  "prone",
                                  "supine",
                                  "side lying",
                                  "support hold",
                                  "jump",
                                  "hinged",
                                  "Quadruped",
                                  "other"
                                ],
                                "x-i18n": {
                                  travelling: "移动",
                                  standing: "站姿",
                                  sitting: "坐姿",
                                  kneeling: "跪姿",
                                  prone: "俯卧",
                                  supine: "仰卧",
                                  "side lying": "侧卧",
                                  "support hold": "支撑",
                                  jump: "跳跃",
                                  hinged: "髋屈伸",
                                  Quadruped: "四足支撑",
                                  other: "其他"
                                }
                              },
                              description: "姿态"
                            },
                            primaryEquipment: { description: "主器械" },
                            active: { type: "boolean", description: "激活" },
                            enName: { type: "string", description: "英文名称" },
                            bodyWeight: {
                              type: "boolean",
                              description: "自重"
                            },
                            secondaryEquipment: {
                              description: "Secondary equipment TODO",
                              ref: "TasEquipmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                name: { type: "string", description: "名称" },
                                enName: {
                                  type: "string",
                                  description: "En name TODO"
                                },
                                code: {
                                  type: "string",
                                  description: "Code TODO"
                                }
                              }
                            },
                            tips: { type: "object", description: "备注" },
                            cnCoachingCues: {
                              type: "object",
                              description: "中文教案"
                            },
                            coachingCues: {
                              type: "object",
                              description: "教案"
                            },
                            shot: { type: "string", description: "拍摄视角" },
                            notGuided: {
                              type: "boolean",
                              description: "不纳入指导"
                            }
                          }
                        },
                        guided: { type: "boolean", description: "Guided TODO" },
                        stepType: {
                          type: "string",
                          description: "Step type TODO"
                        },
                        essential: {
                          type: "boolean",
                          description: "Essential TODO"
                        }
                      }
                    },
                    description: "Practices TODO",
                    ref: "PlatformTasPracticeDetail"
                  },
                  hasCoachingStep: {
                    type: "boolean",
                    description: "Has coaching step TODO"
                  }
                }
              },
              description: "Screens TODO",
              ref: "PlatformTasScreenDetail"
            },
            active: { type: "boolean", description: "激活" }
          }
        },
        description: "Big circles TODO",
        ref: "TasBigCircleDetail"
      },
      workoutId: { type: "number", description: "Workout TODO" },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      defaultPlaylist: {
        description: "Default playlist TODO",
        ref: "TasPlaylistDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          date: {
            description: "Date TODO",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          }
        }
      },
      updatedAt: {
        description: "Updated at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      todayPlaylistItems: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            bgmId: { type: "number", description: "Bgm TODO" },
            bigCircleId: { type: "number", description: "Big circle TODO" },
            bgmName: { type: "string", description: "Bgm name TODO" },
            position: { type: "number", description: "排序" },
            category: { type: "string", description: "类型" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tag list TODO"
            },
            count: { type: "number", description: "Count TODO" },
            duration: { type: "number", description: "时长" },
            file: { type: "string", description: "File TODO" },
            offset: { type: "number", description: "Offset TODO" }
          }
        },
        description: "Today playlist items TODO",
        ref: "TasPlaylistItemDefault"
      }
    }
  },
  TasWorkoutOutlineStatistic: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      workoutName: { type: "string", description: "课程产品名称" },
      packageSerial: { type: "string", description: "Package serial TODO" },
      packageDesc: { type: "object", description: "Package desc TODO" }
    }
  },
  TasWorkoutOutlineTas: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "状态"
      },
      brandId: { type: "number", description: "Brand TODO" },
      circuitOutline: { type: "boolean", description: "循环训练" },
      workoutVersion: {
        description: "课程版本",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      active: { type: "boolean", description: "Active TODO" },
      note: { type: "object", description: "名称" },
      bgmFade: { type: "boolean", description: "音乐淡入淡出" },
      playlistStrategy: {
        type: "string",
        description: "Playlist strategy TODO"
      },
      instruction: { type: "object", description: "说明" },
      workoutVersionId: { type: "number", description: "Workout version TODO" },
      packageId: { type: "number", description: "Package TODO" },
      duration: { type: "number", description: "Duration TODO" },
      essentialExercises: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            serial: { type: "string", description: "Serial TODO" },
            name: { type: "string", description: "Name TODO" },
            stationNo: { type: "string", description: "Station no TODO" },
            tips: { type: "object", description: "Tips TODO" },
            cnCoachingCues: {
              type: "object",
              description: "Cn coaching cues TODO"
            },
            notGuided: { type: "boolean", description: "Not guided TODO" },
            command: { type: "string", description: "Command TODO" },
            mp4: { type: "string", description: "Mp4 TODO" },
            coverPicture: { type: "string", description: "Cover picture TODO" },
            presentPicture: {
              type: "string",
              description: "Present picture TODO"
            },
            repeat: { type: "number", description: "Repeat TODO" },
            lor: { type: "string", description: "Lor TODO" },
            instruction: { type: "object", description: "Instruction TODO" }
          }
        },
        description: "Essential exercises TODO",
        ref: "TasExerciseTas"
      }
    }
  },
  TasWorkoutOutlineWithExercises: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      bigCircles: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            enName: { type: "string", description: "En name TODO" },
            videoUrl: { type: "string", description: "Video url TODO" },
            coachingCue: { type: "string", description: "Coaching cue TODO" },
            coachingImage: {
              description: "Coaching image TODO",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            exercises: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  serial: { type: "string", description: "Serial TODO" },
                  name: { type: "string", description: "Name TODO" },
                  stationNo: { type: "string", description: "Station no TODO" },
                  tips: { type: "object", description: "Tips TODO" },
                  cnCoachingCues: {
                    type: "object",
                    description: "Cn coaching cues TODO"
                  },
                  notGuided: {
                    type: "boolean",
                    description: "Not guided TODO"
                  },
                  command: { type: "string", description: "Command TODO" },
                  mp4: { type: "string", description: "Mp4 TODO" },
                  coverPicture: {
                    type: "string",
                    description: "Cover picture TODO"
                  },
                  presentPicture: {
                    type: "string",
                    description: "Present picture TODO"
                  },
                  repeat: { type: "number", description: "Repeat TODO" },
                  lor: { type: "string", description: "Lor TODO" },
                  instruction: {
                    type: "object",
                    description: "Instruction TODO"
                  }
                }
              },
              description: "Exercises TODO",
              ref: "TasExerciseTas"
            }
          }
        },
        description: "Big circles TODO",
        ref: "TasBigCircleWithExercises"
      }
    }
  },
  TasWorkoutOutlineCreateForm: {
    type: "object",
    properties: {
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "状态"
      },
      note: { type: "string", description: "名称" },
      bgmFade: { type: "boolean", description: "音乐淡入淡出" },
      productId: { type: "number", description: "Product TODO" }
    }
  },
  TasWorkoutOutlineEditForm: {
    type: "object",
    properties: {
      note: { type: "string", description: "名称" },
      instruction: { type: "string", description: "说明" },
      active: { type: "boolean", description: "Active TODO" },
      bgmFade: { type: "boolean", description: "音乐淡入淡出" }
    }
  },
  TasWorkoutOutlineUpdateFrameForm: {
    type: "object",
    properties: {
      duration: { type: "number", description: "Duration TODO" },
      sourceIds: {
        type: "array",
        items: { type: "number" },
        description: "Source ids TODO"
      },
      sourceType: { type: "string", description: "Source type TODO" },
      sourceAttribute: { type: "string", description: "Source attribute TODO" }
    }
  },
  KtasWorkoutDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      duration: { type: "number", description: "时长" },
      abbr: { type: "string", description: "缩写" },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "年龄阶段"
      },
      brandId: { type: "number", description: "Brand TODO" },
      intro: { type: "object", description: "介绍" },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasWorkoutForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      abbr: { type: "string", description: "缩写" },
      duration: { type: "number", description: "时长" },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "年龄阶段"
      },
      productId: { type: "number", nullable: true, description: "训练产品" },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            signedId: { type: "string", description: "Signed ID" },
            remoteUrl: {
              type: "string",
              nullable: true,
              description: "文件远程 URL"
            },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "缩略图",
        ref: "AttachmentForm"
      },
      intro: { type: "object", description: "介绍" },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasOutlineDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      brandId: { type: "number", description: "Brand TODO" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "abandoned"],
        "x-i18n": {
          new: "待认证",
          certificated: "已认证",
          abandoned: "已废弃"
        },
        description: "状态"
      },
      workout: {
        description: "课程品类",
        ref: "KtasWorkoutDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          duration: { type: "number", description: "时长" },
          abbr: { type: "string", description: "缩写" },
          stageList: {
            type: "array",
            items: { type: "string" },
            description: "年龄阶段"
          },
          brandId: { type: "number", description: "Brand TODO" },
          intro: { type: "object", description: "介绍" },
          active: { type: "boolean", description: "激活" }
        }
      },
      intro: { type: "string", description: "课程介绍" },
      physicalQualityList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["综合素质"],
          "x-i18n": { 综合素质: "综合素质" }
        },
        description: "身体素质"
      },
      actionList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["抓握", "蹦跳", "爬行", "跑步"],
          "x-i18n": { 抓握: "抓握", 蹦跳: "蹦跳", 爬行: "爬行", 跑步: "跑步" }
        },
        description: "动作模式"
      },
      skillList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["支撑", "翻越", "蹦跳", "跑步", "抓握"],
          "x-i18n": {
            支撑: "支撑",
            翻越: "翻越",
            蹦跳: "蹦跳",
            跑步: "跑步",
            抓握: "抓握"
          }
        },
        description: "动作技能"
      },
      characterList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: [
            "专注",
            "友善",
            "感恩",
            "守时",
            "责任",
            "主动",
            "宽容",
            "思考",
            "勇敢",
            "尊重",
            "勤劳",
            "独立",
            "规则",
            "诚实守信"
          ],
          "x-i18n": {
            专注: "专注",
            友善: "友善",
            感恩: "感恩",
            守时: "守时",
            责任: "责任",
            主动: "主动",
            宽容: "宽容",
            思考: "思考",
            勇敢: "勇敢",
            尊重: "尊重",
            勤劳: "勤劳",
            独立: "独立",
            规则: "规则",
            诚实守信: "诚实守信"
          }
        },
        description: "品格训练"
      }
    }
  },
  KtasTrainingMethodDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      preferList: {
        type: "array",
        items: { type: "string" },
        description: "适用"
      },
      desc: { type: "object", description: "介绍" },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasTrainingMethodForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      preferList: {
        type: "array",
        items: { type: "string" },
        description: "适用"
      },
      desc: { type: "object", description: "介绍" },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasGameDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      desc: { type: "object", description: "介绍" },
      preferList: {
        type: "array",
        items: { type: "string" },
        description: "适用"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasGameForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      desc: { type: "object", description: "介绍" },
      preferList: {
        type: "array",
        items: { type: "string" },
        description: "适用"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasSectionDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      category: {
        type: "string",
        required: false,
        enum: ["normal", "game"],
        "x-i18n": { normal: "普通", game: "游戏" },
        description: "类型"
      },
      trainingMethod: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/KtasTrainingMethodDefault" }
        ],
        description: "训练方式"
      },
      video: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "视频"
      },
      games: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            desc: { type: "object", description: "介绍" },
            preferList: {
              type: "array",
              items: { type: "string" },
              description: "适用"
            },
            active: { type: "boolean", description: "激活" }
          }
        },
        description: "Games TODO",
        ref: "KtasGameDefault"
      },
      gameIds: {
        type: "array",
        items: { type: "number" },
        description: "推荐游戏"
      },
      duration: { type: "number", description: "时长" },
      desc: { type: "object", description: "介绍" },
      coachingTips: { type: "object", description: "教案" },
      exerciseList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["动作1", "动作2"],
          "x-i18n": { 动作1: "动作1", 动作2: "动作2" }
        },
        description: "推荐动作"
      },
      coachingTipList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["喝水", "拍照"],
          "x-i18n": { 喝水: "喝水", 拍照: "拍照" }
        },
        description: "教练提示"
      },
      position: { type: "number", description: "排序" }
    }
  },
  KtasSectionForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      category: {
        type: "string",
        required: false,
        enum: ["normal", "game"],
        "x-i18n": { normal: "普通", game: "游戏" },
        description: "类型"
      },
      outline: {
        description: "课程教案",
        ref: "KtasOutlineDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          serial: { type: "string", description: "编号" },
          brandId: { type: "number", description: "Brand TODO" },
          workflowState: {
            type: "string",
            required: false,
            enum: ["new", "certificated", "abandoned"],
            "x-i18n": {
              new: "待认证",
              certificated: "已认证",
              abandoned: "已废弃"
            },
            description: "状态"
          },
          workout: {
            description: "课程品类",
            ref: "KtasWorkoutDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              duration: { type: "number", description: "时长" },
              abbr: { type: "string", description: "缩写" },
              stageList: {
                type: "array",
                items: { type: "string" },
                description: "年龄阶段"
              },
              brandId: { type: "number", description: "Brand TODO" },
              intro: { type: "object", description: "介绍" },
              active: { type: "boolean", description: "激活" }
            }
          },
          intro: { type: "string", description: "课程介绍" },
          physicalQualityList: {
            type: "array",
            required: false,
            items: {
              type: "string",
              enum: ["综合素质"],
              "x-i18n": { 综合素质: "综合素质" }
            },
            description: "身体素质"
          },
          actionList: {
            type: "array",
            required: false,
            items: {
              type: "string",
              enum: ["抓握", "蹦跳", "爬行", "跑步"],
              "x-i18n": {
                抓握: "抓握",
                蹦跳: "蹦跳",
                爬行: "爬行",
                跑步: "跑步"
              }
            },
            description: "动作模式"
          },
          skillList: {
            type: "array",
            required: false,
            items: {
              type: "string",
              enum: ["支撑", "翻越", "蹦跳", "跑步", "抓握"],
              "x-i18n": {
                支撑: "支撑",
                翻越: "翻越",
                蹦跳: "蹦跳",
                跑步: "跑步",
                抓握: "抓握"
              }
            },
            description: "动作技能"
          },
          characterList: {
            type: "array",
            required: false,
            items: {
              type: "string",
              enum: [
                "专注",
                "友善",
                "感恩",
                "守时",
                "责任",
                "主动",
                "宽容",
                "思考",
                "勇敢",
                "尊重",
                "勤劳",
                "独立",
                "规则",
                "诚实守信"
              ],
              "x-i18n": {
                专注: "专注",
                友善: "友善",
                感恩: "感恩",
                守时: "守时",
                责任: "责任",
                主动: "主动",
                宽容: "宽容",
                思考: "思考",
                勇敢: "勇敢",
                尊重: "尊重",
                勤劳: "勤劳",
                独立: "独立",
                规则: "规则",
                诚实守信: "诚实守信"
              }
            },
            description: "品格训练"
          }
        }
      },
      trainingMethodId: {
        type: "number",
        nullable: true,
        description: "训练方式"
      },
      gameIds: {
        type: "array",
        items: { type: "number" },
        description: "推荐游戏"
      },
      video: {
        description: "视频",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      exerciseList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["动作1", "动作2"],
          "x-i18n": { 动作1: "动作1", 动作2: "动作2" }
        },
        description: "推荐动作"
      },
      coachingTipList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["喝水", "拍照"],
          "x-i18n": { 喝水: "喝水", 拍照: "拍照" }
        },
        description: "教练提示"
      },
      duration: { type: "number", description: "时长" },
      desc: { type: "object", description: "介绍" },
      coachingTips: { type: "object", description: "教案" },
      position: { type: "number", description: "排序" }
    }
  },
  KtasOutlineDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      brandId: { type: "number", description: "Brand TODO" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "abandoned"],
        "x-i18n": {
          new: "待认证",
          certificated: "已认证",
          abandoned: "已废弃"
        },
        description: "状态"
      },
      workout: { description: "课程品类" },
      intro: { type: "string", description: "课程介绍" },
      physicalQualityList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["综合素质"],
          "x-i18n": { 综合素质: "综合素质" }
        },
        description: "身体素质"
      },
      actionList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["抓握", "蹦跳", "爬行", "跑步"],
          "x-i18n": { 抓握: "抓握", 蹦跳: "蹦跳", 爬行: "爬行", 跑步: "跑步" }
        },
        description: "动作模式"
      },
      skillList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["支撑", "翻越", "蹦跳", "跑步", "抓握"],
          "x-i18n": {
            支撑: "支撑",
            翻越: "翻越",
            蹦跳: "蹦跳",
            跑步: "跑步",
            抓握: "抓握"
          }
        },
        description: "动作技能"
      },
      characterList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: [
            "专注",
            "友善",
            "感恩",
            "守时",
            "责任",
            "主动",
            "宽容",
            "思考",
            "勇敢",
            "尊重",
            "勤劳",
            "独立",
            "规则",
            "诚实守信"
          ],
          "x-i18n": {
            专注: "专注",
            友善: "友善",
            感恩: "感恩",
            守时: "守时",
            责任: "责任",
            主动: "主动",
            宽容: "宽容",
            思考: "思考",
            勇敢: "勇敢",
            尊重: "尊重",
            勤劳: "勤劳",
            独立: "独立",
            规则: "规则",
            诚实守信: "诚实守信"
          }
        },
        description: "品格训练"
      },
      sections: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            category: {
              type: "string",
              required: false,
              enum: ["normal", "game"],
              "x-i18n": { normal: "普通", game: "游戏" },
              description: "类型"
            },
            trainingMethod: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/KtasTrainingMethodDefault" }
              ],
              description: "训练方式"
            },
            video: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "视频"
            },
            games: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  desc: { type: "object", description: "介绍" },
                  preferList: {
                    type: "array",
                    items: { type: "string" },
                    description: "适用"
                  },
                  active: { type: "boolean", description: "激活" }
                }
              },
              description: "Games TODO",
              ref: "KtasGameDefault"
            },
            gameIds: {
              type: "array",
              items: { type: "number" },
              description: "推荐游戏"
            },
            duration: { type: "number", description: "时长" },
            desc: { type: "object", description: "介绍" },
            coachingTips: { type: "object", description: "教案" },
            exerciseList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: ["动作1", "动作2"],
                "x-i18n": { 动作1: "动作1", 动作2: "动作2" }
              },
              description: "推荐动作"
            },
            coachingTipList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: ["喝水", "拍照"],
                "x-i18n": { 喝水: "喝水", 拍照: "拍照" }
              },
              description: "教练提示"
            },
            position: { type: "number", description: "排序" }
          }
        },
        description: "Sections TODO",
        ref: "KtasSectionDefault"
      },
      video: { type: "object", nullable: true, description: "介绍视频" }
    }
  },
  KtasOutlineForm: {
    type: "object",
    properties: {
      serial: { type: "string", description: "编号" },
      physicalQualityList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["综合素质"],
          "x-i18n": { 综合素质: "综合素质" }
        },
        description: "身体素质"
      },
      actionList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["抓握", "蹦跳", "爬行", "跑步"],
          "x-i18n": { 抓握: "抓握", 蹦跳: "蹦跳", 爬行: "爬行", 跑步: "跑步" }
        },
        description: "动作模式"
      },
      skillList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["支撑", "翻越", "蹦跳", "跑步", "抓握"],
          "x-i18n": {
            支撑: "支撑",
            翻越: "翻越",
            蹦跳: "蹦跳",
            跑步: "跑步",
            抓握: "抓握"
          }
        },
        description: "动作技能"
      },
      characterList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: [
            "专注",
            "友善",
            "感恩",
            "守时",
            "责任",
            "主动",
            "宽容",
            "思考",
            "勇敢",
            "尊重",
            "勤劳",
            "独立",
            "规则",
            "诚实守信"
          ],
          "x-i18n": {
            专注: "专注",
            友善: "友善",
            感恩: "感恩",
            守时: "守时",
            责任: "责任",
            主动: "主动",
            宽容: "宽容",
            思考: "思考",
            勇敢: "勇敢",
            尊重: "尊重",
            勤劳: "勤劳",
            独立: "独立",
            规则: "规则",
            诚实守信: "诚实守信"
          }
        },
        description: "品格训练"
      },
      intro: { type: "string", description: "课程介绍" },
      video: {
        description: "介绍视频",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      }
    }
  },
  StudioZoneList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "场地名称" },
      position: { type: "number", description: "排序" },
      useHrm: { type: "boolean", description: "使用心率" },
      eliteTraining: { type: "boolean", description: "可上私教" },
      tasExpiried: { type: "boolean", description: "Tas expiried TODO" },
      studio: {
        description: "门店",
        ref: "StudioStudioList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          fullName: { type: "string", description: "门店全称" },
          shortName: { type: "string", description: "门店名称" }
        }
      }
    }
  },
  StudioBlockBrief: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      blockTypeName: { type: "string", description: "名称" },
      active: { type: "boolean", description: "激活" }
    }
  },
  StudioBlockDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      blockTypeName: { type: "string", description: "名称" },
      zoneId: { type: "number", description: "场地" },
      blockTypeId: { type: "number", description: "训练区类型" }
    }
  },
  StudioBlockForm: {
    type: "object",
    properties: {
      serial: { type: "string", description: "编号" },
      blockTypeId: { type: "number", description: "训练区类型" }
    }
  },
  StudioZoneDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "场地名称" },
      screenNum: {
        type: "string",
        required: true,
        enum: ["1", "2", "3", "6"],
        "x-i18n": {
          "1": "单屏幕",
          "2": "双屏幕",
          "3": "三屏幕",
          "6": "六屏幕"
        },
        description: "屏幕数量"
      },
      bgmBlockNo: { type: "string", description: "BGM主机编号" },
      hrmUrl: { type: "string", nullable: true, description: "心率系统 URL" },
      useHrm: { type: "boolean", description: "使用心率" },
      hrmDeviceNumber: { type: "string", description: "心率设备编号" },
      blocks: { type: "object", nullable: true, description: "Blocks TODO" },
      maxCapacity: { type: "number", nullable: true, description: "最大容量" },
      positionSelectable: { type: "boolean", description: "可以选位" },
      position: { type: "number", description: "排序" },
      eliteTraining: { type: "boolean", description: "可上私教" },
      active: { type: "boolean", description: "激活" }
    }
  },
  StudioZoneTas: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "number", description: "场地名称" },
      blocks: { type: "number", description: "Blocks TODO" },
      tasHostIp: { type: "string", description: "Tas host ip TODO" }
    }
  },
  StudioZoneTasForm: {
    type: "object",
    properties: {
      tasHostIp: { type: "string", description: "Tas host ip TODO" }
    }
  },
  StudioZoneForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "场地名称" },
      maxCapacity: { type: "number", required: true, description: "最大容量" },
      bgmBlockNo: {
        type: "string",
        required: true,
        enum: ["A", "B", "C", "D", "E", "F"],
        "x-i18n": { A: "A", B: "B", C: "C", D: "D", E: "E", F: "F" },
        description: "BGM主机编号"
      },
      useHrm: { type: "boolean", nullable: true, description: "使用心率" },
      screenNum: {
        type: "string",
        required: true,
        enum: ["1", "2", "3", "6"],
        "x-i18n": {
          "1": "单屏幕",
          "2": "双屏幕",
          "3": "三屏幕",
          "6": "六屏幕"
        },
        description: "屏幕数量"
      },
      positionSelectable: { type: "boolean", description: "可以选位" },
      position: { type: "number", description: "排序" },
      eliteTraining: { type: "boolean", description: "可上私教" },
      active: { type: "boolean", description: "激活" }
    }
  },
  CourseGroupTrainingSchedule: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "课程英文名称" },
      category: {
        type: "string",
        required: false,
        enum: ["normal", "buyout", "event", "test"],
        "x-i18n": {
          normal: "普通",
          buyout: "包场",
          event: "活动",
          test: "测试"
        },
        description: "类型"
      },
      categoryCn: { type: "string", description: "Category cn TODO" },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "产品类型"
      },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      endTime: {
        description: "结束时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      capacity: { type: "number", description: "容量" },
      state: { type: "string", description: "状态" },
      primaryCoach: {
        description: "教练",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      assistantCoaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" }
          }
        },
        description: "助教",
        ref: "CoachCoachEssential"
      },
      workoutOutline: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/TasWorkoutOutlineList" }
        ],
        description: "套路"
      },
      ktasOutline: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/KtasOutlineDefault" }],
        description: "课程教案"
      },
      tagList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["新课体验", "新套路", "新教练"],
          "x-i18n": {
            新课体验: "新课体验",
            新套路: "新套路",
            新教练: "新教练"
          }
        },
        description: "标签"
      },
      bookingCount: { type: "number", description: "预约人数" },
      homework: { type: "string", description: "课后作业" },
      zoneId: { type: "number", description: "场地" },
      zone: {
        description: "Zone TODO",
        ref: "StudioZoneList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "场地名称" },
          position: { type: "number", description: "排序" },
          useHrm: { type: "boolean", description: "使用心率" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          tasExpiried: { type: "boolean", description: "Tas expiried TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" }
            }
          }
        }
      },
      individualPrice: { type: "string", description: "单独定价" },
      published: { type: "boolean", description: "展示" }
    }
  },
  CourseGroupTrainingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "课程英文名称" },
      product: {
        description: "训练产品",
        ref: "CourseProductDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          brandId: { type: "number", description: "品牌" },
          name: { type: "string", description: "课程名称" },
          enName: { type: "string", description: "英文名称" },
          usageCn: { type: "string", description: "Usage cn TODO" },
          forKid: { type: "boolean", description: "For kid TODO" },
          stageList: {
            type: "array",
            items: { type: "string" },
            description: "Stage list TODO"
          },
          provider: { type: "string", description: "产品品牌" },
          expiryDate: {
            description: "授权截止",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          category: {
            type: "string",
            required: false,
            enum: ["brand", "platform"],
            "x-i18n": { brand: "自研产品", platform: "授权产品" },
            description: "类型"
          },
          productType: {
            type: "string",
            required: false,
            enum: ["group_training", "elite_training", "camp", "free_training"],
            "x-i18n": {
              group_training: "团课",
              elite_training: "私教",
              camp: "训练营",
              free_training: "自由训练"
            },
            description: "类型"
          },
          scheduleRule: {
            type: "string",
            required: false,
            enum: ["default", "follow_zone", "follow_coach"],
            "x-i18n": {
              default: "默认",
              follow_zone: "场地记忆",
              follow_coach: "教练记忆"
            },
            description: "Schedule rule TODO"
          },
          entryPrice: { type: "string", description: "Entry price TODO" },
          active: { type: "boolean", description: "激活" },
          price: { type: "string", description: "价格" },
          prepayPrice: { type: "string", description: "储值卡支付价格" },
          workoutId: { type: "number", description: "训练课程" },
          duration: { type: "number", description: "课程时长" },
          courseFee: { type: "string", description: "主教课时费" },
          assistantCourseFee: { type: "string", description: "助教课时费" },
          trialCourseFee: { type: "string", description: "实习课时费" },
          intro: { type: "string", nullable: true, description: "课程介绍" },
          groundType: {
            type: "string",
            required: false,
            enum: ["indoor", "outdoor"],
            "x-i18n": { indoor: "室内", outdoor: "户外" },
            description: "Ground type TODO"
          },
          academy: { type: "boolean", description: "提供培训" },
          note: { type: "string", description: "授权备注" },
          live: {
            description: "课程Live",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          banners: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "封面图",
            ref: "AttachmentDefault"
          },
          coachProductId: { type: "number", description: "Coach product TODO" },
          tagList: {
            type: "array",
            items: { type: "string", enum: [] },
            description: "标签"
          },
          paymentMethods: {
            type: "array",
            items: {
              type: "string",
              required: false,
              enum: ["wechat", "prepay_card", "membership_card", "coupon"],
              "x-i18n": {
                wechat: "微信支付",
                prepay_card: "储值账户",
                membership_card: "会员卡",
                coupon: "代金券"
              }
            },
            description: "支付方式"
          },
          hasConfirmCourseThreshold: {
            type: "boolean",
            description: "单独设置开课限制"
          },
          confirmCourseThreshold: {
            type: "number",
            description: "最低开课人数"
          },
          confirmCourseDeadline: {
            type: "number",
            description: "开课确认时间"
          },
          defaultCaloriesTarget: {
            type: "number",
            description: "默认卡路里目标"
          },
          tasType: {
            type: "string",
            required: false,
            enum: ["tas", "hrm", "countdown"],
            "x-i18n": {
              tas: "内容辅助",
              hrm: "心率辅助",
              countdown: "时间辅助"
            },
            description: "辅助系统配置"
          },
          multiScreenVersions: {
            type: "array",
            items: { type: "number" },
            description: "多屏幕适配"
          },
          displayed: { type: "boolean", description: "Displayed TODO" },
          useHrm: { type: "boolean", description: "使用心率" },
          position: { type: "number", description: "排序" }
        }
      },
      category: {
        type: "string",
        required: false,
        enum: ["normal", "buyout", "event", "test"],
        "x-i18n": {
          normal: "普通",
          buyout: "包场",
          event: "活动",
          test: "测试"
        },
        description: "类型"
      },
      categoryCn: { type: "string", description: "Category cn TODO" },
      tagList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["新课体验", "新套路", "新教练"],
          "x-i18n": {
            新课体验: "新课体验",
            新套路: "新套路",
            新教练: "新教练"
          }
        },
        description: "标签"
      },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      endTime: {
        description: "结束时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      capacity: { type: "number", description: "容量" },
      hasConfirmCourseThreshold: {
        type: "boolean",
        description: "单独设置开课限制"
      },
      confirmCourseThreshold: { type: "number", description: "最低开课人数" },
      confirmCourseDeadline: { type: "number", description: "开课确认时间" },
      state: { type: "string", description: "状态" },
      primaryCoach: {
        description: "教练",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      assistantCoaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" }
          }
        },
        description: "助教",
        ref: "CoachCoachEssential"
      },
      assistantCoachIds: {
        type: "array",
        items: { type: "number" },
        description: "助教"
      },
      bookingCount: { type: "number", description: "预约人数" },
      ktasOutline: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/KtasOutlineDefault" }],
        description: "课程教案"
      },
      workoutOutline: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/TasWorkoutOutlineList" }
        ],
        description: "套路"
      },
      packageId: { type: "number", nullable: true, description: "课程包" },
      zoneId: { type: "number", description: "场地" },
      zone: {
        description: "Zone TODO",
        ref: "StudioZoneList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "场地名称" },
          position: { type: "number", description: "排序" },
          useHrm: { type: "boolean", description: "使用心率" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          tasExpiried: { type: "boolean", description: "Tas expiried TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" }
            }
          }
        }
      },
      homework: { type: "string", description: "课后作业" },
      individualPrice: { type: "string", description: "单独定价" },
      published: { type: "boolean", description: "展示" },
      notes: { type: "string", description: "备注" },
      token: { type: "string", description: "Token TODO" },
      bookFree: { type: "boolean", description: "Book free TODO" }
    }
  },
  CourseGroupTrainingForm: {
    type: "object",
    properties: {
      productId: { type: "number", required: true, description: "训练产品" },
      zoneId: { type: "number", required: true, description: "场地" },
      startTime: { type: "object", required: true, description: "开始时间" },
      ignoreTimeConflict: { type: "boolean", description: "忽略课程时间冲突" },
      capacity: { type: "number", required: true, description: "容量" },
      category: {
        type: "string",
        required: true,
        enum: ["normal", "buyout", "event", "test"],
        "x-i18n": {
          normal: "普通",
          buyout: "包场",
          event: "活动",
          test: "测试"
        },
        description: "类型"
      },
      packageId: { type: "number", nullable: true, description: "课程包" },
      workoutOutlineId: { type: "number", nullable: true, description: "套路" },
      ktasOutlineId: {
        type: "number",
        nullable: true,
        description: "课程教案"
      },
      primaryCoachId: { type: "number", description: "教练" },
      assistantCoachIds: {
        type: "array",
        items: { type: "number" },
        description: "助教"
      },
      tagList: {
        type: "array",
        required: false,
        items: {
          type: "string",
          enum: ["新课体验", "新套路", "新教练"],
          "x-i18n": {
            新课体验: "新课体验",
            新套路: "新套路",
            新教练: "新教练"
          }
        },
        description: "标签"
      },
      homework: { type: "string", description: "课后作业" },
      published: { type: "boolean", description: "展示" },
      devicesReboot: { type: "boolean", description: "Devices reboot TODO" },
      hasConfirmCourseThreshold: {
        type: "boolean",
        description: "单独设置开课限制"
      },
      confirmCourseThreshold: { type: "number", description: "最低开课人数" },
      confirmCourseDeadline: { type: "number", description: "开课确认时间" },
      coverOtherCourses: {
        type: "boolean",
        description: "Cover other courses TODO"
      },
      individualPrice: { type: "string", description: "单独定价" },
      notes: { type: "string", description: "备注" }
    }
  },
  CourseGroupTrainingCancelForm: {
    type: "object",
    properties: { cancelReason: { type: "string", description: "取消原因" } }
  },
  CourseGroupTrainingUpdateOutlineForm: {
    type: "object",
    properties: {
      outlineId: { type: "number", required: true, description: "套路" },
      scope: {
        type: "string",
        required: false,
        enum: ["single", "all"],
        "x-i18n": { single: "当堂课程", all: "所有后续课程" },
        description: "Scope TODO"
      }
    }
  },
  CourseGroupTrainingMultiUpdateForm: {
    type: "object",
    properties: {
      courseIds: { type: "object", required: true, description: "课程" },
      productId: { type: "number", description: "训练产品" },
      primaryCoachId: { type: "number", description: "教练" },
      assistantCoachIds: {
        type: "array",
        items: { type: "number" },
        description: "助教"
      }
    }
  },
  CourseGroupTrainingCloneScheduleForm: {
    type: "object",
    properties: {
      zoneId: { type: "number", description: "场地" },
      targetZoneId: { type: "number", description: "目标场地" },
      sourceFrom: { type: "object", required: true, description: "源开始日期" },
      sourceTo: { type: "object", required: true, description: "源截至日期" },
      targetFrom: {
        type: "object",
        required: true,
        description: "目标开始日期"
      },
      skipFailure: { type: "boolean", description: "跳过失败" }
    }
  },
  CourseGroupTrainingPublishScheduleForm: {
    type: "object",
    properties: {
      from: { type: "object", required: true, description: "开始日期" },
      to: { type: "object", required: true, description: "结束日期" }
    }
  },
  CourseGroupTrainingPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            courseName: { type: "string", description: "课程名称" },
            enCourseName: { type: "string", description: "课程英文名称" },
            product: {
              description: "训练产品",
              ref: "CourseProductDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  required: false,
                  enum: ["default", "follow_zone", "follow_coach"],
                  "x-i18n": {
                    default: "默认",
                    follow_zone: "场地记忆",
                    follow_coach: "教练记忆"
                  },
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" },
                price: { type: "string", description: "价格" },
                prepayPrice: { type: "string", description: "储值卡支付价格" },
                workoutId: { type: "number", description: "训练课程" },
                duration: { type: "number", description: "课程时长" },
                courseFee: { type: "string", description: "主教课时费" },
                assistantCourseFee: {
                  type: "string",
                  description: "助教课时费"
                },
                trialCourseFee: { type: "string", description: "实习课时费" },
                intro: {
                  type: "string",
                  nullable: true,
                  description: "课程介绍"
                },
                groundType: {
                  type: "string",
                  required: false,
                  enum: ["indoor", "outdoor"],
                  "x-i18n": { indoor: "室内", outdoor: "户外" },
                  description: "Ground type TODO"
                },
                academy: { type: "boolean", description: "提供培训" },
                note: { type: "string", description: "授权备注" },
                live: {
                  description: "课程Live",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                banners: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "封面图",
                  ref: "AttachmentDefault"
                },
                coachProductId: {
                  type: "number",
                  description: "Coach product TODO"
                },
                tagList: {
                  type: "array",
                  items: { type: "string", enum: [] },
                  description: "标签"
                },
                paymentMethods: {
                  type: "array",
                  items: {
                    type: "string",
                    required: false,
                    enum: [
                      "wechat",
                      "prepay_card",
                      "membership_card",
                      "coupon"
                    ],
                    "x-i18n": {
                      wechat: "微信支付",
                      prepay_card: "储值账户",
                      membership_card: "会员卡",
                      coupon: "代金券"
                    }
                  },
                  description: "支付方式"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "单独设置开课限制"
                },
                confirmCourseThreshold: {
                  type: "number",
                  description: "最低开课人数"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "开课确认时间"
                },
                defaultCaloriesTarget: {
                  type: "number",
                  description: "默认卡路里目标"
                },
                tasType: {
                  type: "string",
                  required: false,
                  enum: ["tas", "hrm", "countdown"],
                  "x-i18n": {
                    tas: "内容辅助",
                    hrm: "心率辅助",
                    countdown: "时间辅助"
                  },
                  description: "辅助系统配置"
                },
                multiScreenVersions: {
                  type: "array",
                  items: { type: "number" },
                  description: "多屏幕适配"
                },
                displayed: { type: "boolean", description: "Displayed TODO" },
                useHrm: { type: "boolean", description: "使用心率" },
                position: { type: "number", description: "排序" }
              }
            },
            category: {
              type: "string",
              required: false,
              enum: ["normal", "buyout", "event", "test"],
              "x-i18n": {
                normal: "普通",
                buyout: "包场",
                event: "活动",
                test: "测试"
              },
              description: "类型"
            },
            categoryCn: { type: "string", description: "Category cn TODO" },
            tagList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: ["新课体验", "新套路", "新教练"],
                "x-i18n": {
                  新课体验: "新课体验",
                  新套路: "新套路",
                  新教练: "新教练"
                }
              },
              description: "标签"
            },
            startTime: {
              description: "开始时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            endTime: {
              description: "结束时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            capacity: { type: "number", description: "容量" },
            hasConfirmCourseThreshold: {
              type: "boolean",
              description: "单独设置开课限制"
            },
            confirmCourseThreshold: {
              type: "number",
              description: "最低开课人数"
            },
            confirmCourseDeadline: {
              type: "number",
              description: "开课确认时间"
            },
            state: { type: "string", description: "状态" },
            primaryCoach: {
              description: "教练",
              ref: "CoachCoachEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" }
              }
            },
            assistantCoaches: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" }
                }
              },
              description: "助教",
              ref: "CoachCoachEssential"
            },
            assistantCoachIds: {
              type: "array",
              items: { type: "number" },
              description: "助教"
            },
            bookingCount: { type: "number", description: "预约人数" },
            ktasOutline: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/KtasOutlineDefault" }
              ],
              description: "课程教案"
            },
            workoutOutline: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/TasWorkoutOutlineList" }
              ],
              description: "套路"
            },
            packageId: {
              type: "number",
              nullable: true,
              description: "课程包"
            },
            zoneId: { type: "number", description: "场地" },
            zone: {
              description: "Zone TODO",
              ref: "StudioZoneList",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "场地名称" },
                position: { type: "number", description: "排序" },
                useHrm: { type: "boolean", description: "使用心率" },
                eliteTraining: { type: "boolean", description: "可上私教" },
                tasExpiried: {
                  type: "boolean",
                  description: "Tas expiried TODO"
                },
                studio: {
                  description: "门店",
                  ref: "StudioStudioList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" }
                  }
                }
              }
            },
            homework: { type: "string", description: "课后作业" },
            individualPrice: { type: "string", description: "单独定价" },
            published: { type: "boolean", description: "展示" },
            notes: { type: "string", description: "备注" },
            token: { type: "string", description: "Token TODO" },
            bookFree: { type: "boolean", description: "Book free TODO" }
          }
        },
        ref: "CourseGroupTrainingDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CoachLevelPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            category: {
              description: "类型",
              ref: "CoachCategoryDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" }
              }
            },
            categoryId: { type: "number", description: "类型" }
          }
        },
        ref: "CoachLevelDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CoachCoachProductDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      coachProductId: { type: "number", description: "Coach product TODO" },
      name: { type: "string", description: "产品名称" },
      enName: { type: "string", description: "英文名" },
      provider: { type: "string", description: "Provider TODO" },
      duration: { type: "string", description: "时长" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "Tag list TODO"
      },
      expiryDate: {
        description: "过期时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      grantedAt: {
        description: "授权时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  CoachCoachProductForm: {
    type: "object",
    properties: {
      expiryDate: {
        description: "过期时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      }
    }
  },
  CoachCoachProductGrantForm: {
    type: "object",
    properties: {
      expiryDate: {
        description: "过期时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      productIds: {
        type: "array",
        items: { type: "number" },
        description: "训练产品"
      },
      applyToAllProduct: { type: "boolean", description: "授权所有训练产品" },
      note: { type: "string", description: "授权备注" }
    }
  },
  CoachCoachProductOperating: {
    type: "object",
    properties: {
      coach: {
        description: "Coach TODO",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      productName: { type: "string", description: "Product name TODO" },
      note: { type: "string", description: "授权备注" },
      expiryDate: {
        description: "过期时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      }
    }
  },
  TasWorkoutBrief: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "英文名" },
      productType: { type: "string", description: "产品类型" }
    }
  },
  TasWorkoutEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      abbr: { type: "string", description: "缩写" },
      enName: { type: "string", description: "英文名" },
      category: {
        type: "string",
        enum: [
          { 自研: "自研" },
          { 团操课: "团操课" },
          { 其他: "其他" },
          { 训练营: "训练营" }
        ],
        description: "类型"
      },
      defaultCaloriesTarget: {
        type: "number",
        description: "PowerHeart默认卡路里(Cal)"
      },
      trainingMethod: {
        type: "string",
        enum: [
          { offline: "线下训练" },
          { live: "线上课程" },
          { homework: "家庭作业" }
        ],
        description: "训练方式"
      },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "排课规则"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营"
        },
        description: "产品类型"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentDefault"
      },
      intro: { type: "object", description: "介绍" },
      provider: {
        type: "string",
        required: false,
        enum: [
          "PowerGroup 精品小团课",
          "Power 45 精品团课",
          "POWER45+",
          "POWER45(2023版)",
          "P45培训",
          "MCPOWER",
          "BodyLight",
          "P45内容库",
          "团操课",
          "燃脂舞蹈",
          "CrossFit",
          "MCFATREBEL",
          "TTT",
          "场馆支持",
          "其他"
        ],
        "x-i18n": {
          "PowerGroup 精品小团课": "PowerGroup 精品小团课",
          "Power 45 精品团课": "Power 45 精品团课",
          "POWER45+": "POWER45+",
          "POWER45(2023版)": "POWER45(2023版)",
          P45培训: "P45培训",
          MCPOWER: "MCPOWER",
          BodyLight: "BodyLight",
          P45内容库: "P45内容库",
          团操课: "团操课",
          燃脂舞蹈: "燃脂舞蹈",
          CrossFit: "CrossFit",
          MCFATREBEL: "MCFATREBEL",
          TTT: "TTT",
          场馆支持: "场馆支持",
          其他: "其他"
        },
        description: "产品品牌"
      },
      duration: { type: "number", description: "时长" },
      usage: {
        type: "string",
        required: false,
        enum: ["default", "template"],
        "x-i18n": { default: "用于授权", template: "用于模板" },
        description: "使用方式"
      },
      usageCn: { type: "string", description: "Usage cn TODO" },
      coachingMode: { type: "boolean", description: "白板模式" },
      coachingModeTemplate: { type: "boolean", description: "白板课程模板" },
      tasType: { type: "string", description: "Tas type TODO" },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasWorkoutDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      abbr: { type: "string", description: "缩写" },
      enName: { type: "string", description: "英文名" },
      category: {
        type: "string",
        enum: [
          { 自研: "自研" },
          { 团操课: "团操课" },
          { 其他: "其他" },
          { 训练营: "训练营" }
        ],
        description: "类型"
      },
      defaultCaloriesTarget: {
        type: "number",
        description: "PowerHeart默认卡路里(Cal)"
      },
      trainingMethod: {
        type: "string",
        enum: [
          { offline: "线下训练" },
          { live: "线上课程" },
          { homework: "家庭作业" }
        ],
        description: "训练方式"
      },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "排课规则"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营"
        },
        description: "产品类型"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentDefault"
      },
      intro: { type: "object", description: "介绍" },
      provider: {
        type: "string",
        required: false,
        enum: [
          "PowerGroup 精品小团课",
          "Power 45 精品团课",
          "POWER45+",
          "POWER45(2023版)",
          "P45培训",
          "MCPOWER",
          "BodyLight",
          "P45内容库",
          "团操课",
          "燃脂舞蹈",
          "CrossFit",
          "MCFATREBEL",
          "TTT",
          "场馆支持",
          "其他"
        ],
        "x-i18n": {
          "PowerGroup 精品小团课": "PowerGroup 精品小团课",
          "Power 45 精品团课": "Power 45 精品团课",
          "POWER45+": "POWER45+",
          "POWER45(2023版)": "POWER45(2023版)",
          P45培训: "P45培训",
          MCPOWER: "MCPOWER",
          BodyLight: "BodyLight",
          P45内容库: "P45内容库",
          团操课: "团操课",
          燃脂舞蹈: "燃脂舞蹈",
          CrossFit: "CrossFit",
          MCFATREBEL: "MCFATREBEL",
          TTT: "TTT",
          场馆支持: "场馆支持",
          其他: "其他"
        },
        description: "产品品牌"
      },
      duration: { type: "number", description: "时长" },
      usage: {
        type: "string",
        required: false,
        enum: ["default", "template"],
        "x-i18n": { default: "用于授权", template: "用于模板" },
        description: "使用方式"
      },
      usageCn: { type: "string", description: "Usage cn TODO" },
      coachingMode: { type: "boolean", description: "白板模式" },
      coachingModeTemplate: { type: "boolean", description: "白板课程模板" },
      tasType: { type: "string", description: "Tas type TODO" },
      active: { type: "boolean", description: "激活" },
      workoutGroupId: { type: "number", description: "Workout group TODO" },
      saleType: { type: "string", description: "Sale type TODO" },
      displayed: { type: "boolean", description: "Displayed TODO" },
      appropriateCrowd: {
        type: "object",
        description: "Appropriate crowd TODO"
      },
      courseFeature: { type: "string", description: "Course feature TODO" },
      logo: { type: "string", description: "Logo" },
      textLogo: { type: "string", description: "Text logo TODO" },
      background: { type: "string", description: "Background TODO" },
      detailIntro: { type: "object", description: "Detail intro TODO" },
      useHrm: { type: "boolean", description: "是否使用心率系统" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      productImages: { type: "string", description: "Product images TODO" }
    }
  },
  TasWorkoutDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      abbr: { type: "string", description: "缩写" },
      enName: { type: "string", description: "英文名" },
      category: {
        type: "string",
        enum: [
          { 自研: "自研" },
          { 团操课: "团操课" },
          { 其他: "其他" },
          { 训练营: "训练营" }
        ],
        description: "类型"
      },
      defaultCaloriesTarget: {
        type: "number",
        description: "PowerHeart默认卡路里(Cal)"
      },
      trainingMethod: {
        type: "string",
        enum: [
          { offline: "线下训练" },
          { live: "线上课程" },
          { homework: "家庭作业" }
        ],
        description: "训练方式"
      },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "排课规则"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营"
        },
        description: "产品类型"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentDefault"
      },
      intro: { type: "object", description: "介绍" },
      provider: {
        type: "string",
        required: false,
        enum: [
          "PowerGroup 精品小团课",
          "Power 45 精品团课",
          "POWER45+",
          "POWER45(2023版)",
          "P45培训",
          "MCPOWER",
          "BodyLight",
          "P45内容库",
          "团操课",
          "燃脂舞蹈",
          "CrossFit",
          "MCFATREBEL",
          "TTT",
          "场馆支持",
          "其他"
        ],
        "x-i18n": {
          "PowerGroup 精品小团课": "PowerGroup 精品小团课",
          "Power 45 精品团课": "Power 45 精品团课",
          "POWER45+": "POWER45+",
          "POWER45(2023版)": "POWER45(2023版)",
          P45培训: "P45培训",
          MCPOWER: "MCPOWER",
          BodyLight: "BodyLight",
          P45内容库: "P45内容库",
          团操课: "团操课",
          燃脂舞蹈: "燃脂舞蹈",
          CrossFit: "CrossFit",
          MCFATREBEL: "MCFATREBEL",
          TTT: "TTT",
          场馆支持: "场馆支持",
          其他: "其他"
        },
        description: "产品品牌"
      },
      duration: { type: "number", description: "时长" },
      usage: {
        type: "string",
        required: false,
        enum: ["default", "template"],
        "x-i18n": { default: "用于授权", template: "用于模板" },
        description: "使用方式"
      },
      usageCn: { type: "string", description: "Usage cn TODO" },
      coachingMode: { type: "boolean", description: "白板模式" },
      coachingModeTemplate: { type: "boolean", description: "白板课程模板" },
      tasType: { type: "string", description: "Tas type TODO" },
      active: { type: "boolean", description: "激活" },
      workoutGroupId: { type: "number", description: "Workout group TODO" },
      saleType: { type: "string", description: "Sale type TODO" },
      displayed: { type: "boolean", description: "Displayed TODO" },
      appropriateCrowd: {
        type: "object",
        description: "Appropriate crowd TODO"
      },
      courseFeature: { type: "string", description: "Course feature TODO" },
      logo: { type: "string", description: "Logo" },
      textLogo: { type: "string", description: "Text logo TODO" },
      background: { type: "string", description: "Background TODO" },
      detailIntro: { type: "object", description: "Detail intro TODO" },
      useHrm: { type: "boolean", description: "是否使用心率系统" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      productImages: { type: "string", description: "Product images TODO" },
      live: {
        description: "课程Live",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      rhythmBgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "节奏BGM曲库"
      }
    }
  },
  TasWorkoutForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "英文名" },
      abbr: { type: "string", description: "缩写" },
      category: { type: "string", description: "类型" },
      duration: { type: "number", description: "时长" },
      provider: {
        type: "string",
        required: false,
        enum: [
          "PowerGroup 精品小团课",
          "Power 45 精品团课",
          "POWER45+",
          "POWER45(2023版)",
          "P45培训",
          "MCPOWER",
          "BodyLight",
          "P45内容库",
          "团操课",
          "燃脂舞蹈",
          "CrossFit",
          "MCFATREBEL",
          "TTT",
          "场馆支持",
          "其他"
        ],
        "x-i18n": {
          "PowerGroup 精品小团课": "PowerGroup 精品小团课",
          "Power 45 精品团课": "Power 45 精品团课",
          "POWER45+": "POWER45+",
          "POWER45(2023版)": "POWER45(2023版)",
          P45培训: "P45培训",
          MCPOWER: "MCPOWER",
          BodyLight: "BodyLight",
          P45内容库: "P45内容库",
          团操课: "团操课",
          燃脂舞蹈: "燃脂舞蹈",
          CrossFit: "CrossFit",
          MCFATREBEL: "MCFATREBEL",
          TTT: "TTT",
          场馆支持: "场馆支持",
          其他: "其他"
        },
        description: "产品品牌"
      },
      live: {
        description: "课程Live",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            signedId: { type: "string", description: "Signed ID" },
            remoteUrl: {
              type: "string",
              nullable: true,
              description: "文件远程 URL"
            },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentForm"
      },
      rhythmBgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "节奏BGM曲库"
      },
      trainingMethod: { type: "string", description: "训练方式" },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营"
        },
        description: "产品类型"
      },
      academy: { type: "boolean", description: "Academy TODO" },
      defaultCaloriesTarget: {
        type: "number",
        description: "PowerHeart默认卡路里(Cal)"
      },
      tasType: { type: "string", description: "Tas type TODO" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      usage: {
        type: "string",
        required: false,
        enum: ["default", "template"],
        "x-i18n": { default: "用于授权", template: "用于模板" },
        description: "使用方式"
      },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "排课规则"
      },
      intro: { type: "object", description: "介绍" },
      useHrm: { type: "boolean", description: "是否使用心率系统" },
      coachingMode: { type: "boolean", description: "白板模式" },
      coachingModeTemplate: { type: "boolean", description: "白板课程模板" },
      active: { type: "boolean", description: "激活" }
    }
  },
  CouponCouponPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            value: { type: "string", description: "面值" },
            fullDiscount: { type: "boolean", description: "全额代金券" },
            availableTimeRange: {
              type: "array",
              items: { type: "string" },
              description: "可用时段"
            },
            validityPeriod: { type: "number", description: "有效期" },
            usageThreshold: { type: "string", description: "使用门槛" },
            applyToAllStudio: { type: "boolean", description: "所有门店可用" },
            studios: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" }
                }
              },
              description: "应用门店",
              ref: "StudioStudioList"
            },
            applyToAllProduct: {
              type: "boolean",
              description: "所有训练产品可用"
            },
            products: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  brandId: { type: "number", description: "品牌" },
                  name: { type: "string", description: "课程名称" },
                  enName: { type: "string", description: "英文名称" },
                  usageCn: { type: "string", description: "Usage cn TODO" },
                  forKid: { type: "boolean", description: "For kid TODO" },
                  stageList: {
                    type: "array",
                    items: { type: "string" },
                    description: "Stage list TODO"
                  },
                  provider: { type: "string", description: "产品品牌" },
                  expiryDate: {
                    description: "授权截止",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  category: {
                    type: "string",
                    required: false,
                    enum: ["brand", "platform"],
                    "x-i18n": { brand: "自研产品", platform: "授权产品" },
                    description: "类型"
                  },
                  productType: {
                    type: "string",
                    required: false,
                    enum: [
                      "group_training",
                      "elite_training",
                      "camp",
                      "free_training"
                    ],
                    "x-i18n": {
                      group_training: "团课",
                      elite_training: "私教",
                      camp: "训练营",
                      free_training: "自由训练"
                    },
                    description: "类型"
                  },
                  scheduleRule: {
                    type: "string",
                    description: "Schedule rule TODO"
                  },
                  entryPrice: {
                    type: "string",
                    description: "Entry price TODO"
                  },
                  active: { type: "boolean", description: "激活" }
                }
              },
              description: "应用训练产品",
              ref: "CourseProductList"
            },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "CouponCouponDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CouponEventCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      coupon: {
        description: "代金券",
        ref: "CouponCouponDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          value: { type: "string", description: "面值" },
          fullDiscount: { type: "boolean", description: "全额代金券" },
          availableTimeRange: {
            type: "array",
            items: { type: "string" },
            description: "可用时段"
          },
          validityPeriod: { type: "number", description: "有效期" },
          usageThreshold: { type: "string", description: "使用门槛" },
          applyToAllStudio: { type: "boolean", description: "所有门店可用" },
          studios: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" }
              }
            },
            description: "应用门店",
            ref: "StudioStudioList"
          },
          applyToAllProduct: {
            type: "boolean",
            description: "所有训练产品可用"
          },
          products: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" }
              }
            },
            description: "应用训练产品",
            ref: "CourseProductList"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      couponQuantity: { type: "number", description: "单次发券数量" }
    }
  },
  CouponEventCouponForm: {
    type: "object",
    properties: {
      couponId: { type: "number", description: "代金券" },
      couponQuantity: { type: "number", description: "单次发券数量" }
    }
  },
  CouponEventDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      image: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "图片"
      },
      rule: { type: "string", description: "规则" },
      receiveMethod: {
        type: "string",
        required: false,
        enum: ["auto", "manual"],
        "x-i18n": { auto: "自动发放", manual: "手动领取" },
        description: "获取方式"
      },
      active: { type: "boolean", description: "激活" },
      variableCount: { type: "number", description: "可设变量个数" },
      variables: {
        type: "array",
        items: { type: "number" },
        description: "变量值"
      }
    }
  },
  CouponEventForm: {
    type: "object",
    properties: {
      image: {
        description: "图片",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      receiveMethod: {
        type: "string",
        required: false,
        enum: ["auto", "manual"],
        "x-i18n": { auto: "自动发放", manual: "手动领取" },
        description: "获取方式"
      },
      active: { type: "boolean", description: "激活" },
      variables: {
        type: "array",
        items: { type: "number" },
        description: "变量值"
      }
    }
  },
  CouponEventRegistraionReward: {
    type: "object",
    properties: {
      coupons: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            coupon: {
              description: "代金券",
              ref: "CouponCouponDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                value: { type: "string", description: "面值" },
                fullDiscount: { type: "boolean", description: "全额代金券" },
                availableTimeRange: {
                  type: "array",
                  items: { type: "string" },
                  description: "可用时段"
                },
                validityPeriod: { type: "number", description: "有效期" },
                usageThreshold: { type: "string", description: "使用门槛" },
                applyToAllStudio: {
                  type: "boolean",
                  description: "所有门店可用"
                },
                studios: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  },
                  description: "应用门店",
                  ref: "StudioStudioList"
                },
                applyToAllProduct: {
                  type: "boolean",
                  description: "所有训练产品可用"
                },
                products: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      brandId: { type: "number", description: "品牌" },
                      name: { type: "string", description: "课程名称" },
                      enName: { type: "string", description: "英文名称" },
                      usageCn: { type: "string", description: "Usage cn TODO" },
                      forKid: { type: "boolean", description: "For kid TODO" },
                      stageList: {
                        type: "array",
                        items: { type: "string" },
                        description: "Stage list TODO"
                      },
                      provider: { type: "string", description: "产品品牌" },
                      expiryDate: {
                        description: "授权截止",
                        ref: "date",
                        type: "string",
                        format: "date",
                        example: "2018-01-01",
                        properties: {}
                      },
                      category: {
                        type: "string",
                        required: false,
                        enum: ["brand", "platform"],
                        "x-i18n": { brand: "自研产品", platform: "授权产品" },
                        description: "类型"
                      },
                      productType: {
                        type: "string",
                        required: false,
                        enum: [
                          "group_training",
                          "elite_training",
                          "camp",
                          "free_training"
                        ],
                        "x-i18n": {
                          group_training: "团课",
                          elite_training: "私教",
                          camp: "训练营",
                          free_training: "自由训练"
                        },
                        description: "类型"
                      },
                      scheduleRule: {
                        type: "string",
                        description: "Schedule rule TODO"
                      },
                      entryPrice: {
                        type: "string",
                        description: "Entry price TODO"
                      },
                      active: { type: "boolean", description: "激活" }
                    }
                  },
                  description: "应用训练产品",
                  ref: "CourseProductList"
                },
                active: { type: "boolean", description: "激活" }
              }
            },
            couponQuantity: { type: "number", description: "单次发券数量" }
          }
        },
        description: "代金券",
        ref: "CouponEventCouponDefault"
      }
    }
  },
  CouponPackageDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      introduction: { type: "string", description: "介绍" },
      image: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "图片"
      },
      purchaseQrCode: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "购买二维码"
      },
      price: { type: "string", description: "价格" },
      coupon: {
        description: "代金券",
        ref: "CouponCouponDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          value: { type: "string", description: "面值" },
          fullDiscount: { type: "boolean", description: "全额代金券" },
          availableTimeRange: {
            type: "array",
            items: { type: "string" },
            description: "可用时段"
          },
          validityPeriod: { type: "number", description: "有效期" },
          usageThreshold: { type: "string", description: "使用门槛" },
          applyToAllStudio: { type: "boolean", description: "所有门店可用" },
          studios: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" }
              }
            },
            description: "应用门店",
            ref: "StudioStudioList"
          },
          applyToAllProduct: {
            type: "boolean",
            description: "所有训练产品可用"
          },
          products: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" }
              }
            },
            description: "应用训练产品",
            ref: "CourseProductList"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      couponQuantity: { type: "number", description: "商品包含代金券张数" },
      salesType: {
        type: "string",
        required: false,
        enum: ["normal", "new_member", "purchase_limited"],
        "x-i18n": {
          normal: "普通",
          new_member: "新手专享",
          purchase_limited: "特惠（限购一次）"
        },
        description: "销售限制"
      },
      inventory: { type: "number", nullable: true, description: "商品库存" },
      purchaseCount: { type: "number", description: "销售数量" },
      availableTimeStart: {
        description: "上架时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      availableTimeEnd: {
        description: "下架时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      effectiveTime: {
        description: "代金券生效时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      display: { type: "boolean", description: "展示" },
      active: { type: "boolean", description: "激活" }
    }
  },
  CouponPackageForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      introduction: { type: "string", description: "介绍" },
      image: {
        description: "图片",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      price: { type: "number", description: "价格" },
      couponId: { type: "number", description: "代金券" },
      couponQuantity: { type: "number", description: "商品包含代金券张数" },
      salesType: {
        type: "string",
        required: false,
        enum: ["normal", "new_member", "purchase_limited"],
        "x-i18n": {
          normal: "普通",
          new_member: "新手专享",
          purchase_limited: "特惠（限购一次）"
        },
        description: "销售限制"
      },
      inventory: { type: "number", description: "商品库存" },
      availableTimeStart: {
        description: "上架时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      availableTimeEnd: {
        description: "下架时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      effectiveTime: {
        description: "代金券生效时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      display: { type: "boolean", description: "展示" },
      active: { type: "boolean", description: "激活" }
    }
  },
  CouponPackagePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            introduction: { type: "string", description: "介绍" },
            image: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "图片"
            },
            purchaseQrCode: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "购买二维码"
            },
            price: { type: "string", description: "价格" },
            coupon: {
              description: "代金券",
              ref: "CouponCouponDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                value: { type: "string", description: "面值" },
                fullDiscount: { type: "boolean", description: "全额代金券" },
                availableTimeRange: {
                  type: "array",
                  items: { type: "string" },
                  description: "可用时段"
                },
                validityPeriod: { type: "number", description: "有效期" },
                usageThreshold: { type: "string", description: "使用门槛" },
                applyToAllStudio: {
                  type: "boolean",
                  description: "所有门店可用"
                },
                studios: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  },
                  description: "应用门店",
                  ref: "StudioStudioList"
                },
                applyToAllProduct: {
                  type: "boolean",
                  description: "所有训练产品可用"
                },
                products: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      brandId: { type: "number", description: "品牌" },
                      name: { type: "string", description: "课程名称" },
                      enName: { type: "string", description: "英文名称" },
                      usageCn: { type: "string", description: "Usage cn TODO" },
                      forKid: { type: "boolean", description: "For kid TODO" },
                      stageList: {
                        type: "array",
                        items: { type: "string" },
                        description: "Stage list TODO"
                      },
                      provider: { type: "string", description: "产品品牌" },
                      expiryDate: {
                        description: "授权截止",
                        ref: "date",
                        type: "string",
                        format: "date",
                        example: "2018-01-01",
                        properties: {}
                      },
                      category: {
                        type: "string",
                        required: false,
                        enum: ["brand", "platform"],
                        "x-i18n": { brand: "自研产品", platform: "授权产品" },
                        description: "类型"
                      },
                      productType: {
                        type: "string",
                        required: false,
                        enum: [
                          "group_training",
                          "elite_training",
                          "camp",
                          "free_training"
                        ],
                        "x-i18n": {
                          group_training: "团课",
                          elite_training: "私教",
                          camp: "训练营",
                          free_training: "自由训练"
                        },
                        description: "类型"
                      },
                      scheduleRule: {
                        type: "string",
                        description: "Schedule rule TODO"
                      },
                      entryPrice: {
                        type: "string",
                        description: "Entry price TODO"
                      },
                      active: { type: "boolean", description: "激活" }
                    }
                  },
                  description: "应用训练产品",
                  ref: "CourseProductList"
                },
                active: { type: "boolean", description: "激活" }
              }
            },
            couponQuantity: {
              type: "number",
              description: "商品包含代金券张数"
            },
            salesType: {
              type: "string",
              required: false,
              enum: ["normal", "new_member", "purchase_limited"],
              "x-i18n": {
                normal: "普通",
                new_member: "新手专享",
                purchase_limited: "特惠（限购一次）"
              },
              description: "销售限制"
            },
            inventory: {
              type: "number",
              nullable: true,
              description: "商品库存"
            },
            purchaseCount: { type: "number", description: "销售数量" },
            availableTimeStart: {
              description: "上架时间",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            availableTimeEnd: {
              description: "下架时间",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            effectiveTime: {
              description: "代金券生效时间",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            display: { type: "boolean", description: "展示" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "CouponPackageDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CourseCourseEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      product: {
        description: "训练产品",
        ref: "CourseProductList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          brandId: { type: "number", description: "品牌" },
          name: { type: "string", description: "课程名称" },
          enName: { type: "string", description: "英文名称" },
          usageCn: { type: "string", description: "Usage cn TODO" },
          forKid: { type: "boolean", description: "For kid TODO" },
          stageList: {
            type: "array",
            items: { type: "string" },
            description: "Stage list TODO"
          },
          provider: { type: "string", description: "产品品牌" },
          expiryDate: {
            description: "授权截止",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          category: {
            type: "string",
            required: false,
            enum: ["brand", "platform"],
            "x-i18n": { brand: "自研产品", platform: "授权产品" },
            description: "类型"
          },
          productType: {
            type: "string",
            required: false,
            enum: ["group_training", "elite_training", "camp", "free_training"],
            "x-i18n": {
              group_training: "团课",
              elite_training: "私教",
              camp: "训练营",
              free_training: "自由训练"
            },
            description: "类型"
          },
          scheduleRule: { type: "string", description: "Schedule rule TODO" },
          entryPrice: { type: "string", description: "Entry price TODO" },
          active: { type: "boolean", description: "激活" }
        }
      },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      primaryCoach: {
        description: "教练",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      zone: {
        description: "场地",
        ref: "StudioZoneList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "场地名称" },
          position: { type: "number", description: "排序" },
          useHrm: { type: "boolean", description: "使用心率" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          tasExpiried: { type: "boolean", description: "Tas expiried TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" }
            }
          }
        }
      }
    }
  },
  CourseCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      name: { type: "string", description: "名称" },
      enName: { type: "string", description: "课程英文名称" },
      homework: { type: "string", description: "课后作业" },
      workflowState: { type: "string", description: "Workflow state TODO" },
      product: {
        description: "训练产品",
        ref: "CourseProductList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          brandId: { type: "number", description: "品牌" },
          name: { type: "string", description: "课程名称" },
          enName: { type: "string", description: "英文名称" },
          usageCn: { type: "string", description: "Usage cn TODO" },
          forKid: { type: "boolean", description: "For kid TODO" },
          stageList: {
            type: "array",
            items: { type: "string" },
            description: "Stage list TODO"
          },
          provider: { type: "string", description: "产品品牌" },
          expiryDate: {
            description: "授权截止",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          category: {
            type: "string",
            required: false,
            enum: ["brand", "platform"],
            "x-i18n": { brand: "自研产品", platform: "授权产品" },
            description: "类型"
          },
          productType: {
            type: "string",
            required: false,
            enum: ["group_training", "elite_training", "camp", "free_training"],
            "x-i18n": {
              group_training: "团课",
              elite_training: "私教",
              camp: "训练营",
              free_training: "自由训练"
            },
            description: "类型"
          },
          scheduleRule: { type: "string", description: "Schedule rule TODO" },
          entryPrice: { type: "string", description: "Entry price TODO" },
          active: { type: "boolean", description: "激活" }
        }
      },
      primaryCoach: {
        description: "教练",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      assistantCoaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" }
          }
        },
        description: "助教",
        ref: "CoachCoachEssential"
      },
      zone: {
        description: "场地",
        ref: "StudioZoneList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "场地名称" },
          position: { type: "number", description: "排序" },
          useHrm: { type: "boolean", description: "使用心率" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          tasExpiried: { type: "boolean", description: "Tas expiried TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" }
            }
          }
        }
      }
    }
  },
  CourseBookingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      workflowState: {
        type: "string",
        required: false,
        enum: [
          "new",
          "reserved",
          "queued",
          "checked_in",
          "absent",
          "cancelled"
        ],
        "x-i18n": {
          new: "未签到",
          reserved: "未支付",
          queued: "已排号",
          checked_in: "已签到",
          absent: "缺席",
          cancelled: "已取消"
        },
        description: "状态"
      },
      course: {
        description: "课程",
        ref: "CourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          name: { type: "string", description: "名称" },
          enName: { type: "string", description: "课程英文名称" },
          homework: { type: "string", description: "课后作业" },
          workflowState: { type: "string", description: "Workflow state TODO" },
          product: {
            description: "训练产品",
            ref: "CourseProductList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              brandId: { type: "number", description: "品牌" },
              name: { type: "string", description: "课程名称" },
              enName: { type: "string", description: "英文名称" },
              usageCn: { type: "string", description: "Usage cn TODO" },
              forKid: { type: "boolean", description: "For kid TODO" },
              stageList: {
                type: "array",
                items: { type: "string" },
                description: "Stage list TODO"
              },
              provider: { type: "string", description: "产品品牌" },
              expiryDate: {
                description: "授权截止",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              category: {
                type: "string",
                required: false,
                enum: ["brand", "platform"],
                "x-i18n": { brand: "自研产品", platform: "授权产品" },
                description: "类型"
              },
              productType: {
                type: "string",
                required: false,
                enum: [
                  "group_training",
                  "elite_training",
                  "camp",
                  "free_training"
                ],
                "x-i18n": {
                  group_training: "团课",
                  elite_training: "私教",
                  camp: "训练营",
                  free_training: "自由训练"
                },
                description: "类型"
              },
              scheduleRule: {
                type: "string",
                description: "Schedule rule TODO"
              },
              entryPrice: { type: "string", description: "Entry price TODO" },
              active: { type: "boolean", description: "激活" }
            }
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" }
            }
          },
          assistantCoaches: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" }
              }
            },
            description: "助教",
            ref: "CoachCoachEssential"
          },
          zone: {
            description: "场地",
            ref: "StudioZoneList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "场地名称" },
              position: { type: "number", description: "排序" },
              useHrm: { type: "boolean", description: "使用心率" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              tasExpiried: {
                type: "boolean",
                description: "Tas expiried TODO"
              },
              studio: {
                description: "门店",
                ref: "StudioStudioList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" }
                }
              }
            }
          }
        }
      }
    }
  },
  CourseBookingGroupTraining: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      workflowState: {
        type: "string",
        required: false,
        enum: [
          "new",
          "reserved",
          "queued",
          "checked_in",
          "absent",
          "cancelled"
        ],
        "x-i18n": {
          new: "未签到",
          reserved: "未支付",
          queued: "已排号",
          checked_in: "已签到",
          absent: "缺席",
          cancelled: "已取消"
        },
        description: "状态"
      },
      consumeType: { type: "string", description: "预约方式" },
      productTrainingCount: { type: "number", description: "该产品训练次数" },
      position: { type: "number", description: "位置" },
      number: { type: "string", description: "心率表编号" },
      coachComments: { type: "string", description: "教练点评" },
      cancelledAt: {
        description: "Cancelled at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      photos: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "训练照片",
        ref: "AttachmentDefault"
      },
      owner: {
        description: "所有人",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      course: {
        description: "课程",
        ref: "CourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          name: { type: "string", description: "名称" },
          enName: { type: "string", description: "课程英文名称" },
          homework: { type: "string", description: "课后作业" },
          workflowState: { type: "string", description: "Workflow state TODO" },
          product: {
            description: "训练产品",
            ref: "CourseProductList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              brandId: { type: "number", description: "品牌" },
              name: { type: "string", description: "课程名称" },
              enName: { type: "string", description: "英文名称" },
              usageCn: { type: "string", description: "Usage cn TODO" },
              forKid: { type: "boolean", description: "For kid TODO" },
              stageList: {
                type: "array",
                items: { type: "string" },
                description: "Stage list TODO"
              },
              provider: { type: "string", description: "产品品牌" },
              expiryDate: {
                description: "授权截止",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              category: {
                type: "string",
                required: false,
                enum: ["brand", "platform"],
                "x-i18n": { brand: "自研产品", platform: "授权产品" },
                description: "类型"
              },
              productType: {
                type: "string",
                required: false,
                enum: [
                  "group_training",
                  "elite_training",
                  "camp",
                  "free_training"
                ],
                "x-i18n": {
                  group_training: "团课",
                  elite_training: "私教",
                  camp: "训练营",
                  free_training: "自由训练"
                },
                description: "类型"
              },
              scheduleRule: {
                type: "string",
                description: "Schedule rule TODO"
              },
              entryPrice: { type: "string", description: "Entry price TODO" },
              active: { type: "boolean", description: "激活" }
            }
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" }
            }
          },
          assistantCoaches: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" }
              }
            },
            description: "助教",
            ref: "CoachCoachEssential"
          },
          zone: {
            description: "场地",
            ref: "StudioZoneList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "场地名称" },
              position: { type: "number", description: "排序" },
              useHrm: { type: "boolean", description: "使用心率" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              tasExpiried: {
                type: "boolean",
                description: "Tas expiried TODO"
              },
              studio: {
                description: "门店",
                ref: "StudioStudioList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" }
                }
              }
            }
          }
        }
      }
    }
  },
  CourseBookingGiftBooking: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      owner: {
        description: "所有人",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      member: {
        description: "会员",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      course: {
        description: "课程",
        ref: "CourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          name: { type: "string", description: "名称" },
          enName: { type: "string", description: "课程英文名称" },
          homework: { type: "string", description: "课后作业" },
          workflowState: { type: "string", description: "Workflow state TODO" },
          product: {
            description: "训练产品",
            ref: "CourseProductList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              brandId: { type: "number", description: "品牌" },
              name: { type: "string", description: "课程名称" },
              enName: { type: "string", description: "英文名称" },
              usageCn: { type: "string", description: "Usage cn TODO" },
              forKid: { type: "boolean", description: "For kid TODO" },
              stageList: {
                type: "array",
                items: { type: "string" },
                description: "Stage list TODO"
              },
              provider: { type: "string", description: "产品品牌" },
              expiryDate: {
                description: "授权截止",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              category: {
                type: "string",
                required: false,
                enum: ["brand", "platform"],
                "x-i18n": { brand: "自研产品", platform: "授权产品" },
                description: "类型"
              },
              productType: {
                type: "string",
                required: false,
                enum: [
                  "group_training",
                  "elite_training",
                  "camp",
                  "free_training"
                ],
                "x-i18n": {
                  group_training: "团课",
                  elite_training: "私教",
                  camp: "训练营",
                  free_training: "自由训练"
                },
                description: "类型"
              },
              scheduleRule: {
                type: "string",
                description: "Schedule rule TODO"
              },
              entryPrice: { type: "string", description: "Entry price TODO" },
              active: { type: "boolean", description: "激活" }
            }
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" }
            }
          },
          assistantCoaches: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" }
              }
            },
            description: "助教",
            ref: "CoachCoachEssential"
          },
          zone: {
            description: "场地",
            ref: "StudioZoneList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "场地名称" },
              position: { type: "number", description: "排序" },
              useHrm: { type: "boolean", description: "使用心率" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              tasExpiried: {
                type: "boolean",
                description: "Tas expiried TODO"
              },
              studio: {
                description: "门店",
                ref: "StudioStudioList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" }
                }
              }
            }
          }
        }
      },
      cost: { type: "string", description: "Cost TODO" },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      workflowState: {
        type: "string",
        required: false,
        enum: [
          "new",
          "reserved",
          "queued",
          "checked_in",
          "absent",
          "cancelled"
        ],
        "x-i18n": {
          new: "未签到",
          reserved: "未支付",
          queued: "已排号",
          checked_in: "已签到",
          absent: "缺席",
          cancelled: "已取消"
        },
        description: "状态"
      },
      consumeType: { type: "string", description: "预约方式" }
    }
  },
  CourseTrainingResultDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      avgHr: { type: "number", description: "平均心率" },
      maxHr: { type: "number", description: "最大心率" },
      calories: { type: "number", description: "卡路里" },
      score: { type: "number", description: "得分" },
      timeInZone: {
        type: "array",
        items: { type: "number" },
        description: "Time in zone TODO"
      },
      scoredDuration: { type: "number", description: "Scored duration TODO" },
      epoc: { type: "boolean", description: "epoc" }
    }
  },
  MembershipCardEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      cardType: {
        type: "string",
        required: true,
        enum: ["duration", "entry"],
        "x-i18n": { duration: "时段卡", entry: "次卡" },
        description: "会员卡类型"
      },
      shared: { type: "boolean", description: "共享卡" },
      sharedType: { type: "string", description: "共享类型" },
      productType: {
        type: "string",
        required: true,
        enum: ["elite_training", "group_training", "free_training"],
        "x-i18n": {
          elite_training: "私教",
          group_training: "团课",
          free_training: "自由训练"
        },
        description: "训练产品类型"
      },
      productTypeZh: { type: "string", description: "训练产品类型" },
      cancellationDeadline: { type: "number", description: "取消限制（小时）" },
      activateStrategy: { type: "string", description: "开卡设置" },
      display: { type: "boolean", description: "展示" },
      giftable: { type: "boolean", description: "是否可赠课" },
      position: { type: "number", description: "排序" },
      active: { type: "boolean", description: "激活" }
    }
  },
  MembershipCardOptionForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      value: { type: "string", required: true, description: "充值金额" },
      validityPeriod: { type: "number", required: true, description: "有效期" },
      originalPrice: { type: "string", required: true, description: "原价" },
      inventory: { type: "number", nullable: true, description: "库存" },
      price: { type: "string", required: true, description: "价格" },
      salesType: {
        type: "string",
        required: true,
        enum: ["normal", "sales", "new_member", "purchase_limited"],
        "x-i18n": {
          normal: "普通",
          sales: "特惠",
          new_member: "新手专享",
          purchase_limited: "限购一次"
        },
        description: "销售类型"
      },
      active: { type: "boolean", required: true, description: "激活" },
      position: { type: "number", description: "排序" },
      display: { type: "boolean", required: true, description: "展示" }
    }
  },
  MembershipCardOptionPrepayForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      value: { type: "string", required: true, description: "充值金额" },
      bonus: { type: "string", required: true, description: "赠送金额" },
      validityPeriod: { type: "number", required: true, description: "有效期" },
      inventory: { type: "number", nullable: true, description: "库存" },
      salesType: {
        type: "string",
        required: true,
        enum: ["normal", "sales", "new_member", "purchase_limited"],
        "x-i18n": {
          normal: "普通",
          sales: "特惠",
          new_member: "新手专享",
          purchase_limited: "限购一次"
        },
        description: "销售类型"
      },
      notes: { type: "string", nullable: true, description: "附加说明" },
      active: { type: "boolean", required: true, description: "激活" },
      display: { type: "boolean", required: true, description: "展示" }
    }
  },
  MembershipCardOptionDefault: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      value: { type: "string", required: true, description: "充值金额" },
      validityPeriod: { type: "number", required: true, description: "有效期" },
      originalPrice: { type: "string", required: true, description: "原价" },
      inventory: { type: "number", nullable: true, description: "库存" },
      price: { type: "string", required: true, description: "价格" },
      salesType: {
        type: "string",
        required: true,
        enum: ["normal", "sales", "new_member", "purchase_limited"],
        "x-i18n": {
          normal: "普通",
          sales: "特惠",
          new_member: "新手专享",
          purchase_limited: "限购一次"
        },
        description: "销售类型"
      },
      active: { type: "boolean", required: true, description: "激活" },
      position: { type: "number", description: "排序" },
      display: { type: "boolean", required: true, description: "展示" },
      id: { type: "number", description: "ID" },
      bonus: { type: "string", description: "赠送金额" },
      purchaseCount: { type: "number", description: "销售数量" },
      displayName: { type: "string", description: "展示名称" },
      courseProductType: {
        type: "string",
        description: "Course product type TODO"
      },
      notes: { type: "string", description: "附加说明" }
    }
  },
  MembershipCardDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      cardType: {
        type: "string",
        required: true,
        enum: ["duration", "entry"],
        "x-i18n": { duration: "时段卡", entry: "次卡" },
        description: "会员卡类型"
      },
      shared: { type: "boolean", description: "共享卡" },
      sharedType: { type: "string", description: "共享类型" },
      productType: {
        type: "string",
        required: true,
        enum: ["elite_training", "group_training", "free_training"],
        "x-i18n": {
          elite_training: "私教",
          group_training: "团课",
          free_training: "自由训练"
        },
        description: "训练产品类型"
      },
      productTypeZh: { type: "string", description: "训练产品类型" },
      cancellationDeadline: { type: "number", description: "取消限制（小时）" },
      activateStrategy: { type: "string", description: "开卡设置" },
      display: { type: "boolean", description: "展示" },
      giftable: { type: "boolean", description: "是否可赠课" },
      position: { type: "number", description: "排序" },
      active: { type: "boolean", description: "激活" },
      cardOptions: {
        type: "array",
        items: {
          type: "object",
          properties: {
            name: { type: "string", required: true, description: "名称" },
            value: { type: "string", required: true, description: "充值金额" },
            validityPeriod: {
              type: "number",
              required: true,
              description: "有效期"
            },
            originalPrice: {
              type: "string",
              required: true,
              description: "原价"
            },
            inventory: { type: "number", nullable: true, description: "库存" },
            price: { type: "string", required: true, description: "价格" },
            salesType: {
              type: "string",
              required: true,
              enum: ["normal", "sales", "new_member", "purchase_limited"],
              "x-i18n": {
                normal: "普通",
                sales: "特惠",
                new_member: "新手专享",
                purchase_limited: "限购一次"
              },
              description: "销售类型"
            },
            active: { type: "boolean", required: true, description: "激活" },
            position: { type: "number", description: "排序" },
            display: { type: "boolean", required: true, description: "展示" },
            id: { type: "number", description: "ID" },
            bonus: { type: "string", description: "赠送金额" },
            purchaseCount: { type: "number", description: "销售数量" },
            displayName: { type: "string", description: "展示名称" },
            courseProductType: {
              type: "string",
              description: "Course product type TODO"
            },
            notes: { type: "string", description: "附加说明" }
          }
        },
        description: "充值选项",
        ref: "MembershipCardOptionDefault"
      },
      cover: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "封面图"
      },
      introduction: { type: "string", nullable: true, description: "介绍" },
      applyToAllStudio: { type: "boolean", description: "所有门店可用" },
      studios: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            fullName: { type: "string", description: "门店全称" },
            shortName: { type: "string", description: "门店名称" }
          }
        },
        description: "应用门店",
        ref: "StudioStudioList"
      },
      applyToAllProduct: { type: "boolean", description: "所有训练产品可用" },
      productIds: {
        type: "array",
        items: { type: "number" },
        description: "训练产品"
      },
      products: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            brandId: { type: "number", description: "品牌" },
            name: { type: "string", description: "课程名称" },
            enName: { type: "string", description: "英文名称" },
            usageCn: { type: "string", description: "Usage cn TODO" },
            forKid: { type: "boolean", description: "For kid TODO" },
            stageList: {
              type: "array",
              items: { type: "string" },
              description: "Stage list TODO"
            },
            provider: { type: "string", description: "产品品牌" },
            expiryDate: {
              description: "授权截止",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            category: {
              type: "string",
              required: false,
              enum: ["brand", "platform"],
              "x-i18n": { brand: "自研产品", platform: "授权产品" },
              description: "类型"
            },
            productType: {
              type: "string",
              required: false,
              enum: [
                "group_training",
                "elite_training",
                "camp",
                "free_training"
              ],
              "x-i18n": {
                group_training: "团课",
                elite_training: "私教",
                camp: "训练营",
                free_training: "自由训练"
              },
              description: "类型"
            },
            scheduleRule: { type: "string", description: "Schedule rule TODO" },
            entryPrice: { type: "string", description: "Entry price TODO" },
            active: { type: "boolean", description: "激活" }
          }
        },
        description: "应用训练产品",
        ref: "CourseProductList"
      },
      applyToAllCoachLevel: {
        type: "boolean",
        description: "所有教练等级可销售"
      },
      coachLevels: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" }
          }
        },
        description: "可销售教练等级",
        ref: "CoachLevelList"
      },
      applyToAllCoach: { type: "boolean", description: "所有教练可用" },
      coaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "姓名" },
            levelId: { type: "number", description: "等级" },
            levelName: { type: "string", description: "Level name TODO" },
            displayName: { type: "string", description: "昵称" }
          }
        },
        description: "Coaches TODO",
        ref: "CoachCoachList"
      },
      coachIds: {
        type: "array",
        items: { type: "number" },
        description: "可用教练"
      },
      purchaseQrCode: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "购买二维码"
      },
      bookingLimitPeriod: {
        type: "string",
        required: false,
        enum: ["", "daily", "weekly", "monthly"],
        "x-i18n": { "": "无", daily: "每日", weekly: "每周", monthly: "每月" },
        description: "约课次数限制"
      },
      bookingLimit: { type: "number", description: "最大预约次数" },
      bookingDaysLimit: { type: "number", description: "约课天数限制" },
      availableForCoursesWithOverlap: {
        type: "boolean",
        description: "同一时段预约多堂课"
      }
    }
  },
  MembershipCardForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      cardType: {
        type: "string",
        required: true,
        enum: ["duration", "entry"],
        "x-i18n": { duration: "时段卡", entry: "次卡" },
        description: "会员卡类型"
      },
      shared: { type: "boolean", description: "共享卡" },
      productType: {
        type: "string",
        required: true,
        enum: ["elite_training", "group_training", "free_training"],
        "x-i18n": {
          elite_training: "私教",
          group_training: "团课",
          free_training: "自由训练"
        },
        description: "训练产品类型"
      },
      introduction: { type: "string", description: "介绍" },
      applyToAllStudio: { type: "boolean", description: "所有门店可用" },
      studioIds: {
        type: "array",
        items: { type: "number" },
        description: "应用门店"
      },
      applyToAllProduct: { type: "boolean", description: "所有训练产品可用" },
      productIds: {
        type: "array",
        items: { type: "number" },
        description: "训练产品"
      },
      applyToAllCoachLevel: {
        type: "boolean",
        description: "所有教练等级可销售"
      },
      coachLevelIds: {
        type: "array",
        items: { type: "number" },
        description: "可销售教练等级"
      },
      applyToAllCoach: { type: "boolean", description: "所有教练可用" },
      coachIds: {
        type: "array",
        items: { type: "number" },
        description: "可用教练"
      },
      cancellationDeadline: { type: "number", description: "取消限制（小时）" },
      bookingLimitPeriod: {
        type: "string",
        required: false,
        enum: ["", "daily", "weekly", "monthly"],
        "x-i18n": { "": "无", daily: "每日", weekly: "每周", monthly: "每月" },
        description: "约课次数限制"
      },
      bookingLimit: { type: "number", description: "最大预约次数" },
      bookingDaysLimit: { type: "number", description: "约课天数限制" },
      availableForCoursesWithOverlap: {
        type: "boolean",
        description: "同一时段预约多堂课"
      },
      activateStrategy: { type: "string", description: "开卡设置" },
      cover: {
        description: "封面图",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      giftable: { type: "boolean", required: true, description: "是否可赠课" },
      display: { type: "boolean", required: true, description: "展示" },
      position: { type: "number", description: "排序" },
      active: { type: "boolean", required: true, description: "激活" }
    }
  },
  MembershipAccountDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      accountType: { type: "string", description: "账户类型" },
      balance: { type: "string", description: "余额" },
      value: { type: "string", description: "真实余额" },
      bonus: { type: "string", description: "赠送余额" },
      shared: { type: "boolean", description: "共享" },
      owner: {
        description: "所有人",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      workflowState: { type: "string", description: "状态" },
      validityPeriod: { type: "number", description: "有效期" },
      expiryDate: { type: "object", description: "有效日期" },
      settlementPrice: { type: "string", description: "结算单价" },
      activateAt: { type: "object", description: "激活时间" },
      coachIds: {
        type: "array",
        items: { type: "number" },
        description: "私教教练"
      },
      coaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" }
          }
        },
        description: "私教教练",
        ref: "CoachCoachEssential"
      },
      card: {
        description: "会员卡",
        ref: "MembershipCardEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          cardType: {
            type: "string",
            required: true,
            enum: ["duration", "entry"],
            "x-i18n": { duration: "时段卡", entry: "次卡" },
            description: "会员卡类型"
          },
          shared: { type: "boolean", description: "共享卡" },
          sharedType: { type: "string", description: "共享类型" },
          productType: {
            type: "string",
            required: true,
            enum: ["elite_training", "group_training", "free_training"],
            "x-i18n": {
              elite_training: "私教",
              group_training: "团课",
              free_training: "自由训练"
            },
            description: "训练产品类型"
          },
          productTypeZh: { type: "string", description: "训练产品类型" },
          cancellationDeadline: {
            type: "number",
            description: "取消限制（小时）"
          },
          activateStrategy: { type: "string", description: "开卡设置" },
          display: { type: "boolean", description: "展示" },
          giftable: { type: "boolean", description: "是否可赠课" },
          position: { type: "number", description: "排序" },
          active: { type: "boolean", description: "激活" }
        }
      }
    }
  },
  MembershipAccountSummary: {
    type: "object",
    properties: { balance: { type: "number", description: "余额" } }
  },
  MembershipAccountPauseForm: {
    type: "object",
    properties: {
      notes: { required: true, type: "string", description: "备注" }
    }
  },
  MembershipAccountPunishForm: {
    type: "object",
    properties: {
      notes: { required: true, type: "string", description: "备注" },
      cancelFutureBookings: {
        type: "boolean",
        description: "Cancel future bookings TODO"
      }
    }
  },
  MembershipAccountActivateForm: {
    type: "object",
    properties: {
      timing: { type: "string", description: "设置激活方式" },
      activateAt: { type: "object", description: "激活时间" }
    }
  },
  MembershipAccountExtendValidityPeriodForm: {
    type: "object",
    properties: {
      validityPeriod: { required: true, type: "number", description: "有效期" },
      notes: { required: true, type: "string", description: "备注" }
    }
  },
  MembershipAccountTransferForm: {
    type: "object",
    properties: {
      amount: { required: true, type: "number", description: "数量" },
      targetMemberId: {
        required: true,
        type: "number",
        description: "目标会员"
      },
      notes: { required: true, type: "string", description: "备注" }
    }
  },
  MembershipAccountConsumeForm: {
    type: "object",
    properties: {
      amount: { type: "number", description: "数量" },
      value: { type: "number", description: "真实余额" },
      bonus: { type: "number", description: "赠送余额" },
      notes: { required: true, type: "string", description: "备注" }
    }
  },
  MembershipAccountConsumeProductWithPrepayForm: {
    type: "object",
    properties: {
      consumeType: { type: "string", description: "消费类型" },
      cardId: { type: "number", description: "会员卡" },
      productId: { type: "number", description: "产品" },
      coachIds: {
        type: "array",
        items: { type: "number" },
        description: "私教教练"
      },
      value: { type: "number", description: "真实余额" },
      validityPeriod: { type: "number", description: "有效期" },
      paymentAmount: { type: "number", description: "Payment amount TODO" }
    }
  },
  MembershipAccountRechargeForm: {
    type: "object",
    properties: {
      membershipCardId: {
        required: false,
        type: "number",
        description: "会员卡"
      },
      amount: { required: true, type: "number", description: "数量" },
      settlementAmount: {
        required: true,
        type: "number",
        description: "结算金额"
      },
      validityPeriod: { required: true, type: "number", description: "有效期" },
      notes: { required: true, type: "string", description: "备注" }
    }
  },
  MembershipAccountChangeCoachForm: {
    type: "object",
    properties: {
      coachId: { required: true, type: "number", description: "项目" }
    }
  },
  CourseBookingWithCourseAndResult: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      owner: {
        description: "所有人",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      workflowState: {
        type: "string",
        required: false,
        enum: [
          "new",
          "reserved",
          "queued",
          "checked_in",
          "absent",
          "cancelled"
        ],
        "x-i18n": {
          new: "未签到",
          reserved: "未支付",
          queued: "已排号",
          checked_in: "已签到",
          absent: "缺席",
          cancelled: "已取消"
        },
        description: "状态"
      },
      course: {
        description: "课程",
        ref: "CourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          name: { type: "string", description: "名称" },
          enName: { type: "string", description: "课程英文名称" },
          homework: { type: "string", description: "课后作业" },
          workflowState: { type: "string", description: "Workflow state TODO" },
          product: {
            description: "训练产品",
            ref: "CourseProductList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              brandId: { type: "number", description: "品牌" },
              name: { type: "string", description: "课程名称" },
              enName: { type: "string", description: "英文名称" },
              usageCn: { type: "string", description: "Usage cn TODO" },
              forKid: { type: "boolean", description: "For kid TODO" },
              stageList: {
                type: "array",
                items: { type: "string" },
                description: "Stage list TODO"
              },
              provider: { type: "string", description: "产品品牌" },
              expiryDate: {
                description: "授权截止",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              category: {
                type: "string",
                required: false,
                enum: ["brand", "platform"],
                "x-i18n": { brand: "自研产品", platform: "授权产品" },
                description: "类型"
              },
              productType: {
                type: "string",
                required: false,
                enum: [
                  "group_training",
                  "elite_training",
                  "camp",
                  "free_training"
                ],
                "x-i18n": {
                  group_training: "团课",
                  elite_training: "私教",
                  camp: "训练营",
                  free_training: "自由训练"
                },
                description: "类型"
              },
              scheduleRule: {
                type: "string",
                description: "Schedule rule TODO"
              },
              entryPrice: { type: "string", description: "Entry price TODO" },
              active: { type: "boolean", description: "激活" }
            }
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" }
            }
          },
          assistantCoaches: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" }
              }
            },
            description: "助教",
            ref: "CoachCoachEssential"
          },
          zone: {
            description: "场地",
            ref: "StudioZoneList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "场地名称" },
              position: { type: "number", description: "排序" },
              useHrm: { type: "boolean", description: "使用心率" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              tasExpiried: {
                type: "boolean",
                description: "Tas expiried TODO"
              },
              studio: {
                description: "门店",
                ref: "StudioStudioList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" }
                }
              }
            }
          }
        }
      },
      consumeType: { type: "string", description: "预约方式" },
      trainingResult: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CourseTrainingResultDefault" }
        ],
        description: "Training result TODO"
      },
      actorName: { type: "string", description: "Actor name TODO" },
      actAt: { type: "object", description: "Act at TODO" },
      actionNotes: { type: "string", description: "Action notes TODO" },
      consumeAccount: {
        description: "Consume account TODO",
        ref: "MembershipAccountDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          accountType: { type: "string", description: "账户类型" },
          balance: { type: "string", description: "余额" },
          value: { type: "string", description: "真实余额" },
          bonus: { type: "string", description: "赠送余额" },
          shared: { type: "boolean", description: "共享" },
          owner: {
            description: "所有人",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          workflowState: { type: "string", description: "状态" },
          validityPeriod: { type: "number", description: "有效期" },
          expiryDate: { type: "object", description: "有效日期" },
          settlementPrice: { type: "string", description: "结算单价" },
          activateAt: { type: "object", description: "激活时间" },
          coachIds: {
            type: "array",
            items: { type: "number" },
            description: "私教教练"
          },
          coaches: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" }
              }
            },
            description: "私教教练",
            ref: "CoachCoachEssential"
          },
          card: {
            description: "会员卡",
            ref: "MembershipCardEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              cardType: {
                type: "string",
                required: true,
                enum: ["duration", "entry"],
                "x-i18n": { duration: "时段卡", entry: "次卡" },
                description: "会员卡类型"
              },
              shared: { type: "boolean", description: "共享卡" },
              sharedType: { type: "string", description: "共享类型" },
              productType: {
                type: "string",
                required: true,
                enum: ["elite_training", "group_training", "free_training"],
                "x-i18n": {
                  elite_training: "私教",
                  group_training: "团课",
                  free_training: "自由训练"
                },
                description: "训练产品类型"
              },
              productTypeZh: { type: "string", description: "训练产品类型" },
              cancellationDeadline: {
                type: "number",
                description: "取消限制（小时）"
              },
              activateStrategy: { type: "string", description: "开卡设置" },
              display: { type: "boolean", description: "展示" },
              giftable: { type: "boolean", description: "是否可赠课" },
              position: { type: "number", description: "排序" },
              active: { type: "boolean", description: "激活" }
            }
          }
        }
      }
    }
  },
  CourseBookingMemberSummary: {
    type: "object",
    properties: {
      total: { type: "number", description: "总训练次数" },
      currentMonthTotal: { type: "number", description: "本月训练数" },
      latestTrainingAt: {
        type: "object",
        nullable: true,
        description: "上次训练时间"
      },
      trainingCountPerMonth: { type: "number", description: "平均月训练次数" }
    }
  },
  CourseBookingMemberReportItem: {
    type: "object",
    properties: {
      label: { type: "string", description: "标识" },
      data: { type: "number", description: "数据" }
    }
  },
  CourseBookingGroupTrainingForm: {
    type: "object",
    properties: {
      memberId: { type: "number", description: "用户" },
      accountId: { type: "number", description: "账户" },
      memberCouponId: { type: "number", description: "代金券" },
      coachComments: { type: "string", description: "教练点评" },
      photos: {
        type: "array",
        items: {
          type: "object",
          properties: {
            signedId: { type: "string", description: "Signed ID" },
            remoteUrl: {
              type: "string",
              nullable: true,
              description: "文件远程 URL"
            },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "训练照片",
        ref: "AttachmentForm"
      }
    }
  },
  CourseBookingGroupTrainingCancelForm: {
    type: "object",
    properties: { cancelReason: { type: "string", description: "取消原因" } }
  },
  CourseBookingCheckInScreen: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      gender: { type: "string", description: "性别" },
      kol: { type: "boolean", description: "KOL" },
      avatar: { type: "string", description: "头像" },
      spfNewbie: { type: "boolean", description: "新手" },
      workflowState: {
        type: "string",
        required: false,
        enum: [
          "new",
          "reserved",
          "queued",
          "checked_in",
          "absent",
          "cancelled"
        ],
        "x-i18n": {
          new: "未签到",
          reserved: "未支付",
          queued: "已排号",
          checked_in: "已签到",
          absent: "缺席",
          cancelled: "已取消"
        },
        description: "状态"
      },
      greeting: { type: "string", nullable: true, description: "欢迎语" },
      number: { type: "string", description: "心率表编号" },
      checkedBookingsCount: { type: "number", description: "训练数" },
      checkedBookingsNumberAtThisWorkout: {
        type: "number",
        description: "该课程产品训练书"
      }
    }
  },
  CourseBookingSelectPositionForm: {
    type: "object",
    properties: { position: { type: "number", description: "位置" } }
  },
  CourseBookingMemberExport: {
    type: "object",
    properties: {
      startTime: {
        description: "Start time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      zoneName: { type: "string", description: "Zone name TODO" },
      productType: { type: "string", description: "Product type TODO" },
      productName: { type: "string", description: "Product name TODO" },
      coaches: { type: "string", description: "Coaches TODO" },
      consumeType: { type: "string", description: "预约方式" },
      workflowState: {
        type: "string",
        required: false,
        enum: [
          "new",
          "reserved",
          "queued",
          "checked_in",
          "absent",
          "cancelled"
        ],
        "x-i18n": {
          new: "未签到",
          reserved: "未支付",
          queued: "已排号",
          checked_in: "已签到",
          absent: "缺席",
          cancelled: "已取消"
        },
        description: "状态"
      }
    }
  },
  CourseBookingPagedArrayWithCourseAndResult: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "会员",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            owner: {
              description: "所有人",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            workflowState: {
              type: "string",
              required: false,
              enum: [
                "new",
                "reserved",
                "queued",
                "checked_in",
                "absent",
                "cancelled"
              ],
              "x-i18n": {
                new: "未签到",
                reserved: "未支付",
                queued: "已排号",
                checked_in: "已签到",
                absent: "缺席",
                cancelled: "已取消"
              },
              description: "状态"
            },
            course: {
              description: "课程",
              ref: "CourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                name: { type: "string", description: "名称" },
                enName: { type: "string", description: "课程英文名称" },
                homework: { type: "string", description: "课后作业" },
                workflowState: {
                  type: "string",
                  description: "Workflow state TODO"
                },
                product: {
                  description: "训练产品",
                  ref: "CourseProductList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    brandId: { type: "number", description: "品牌" },
                    name: { type: "string", description: "课程名称" },
                    enName: { type: "string", description: "英文名称" },
                    usageCn: { type: "string", description: "Usage cn TODO" },
                    forKid: { type: "boolean", description: "For kid TODO" },
                    stageList: {
                      type: "array",
                      items: { type: "string" },
                      description: "Stage list TODO"
                    },
                    provider: { type: "string", description: "产品品牌" },
                    expiryDate: {
                      description: "授权截止",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    category: {
                      type: "string",
                      required: false,
                      enum: ["brand", "platform"],
                      "x-i18n": { brand: "自研产品", platform: "授权产品" },
                      description: "类型"
                    },
                    productType: {
                      type: "string",
                      required: false,
                      enum: [
                        "group_training",
                        "elite_training",
                        "camp",
                        "free_training"
                      ],
                      "x-i18n": {
                        group_training: "团课",
                        elite_training: "私教",
                        camp: "训练营",
                        free_training: "自由训练"
                      },
                      description: "类型"
                    },
                    scheduleRule: {
                      type: "string",
                      description: "Schedule rule TODO"
                    },
                    entryPrice: {
                      type: "string",
                      description: "Entry price TODO"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                assistantCoaches: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      phone: { type: "string", description: "手机号" },
                      avatar: {
                        description: "头像",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      name: { type: "string", description: "姓名" },
                      displayName: { type: "string", description: "昵称" },
                      note: { type: "string", description: "备注" },
                      active: { type: "boolean", description: "激活" },
                      certified: {
                        type: "boolean",
                        description: "Certified TODO"
                      }
                    }
                  },
                  description: "助教",
                  ref: "CoachCoachEssential"
                },
                zone: {
                  description: "场地",
                  ref: "StudioZoneList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "场地名称" },
                    position: { type: "number", description: "排序" },
                    useHrm: { type: "boolean", description: "使用心率" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    tasExpiried: {
                      type: "boolean",
                      description: "Tas expiried TODO"
                    },
                    studio: {
                      description: "门店",
                      ref: "StudioStudioList",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        fullName: { type: "string", description: "门店全称" },
                        shortName: { type: "string", description: "门店名称" }
                      }
                    }
                  }
                }
              }
            },
            consumeType: { type: "string", description: "预约方式" },
            trainingResult: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/CourseTrainingResultDefault" }
              ],
              description: "Training result TODO"
            },
            actorName: { type: "string", description: "Actor name TODO" },
            actAt: { type: "object", description: "Act at TODO" },
            actionNotes: { type: "string", description: "Action notes TODO" },
            consumeAccount: {
              description: "Consume account TODO",
              ref: "MembershipAccountDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                accountType: { type: "string", description: "账户类型" },
                balance: { type: "string", description: "余额" },
                value: { type: "string", description: "真实余额" },
                bonus: { type: "string", description: "赠送余额" },
                shared: { type: "boolean", description: "共享" },
                owner: {
                  description: "所有人",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                workflowState: { type: "string", description: "状态" },
                validityPeriod: { type: "number", description: "有效期" },
                expiryDate: { type: "object", description: "有效日期" },
                settlementPrice: { type: "string", description: "结算单价" },
                activateAt: { type: "object", description: "激活时间" },
                coachIds: {
                  type: "array",
                  items: { type: "number" },
                  description: "私教教练"
                },
                coaches: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      phone: { type: "string", description: "手机号" },
                      avatar: {
                        description: "头像",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      name: { type: "string", description: "姓名" },
                      displayName: { type: "string", description: "昵称" },
                      note: { type: "string", description: "备注" },
                      active: { type: "boolean", description: "激活" },
                      certified: {
                        type: "boolean",
                        description: "Certified TODO"
                      }
                    }
                  },
                  description: "私教教练",
                  ref: "CoachCoachEssential"
                },
                card: {
                  description: "会员卡",
                  ref: "MembershipCardEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    cardType: {
                      type: "string",
                      required: true,
                      enum: ["duration", "entry"],
                      "x-i18n": { duration: "时段卡", entry: "次卡" },
                      description: "会员卡类型"
                    },
                    shared: { type: "boolean", description: "共享卡" },
                    sharedType: { type: "string", description: "共享类型" },
                    productType: {
                      type: "string",
                      required: true,
                      enum: [
                        "elite_training",
                        "group_training",
                        "free_training"
                      ],
                      "x-i18n": {
                        elite_training: "私教",
                        group_training: "团课",
                        free_training: "自由训练"
                      },
                      description: "训练产品类型"
                    },
                    productTypeZh: {
                      type: "string",
                      description: "训练产品类型"
                    },
                    cancellationDeadline: {
                      type: "number",
                      description: "取消限制（小时）"
                    },
                    activateStrategy: {
                      type: "string",
                      description: "开卡设置"
                    },
                    display: { type: "boolean", description: "展示" },
                    giftable: { type: "boolean", description: "是否可赠课" },
                    position: { type: "number", description: "排序" },
                    active: { type: "boolean", description: "激活" }
                  }
                }
              }
            }
          }
        },
        description: "数据",
        ref: "CourseBookingWithCourseAndResult"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CourseProductPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            brandId: { type: "number", description: "品牌" },
            name: { type: "string", description: "课程名称" },
            enName: { type: "string", description: "英文名称" },
            usageCn: { type: "string", description: "Usage cn TODO" },
            forKid: { type: "boolean", description: "For kid TODO" },
            stageList: {
              type: "array",
              items: { type: "string" },
              description: "Stage list TODO"
            },
            provider: { type: "string", description: "产品品牌" },
            expiryDate: {
              description: "授权截止",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            category: {
              type: "string",
              required: false,
              enum: ["brand", "platform"],
              "x-i18n": { brand: "自研产品", platform: "授权产品" },
              description: "类型"
            },
            productType: {
              type: "string",
              required: false,
              enum: [
                "group_training",
                "elite_training",
                "camp",
                "free_training"
              ],
              "x-i18n": {
                group_training: "团课",
                elite_training: "私教",
                camp: "训练营",
                free_training: "自由训练"
              },
              description: "类型"
            },
            scheduleRule: {
              type: "string",
              required: false,
              enum: ["default", "follow_zone", "follow_coach"],
              "x-i18n": {
                default: "默认",
                follow_zone: "场地记忆",
                follow_coach: "教练记忆"
              },
              description: "Schedule rule TODO"
            },
            entryPrice: { type: "string", description: "Entry price TODO" },
            active: { type: "boolean", description: "激活" },
            price: { type: "string", description: "价格" },
            prepayPrice: { type: "string", description: "储值卡支付价格" },
            workoutId: { type: "number", description: "训练课程" },
            duration: { type: "number", description: "课程时长" },
            courseFee: { type: "string", description: "主教课时费" },
            assistantCourseFee: { type: "string", description: "助教课时费" },
            trialCourseFee: { type: "string", description: "实习课时费" },
            intro: { type: "string", nullable: true, description: "课程介绍" },
            groundType: {
              type: "string",
              required: false,
              enum: ["indoor", "outdoor"],
              "x-i18n": { indoor: "室内", outdoor: "户外" },
              description: "Ground type TODO"
            },
            academy: { type: "boolean", description: "提供培训" },
            note: { type: "string", description: "授权备注" },
            live: {
              description: "课程Live",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            banners: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "封面图",
              ref: "AttachmentDefault"
            },
            coachProductId: {
              type: "number",
              description: "Coach product TODO"
            },
            tagList: {
              type: "array",
              items: { type: "string", enum: [] },
              description: "标签"
            },
            paymentMethods: {
              type: "array",
              items: {
                type: "string",
                required: false,
                enum: ["wechat", "prepay_card", "membership_card", "coupon"],
                "x-i18n": {
                  wechat: "微信支付",
                  prepay_card: "储值账户",
                  membership_card: "会员卡",
                  coupon: "代金券"
                }
              },
              description: "支付方式"
            },
            hasConfirmCourseThreshold: {
              type: "boolean",
              description: "单独设置开课限制"
            },
            confirmCourseThreshold: {
              type: "number",
              description: "最低开课人数"
            },
            confirmCourseDeadline: {
              type: "number",
              description: "开课确认时间"
            },
            defaultCaloriesTarget: {
              type: "number",
              description: "默认卡路里目标"
            },
            tasType: {
              type: "string",
              required: false,
              enum: ["tas", "hrm", "countdown"],
              "x-i18n": {
                tas: "内容辅助",
                hrm: "心率辅助",
                countdown: "时间辅助"
              },
              description: "辅助系统配置"
            },
            multiScreenVersions: {
              type: "array",
              items: { type: "number" },
              description: "多屏幕适配"
            },
            displayed: { type: "boolean", description: "Displayed TODO" },
            useHrm: { type: "boolean", description: "使用心率" },
            position: { type: "number", description: "排序" }
          }
        },
        ref: "CourseProductDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CourseRatingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      booking: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CourseBookingDefault" }
        ],
        description: "预约"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      notes: { type: "string", description: "评论" }
    }
  },
  CourseRatingForm: {
    type: "object",
    properties: {
      rating: { type: "number", required: true, description: "评价" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      }
    }
  },
  CourseRatingCloseForm: {
    type: "object",
    properties: { notes: { type: "string", description: "评论" } }
  },
  CourseRatingPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            booking: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/CourseBookingDefault" }
              ],
              description: "预约"
            },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            notes: { type: "string", description: "评论" }
          }
        },
        ref: "CourseRatingDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MobileStudioStudioBrief: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      fullName: { type: "string", description: "名称" },
      shortName: { type: "string", description: "简称" },
      longitude: { type: "object", description: "经度" },
      latitude: { type: "object", description: "维度" },
      address: { type: "string", description: "抵制" }
    }
  },
  MobileStudioStudioDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      fullName: { type: "string", description: "名称" },
      shortName: { type: "string", description: "简称" },
      longitude: { type: "object", description: "经度" },
      latitude: { type: "object", description: "维度" },
      address: { type: "string", description: "抵制" },
      active: { type: "boolean", description: "激活" },
      distance: { type: "string", description: "距离" }
    }
  },
  MobileStudioZoneDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      displayName: { type: "string", description: "Display name TODO" },
      eliteTraining: { type: "boolean", description: "可上私教" },
      studio: {
        description: "门店",
        ref: "MobileStudioStudioBrief",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          fullName: { type: "string", description: "名称" },
          shortName: { type: "string", description: "简称" },
          longitude: { type: "object", description: "经度" },
          latitude: { type: "object", description: "维度" },
          address: { type: "string", description: "抵制" }
        }
      }
    }
  },
  MobileCourseCourseEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "英文课程名称" },
      zone: {
        description: "Zone TODO",
        ref: "MobileStudioZoneDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          displayName: { type: "string", description: "Display name TODO" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          studio: {
            description: "门店",
            ref: "MobileStudioStudioBrief",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              fullName: { type: "string", description: "名称" },
              shortName: { type: "string", description: "简称" },
              longitude: { type: "object", description: "经度" },
              latitude: { type: "object", description: "维度" },
              address: { type: "string", description: "抵制" }
            }
          }
        }
      },
      studio: {
        description: "门店",
        ref: "StudioStudioEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          fullName: { type: "string", description: "门店全称" },
          shortName: { type: "string", description: "门店名称" },
          active: { type: "boolean", description: "激活" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          display: { type: "boolean", description: "展示" },
          address: { type: "string", description: "地址" },
          contactPhone: { type: "string", description: "联系电话" },
          longitude: { type: "string", description: "经度" },
          latitude: { type: "string", description: "维度" },
          gatePassword: { type: "string", description: "门禁密码" }
        }
      },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      endTime: {
        description: "结束时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  StudioHrSensorDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      label: { type: "string", description: "标识" },
      zone: {
        description: "场地",
        ref: "StudioZoneList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "场地名称" },
          position: { type: "number", description: "排序" },
          useHrm: { type: "boolean", description: "使用心率" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          tasExpiried: { type: "boolean", description: "Tas expiried TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" }
            }
          }
        }
      },
      antId: { type: "number", description: "Ant ID" },
      active: { type: "boolean", description: "激活" }
    }
  },
  StudioHrSensorForm: {
    type: "object",
    properties: {
      label: { type: "string", required: true, description: "标识" },
      zoneId: { type: "number", required: true, description: "场地" },
      antId: { type: "number", required: true, description: "Ant ID" },
      active: { type: "boolean", description: "激活" }
    }
  },
  MobileCourseBookingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      course: {
        description: "课程",
        ref: "MobileCourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          courseName: { type: "string", description: "课程名称" },
          enCourseName: { type: "string", description: "英文课程名称" },
          zone: { description: "Zone TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" },
              active: { type: "boolean", description: "激活" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              display: { type: "boolean", description: "展示" },
              address: { type: "string", description: "地址" },
              contactPhone: { type: "string", description: "联系电话" },
              longitude: { type: "string", description: "经度" },
              latitude: { type: "string", description: "维度" },
              gatePassword: { type: "string", description: "门禁密码" }
            }
          },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          endTime: {
            description: "结束时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" },
              user: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/UserDefault" }
                ],
                description: "用户"
              },
              userId: { type: "number", description: "用户" },
              profilePhoto: {
                description: "形象照",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              wxQrCode: {
                description: "微信二维码",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              level: {
                description: "等级",
                ref: "CoachLevelDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  category: {
                    description: "类型",
                    ref: "CoachCategoryDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" }
                    }
                  },
                  categoryId: { type: "number", description: "类型" }
                }
              },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              joinedAt: {
                description: "入职时间",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              primaryStudio: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/StudioStudioList" }
                ],
                description: "绑定门店"
              },
              scopeOfAccess: {
                type: "string",
                required: true,
                enum: ["mine", "all"],
                "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                description: "查看排课范围"
              },
              introduction: { type: "string", description: "介绍" },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "教练风格"
              },
              certificationList: {
                type: "array",
                items: { type: "string" },
                description: "技能认证"
              },
              styleList: {
                type: "array",
                items: { type: "string" },
                description: "执教风格"
              },
              resumeDisplay: { type: "boolean", description: "执教数据展示" },
              eliteTrainingCapacity: {
                type: "number",
                description: "私教人数上限"
              },
              primaryStudioId: { type: "number", description: "绑定门店" },
              applyToAllProduct: {
                type: "boolean",
                description: "授权所有训练产品"
              },
              levelId: { type: "number", description: "等级" },
              createdAt: {
                description: "加入时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              }
            }
          },
          capacityState: { type: "string", description: "容量状态" },
          capacity: { type: "number", description: "容量" },
          bookingCount: { type: "number", description: "预约人数" },
          tags: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          productTags: {
            type: "array",
            items: { type: "string" },
            description: "产品标签"
          },
          productStages: {
            type: "array",
            items: { type: "string" },
            description: "Product stages TODO"
          },
          productType: { type: "string", description: "Product type TODO" },
          paymentMethods: {
            type: "array",
            items: { type: "string" },
            description: "Payment methods TODO"
          },
          bookState: { type: "string", description: "预约状态" },
          bookingId: { type: "number", description: "Booking TODO" },
          booking: {
            description: "Booking TODO",
            ref: "MobileCourseBookingGift",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              workflowState: { type: "string", description: "状态" },
              isOwner: { type: "boolean", description: "所有人" },
              giftState: { type: "string", description: "赠课状态" },
              token: { type: "string", description: "token" }
            }
          },
          campId: { type: "number", nullable: true, description: "训练营" },
          price: { type: "string", description: "课程价格" },
          prepayPrice: { type: "string", description: "Prepay price TODO" },
          positionSelectable: { type: "boolean", description: "可以选位" },
          multipleCoach: { type: "boolean", description: "Multiple 项目" },
          eliteTrainingMember: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/MobileMemberDefault" }
            ],
            description: "Elite training member TODO"
          },
          banner: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头图"
          },
          homework: { type: "string", description: "Homework TODO" },
          confirmCourseThreshold: {
            type: "number",
            description: "Confirm course threshold TODO"
          },
          confirmCourseDeadline: {
            type: "number",
            description: "Confirm course deadline TODO"
          },
          hasConfirmCourseThreshold: {
            type: "boolean",
            description: "Has confirm course threshold TODO"
          },
          useHrm: { type: "boolean", description: "使用心率" }
        }
      },
      member: {
        description: "会员",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      owner: {
        description: "所有人",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      primaryCoachName: { type: "string", description: "教练" },
      studioName: { type: "string", description: "门店" },
      courseDuration: { type: "number", description: "课程时长" },
      hrSensor: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/StudioHrSensorDefault" }
        ],
        description: "心率带"
      },
      workflowState: { type: "string", description: "状态" },
      trainingCount: { type: "number", description: "训练数" },
      cancellable: { type: "boolean", description: "可以取消" },
      cancellationDeadline: { type: "number", description: "取消时间" },
      queuePosition: {
        type: "number",
        nullable: true,
        description: "排位顺序"
      },
      position: { type: "number", nullable: true, description: "位置" },
      coachComments: { type: "string", description: "教练点评" },
      rated: { type: "boolean", description: "已评价" },
      reviewed: { type: "boolean", description: "已评论" },
      isOwner: { type: "boolean", description: "所有人" },
      giftState: { type: "string", description: "赠课状态" },
      token: { type: "string", description: "token" }
    }
  },
  MobileMemberEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      vip: { type: "boolean", description: "vip" }
    }
  },
  MobileBrandDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      identifier: { type: "string", description: "标识符" },
      saasType: { type: "string", description: "Saas type TODO" },
      showCourseBookingCount: {
        type: "boolean",
        description: "Show course booking count TODO"
      },
      giftBookingEnabled: {
        type: "boolean",
        description: "Gift booking enabled TODO"
      },
      bookingQueueEnabled: {
        type: "boolean",
        description: "Booking queue enabled TODO"
      },
      groupTrainingCoachBook: { type: "boolean", description: "教练代约团课" },
      groupTrainingCoachCheckIn: {
        type: "boolean",
        description: "教练代签到团课"
      },
      cancellationDeadline: { type: "number", description: "取消时限" },
      vipGroupTrainingDiscount: {
        type: "number",
        description: "Vip group training discount TODO"
      },
      vipFreeTrainingDiscount: {
        type: "number",
        description: "Vip group training discount TODO"
      },
      vipCampDiscount: {
        type: "number",
        description: "Vip camp discount TODO"
      },
      vipCouponPackageDiscount: {
        type: "number",
        description: "Vip coupon package discount TODO"
      },
      vipMembershipCardDiscount: {
        type: "number",
        description: "Vip membership card discount TODO"
      },
      vipCancellationDeadline: {
        type: "number",
        description: "Vip cancellation deadline TODO"
      },
      trainingReport: { type: "boolean", description: "Training report TODO" },
      prepayCardName: {
        type: "string",
        nullable: true,
        description: "储值卡名称"
      },
      availableProductTypes: {
        type: "array",
        items: { type: "string" },
        description: "可用产品类型"
      },
      membershipCardAvailableProductTypes: {
        type: "array",
        items: { type: "string" },
        description: "支持会员卡约课的产品类型"
      },
      prepayCardPurchaseAvailable: {
        type: "boolean",
        description: "启用储值卡充值"
      },
      couponPurchaseAvailable: {
        type: "boolean",
        description: "启用代金券商城"
      },
      mobileCancelEliteTrainingBooking: {
        type: "boolean",
        description: "Mobile cancel elite training booking TODO"
      },
      mobileEliteTrainingBooking: {
        type: "boolean",
        description: "Mobile elite training booking TODO"
      },
      mobileEliteTrainingBookingDeadline: {
        type: "number",
        description: "Mobile elite training booking deadline TODO"
      },
      eliteTrainingScheduleDays: {
        type: "number",
        description: "Elite training schedule days TODO"
      },
      eliteTrainingCoachCheckIn: {
        type: "boolean",
        description: "私教教练代签到"
      },
      eliteTrainingCoachCancel: {
        type: "boolean",
        description: "私教教练端取消预约"
      },
      eliteTrainingCoachLeave: {
        type: "boolean",
        description: "私教教练端隐藏时段"
      },
      expiryAlertEntryThreshold: {
        type: "number",
        description: "Expiry alert entry threshold TODO"
      },
      expiryAlertEliteEntryThreshold: {
        type: "number",
        description: "Expiry alert elite entry threshold TODO"
      },
      expiryAlertPrepayAmountThreshold: {
        type: "number",
        description: "Expiry alert prepay amount threshold TODO"
      },
      expiryAlertValidityPeriodThreshold: {
        type: "number",
        description: "Expiry alert validity period threshold TODO"
      },
      churnThreshold: { type: "number", description: "Churn threshold TODO" },
      newMemberThreshold: {
        type: "number",
        description: "New member threshold TODO"
      },
      inactiveMemberThreshold: {
        type: "number",
        description: "Inactive member threshold TODO"
      },
      inactiveEliteMemberThreshold: {
        type: "number",
        description: "Inactive elite member threshold TODO"
      },
      logo: {
        description: "Logo",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      qrCode: {
        description: "Qr code TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      theme: {
        description: "小程序主题",
        ref: "BrandThemeDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          primaryColor: { type: "string", description: "主题色" },
          secondaryColor: { type: "string", description: "辅助色" }
        }
      },
      wxMessageTemplates: {
        description: "Wx message templates TODO",
        ref: "BrandWxConfigMessageTemplates",
        type: "object",
        properties: {
          courseReminder: {
            type: "string",
            description: "Course reminder TODO"
          },
          bookingCancel: { type: "string", description: "Booking cancel TODO" },
          bookingQueueStatus: {
            type: "string",
            description: "Booking queue status TODO"
          },
          trainingResult: {
            type: "string",
            description: "Training result TODO"
          },
          footprintUploaded: {
            type: "string",
            description: "Footprint uploaded TODO"
          }
        }
      },
      schedulePublishWday: {
        type: "number",
        description: "Schedule publish wday TODO"
      },
      schedulePublishTime: {
        type: "string",
        description: "Schedule publish time TODO"
      },
      referalAd: {
        description: "Referal ad TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      communitySetting: {
        description: "Community setting TODO",
        ref: "BrandCommunitySettingDefault",
        type: "object",
        properties: {
          enabled: { type: "number", description: "开启社群" },
          progressEnabled: { type: "boolean", description: "展示社群统计" },
          progressType: {
            type: "string",
            required: false,
            enum: ["", "training", "calories"],
            "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
            description: "社群统计数据类型"
          },
          progressAmplificationFactor: {
            type: "number",
            description: "社群统计数据放大系数"
          },
          leaderboardEnabled: { type: "boolean", description: "展示排行榜" },
          leaderboardTypes: {
            type: "array",
            items: { type: "string" },
            description: "展示排行榜类型"
          },
          tasLeaderboards: {
            type: "array",
            items: { type: "string" },
            description: "智慧屏榜单展示"
          },
          operatingLists: {
            type: "array",
            items: { type: "string" },
            description: "运营列表"
          },
          footprintsEnabled: { type: "boolean", description: "展示记忆相册" },
          footprintMemberCountThreshold: {
            type: "number",
            description: "记忆相册展示人数门槛"
          }
        }
      },
      userTerms: { type: "string", description: "User terms TODO" },
      prepayRules: { type: "string", description: "Prepay rules TODO" }
    }
  },
  MobileCoachList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      name: { type: "string", description: "名称" },
      displayName: { type: "string", description: "展示名称" },
      level: {
        description: "等级",
        ref: "CoachLevelDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          category: {
            description: "类型",
            ref: "CoachCategoryDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" }
            }
          },
          categoryId: { type: "number", description: "类型" }
        }
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      }
    }
  },
  MobileCoachEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      name: { type: "string", description: "名称" },
      displayName: { type: "string", description: "展示名称" }
    }
  },
  CoachWorkoutVerficationDefault: {
    type: "object",
    properties: {
      workout: {
        description: "Workout TODO",
        ref: "TasWorkoutBrief",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          enName: { type: "string", description: "英文名" },
          productType: { type: "string", description: "产品类型" }
        }
      },
      verifiedAt: {
        description: "Verified at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  MobileCoachDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      profilePhoto: {
        description: "形象照",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      introduction: { type: "string", description: "介绍" },
      certified: { type: "boolean", description: "Certified TODO" },
      name: { type: "string", description: "名称" },
      displayName: { type: "string", description: "展示名称" },
      wxQrCode: {
        description: "微信二维码",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      level: {
        description: "等级",
        ref: "CoachLevelDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          category: {
            description: "类型",
            ref: "CoachCategoryDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" }
            }
          },
          categoryId: { type: "number", description: "类型" }
        }
      },
      coachDayCount: { type: "number", description: "执教天数" },
      eliteTrainingAvailable: {
        type: "boolean",
        description: "Elite training available TODO"
      },
      trainingCount: { type: "number", description: "团课执教课时数" },
      trainingCountRank: { type: "number", description: "团课执教课时数排名" },
      trainingMemberCount: { type: "number", description: "团课执教课用户数" },
      trainingMemberCountRank: {
        type: "number",
        description: "团课执教课用户数排名"
      },
      certificationList: {
        type: "array",
        items: { type: "string" },
        description: "技能认证"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      workoutVerifications: {
        type: "array",
        items: {
          type: "object",
          properties: {
            workout: {
              description: "Workout TODO",
              ref: "TasWorkoutBrief",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                enName: { type: "string", description: "英文名" },
                productType: { type: "string", description: "产品类型" }
              }
            },
            verifiedAt: {
              description: "Verified at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        description: "Workout verifications TODO",
        ref: "CoachWorkoutVerficationDefault"
      },
      resumeDisplay: { type: "boolean", description: "显示履历" }
    }
  },
  MobileMemberDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      phone: { type: "string", description: "手机" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      age: { type: "number", description: "年龄" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      height: { type: "number", description: "Height TODO" },
      weight: { type: "number", description: "体重" },
      brand: {
        description: "品牌",
        ref: "MobileBrandDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          identifier: { type: "string", description: "标识符" },
          saasType: { type: "string", description: "Saas type TODO" },
          showCourseBookingCount: {
            type: "boolean",
            description: "Show course booking count TODO"
          },
          giftBookingEnabled: {
            type: "boolean",
            description: "Gift booking enabled TODO"
          },
          bookingQueueEnabled: {
            type: "boolean",
            description: "Booking queue enabled TODO"
          },
          groupTrainingCoachBook: {
            type: "boolean",
            description: "教练代约团课"
          },
          groupTrainingCoachCheckIn: {
            type: "boolean",
            description: "教练代签到团课"
          },
          cancellationDeadline: { type: "number", description: "取消时限" },
          vipGroupTrainingDiscount: {
            type: "number",
            description: "Vip group training discount TODO"
          },
          vipFreeTrainingDiscount: {
            type: "number",
            description: "Vip group training discount TODO"
          },
          vipCampDiscount: {
            type: "number",
            description: "Vip camp discount TODO"
          },
          vipCouponPackageDiscount: {
            type: "number",
            description: "Vip coupon package discount TODO"
          },
          vipMembershipCardDiscount: {
            type: "number",
            description: "Vip membership card discount TODO"
          },
          vipCancellationDeadline: {
            type: "number",
            description: "Vip cancellation deadline TODO"
          },
          trainingReport: {
            type: "boolean",
            description: "Training report TODO"
          },
          prepayCardName: {
            type: "string",
            nullable: true,
            description: "储值卡名称"
          },
          availableProductTypes: {
            type: "array",
            items: { type: "string" },
            description: "可用产品类型"
          },
          membershipCardAvailableProductTypes: {
            type: "array",
            items: { type: "string" },
            description: "支持会员卡约课的产品类型"
          },
          prepayCardPurchaseAvailable: {
            type: "boolean",
            description: "启用储值卡充值"
          },
          couponPurchaseAvailable: {
            type: "boolean",
            description: "启用代金券商城"
          },
          mobileCancelEliteTrainingBooking: {
            type: "boolean",
            description: "Mobile cancel elite training booking TODO"
          },
          mobileEliteTrainingBooking: {
            type: "boolean",
            description: "Mobile elite training booking TODO"
          },
          mobileEliteTrainingBookingDeadline: {
            type: "number",
            description: "Mobile elite training booking deadline TODO"
          },
          eliteTrainingScheduleDays: {
            type: "number",
            description: "Elite training schedule days TODO"
          },
          eliteTrainingCoachCheckIn: {
            type: "boolean",
            description: "私教教练代签到"
          },
          eliteTrainingCoachCancel: {
            type: "boolean",
            description: "私教教练端取消预约"
          },
          eliteTrainingCoachLeave: {
            type: "boolean",
            description: "私教教练端隐藏时段"
          },
          expiryAlertEntryThreshold: {
            type: "number",
            description: "Expiry alert entry threshold TODO"
          },
          expiryAlertEliteEntryThreshold: {
            type: "number",
            description: "Expiry alert elite entry threshold TODO"
          },
          expiryAlertPrepayAmountThreshold: {
            type: "number",
            description: "Expiry alert prepay amount threshold TODO"
          },
          expiryAlertValidityPeriodThreshold: {
            type: "number",
            description: "Expiry alert validity period threshold TODO"
          },
          churnThreshold: {
            type: "number",
            description: "Churn threshold TODO"
          },
          newMemberThreshold: {
            type: "number",
            description: "New member threshold TODO"
          },
          inactiveMemberThreshold: {
            type: "number",
            description: "Inactive member threshold TODO"
          },
          inactiveEliteMemberThreshold: {
            type: "number",
            description: "Inactive elite member threshold TODO"
          },
          logo: {
            description: "Logo",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          qrCode: {
            description: "Qr code TODO",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          theme: {
            description: "小程序主题",
            ref: "BrandThemeDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              primaryColor: { type: "string", description: "主题色" },
              secondaryColor: { type: "string", description: "辅助色" }
            }
          },
          wxMessageTemplates: {
            description: "Wx message templates TODO",
            ref: "BrandWxConfigMessageTemplates",
            type: "object",
            properties: {
              courseReminder: {
                type: "string",
                description: "Course reminder TODO"
              },
              bookingCancel: {
                type: "string",
                description: "Booking cancel TODO"
              },
              bookingQueueStatus: {
                type: "string",
                description: "Booking queue status TODO"
              },
              trainingResult: {
                type: "string",
                description: "Training result TODO"
              },
              footprintUploaded: {
                type: "string",
                description: "Footprint uploaded TODO"
              }
            }
          },
          schedulePublishWday: {
            type: "number",
            description: "Schedule publish wday TODO"
          },
          schedulePublishTime: {
            type: "string",
            description: "Schedule publish time TODO"
          },
          referalAd: {
            description: "Referal ad TODO",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          communitySetting: {
            description: "Community setting TODO",
            ref: "BrandCommunitySettingDefault",
            type: "object",
            properties: {
              enabled: { type: "number", description: "开启社群" },
              progressEnabled: { type: "boolean", description: "展示社群统计" },
              progressType: {
                type: "string",
                required: false,
                enum: ["", "training", "calories"],
                "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
                description: "社群统计数据类型"
              },
              progressAmplificationFactor: {
                type: "number",
                description: "社群统计数据放大系数"
              },
              leaderboardEnabled: {
                type: "boolean",
                description: "展示排行榜"
              },
              leaderboardTypes: {
                type: "array",
                items: { type: "string" },
                description: "展示排行榜类型"
              },
              tasLeaderboards: {
                type: "array",
                items: { type: "string" },
                description: "智慧屏榜单展示"
              },
              operatingLists: {
                type: "array",
                items: { type: "string" },
                description: "运营列表"
              },
              footprintsEnabled: {
                type: "boolean",
                description: "展示记忆相册"
              },
              footprintMemberCountThreshold: {
                type: "number",
                description: "记忆相册展示人数门槛"
              }
            }
          },
          userTerms: { type: "string", description: "User terms TODO" },
          prepayRules: { type: "string", description: "Prepay rules TODO" }
        }
      },
      wxInfoSynced: { type: "boolean", description: "Wx info synced TODO" },
      personalInfoFilled: { type: "boolean", description: "个人信息已填" },
      phoneFilled: { type: "boolean", description: "手机号已绑定" },
      profileFilled: { type: "boolean", description: "新手履历已填" },
      promoCode: { type: "string", description: "邀请码" },
      newbie: { type: "boolean", description: "Newbie TODO" },
      defaultStudioId: {
        type: "number",
        nullable: true,
        description: "Default studio TODO"
      },
      active: { type: "boolean", description: "激活" },
      vip: { type: "boolean", description: "vip" },
      coach: {
        description: "Coach TODO",
        ref: "MobileCoachList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "名称" },
          displayName: { type: "string", description: "展示名称" },
          level: {
            description: "等级",
            ref: "CoachLevelDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              category: {
                description: "类型",
                ref: "CoachCategoryDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" }
                }
              },
              categoryId: { type: "number", description: "类型" }
            }
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          }
        }
      },
      isCoach: { type: "boolean", description: "Is 项目" }
    }
  },
  MobileMemberInviteForm: {
    type: "object",
    properties: {
      promoCode: { type: "string", required: true, description: "邀请码" }
    }
  },
  MobileMemberProfileForm: {
    type: "object",
    properties: {
      phone: { type: "string", description: "手机" },
      phoneCode: { type: "string", description: "短信验证码" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      weight: { type: "number", description: "体重" },
      height: { type: "number", description: "Height TODO" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      }
    }
  },
  MobileMemberTrainingReport: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      phone: { type: "string", description: "手机" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      age: { type: "number", description: "年龄" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      height: { type: "number", description: "Height TODO" },
      weight: { type: "number", description: "体重" },
      brand: { description: "品牌" },
      wxInfoSynced: { type: "boolean", description: "Wx info synced TODO" },
      personalInfoFilled: { type: "boolean", description: "个人信息已填" },
      phoneFilled: { type: "boolean", description: "手机号已绑定" },
      profileFilled: { type: "boolean", description: "新手履历已填" },
      promoCode: { type: "string", description: "邀请码" },
      newbie: { type: "boolean", description: "Newbie TODO" },
      defaultStudioId: {
        type: "number",
        nullable: true,
        description: "Default studio TODO"
      },
      active: { type: "boolean", description: "激活" },
      vip: { type: "boolean", description: "vip" },
      coach: { description: "Coach TODO" },
      isCoach: { type: "boolean", description: "Is 项目" },
      groupTrainingCount: { type: "number", description: "团课训练次数" },
      groupTrainingDuration: { type: "number", description: "团课训练时长" },
      joinedDays: { type: "number", description: "加入天数" },
      kol: { type: "boolean", description: "KOL" },
      totalCalories: { type: "number", description: "Total calories TODO" }
    }
  },
  MobileCourseBookingEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "MobileMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          vip: { type: "boolean", description: "vip" }
        }
      }
    }
  },
  CourseMemberFootprintDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      image: {
        description: "图片",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      course: {
        description: "课程",
        ref: "MobileCourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          courseName: { type: "string", description: "课程名称" },
          enCourseName: { type: "string", description: "英文课程名称" },
          zone: { description: "Zone TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" },
              active: { type: "boolean", description: "激活" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              display: { type: "boolean", description: "展示" },
              address: { type: "string", description: "地址" },
              contactPhone: { type: "string", description: "联系电话" },
              longitude: { type: "string", description: "经度" },
              latitude: { type: "string", description: "维度" },
              gatePassword: { type: "string", description: "门禁密码" }
            }
          },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          endTime: {
            description: "结束时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" },
              user: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/UserDefault" }
                ],
                description: "用户"
              },
              userId: { type: "number", description: "用户" },
              profilePhoto: {
                description: "形象照",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              wxQrCode: {
                description: "微信二维码",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              level: {
                description: "等级",
                ref: "CoachLevelDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  category: {
                    description: "类型",
                    ref: "CoachCategoryDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" }
                    }
                  },
                  categoryId: { type: "number", description: "类型" }
                }
              },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              joinedAt: {
                description: "入职时间",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              primaryStudio: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/StudioStudioList" }
                ],
                description: "绑定门店"
              },
              scopeOfAccess: {
                type: "string",
                required: true,
                enum: ["mine", "all"],
                "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                description: "查看排课范围"
              },
              introduction: { type: "string", description: "介绍" },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "教练风格"
              },
              certificationList: {
                type: "array",
                items: { type: "string" },
                description: "技能认证"
              },
              styleList: {
                type: "array",
                items: { type: "string" },
                description: "执教风格"
              },
              resumeDisplay: { type: "boolean", description: "执教数据展示" },
              eliteTrainingCapacity: {
                type: "number",
                description: "私教人数上限"
              },
              primaryStudioId: { type: "number", description: "绑定门店" },
              applyToAllProduct: {
                type: "boolean",
                description: "授权所有训练产品"
              },
              levelId: { type: "number", description: "等级" },
              createdAt: {
                description: "加入时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              }
            }
          },
          capacityState: { type: "string", description: "容量状态" },
          capacity: { type: "number", description: "容量" },
          bookingCount: { type: "number", description: "预约人数" },
          tags: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          productTags: {
            type: "array",
            items: { type: "string" },
            description: "产品标签"
          },
          productStages: {
            type: "array",
            items: { type: "string" },
            description: "Product stages TODO"
          },
          productType: { type: "string", description: "Product type TODO" },
          paymentMethods: {
            type: "array",
            items: { type: "string" },
            description: "Payment methods TODO"
          },
          bookState: { type: "string", description: "预约状态" },
          bookingId: { type: "number", description: "Booking TODO" },
          booking: {
            description: "Booking TODO",
            ref: "MobileCourseBookingGift",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              workflowState: { type: "string", description: "状态" },
              isOwner: { type: "boolean", description: "所有人" },
              giftState: { type: "string", description: "赠课状态" },
              token: { type: "string", description: "token" }
            }
          },
          campId: { type: "number", nullable: true, description: "训练营" },
          price: { type: "string", description: "课程价格" },
          prepayPrice: { type: "string", description: "Prepay price TODO" },
          positionSelectable: { type: "boolean", description: "可以选位" },
          multipleCoach: { type: "boolean", description: "Multiple 项目" },
          eliteTrainingMember: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/MobileMemberDefault" }
            ],
            description: "Elite training member TODO"
          },
          banner: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头图"
          },
          homework: { type: "string", description: "Homework TODO" },
          confirmCourseThreshold: {
            type: "number",
            description: "Confirm course threshold TODO"
          },
          confirmCourseDeadline: {
            type: "number",
            description: "Confirm course deadline TODO"
          },
          hasConfirmCourseThreshold: {
            type: "boolean",
            description: "Has confirm course threshold TODO"
          },
          useHrm: { type: "boolean", description: "使用心率" }
        }
      },
      favorite: { type: "boolean", description: "收藏" },
      trainingNumber: { type: "number", description: "训练编号" }
    }
  },
  MobileCourseTrainingResultDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      avgHr: { type: "number", description: "平均心率" },
      maxHr: { type: "number", description: "最大心率" },
      calories: { type: "number", description: "卡路里消耗" },
      rank: { type: "number", description: "排名" },
      score: { type: "number", description: "得分" },
      member: {
        description: "会员",
        ref: "MobileMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          phone: { type: "string", description: "手机" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          age: { type: "number", description: "年龄" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          height: { type: "number", description: "Height TODO" },
          weight: { type: "number", description: "体重" },
          brand: {
            description: "品牌",
            ref: "MobileBrandDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              identifier: { type: "string", description: "标识符" },
              saasType: { type: "string", description: "Saas type TODO" },
              showCourseBookingCount: {
                type: "boolean",
                description: "Show course booking count TODO"
              },
              giftBookingEnabled: {
                type: "boolean",
                description: "Gift booking enabled TODO"
              },
              bookingQueueEnabled: {
                type: "boolean",
                description: "Booking queue enabled TODO"
              },
              groupTrainingCoachBook: {
                type: "boolean",
                description: "教练代约团课"
              },
              groupTrainingCoachCheckIn: {
                type: "boolean",
                description: "教练代签到团课"
              },
              cancellationDeadline: { type: "number", description: "取消时限" },
              vipGroupTrainingDiscount: {
                type: "number",
                description: "Vip group training discount TODO"
              },
              vipFreeTrainingDiscount: {
                type: "number",
                description: "Vip group training discount TODO"
              },
              vipCampDiscount: {
                type: "number",
                description: "Vip camp discount TODO"
              },
              vipCouponPackageDiscount: {
                type: "number",
                description: "Vip coupon package discount TODO"
              },
              vipMembershipCardDiscount: {
                type: "number",
                description: "Vip membership card discount TODO"
              },
              vipCancellationDeadline: {
                type: "number",
                description: "Vip cancellation deadline TODO"
              },
              trainingReport: {
                type: "boolean",
                description: "Training report TODO"
              },
              prepayCardName: {
                type: "string",
                nullable: true,
                description: "储值卡名称"
              },
              availableProductTypes: {
                type: "array",
                items: { type: "string" },
                description: "可用产品类型"
              },
              membershipCardAvailableProductTypes: {
                type: "array",
                items: { type: "string" },
                description: "支持会员卡约课的产品类型"
              },
              prepayCardPurchaseAvailable: {
                type: "boolean",
                description: "启用储值卡充值"
              },
              couponPurchaseAvailable: {
                type: "boolean",
                description: "启用代金券商城"
              },
              mobileCancelEliteTrainingBooking: {
                type: "boolean",
                description: "Mobile cancel elite training booking TODO"
              },
              mobileEliteTrainingBooking: {
                type: "boolean",
                description: "Mobile elite training booking TODO"
              },
              mobileEliteTrainingBookingDeadline: {
                type: "number",
                description: "Mobile elite training booking deadline TODO"
              },
              eliteTrainingScheduleDays: {
                type: "number",
                description: "Elite training schedule days TODO"
              },
              eliteTrainingCoachCheckIn: {
                type: "boolean",
                description: "私教教练代签到"
              },
              eliteTrainingCoachCancel: {
                type: "boolean",
                description: "私教教练端取消预约"
              },
              eliteTrainingCoachLeave: {
                type: "boolean",
                description: "私教教练端隐藏时段"
              },
              expiryAlertEntryThreshold: {
                type: "number",
                description: "Expiry alert entry threshold TODO"
              },
              expiryAlertEliteEntryThreshold: {
                type: "number",
                description: "Expiry alert elite entry threshold TODO"
              },
              expiryAlertPrepayAmountThreshold: {
                type: "number",
                description: "Expiry alert prepay amount threshold TODO"
              },
              expiryAlertValidityPeriodThreshold: {
                type: "number",
                description: "Expiry alert validity period threshold TODO"
              },
              churnThreshold: {
                type: "number",
                description: "Churn threshold TODO"
              },
              newMemberThreshold: {
                type: "number",
                description: "New member threshold TODO"
              },
              inactiveMemberThreshold: {
                type: "number",
                description: "Inactive member threshold TODO"
              },
              inactiveEliteMemberThreshold: {
                type: "number",
                description: "Inactive elite member threshold TODO"
              },
              logo: {
                description: "Logo",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              qrCode: {
                description: "Qr code TODO",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              theme: {
                description: "小程序主题",
                ref: "BrandThemeDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  primaryColor: { type: "string", description: "主题色" },
                  secondaryColor: { type: "string", description: "辅助色" }
                }
              },
              wxMessageTemplates: {
                description: "Wx message templates TODO",
                ref: "BrandWxConfigMessageTemplates",
                type: "object",
                properties: {
                  courseReminder: {
                    type: "string",
                    description: "Course reminder TODO"
                  },
                  bookingCancel: {
                    type: "string",
                    description: "Booking cancel TODO"
                  },
                  bookingQueueStatus: {
                    type: "string",
                    description: "Booking queue status TODO"
                  },
                  trainingResult: {
                    type: "string",
                    description: "Training result TODO"
                  },
                  footprintUploaded: {
                    type: "string",
                    description: "Footprint uploaded TODO"
                  }
                }
              },
              schedulePublishWday: {
                type: "number",
                description: "Schedule publish wday TODO"
              },
              schedulePublishTime: {
                type: "string",
                description: "Schedule publish time TODO"
              },
              referalAd: {
                description: "Referal ad TODO",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              communitySetting: {
                description: "Community setting TODO",
                ref: "BrandCommunitySettingDefault",
                type: "object",
                properties: {
                  enabled: { type: "number", description: "开启社群" },
                  progressEnabled: {
                    type: "boolean",
                    description: "展示社群统计"
                  },
                  progressType: {
                    type: "string",
                    required: false,
                    enum: ["", "training", "calories"],
                    "x-i18n": {
                      "": "不展示",
                      training: "打卡",
                      calories: "燃脂"
                    },
                    description: "社群统计数据类型"
                  },
                  progressAmplificationFactor: {
                    type: "number",
                    description: "社群统计数据放大系数"
                  },
                  leaderboardEnabled: {
                    type: "boolean",
                    description: "展示排行榜"
                  },
                  leaderboardTypes: {
                    type: "array",
                    items: { type: "string" },
                    description: "展示排行榜类型"
                  },
                  tasLeaderboards: {
                    type: "array",
                    items: { type: "string" },
                    description: "智慧屏榜单展示"
                  },
                  operatingLists: {
                    type: "array",
                    items: { type: "string" },
                    description: "运营列表"
                  },
                  footprintsEnabled: {
                    type: "boolean",
                    description: "展示记忆相册"
                  },
                  footprintMemberCountThreshold: {
                    type: "number",
                    description: "记忆相册展示人数门槛"
                  }
                }
              },
              userTerms: { type: "string", description: "User terms TODO" },
              prepayRules: { type: "string", description: "Prepay rules TODO" }
            }
          },
          wxInfoSynced: { type: "boolean", description: "Wx info synced TODO" },
          personalInfoFilled: { type: "boolean", description: "个人信息已填" },
          phoneFilled: { type: "boolean", description: "手机号已绑定" },
          profileFilled: { type: "boolean", description: "新手履历已填" },
          promoCode: { type: "string", description: "邀请码" },
          newbie: { type: "boolean", description: "Newbie TODO" },
          defaultStudioId: {
            type: "number",
            nullable: true,
            description: "Default studio TODO"
          },
          active: { type: "boolean", description: "激活" },
          vip: { type: "boolean", description: "vip" },
          coach: {
            description: "Coach TODO",
            ref: "MobileCoachList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "名称" },
              displayName: { type: "string", description: "展示名称" },
              level: {
                description: "等级",
                ref: "CoachLevelDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  category: {
                    description: "类型",
                    ref: "CoachCategoryDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" }
                    }
                  },
                  categoryId: { type: "number", description: "类型" }
                }
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              }
            }
          },
          isCoach: { type: "boolean", description: "Is 项目" }
        }
      },
      timeInZone: {
        type: "array",
        items: { type: "number" },
        description: "Time in zone TODO"
      },
      scoredDuration: { type: "number", description: "Scored duration TODO" },
      epoc: { type: "boolean", description: "epoc" }
    }
  },
  MobileCourseCalorieAchievementDefault: {
    type: "object",
    properties: {
      count: { type: "number", description: "数量" },
      food: { type: "string", description: "视图" },
      imageUrl: { type: "string", description: "图片 URL" },
      unit: { type: "string", description: "单位" }
    }
  },
  MobileCourseTrainingResultDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      avgHr: { type: "number", description: "平均心率" },
      maxHr: { type: "number", description: "最大心率" },
      calories: { type: "number", description: "卡路里消耗" },
      rank: { type: "number", description: "排名" },
      score: { type: "number", description: "得分" },
      member: {
        description: "会员",
        ref: "MobileMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          phone: { type: "string", description: "手机" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          age: { type: "number", description: "年龄" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          height: { type: "number", description: "Height TODO" },
          weight: { type: "number", description: "体重" },
          brand: {
            description: "品牌",
            ref: "MobileBrandDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              identifier: { type: "string", description: "标识符" },
              saasType: { type: "string", description: "Saas type TODO" },
              showCourseBookingCount: {
                type: "boolean",
                description: "Show course booking count TODO"
              },
              giftBookingEnabled: {
                type: "boolean",
                description: "Gift booking enabled TODO"
              },
              bookingQueueEnabled: {
                type: "boolean",
                description: "Booking queue enabled TODO"
              },
              groupTrainingCoachBook: {
                type: "boolean",
                description: "教练代约团课"
              },
              groupTrainingCoachCheckIn: {
                type: "boolean",
                description: "教练代签到团课"
              },
              cancellationDeadline: { type: "number", description: "取消时限" },
              vipGroupTrainingDiscount: {
                type: "number",
                description: "Vip group training discount TODO"
              },
              vipFreeTrainingDiscount: {
                type: "number",
                description: "Vip group training discount TODO"
              },
              vipCampDiscount: {
                type: "number",
                description: "Vip camp discount TODO"
              },
              vipCouponPackageDiscount: {
                type: "number",
                description: "Vip coupon package discount TODO"
              },
              vipMembershipCardDiscount: {
                type: "number",
                description: "Vip membership card discount TODO"
              },
              vipCancellationDeadline: {
                type: "number",
                description: "Vip cancellation deadline TODO"
              },
              trainingReport: {
                type: "boolean",
                description: "Training report TODO"
              },
              prepayCardName: {
                type: "string",
                nullable: true,
                description: "储值卡名称"
              },
              availableProductTypes: {
                type: "array",
                items: { type: "string" },
                description: "可用产品类型"
              },
              membershipCardAvailableProductTypes: {
                type: "array",
                items: { type: "string" },
                description: "支持会员卡约课的产品类型"
              },
              prepayCardPurchaseAvailable: {
                type: "boolean",
                description: "启用储值卡充值"
              },
              couponPurchaseAvailable: {
                type: "boolean",
                description: "启用代金券商城"
              },
              mobileCancelEliteTrainingBooking: {
                type: "boolean",
                description: "Mobile cancel elite training booking TODO"
              },
              mobileEliteTrainingBooking: {
                type: "boolean",
                description: "Mobile elite training booking TODO"
              },
              mobileEliteTrainingBookingDeadline: {
                type: "number",
                description: "Mobile elite training booking deadline TODO"
              },
              eliteTrainingScheduleDays: {
                type: "number",
                description: "Elite training schedule days TODO"
              },
              eliteTrainingCoachCheckIn: {
                type: "boolean",
                description: "私教教练代签到"
              },
              eliteTrainingCoachCancel: {
                type: "boolean",
                description: "私教教练端取消预约"
              },
              eliteTrainingCoachLeave: {
                type: "boolean",
                description: "私教教练端隐藏时段"
              },
              expiryAlertEntryThreshold: {
                type: "number",
                description: "Expiry alert entry threshold TODO"
              },
              expiryAlertEliteEntryThreshold: {
                type: "number",
                description: "Expiry alert elite entry threshold TODO"
              },
              expiryAlertPrepayAmountThreshold: {
                type: "number",
                description: "Expiry alert prepay amount threshold TODO"
              },
              expiryAlertValidityPeriodThreshold: {
                type: "number",
                description: "Expiry alert validity period threshold TODO"
              },
              churnThreshold: {
                type: "number",
                description: "Churn threshold TODO"
              },
              newMemberThreshold: {
                type: "number",
                description: "New member threshold TODO"
              },
              inactiveMemberThreshold: {
                type: "number",
                description: "Inactive member threshold TODO"
              },
              inactiveEliteMemberThreshold: {
                type: "number",
                description: "Inactive elite member threshold TODO"
              },
              logo: {
                description: "Logo",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              qrCode: {
                description: "Qr code TODO",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              theme: {
                description: "小程序主题",
                ref: "BrandThemeDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  primaryColor: { type: "string", description: "主题色" },
                  secondaryColor: { type: "string", description: "辅助色" }
                }
              },
              wxMessageTemplates: {
                description: "Wx message templates TODO",
                ref: "BrandWxConfigMessageTemplates",
                type: "object",
                properties: {
                  courseReminder: {
                    type: "string",
                    description: "Course reminder TODO"
                  },
                  bookingCancel: {
                    type: "string",
                    description: "Booking cancel TODO"
                  },
                  bookingQueueStatus: {
                    type: "string",
                    description: "Booking queue status TODO"
                  },
                  trainingResult: {
                    type: "string",
                    description: "Training result TODO"
                  },
                  footprintUploaded: {
                    type: "string",
                    description: "Footprint uploaded TODO"
                  }
                }
              },
              schedulePublishWday: {
                type: "number",
                description: "Schedule publish wday TODO"
              },
              schedulePublishTime: {
                type: "string",
                description: "Schedule publish time TODO"
              },
              referalAd: {
                description: "Referal ad TODO",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              communitySetting: {
                description: "Community setting TODO",
                ref: "BrandCommunitySettingDefault",
                type: "object",
                properties: {
                  enabled: { type: "number", description: "开启社群" },
                  progressEnabled: {
                    type: "boolean",
                    description: "展示社群统计"
                  },
                  progressType: {
                    type: "string",
                    required: false,
                    enum: ["", "training", "calories"],
                    "x-i18n": {
                      "": "不展示",
                      training: "打卡",
                      calories: "燃脂"
                    },
                    description: "社群统计数据类型"
                  },
                  progressAmplificationFactor: {
                    type: "number",
                    description: "社群统计数据放大系数"
                  },
                  leaderboardEnabled: {
                    type: "boolean",
                    description: "展示排行榜"
                  },
                  leaderboardTypes: {
                    type: "array",
                    items: { type: "string" },
                    description: "展示排行榜类型"
                  },
                  tasLeaderboards: {
                    type: "array",
                    items: { type: "string" },
                    description: "智慧屏榜单展示"
                  },
                  operatingLists: {
                    type: "array",
                    items: { type: "string" },
                    description: "运营列表"
                  },
                  footprintsEnabled: {
                    type: "boolean",
                    description: "展示记忆相册"
                  },
                  footprintMemberCountThreshold: {
                    type: "number",
                    description: "记忆相册展示人数门槛"
                  }
                }
              },
              userTerms: { type: "string", description: "User terms TODO" },
              prepayRules: { type: "string", description: "Prepay rules TODO" }
            }
          },
          wxInfoSynced: { type: "boolean", description: "Wx info synced TODO" },
          personalInfoFilled: { type: "boolean", description: "个人信息已填" },
          phoneFilled: { type: "boolean", description: "手机号已绑定" },
          profileFilled: { type: "boolean", description: "新手履历已填" },
          promoCode: { type: "string", description: "邀请码" },
          newbie: { type: "boolean", description: "Newbie TODO" },
          defaultStudioId: {
            type: "number",
            nullable: true,
            description: "Default studio TODO"
          },
          active: { type: "boolean", description: "激活" },
          vip: { type: "boolean", description: "vip" },
          coach: {
            description: "Coach TODO",
            ref: "MobileCoachList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "名称" },
              displayName: { type: "string", description: "展示名称" },
              level: {
                description: "等级",
                ref: "CoachLevelDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  category: {
                    description: "类型",
                    ref: "CoachCategoryDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" }
                    }
                  },
                  categoryId: { type: "number", description: "类型" }
                }
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              }
            }
          },
          isCoach: { type: "boolean", description: "Is 项目" }
        }
      },
      timeInZone: {
        type: "array",
        items: { type: "number" },
        description: "Time in zone TODO"
      },
      scoredDuration: { type: "number", description: "Scored duration TODO" },
      epoc: { type: "boolean", description: "epoc" },
      newCalorieRecord: { type: "boolean", description: "卡路里消耗新记录" },
      calorieAchievement: {
        description: "卡路里成就",
        ref: "MobileCourseCalorieAchievementDefault",
        type: "object",
        properties: {
          count: { type: "number", description: "数量" },
          food: { type: "string", description: "视图" },
          imageUrl: { type: "string", description: "图片 URL" },
          unit: { type: "string", description: "单位" }
        }
      },
      newScoreRecord: { type: "boolean", description: "得分新记录" },
      scoreIncrease: { type: "number", description: "得分增加" },
      hrRecords: {
        type: "array",
        items: { type: "number" },
        description: "心率数据"
      }
    }
  },
  MobileCourseBookingWithResult: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      course: {
        description: "课程",
        ref: "MobileCourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          courseName: { type: "string", description: "课程名称" },
          enCourseName: { type: "string", description: "英文课程名称" },
          zone: { description: "Zone TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" },
              active: { type: "boolean", description: "激活" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              display: { type: "boolean", description: "展示" },
              address: { type: "string", description: "地址" },
              contactPhone: { type: "string", description: "联系电话" },
              longitude: { type: "string", description: "经度" },
              latitude: { type: "string", description: "维度" },
              gatePassword: { type: "string", description: "门禁密码" }
            }
          },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          endTime: {
            description: "结束时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" },
              user: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/UserDefault" }
                ],
                description: "用户"
              },
              userId: { type: "number", description: "用户" },
              profilePhoto: {
                description: "形象照",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              wxQrCode: {
                description: "微信二维码",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              level: {
                description: "等级",
                ref: "CoachLevelDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  category: {
                    description: "类型",
                    ref: "CoachCategoryDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" }
                    }
                  },
                  categoryId: { type: "number", description: "类型" }
                }
              },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              joinedAt: {
                description: "入职时间",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              primaryStudio: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/StudioStudioList" }
                ],
                description: "绑定门店"
              },
              scopeOfAccess: {
                type: "string",
                required: true,
                enum: ["mine", "all"],
                "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                description: "查看排课范围"
              },
              introduction: { type: "string", description: "介绍" },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "教练风格"
              },
              certificationList: {
                type: "array",
                items: { type: "string" },
                description: "技能认证"
              },
              styleList: {
                type: "array",
                items: { type: "string" },
                description: "执教风格"
              },
              resumeDisplay: { type: "boolean", description: "执教数据展示" },
              eliteTrainingCapacity: {
                type: "number",
                description: "私教人数上限"
              },
              primaryStudioId: { type: "number", description: "绑定门店" },
              applyToAllProduct: {
                type: "boolean",
                description: "授权所有训练产品"
              },
              levelId: { type: "number", description: "等级" },
              createdAt: {
                description: "加入时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              }
            }
          },
          capacityState: { type: "string", description: "容量状态" },
          capacity: { type: "number", description: "容量" },
          bookingCount: { type: "number", description: "预约人数" },
          tags: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          productTags: {
            type: "array",
            items: { type: "string" },
            description: "产品标签"
          },
          productStages: {
            type: "array",
            items: { type: "string" },
            description: "Product stages TODO"
          },
          productType: { type: "string", description: "Product type TODO" },
          paymentMethods: {
            type: "array",
            items: { type: "string" },
            description: "Payment methods TODO"
          },
          bookState: { type: "string", description: "预约状态" },
          bookingId: { type: "number", description: "Booking TODO" },
          booking: {
            description: "Booking TODO",
            ref: "MobileCourseBookingGift",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              workflowState: { type: "string", description: "状态" },
              isOwner: { type: "boolean", description: "所有人" },
              giftState: { type: "string", description: "赠课状态" },
              token: { type: "string", description: "token" }
            }
          },
          campId: { type: "number", nullable: true, description: "训练营" },
          price: { type: "string", description: "课程价格" },
          prepayPrice: { type: "string", description: "Prepay price TODO" },
          positionSelectable: { type: "boolean", description: "可以选位" },
          multipleCoach: { type: "boolean", description: "Multiple 项目" },
          eliteTrainingMember: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/MobileMemberDefault" }
            ],
            description: "Elite training member TODO"
          },
          banner: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头图"
          },
          homework: { type: "string", description: "Homework TODO" },
          confirmCourseThreshold: {
            type: "number",
            description: "Confirm course threshold TODO"
          },
          confirmCourseDeadline: {
            type: "number",
            description: "Confirm course deadline TODO"
          },
          hasConfirmCourseThreshold: {
            type: "boolean",
            description: "Has confirm course threshold TODO"
          },
          useHrm: { type: "boolean", description: "使用心率" }
        }
      },
      member: { description: "会员" },
      owner: { description: "所有人" },
      primaryCoachName: { type: "string", description: "教练" },
      studioName: { type: "string", description: "门店" },
      courseDuration: { type: "number", description: "课程时长" },
      hrSensor: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/StudioHrSensorDefault" }
        ],
        description: "心率带"
      },
      workflowState: { type: "string", description: "状态" },
      trainingCount: { type: "number", description: "训练数" },
      cancellable: { type: "boolean", description: "可以取消" },
      cancellationDeadline: { type: "number", description: "取消时间" },
      queuePosition: {
        type: "number",
        nullable: true,
        description: "排位顺序"
      },
      position: { type: "number", nullable: true, description: "位置" },
      coachComments: { type: "string", description: "教练点评" },
      rated: { type: "boolean", description: "已评价" },
      reviewed: { type: "boolean", description: "已评论" },
      isOwner: { type: "boolean", description: "所有人" },
      giftState: { type: "string", description: "赠课状态" },
      token: { type: "string", description: "token" },
      memberFootprint: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CourseMemberFootprintDefault" }
        ],
        description: "训练照片"
      },
      trainingResult: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MobileCourseTrainingResultDetail" }
        ],
        description: "训练结果"
      }
    }
  },
  MobileOrderDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      description: { type: "string", description: "描述" },
      serial: { type: "string", description: "编号" },
      workflowState: { type: "string", description: "状态" },
      paymentMethod: {
        type: "string",
        nullable: true,
        description: "支付方式"
      },
      notes: { type: "string", nullable: true, description: "备注" }
    }
  },
  MobileOrderPurchaseCardForm: {
    type: "object",
    properties: {
      cardOptionId: { type: "number", description: "充值选项" },
      coachId: { type: "number", nullable: true, description: "关联账户" }
    }
  },
  MobileOrderWechatPayment: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      description: { type: "string", description: "描述" },
      serial: { type: "string", description: "编号" },
      workflowState: { type: "string", description: "状态" },
      paymentMethod: {
        type: "string",
        nullable: true,
        description: "支付方式"
      },
      notes: { type: "string", nullable: true, description: "备注" },
      wxPayRequest: {
        type: "string",
        nullable: true,
        description: "微信支付请求"
      }
    }
  },
  MobileCourseBookingWithOrder: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      course: {
        description: "课程",
        ref: "MobileCourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          courseName: { type: "string", description: "课程名称" },
          enCourseName: { type: "string", description: "英文课程名称" },
          zone: { description: "Zone TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" },
              active: { type: "boolean", description: "激活" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              display: { type: "boolean", description: "展示" },
              address: { type: "string", description: "地址" },
              contactPhone: { type: "string", description: "联系电话" },
              longitude: { type: "string", description: "经度" },
              latitude: { type: "string", description: "维度" },
              gatePassword: { type: "string", description: "门禁密码" }
            }
          },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          endTime: {
            description: "结束时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" },
              user: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/UserDefault" }
                ],
                description: "用户"
              },
              userId: { type: "number", description: "用户" },
              profilePhoto: {
                description: "形象照",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              wxQrCode: {
                description: "微信二维码",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              level: {
                description: "等级",
                ref: "CoachLevelDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  category: {
                    description: "类型",
                    ref: "CoachCategoryDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" }
                    }
                  },
                  categoryId: { type: "number", description: "类型" }
                }
              },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              joinedAt: {
                description: "入职时间",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              primaryStudio: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/StudioStudioList" }
                ],
                description: "绑定门店"
              },
              scopeOfAccess: {
                type: "string",
                required: true,
                enum: ["mine", "all"],
                "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                description: "查看排课范围"
              },
              introduction: { type: "string", description: "介绍" },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "教练风格"
              },
              certificationList: {
                type: "array",
                items: { type: "string" },
                description: "技能认证"
              },
              styleList: {
                type: "array",
                items: { type: "string" },
                description: "执教风格"
              },
              resumeDisplay: { type: "boolean", description: "执教数据展示" },
              eliteTrainingCapacity: {
                type: "number",
                description: "私教人数上限"
              },
              primaryStudioId: { type: "number", description: "绑定门店" },
              applyToAllProduct: {
                type: "boolean",
                description: "授权所有训练产品"
              },
              levelId: { type: "number", description: "等级" },
              createdAt: {
                description: "加入时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              }
            }
          },
          capacityState: { type: "string", description: "容量状态" },
          capacity: { type: "number", description: "容量" },
          bookingCount: { type: "number", description: "预约人数" },
          tags: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          productTags: {
            type: "array",
            items: { type: "string" },
            description: "产品标签"
          },
          productStages: {
            type: "array",
            items: { type: "string" },
            description: "Product stages TODO"
          },
          productType: { type: "string", description: "Product type TODO" },
          paymentMethods: {
            type: "array",
            items: { type: "string" },
            description: "Payment methods TODO"
          },
          bookState: { type: "string", description: "预约状态" },
          bookingId: { type: "number", description: "Booking TODO" },
          booking: {
            description: "Booking TODO",
            ref: "MobileCourseBookingGift",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              workflowState: { type: "string", description: "状态" },
              isOwner: { type: "boolean", description: "所有人" },
              giftState: { type: "string", description: "赠课状态" },
              token: { type: "string", description: "token" }
            }
          },
          campId: { type: "number", nullable: true, description: "训练营" },
          price: { type: "string", description: "课程价格" },
          prepayPrice: { type: "string", description: "Prepay price TODO" },
          positionSelectable: { type: "boolean", description: "可以选位" },
          multipleCoach: { type: "boolean", description: "Multiple 项目" },
          eliteTrainingMember: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/MobileMemberDefault" }
            ],
            description: "Elite training member TODO"
          },
          banner: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头图"
          },
          homework: { type: "string", description: "Homework TODO" },
          confirmCourseThreshold: {
            type: "number",
            description: "Confirm course threshold TODO"
          },
          confirmCourseDeadline: {
            type: "number",
            description: "Confirm course deadline TODO"
          },
          hasConfirmCourseThreshold: {
            type: "boolean",
            description: "Has confirm course threshold TODO"
          },
          useHrm: { type: "boolean", description: "使用心率" }
        }
      },
      member: { description: "会员" },
      owner: { description: "所有人" },
      primaryCoachName: { type: "string", description: "教练" },
      studioName: { type: "string", description: "门店" },
      courseDuration: { type: "number", description: "课程时长" },
      hrSensor: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/StudioHrSensorDefault" }
        ],
        description: "心率带"
      },
      workflowState: { type: "string", description: "状态" },
      trainingCount: { type: "number", description: "训练数" },
      cancellable: { type: "boolean", description: "可以取消" },
      cancellationDeadline: { type: "number", description: "取消时间" },
      queuePosition: {
        type: "number",
        nullable: true,
        description: "排位顺序"
      },
      position: { type: "number", nullable: true, description: "位置" },
      coachComments: { type: "string", description: "教练点评" },
      rated: { type: "boolean", description: "已评价" },
      reviewed: { type: "boolean", description: "已评论" },
      isOwner: { type: "boolean", description: "所有人" },
      giftState: { type: "string", description: "赠课状态" },
      token: { type: "string", description: "token" },
      order: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MobileOrderWechatPayment" }
        ],
        description: "订单"
      }
    }
  },
  MobileCourseBookingGift: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      workflowState: { type: "string", description: "状态" },
      isOwner: { type: "boolean", description: "所有人" },
      giftState: { type: "string", description: "赠课状态" },
      token: { type: "string", description: "token" }
    }
  },
  MobileCourseBookingCompanion: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "MobileMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          vip: { type: "boolean", description: "vip" }
        }
      },
      meetCount: { type: "number", description: "相遇次数" }
    }
  },
  MobileCourseBookingRedeemForm: {
    type: "object",
    properties: { token: { type: "string", description: "token" } }
  },
  MobileCourseCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "英文课程名称" },
      zone: { description: "Zone TODO" },
      studio: {
        description: "门店",
        ref: "StudioStudioEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          fullName: { type: "string", description: "门店全称" },
          shortName: { type: "string", description: "门店名称" },
          active: { type: "boolean", description: "激活" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          display: { type: "boolean", description: "展示" },
          address: { type: "string", description: "地址" },
          contactPhone: { type: "string", description: "联系电话" },
          longitude: { type: "string", description: "经度" },
          latitude: { type: "string", description: "维度" },
          gatePassword: { type: "string", description: "门禁密码" }
        }
      },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      endTime: {
        description: "结束时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      primaryCoach: {
        description: "教练",
        ref: "CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" },
          user: {
            anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
            description: "用户"
          },
          userId: { type: "number", description: "用户" },
          profilePhoto: {
            description: "形象照",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          wxQrCode: {
            description: "微信二维码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          level: {
            description: "等级",
            ref: "CoachLevelDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              category: {
                description: "类型",
                ref: "CoachCategoryDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" }
                }
              },
              categoryId: { type: "number", description: "类型" }
            }
          },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          joinedAt: {
            description: "入职时间",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          primaryStudio: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/StudioStudioList" }
            ],
            description: "绑定门店"
          },
          scopeOfAccess: {
            type: "string",
            required: true,
            enum: ["mine", "all"],
            "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
            description: "查看排课范围"
          },
          introduction: { type: "string", description: "介绍" },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "教练风格"
          },
          certificationList: {
            type: "array",
            items: { type: "string" },
            description: "技能认证"
          },
          styleList: {
            type: "array",
            items: { type: "string" },
            description: "执教风格"
          },
          resumeDisplay: { type: "boolean", description: "执教数据展示" },
          eliteTrainingCapacity: {
            type: "number",
            description: "私教人数上限"
          },
          primaryStudioId: { type: "number", description: "绑定门店" },
          applyToAllProduct: {
            type: "boolean",
            description: "授权所有训练产品"
          },
          levelId: { type: "number", description: "等级" },
          createdAt: {
            description: "加入时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      capacityState: { type: "string", description: "容量状态" },
      capacity: { type: "number", description: "容量" },
      bookingCount: { type: "number", description: "预约人数" },
      tags: { type: "array", items: { type: "string" }, description: "标签" },
      productTags: {
        type: "array",
        items: { type: "string" },
        description: "产品标签"
      },
      productStages: {
        type: "array",
        items: { type: "string" },
        description: "Product stages TODO"
      },
      productType: { type: "string", description: "Product type TODO" },
      paymentMethods: {
        type: "array",
        items: { type: "string" },
        description: "Payment methods TODO"
      },
      bookState: { type: "string", description: "预约状态" },
      bookingId: { type: "number", description: "Booking TODO" },
      booking: {
        description: "Booking TODO",
        ref: "MobileCourseBookingGift",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          workflowState: { type: "string", description: "状态" },
          isOwner: { type: "boolean", description: "所有人" },
          giftState: { type: "string", description: "赠课状态" },
          token: { type: "string", description: "token" }
        }
      },
      campId: { type: "number", nullable: true, description: "训练营" },
      price: { type: "string", description: "课程价格" },
      prepayPrice: { type: "string", description: "Prepay price TODO" },
      positionSelectable: { type: "boolean", description: "可以选位" },
      multipleCoach: { type: "boolean", description: "Multiple 项目" },
      eliteTrainingMember: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MobileMemberDefault" }
        ],
        description: "Elite training member TODO"
      },
      banner: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头图"
      },
      homework: { type: "string", description: "Homework TODO" },
      confirmCourseThreshold: {
        type: "number",
        description: "Confirm course threshold TODO"
      },
      confirmCourseDeadline: {
        type: "number",
        description: "Confirm course deadline TODO"
      },
      hasConfirmCourseThreshold: {
        type: "boolean",
        description: "Has confirm course threshold TODO"
      },
      useHrm: { type: "boolean", description: "使用心率" }
    }
  },
  MobileCourseCourseDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "英文课程名称" },
      zone: { description: "Zone TODO" },
      studio: {
        description: "门店",
        ref: "StudioStudioEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          fullName: { type: "string", description: "门店全称" },
          shortName: { type: "string", description: "门店名称" },
          active: { type: "boolean", description: "激活" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          display: { type: "boolean", description: "展示" },
          address: { type: "string", description: "地址" },
          contactPhone: { type: "string", description: "联系电话" },
          longitude: { type: "string", description: "经度" },
          latitude: { type: "string", description: "维度" },
          gatePassword: { type: "string", description: "门禁密码" }
        }
      },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      endTime: {
        description: "结束时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      primaryCoach: {
        description: "教练",
        ref: "CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" },
          user: {
            anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
            description: "用户"
          },
          userId: { type: "number", description: "用户" },
          profilePhoto: {
            description: "形象照",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          wxQrCode: {
            description: "微信二维码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          level: {
            description: "等级",
            ref: "CoachLevelDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              category: {
                description: "类型",
                ref: "CoachCategoryDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" }
                }
              },
              categoryId: { type: "number", description: "类型" }
            }
          },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          joinedAt: {
            description: "入职时间",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          primaryStudio: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/StudioStudioList" }
            ],
            description: "绑定门店"
          },
          scopeOfAccess: {
            type: "string",
            required: true,
            enum: ["mine", "all"],
            "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
            description: "查看排课范围"
          },
          introduction: { type: "string", description: "介绍" },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "教练风格"
          },
          certificationList: {
            type: "array",
            items: { type: "string" },
            description: "技能认证"
          },
          styleList: {
            type: "array",
            items: { type: "string" },
            description: "执教风格"
          },
          resumeDisplay: { type: "boolean", description: "执教数据展示" },
          eliteTrainingCapacity: {
            type: "number",
            description: "私教人数上限"
          },
          primaryStudioId: { type: "number", description: "绑定门店" },
          applyToAllProduct: {
            type: "boolean",
            description: "授权所有训练产品"
          },
          levelId: { type: "number", description: "等级" },
          createdAt: {
            description: "加入时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      capacityState: { type: "string", description: "容量状态" },
      capacity: { type: "number", description: "容量" },
      bookingCount: { type: "number", description: "预约人数" },
      tags: { type: "array", items: { type: "string" }, description: "标签" },
      productTags: {
        type: "array",
        items: { type: "string" },
        description: "产品标签"
      },
      productStages: {
        type: "array",
        items: { type: "string" },
        description: "Product stages TODO"
      },
      productType: { type: "string", description: "Product type TODO" },
      paymentMethods: {
        type: "array",
        items: { type: "string" },
        description: "Payment methods TODO"
      },
      bookState: { type: "string", description: "预约状态" },
      bookingId: { type: "number", description: "Booking TODO" },
      booking: {
        description: "Booking TODO",
        ref: "MobileCourseBookingGift",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          workflowState: { type: "string", description: "状态" },
          isOwner: { type: "boolean", description: "所有人" },
          giftState: { type: "string", description: "赠课状态" },
          token: { type: "string", description: "token" }
        }
      },
      campId: { type: "number", nullable: true, description: "训练营" },
      price: { type: "string", description: "课程价格" },
      prepayPrice: { type: "string", description: "Prepay price TODO" },
      positionSelectable: { type: "boolean", description: "可以选位" },
      multipleCoach: { type: "boolean", description: "Multiple 项目" },
      eliteTrainingMember: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MobileMemberDefault" }
        ],
        description: "Elite training member TODO"
      },
      banner: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头图"
      },
      homework: { type: "string", description: "Homework TODO" },
      confirmCourseThreshold: {
        type: "number",
        description: "Confirm course threshold TODO"
      },
      confirmCourseDeadline: {
        type: "number",
        description: "Confirm course deadline TODO"
      },
      hasConfirmCourseThreshold: {
        type: "boolean",
        description: "Has confirm course threshold TODO"
      },
      useHrm: { type: "boolean", description: "使用心率" },
      assistantCoaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" }
          }
        },
        description: "助教",
        ref: "CoachCoachEssential"
      },
      companions: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "会员",
              ref: "MobileMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                vip: { type: "boolean", description: "vip" }
              }
            },
            meetCount: { type: "number", description: "相遇次数" }
          }
        },
        description: "同场会员",
        ref: "MobileCourseBookingCompanion"
      },
      product: {
        description: "训练产品",
        ref: "CourseProductDetail",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          brandId: { type: "number", description: "品牌" },
          name: { type: "string", description: "课程名称" },
          enName: { type: "string", description: "英文名称" },
          usageCn: { type: "string", description: "Usage cn TODO" },
          forKid: { type: "boolean", description: "For kid TODO" },
          stageList: {
            type: "array",
            items: { type: "string" },
            description: "Stage list TODO"
          },
          provider: { type: "string", description: "产品品牌" },
          expiryDate: {
            description: "授权截止",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          category: {
            type: "string",
            required: false,
            enum: ["brand", "platform"],
            "x-i18n": { brand: "自研产品", platform: "授权产品" },
            description: "类型"
          },
          productType: {
            type: "string",
            required: false,
            enum: ["group_training", "elite_training", "camp", "free_training"],
            "x-i18n": {
              group_training: "团课",
              elite_training: "私教",
              camp: "训练营",
              free_training: "自由训练"
            },
            description: "类型"
          },
          scheduleRule: {
            type: "string",
            required: false,
            enum: ["default", "follow_zone", "follow_coach"],
            "x-i18n": {
              default: "默认",
              follow_zone: "场地记忆",
              follow_coach: "教练记忆"
            },
            description: "Schedule rule TODO"
          },
          entryPrice: { type: "string", description: "Entry price TODO" },
          active: { type: "boolean", description: "激活" },
          price: { type: "string", description: "价格" },
          prepayPrice: { type: "string", description: "储值卡支付价格" },
          workoutId: { type: "number", description: "训练课程" },
          duration: { type: "number", description: "课程时长" },
          courseFee: { type: "string", description: "主教课时费" },
          assistantCourseFee: { type: "string", description: "助教课时费" },
          trialCourseFee: { type: "string", description: "实习课时费" },
          intro: { type: "string", nullable: true, description: "课程介绍" },
          groundType: {
            type: "string",
            required: false,
            enum: ["indoor", "outdoor"],
            "x-i18n": { indoor: "室内", outdoor: "户外" },
            description: "Ground type TODO"
          },
          academy: { type: "boolean", description: "提供培训" },
          note: { type: "string", description: "授权备注" },
          live: {
            description: "课程Live",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          banners: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "封面图",
            ref: "AttachmentDefault"
          },
          coachProductId: { type: "number", description: "Coach product TODO" },
          tagList: {
            type: "array",
            items: { type: "string", enum: [] },
            description: "标签"
          },
          paymentMethods: {
            type: "array",
            items: {
              type: "string",
              required: false,
              enum: ["wechat", "prepay_card", "membership_card", "coupon"],
              "x-i18n": {
                wechat: "微信支付",
                prepay_card: "储值账户",
                membership_card: "会员卡",
                coupon: "代金券"
              }
            },
            description: "支付方式"
          },
          hasConfirmCourseThreshold: {
            type: "boolean",
            description: "单独设置开课限制"
          },
          confirmCourseThreshold: {
            type: "number",
            description: "最低开课人数"
          },
          confirmCourseDeadline: {
            type: "number",
            description: "开课确认时间"
          },
          defaultCaloriesTarget: {
            type: "number",
            description: "默认卡路里目标"
          },
          tasType: {
            type: "string",
            required: false,
            enum: ["tas", "hrm", "countdown"],
            "x-i18n": {
              tas: "内容辅助",
              hrm: "心率辅助",
              countdown: "时间辅助"
            },
            description: "辅助系统配置"
          },
          multiScreenVersions: {
            type: "array",
            items: { type: "number" },
            description: "多屏幕适配"
          },
          displayed: { type: "boolean", description: "Displayed TODO" },
          useHrm: { type: "boolean", description: "使用心率" },
          position: { type: "number", description: "排序" },
          platformProductEditable: {
            type: "boolean",
            description: "Platform product editable TODO"
          },
          coachingMode: { type: "boolean", description: "Coaching mode TODO" },
          coachingModeTemplate: {
            type: "boolean",
            description: "Coaching mode template TODO"
          },
          enableWod: { type: "boolean", description: "今日训练" },
          showWodOnCourseDate: { type: "boolean", description: "当日展示" },
          studioIds: {
            type: "array",
            items: { type: "number" },
            description: "Studio ids TODO"
          }
        }
      },
      wod: { type: "string", description: "Wod TODO" }
    }
  },
  MobileCourseCourseBookForm: {
    type: "object",
    properties: {
      accountId: { type: "number", description: "会员卡账户" },
      memberCouponId: { type: "number", description: "用户代金券" },
      memberId: { type: "number", description: "会员" },
      queue: { type: "boolean", description: "排号" },
      token: { type: "string", description: "Token" }
    }
  },
  MobileCourseCourseResults: {
    type: "object",
    properties: {
      bookingCount: { type: "number", description: "预约人数" },
      totalCalories: { type: "number", description: "总卡路里消耗" },
      myResult: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MobileCourseTrainingResultDefault" }
        ],
        description: "我的训练结果"
      },
      results: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            avgHr: { type: "number", description: "平均心率" },
            maxHr: { type: "number", description: "最大心率" },
            calories: { type: "number", description: "卡路里消耗" },
            rank: { type: "number", description: "排名" },
            score: { type: "number", description: "得分" },
            member: {
              description: "会员",
              ref: "MobileMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                phone: { type: "string", description: "手机" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                age: { type: "number", description: "年龄" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                height: { type: "number", description: "Height TODO" },
                weight: { type: "number", description: "体重" },
                brand: {
                  description: "品牌",
                  ref: "MobileBrandDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    identifier: { type: "string", description: "标识符" },
                    saasType: { type: "string", description: "Saas type TODO" },
                    showCourseBookingCount: {
                      type: "boolean",
                      description: "Show course booking count TODO"
                    },
                    giftBookingEnabled: {
                      type: "boolean",
                      description: "Gift booking enabled TODO"
                    },
                    bookingQueueEnabled: {
                      type: "boolean",
                      description: "Booking queue enabled TODO"
                    },
                    groupTrainingCoachBook: {
                      type: "boolean",
                      description: "教练代约团课"
                    },
                    groupTrainingCoachCheckIn: {
                      type: "boolean",
                      description: "教练代签到团课"
                    },
                    cancellationDeadline: {
                      type: "number",
                      description: "取消时限"
                    },
                    vipGroupTrainingDiscount: {
                      type: "number",
                      description: "Vip group training discount TODO"
                    },
                    vipFreeTrainingDiscount: {
                      type: "number",
                      description: "Vip group training discount TODO"
                    },
                    vipCampDiscount: {
                      type: "number",
                      description: "Vip camp discount TODO"
                    },
                    vipCouponPackageDiscount: {
                      type: "number",
                      description: "Vip coupon package discount TODO"
                    },
                    vipMembershipCardDiscount: {
                      type: "number",
                      description: "Vip membership card discount TODO"
                    },
                    vipCancellationDeadline: {
                      type: "number",
                      description: "Vip cancellation deadline TODO"
                    },
                    trainingReport: {
                      type: "boolean",
                      description: "Training report TODO"
                    },
                    prepayCardName: {
                      type: "string",
                      nullable: true,
                      description: "储值卡名称"
                    },
                    availableProductTypes: {
                      type: "array",
                      items: { type: "string" },
                      description: "可用产品类型"
                    },
                    membershipCardAvailableProductTypes: {
                      type: "array",
                      items: { type: "string" },
                      description: "支持会员卡约课的产品类型"
                    },
                    prepayCardPurchaseAvailable: {
                      type: "boolean",
                      description: "启用储值卡充值"
                    },
                    couponPurchaseAvailable: {
                      type: "boolean",
                      description: "启用代金券商城"
                    },
                    mobileCancelEliteTrainingBooking: {
                      type: "boolean",
                      description: "Mobile cancel elite training booking TODO"
                    },
                    mobileEliteTrainingBooking: {
                      type: "boolean",
                      description: "Mobile elite training booking TODO"
                    },
                    mobileEliteTrainingBookingDeadline: {
                      type: "number",
                      description: "Mobile elite training booking deadline TODO"
                    },
                    eliteTrainingScheduleDays: {
                      type: "number",
                      description: "Elite training schedule days TODO"
                    },
                    eliteTrainingCoachCheckIn: {
                      type: "boolean",
                      description: "私教教练代签到"
                    },
                    eliteTrainingCoachCancel: {
                      type: "boolean",
                      description: "私教教练端取消预约"
                    },
                    eliteTrainingCoachLeave: {
                      type: "boolean",
                      description: "私教教练端隐藏时段"
                    },
                    expiryAlertEntryThreshold: {
                      type: "number",
                      description: "Expiry alert entry threshold TODO"
                    },
                    expiryAlertEliteEntryThreshold: {
                      type: "number",
                      description: "Expiry alert elite entry threshold TODO"
                    },
                    expiryAlertPrepayAmountThreshold: {
                      type: "number",
                      description: "Expiry alert prepay amount threshold TODO"
                    },
                    expiryAlertValidityPeriodThreshold: {
                      type: "number",
                      description: "Expiry alert validity period threshold TODO"
                    },
                    churnThreshold: {
                      type: "number",
                      description: "Churn threshold TODO"
                    },
                    newMemberThreshold: {
                      type: "number",
                      description: "New member threshold TODO"
                    },
                    inactiveMemberThreshold: {
                      type: "number",
                      description: "Inactive member threshold TODO"
                    },
                    inactiveEliteMemberThreshold: {
                      type: "number",
                      description: "Inactive elite member threshold TODO"
                    },
                    logo: {
                      description: "Logo",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    qrCode: {
                      description: "Qr code TODO",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    theme: {
                      description: "小程序主题",
                      ref: "BrandThemeDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        primaryColor: { type: "string", description: "主题色" },
                        secondaryColor: {
                          type: "string",
                          description: "辅助色"
                        }
                      }
                    },
                    wxMessageTemplates: {
                      description: "Wx message templates TODO",
                      ref: "BrandWxConfigMessageTemplates",
                      type: "object",
                      properties: {
                        courseReminder: {
                          type: "string",
                          description: "Course reminder TODO"
                        },
                        bookingCancel: {
                          type: "string",
                          description: "Booking cancel TODO"
                        },
                        bookingQueueStatus: {
                          type: "string",
                          description: "Booking queue status TODO"
                        },
                        trainingResult: {
                          type: "string",
                          description: "Training result TODO"
                        },
                        footprintUploaded: {
                          type: "string",
                          description: "Footprint uploaded TODO"
                        }
                      }
                    },
                    schedulePublishWday: {
                      type: "number",
                      description: "Schedule publish wday TODO"
                    },
                    schedulePublishTime: {
                      type: "string",
                      description: "Schedule publish time TODO"
                    },
                    referalAd: {
                      description: "Referal ad TODO",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    communitySetting: {
                      description: "Community setting TODO",
                      ref: "BrandCommunitySettingDefault",
                      type: "object",
                      properties: {
                        enabled: { type: "number", description: "开启社群" },
                        progressEnabled: {
                          type: "boolean",
                          description: "展示社群统计"
                        },
                        progressType: {
                          type: "string",
                          required: false,
                          enum: ["", "training", "calories"],
                          "x-i18n": {
                            "": "不展示",
                            training: "打卡",
                            calories: "燃脂"
                          },
                          description: "社群统计数据类型"
                        },
                        progressAmplificationFactor: {
                          type: "number",
                          description: "社群统计数据放大系数"
                        },
                        leaderboardEnabled: {
                          type: "boolean",
                          description: "展示排行榜"
                        },
                        leaderboardTypes: {
                          type: "array",
                          items: { type: "string" },
                          description: "展示排行榜类型"
                        },
                        tasLeaderboards: {
                          type: "array",
                          items: { type: "string" },
                          description: "智慧屏榜单展示"
                        },
                        operatingLists: {
                          type: "array",
                          items: { type: "string" },
                          description: "运营列表"
                        },
                        footprintsEnabled: {
                          type: "boolean",
                          description: "展示记忆相册"
                        },
                        footprintMemberCountThreshold: {
                          type: "number",
                          description: "记忆相册展示人数门槛"
                        }
                      }
                    },
                    userTerms: {
                      type: "string",
                      description: "User terms TODO"
                    },
                    prepayRules: {
                      type: "string",
                      description: "Prepay rules TODO"
                    }
                  }
                },
                wxInfoSynced: {
                  type: "boolean",
                  description: "Wx info synced TODO"
                },
                personalInfoFilled: {
                  type: "boolean",
                  description: "个人信息已填"
                },
                phoneFilled: { type: "boolean", description: "手机号已绑定" },
                profileFilled: { type: "boolean", description: "新手履历已填" },
                promoCode: { type: "string", description: "邀请码" },
                newbie: { type: "boolean", description: "Newbie TODO" },
                defaultStudioId: {
                  type: "number",
                  nullable: true,
                  description: "Default studio TODO"
                },
                active: { type: "boolean", description: "激活" },
                vip: { type: "boolean", description: "vip" },
                coach: {
                  description: "Coach TODO",
                  ref: "MobileCoachList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "名称" },
                    displayName: { type: "string", description: "展示名称" },
                    level: {
                      description: "等级",
                      ref: "CoachLevelDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        category: {
                          description: "类型",
                          ref: "CoachCategoryDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" }
                          }
                        },
                        categoryId: { type: "number", description: "类型" }
                      }
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    }
                  }
                },
                isCoach: { type: "boolean", description: "Is 项目" }
              }
            },
            timeInZone: {
              type: "array",
              items: { type: "number" },
              description: "Time in zone TODO"
            },
            scoredDuration: {
              type: "number",
              description: "Scored duration TODO"
            },
            epoc: { type: "boolean", description: "epoc" }
          }
        },
        description: "训练结果",
        ref: "MobileCourseTrainingResultDefault"
      }
    }
  },
  CourseReviewDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      content: { type: "string", description: "内容" },
      pinned: { type: "boolean", description: "置顶" },
      course: {
        description: "课程",
        ref: "MobileCourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          courseName: { type: "string", description: "课程名称" },
          enCourseName: { type: "string", description: "英文课程名称" },
          zone: { description: "Zone TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" },
              active: { type: "boolean", description: "激活" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              display: { type: "boolean", description: "展示" },
              address: { type: "string", description: "地址" },
              contactPhone: { type: "string", description: "联系电话" },
              longitude: { type: "string", description: "经度" },
              latitude: { type: "string", description: "维度" },
              gatePassword: { type: "string", description: "门禁密码" }
            }
          },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          endTime: {
            description: "结束时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" },
              user: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/UserDefault" }
                ],
                description: "用户"
              },
              userId: { type: "number", description: "用户" },
              profilePhoto: {
                description: "形象照",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              wxQrCode: {
                description: "微信二维码",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              level: {
                description: "等级",
                ref: "CoachLevelDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  category: {
                    description: "类型",
                    ref: "CoachCategoryDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" }
                    }
                  },
                  categoryId: { type: "number", description: "类型" }
                }
              },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              joinedAt: {
                description: "入职时间",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              primaryStudio: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/StudioStudioList" }
                ],
                description: "绑定门店"
              },
              scopeOfAccess: {
                type: "string",
                required: true,
                enum: ["mine", "all"],
                "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                description: "查看排课范围"
              },
              introduction: { type: "string", description: "介绍" },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "教练风格"
              },
              certificationList: {
                type: "array",
                items: { type: "string" },
                description: "技能认证"
              },
              styleList: {
                type: "array",
                items: { type: "string" },
                description: "执教风格"
              },
              resumeDisplay: { type: "boolean", description: "执教数据展示" },
              eliteTrainingCapacity: {
                type: "number",
                description: "私教人数上限"
              },
              primaryStudioId: { type: "number", description: "绑定门店" },
              applyToAllProduct: {
                type: "boolean",
                description: "授权所有训练产品"
              },
              levelId: { type: "number", description: "等级" },
              createdAt: {
                description: "加入时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              }
            }
          },
          capacityState: { type: "string", description: "容量状态" },
          capacity: { type: "number", description: "容量" },
          bookingCount: { type: "number", description: "预约人数" },
          tags: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          productTags: {
            type: "array",
            items: { type: "string" },
            description: "产品标签"
          },
          productStages: {
            type: "array",
            items: { type: "string" },
            description: "Product stages TODO"
          },
          productType: { type: "string", description: "Product type TODO" },
          paymentMethods: {
            type: "array",
            items: { type: "string" },
            description: "Payment methods TODO"
          },
          bookState: { type: "string", description: "预约状态" },
          bookingId: { type: "number", description: "Booking TODO" },
          booking: {
            description: "Booking TODO",
            ref: "MobileCourseBookingGift",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              workflowState: { type: "string", description: "状态" },
              isOwner: { type: "boolean", description: "所有人" },
              giftState: { type: "string", description: "赠课状态" },
              token: { type: "string", description: "token" }
            }
          },
          campId: { type: "number", nullable: true, description: "训练营" },
          price: { type: "string", description: "课程价格" },
          prepayPrice: { type: "string", description: "Prepay price TODO" },
          positionSelectable: { type: "boolean", description: "可以选位" },
          multipleCoach: { type: "boolean", description: "Multiple 项目" },
          eliteTrainingMember: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/MobileMemberDefault" }
            ],
            description: "Elite training member TODO"
          },
          banner: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头图"
          },
          homework: { type: "string", description: "Homework TODO" },
          confirmCourseThreshold: {
            type: "number",
            description: "Confirm course threshold TODO"
          },
          confirmCourseDeadline: {
            type: "number",
            description: "Confirm course deadline TODO"
          },
          hasConfirmCourseThreshold: {
            type: "boolean",
            description: "Has confirm course threshold TODO"
          },
          useHrm: { type: "boolean", description: "使用心率" }
        }
      },
      member: {
        description: "会员",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      images: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "图片",
        ref: "AttachmentDefault"
      },
      displayForCoach: { type: "boolean", description: "教练详情展示" },
      displayForProduct: { type: "boolean", description: "产品详情展示" },
      displayForCourse: { type: "boolean", description: "课程详情展示" }
    }
  },
  CourseReviewForm: {
    type: "object",
    properties: {
      content: { type: "string", required: true, description: "内容" },
      images: {
        type: "array",
        items: {
          type: "object",
          properties: {
            signedId: { type: "string", description: "Signed ID" },
            remoteUrl: {
              type: "string",
              nullable: true,
              description: "文件远程 URL"
            },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "图片",
        ref: "AttachmentForm"
      }
    }
  },
  CourseReviewUpdateForm: {
    type: "object",
    properties: {
      pinned: { type: "boolean", description: "置顶" },
      displayForCoach: { type: "boolean", description: "教练详情展示" },
      displayForProduct: { type: "boolean", description: "产品详情展示" },
      displayForCourse: { type: "boolean", description: "课程详情展示" }
    }
  },
  CourseReviewPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            content: { type: "string", description: "内容" },
            pinned: { type: "boolean", description: "置顶" },
            course: {
              description: "课程",
              ref: "MobileCourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                courseName: { type: "string", description: "课程名称" },
                enCourseName: { type: "string", description: "英文课程名称" },
                zone: { description: "Zone TODO" },
                studio: {
                  description: "门店",
                  ref: "StudioStudioEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" },
                    active: { type: "boolean", description: "激活" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    display: { type: "boolean", description: "展示" },
                    address: { type: "string", description: "地址" },
                    contactPhone: { type: "string", description: "联系电话" },
                    longitude: { type: "string", description: "经度" },
                    latitude: { type: "string", description: "维度" },
                    gatePassword: { type: "string", description: "门禁密码" }
                  }
                },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                endTime: {
                  description: "结束时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    },
                    user: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/UserDefault" }
                      ],
                      description: "用户"
                    },
                    userId: { type: "number", description: "用户" },
                    profilePhoto: {
                      description: "形象照",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    wxQrCode: {
                      description: "微信二维码",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    level: {
                      description: "等级",
                      ref: "CoachLevelDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        category: {
                          description: "类型",
                          ref: "CoachCategoryDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" }
                          }
                        },
                        categoryId: { type: "number", description: "类型" }
                      }
                    },
                    dateOfBirth: {
                      description: "生日",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    joinedAt: {
                      description: "入职时间",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    primaryStudio: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/StudioStudioList" }
                      ],
                      description: "绑定门店"
                    },
                    scopeOfAccess: {
                      type: "string",
                      required: true,
                      enum: ["mine", "all"],
                      "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                      description: "查看排课范围"
                    },
                    introduction: { type: "string", description: "介绍" },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "教练风格"
                    },
                    certificationList: {
                      type: "array",
                      items: { type: "string" },
                      description: "技能认证"
                    },
                    styleList: {
                      type: "array",
                      items: { type: "string" },
                      description: "执教风格"
                    },
                    resumeDisplay: {
                      type: "boolean",
                      description: "执教数据展示"
                    },
                    eliteTrainingCapacity: {
                      type: "number",
                      description: "私教人数上限"
                    },
                    primaryStudioId: {
                      type: "number",
                      description: "绑定门店"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "授权所有训练产品"
                    },
                    levelId: { type: "number", description: "等级" },
                    createdAt: {
                      description: "加入时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    }
                  }
                },
                capacityState: { type: "string", description: "容量状态" },
                capacity: { type: "number", description: "容量" },
                bookingCount: { type: "number", description: "预约人数" },
                tags: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                productTags: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品标签"
                },
                productStages: {
                  type: "array",
                  items: { type: "string" },
                  description: "Product stages TODO"
                },
                productType: {
                  type: "string",
                  description: "Product type TODO"
                },
                paymentMethods: {
                  type: "array",
                  items: { type: "string" },
                  description: "Payment methods TODO"
                },
                bookState: { type: "string", description: "预约状态" },
                bookingId: { type: "number", description: "Booking TODO" },
                booking: {
                  description: "Booking TODO",
                  ref: "MobileCourseBookingGift",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    workflowState: { type: "string", description: "状态" },
                    isOwner: { type: "boolean", description: "所有人" },
                    giftState: { type: "string", description: "赠课状态" },
                    token: { type: "string", description: "token" }
                  }
                },
                campId: {
                  type: "number",
                  nullable: true,
                  description: "训练营"
                },
                price: { type: "string", description: "课程价格" },
                prepayPrice: {
                  type: "string",
                  description: "Prepay price TODO"
                },
                positionSelectable: {
                  type: "boolean",
                  description: "可以选位"
                },
                multipleCoach: {
                  type: "boolean",
                  description: "Multiple 项目"
                },
                eliteTrainingMember: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/MobileMemberDefault" }
                  ],
                  description: "Elite training member TODO"
                },
                banner: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头图"
                },
                homework: { type: "string", description: "Homework TODO" },
                confirmCourseThreshold: {
                  type: "number",
                  description: "Confirm course threshold TODO"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "Confirm course deadline TODO"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "Has confirm course threshold TODO"
                },
                useHrm: { type: "boolean", description: "使用心率" }
              }
            },
            member: {
              description: "会员",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            images: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "图片",
              ref: "AttachmentDefault"
            },
            displayForCoach: { type: "boolean", description: "教练详情展示" },
            displayForProduct: { type: "boolean", description: "产品详情展示" },
            displayForCourse: { type: "boolean", description: "课程详情展示" }
          }
        },
        ref: "CourseReviewDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  GrantedUserDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      phone: { type: "string", description: "手机号" },
      notes: { type: "string", nullable: true, description: "备注" },
      active: { type: "boolean", description: "激活" }
    }
  },
  GrantedUserForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      phone: { type: "string", description: "手机号" },
      notes: { type: "string", description: "备注" },
      active: { type: "boolean", description: "激活" }
    }
  },
  GrantedUserPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            phone: { type: "string", description: "手机号" },
            notes: { type: "string", nullable: true, description: "备注" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "GrantedUserDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CourseBookingPagedArrayGroupTraining: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "会员",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            workflowState: {
              type: "string",
              required: false,
              enum: [
                "new",
                "reserved",
                "queued",
                "checked_in",
                "absent",
                "cancelled"
              ],
              "x-i18n": {
                new: "未签到",
                reserved: "未支付",
                queued: "已排号",
                checked_in: "已签到",
                absent: "缺席",
                cancelled: "已取消"
              },
              description: "状态"
            },
            consumeType: { type: "string", description: "预约方式" },
            productTrainingCount: {
              type: "number",
              description: "该产品训练次数"
            },
            position: { type: "number", description: "位置" },
            number: { type: "string", description: "心率表编号" },
            coachComments: { type: "string", description: "教练点评" },
            cancelledAt: {
              description: "Cancelled at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            photos: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "训练照片",
              ref: "AttachmentDefault"
            },
            owner: {
              description: "所有人",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            course: {
              description: "课程",
              ref: "CourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                name: { type: "string", description: "名称" },
                enName: { type: "string", description: "课程英文名称" },
                homework: { type: "string", description: "课后作业" },
                workflowState: {
                  type: "string",
                  description: "Workflow state TODO"
                },
                product: {
                  description: "训练产品",
                  ref: "CourseProductList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    brandId: { type: "number", description: "品牌" },
                    name: { type: "string", description: "课程名称" },
                    enName: { type: "string", description: "英文名称" },
                    usageCn: { type: "string", description: "Usage cn TODO" },
                    forKid: { type: "boolean", description: "For kid TODO" },
                    stageList: {
                      type: "array",
                      items: { type: "string" },
                      description: "Stage list TODO"
                    },
                    provider: { type: "string", description: "产品品牌" },
                    expiryDate: {
                      description: "授权截止",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    category: {
                      type: "string",
                      required: false,
                      enum: ["brand", "platform"],
                      "x-i18n": { brand: "自研产品", platform: "授权产品" },
                      description: "类型"
                    },
                    productType: {
                      type: "string",
                      required: false,
                      enum: [
                        "group_training",
                        "elite_training",
                        "camp",
                        "free_training"
                      ],
                      "x-i18n": {
                        group_training: "团课",
                        elite_training: "私教",
                        camp: "训练营",
                        free_training: "自由训练"
                      },
                      description: "类型"
                    },
                    scheduleRule: {
                      type: "string",
                      description: "Schedule rule TODO"
                    },
                    entryPrice: {
                      type: "string",
                      description: "Entry price TODO"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                assistantCoaches: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      phone: { type: "string", description: "手机号" },
                      avatar: {
                        description: "头像",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      name: { type: "string", description: "姓名" },
                      displayName: { type: "string", description: "昵称" },
                      note: { type: "string", description: "备注" },
                      active: { type: "boolean", description: "激活" },
                      certified: {
                        type: "boolean",
                        description: "Certified TODO"
                      }
                    }
                  },
                  description: "助教",
                  ref: "CoachCoachEssential"
                },
                zone: {
                  description: "场地",
                  ref: "StudioZoneList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "场地名称" },
                    position: { type: "number", description: "排序" },
                    useHrm: { type: "boolean", description: "使用心率" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    tasExpiried: {
                      type: "boolean",
                      description: "Tas expiried TODO"
                    },
                    studio: {
                      description: "门店",
                      ref: "StudioStudioList",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        fullName: { type: "string", description: "门店全称" },
                        shortName: { type: "string", description: "门店名称" }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        description: "数据",
        ref: "CourseBookingGroupTraining"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  GroupTrainingOutlineScheduleDefault: {
    type: "object",
    properties: {
      product: {
        description: "Product TODO",
        ref: "CourseProductList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          brandId: { type: "number", description: "品牌" },
          name: { type: "string", description: "课程名称" },
          enName: { type: "string", description: "英文名称" },
          usageCn: { type: "string", description: "Usage cn TODO" },
          forKid: { type: "boolean", description: "For kid TODO" },
          stageList: {
            type: "array",
            items: { type: "string" },
            description: "Stage list TODO"
          },
          provider: { type: "string", description: "产品品牌" },
          expiryDate: {
            description: "授权截止",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          category: {
            type: "string",
            required: false,
            enum: ["brand", "platform"],
            "x-i18n": { brand: "自研产品", platform: "授权产品" },
            description: "类型"
          },
          productType: {
            type: "string",
            required: false,
            enum: ["group_training", "elite_training", "camp", "free_training"],
            "x-i18n": {
              group_training: "团课",
              elite_training: "私教",
              camp: "训练营",
              free_training: "自由训练"
            },
            description: "类型"
          },
          scheduleRule: { type: "string", description: "Schedule rule TODO" },
          entryPrice: { type: "string", description: "Entry price TODO" },
          active: { type: "boolean", description: "激活" }
        }
      },
      outlines: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "编号" },
            duration: { type: "number", description: "Duration TODO" },
            instruction: { type: "string", description: "说明" },
            packageSerial: {
              type: "string",
              description: "Package serial TODO"
            },
            packageName: { type: "string", description: "Package name TODO" },
            note: { type: "object", description: "名称" }
          }
        },
        description: "Outlines TODO",
        ref: "TasWorkoutOutlineList"
      },
      followCoach: { type: "boolean", description: "Follow 项目" }
    }
  },
  KtasGamePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            desc: { type: "object", description: "介绍" },
            preferList: {
              type: "array",
              items: { type: "string" },
              description: "适用"
            },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "KtasGameDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  KtasOutlinePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "编号" },
            brandId: { type: "number", description: "Brand TODO" },
            workflowState: {
              type: "string",
              required: false,
              enum: ["new", "certificated", "abandoned"],
              "x-i18n": {
                new: "待认证",
                certificated: "已认证",
                abandoned: "已废弃"
              },
              description: "状态"
            },
            workout: {
              description: "课程品类",
              ref: "KtasWorkoutDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                duration: { type: "number", description: "时长" },
                abbr: { type: "string", description: "缩写" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "年龄阶段"
                },
                brandId: { type: "number", description: "Brand TODO" },
                intro: { type: "object", description: "介绍" },
                active: { type: "boolean", description: "激活" }
              }
            },
            intro: { type: "string", description: "课程介绍" },
            physicalQualityList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: ["综合素质"],
                "x-i18n": { 综合素质: "综合素质" }
              },
              description: "身体素质"
            },
            actionList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: ["抓握", "蹦跳", "爬行", "跑步"],
                "x-i18n": {
                  抓握: "抓握",
                  蹦跳: "蹦跳",
                  爬行: "爬行",
                  跑步: "跑步"
                }
              },
              description: "动作模式"
            },
            skillList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: ["支撑", "翻越", "蹦跳", "跑步", "抓握"],
                "x-i18n": {
                  支撑: "支撑",
                  翻越: "翻越",
                  蹦跳: "蹦跳",
                  跑步: "跑步",
                  抓握: "抓握"
                }
              },
              description: "动作技能"
            },
            characterList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: [
                  "专注",
                  "友善",
                  "感恩",
                  "守时",
                  "责任",
                  "主动",
                  "宽容",
                  "思考",
                  "勇敢",
                  "尊重",
                  "勤劳",
                  "独立",
                  "规则",
                  "诚实守信"
                ],
                "x-i18n": {
                  专注: "专注",
                  友善: "友善",
                  感恩: "感恩",
                  守时: "守时",
                  责任: "责任",
                  主动: "主动",
                  宽容: "宽容",
                  思考: "思考",
                  勇敢: "勇敢",
                  尊重: "尊重",
                  勤劳: "勤劳",
                  独立: "独立",
                  规则: "规则",
                  诚实守信: "诚实守信"
                }
              },
              description: "品格训练"
            }
          }
        },
        ref: "KtasOutlineDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  KtasSectionPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            category: {
              type: "string",
              required: false,
              enum: ["normal", "game"],
              "x-i18n": { normal: "普通", game: "游戏" },
              description: "类型"
            },
            trainingMethod: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/KtasTrainingMethodDefault" }
              ],
              description: "训练方式"
            },
            video: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "视频"
            },
            games: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  desc: { type: "object", description: "介绍" },
                  preferList: {
                    type: "array",
                    items: { type: "string" },
                    description: "适用"
                  },
                  active: { type: "boolean", description: "激活" }
                }
              },
              description: "Games TODO",
              ref: "KtasGameDefault"
            },
            gameIds: {
              type: "array",
              items: { type: "number" },
              description: "推荐游戏"
            },
            duration: { type: "number", description: "时长" },
            desc: { type: "object", description: "介绍" },
            coachingTips: { type: "object", description: "教案" },
            exerciseList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: ["动作1", "动作2"],
                "x-i18n": { 动作1: "动作1", 动作2: "动作2" }
              },
              description: "推荐动作"
            },
            coachingTipList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: ["喝水", "拍照"],
                "x-i18n": { 喝水: "喝水", 拍照: "拍照" }
              },
              description: "教练提示"
            },
            position: { type: "number", description: "排序" }
          }
        },
        ref: "KtasSectionDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  KtasTrainingMethodPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            preferList: {
              type: "array",
              items: { type: "string" },
              description: "适用"
            },
            desc: { type: "object", description: "介绍" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "KtasTrainingMethodDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  KtasWorkoutPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            duration: { type: "number", description: "时长" },
            abbr: { type: "string", description: "缩写" },
            stageList: {
              type: "array",
              items: { type: "string" },
              description: "年龄阶段"
            },
            brandId: { type: "number", description: "Brand TODO" },
            intro: { type: "object", description: "介绍" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "KtasWorkoutDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MembershipAccountMemberDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      notes: { type: "string", description: "备注" },
      usageAmount: { type: "number", description: "使用总数" },
      currentMonthUsageAmount: { type: "number", description: "当月使用数量" }
    }
  },
  MembershipAccountMemberMobileCreateForm: {
    type: "object",
    properties: {
      memberPhone: { type: "string", description: "手机号" },
      notes: { type: "string", description: "备注" }
    }
  },
  MembershipAccountMemberCreateForm: {
    type: "object",
    properties: {
      memberId: { type: "number", description: "会员" },
      notes: { type: "string", description: "备注" }
    }
  },
  MembershipAccountMemberUpdateForm: {
    type: "object",
    properties: { notes: { type: "string", description: "备注" } }
  },
  MembershipAccountMemberAddChildForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "姓名" },
      dateOfBirth: {
        description: "生日",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      weight: { type: "number", description: "体重" },
      gender: {
        type: "string",
        required: false,
        enum: ["male", "female"],
        "x-i18n": { male: "男", female: "女" },
        description: "性别"
      },
      notes: { type: "string", description: "备注" }
    }
  },
  MembershipAccountMemberPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "会员",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            notes: { type: "string", description: "备注" },
            usageAmount: { type: "number", description: "使用总数" },
            currentMonthUsageAmount: {
              type: "number",
              description: "当月使用数量"
            }
          }
        },
        ref: "MembershipAccountMemberDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  ActorDefault: {
    type: "object",
    properties: {
      userId: { type: "number", description: "用户" },
      roleType: { type: "string", description: "角色" },
      name: { type: "string", description: "名称" }
    }
  },
  MemberCommunicationDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      content: { type: "string", description: "内容" },
      actor: {
        description: "操作人",
        ref: "ActorDefault",
        type: "object",
        properties: {
          userId: { type: "number", description: "用户" },
          roleType: { type: "string", description: "角色" },
          name: { type: "string", description: "名称" }
        }
      },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      read: { type: "boolean", description: "已读" }
    }
  },
  MemberCommunicationForm: {
    type: "object",
    properties: { content: { type: "string", description: "内容" } }
  },
  MemberCommunicationPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            content: { type: "string", description: "内容" },
            actor: {
              description: "操作人",
              ref: "ActorDefault",
              type: "object",
              properties: {
                userId: { type: "number", description: "用户" },
                roleType: { type: "string", description: "角色" },
                name: { type: "string", description: "名称" }
              }
            },
            createdAt: {
              description: "Created at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            read: { type: "boolean", description: "已读" }
          }
        },
        ref: "MemberCommunicationDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  ZpkExamSubjectDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "项目名称" },
      minAge: { type: "number", description: "最小测试年龄" },
      maxAge: { type: "number", description: "最大测试年龄" },
      unit: { type: "string", description: "单位" },
      position: { type: "number", description: "位置" },
      effect: { type: "string", description: "影响" },
      trainingMethod: { type: "string", description: "训练方式" }
    }
  },
  ZpkExamResultDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      subjectId: { type: "number", description: "项目" },
      subject: {
        description: "项目",
        ref: "ZpkExamSubjectDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "项目名称" },
          minAge: { type: "number", description: "最小测试年龄" },
          maxAge: { type: "number", description: "最大测试年龄" },
          unit: { type: "string", description: "单位" },
          position: { type: "number", description: "位置" },
          effect: { type: "string", description: "影响" },
          trainingMethod: { type: "string", description: "训练方式" }
        }
      },
      result: { type: "number", description: "测试结果" },
      rank: { type: "string", description: "评级" },
      analysis: { type: "string", description: "解析" }
    }
  },
  ZpkExamResultForm: {
    type: "object",
    properties: {
      subjectName: { type: "string", description: "项目名称" },
      result: { type: "number", description: "测试结果" }
    }
  },
  ZpkExamRecordDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      memberId: { type: "number", description: "会员" },
      member: {
        description: "会员",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      age: { type: "number", description: "年龄" },
      date: {
        description: "测试日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      displayed: { type: "boolean", description: "展示" },
      coachId: { type: "number", description: "测试人" },
      coach: {
        description: "测试人",
        ref: "CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" },
          user: {
            anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
            description: "用户"
          },
          userId: { type: "number", description: "用户" },
          profilePhoto: {
            description: "形象照",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          wxQrCode: {
            description: "微信二维码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          level: {
            description: "等级",
            ref: "CoachLevelDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              category: {
                description: "类型",
                ref: "CoachCategoryDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" }
                }
              },
              categoryId: { type: "number", description: "类型" }
            }
          },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          joinedAt: {
            description: "入职时间",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          primaryStudio: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/StudioStudioList" }
            ],
            description: "绑定门店"
          },
          scopeOfAccess: {
            type: "string",
            required: true,
            enum: ["mine", "all"],
            "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
            description: "查看排课范围"
          },
          introduction: { type: "string", description: "介绍" },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "教练风格"
          },
          certificationList: {
            type: "array",
            items: { type: "string" },
            description: "技能认证"
          },
          styleList: {
            type: "array",
            items: { type: "string" },
            description: "执教风格"
          },
          resumeDisplay: { type: "boolean", description: "执教数据展示" },
          eliteTrainingCapacity: {
            type: "number",
            description: "私教人数上限"
          },
          primaryStudioId: { type: "number", description: "绑定门店" },
          applyToAllProduct: {
            type: "boolean",
            description: "授权所有训练产品"
          },
          levelId: { type: "number", description: "等级" },
          createdAt: {
            description: "加入时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      summary: { type: "string", description: "总评" },
      height: { type: "number", description: "形态身高" },
      weight: { type: "number", description: "体重" },
      bmi: { type: "number", description: "BMI" },
      results: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            subjectId: { type: "number", description: "项目" },
            subject: {
              description: "项目",
              ref: "ZpkExamSubjectDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "项目名称" },
                minAge: { type: "number", description: "最小测试年龄" },
                maxAge: { type: "number", description: "最大测试年龄" },
                unit: { type: "string", description: "单位" },
                position: { type: "number", description: "位置" },
                effect: { type: "string", description: "影响" },
                trainingMethod: { type: "string", description: "训练方式" }
              }
            },
            result: { type: "number", description: "测试结果" },
            rank: { type: "string", description: "评级" },
            analysis: { type: "string", description: "解析" }
          }
        },
        description: "测试成绩",
        ref: "ZpkExamResultDefault"
      }
    }
  },
  ZpkExamRecordForm: {
    type: "object",
    properties: {
      memberId: { type: "number", description: "会员" },
      age: { type: "number", description: "年龄" },
      date: {
        description: "测试日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      coachId: { type: "number", description: "测试人" },
      summary: { type: "string", description: "总评" },
      displayed: { type: "boolean", description: "展示" },
      results: {
        type: "array",
        items: {
          type: "object",
          properties: {
            subjectName: { type: "string", description: "项目名称" },
            result: { type: "number", description: "测试结果" }
          }
        },
        description: "测试成绩",
        ref: "ZpkExamResultForm"
      }
    }
  },
  ZpkExamRecordPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            memberId: { type: "number", description: "会员" },
            member: {
              description: "会员",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            age: { type: "number", description: "年龄" },
            date: {
              description: "测试日期",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            displayed: { type: "boolean", description: "展示" },
            coachId: { type: "number", description: "测试人" },
            coach: {
              description: "测试人",
              ref: "CoachCoachDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" },
                user: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/UserDefault" }
                  ],
                  description: "用户"
                },
                userId: { type: "number", description: "用户" },
                profilePhoto: {
                  description: "形象照",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                wxQrCode: {
                  description: "微信二维码",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                level: {
                  description: "等级",
                  ref: "CoachLevelDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    category: {
                      description: "类型",
                      ref: "CoachCategoryDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" }
                      }
                    },
                    categoryId: { type: "number", description: "类型" }
                  }
                },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                joinedAt: {
                  description: "入职时间",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                primaryStudio: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/StudioStudioList" }
                  ],
                  description: "绑定门店"
                },
                scopeOfAccess: {
                  type: "string",
                  required: true,
                  enum: ["mine", "all"],
                  "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                  description: "查看排课范围"
                },
                introduction: { type: "string", description: "介绍" },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "教练风格"
                },
                certificationList: {
                  type: "array",
                  items: { type: "string" },
                  description: "技能认证"
                },
                styleList: {
                  type: "array",
                  items: { type: "string" },
                  description: "执教风格"
                },
                resumeDisplay: { type: "boolean", description: "执教数据展示" },
                eliteTrainingCapacity: {
                  type: "number",
                  description: "私教人数上限"
                },
                primaryStudioId: { type: "number", description: "绑定门店" },
                applyToAllProduct: {
                  type: "boolean",
                  description: "授权所有训练产品"
                },
                levelId: { type: "number", description: "等级" },
                createdAt: {
                  description: "加入时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                }
              }
            },
            summary: { type: "string", description: "总评" },
            height: { type: "number", description: "形态身高" },
            weight: { type: "number", description: "体重" },
            bmi: { type: "number", description: "BMI" },
            results: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  subjectId: { type: "number", description: "项目" },
                  subject: {
                    description: "项目",
                    ref: "ZpkExamSubjectDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "项目名称" },
                      minAge: { type: "number", description: "最小测试年龄" },
                      maxAge: { type: "number", description: "最大测试年龄" },
                      unit: { type: "string", description: "单位" },
                      position: { type: "number", description: "位置" },
                      effect: { type: "string", description: "影响" },
                      trainingMethod: {
                        type: "string",
                        description: "训练方式"
                      }
                    }
                  },
                  result: { type: "number", description: "测试结果" },
                  rank: { type: "string", description: "评级" },
                  analysis: { type: "string", description: "解析" }
                }
              },
              description: "测试成绩",
              ref: "ZpkExamResultDefault"
            }
          }
        },
        ref: "ZpkExamRecordDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MemberInvitationDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      invitee: {
        description: "受邀人",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      inviter: {
        description: "邀请人",
        ref: "MemberMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "年龄" },
          weight: { type: "number", description: "体重" },
          height: { type: "number", description: "Height TODO" },
          maxHr: { type: "number", nullable: true, description: "最大心率" },
          parent: {
            description: "家长",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          profilePhotos: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "形象照",
            ref: "AttachmentDefault"
          },
          joinedHof: { type: "boolean", description: "加入名人堂" },
          joinedHofAt: {
            description: "加入名人堂时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          wechatLinked: { type: "boolean", description: "Wechat linked TODO" },
          hofNotes: { type: "string", description: "名人堂备注" },
          vip: { type: "boolean", description: "vip" },
          active: { type: "boolean", description: "激活" }
        }
      },
      confirmed: { type: "boolean", description: "成功" },
      confirmedAt: {
        description: "邀请成功时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      purchaseAmount: { type: "string", description: "累积消费" },
      bookingCount: { type: "number", description: "Booking count TODO" }
    }
  },
  MemberInvitationPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            invitee: {
              description: "受邀人",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            inviter: {
              description: "邀请人",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            confirmed: { type: "boolean", description: "成功" },
            confirmedAt: {
              description: "邀请成功时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            createdAt: {
              description: "Created at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            purchaseAmount: { type: "string", description: "累积消费" },
            bookingCount: { type: "number", description: "Booking count TODO" }
          }
        },
        ref: "MemberInvitationDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MemberMemberPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "昵称" },
            userId: { type: "number", description: "用户ID" },
            realName: { type: "string", nullable: true, description: "姓名" },
            phone: { type: "string", nullable: true, description: "手机" },
            gender: {
              type: "string",
              required: false,
              enum: ["male", "female"],
              "x-i18n": { male: "男", female: "女" },
              description: "性别"
            },
            avatar: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "头像"
            },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            memberType: { type: "string", description: "会员类型" },
            dateOfBirth: {
              description: "生日",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            age: { type: "number", description: "年龄" },
            weight: { type: "number", description: "体重" },
            height: { type: "number", description: "Height TODO" },
            maxHr: { type: "number", nullable: true, description: "最大心率" },
            parent: {
              description: "家长",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            profilePhotos: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "形象照",
              ref: "AttachmentDefault"
            },
            joinedHof: { type: "boolean", description: "加入名人堂" },
            joinedHofAt: {
              description: "加入名人堂时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            wechatLinked: {
              type: "boolean",
              description: "Wechat linked TODO"
            },
            hofNotes: { type: "string", description: "名人堂备注" },
            vip: { type: "boolean", description: "vip" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "MemberMemberDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CouponMemberCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      coupon: {
        description: "代金券",
        ref: "CouponCouponDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          value: { type: "string", description: "面值" },
          fullDiscount: { type: "boolean", description: "全额代金券" },
          availableTimeRange: {
            type: "array",
            items: { type: "string" },
            description: "可用时段"
          },
          validityPeriod: { type: "number", description: "有效期" },
          usageThreshold: { type: "string", description: "使用门槛" },
          applyToAllStudio: { type: "boolean", description: "所有门店可用" },
          studios: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" }
              }
            },
            description: "应用门店",
            ref: "StudioStudioList"
          },
          applyToAllProduct: {
            type: "boolean",
            description: "所有训练产品可用"
          },
          products: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" }
              }
            },
            description: "应用训练产品",
            ref: "CourseProductList"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      expiryDate: {
        description: "过期时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      createdAt: {
        description: "Created at TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      validityPeriod: { type: "number", description: "有效期" },
      workflowState: { type: "string", description: "状态" },
      notes: { type: "string", description: "备注" }
    }
  },
  CouponMemberCouponForm: {
    type: "object",
    properties: {
      couponId: { type: "number", description: "代金券" },
      couponQuantity: { type: "number", description: "代金券数量" },
      notes: { type: "string", description: "备注" }
    }
  },
  CouponMemberCouponRestoreForm: {
    type: "object",
    properties: { validityPeriod: { type: "number", description: "有效期" } }
  },
  CouponMemberCouponPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            coupon: {
              description: "代金券",
              ref: "CouponCouponDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                value: { type: "string", description: "面值" },
                fullDiscount: { type: "boolean", description: "全额代金券" },
                availableTimeRange: {
                  type: "array",
                  items: { type: "string" },
                  description: "可用时段"
                },
                validityPeriod: { type: "number", description: "有效期" },
                usageThreshold: { type: "string", description: "使用门槛" },
                applyToAllStudio: {
                  type: "boolean",
                  description: "所有门店可用"
                },
                studios: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  },
                  description: "应用门店",
                  ref: "StudioStudioList"
                },
                applyToAllProduct: {
                  type: "boolean",
                  description: "所有训练产品可用"
                },
                products: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      brandId: { type: "number", description: "品牌" },
                      name: { type: "string", description: "课程名称" },
                      enName: { type: "string", description: "英文名称" },
                      usageCn: { type: "string", description: "Usage cn TODO" },
                      forKid: { type: "boolean", description: "For kid TODO" },
                      stageList: {
                        type: "array",
                        items: { type: "string" },
                        description: "Stage list TODO"
                      },
                      provider: { type: "string", description: "产品品牌" },
                      expiryDate: {
                        description: "授权截止",
                        ref: "date",
                        type: "string",
                        format: "date",
                        example: "2018-01-01",
                        properties: {}
                      },
                      category: {
                        type: "string",
                        required: false,
                        enum: ["brand", "platform"],
                        "x-i18n": { brand: "自研产品", platform: "授权产品" },
                        description: "类型"
                      },
                      productType: {
                        type: "string",
                        required: false,
                        enum: [
                          "group_training",
                          "elite_training",
                          "camp",
                          "free_training"
                        ],
                        "x-i18n": {
                          group_training: "团课",
                          elite_training: "私教",
                          camp: "训练营",
                          free_training: "自由训练"
                        },
                        description: "类型"
                      },
                      scheduleRule: {
                        type: "string",
                        description: "Schedule rule TODO"
                      },
                      entryPrice: {
                        type: "string",
                        description: "Entry price TODO"
                      },
                      active: { type: "boolean", description: "激活" }
                    }
                  },
                  description: "应用训练产品",
                  ref: "CourseProductList"
                },
                active: { type: "boolean", description: "激活" }
              }
            },
            expiryDate: {
              description: "过期时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            createdAt: {
              description: "Created at TODO",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            validityPeriod: { type: "number", description: "有效期" },
            workflowState: { type: "string", description: "状态" },
            notes: { type: "string", description: "备注" }
          }
        },
        ref: "CouponMemberCouponDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MobileMemberInvitationDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      invitee: {
        description: "Invitee TODO",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      confirmed: { type: "boolean", description: "Confirmed TODO" }
    }
  },
  MobileMemberInvitationPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            invitee: {
              description: "Invitee TODO",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            confirmed: { type: "boolean", description: "Confirmed TODO" }
          }
        },
        ref: "MobileMemberInvitationDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  OrderDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "单号" },
      member: {
        description: "会员",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      orderType: {
        type: "string",
        required: false,
        enum: [
          "coupon",
          "direct_purchase",
          "membership_card",
          "prepay_card",
          "camp"
        ],
        "x-i18n": {
          coupon: "代金券",
          direct_purchase: "微信购课",
          membership_card: "会员卡",
          prepay_card: "储值卡",
          camp: "训练营"
        },
        description: "订单类型"
      },
      productInfo: { type: "string", description: "商品信息" },
      description: { type: "string", description: "订单信息" },
      paymentMethod: {
        type: "string",
        required: false,
        enum: [
          "微信钱包",
          "大众点评/美团",
          "抖音",
          "微信转账",
          "支付宝转账",
          "现金",
          "POS机"
        ],
        "x-i18n": {
          微信钱包: "微信钱包",
          "大众点评/美团": "大众点评/美团",
          抖音: "抖音",
          微信转账: "微信转账",
          支付宝转账: "支付宝转账",
          现金: "现金",
          POS机: "POS机"
        },
        description: "支付方式"
      },
      paidAt: {
        description: "支付时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      eliteTrainingCoaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" },
            user: {
              anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
              description: "用户"
            },
            userId: { type: "number", description: "用户" },
            profilePhoto: {
              description: "形象照",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            wxQrCode: {
              description: "微信二维码",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            level: {
              description: "等级",
              ref: "CoachLevelDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                category: {
                  description: "类型",
                  ref: "CoachCategoryDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" }
                  }
                },
                categoryId: { type: "number", description: "类型" }
              }
            },
            dateOfBirth: {
              description: "生日",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            joinedAt: {
              description: "入职时间",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            primaryStudio: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/StudioStudioList" }
              ],
              description: "绑定门店"
            },
            scopeOfAccess: {
              type: "string",
              required: true,
              enum: ["mine", "all"],
              "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
              description: "查看排课范围"
            },
            introduction: { type: "string", description: "介绍" },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "教练风格"
            },
            certificationList: {
              type: "array",
              items: { type: "string" },
              description: "技能认证"
            },
            styleList: {
              type: "array",
              items: { type: "string" },
              description: "执教风格"
            },
            resumeDisplay: { type: "boolean", description: "执教数据展示" },
            eliteTrainingCapacity: {
              type: "number",
              description: "私教人数上限"
            },
            primaryStudioId: { type: "number", description: "绑定门店" },
            applyToAllProduct: {
              type: "boolean",
              description: "授权所有训练产品"
            },
            levelId: { type: "number", description: "等级" },
            createdAt: {
              description: "加入时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        description: "授课教练",
        ref: "CoachCoachDefault"
      },
      coaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" },
            user: {
              anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
              description: "用户"
            },
            userId: { type: "number", description: "用户" },
            profilePhoto: {
              description: "形象照",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            wxQrCode: {
              description: "微信二维码",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            level: {
              description: "等级",
              ref: "CoachLevelDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                category: {
                  description: "类型",
                  ref: "CoachCategoryDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" }
                  }
                },
                categoryId: { type: "number", description: "类型" }
              }
            },
            dateOfBirth: {
              description: "生日",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            joinedAt: {
              description: "入职时间",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            primaryStudio: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/StudioStudioList" }
              ],
              description: "绑定门店"
            },
            scopeOfAccess: {
              type: "string",
              required: true,
              enum: ["mine", "all"],
              "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
              description: "查看排课范围"
            },
            introduction: { type: "string", description: "介绍" },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "教练风格"
            },
            certificationList: {
              type: "array",
              items: { type: "string" },
              description: "技能认证"
            },
            styleList: {
              type: "array",
              items: { type: "string" },
              description: "执教风格"
            },
            resumeDisplay: { type: "boolean", description: "执教数据展示" },
            eliteTrainingCapacity: {
              type: "number",
              description: "私教人数上限"
            },
            primaryStudioId: { type: "number", description: "绑定门店" },
            applyToAllProduct: {
              type: "boolean",
              description: "授权所有训练产品"
            },
            levelId: { type: "number", description: "等级" },
            createdAt: {
              description: "加入时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        description: "关联员工",
        ref: "CoachCoachDefault"
      },
      coachIds: {
        type: "array",
        items: { type: "number" },
        description: "关联员工"
      },
      studio: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/StudioStudioDefault" }
        ],
        description: "Studio TODO"
      },
      amount: { type: "string", description: "支付金额" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "paid", "refunded", "cancelled"],
        "x-i18n": {
          new: "待支付",
          paid: "已支付",
          refunded: "已退款",
          cancelled: "已取消"
        },
        description: "状态"
      },
      externalTransactionId: {
        type: "string",
        nullable: true,
        description: "商户交易ID"
      },
      notes: { type: "string", nullable: true, description: "备注" }
    }
  },
  OrderForm: {
    type: "object",
    properties: {
      memberId: { type: "number", required: true, description: "会员" },
      orderType: {
        type: "string",
        required: false,
        enum: [
          "coupon",
          "direct_purchase",
          "membership_card",
          "prepay_card",
          "camp"
        ],
        "x-i18n": {
          coupon: "代金券",
          direct_purchase: "微信购课",
          membership_card: "会员卡",
          prepay_card: "储值卡",
          camp: "训练营"
        },
        description: "订单类型"
      },
      productId: { type: "number", required: true, description: "商品" },
      paymentMethod: {
        type: "string",
        required: true,
        enum: [
          "微信钱包",
          "大众点评/美团",
          "抖音",
          "微信转账",
          "支付宝转账",
          "现金",
          "POS机"
        ],
        "x-i18n": {
          微信钱包: "微信钱包",
          "大众点评/美团": "大众点评/美团",
          抖音: "抖音",
          微信转账: "微信转账",
          支付宝转账: "支付宝转账",
          现金: "现金",
          POS机: "POS机"
        },
        description: "支付方式"
      },
      amount: { type: "number", required: true, description: "支付金额" },
      externalTransactionId: { type: "string", description: "商户交易ID" },
      studioId: { type: "number", description: "Studio TODO" },
      eliteTrainingCoachIds: {
        type: "array",
        items: { type: "number" },
        description: "授课教练"
      },
      coachIds: {
        type: "array",
        items: { type: "number" },
        description: "关联员工"
      },
      notes: { type: "string", description: "备注" }
    }
  },
  OrderUpdateForm: {
    type: "object",
    properties: {
      studioId: { type: "number", description: "Studio TODO" },
      eliteTrainingCoachIds: {
        type: "array",
        items: { type: "number" },
        description: "授课教练"
      },
      coachIds: {
        type: "array",
        items: { type: "number" },
        description: "关联员工"
      },
      paymentMethod: { type: "string", description: "支付方式" },
      externalTransactionId: { type: "string", description: "商户交易ID" },
      notes: { type: "string", description: "备注" }
    }
  },
  OrderMemberSummary: {
    type: "object",
    properties: {
      total: { type: "string", description: "总金额" },
      currentMonthTotal: { type: "string", description: "本月总金额" }
    }
  },
  OrderMemberReportItem: {
    type: "object",
    properties: {
      label: { type: "string", description: "标识" },
      data: { type: "string", description: "数据" }
    }
  },
  OrderPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "单号" },
            member: {
              description: "会员",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            orderType: {
              type: "string",
              required: false,
              enum: [
                "coupon",
                "direct_purchase",
                "membership_card",
                "prepay_card",
                "camp"
              ],
              "x-i18n": {
                coupon: "代金券",
                direct_purchase: "微信购课",
                membership_card: "会员卡",
                prepay_card: "储值卡",
                camp: "训练营"
              },
              description: "订单类型"
            },
            productInfo: { type: "string", description: "商品信息" },
            description: { type: "string", description: "订单信息" },
            paymentMethod: {
              type: "string",
              required: false,
              enum: [
                "微信钱包",
                "大众点评/美团",
                "抖音",
                "微信转账",
                "支付宝转账",
                "现金",
                "POS机"
              ],
              "x-i18n": {
                微信钱包: "微信钱包",
                "大众点评/美团": "大众点评/美团",
                抖音: "抖音",
                微信转账: "微信转账",
                支付宝转账: "支付宝转账",
                现金: "现金",
                POS机: "POS机"
              },
              description: "支付方式"
            },
            paidAt: {
              description: "支付时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            eliteTrainingCoaches: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" },
                  user: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/UserDefault" }
                    ],
                    description: "用户"
                  },
                  userId: { type: "number", description: "用户" },
                  profilePhoto: {
                    description: "形象照",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  wxQrCode: {
                    description: "微信二维码",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  level: {
                    description: "等级",
                    ref: "CoachLevelDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      category: {
                        description: "类型",
                        ref: "CoachCategoryDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" }
                        }
                      },
                      categoryId: { type: "number", description: "类型" }
                    }
                  },
                  dateOfBirth: {
                    description: "生日",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  joinedAt: {
                    description: "入职时间",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  primaryStudio: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/StudioStudioList" }
                    ],
                    description: "绑定门店"
                  },
                  scopeOfAccess: {
                    type: "string",
                    required: true,
                    enum: ["mine", "all"],
                    "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                    description: "查看排课范围"
                  },
                  introduction: { type: "string", description: "介绍" },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "教练风格"
                  },
                  certificationList: {
                    type: "array",
                    items: { type: "string" },
                    description: "技能认证"
                  },
                  styleList: {
                    type: "array",
                    items: { type: "string" },
                    description: "执教风格"
                  },
                  resumeDisplay: {
                    type: "boolean",
                    description: "执教数据展示"
                  },
                  eliteTrainingCapacity: {
                    type: "number",
                    description: "私教人数上限"
                  },
                  primaryStudioId: { type: "number", description: "绑定门店" },
                  applyToAllProduct: {
                    type: "boolean",
                    description: "授权所有训练产品"
                  },
                  levelId: { type: "number", description: "等级" },
                  createdAt: {
                    description: "加入时间",
                    ref: "datetime",
                    type: "string",
                    format: "date-time",
                    example: "2018-08-01T00:00:00.000Z",
                    properties: {}
                  }
                }
              },
              description: "授课教练",
              ref: "CoachCoachDefault"
            },
            coaches: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" },
                  user: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/UserDefault" }
                    ],
                    description: "用户"
                  },
                  userId: { type: "number", description: "用户" },
                  profilePhoto: {
                    description: "形象照",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  wxQrCode: {
                    description: "微信二维码",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  level: {
                    description: "等级",
                    ref: "CoachLevelDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      category: {
                        description: "类型",
                        ref: "CoachCategoryDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" }
                        }
                      },
                      categoryId: { type: "number", description: "类型" }
                    }
                  },
                  dateOfBirth: {
                    description: "生日",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  joinedAt: {
                    description: "入职时间",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  primaryStudio: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/StudioStudioList" }
                    ],
                    description: "绑定门店"
                  },
                  scopeOfAccess: {
                    type: "string",
                    required: true,
                    enum: ["mine", "all"],
                    "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                    description: "查看排课范围"
                  },
                  introduction: { type: "string", description: "介绍" },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "教练风格"
                  },
                  certificationList: {
                    type: "array",
                    items: { type: "string" },
                    description: "技能认证"
                  },
                  styleList: {
                    type: "array",
                    items: { type: "string" },
                    description: "执教风格"
                  },
                  resumeDisplay: {
                    type: "boolean",
                    description: "执教数据展示"
                  },
                  eliteTrainingCapacity: {
                    type: "number",
                    description: "私教人数上限"
                  },
                  primaryStudioId: { type: "number", description: "绑定门店" },
                  applyToAllProduct: {
                    type: "boolean",
                    description: "授权所有训练产品"
                  },
                  levelId: { type: "number", description: "等级" },
                  createdAt: {
                    description: "加入时间",
                    ref: "datetime",
                    type: "string",
                    format: "date-time",
                    example: "2018-08-01T00:00:00.000Z",
                    properties: {}
                  }
                }
              },
              description: "关联员工",
              ref: "CoachCoachDefault"
            },
            coachIds: {
              type: "array",
              items: { type: "number" },
              description: "关联员工"
            },
            studio: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/StudioStudioDefault" }
              ],
              description: "Studio TODO"
            },
            amount: { type: "string", description: "支付金额" },
            workflowState: {
              type: "string",
              required: false,
              enum: ["new", "paid", "refunded", "cancelled"],
              "x-i18n": {
                new: "待支付",
                paid: "已支付",
                refunded: "已退款",
                cancelled: "已取消"
              },
              description: "状态"
            },
            externalTransactionId: {
              type: "string",
              nullable: true,
              description: "商户交易ID"
            },
            notes: { type: "string", nullable: true, description: "备注" }
          }
        },
        description: "数据",
        ref: "OrderDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MembershipRecordDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      recordType: { type: "string", description: "记录类型" },
      targetType: { type: "string", description: "target_type" },
      targetId: { type: "number", description: "target_id" },
      value: { type: "string", description: "充值金额" },
      bonus: { type: "string", description: "赠费" },
      total: { type: "string", description: "总金额" },
      validityPeriod: { type: "number", description: "有效期" },
      createdAt: {
        description: "记录时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      member: {
        description: "Member TODO",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      booking: { type: "string", description: "Booking TODO" },
      notes: { type: "string", description: "备注" }
    }
  },
  MembershipRecordPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            recordType: { type: "string", description: "记录类型" },
            targetType: { type: "string", description: "target_type" },
            targetId: { type: "number", description: "target_id" },
            value: { type: "string", description: "充值金额" },
            bonus: { type: "string", description: "赠费" },
            total: { type: "string", description: "总金额" },
            validityPeriod: { type: "number", description: "有效期" },
            createdAt: {
              description: "记录时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            member: {
              description: "Member TODO",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            booking: { type: "string", description: "Booking TODO" },
            notes: { type: "string", description: "备注" }
          }
        },
        ref: "MembershipRecordDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CouponCardOptionCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      coupon: {
        description: "代金券",
        ref: "CouponCouponDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          value: { type: "string", description: "面值" },
          fullDiscount: { type: "boolean", description: "全额代金券" },
          availableTimeRange: {
            type: "array",
            items: { type: "string" },
            description: "可用时段"
          },
          validityPeriod: { type: "number", description: "有效期" },
          usageThreshold: { type: "string", description: "使用门槛" },
          applyToAllStudio: { type: "boolean", description: "所有门店可用" },
          studios: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" }
              }
            },
            description: "应用门店",
            ref: "StudioStudioList"
          },
          applyToAllProduct: {
            type: "boolean",
            description: "所有训练产品可用"
          },
          products: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" }
              }
            },
            description: "应用训练产品",
            ref: "CourseProductList"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      couponQuantity: { type: "number", description: "代金券数量" }
    }
  },
  CouponCardOptionCouponForm: {
    type: "object",
    properties: {
      couponId: { type: "number", description: "代金券" },
      couponQuantity: { type: "number", description: "代金券数量" }
    }
  },
  MembershipCardOptionPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            name: { type: "string", required: true, description: "名称" },
            value: { type: "string", required: true, description: "充值金额" },
            validityPeriod: {
              type: "number",
              required: true,
              description: "有效期"
            },
            originalPrice: {
              type: "string",
              required: true,
              description: "原价"
            },
            inventory: { type: "number", nullable: true, description: "库存" },
            price: { type: "string", required: true, description: "价格" },
            salesType: {
              type: "string",
              required: true,
              enum: ["normal", "sales", "new_member", "purchase_limited"],
              "x-i18n": {
                normal: "普通",
                sales: "特惠",
                new_member: "新手专享",
                purchase_limited: "限购一次"
              },
              description: "销售类型"
            },
            active: { type: "boolean", required: true, description: "激活" },
            position: { type: "number", description: "排序" },
            display: { type: "boolean", required: true, description: "展示" },
            id: { type: "number", description: "ID" },
            bonus: { type: "string", description: "赠送金额" },
            purchaseCount: { type: "number", description: "销售数量" },
            displayName: { type: "string", description: "展示名称" },
            courseProductType: {
              type: "string",
              description: "Course product type TODO"
            },
            notes: { type: "string", description: "附加说明" }
          }
        },
        ref: "MembershipCardOptionDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MembershipCardProductForm: {
    type: "object",
    properties: {
      price: { type: "string", required: true, description: "扣次" }
    }
  },
  MembershipCardProductDefault: {
    type: "object",
    properties: {
      price: { type: "string", description: "扣次" },
      id: { type: "number", description: "ID" },
      product: {
        description: "产品",
        ref: "CourseProductList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          brandId: { type: "number", description: "品牌" },
          name: { type: "string", description: "课程名称" },
          enName: { type: "string", description: "英文名称" },
          usageCn: { type: "string", description: "Usage cn TODO" },
          forKid: { type: "boolean", description: "For kid TODO" },
          stageList: {
            type: "array",
            items: { type: "string" },
            description: "Stage list TODO"
          },
          provider: { type: "string", description: "产品品牌" },
          expiryDate: {
            description: "授权截止",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          category: {
            type: "string",
            required: false,
            enum: ["brand", "platform"],
            "x-i18n": { brand: "自研产品", platform: "授权产品" },
            description: "类型"
          },
          productType: {
            type: "string",
            required: false,
            enum: ["group_training", "elite_training", "camp", "free_training"],
            "x-i18n": {
              group_training: "团课",
              elite_training: "私教",
              camp: "训练营",
              free_training: "自由训练"
            },
            description: "类型"
          },
          scheduleRule: { type: "string", description: "Schedule rule TODO" },
          entryPrice: { type: "string", description: "Entry price TODO" },
          active: { type: "boolean", description: "激活" }
        }
      }
    }
  },
  MembershipCardProductPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            price: { type: "string", description: "扣次" },
            id: { type: "number", description: "ID" },
            product: {
              description: "产品",
              ref: "CourseProductList",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" }
              }
            }
          }
        },
        ref: "MembershipCardProductDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MembershipCardPagedArrayEssential: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            cardType: {
              type: "string",
              required: true,
              enum: ["duration", "entry"],
              "x-i18n": { duration: "时段卡", entry: "次卡" },
              description: "会员卡类型"
            },
            shared: { type: "boolean", description: "共享卡" },
            sharedType: { type: "string", description: "共享类型" },
            productType: {
              type: "string",
              required: true,
              enum: ["elite_training", "group_training", "free_training"],
              "x-i18n": {
                elite_training: "私教",
                group_training: "团课",
                free_training: "自由训练"
              },
              description: "训练产品类型"
            },
            productTypeZh: { type: "string", description: "训练产品类型" },
            cancellationDeadline: {
              type: "number",
              description: "取消限制（小时）"
            },
            activateStrategy: { type: "string", description: "开卡设置" },
            display: { type: "boolean", description: "展示" },
            giftable: { type: "boolean", description: "是否可赠课" },
            position: { type: "number", description: "排序" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "MembershipCardEssential"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MembershipPrepayCardDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      campDiscount: { type: "string", description: "训练营折扣" },
      groupTrainingDiscount: { type: "string", description: "团课折扣" },
      freeTrainingDiscount: { type: "string", description: "自由训练折扣" },
      eliteTrainingDiscount: { type: "string", description: "私教折扣" },
      couponPackageDiscount: { type: "string", description: "代金券购买折扣" },
      membershipCardDiscount: { type: "string", description: "会员卡购买折扣" },
      bookingLimitPeriod: {
        type: "string",
        required: false,
        enum: ["", "daily", "weekly", "monthly"],
        "x-i18n": { "": "无", daily: "每日", weekly: "每周", monthly: "每月" },
        description: "约课次数限制"
      },
      bookingLimit: { type: "number", description: "最大预约次数" },
      bookingDaysLimit: { type: "number", description: "约课天数限制" },
      availableForCoursesWithOverlap: {
        type: "boolean",
        description: "同一时段预约多堂课"
      },
      cancellationDeadline: { type: "number", description: "取消限制（小时）" },
      display: { type: "boolean", description: "展示" },
      cover: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "封面图"
      },
      introduction: { type: "string", nullable: true, description: "介绍" },
      maxValidityPeriod: {
        type: "number",
        required: false,
        description: "最大有效期"
      },
      activateStrategy: { type: "string", description: "开卡设置" },
      terms: { type: "string", required: false, description: "充值和扣费规则" },
      availableToCouponPackage: {
        type: "boolean",
        description: "可以用于购买代金券商品"
      },
      availableToMembershipCard: {
        type: "boolean",
        description: "可以用于购买会员卡"
      },
      giftable: { type: "boolean", description: "是否可赠课" },
      active: { type: "boolean", description: "激活" }
    }
  },
  MembershipPrepayCardForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      campDiscount: {
        type: "number",
        required: true,
        description: "训练营折扣"
      },
      groupTrainingDiscount: {
        type: "number",
        required: true,
        description: "团课折扣"
      },
      freeTrainingDiscount: {
        type: "number",
        required: true,
        description: "自由训练折扣"
      },
      eliteTrainingDiscount: {
        type: "number",
        required: true,
        description: "私教折扣"
      },
      couponPackageDiscount: {
        type: "number",
        required: true,
        description: "代金券购买折扣"
      },
      membershipCardDiscount: {
        type: "number",
        required: true,
        description: "会员卡购买折扣"
      },
      bookingLimitPeriod: {
        type: "string",
        required: false,
        enum: ["", "daily", "weekly", "monthly"],
        "x-i18n": { "": "无", daily: "每日", weekly: "每周", monthly: "每月" },
        description: "约课次数限制"
      },
      bookingLimit: { type: "number", description: "最大预约次数" },
      bookingDaysLimit: { type: "number", description: "约课天数限制" },
      availableForCoursesWithOverlap: {
        type: "boolean",
        description: "同一时段预约多堂课"
      },
      cancellationDeadline: {
        type: "number",
        required: true,
        description: "取消限制（小时）"
      },
      maxValidityPeriod: {
        type: "number",
        required: false,
        description: "最大有效期"
      },
      activateStrategy: { type: "string", description: "开卡设置" },
      terms: { type: "string", required: false, description: "充值和扣费规则" },
      display: { type: "boolean", required: true, description: "展示" },
      cover: {
        description: "封面图",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      introduction: { type: "string", description: "介绍" },
      availableToCouponPackage: {
        type: "boolean",
        description: "可以用于购买代金券商品"
      },
      availableToMembershipCard: {
        type: "boolean",
        description: "可以用于购买会员卡"
      },
      giftable: { type: "boolean", required: true, description: "是否可赠课" },
      active: { type: "boolean", required: true, description: "激活" }
    }
  },
  BrandOperatingCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "代金券名称" },
      value: { type: "string", description: "面额" },
      fullDiscount: { type: "boolean", description: "全额券" },
      totalCount: { type: "number", description: "发放数量" },
      newCount: { type: "number", description: "未使用" },
      usedCount: { type: "number", description: "已使用" },
      expiredCount: { type: "number", description: "已过期" }
    }
  },
  BrandOperatingCouponPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "代金券名称" },
            value: { type: "string", description: "面额" },
            fullDiscount: { type: "boolean", description: "全额券" },
            totalCount: { type: "number", description: "发放数量" },
            newCount: { type: "number", description: "未使用" },
            usedCount: { type: "number", description: "已使用" },
            expiredCount: { type: "number", description: "已过期" }
          }
        },
        ref: "BrandOperatingCouponDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  BrandOperatingMemberCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      expiryDate: {
        description: "过期时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      validityPeriod: { type: "number", description: "有效期" },
      createdAt: {
        description: "Created at TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      workflowState: { type: "string", description: "状态" },
      notes: { type: "string", description: "备注" }
    }
  },
  BrandOperatingMemberCouponPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "会员",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            expiryDate: {
              description: "过期时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            validityPeriod: { type: "number", description: "有效期" },
            createdAt: {
              description: "Created at TODO",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            workflowState: { type: "string", description: "状态" },
            notes: { type: "string", description: "备注" }
          }
        },
        ref: "BrandOperatingMemberCouponDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CourseBookingPagedArrayGiftBooking: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            owner: {
              description: "所有人",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            member: {
              description: "会员",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            course: {
              description: "课程",
              ref: "CourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                name: { type: "string", description: "名称" },
                enName: { type: "string", description: "课程英文名称" },
                homework: { type: "string", description: "课后作业" },
                workflowState: {
                  type: "string",
                  description: "Workflow state TODO"
                },
                product: {
                  description: "训练产品",
                  ref: "CourseProductList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    brandId: { type: "number", description: "品牌" },
                    name: { type: "string", description: "课程名称" },
                    enName: { type: "string", description: "英文名称" },
                    usageCn: { type: "string", description: "Usage cn TODO" },
                    forKid: { type: "boolean", description: "For kid TODO" },
                    stageList: {
                      type: "array",
                      items: { type: "string" },
                      description: "Stage list TODO"
                    },
                    provider: { type: "string", description: "产品品牌" },
                    expiryDate: {
                      description: "授权截止",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    category: {
                      type: "string",
                      required: false,
                      enum: ["brand", "platform"],
                      "x-i18n": { brand: "自研产品", platform: "授权产品" },
                      description: "类型"
                    },
                    productType: {
                      type: "string",
                      required: false,
                      enum: [
                        "group_training",
                        "elite_training",
                        "camp",
                        "free_training"
                      ],
                      "x-i18n": {
                        group_training: "团课",
                        elite_training: "私教",
                        camp: "训练营",
                        free_training: "自由训练"
                      },
                      description: "类型"
                    },
                    scheduleRule: {
                      type: "string",
                      description: "Schedule rule TODO"
                    },
                    entryPrice: {
                      type: "string",
                      description: "Entry price TODO"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                assistantCoaches: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      phone: { type: "string", description: "手机号" },
                      avatar: {
                        description: "头像",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      name: { type: "string", description: "姓名" },
                      displayName: { type: "string", description: "昵称" },
                      note: { type: "string", description: "备注" },
                      active: { type: "boolean", description: "激活" },
                      certified: {
                        type: "boolean",
                        description: "Certified TODO"
                      }
                    }
                  },
                  description: "助教",
                  ref: "CoachCoachEssential"
                },
                zone: {
                  description: "场地",
                  ref: "StudioZoneList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "场地名称" },
                    position: { type: "number", description: "排序" },
                    useHrm: { type: "boolean", description: "使用心率" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    tasExpiried: {
                      type: "boolean",
                      description: "Tas expiried TODO"
                    },
                    studio: {
                      description: "门店",
                      ref: "StudioStudioList",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        fullName: { type: "string", description: "门店全称" },
                        shortName: { type: "string", description: "门店名称" }
                      }
                    }
                  }
                }
              }
            },
            cost: { type: "string", description: "Cost TODO" },
            createdAt: {
              description: "Created at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            workflowState: {
              type: "string",
              required: false,
              enum: [
                "new",
                "reserved",
                "queued",
                "checked_in",
                "absent",
                "cancelled"
              ],
              "x-i18n": {
                new: "未签到",
                reserved: "未支付",
                queued: "已排号",
                checked_in: "已签到",
                absent: "缺席",
                cancelled: "已取消"
              },
              description: "状态"
            },
            consumeType: { type: "string", description: "预约方式" }
          }
        },
        description: "数据",
        ref: "CourseBookingGiftBooking"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  OperatingMemberDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", nullable: true, description: "姓名" },
      phone: { type: "string", nullable: true, description: "手机" },
      gender: { type: "string", description: "性别" },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      coachName: { type: "string", description: "Coach name TODO" },
      age: { type: "number", description: "年龄" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      firstBooking: {
        description: "首次训练",
        ref: "CourseBookingDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          member: {
            description: "会员",
            ref: "MemberMemberDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              age: { type: "number", description: "年龄" },
              weight: { type: "number", description: "体重" },
              height: { type: "number", description: "Height TODO" },
              maxHr: {
                type: "number",
                nullable: true,
                description: "最大心率"
              },
              parent: {
                description: "家长",
                ref: "MemberMemberEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "昵称" },
                  userId: { type: "number", description: "用户ID" },
                  realName: {
                    type: "string",
                    nullable: true,
                    description: "姓名"
                  },
                  phone: {
                    type: "string",
                    nullable: true,
                    description: "手机"
                  },
                  gender: {
                    type: "string",
                    required: false,
                    enum: ["male", "female"],
                    "x-i18n": { male: "男", female: "女" },
                    description: "性别"
                  },
                  avatar: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/AttachmentDefault" }
                    ],
                    description: "头像"
                  },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "标签"
                  },
                  memberType: { type: "string", description: "会员类型" }
                }
              },
              profilePhotos: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                description: "形象照",
                ref: "AttachmentDefault"
              },
              joinedHof: { type: "boolean", description: "加入名人堂" },
              joinedHofAt: {
                description: "加入名人堂时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              wechatLinked: {
                type: "boolean",
                description: "Wechat linked TODO"
              },
              hofNotes: { type: "string", description: "名人堂备注" },
              vip: { type: "boolean", description: "vip" },
              active: { type: "boolean", description: "激活" }
            }
          },
          workflowState: {
            type: "string",
            required: false,
            enum: [
              "new",
              "reserved",
              "queued",
              "checked_in",
              "absent",
              "cancelled"
            ],
            "x-i18n": {
              new: "未签到",
              reserved: "未支付",
              queued: "已排号",
              checked_in: "已签到",
              absent: "缺席",
              cancelled: "已取消"
            },
            description: "状态"
          },
          course: {
            description: "课程",
            ref: "CourseCourseDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              startTime: {
                description: "开始时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              name: { type: "string", description: "名称" },
              enName: { type: "string", description: "课程英文名称" },
              homework: { type: "string", description: "课后作业" },
              workflowState: {
                type: "string",
                description: "Workflow state TODO"
              },
              product: {
                description: "训练产品",
                ref: "CourseProductList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  brandId: { type: "number", description: "品牌" },
                  name: { type: "string", description: "课程名称" },
                  enName: { type: "string", description: "英文名称" },
                  usageCn: { type: "string", description: "Usage cn TODO" },
                  forKid: { type: "boolean", description: "For kid TODO" },
                  stageList: {
                    type: "array",
                    items: { type: "string" },
                    description: "Stage list TODO"
                  },
                  provider: { type: "string", description: "产品品牌" },
                  expiryDate: {
                    description: "授权截止",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  category: {
                    type: "string",
                    required: false,
                    enum: ["brand", "platform"],
                    "x-i18n": { brand: "自研产品", platform: "授权产品" },
                    description: "类型"
                  },
                  productType: {
                    type: "string",
                    required: false,
                    enum: [
                      "group_training",
                      "elite_training",
                      "camp",
                      "free_training"
                    ],
                    "x-i18n": {
                      group_training: "团课",
                      elite_training: "私教",
                      camp: "训练营",
                      free_training: "自由训练"
                    },
                    description: "类型"
                  },
                  scheduleRule: {
                    type: "string",
                    description: "Schedule rule TODO"
                  },
                  entryPrice: {
                    type: "string",
                    description: "Entry price TODO"
                  },
                  active: { type: "boolean", description: "激活" }
                }
              },
              primaryCoach: {
                description: "教练",
                ref: "CoachCoachEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" }
                }
              },
              assistantCoaches: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                description: "助教",
                ref: "CoachCoachEssential"
              },
              zone: {
                description: "场地",
                ref: "StudioZoneList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "场地名称" },
                  position: { type: "number", description: "排序" },
                  useHrm: { type: "boolean", description: "使用心率" },
                  eliteTraining: { type: "boolean", description: "可上私教" },
                  tasExpiried: {
                    type: "boolean",
                    description: "Tas expiried TODO"
                  },
                  studio: {
                    description: "门店",
                    ref: "StudioStudioList",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  }
                }
              }
            }
          }
        }
      },
      trainingDays: { type: "number", description: "注册时长" },
      daysSinceLastTraining: { type: "number", description: "据上次训练天数" },
      trainingCount: { type: "number", description: "累积训练次数" },
      totalOrderAmount: { type: "string", description: "累积消费额" },
      communicationCount: { type: "number", description: "沟通次数" },
      parent: {
        description: "Parent TODO",
        ref: "MemberMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          userId: { type: "number", description: "用户ID" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          memberType: { type: "string", description: "会员类型" }
        }
      },
      createdAt: { type: "string", description: "注册日期" }
    }
  },
  OperatingMemberSendCouponsForm: {
    type: "object",
    properties: {
      memberType: { type: "string", description: "Member type TODO" },
      tag: { type: "string", nullable: true, description: "Tag TODO" },
      query: { type: "string", nullable: true, description: "Query TODO" },
      couponId: { type: "number", description: "Coupon TODO" },
      couponQuantity: { type: "number", description: "Coupon quantity TODO" },
      notes: { type: "string", nullable: true, description: "Notes TODO" }
    }
  },
  OperatingMemberExtendCouponValidityPeriodForm: {
    type: "object",
    properties: {
      memberType: { type: "string", description: "Member type TODO" },
      tag: { type: "string", nullable: true, description: "Tag TODO" },
      query: { type: "string", nullable: true, description: "Query TODO" },
      couponId: { type: "number", nullable: true, description: "Coupon TODO" },
      validityPeriod: { type: "number", description: "Validity period TODO" }
    }
  },
  OperatingMemberPrepay: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", nullable: true, description: "姓名" },
      phone: { type: "string", nullable: true, description: "手机" },
      gender: { type: "string", description: "性别" },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      coachName: { type: "string", description: "Coach name TODO" },
      age: { type: "number", description: "年龄" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      firstBooking: {
        description: "首次训练",
        ref: "CourseBookingDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          member: {
            description: "会员",
            ref: "MemberMemberDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              age: { type: "number", description: "年龄" },
              weight: { type: "number", description: "体重" },
              height: { type: "number", description: "Height TODO" },
              maxHr: {
                type: "number",
                nullable: true,
                description: "最大心率"
              },
              parent: {
                description: "家长",
                ref: "MemberMemberEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "昵称" },
                  userId: { type: "number", description: "用户ID" },
                  realName: {
                    type: "string",
                    nullable: true,
                    description: "姓名"
                  },
                  phone: {
                    type: "string",
                    nullable: true,
                    description: "手机"
                  },
                  gender: {
                    type: "string",
                    required: false,
                    enum: ["male", "female"],
                    "x-i18n": { male: "男", female: "女" },
                    description: "性别"
                  },
                  avatar: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/AttachmentDefault" }
                    ],
                    description: "头像"
                  },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "标签"
                  },
                  memberType: { type: "string", description: "会员类型" }
                }
              },
              profilePhotos: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                description: "形象照",
                ref: "AttachmentDefault"
              },
              joinedHof: { type: "boolean", description: "加入名人堂" },
              joinedHofAt: {
                description: "加入名人堂时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              wechatLinked: {
                type: "boolean",
                description: "Wechat linked TODO"
              },
              hofNotes: { type: "string", description: "名人堂备注" },
              vip: { type: "boolean", description: "vip" },
              active: { type: "boolean", description: "激活" }
            }
          },
          workflowState: {
            type: "string",
            required: false,
            enum: [
              "new",
              "reserved",
              "queued",
              "checked_in",
              "absent",
              "cancelled"
            ],
            "x-i18n": {
              new: "未签到",
              reserved: "未支付",
              queued: "已排号",
              checked_in: "已签到",
              absent: "缺席",
              cancelled: "已取消"
            },
            description: "状态"
          },
          course: {
            description: "课程",
            ref: "CourseCourseDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              startTime: {
                description: "开始时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              name: { type: "string", description: "名称" },
              enName: { type: "string", description: "课程英文名称" },
              homework: { type: "string", description: "课后作业" },
              workflowState: {
                type: "string",
                description: "Workflow state TODO"
              },
              product: {
                description: "训练产品",
                ref: "CourseProductList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  brandId: { type: "number", description: "品牌" },
                  name: { type: "string", description: "课程名称" },
                  enName: { type: "string", description: "英文名称" },
                  usageCn: { type: "string", description: "Usage cn TODO" },
                  forKid: { type: "boolean", description: "For kid TODO" },
                  stageList: {
                    type: "array",
                    items: { type: "string" },
                    description: "Stage list TODO"
                  },
                  provider: { type: "string", description: "产品品牌" },
                  expiryDate: {
                    description: "授权截止",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  category: {
                    type: "string",
                    required: false,
                    enum: ["brand", "platform"],
                    "x-i18n": { brand: "自研产品", platform: "授权产品" },
                    description: "类型"
                  },
                  productType: {
                    type: "string",
                    required: false,
                    enum: [
                      "group_training",
                      "elite_training",
                      "camp",
                      "free_training"
                    ],
                    "x-i18n": {
                      group_training: "团课",
                      elite_training: "私教",
                      camp: "训练营",
                      free_training: "自由训练"
                    },
                    description: "类型"
                  },
                  scheduleRule: {
                    type: "string",
                    description: "Schedule rule TODO"
                  },
                  entryPrice: {
                    type: "string",
                    description: "Entry price TODO"
                  },
                  active: { type: "boolean", description: "激活" }
                }
              },
              primaryCoach: {
                description: "教练",
                ref: "CoachCoachEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" }
                }
              },
              assistantCoaches: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                description: "助教",
                ref: "CoachCoachEssential"
              },
              zone: {
                description: "场地",
                ref: "StudioZoneList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "场地名称" },
                  position: { type: "number", description: "排序" },
                  useHrm: { type: "boolean", description: "使用心率" },
                  eliteTraining: { type: "boolean", description: "可上私教" },
                  tasExpiried: {
                    type: "boolean",
                    description: "Tas expiried TODO"
                  },
                  studio: {
                    description: "门店",
                    ref: "StudioStudioList",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  }
                }
              }
            }
          }
        }
      },
      trainingDays: { type: "number", description: "注册时长" },
      daysSinceLastTraining: { type: "number", description: "据上次训练天数" },
      trainingCount: { type: "number", description: "累积训练次数" },
      totalOrderAmount: { type: "string", description: "累积消费额" },
      communicationCount: { type: "number", description: "沟通次数" },
      parent: { description: "Parent TODO" },
      createdAt: { type: "string", description: "注册日期" },
      expiryInfo: { type: "string", description: "Expiry info TODO" }
    }
  },
  OperatingMemberGroupTrainingMembership: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", nullable: true, description: "姓名" },
      phone: { type: "string", nullable: true, description: "手机" },
      gender: { type: "string", description: "性别" },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      coachName: { type: "string", description: "Coach name TODO" },
      age: { type: "number", description: "年龄" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      firstBooking: {
        description: "首次训练",
        ref: "CourseBookingDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          member: {
            description: "会员",
            ref: "MemberMemberDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              age: { type: "number", description: "年龄" },
              weight: { type: "number", description: "体重" },
              height: { type: "number", description: "Height TODO" },
              maxHr: {
                type: "number",
                nullable: true,
                description: "最大心率"
              },
              parent: {
                description: "家长",
                ref: "MemberMemberEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "昵称" },
                  userId: { type: "number", description: "用户ID" },
                  realName: {
                    type: "string",
                    nullable: true,
                    description: "姓名"
                  },
                  phone: {
                    type: "string",
                    nullable: true,
                    description: "手机"
                  },
                  gender: {
                    type: "string",
                    required: false,
                    enum: ["male", "female"],
                    "x-i18n": { male: "男", female: "女" },
                    description: "性别"
                  },
                  avatar: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/AttachmentDefault" }
                    ],
                    description: "头像"
                  },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "标签"
                  },
                  memberType: { type: "string", description: "会员类型" }
                }
              },
              profilePhotos: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                description: "形象照",
                ref: "AttachmentDefault"
              },
              joinedHof: { type: "boolean", description: "加入名人堂" },
              joinedHofAt: {
                description: "加入名人堂时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              wechatLinked: {
                type: "boolean",
                description: "Wechat linked TODO"
              },
              hofNotes: { type: "string", description: "名人堂备注" },
              vip: { type: "boolean", description: "vip" },
              active: { type: "boolean", description: "激活" }
            }
          },
          workflowState: {
            type: "string",
            required: false,
            enum: [
              "new",
              "reserved",
              "queued",
              "checked_in",
              "absent",
              "cancelled"
            ],
            "x-i18n": {
              new: "未签到",
              reserved: "未支付",
              queued: "已排号",
              checked_in: "已签到",
              absent: "缺席",
              cancelled: "已取消"
            },
            description: "状态"
          },
          course: {
            description: "课程",
            ref: "CourseCourseDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              startTime: {
                description: "开始时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              name: { type: "string", description: "名称" },
              enName: { type: "string", description: "课程英文名称" },
              homework: { type: "string", description: "课后作业" },
              workflowState: {
                type: "string",
                description: "Workflow state TODO"
              },
              product: {
                description: "训练产品",
                ref: "CourseProductList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  brandId: { type: "number", description: "品牌" },
                  name: { type: "string", description: "课程名称" },
                  enName: { type: "string", description: "英文名称" },
                  usageCn: { type: "string", description: "Usage cn TODO" },
                  forKid: { type: "boolean", description: "For kid TODO" },
                  stageList: {
                    type: "array",
                    items: { type: "string" },
                    description: "Stage list TODO"
                  },
                  provider: { type: "string", description: "产品品牌" },
                  expiryDate: {
                    description: "授权截止",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  category: {
                    type: "string",
                    required: false,
                    enum: ["brand", "platform"],
                    "x-i18n": { brand: "自研产品", platform: "授权产品" },
                    description: "类型"
                  },
                  productType: {
                    type: "string",
                    required: false,
                    enum: [
                      "group_training",
                      "elite_training",
                      "camp",
                      "free_training"
                    ],
                    "x-i18n": {
                      group_training: "团课",
                      elite_training: "私教",
                      camp: "训练营",
                      free_training: "自由训练"
                    },
                    description: "类型"
                  },
                  scheduleRule: {
                    type: "string",
                    description: "Schedule rule TODO"
                  },
                  entryPrice: {
                    type: "string",
                    description: "Entry price TODO"
                  },
                  active: { type: "boolean", description: "激活" }
                }
              },
              primaryCoach: {
                description: "教练",
                ref: "CoachCoachEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" }
                }
              },
              assistantCoaches: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                description: "助教",
                ref: "CoachCoachEssential"
              },
              zone: {
                description: "场地",
                ref: "StudioZoneList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "场地名称" },
                  position: { type: "number", description: "排序" },
                  useHrm: { type: "boolean", description: "使用心率" },
                  eliteTraining: { type: "boolean", description: "可上私教" },
                  tasExpiried: {
                    type: "boolean",
                    description: "Tas expiried TODO"
                  },
                  studio: {
                    description: "门店",
                    ref: "StudioStudioList",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  }
                }
              }
            }
          }
        }
      },
      trainingDays: { type: "number", description: "注册时长" },
      daysSinceLastTraining: { type: "number", description: "据上次训练天数" },
      trainingCount: { type: "number", description: "累积训练次数" },
      totalOrderAmount: { type: "string", description: "累积消费额" },
      communicationCount: { type: "number", description: "沟通次数" },
      parent: { description: "Parent TODO" },
      createdAt: { type: "string", description: "注册日期" },
      expiryInfo: { type: "string", description: "Expiry info TODO" }
    }
  },
  OperatingMemberEliteTrainingMembership: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", nullable: true, description: "姓名" },
      phone: { type: "string", nullable: true, description: "手机" },
      gender: { type: "string", description: "性别" },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      coachName: { type: "string", description: "Coach name TODO" },
      age: { type: "number", description: "年龄" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      firstBooking: {
        description: "首次训练",
        ref: "CourseBookingDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          member: {
            description: "会员",
            ref: "MemberMemberDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              age: { type: "number", description: "年龄" },
              weight: { type: "number", description: "体重" },
              height: { type: "number", description: "Height TODO" },
              maxHr: {
                type: "number",
                nullable: true,
                description: "最大心率"
              },
              parent: {
                description: "家长",
                ref: "MemberMemberEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "昵称" },
                  userId: { type: "number", description: "用户ID" },
                  realName: {
                    type: "string",
                    nullable: true,
                    description: "姓名"
                  },
                  phone: {
                    type: "string",
                    nullable: true,
                    description: "手机"
                  },
                  gender: {
                    type: "string",
                    required: false,
                    enum: ["male", "female"],
                    "x-i18n": { male: "男", female: "女" },
                    description: "性别"
                  },
                  avatar: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/AttachmentDefault" }
                    ],
                    description: "头像"
                  },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "标签"
                  },
                  memberType: { type: "string", description: "会员类型" }
                }
              },
              profilePhotos: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                description: "形象照",
                ref: "AttachmentDefault"
              },
              joinedHof: { type: "boolean", description: "加入名人堂" },
              joinedHofAt: {
                description: "加入名人堂时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              wechatLinked: {
                type: "boolean",
                description: "Wechat linked TODO"
              },
              hofNotes: { type: "string", description: "名人堂备注" },
              vip: { type: "boolean", description: "vip" },
              active: { type: "boolean", description: "激活" }
            }
          },
          workflowState: {
            type: "string",
            required: false,
            enum: [
              "new",
              "reserved",
              "queued",
              "checked_in",
              "absent",
              "cancelled"
            ],
            "x-i18n": {
              new: "未签到",
              reserved: "未支付",
              queued: "已排号",
              checked_in: "已签到",
              absent: "缺席",
              cancelled: "已取消"
            },
            description: "状态"
          },
          course: {
            description: "课程",
            ref: "CourseCourseDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              startTime: {
                description: "开始时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              name: { type: "string", description: "名称" },
              enName: { type: "string", description: "课程英文名称" },
              homework: { type: "string", description: "课后作业" },
              workflowState: {
                type: "string",
                description: "Workflow state TODO"
              },
              product: {
                description: "训练产品",
                ref: "CourseProductList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  brandId: { type: "number", description: "品牌" },
                  name: { type: "string", description: "课程名称" },
                  enName: { type: "string", description: "英文名称" },
                  usageCn: { type: "string", description: "Usage cn TODO" },
                  forKid: { type: "boolean", description: "For kid TODO" },
                  stageList: {
                    type: "array",
                    items: { type: "string" },
                    description: "Stage list TODO"
                  },
                  provider: { type: "string", description: "产品品牌" },
                  expiryDate: {
                    description: "授权截止",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  category: {
                    type: "string",
                    required: false,
                    enum: ["brand", "platform"],
                    "x-i18n": { brand: "自研产品", platform: "授权产品" },
                    description: "类型"
                  },
                  productType: {
                    type: "string",
                    required: false,
                    enum: [
                      "group_training",
                      "elite_training",
                      "camp",
                      "free_training"
                    ],
                    "x-i18n": {
                      group_training: "团课",
                      elite_training: "私教",
                      camp: "训练营",
                      free_training: "自由训练"
                    },
                    description: "类型"
                  },
                  scheduleRule: {
                    type: "string",
                    description: "Schedule rule TODO"
                  },
                  entryPrice: {
                    type: "string",
                    description: "Entry price TODO"
                  },
                  active: { type: "boolean", description: "激活" }
                }
              },
              primaryCoach: {
                description: "教练",
                ref: "CoachCoachEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" }
                }
              },
              assistantCoaches: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                description: "助教",
                ref: "CoachCoachEssential"
              },
              zone: {
                description: "场地",
                ref: "StudioZoneList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "场地名称" },
                  position: { type: "number", description: "排序" },
                  useHrm: { type: "boolean", description: "使用心率" },
                  eliteTraining: { type: "boolean", description: "可上私教" },
                  tasExpiried: {
                    type: "boolean",
                    description: "Tas expiried TODO"
                  },
                  studio: {
                    description: "门店",
                    ref: "StudioStudioList",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  }
                }
              }
            }
          }
        }
      },
      trainingDays: { type: "number", description: "注册时长" },
      daysSinceLastTraining: { type: "number", description: "据上次训练天数" },
      trainingCount: { type: "number", description: "累积训练次数" },
      totalOrderAmount: { type: "string", description: "累积消费额" },
      communicationCount: { type: "number", description: "沟通次数" },
      parent: { description: "Parent TODO" },
      createdAt: { type: "string", description: "注册日期" },
      expiryInfo: { type: "string", description: "Expiry info TODO" }
    }
  },
  OperatingMemberExpiry: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", nullable: true, description: "姓名" },
      phone: { type: "string", nullable: true, description: "手机" },
      gender: { type: "string", description: "性别" },
      avatar: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "头像"
      },
      coachName: { type: "string", description: "Coach name TODO" },
      age: { type: "number", description: "年龄" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      firstBooking: {
        description: "首次训练",
        ref: "CourseBookingDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          member: {
            description: "会员",
            ref: "MemberMemberDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              age: { type: "number", description: "年龄" },
              weight: { type: "number", description: "体重" },
              height: { type: "number", description: "Height TODO" },
              maxHr: {
                type: "number",
                nullable: true,
                description: "最大心率"
              },
              parent: {
                description: "家长",
                ref: "MemberMemberEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "昵称" },
                  userId: { type: "number", description: "用户ID" },
                  realName: {
                    type: "string",
                    nullable: true,
                    description: "姓名"
                  },
                  phone: {
                    type: "string",
                    nullable: true,
                    description: "手机"
                  },
                  gender: {
                    type: "string",
                    required: false,
                    enum: ["male", "female"],
                    "x-i18n": { male: "男", female: "女" },
                    description: "性别"
                  },
                  avatar: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/AttachmentDefault" }
                    ],
                    description: "头像"
                  },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "标签"
                  },
                  memberType: { type: "string", description: "会员类型" }
                }
              },
              profilePhotos: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                description: "形象照",
                ref: "AttachmentDefault"
              },
              joinedHof: { type: "boolean", description: "加入名人堂" },
              joinedHofAt: {
                description: "加入名人堂时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              wechatLinked: {
                type: "boolean",
                description: "Wechat linked TODO"
              },
              hofNotes: { type: "string", description: "名人堂备注" },
              vip: { type: "boolean", description: "vip" },
              active: { type: "boolean", description: "激活" }
            }
          },
          workflowState: {
            type: "string",
            required: false,
            enum: [
              "new",
              "reserved",
              "queued",
              "checked_in",
              "absent",
              "cancelled"
            ],
            "x-i18n": {
              new: "未签到",
              reserved: "未支付",
              queued: "已排号",
              checked_in: "已签到",
              absent: "缺席",
              cancelled: "已取消"
            },
            description: "状态"
          },
          course: {
            description: "课程",
            ref: "CourseCourseDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              startTime: {
                description: "开始时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              name: { type: "string", description: "名称" },
              enName: { type: "string", description: "课程英文名称" },
              homework: { type: "string", description: "课后作业" },
              workflowState: {
                type: "string",
                description: "Workflow state TODO"
              },
              product: {
                description: "训练产品",
                ref: "CourseProductList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  brandId: { type: "number", description: "品牌" },
                  name: { type: "string", description: "课程名称" },
                  enName: { type: "string", description: "英文名称" },
                  usageCn: { type: "string", description: "Usage cn TODO" },
                  forKid: { type: "boolean", description: "For kid TODO" },
                  stageList: {
                    type: "array",
                    items: { type: "string" },
                    description: "Stage list TODO"
                  },
                  provider: { type: "string", description: "产品品牌" },
                  expiryDate: {
                    description: "授权截止",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  category: {
                    type: "string",
                    required: false,
                    enum: ["brand", "platform"],
                    "x-i18n": { brand: "自研产品", platform: "授权产品" },
                    description: "类型"
                  },
                  productType: {
                    type: "string",
                    required: false,
                    enum: [
                      "group_training",
                      "elite_training",
                      "camp",
                      "free_training"
                    ],
                    "x-i18n": {
                      group_training: "团课",
                      elite_training: "私教",
                      camp: "训练营",
                      free_training: "自由训练"
                    },
                    description: "类型"
                  },
                  scheduleRule: {
                    type: "string",
                    description: "Schedule rule TODO"
                  },
                  entryPrice: {
                    type: "string",
                    description: "Entry price TODO"
                  },
                  active: { type: "boolean", description: "激活" }
                }
              },
              primaryCoach: {
                description: "教练",
                ref: "CoachCoachEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" }
                }
              },
              assistantCoaches: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                description: "助教",
                ref: "CoachCoachEssential"
              },
              zone: {
                description: "场地",
                ref: "StudioZoneList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "场地名称" },
                  position: { type: "number", description: "排序" },
                  useHrm: { type: "boolean", description: "使用心率" },
                  eliteTraining: { type: "boolean", description: "可上私教" },
                  tasExpiried: {
                    type: "boolean",
                    description: "Tas expiried TODO"
                  },
                  studio: {
                    description: "门店",
                    ref: "StudioStudioList",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  }
                }
              }
            }
          }
        }
      },
      trainingDays: { type: "number", description: "注册时长" },
      daysSinceLastTraining: { type: "number", description: "据上次训练天数" },
      trainingCount: { type: "number", description: "累积训练次数" },
      totalOrderAmount: { type: "string", description: "累积消费额" },
      communicationCount: { type: "number", description: "沟通次数" },
      parent: { description: "Parent TODO" },
      createdAt: { type: "string", description: "注册日期" },
      expiryInfo: { type: "string", description: "Expiry info TODO" }
    }
  },
  OperatingMemberPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "昵称" },
            realName: { type: "string", nullable: true, description: "姓名" },
            phone: { type: "string", nullable: true, description: "手机" },
            gender: { type: "string", description: "性别" },
            avatar: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "头像"
            },
            coachName: { type: "string", description: "Coach name TODO" },
            age: { type: "number", description: "年龄" },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            firstBooking: {
              description: "首次训练",
              ref: "CourseBookingDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                member: {
                  description: "会员",
                  ref: "MemberMemberDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" },
                    dateOfBirth: {
                      description: "生日",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    age: { type: "number", description: "年龄" },
                    weight: { type: "number", description: "体重" },
                    height: { type: "number", description: "Height TODO" },
                    maxHr: {
                      type: "number",
                      nullable: true,
                      description: "最大心率"
                    },
                    parent: {
                      description: "家长",
                      ref: "MemberMemberEssential",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "昵称" },
                        userId: { type: "number", description: "用户ID" },
                        realName: {
                          type: "string",
                          nullable: true,
                          description: "姓名"
                        },
                        phone: {
                          type: "string",
                          nullable: true,
                          description: "手机"
                        },
                        gender: {
                          type: "string",
                          required: false,
                          enum: ["male", "female"],
                          "x-i18n": { male: "男", female: "女" },
                          description: "性别"
                        },
                        avatar: {
                          anyOf: [
                            { type: "null" },
                            { $ref: "#/definitions/AttachmentDefault" }
                          ],
                          description: "头像"
                        },
                        tagList: {
                          type: "array",
                          items: { type: "string" },
                          description: "标签"
                        },
                        memberType: { type: "string", description: "会员类型" }
                      }
                    },
                    profilePhotos: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      description: "形象照",
                      ref: "AttachmentDefault"
                    },
                    joinedHof: { type: "boolean", description: "加入名人堂" },
                    joinedHofAt: {
                      description: "加入名人堂时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    },
                    wechatLinked: {
                      type: "boolean",
                      description: "Wechat linked TODO"
                    },
                    hofNotes: { type: "string", description: "名人堂备注" },
                    vip: { type: "boolean", description: "vip" },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                workflowState: {
                  type: "string",
                  required: false,
                  enum: [
                    "new",
                    "reserved",
                    "queued",
                    "checked_in",
                    "absent",
                    "cancelled"
                  ],
                  "x-i18n": {
                    new: "未签到",
                    reserved: "未支付",
                    queued: "已排号",
                    checked_in: "已签到",
                    absent: "缺席",
                    cancelled: "已取消"
                  },
                  description: "状态"
                },
                course: {
                  description: "课程",
                  ref: "CourseCourseDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    startTime: {
                      description: "开始时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    },
                    name: { type: "string", description: "名称" },
                    enName: { type: "string", description: "课程英文名称" },
                    homework: { type: "string", description: "课后作业" },
                    workflowState: {
                      type: "string",
                      description: "Workflow state TODO"
                    },
                    product: {
                      description: "训练产品",
                      ref: "CourseProductList",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        brandId: { type: "number", description: "品牌" },
                        name: { type: "string", description: "课程名称" },
                        enName: { type: "string", description: "英文名称" },
                        usageCn: {
                          type: "string",
                          description: "Usage cn TODO"
                        },
                        forKid: {
                          type: "boolean",
                          description: "For kid TODO"
                        },
                        stageList: {
                          type: "array",
                          items: { type: "string" },
                          description: "Stage list TODO"
                        },
                        provider: { type: "string", description: "产品品牌" },
                        expiryDate: {
                          description: "授权截止",
                          ref: "date",
                          type: "string",
                          format: "date",
                          example: "2018-01-01",
                          properties: {}
                        },
                        category: {
                          type: "string",
                          required: false,
                          enum: ["brand", "platform"],
                          "x-i18n": { brand: "自研产品", platform: "授权产品" },
                          description: "类型"
                        },
                        productType: {
                          type: "string",
                          required: false,
                          enum: [
                            "group_training",
                            "elite_training",
                            "camp",
                            "free_training"
                          ],
                          "x-i18n": {
                            group_training: "团课",
                            elite_training: "私教",
                            camp: "训练营",
                            free_training: "自由训练"
                          },
                          description: "类型"
                        },
                        scheduleRule: {
                          type: "string",
                          description: "Schedule rule TODO"
                        },
                        entryPrice: {
                          type: "string",
                          description: "Entry price TODO"
                        },
                        active: { type: "boolean", description: "激活" }
                      }
                    },
                    primaryCoach: {
                      description: "教练",
                      ref: "CoachCoachEssential",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        phone: { type: "string", description: "手机号" },
                        avatar: {
                          description: "头像",
                          ref: "AttachmentDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            signedId: {
                              type: "string",
                              description: "Signed ID"
                            },
                            url: { type: "string", description: "URL" },
                            originalUrl: {
                              type: "string",
                              description: "原始文件 URL"
                            },
                            filename: { type: "string", description: "文件名" },
                            contentType: {
                              type: "string",
                              description: "文件类型"
                            }
                          }
                        },
                        name: { type: "string", description: "姓名" },
                        displayName: { type: "string", description: "昵称" },
                        note: { type: "string", description: "备注" },
                        active: { type: "boolean", description: "激活" },
                        certified: {
                          type: "boolean",
                          description: "Certified TODO"
                        }
                      }
                    },
                    assistantCoaches: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          phone: { type: "string", description: "手机号" },
                          avatar: {
                            description: "头像",
                            ref: "AttachmentDefault",
                            type: "object",
                            properties: {
                              id: { type: "number", description: "ID" },
                              signedId: {
                                type: "string",
                                description: "Signed ID"
                              },
                              url: { type: "string", description: "URL" },
                              originalUrl: {
                                type: "string",
                                description: "原始文件 URL"
                              },
                              filename: {
                                type: "string",
                                description: "文件名"
                              },
                              contentType: {
                                type: "string",
                                description: "文件类型"
                              }
                            }
                          },
                          name: { type: "string", description: "姓名" },
                          displayName: { type: "string", description: "昵称" },
                          note: { type: "string", description: "备注" },
                          active: { type: "boolean", description: "激活" },
                          certified: {
                            type: "boolean",
                            description: "Certified TODO"
                          }
                        }
                      },
                      description: "助教",
                      ref: "CoachCoachEssential"
                    },
                    zone: {
                      description: "场地",
                      ref: "StudioZoneList",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "场地名称" },
                        position: { type: "number", description: "排序" },
                        useHrm: { type: "boolean", description: "使用心率" },
                        eliteTraining: {
                          type: "boolean",
                          description: "可上私教"
                        },
                        tasExpiried: {
                          type: "boolean",
                          description: "Tas expiried TODO"
                        },
                        studio: {
                          description: "门店",
                          ref: "StudioStudioList",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" },
                            fullName: {
                              type: "string",
                              description: "门店全称"
                            },
                            shortName: {
                              type: "string",
                              description: "门店名称"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            trainingDays: { type: "number", description: "注册时长" },
            daysSinceLastTraining: {
              type: "number",
              description: "据上次训练天数"
            },
            trainingCount: { type: "number", description: "累积训练次数" },
            totalOrderAmount: { type: "string", description: "累积消费额" },
            communicationCount: { type: "number", description: "沟通次数" },
            parent: {
              description: "Parent TODO",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            createdAt: { type: "string", description: "注册日期" }
          }
        },
        ref: "OperatingMemberDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  OperatingBookingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "OperatingMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "昵称" },
          realName: { type: "string", nullable: true, description: "姓名" },
          phone: { type: "string", nullable: true, description: "手机" },
          gender: { type: "string", description: "性别" },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          coachName: { type: "string", description: "Coach name TODO" },
          age: { type: "number", description: "年龄" },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          firstBooking: {
            description: "首次训练",
            ref: "CourseBookingDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              member: {
                description: "会员",
                ref: "MemberMemberDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "昵称" },
                  userId: { type: "number", description: "用户ID" },
                  realName: {
                    type: "string",
                    nullable: true,
                    description: "姓名"
                  },
                  phone: {
                    type: "string",
                    nullable: true,
                    description: "手机"
                  },
                  gender: {
                    type: "string",
                    required: false,
                    enum: ["male", "female"],
                    "x-i18n": { male: "男", female: "女" },
                    description: "性别"
                  },
                  avatar: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/AttachmentDefault" }
                    ],
                    description: "头像"
                  },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "标签"
                  },
                  memberType: { type: "string", description: "会员类型" },
                  dateOfBirth: {
                    description: "生日",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  age: { type: "number", description: "年龄" },
                  weight: { type: "number", description: "体重" },
                  height: { type: "number", description: "Height TODO" },
                  maxHr: {
                    type: "number",
                    nullable: true,
                    description: "最大心率"
                  },
                  parent: {
                    description: "家长",
                    ref: "MemberMemberEssential",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "昵称" },
                      userId: { type: "number", description: "用户ID" },
                      realName: {
                        type: "string",
                        nullable: true,
                        description: "姓名"
                      },
                      phone: {
                        type: "string",
                        nullable: true,
                        description: "手机"
                      },
                      gender: {
                        type: "string",
                        required: false,
                        enum: ["male", "female"],
                        "x-i18n": { male: "男", female: "女" },
                        description: "性别"
                      },
                      avatar: {
                        anyOf: [
                          { type: "null" },
                          { $ref: "#/definitions/AttachmentDefault" }
                        ],
                        description: "头像"
                      },
                      tagList: {
                        type: "array",
                        items: { type: "string" },
                        description: "标签"
                      },
                      memberType: { type: "string", description: "会员类型" }
                    }
                  },
                  profilePhotos: {
                    type: "array",
                    items: {
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    description: "形象照",
                    ref: "AttachmentDefault"
                  },
                  joinedHof: { type: "boolean", description: "加入名人堂" },
                  joinedHofAt: {
                    description: "加入名人堂时间",
                    ref: "datetime",
                    type: "string",
                    format: "date-time",
                    example: "2018-08-01T00:00:00.000Z",
                    properties: {}
                  },
                  wechatLinked: {
                    type: "boolean",
                    description: "Wechat linked TODO"
                  },
                  hofNotes: { type: "string", description: "名人堂备注" },
                  vip: { type: "boolean", description: "vip" },
                  active: { type: "boolean", description: "激活" }
                }
              },
              workflowState: {
                type: "string",
                required: false,
                enum: [
                  "new",
                  "reserved",
                  "queued",
                  "checked_in",
                  "absent",
                  "cancelled"
                ],
                "x-i18n": {
                  new: "未签到",
                  reserved: "未支付",
                  queued: "已排号",
                  checked_in: "已签到",
                  absent: "缺席",
                  cancelled: "已取消"
                },
                description: "状态"
              },
              course: {
                description: "课程",
                ref: "CourseCourseDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  startTime: {
                    description: "开始时间",
                    ref: "datetime",
                    type: "string",
                    format: "date-time",
                    example: "2018-08-01T00:00:00.000Z",
                    properties: {}
                  },
                  name: { type: "string", description: "名称" },
                  enName: { type: "string", description: "课程英文名称" },
                  homework: { type: "string", description: "课后作业" },
                  workflowState: {
                    type: "string",
                    description: "Workflow state TODO"
                  },
                  product: {
                    description: "训练产品",
                    ref: "CourseProductList",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      brandId: { type: "number", description: "品牌" },
                      name: { type: "string", description: "课程名称" },
                      enName: { type: "string", description: "英文名称" },
                      usageCn: { type: "string", description: "Usage cn TODO" },
                      forKid: { type: "boolean", description: "For kid TODO" },
                      stageList: {
                        type: "array",
                        items: { type: "string" },
                        description: "Stage list TODO"
                      },
                      provider: { type: "string", description: "产品品牌" },
                      expiryDate: {
                        description: "授权截止",
                        ref: "date",
                        type: "string",
                        format: "date",
                        example: "2018-01-01",
                        properties: {}
                      },
                      category: {
                        type: "string",
                        required: false,
                        enum: ["brand", "platform"],
                        "x-i18n": { brand: "自研产品", platform: "授权产品" },
                        description: "类型"
                      },
                      productType: {
                        type: "string",
                        required: false,
                        enum: [
                          "group_training",
                          "elite_training",
                          "camp",
                          "free_training"
                        ],
                        "x-i18n": {
                          group_training: "团课",
                          elite_training: "私教",
                          camp: "训练营",
                          free_training: "自由训练"
                        },
                        description: "类型"
                      },
                      scheduleRule: {
                        type: "string",
                        description: "Schedule rule TODO"
                      },
                      entryPrice: {
                        type: "string",
                        description: "Entry price TODO"
                      },
                      active: { type: "boolean", description: "激活" }
                    }
                  },
                  primaryCoach: {
                    description: "教练",
                    ref: "CoachCoachEssential",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      phone: { type: "string", description: "手机号" },
                      avatar: {
                        description: "头像",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      name: { type: "string", description: "姓名" },
                      displayName: { type: "string", description: "昵称" },
                      note: { type: "string", description: "备注" },
                      active: { type: "boolean", description: "激活" },
                      certified: {
                        type: "boolean",
                        description: "Certified TODO"
                      }
                    }
                  },
                  assistantCoaches: {
                    type: "array",
                    items: {
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        phone: { type: "string", description: "手机号" },
                        avatar: {
                          description: "头像",
                          ref: "AttachmentDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            signedId: {
                              type: "string",
                              description: "Signed ID"
                            },
                            url: { type: "string", description: "URL" },
                            originalUrl: {
                              type: "string",
                              description: "原始文件 URL"
                            },
                            filename: { type: "string", description: "文件名" },
                            contentType: {
                              type: "string",
                              description: "文件类型"
                            }
                          }
                        },
                        name: { type: "string", description: "姓名" },
                        displayName: { type: "string", description: "昵称" },
                        note: { type: "string", description: "备注" },
                        active: { type: "boolean", description: "激活" },
                        certified: {
                          type: "boolean",
                          description: "Certified TODO"
                        }
                      }
                    },
                    description: "助教",
                    ref: "CoachCoachEssential"
                  },
                  zone: {
                    description: "场地",
                    ref: "StudioZoneList",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "场地名称" },
                      position: { type: "number", description: "排序" },
                      useHrm: { type: "boolean", description: "使用心率" },
                      eliteTraining: {
                        type: "boolean",
                        description: "可上私教"
                      },
                      tasExpiried: {
                        type: "boolean",
                        description: "Tas expiried TODO"
                      },
                      studio: {
                        description: "门店",
                        ref: "StudioStudioList",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" },
                          fullName: { type: "string", description: "门店全称" },
                          shortName: {
                            type: "string",
                            description: "门店名称"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          trainingDays: { type: "number", description: "注册时长" },
          daysSinceLastTraining: {
            type: "number",
            description: "据上次训练天数"
          },
          trainingCount: { type: "number", description: "累积训练次数" },
          totalOrderAmount: { type: "string", description: "累积消费额" },
          communicationCount: { type: "number", description: "沟通次数" },
          parent: {
            description: "Parent TODO",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          createdAt: { type: "string", description: "注册日期" }
        }
      },
      workflowState: { type: "string", description: "签到状态" },
      course: {
        description: "课程",
        ref: "CourseCourseEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          product: {
            description: "训练产品",
            ref: "CourseProductList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              brandId: { type: "number", description: "品牌" },
              name: { type: "string", description: "课程名称" },
              enName: { type: "string", description: "英文名称" },
              usageCn: { type: "string", description: "Usage cn TODO" },
              forKid: { type: "boolean", description: "For kid TODO" },
              stageList: {
                type: "array",
                items: { type: "string" },
                description: "Stage list TODO"
              },
              provider: { type: "string", description: "产品品牌" },
              expiryDate: {
                description: "授权截止",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              category: {
                type: "string",
                required: false,
                enum: ["brand", "platform"],
                "x-i18n": { brand: "自研产品", platform: "授权产品" },
                description: "类型"
              },
              productType: {
                type: "string",
                required: false,
                enum: [
                  "group_training",
                  "elite_training",
                  "camp",
                  "free_training"
                ],
                "x-i18n": {
                  group_training: "团课",
                  elite_training: "私教",
                  camp: "训练营",
                  free_training: "自由训练"
                },
                description: "类型"
              },
              scheduleRule: {
                type: "string",
                description: "Schedule rule TODO"
              },
              entryPrice: { type: "string", description: "Entry price TODO" },
              active: { type: "boolean", description: "激活" }
            }
          },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          primaryCoach: {
            description: "教练",
            ref: "CoachCoachEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" }
            }
          },
          zone: {
            description: "场地",
            ref: "StudioZoneList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "场地名称" },
              position: { type: "number", description: "排序" },
              useHrm: { type: "boolean", description: "使用心率" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              tasExpiried: {
                type: "boolean",
                description: "Tas expiried TODO"
              },
              studio: {
                description: "门店",
                ref: "StudioStudioList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" }
                }
              }
            }
          }
        }
      },
      consumeType: { type: "string", description: "消费方式" }
    }
  },
  OperatingBookingPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "会员",
              ref: "OperatingMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: { type: "string", description: "性别" },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                coachName: { type: "string", description: "Coach name TODO" },
                age: { type: "number", description: "年龄" },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                firstBooking: {
                  description: "首次训练",
                  ref: "CourseBookingDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    member: {
                      description: "会员",
                      ref: "MemberMemberDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "昵称" },
                        userId: { type: "number", description: "用户ID" },
                        realName: {
                          type: "string",
                          nullable: true,
                          description: "姓名"
                        },
                        phone: {
                          type: "string",
                          nullable: true,
                          description: "手机"
                        },
                        gender: {
                          type: "string",
                          required: false,
                          enum: ["male", "female"],
                          "x-i18n": { male: "男", female: "女" },
                          description: "性别"
                        },
                        avatar: {
                          anyOf: [
                            { type: "null" },
                            { $ref: "#/definitions/AttachmentDefault" }
                          ],
                          description: "头像"
                        },
                        tagList: {
                          type: "array",
                          items: { type: "string" },
                          description: "标签"
                        },
                        memberType: { type: "string", description: "会员类型" },
                        dateOfBirth: {
                          description: "生日",
                          ref: "date",
                          type: "string",
                          format: "date",
                          example: "2018-01-01",
                          properties: {}
                        },
                        age: { type: "number", description: "年龄" },
                        weight: { type: "number", description: "体重" },
                        height: { type: "number", description: "Height TODO" },
                        maxHr: {
                          type: "number",
                          nullable: true,
                          description: "最大心率"
                        },
                        parent: {
                          description: "家长",
                          ref: "MemberMemberEssential",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "昵称" },
                            userId: { type: "number", description: "用户ID" },
                            realName: {
                              type: "string",
                              nullable: true,
                              description: "姓名"
                            },
                            phone: {
                              type: "string",
                              nullable: true,
                              description: "手机"
                            },
                            gender: {
                              type: "string",
                              required: false,
                              enum: ["male", "female"],
                              "x-i18n": { male: "男", female: "女" },
                              description: "性别"
                            },
                            avatar: {
                              anyOf: [
                                { type: "null" },
                                { $ref: "#/definitions/AttachmentDefault" }
                              ],
                              description: "头像"
                            },
                            tagList: {
                              type: "array",
                              items: { type: "string" },
                              description: "标签"
                            },
                            memberType: {
                              type: "string",
                              description: "会员类型"
                            }
                          }
                        },
                        profilePhotos: {
                          type: "array",
                          items: {
                            type: "object",
                            properties: {
                              id: { type: "number", description: "ID" },
                              signedId: {
                                type: "string",
                                description: "Signed ID"
                              },
                              url: { type: "string", description: "URL" },
                              originalUrl: {
                                type: "string",
                                description: "原始文件 URL"
                              },
                              filename: {
                                type: "string",
                                description: "文件名"
                              },
                              contentType: {
                                type: "string",
                                description: "文件类型"
                              }
                            }
                          },
                          description: "形象照",
                          ref: "AttachmentDefault"
                        },
                        joinedHof: {
                          type: "boolean",
                          description: "加入名人堂"
                        },
                        joinedHofAt: {
                          description: "加入名人堂时间",
                          ref: "datetime",
                          type: "string",
                          format: "date-time",
                          example: "2018-08-01T00:00:00.000Z",
                          properties: {}
                        },
                        wechatLinked: {
                          type: "boolean",
                          description: "Wechat linked TODO"
                        },
                        hofNotes: { type: "string", description: "名人堂备注" },
                        vip: { type: "boolean", description: "vip" },
                        active: { type: "boolean", description: "激活" }
                      }
                    },
                    workflowState: {
                      type: "string",
                      required: false,
                      enum: [
                        "new",
                        "reserved",
                        "queued",
                        "checked_in",
                        "absent",
                        "cancelled"
                      ],
                      "x-i18n": {
                        new: "未签到",
                        reserved: "未支付",
                        queued: "已排号",
                        checked_in: "已签到",
                        absent: "缺席",
                        cancelled: "已取消"
                      },
                      description: "状态"
                    },
                    course: {
                      description: "课程",
                      ref: "CourseCourseDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        startTime: {
                          description: "开始时间",
                          ref: "datetime",
                          type: "string",
                          format: "date-time",
                          example: "2018-08-01T00:00:00.000Z",
                          properties: {}
                        },
                        name: { type: "string", description: "名称" },
                        enName: { type: "string", description: "课程英文名称" },
                        homework: { type: "string", description: "课后作业" },
                        workflowState: {
                          type: "string",
                          description: "Workflow state TODO"
                        },
                        product: {
                          description: "训练产品",
                          ref: "CourseProductList",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            brandId: { type: "number", description: "品牌" },
                            name: { type: "string", description: "课程名称" },
                            enName: { type: "string", description: "英文名称" },
                            usageCn: {
                              type: "string",
                              description: "Usage cn TODO"
                            },
                            forKid: {
                              type: "boolean",
                              description: "For kid TODO"
                            },
                            stageList: {
                              type: "array",
                              items: { type: "string" },
                              description: "Stage list TODO"
                            },
                            provider: {
                              type: "string",
                              description: "产品品牌"
                            },
                            expiryDate: {
                              description: "授权截止",
                              ref: "date",
                              type: "string",
                              format: "date",
                              example: "2018-01-01",
                              properties: {}
                            },
                            category: {
                              type: "string",
                              required: false,
                              enum: ["brand", "platform"],
                              "x-i18n": {
                                brand: "自研产品",
                                platform: "授权产品"
                              },
                              description: "类型"
                            },
                            productType: {
                              type: "string",
                              required: false,
                              enum: [
                                "group_training",
                                "elite_training",
                                "camp",
                                "free_training"
                              ],
                              "x-i18n": {
                                group_training: "团课",
                                elite_training: "私教",
                                camp: "训练营",
                                free_training: "自由训练"
                              },
                              description: "类型"
                            },
                            scheduleRule: {
                              type: "string",
                              description: "Schedule rule TODO"
                            },
                            entryPrice: {
                              type: "string",
                              description: "Entry price TODO"
                            },
                            active: { type: "boolean", description: "激活" }
                          }
                        },
                        primaryCoach: {
                          description: "教练",
                          ref: "CoachCoachEssential",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            phone: { type: "string", description: "手机号" },
                            avatar: {
                              description: "头像",
                              ref: "AttachmentDefault",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                signedId: {
                                  type: "string",
                                  description: "Signed ID"
                                },
                                url: { type: "string", description: "URL" },
                                originalUrl: {
                                  type: "string",
                                  description: "原始文件 URL"
                                },
                                filename: {
                                  type: "string",
                                  description: "文件名"
                                },
                                contentType: {
                                  type: "string",
                                  description: "文件类型"
                                }
                              }
                            },
                            name: { type: "string", description: "姓名" },
                            displayName: {
                              type: "string",
                              description: "昵称"
                            },
                            note: { type: "string", description: "备注" },
                            active: { type: "boolean", description: "激活" },
                            certified: {
                              type: "boolean",
                              description: "Certified TODO"
                            }
                          }
                        },
                        assistantCoaches: {
                          type: "array",
                          items: {
                            type: "object",
                            properties: {
                              id: { type: "number", description: "ID" },
                              phone: { type: "string", description: "手机号" },
                              avatar: {
                                description: "头像",
                                ref: "AttachmentDefault",
                                type: "object",
                                properties: {
                                  id: { type: "number", description: "ID" },
                                  signedId: {
                                    type: "string",
                                    description: "Signed ID"
                                  },
                                  url: { type: "string", description: "URL" },
                                  originalUrl: {
                                    type: "string",
                                    description: "原始文件 URL"
                                  },
                                  filename: {
                                    type: "string",
                                    description: "文件名"
                                  },
                                  contentType: {
                                    type: "string",
                                    description: "文件类型"
                                  }
                                }
                              },
                              name: { type: "string", description: "姓名" },
                              displayName: {
                                type: "string",
                                description: "昵称"
                              },
                              note: { type: "string", description: "备注" },
                              active: { type: "boolean", description: "激活" },
                              certified: {
                                type: "boolean",
                                description: "Certified TODO"
                              }
                            }
                          },
                          description: "助教",
                          ref: "CoachCoachEssential"
                        },
                        zone: {
                          description: "场地",
                          ref: "StudioZoneList",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "场地名称" },
                            position: { type: "number", description: "排序" },
                            useHrm: {
                              type: "boolean",
                              description: "使用心率"
                            },
                            eliteTraining: {
                              type: "boolean",
                              description: "可上私教"
                            },
                            tasExpiried: {
                              type: "boolean",
                              description: "Tas expiried TODO"
                            },
                            studio: {
                              description: "门店",
                              ref: "StudioStudioList",
                              type: "object",
                              properties: {
                                id: { type: "number", description: "ID" },
                                name: { type: "string", description: "名称" },
                                fullName: {
                                  type: "string",
                                  description: "门店全称"
                                },
                                shortName: {
                                  type: "string",
                                  description: "门店名称"
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                trainingDays: { type: "number", description: "注册时长" },
                daysSinceLastTraining: {
                  type: "number",
                  description: "据上次训练天数"
                },
                trainingCount: { type: "number", description: "累积训练次数" },
                totalOrderAmount: { type: "string", description: "累积消费额" },
                communicationCount: { type: "number", description: "沟通次数" },
                parent: {
                  description: "Parent TODO",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                createdAt: { type: "string", description: "注册日期" }
              }
            },
            workflowState: { type: "string", description: "签到状态" },
            course: {
              description: "课程",
              ref: "CourseCourseEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                product: {
                  description: "训练产品",
                  ref: "CourseProductList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    brandId: { type: "number", description: "品牌" },
                    name: { type: "string", description: "课程名称" },
                    enName: { type: "string", description: "英文名称" },
                    usageCn: { type: "string", description: "Usage cn TODO" },
                    forKid: { type: "boolean", description: "For kid TODO" },
                    stageList: {
                      type: "array",
                      items: { type: "string" },
                      description: "Stage list TODO"
                    },
                    provider: { type: "string", description: "产品品牌" },
                    expiryDate: {
                      description: "授权截止",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    category: {
                      type: "string",
                      required: false,
                      enum: ["brand", "platform"],
                      "x-i18n": { brand: "自研产品", platform: "授权产品" },
                      description: "类型"
                    },
                    productType: {
                      type: "string",
                      required: false,
                      enum: [
                        "group_training",
                        "elite_training",
                        "camp",
                        "free_training"
                      ],
                      "x-i18n": {
                        group_training: "团课",
                        elite_training: "私教",
                        camp: "训练营",
                        free_training: "自由训练"
                      },
                      description: "类型"
                    },
                    scheduleRule: {
                      type: "string",
                      description: "Schedule rule TODO"
                    },
                    entryPrice: {
                      type: "string",
                      description: "Entry price TODO"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                zone: {
                  description: "场地",
                  ref: "StudioZoneList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "场地名称" },
                    position: { type: "number", description: "排序" },
                    useHrm: { type: "boolean", description: "使用心率" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    tasExpiried: {
                      type: "boolean",
                      description: "Tas expiried TODO"
                    },
                    studio: {
                      description: "门店",
                      ref: "StudioStudioList",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        fullName: { type: "string", description: "门店全称" },
                        shortName: { type: "string", description: "门店名称" }
                      }
                    }
                  }
                }
              }
            },
            consumeType: { type: "string", description: "消费方式" }
          }
        },
        ref: "OperatingBookingDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MilestoneDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id" },
      coach: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CoachCoachEssential" }
        ],
        description: "教练"
      },
      course: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CourseCourseEssential" }
        ],
        description: "课程"
      },
      member: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MemberMemberEssential" }
        ],
        description: "会员"
      },
      time: {
        description: "时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      eventType: { type: "string", description: "类型" },
      value: { type: "number", description: "Value TODO" },
      qrcode: {
        description: "Qrcode TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      text: { type: "string", description: "内容" },
      tasScreenText: { type: "string", description: "Tas screen text TODO" }
    }
  },
  MilestoneTas: {
    type: "object",
    properties: {
      coach: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CoachCoachEssential" }
        ],
        description: "教练"
      },
      course: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CourseCourseEssential" }
        ],
        description: "课程"
      },
      member: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MemberMemberEssential" }
        ],
        description: "会员"
      },
      eventType: { type: "string", description: "类型" },
      tasScreenText: { type: "string", description: "Tas screen text TODO" }
    }
  },
  MilestonePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id" },
            coach: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/CoachCoachEssential" }
              ],
              description: "教练"
            },
            course: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/CourseCourseEssential" }
              ],
              description: "课程"
            },
            member: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/MemberMemberEssential" }
              ],
              description: "会员"
            },
            time: {
              description: "时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            eventType: { type: "string", description: "类型" },
            value: { type: "number", description: "Value TODO" },
            qrcode: {
              description: "Qrcode TODO",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            text: { type: "string", description: "内容" },
            tasScreenText: {
              type: "string",
              description: "Tas screen text TODO"
            }
          }
        },
        ref: "MilestoneDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  StudioBlockPagedArrayBrief: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "编号" },
            blockTypeName: { type: "string", description: "名称" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "StudioBlockBrief"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  StudioHrSensorPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            label: { type: "string", description: "标识" },
            zone: {
              description: "场地",
              ref: "StudioZoneList",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "场地名称" },
                position: { type: "number", description: "排序" },
                useHrm: { type: "boolean", description: "使用心率" },
                eliteTraining: { type: "boolean", description: "可上私教" },
                tasExpiried: {
                  type: "boolean",
                  description: "Tas expiried TODO"
                },
                studio: {
                  description: "门店",
                  ref: "StudioStudioList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" }
                  }
                }
              }
            },
            antId: { type: "number", description: "Ant ID" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "StudioHrSensorDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  AttachmentPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        ref: "AttachmentDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  StudioStudioPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            fullName: { type: "string", description: "门店全称" },
            shortName: { type: "string", description: "门店名称" },
            active: { type: "boolean", description: "激活" },
            eliteTraining: { type: "boolean", description: "可上私教" },
            display: { type: "boolean", description: "展示" },
            address: { type: "string", description: "地址" },
            contactPhone: { type: "string", description: "联系电话" },
            longitude: { type: "string", description: "经度" },
            latitude: { type: "string", description: "维度" },
            gatePassword: { type: "string", description: "门禁密码" },
            businessOpenTime: { type: "string", description: "门店开门时间" },
            businessCloseTime: { type: "string", description: "门店关门时间" },
            traffic: {
              type: "string",
              nullable: true,
              description: "交通指引"
            },
            gateMacs: {
              type: "array",
              items: { type: "string" },
              description: "门禁MAC地址"
            },
            checkInQrCode: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "签到二维码"
            },
            openGateQrCodes: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "开门二维码"
            },
            coach: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/CoachCoachEssential" }
              ],
              description: "店长"
            },
            primaryImage: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "主图"
            },
            images: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "图片",
              ref: "AttachmentDefault"
            },
            checkInUrl: { type: "string", description: "签到URL" },
            adsUrl: { type: "string", description: "广告URL" },
            gateStatus: {
              type: "array",
              items: { type: "boolean" },
              description: "门禁状态"
            }
          }
        },
        ref: "StudioStudioDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  StudioZonePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "场地名称" },
            screenNum: {
              type: "string",
              required: true,
              enum: ["1", "2", "3", "6"],
              "x-i18n": {
                "1": "单屏幕",
                "2": "双屏幕",
                "3": "三屏幕",
                "6": "六屏幕"
              },
              description: "屏幕数量"
            },
            bgmBlockNo: { type: "string", description: "BGM主机编号" },
            hrmUrl: {
              type: "string",
              nullable: true,
              description: "心率系统 URL"
            },
            useHrm: { type: "boolean", description: "使用心率" },
            hrmDeviceNumber: { type: "string", description: "心率设备编号" },
            blocks: {
              type: "object",
              nullable: true,
              description: "Blocks TODO"
            },
            maxCapacity: {
              type: "number",
              nullable: true,
              description: "最大容量"
            },
            positionSelectable: { type: "boolean", description: "可以选位" },
            position: { type: "number", description: "排序" },
            eliteTraining: { type: "boolean", description: "可上私教" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "StudioZoneDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  StudioZonePagedArrayList: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "场地名称" },
            position: { type: "number", description: "排序" },
            useHrm: { type: "boolean", description: "使用心率" },
            eliteTraining: { type: "boolean", description: "可上私教" },
            tasExpiried: { type: "boolean", description: "Tas expiried TODO" },
            studio: {
              description: "门店",
              ref: "StudioStudioList",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" }
              }
            }
          }
        },
        ref: "StudioZoneList"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  PlatformTasExercisePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "编号" },
            name: { type: "string", description: "名称" },
            brandId: { type: "number", description: "Brand TODO" },
            mp4: {
              description: "视频",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            coverPicture: {
              description: "封面图",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            presentPicture: {
              description: "缩略图",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            command: {
              description: "口令",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            positionList: {
              type: "array",
              required: false,
              items: {
                type: "string",
                enum: [
                  "travelling",
                  "standing",
                  "sitting",
                  "kneeling",
                  "prone",
                  "supine",
                  "side lying",
                  "support hold",
                  "jump",
                  "hinged",
                  "Quadruped",
                  "other"
                ],
                "x-i18n": {
                  travelling: "移动",
                  standing: "站姿",
                  sitting: "坐姿",
                  kneeling: "跪姿",
                  prone: "俯卧",
                  supine: "仰卧",
                  "side lying": "侧卧",
                  "support hold": "支撑",
                  jump: "跳跃",
                  hinged: "髋屈伸",
                  Quadruped: "四足支撑",
                  other: "其他"
                }
              },
              description: "姿态"
            },
            primaryEquipment: {
              description: "主器械",
              ref: "TasEquipmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                enName: { type: "string", description: "En name TODO" },
                code: { type: "string", description: "Code TODO" }
              }
            },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "PlatformTasExerciseDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasPackageEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      name: { type: "string", description: "团课包名称" },
      desc: { type: "object", description: "描述" },
      schedulableOutlinesCount: { type: "number", description: "训练流数量" },
      active: { type: "boolean", description: "激活" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  TasPackageDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "编号" },
      name: { type: "string", description: "团课包名称" },
      desc: { type: "object", description: "描述" },
      schedulableOutlinesCount: { type: "number", description: "训练流数量" },
      active: { type: "boolean", description: "激活" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      workoutId: { type: "number", description: "Workout TODO" },
      workoutVersionId: { type: "number", description: "Workout version TODO" }
    }
  },
  TasPackageForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "团课包名称" },
      workoutVersionId: { type: "number", description: "Workout version TODO" },
      desc: { type: "object", description: "描述" }
    }
  },
  TasPackagePagedArrayEssential: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "编号" },
            name: { type: "string", description: "团课包名称" },
            desc: { type: "object", description: "描述" },
            schedulableOutlinesCount: {
              type: "number",
              description: "训练流数量"
            },
            active: { type: "boolean", description: "激活" },
            createdAt: {
              description: "创建时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "TasPackageEssential"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasWorkoutOutlinePagedArrayEssential: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "编号" },
            workflowState: {
              type: "string",
              required: false,
              enum: ["new", "certificated", "test", "abandoned"],
              "x-i18n": {
                new: "未认证",
                certificated: "已认证",
                test: "测试",
                abandoned: "未认证"
              },
              description: "状态"
            },
            brandId: { type: "number", description: "Brand TODO" },
            circuitOutline: { type: "boolean", description: "循环训练" },
            workoutVersion: {
              description: "课程版本",
              ref: "TasWorkoutVersionEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "版本名称" },
                courseDuration: { type: "number", description: "课程时长" },
                workflowState: { type: "string", description: "状态" },
                note: { type: "object", description: "备注" },
                createdAt: {
                  description: "创建时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                }
              }
            },
            createdAt: {
              description: "创建时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            active: { type: "boolean", description: "Active TODO" },
            note: { type: "object", description: "名称" }
          }
        },
        ref: "TasWorkoutOutlineEssential"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasWorkoutOutlinePagedArrayList: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "编号" },
            duration: { type: "number", description: "Duration TODO" },
            instruction: { type: "string", description: "说明" },
            packageSerial: {
              type: "string",
              description: "Package serial TODO"
            },
            packageName: { type: "string", description: "Package name TODO" },
            note: { type: "object", description: "名称" }
          }
        },
        ref: "TasWorkoutOutlineList"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasFrameTas: {
    type: "object",
    properties: {
      duration: { type: "number", description: "时长" },
      offset: { type: "number", description: "Offset TODO" },
      viewType: { type: "string", description: "View type TODO" },
      viewTypeCn: { type: "string", description: "View type cn TODO" },
      sourceType: { type: "string", description: "Source type TODO" },
      sourceIds: {
        type: "array",
        items: { type: "number" },
        description: "Source ids TODO"
      },
      sourceAttribute: { type: "string", description: "Source attribute TODO" },
      stationNo: { type: "string", description: "Station no TODO" },
      screenInstruction: {
        type: "string",
        description: "Screen instruction TODO"
      },
      stationNos: {
        type: "array",
        items: { type: "string" },
        description: "Station nos TODO"
      },
      bigCircleIndex: { type: "number", description: "Big circle index TODO" },
      beep: { type: "boolean", description: "Beep TODO" },
      playCommand: { type: "boolean", description: "Play command TODO" },
      remains: { type: "number", description: "Remains TODO" },
      trainingIndex: { type: "number", description: "Training index TODO" },
      totalTraining: { type: "number", description: "Total training TODO" },
      exerciseIndex: { type: "number", description: "Exercise index TODO" },
      volume: { type: "number", description: "Volume TODO" },
      totalExercises: { type: "number", description: "Total exercises TODO" },
      bgmTags: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tags TODO"
      },
      showNextExercise: {
        type: "boolean",
        description: "Show next exercise TODO"
      },
      timelines: {
        type: "array",
        items: { type: "object" },
        description: "Timelines TODO"
      },
      images: { type: "array", items: { type: "string" }, description: "图片" }
    }
  },
  TasTimelineTas: {
    type: "object",
    properties: {
      totalDuration: { type: "number", description: "Total duration TODO" },
      weight: { type: "number", description: "体重" },
      resultType: { type: "string", description: "Result type TODO" },
      frames: {
        type: "array",
        items: {
          type: "object",
          properties: {
            duration: { type: "number", description: "时长" },
            offset: { type: "number", description: "Offset TODO" },
            viewType: { type: "string", description: "View type TODO" },
            viewTypeCn: { type: "string", description: "View type cn TODO" },
            sourceType: { type: "string", description: "Source type TODO" },
            sourceIds: {
              type: "array",
              items: { type: "number" },
              description: "Source ids TODO"
            },
            sourceAttribute: {
              type: "string",
              description: "Source attribute TODO"
            },
            stationNo: { type: "string", description: "Station no TODO" },
            screenInstruction: {
              type: "string",
              description: "Screen instruction TODO"
            },
            stationNos: {
              type: "array",
              items: { type: "string" },
              description: "Station nos TODO"
            },
            bigCircleIndex: {
              type: "number",
              description: "Big circle index TODO"
            },
            beep: { type: "boolean", description: "Beep TODO" },
            playCommand: { type: "boolean", description: "Play command TODO" },
            remains: { type: "number", description: "Remains TODO" },
            trainingIndex: {
              type: "number",
              description: "Training index TODO"
            },
            totalTraining: {
              type: "number",
              description: "Total training TODO"
            },
            exerciseIndex: {
              type: "number",
              description: "Exercise index TODO"
            },
            volume: { type: "number", description: "Volume TODO" },
            totalExercises: {
              type: "number",
              description: "Total exercises TODO"
            },
            bgmTags: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tags TODO"
            },
            showNextExercise: {
              type: "boolean",
              description: "Show next exercise TODO"
            },
            timelines: {
              type: "array",
              items: { type: "object" },
              description: "Timelines TODO"
            },
            images: {
              type: "array",
              items: { type: "string" },
              description: "图片"
            }
          }
        },
        description: "Frames TODO",
        ref: "TasFrameTas"
      },
      outline: {
        description: "Outline TODO",
        ref: "TasWorkoutOutlineTas",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          serial: { type: "string", description: "编号" },
          workflowState: {
            type: "string",
            required: false,
            enum: ["new", "certificated", "test", "abandoned"],
            "x-i18n": {
              new: "未认证",
              certificated: "已认证",
              test: "测试",
              abandoned: "未认证"
            },
            description: "状态"
          },
          brandId: { type: "number", description: "Brand TODO" },
          circuitOutline: { type: "boolean", description: "循环训练" },
          workoutVersion: {
            description: "课程版本",
            ref: "TasWorkoutVersionEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "版本名称" },
              courseDuration: { type: "number", description: "课程时长" },
              workflowState: { type: "string", description: "状态" },
              note: { type: "object", description: "备注" },
              createdAt: {
                description: "创建时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              }
            }
          },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          active: { type: "boolean", description: "Active TODO" },
          note: { type: "object", description: "名称" },
          bgmFade: { type: "boolean", description: "音乐淡入淡出" },
          playlistStrategy: {
            type: "string",
            description: "Playlist strategy TODO"
          },
          instruction: { type: "object", description: "说明" },
          workoutVersionId: {
            type: "number",
            description: "Workout version TODO"
          },
          packageId: { type: "number", description: "Package TODO" },
          duration: { type: "number", description: "Duration TODO" },
          essentialExercises: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                serial: { type: "string", description: "Serial TODO" },
                name: { type: "string", description: "Name TODO" },
                stationNo: { type: "string", description: "Station no TODO" },
                tips: { type: "object", description: "Tips TODO" },
                cnCoachingCues: {
                  type: "object",
                  description: "Cn coaching cues TODO"
                },
                notGuided: { type: "boolean", description: "Not guided TODO" },
                command: { type: "string", description: "Command TODO" },
                mp4: { type: "string", description: "Mp4 TODO" },
                coverPicture: {
                  type: "string",
                  description: "Cover picture TODO"
                },
                presentPicture: {
                  type: "string",
                  description: "Present picture TODO"
                },
                repeat: { type: "number", description: "Repeat TODO" },
                lor: { type: "string", description: "Lor TODO" },
                instruction: {
                  type: "object",
                  description: "Instruction TODO"
                }
              }
            },
            description: "Essential exercises TODO",
            ref: "TasExerciseTas"
          }
        }
      },
      useHrm: { type: "boolean", description: "使用心率" }
    }
  },
  TasTimelineWithOutlineDuration: {
    type: "object",
    properties: {
      totalDuration: { type: "number", description: "Total duration TODO" },
      weight: { type: "number", description: "体重" },
      resultType: { type: "string", description: "Result type TODO" },
      frames: {
        type: "array",
        items: {
          type: "object",
          properties: {
            duration: { type: "number", description: "时长" },
            offset: { type: "number", description: "Offset TODO" },
            viewType: { type: "string", description: "View type TODO" },
            viewTypeCn: { type: "string", description: "View type cn TODO" },
            sourceType: { type: "string", description: "Source type TODO" },
            sourceIds: {
              type: "array",
              items: { type: "number" },
              description: "Source ids TODO"
            },
            sourceAttribute: {
              type: "string",
              description: "Source attribute TODO"
            },
            stationNo: { type: "string", description: "Station no TODO" },
            screenInstruction: {
              type: "string",
              description: "Screen instruction TODO"
            },
            stationNos: {
              type: "array",
              items: { type: "string" },
              description: "Station nos TODO"
            },
            bigCircleIndex: {
              type: "number",
              description: "Big circle index TODO"
            },
            beep: { type: "boolean", description: "Beep TODO" },
            playCommand: { type: "boolean", description: "Play command TODO" },
            remains: { type: "number", description: "Remains TODO" },
            trainingIndex: {
              type: "number",
              description: "Training index TODO"
            },
            totalTraining: {
              type: "number",
              description: "Total training TODO"
            },
            exerciseIndex: {
              type: "number",
              description: "Exercise index TODO"
            },
            volume: { type: "number", description: "Volume TODO" },
            totalExercises: {
              type: "number",
              description: "Total exercises TODO"
            },
            bgmTags: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tags TODO"
            },
            showNextExercise: {
              type: "boolean",
              description: "Show next exercise TODO"
            },
            timelines: {
              type: "array",
              items: { type: "object" },
              description: "Timelines TODO"
            },
            images: {
              type: "array",
              items: { type: "string" },
              description: "图片"
            }
          }
        },
        description: "Frames TODO",
        ref: "TasFrameTas"
      },
      outline: {
        description: "Outline TODO",
        ref: "TasWorkoutOutlineTas",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          serial: { type: "string", description: "编号" },
          workflowState: {
            type: "string",
            required: false,
            enum: ["new", "certificated", "test", "abandoned"],
            "x-i18n": {
              new: "未认证",
              certificated: "已认证",
              test: "测试",
              abandoned: "未认证"
            },
            description: "状态"
          },
          brandId: { type: "number", description: "Brand TODO" },
          circuitOutline: { type: "boolean", description: "循环训练" },
          workoutVersion: {
            description: "课程版本",
            ref: "TasWorkoutVersionEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "版本名称" },
              courseDuration: { type: "number", description: "课程时长" },
              workflowState: { type: "string", description: "状态" },
              note: { type: "object", description: "备注" },
              createdAt: {
                description: "创建时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              }
            }
          },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          active: { type: "boolean", description: "Active TODO" },
          note: { type: "object", description: "名称" },
          bgmFade: { type: "boolean", description: "音乐淡入淡出" },
          playlistStrategy: {
            type: "string",
            description: "Playlist strategy TODO"
          },
          instruction: { type: "object", description: "说明" },
          workoutVersionId: {
            type: "number",
            description: "Workout version TODO"
          },
          packageId: { type: "number", description: "Package TODO" },
          duration: { type: "number", description: "Duration TODO" },
          essentialExercises: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                serial: { type: "string", description: "Serial TODO" },
                name: { type: "string", description: "Name TODO" },
                stationNo: { type: "string", description: "Station no TODO" },
                tips: { type: "object", description: "Tips TODO" },
                cnCoachingCues: {
                  type: "object",
                  description: "Cn coaching cues TODO"
                },
                notGuided: { type: "boolean", description: "Not guided TODO" },
                command: { type: "string", description: "Command TODO" },
                mp4: { type: "string", description: "Mp4 TODO" },
                coverPicture: {
                  type: "string",
                  description: "Cover picture TODO"
                },
                presentPicture: {
                  type: "string",
                  description: "Present picture TODO"
                },
                repeat: { type: "number", description: "Repeat TODO" },
                lor: { type: "string", description: "Lor TODO" },
                instruction: {
                  type: "object",
                  description: "Instruction TODO"
                }
              }
            },
            description: "Essential exercises TODO",
            ref: "TasExerciseTas"
          }
        }
      },
      useHrm: { type: "boolean", description: "使用心率" },
      outlineDuration: { type: "number", description: "Outline duration TODO" }
    }
  },
  CoachApiBookingEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      gender: { type: "string", description: "性别" },
      age: { type: "number", description: "年龄" },
      weight: { type: "number", description: "体重" },
      name: { type: "string", description: "名称" },
      realName: { type: "string", description: "Real name TODO" },
      memberId: { type: "number", description: "会员" },
      effectivePaidForCoach: {
        type: "boolean",
        description: "Effective paid for 项目"
      },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      phone: { type: "string", nullable: true, description: "手机" },
      tags: { type: "array", items: { type: "string" }, description: "标签" },
      workflowState: { type: "string", description: "状态" },
      cancelledAt: {
        description: "Cancelled at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      sensor: {
        description: "心率带",
        ref: "StudioHrSensorDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          label: { type: "string", description: "标识" },
          zone: {
            description: "场地",
            ref: "StudioZoneList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "场地名称" },
              position: { type: "number", description: "排序" },
              useHrm: { type: "boolean", description: "使用心率" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              tasExpiried: {
                type: "boolean",
                description: "Tas expiried TODO"
              },
              studio: {
                description: "门店",
                ref: "StudioStudioList",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" }
                }
              }
            }
          },
          antId: { type: "number", description: "Ant ID" },
          active: { type: "boolean", description: "激活" }
        }
      },
      position: { type: "number", description: "Position TODO" },
      consumeType: { type: "string", description: "消费方式" },
      checkedBookingsNumberAtThisWorkout: {
        type: "number",
        description: "该训练产品打卡次数"
      }
    }
  },
  CoachApiMemberDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      phone: { type: "string", nullable: true, description: "手机" },
      avatar: {
        description: "头像",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      age: { type: "number", description: "年龄" },
      gender: { type: "string", description: "性别" },
      weight: { type: "number", description: "体重" },
      tags: { type: "array", items: { type: "string" }, description: "标签" },
      lastestBooking: {
        type: "object",
        nullable: true,
        description: "最近训练"
      },
      totalPurchaseAmount: { type: "string", description: "总消费金额" },
      daysSinceFirstTraining: { type: "number", description: "距首次训练天数" },
      trainingCount: { type: "number", description: "训练数" },
      communications: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            content: { type: "string", description: "内容" },
            actor: {
              description: "操作人",
              ref: "ActorDefault",
              type: "object",
              properties: {
                userId: { type: "number", description: "用户" },
                roleType: { type: "string", description: "角色" },
                name: { type: "string", description: "名称" }
              }
            },
            createdAt: {
              description: "Created at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            read: { type: "boolean", description: "已读" }
          }
        },
        description: "沟通记录",
        ref: "MemberCommunicationDefault"
      }
    }
  },
  CoachApiBookingDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "CoachApiMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          phone: { type: "string", nullable: true, description: "手机" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          age: { type: "number", description: "年龄" },
          gender: { type: "string", description: "性别" },
          weight: { type: "number", description: "体重" },
          tags: {
            type: "array",
            items: { type: "string" },
            description: "标签"
          },
          lastestBooking: {
            type: "object",
            nullable: true,
            description: "最近训练"
          },
          totalPurchaseAmount: { type: "string", description: "总消费金额" },
          daysSinceFirstTraining: {
            type: "number",
            description: "距首次训练天数"
          },
          trainingCount: { type: "number", description: "训练数" },
          communications: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                content: { type: "string", description: "内容" },
                actor: {
                  description: "操作人",
                  ref: "ActorDefault",
                  type: "object",
                  properties: {
                    userId: { type: "number", description: "用户" },
                    roleType: { type: "string", description: "角色" },
                    name: { type: "string", description: "名称" }
                  }
                },
                createdAt: {
                  description: "Created at TODO",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                read: { type: "boolean", description: "已读" }
              }
            },
            description: "沟通记录",
            ref: "MemberCommunicationDefault"
          }
        }
      },
      effectivePaidForCoach: {
        type: "boolean",
        description: "Effective paid for 项目"
      },
      consumeType: { type: "string", description: "消费方式" },
      membershipCardName: { type: "string", description: "会员卡名称" },
      trainingsInTheLast30Days: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            course: {
              description: "课程",
              ref: "MobileCourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                courseName: { type: "string", description: "课程名称" },
                enCourseName: { type: "string", description: "英文课程名称" },
                zone: { description: "Zone TODO" },
                studio: {
                  description: "门店",
                  ref: "StudioStudioEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" },
                    active: { type: "boolean", description: "激活" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    display: { type: "boolean", description: "展示" },
                    address: { type: "string", description: "地址" },
                    contactPhone: { type: "string", description: "联系电话" },
                    longitude: { type: "string", description: "经度" },
                    latitude: { type: "string", description: "维度" },
                    gatePassword: { type: "string", description: "门禁密码" }
                  }
                },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                endTime: {
                  description: "结束时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    },
                    user: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/UserDefault" }
                      ],
                      description: "用户"
                    },
                    userId: { type: "number", description: "用户" },
                    profilePhoto: {
                      description: "形象照",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    wxQrCode: {
                      description: "微信二维码",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    level: {
                      description: "等级",
                      ref: "CoachLevelDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        category: {
                          description: "类型",
                          ref: "CoachCategoryDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" }
                          }
                        },
                        categoryId: { type: "number", description: "类型" }
                      }
                    },
                    dateOfBirth: {
                      description: "生日",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    joinedAt: {
                      description: "入职时间",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    primaryStudio: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/StudioStudioList" }
                      ],
                      description: "绑定门店"
                    },
                    scopeOfAccess: {
                      type: "string",
                      required: true,
                      enum: ["mine", "all"],
                      "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                      description: "查看排课范围"
                    },
                    introduction: { type: "string", description: "介绍" },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "教练风格"
                    },
                    certificationList: {
                      type: "array",
                      items: { type: "string" },
                      description: "技能认证"
                    },
                    styleList: {
                      type: "array",
                      items: { type: "string" },
                      description: "执教风格"
                    },
                    resumeDisplay: {
                      type: "boolean",
                      description: "执教数据展示"
                    },
                    eliteTrainingCapacity: {
                      type: "number",
                      description: "私教人数上限"
                    },
                    primaryStudioId: {
                      type: "number",
                      description: "绑定门店"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "授权所有训练产品"
                    },
                    levelId: { type: "number", description: "等级" },
                    createdAt: {
                      description: "加入时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    }
                  }
                },
                capacityState: { type: "string", description: "容量状态" },
                capacity: { type: "number", description: "容量" },
                bookingCount: { type: "number", description: "预约人数" },
                tags: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                productTags: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品标签"
                },
                productStages: {
                  type: "array",
                  items: { type: "string" },
                  description: "Product stages TODO"
                },
                productType: {
                  type: "string",
                  description: "Product type TODO"
                },
                paymentMethods: {
                  type: "array",
                  items: { type: "string" },
                  description: "Payment methods TODO"
                },
                bookState: { type: "string", description: "预约状态" },
                bookingId: { type: "number", description: "Booking TODO" },
                booking: {
                  description: "Booking TODO",
                  ref: "MobileCourseBookingGift",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    workflowState: { type: "string", description: "状态" },
                    isOwner: { type: "boolean", description: "所有人" },
                    giftState: { type: "string", description: "赠课状态" },
                    token: { type: "string", description: "token" }
                  }
                },
                campId: {
                  type: "number",
                  nullable: true,
                  description: "训练营"
                },
                price: { type: "string", description: "课程价格" },
                prepayPrice: {
                  type: "string",
                  description: "Prepay price TODO"
                },
                positionSelectable: {
                  type: "boolean",
                  description: "可以选位"
                },
                multipleCoach: {
                  type: "boolean",
                  description: "Multiple 项目"
                },
                eliteTrainingMember: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/MobileMemberDefault" }
                  ],
                  description: "Elite training member TODO"
                },
                banner: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头图"
                },
                homework: { type: "string", description: "Homework TODO" },
                confirmCourseThreshold: {
                  type: "number",
                  description: "Confirm course threshold TODO"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "Confirm course deadline TODO"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "Has confirm course threshold TODO"
                },
                useHrm: { type: "boolean", description: "使用心率" }
              }
            },
            member: {
              description: "会员",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            owner: {
              description: "所有人",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            primaryCoachName: { type: "string", description: "教练" },
            studioName: { type: "string", description: "门店" },
            courseDuration: { type: "number", description: "课程时长" },
            hrSensor: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/StudioHrSensorDefault" }
              ],
              description: "心率带"
            },
            workflowState: { type: "string", description: "状态" },
            trainingCount: { type: "number", description: "训练数" },
            cancellable: { type: "boolean", description: "可以取消" },
            cancellationDeadline: { type: "number", description: "取消时间" },
            queuePosition: {
              type: "number",
              nullable: true,
              description: "排位顺序"
            },
            position: { type: "number", nullable: true, description: "位置" },
            coachComments: { type: "string", description: "教练点评" },
            rated: { type: "boolean", description: "已评价" },
            reviewed: { type: "boolean", description: "已评论" },
            isOwner: { type: "boolean", description: "所有人" },
            giftState: { type: "string", description: "赠课状态" },
            token: { type: "string", description: "token" }
          }
        },
        description: "Trainings in the last 30 days TODO",
        ref: "MobileCourseBookingDefault"
      },
      lastestBooking: {
        type: "object",
        nullable: true,
        description: "Lastest booking TODO"
      },
      checkedBookingsNumberAtThisWorkout: {
        type: "number",
        description: "该训练产品打卡次数"
      }
    }
  },
  CoachApiBookingForm: {
    type: "object",
    properties: {
      accountId: { type: "number", description: "会员卡账户" },
      memberId: { type: "number", description: "会员" },
      courseId: { type: "number", description: "课程" }
    }
  },
  MobileZoneScheduleDefault: {
    type: "object",
    properties: {
      studio: {
        description: "门店",
        ref: "StudioStudioEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          fullName: { type: "string", description: "门店全称" },
          shortName: { type: "string", description: "门店名称" },
          active: { type: "boolean", description: "激活" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          display: { type: "boolean", description: "展示" },
          address: { type: "string", description: "地址" },
          contactPhone: { type: "string", description: "联系电话" },
          longitude: { type: "string", description: "经度" },
          latitude: { type: "string", description: "维度" },
          gatePassword: { type: "string", description: "门禁密码" }
        }
      },
      zone: {
        description: "场地",
        ref: "StudioZoneList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "场地名称" },
          position: { type: "number", description: "排序" },
          useHrm: { type: "boolean", description: "使用心率" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          tasExpiried: { type: "boolean", description: "Tas expiried TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" }
            }
          }
        }
      },
      displayName: { type: "string", description: "展示名称" },
      courses: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            courseName: { type: "string", description: "课程名称" },
            enCourseName: { type: "string", description: "英文课程名称" },
            zone: { description: "Zone TODO" },
            studio: {
              description: "门店",
              ref: "StudioStudioEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" },
                active: { type: "boolean", description: "激活" },
                eliteTraining: { type: "boolean", description: "可上私教" },
                display: { type: "boolean", description: "展示" },
                address: { type: "string", description: "地址" },
                contactPhone: { type: "string", description: "联系电话" },
                longitude: { type: "string", description: "经度" },
                latitude: { type: "string", description: "维度" },
                gatePassword: { type: "string", description: "门禁密码" }
              }
            },
            startTime: {
              description: "开始时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            endTime: {
              description: "结束时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            primaryCoach: {
              description: "教练",
              ref: "CoachCoachDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" },
                user: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/UserDefault" }
                  ],
                  description: "用户"
                },
                userId: { type: "number", description: "用户" },
                profilePhoto: {
                  description: "形象照",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                wxQrCode: {
                  description: "微信二维码",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                level: {
                  description: "等级",
                  ref: "CoachLevelDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    category: {
                      description: "类型",
                      ref: "CoachCategoryDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" }
                      }
                    },
                    categoryId: { type: "number", description: "类型" }
                  }
                },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                joinedAt: {
                  description: "入职时间",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                primaryStudio: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/StudioStudioList" }
                  ],
                  description: "绑定门店"
                },
                scopeOfAccess: {
                  type: "string",
                  required: true,
                  enum: ["mine", "all"],
                  "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                  description: "查看排课范围"
                },
                introduction: { type: "string", description: "介绍" },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "教练风格"
                },
                certificationList: {
                  type: "array",
                  items: { type: "string" },
                  description: "技能认证"
                },
                styleList: {
                  type: "array",
                  items: { type: "string" },
                  description: "执教风格"
                },
                resumeDisplay: { type: "boolean", description: "执教数据展示" },
                eliteTrainingCapacity: {
                  type: "number",
                  description: "私教人数上限"
                },
                primaryStudioId: { type: "number", description: "绑定门店" },
                applyToAllProduct: {
                  type: "boolean",
                  description: "授权所有训练产品"
                },
                levelId: { type: "number", description: "等级" },
                createdAt: {
                  description: "加入时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                }
              }
            },
            capacityState: { type: "string", description: "容量状态" },
            capacity: { type: "number", description: "容量" },
            bookingCount: { type: "number", description: "预约人数" },
            tags: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            productTags: {
              type: "array",
              items: { type: "string" },
              description: "产品标签"
            },
            productStages: {
              type: "array",
              items: { type: "string" },
              description: "Product stages TODO"
            },
            productType: { type: "string", description: "Product type TODO" },
            paymentMethods: {
              type: "array",
              items: { type: "string" },
              description: "Payment methods TODO"
            },
            bookState: { type: "string", description: "预约状态" },
            bookingId: { type: "number", description: "Booking TODO" },
            booking: {
              description: "Booking TODO",
              ref: "MobileCourseBookingGift",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                workflowState: { type: "string", description: "状态" },
                isOwner: { type: "boolean", description: "所有人" },
                giftState: { type: "string", description: "赠课状态" },
                token: { type: "string", description: "token" }
              }
            },
            campId: { type: "number", nullable: true, description: "训练营" },
            price: { type: "string", description: "课程价格" },
            prepayPrice: { type: "string", description: "Prepay price TODO" },
            positionSelectable: { type: "boolean", description: "可以选位" },
            multipleCoach: { type: "boolean", description: "Multiple 项目" },
            eliteTrainingMember: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/MobileMemberDefault" }
              ],
              description: "Elite training member TODO"
            },
            banner: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "头图"
            },
            homework: { type: "string", description: "Homework TODO" },
            confirmCourseThreshold: {
              type: "number",
              description: "Confirm course threshold TODO"
            },
            confirmCourseDeadline: {
              type: "number",
              description: "Confirm course deadline TODO"
            },
            hasConfirmCourseThreshold: {
              type: "boolean",
              description: "Has confirm course threshold TODO"
            },
            useHrm: { type: "boolean", description: "使用心率" }
          }
        },
        description: "排课",
        ref: "MobileCourseCourseDefault"
      }
    }
  },
  CoachApiCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "En course name TODO" },
      zone: {
        description: "Zone TODO",
        ref: "MobileStudioZoneDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          displayName: { type: "string", description: "Display name TODO" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          studio: {
            description: "门店",
            ref: "MobileStudioStudioBrief",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              fullName: { type: "string", description: "名称" },
              shortName: { type: "string", description: "简称" },
              longitude: { type: "object", description: "经度" },
              latitude: { type: "object", description: "维度" },
              address: { type: "string", description: "抵制" }
            }
          }
        }
      },
      studio: {
        description: "Studio TODO",
        ref: "StudioStudioEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          fullName: { type: "string", description: "门店全称" },
          shortName: { type: "string", description: "门店名称" },
          active: { type: "boolean", description: "激活" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          display: { type: "boolean", description: "展示" },
          address: { type: "string", description: "地址" },
          contactPhone: { type: "string", description: "联系电话" },
          longitude: { type: "string", description: "经度" },
          latitude: { type: "string", description: "维度" },
          gatePassword: { type: "string", description: "门禁密码" }
        }
      },
      bookState: { type: "string", description: "Book state TODO" },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      primaryCoach: {
        description: "Primary 项目",
        ref: "CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" },
          user: {
            anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
            description: "用户"
          },
          userId: { type: "number", description: "用户" },
          profilePhoto: {
            description: "形象照",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          wxQrCode: {
            description: "微信二维码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          level: {
            description: "等级",
            ref: "CoachLevelDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              category: {
                description: "类型",
                ref: "CoachCategoryDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" }
                }
              },
              categoryId: { type: "number", description: "类型" }
            }
          },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          joinedAt: {
            description: "入职时间",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          primaryStudio: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/StudioStudioList" }
            ],
            description: "绑定门店"
          },
          scopeOfAccess: {
            type: "string",
            required: true,
            enum: ["mine", "all"],
            "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
            description: "查看排课范围"
          },
          introduction: { type: "string", description: "介绍" },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "教练风格"
          },
          certificationList: {
            type: "array",
            items: { type: "string" },
            description: "技能认证"
          },
          styleList: {
            type: "array",
            items: { type: "string" },
            description: "执教风格"
          },
          resumeDisplay: { type: "boolean", description: "执教数据展示" },
          eliteTrainingCapacity: {
            type: "number",
            description: "私教人数上限"
          },
          primaryStudioId: { type: "number", description: "绑定门店" },
          applyToAllProduct: {
            type: "boolean",
            description: "授权所有训练产品"
          },
          levelId: { type: "number", description: "等级" },
          createdAt: {
            description: "加入时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      capacityState: { type: "string", description: "Capacity state TODO" },
      multipleCoach: { type: "boolean", description: "Multiple coach TODO" },
      bookingCount: { type: "number", description: "Booking count TODO" },
      capacity: { type: "number", description: "容量" },
      productType: { type: "string", description: "Product type TODO" },
      eliteTrainingMember: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MobileMemberDefault" }
        ],
        description: "Elite training member TODO"
      },
      workflowState: { type: "string", description: "状态" },
      footprintUploaded: {
        type: "boolean",
        description: "Footprint uploaded TODO"
      }
    }
  },
  CoachApiWorkoutOutlineDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "Serial TODO" },
      note: { type: "string", description: "Note TODO" }
    }
  },
  CoachApiBigCircleDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "Name TODO" },
      duration: { type: "number", description: "Duration TODO" },
      exercises: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            serial: { type: "string", description: "Serial TODO" },
            name: { type: "string", description: "Name TODO" },
            tips: { type: "object", description: "Tips TODO" },
            cnCoachingCues: {
              type: "object",
              description: "Cn coaching cues TODO"
            },
            mp4: {
              description: "Mp4 TODO",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            coverPicture: {
              description: "Cover picture TODO",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            command: {
              description: "Command TODO",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            presentPicture: {
              description: "Present picture TODO",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            enName: { type: "string", description: "En name TODO" },
            bodyWeight: { type: "boolean", description: "Body weight TODO" },
            peAmount: {
              type: "number",
              nullable: true,
              description: "Pe amount TODO"
            },
            peWeight: {
              type: "number",
              nullable: true,
              description: "Pe weight TODO"
            },
            seAmount: {
              type: "number",
              nullable: true,
              description: "Se amount TODO"
            },
            seWeight: {
              type: "number",
              nullable: true,
              description: "Se weight TODO"
            },
            positionList: {
              type: "array",
              items: { type: "string" },
              description: "Position list TODO"
            },
            coachingCues: { type: "object", description: "Coaching cues TODO" },
            shot: { type: "string", description: "Shot TODO" },
            active: { type: "boolean", description: "Active TODO" },
            notGuided: { type: "boolean", description: "Not guided TODO" }
          }
        },
        description: "Exercises TODO",
        ref: "TasExerciseDetail"
      }
    }
  },
  CoachApiBigCircleCoaching: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "Name TODO" },
      duration: { type: "number", description: "Duration TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tag list TODO"
      },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      coachingImage: {
        description: "Coaching image TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      }
    }
  },
  CoachApiWorkoutOutlineCoaching: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "Serial TODO" },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      instruction: { type: "string", description: "Instruction TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      bgmZipUrl: { type: "string", description: "Bgm zip url TODO" },
      coachingImage: {
        description: "Coaching image TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      coachingBigCircles: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "Name TODO" },
            duration: { type: "number", description: "Duration TODO" },
            videoUrl: { type: "string", description: "Video url TODO" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tag list TODO"
            },
            coachingCue: { type: "string", description: "Coaching cue TODO" },
            coachingImage: {
              description: "Coaching image TODO",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            coachingImages: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "Coaching images TODO",
              ref: "AttachmentDefault"
            }
          }
        },
        description: "Coaching big circles TODO",
        ref: "CoachApiBigCircleCoaching"
      }
    }
  },
  CoachApiWorkoutOutlineDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      serial: { type: "string", description: "Serial TODO" },
      note: { type: "string", description: "Note TODO" },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      instruction: { type: "string", description: "Instruction TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      coachingImage: {
        description: "Coaching image TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coachingBigCircles: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "Name TODO" },
            duration: { type: "number", description: "Duration TODO" },
            videoUrl: { type: "string", description: "Video url TODO" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tag list TODO"
            },
            coachingCue: { type: "string", description: "Coaching cue TODO" },
            coachingImage: {
              description: "Coaching image TODO",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            coachingImages: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "Coaching images TODO",
              ref: "AttachmentDefault"
            }
          }
        },
        description: "Coaching big circles TODO",
        ref: "CoachApiBigCircleCoaching"
      },
      bigCircles: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "Name TODO" },
            duration: { type: "number", description: "Duration TODO" },
            exercises: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  serial: { type: "string", description: "Serial TODO" },
                  name: { type: "string", description: "Name TODO" },
                  tips: { type: "object", description: "Tips TODO" },
                  cnCoachingCues: {
                    type: "object",
                    description: "Cn coaching cues TODO"
                  },
                  mp4: {
                    description: "Mp4 TODO",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  coverPicture: {
                    description: "Cover picture TODO",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  command: {
                    description: "Command TODO",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  presentPicture: {
                    description: "Present picture TODO",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  enName: { type: "string", description: "En name TODO" },
                  bodyWeight: {
                    type: "boolean",
                    description: "Body weight TODO"
                  },
                  peAmount: {
                    type: "number",
                    nullable: true,
                    description: "Pe amount TODO"
                  },
                  peWeight: {
                    type: "number",
                    nullable: true,
                    description: "Pe weight TODO"
                  },
                  seAmount: {
                    type: "number",
                    nullable: true,
                    description: "Se amount TODO"
                  },
                  seWeight: {
                    type: "number",
                    nullable: true,
                    description: "Se weight TODO"
                  },
                  positionList: {
                    type: "array",
                    items: { type: "string" },
                    description: "Position list TODO"
                  },
                  coachingCues: {
                    type: "object",
                    description: "Coaching cues TODO"
                  },
                  shot: { type: "string", description: "Shot TODO" },
                  active: { type: "boolean", description: "Active TODO" },
                  notGuided: {
                    type: "boolean",
                    description: "Not guided TODO"
                  }
                }
              },
              description: "Exercises TODO",
              ref: "TasExerciseDetail"
            }
          }
        },
        description: "Big circles TODO",
        ref: "CoachApiBigCircleDefault"
      },
      todayPlaylistItems: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            bgmId: { type: "number", description: "Bgm TODO" },
            bigCircleId: { type: "number", description: "Big circle TODO" },
            bgmName: { type: "string", description: "Bgm name TODO" },
            position: { type: "number", description: "排序" },
            category: { type: "string", description: "类型" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tag list TODO"
            },
            count: { type: "number", description: "Count TODO" },
            duration: { type: "number", description: "时长" },
            file: { type: "string", description: "File TODO" },
            offset: { type: "number", description: "Offset TODO" }
          }
        },
        description: "Today playlist items TODO",
        ref: "TasPlaylistItemDefault"
      }
    }
  },
  CoachApiCourseDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      coach: {
        description: "教练",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      workflowState: { type: "string", description: "状态" },
      courseName: { type: "string", description: "课程名称" },
      capacity: { type: "number", description: "容量" },
      bookings: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            gender: { type: "string", description: "性别" },
            age: { type: "number", description: "年龄" },
            weight: { type: "number", description: "体重" },
            name: { type: "string", description: "名称" },
            realName: { type: "string", description: "Real name TODO" },
            memberId: { type: "number", description: "会员" },
            effectivePaidForCoach: {
              type: "boolean",
              description: "Effective paid for 项目"
            },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            phone: { type: "string", nullable: true, description: "手机" },
            tags: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            workflowState: { type: "string", description: "状态" },
            cancelledAt: {
              description: "Cancelled at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            sensor: {
              description: "心率带",
              ref: "StudioHrSensorDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                label: { type: "string", description: "标识" },
                zone: {
                  description: "场地",
                  ref: "StudioZoneList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "场地名称" },
                    position: { type: "number", description: "排序" },
                    useHrm: { type: "boolean", description: "使用心率" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    tasExpiried: {
                      type: "boolean",
                      description: "Tas expiried TODO"
                    },
                    studio: {
                      description: "门店",
                      ref: "StudioStudioList",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        fullName: { type: "string", description: "门店全称" },
                        shortName: { type: "string", description: "门店名称" }
                      }
                    }
                  }
                },
                antId: { type: "number", description: "Ant ID" },
                active: { type: "boolean", description: "激活" }
              }
            },
            position: { type: "number", description: "Position TODO" },
            consumeType: { type: "string", description: "消费方式" },
            checkedBookingsNumberAtThisWorkout: {
              type: "number",
              description: "该训练产品打卡次数"
            }
          }
        },
        description: "预约",
        ref: "CoachApiBookingEssential"
      },
      ktasOutlineId: { type: "number", description: "Ktas outline TODO" },
      workoutOutline: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CoachApiWorkoutOutlineDefault" }
        ],
        description: "套路"
      },
      tags: {
        type: "array",
        items: {
          type: "object",
          properties: { text: { type: "string" }, class: { type: "string" } }
        },
        description: "标签"
      },
      categoryZh: { type: "string", description: "Category zh TODO" },
      bookFree: { type: "boolean", description: "免费" },
      outlineChangable: {
        type: "boolean",
        description: "Outline changable TODO"
      },
      workoutId: { type: "number", description: "训练课程" },
      productId: { type: "number", description: "Product TODO" },
      zone: {
        description: "Zone TODO",
        ref: "StudioZoneList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "场地名称" },
          position: { type: "number", description: "排序" },
          useHrm: { type: "boolean", description: "使用心率" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          tasExpiried: { type: "boolean", description: "Tas expiried TODO" },
          studio: {
            description: "门店",
            ref: "StudioStudioList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              fullName: { type: "string", description: "门店全称" },
              shortName: { type: "string", description: "门店名称" }
            }
          }
        }
      },
      checkInQrCode: {
        description: "Check in qr code TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      useHrm: { type: "boolean", description: "使用心率" }
    }
  },
  CoachApiCourseFootprint: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      primaryCoach: {
        description: "Primary 项目",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "En course name TODO" },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      footprintUploaded: {
        type: "boolean",
        description: "Footprint uploaded TODO"
      }
    }
  },
  CourseFootprintDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      image: {
        description: "Image TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      }
    }
  },
  CourseFootprintForm: {
    type: "object",
    properties: {
      courseId: { type: "string", description: "课程" },
      image: {
        description: "Image TODO",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      }
    }
  },
  CoachApiCourseFootprintDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "En course name TODO" },
      productType: { type: "string", description: "Product type TODO" },
      zoneName: { type: "string", description: "Zone name TODO" },
      coach: {
        description: "教练",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      footprint: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/CourseFootprintDefault" }
        ],
        description: "Footprint TODO"
      },
      bookings: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "会员",
              ref: "MobileMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                vip: { type: "boolean", description: "vip" }
              }
            }
          }
        },
        description: "预约",
        ref: "MobileCourseBookingEssential"
      },
      trainingCount: { type: "number", description: "Training count TODO" },
      groupTrainingCount: {
        type: "number",
        description: "Group training count TODO"
      },
      totalCalories: { type: "number", description: "Total calories TODO" }
    }
  },
  CoachApiCourseForm: {
    type: "object",
    properties: { workoutOutlineId: { type: "number", description: "套路" } }
  },
  CoachApiCourseEliteTrainingForm: {
    type: "object",
    properties: {
      memberId: { type: "number", description: "Member TODO" },
      accountId: { type: "number", description: "Account TODO" },
      productId: { type: "number", description: "Product TODO" },
      zoneId: { type: "number", description: "Zone TODO" },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  MobileMembershipAccountDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      cardId: { type: "number", description: "Card TODO" },
      accountType: { type: "string", description: "账户类型" },
      productType: { type: "string", description: "Product type TODO" },
      membershipCardName: { type: "string", description: "会员卡名称" },
      balance: { type: "string", description: "余额" },
      value: { type: "string", description: "Value TODO" },
      bonus: { type: "string", description: "Bonus TODO" },
      entryPrice: { type: "string", description: "Entry price TODO" },
      remainingValidityPeriod: { type: "number", description: "剩余有效期" },
      expiryDate: { type: "object", description: "过期日期" },
      workflowState: { type: "string", description: "状态" },
      activateAt: { type: "object", description: "Activate at TODO" },
      available: { type: "boolean", description: "可用" },
      groupTrainingDiscount: {
        type: "string",
        description: "Group training discount TODO"
      },
      freeTrainingDiscount: {
        type: "string",
        description: "Free training discount TODO"
      },
      cancellationDeadline: { type: "number", description: "取消限制（小时）" },
      shared: { type: "boolean", description: "共享" },
      giftable: { type: "boolean", description: "Giftable TODO" },
      owner: {
        description: "所有人",
        ref: "MobileMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          vip: { type: "boolean", description: "vip" }
        }
      },
      unavailableReason: {
        type: "string",
        nullable: true,
        description: "不可用原因"
      },
      coaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "名称" },
            displayName: { type: "string", description: "展示名称" },
            level: {
              description: "等级",
              ref: "CoachLevelDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                category: {
                  description: "类型",
                  ref: "CoachCategoryDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" }
                  }
                },
                categoryId: { type: "number", description: "类型" }
              }
            },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            }
          }
        },
        description: "Coaches TODO",
        ref: "MobileCoachList"
      }
    }
  },
  MobileMembershipCardEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      introduction: { type: "string", description: "Introduction TODO" },
      cardType: { type: "string", description: "会员卡类型" },
      productType: { type: "string", description: "Product type TODO" },
      shared: { type: "boolean", description: "Shared TODO" }
    }
  },
  MobileMembershipCardDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      introduction: { type: "string", description: "Introduction TODO" },
      cardType: { type: "string", description: "会员卡类型" },
      productType: { type: "string", description: "Product type TODO" },
      shared: { type: "boolean", description: "Shared TODO" },
      applyToAllStudio: {
        type: "boolean",
        description: "Apply to all studio TODO"
      },
      studios: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            fullName: { type: "string", description: "门店全称" },
            shortName: { type: "string", description: "门店名称" }
          }
        },
        description: "Studios TODO",
        ref: "StudioStudioList"
      },
      applyToAllProduct: {
        type: "boolean",
        description: "Apply to all product TODO"
      },
      products: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            brandId: { type: "number", description: "品牌" },
            name: { type: "string", description: "课程名称" },
            enName: { type: "string", description: "英文名称" },
            usageCn: { type: "string", description: "Usage cn TODO" },
            forKid: { type: "boolean", description: "For kid TODO" },
            stageList: {
              type: "array",
              items: { type: "string" },
              description: "Stage list TODO"
            },
            provider: { type: "string", description: "产品品牌" },
            expiryDate: {
              description: "授权截止",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            category: {
              type: "string",
              required: false,
              enum: ["brand", "platform"],
              "x-i18n": { brand: "自研产品", platform: "授权产品" },
              description: "类型"
            },
            productType: {
              type: "string",
              required: false,
              enum: [
                "group_training",
                "elite_training",
                "camp",
                "free_training"
              ],
              "x-i18n": {
                group_training: "团课",
                elite_training: "私教",
                camp: "训练营",
                free_training: "自由训练"
              },
              description: "类型"
            },
            scheduleRule: { type: "string", description: "Schedule rule TODO" },
            entryPrice: { type: "string", description: "Entry price TODO" },
            active: { type: "boolean", description: "激活" }
          }
        },
        description: "Products TODO",
        ref: "CourseProductList"
      },
      terms: { type: "string", description: "Terms TODO" },
      cancellationDeadline: {
        type: "number",
        description: "Cancellation deadline TODO"
      },
      campDiscount: { type: "string", description: "Camp discount TODO" },
      eliteTrainingDiscount: {
        type: "string",
        description: "Elite training discount TODO"
      },
      groupTrainingDiscount: {
        type: "string",
        description: "Group training discount TODO"
      },
      freeTrainingDiscount: {
        type: "string",
        description: "Free training discount TODO"
      }
    }
  },
  MobileMembershipCardOptionDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      value: { type: "string", description: "Value TODO" },
      validityPeriod: { type: "number", description: "有效期" },
      originalPrice: { type: "string", description: "原价" },
      inventory: { type: "number", nullable: true, description: "库存" },
      price: { type: "string", description: "价格" },
      salesTypeCn: { type: "string", description: "Sales type cn TODO" },
      salesType: {
        type: "string",
        required: false,
        enum: ["normal", "sales", "purchase_limited", "new_member"],
        "x-i18n": {
          normal: "普通",
          sales: "特惠",
          purchase_limited: "限购一次",
          new_member: "新手专享"
        },
        description: "销售限制"
      },
      active: { type: "boolean", description: "激活" },
      display: { type: "boolean", description: "展示" },
      bonus: { type: "string", description: "赠费" },
      available: { type: "boolean", description: "可用" },
      notes: { type: "string", description: "备注" },
      unavailableReason: {
        type: "string",
        nullable: true,
        description: "Unavailable reason TODO"
      }
    }
  },
  MobileMembershipCardDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      introduction: { type: "string", description: "Introduction TODO" },
      cardType: { type: "string", description: "会员卡类型" },
      productType: { type: "string", description: "Product type TODO" },
      shared: { type: "boolean", description: "Shared TODO" },
      applyToAllStudio: {
        type: "boolean",
        description: "Apply to all studio TODO"
      },
      studios: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            fullName: { type: "string", description: "门店全称" },
            shortName: { type: "string", description: "门店名称" }
          }
        },
        description: "Studios TODO",
        ref: "StudioStudioList"
      },
      applyToAllProduct: {
        type: "boolean",
        description: "Apply to all product TODO"
      },
      products: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            brandId: { type: "number", description: "品牌" },
            name: { type: "string", description: "课程名称" },
            enName: { type: "string", description: "英文名称" },
            usageCn: { type: "string", description: "Usage cn TODO" },
            forKid: { type: "boolean", description: "For kid TODO" },
            stageList: {
              type: "array",
              items: { type: "string" },
              description: "Stage list TODO"
            },
            provider: { type: "string", description: "产品品牌" },
            expiryDate: {
              description: "授权截止",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            category: {
              type: "string",
              required: false,
              enum: ["brand", "platform"],
              "x-i18n": { brand: "自研产品", platform: "授权产品" },
              description: "类型"
            },
            productType: {
              type: "string",
              required: false,
              enum: [
                "group_training",
                "elite_training",
                "camp",
                "free_training"
              ],
              "x-i18n": {
                group_training: "团课",
                elite_training: "私教",
                camp: "训练营",
                free_training: "自由训练"
              },
              description: "类型"
            },
            scheduleRule: { type: "string", description: "Schedule rule TODO" },
            entryPrice: { type: "string", description: "Entry price TODO" },
            active: { type: "boolean", description: "激活" }
          }
        },
        description: "Products TODO",
        ref: "CourseProductList"
      },
      terms: { type: "string", description: "Terms TODO" },
      cancellationDeadline: {
        type: "number",
        description: "Cancellation deadline TODO"
      },
      campDiscount: { type: "string", description: "Camp discount TODO" },
      eliteTrainingDiscount: {
        type: "string",
        description: "Elite training discount TODO"
      },
      groupTrainingDiscount: {
        type: "string",
        description: "Group training discount TODO"
      },
      freeTrainingDiscount: {
        type: "string",
        description: "Free training discount TODO"
      },
      cardOptions: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            value: { type: "string", description: "Value TODO" },
            validityPeriod: { type: "number", description: "有效期" },
            originalPrice: { type: "string", description: "原价" },
            inventory: { type: "number", nullable: true, description: "库存" },
            price: { type: "string", description: "价格" },
            salesTypeCn: { type: "string", description: "Sales type cn TODO" },
            salesType: {
              type: "string",
              required: false,
              enum: ["normal", "sales", "purchase_limited", "new_member"],
              "x-i18n": {
                normal: "普通",
                sales: "特惠",
                purchase_limited: "限购一次",
                new_member: "新手专享"
              },
              description: "销售限制"
            },
            active: { type: "boolean", description: "激活" },
            display: { type: "boolean", description: "展示" },
            bonus: { type: "string", description: "赠费" },
            available: { type: "boolean", description: "可用" },
            notes: { type: "string", description: "备注" },
            unavailableReason: {
              type: "string",
              nullable: true,
              description: "Unavailable reason TODO"
            }
          }
        },
        description: "充值选项",
        ref: "MobileMembershipCardOptionDefault"
      }
    }
  },
  MobileMembershipAccountDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      cardId: { type: "number", description: "Card TODO" },
      accountType: { type: "string", description: "账户类型" },
      productType: { type: "string", description: "Product type TODO" },
      membershipCardName: { type: "string", description: "会员卡名称" },
      balance: { type: "string", description: "余额" },
      value: { type: "string", description: "Value TODO" },
      bonus: { type: "string", description: "Bonus TODO" },
      entryPrice: { type: "string", description: "Entry price TODO" },
      remainingValidityPeriod: { type: "number", description: "剩余有效期" },
      expiryDate: { type: "object", description: "过期日期" },
      workflowState: { type: "string", description: "状态" },
      activateAt: { type: "object", description: "Activate at TODO" },
      available: { type: "boolean", description: "可用" },
      groupTrainingDiscount: {
        type: "string",
        description: "Group training discount TODO"
      },
      freeTrainingDiscount: {
        type: "string",
        description: "Free training discount TODO"
      },
      cancellationDeadline: { type: "number", description: "取消限制（小时）" },
      shared: { type: "boolean", description: "共享" },
      giftable: { type: "boolean", description: "Giftable TODO" },
      owner: {
        description: "所有人",
        ref: "MobileMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          vip: { type: "boolean", description: "vip" }
        }
      },
      unavailableReason: {
        type: "string",
        nullable: true,
        description: "不可用原因"
      },
      coaches: { description: "Coaches TODO" },
      card: {
        description: "Card TODO",
        ref: "MobileMembershipCardDetail",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          introduction: { type: "string", description: "Introduction TODO" },
          cardType: { type: "string", description: "会员卡类型" },
          productType: { type: "string", description: "Product type TODO" },
          shared: { type: "boolean", description: "Shared TODO" },
          applyToAllStudio: {
            type: "boolean",
            description: "Apply to all studio TODO"
          },
          studios: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" }
              }
            },
            description: "Studios TODO",
            ref: "StudioStudioList"
          },
          applyToAllProduct: {
            type: "boolean",
            description: "Apply to all product TODO"
          },
          products: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" }
              }
            },
            description: "Products TODO",
            ref: "CourseProductList"
          },
          terms: { type: "string", description: "Terms TODO" },
          cancellationDeadline: {
            type: "number",
            description: "Cancellation deadline TODO"
          },
          campDiscount: { type: "string", description: "Camp discount TODO" },
          eliteTrainingDiscount: {
            type: "string",
            description: "Elite training discount TODO"
          },
          groupTrainingDiscount: {
            type: "string",
            description: "Group training discount TODO"
          },
          freeTrainingDiscount: {
            type: "string",
            description: "Free training discount TODO"
          }
        }
      }
    }
  },
  MobileMembershipAccountFamily: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      cardId: { type: "number", description: "Card TODO" },
      accountType: { type: "string", description: "账户类型" },
      productType: { type: "string", description: "Product type TODO" },
      membershipCardName: { type: "string", description: "会员卡名称" },
      balance: { type: "string", description: "余额" },
      value: { type: "string", description: "Value TODO" },
      bonus: { type: "string", description: "Bonus TODO" },
      entryPrice: { type: "string", description: "Entry price TODO" },
      remainingValidityPeriod: { type: "number", description: "剩余有效期" },
      expiryDate: { type: "object", description: "过期日期" },
      workflowState: { type: "string", description: "状态" },
      activateAt: { type: "object", description: "Activate at TODO" },
      available: { type: "boolean", description: "可用" },
      groupTrainingDiscount: {
        type: "string",
        description: "Group training discount TODO"
      },
      freeTrainingDiscount: {
        type: "string",
        description: "Free training discount TODO"
      },
      cancellationDeadline: { type: "number", description: "取消限制（小时）" },
      shared: { type: "boolean", description: "共享" },
      giftable: { type: "boolean", description: "Giftable TODO" },
      owner: {
        description: "所有人",
        ref: "MobileMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          vip: { type: "boolean", description: "vip" }
        }
      },
      unavailableReason: {
        type: "string",
        nullable: true,
        description: "不可用原因"
      },
      coaches: { description: "Coaches TODO" },
      familyMembers: {
        description: "Family members TODO",
        ref: "MembershipAccountMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          member: {
            description: "会员",
            ref: "MemberMemberDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              age: { type: "number", description: "年龄" },
              weight: { type: "number", description: "体重" },
              height: { type: "number", description: "Height TODO" },
              maxHr: {
                type: "number",
                nullable: true,
                description: "最大心率"
              },
              parent: {
                description: "家长",
                ref: "MemberMemberEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "昵称" },
                  userId: { type: "number", description: "用户ID" },
                  realName: {
                    type: "string",
                    nullable: true,
                    description: "姓名"
                  },
                  phone: {
                    type: "string",
                    nullable: true,
                    description: "手机"
                  },
                  gender: {
                    type: "string",
                    required: false,
                    enum: ["male", "female"],
                    "x-i18n": { male: "男", female: "女" },
                    description: "性别"
                  },
                  avatar: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/AttachmentDefault" }
                    ],
                    description: "头像"
                  },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "标签"
                  },
                  memberType: { type: "string", description: "会员类型" }
                }
              },
              profilePhotos: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                description: "形象照",
                ref: "AttachmentDefault"
              },
              joinedHof: { type: "boolean", description: "加入名人堂" },
              joinedHofAt: {
                description: "加入名人堂时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              wechatLinked: {
                type: "boolean",
                description: "Wechat linked TODO"
              },
              hofNotes: { type: "string", description: "名人堂备注" },
              vip: { type: "boolean", description: "vip" },
              active: { type: "boolean", description: "激活" }
            }
          },
          notes: { type: "string", description: "备注" },
          usageAmount: { type: "number", description: "使用总数" },
          currentMonthUsageAmount: {
            type: "number",
            description: "当月使用数量"
          }
        }
      }
    }
  },
  CoachApiStudioScheduleDefault: {
    type: "object",
    properties: {
      studio: {
        description: "Studio TODO",
        ref: "StudioStudioDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          fullName: { type: "string", description: "门店全称" },
          shortName: { type: "string", description: "门店名称" },
          active: { type: "boolean", description: "激活" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          display: { type: "boolean", description: "展示" },
          address: { type: "string", description: "地址" },
          contactPhone: { type: "string", description: "联系电话" },
          longitude: { type: "string", description: "经度" },
          latitude: { type: "string", description: "维度" },
          gatePassword: { type: "string", description: "门禁密码" },
          businessOpenTime: { type: "string", description: "门店开门时间" },
          businessCloseTime: { type: "string", description: "门店关门时间" },
          traffic: { type: "string", nullable: true, description: "交通指引" },
          gateMacs: {
            type: "array",
            items: { type: "string" },
            description: "门禁MAC地址"
          },
          checkInQrCode: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "签到二维码"
          },
          openGateQrCodes: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "开门二维码"
          },
          coach: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/CoachCoachEssential" }
            ],
            description: "店长"
          },
          primaryImage: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "主图"
          },
          images: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "图片",
            ref: "AttachmentDefault"
          },
          checkInUrl: { type: "string", description: "签到URL" },
          adsUrl: { type: "string", description: "广告URL" },
          gateStatus: {
            type: "array",
            items: { type: "boolean" },
            description: "门禁状态"
          }
        }
      },
      displayName: { type: "string", description: "Display name TODO" },
      courses: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            primaryCoach: {
              description: "Primary 项目",
              ref: "CoachCoachEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" }
              }
            },
            courseName: { type: "string", description: "课程名称" },
            enCourseName: {
              type: "string",
              description: "En course name TODO"
            },
            startTime: {
              description: "开始时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            footprintUploaded: {
              type: "boolean",
              description: "Footprint uploaded TODO"
            }
          }
        },
        description: "Courses TODO",
        ref: "CoachApiCourseFootprint"
      }
    }
  },
  CoachApiCourseProductDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      enName: { type: "string", description: "En name TODO" },
      duration: { type: "string", description: "Duration TODO" },
      coachingMode: { type: "boolean", description: "Coaching mode TODO" },
      coachingModeTemplate: {
        type: "boolean",
        description: "Coaching mode template TODO"
      }
    }
  },
  CourseBookingPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "会员",
              ref: "MemberMemberDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                age: { type: "number", description: "年龄" },
                weight: { type: "number", description: "体重" },
                height: { type: "number", description: "Height TODO" },
                maxHr: {
                  type: "number",
                  nullable: true,
                  description: "最大心率"
                },
                parent: {
                  description: "家长",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                profilePhotos: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "形象照",
                  ref: "AttachmentDefault"
                },
                joinedHof: { type: "boolean", description: "加入名人堂" },
                joinedHofAt: {
                  description: "加入名人堂时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                wechatLinked: {
                  type: "boolean",
                  description: "Wechat linked TODO"
                },
                hofNotes: { type: "string", description: "名人堂备注" },
                vip: { type: "boolean", description: "vip" },
                active: { type: "boolean", description: "激活" }
              }
            },
            workflowState: {
              type: "string",
              required: false,
              enum: [
                "new",
                "reserved",
                "queued",
                "checked_in",
                "absent",
                "cancelled"
              ],
              "x-i18n": {
                new: "未签到",
                reserved: "未支付",
                queued: "已排号",
                checked_in: "已签到",
                absent: "缺席",
                cancelled: "已取消"
              },
              description: "状态"
            },
            course: {
              description: "课程",
              ref: "CourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                name: { type: "string", description: "名称" },
                enName: { type: "string", description: "课程英文名称" },
                homework: { type: "string", description: "课后作业" },
                workflowState: {
                  type: "string",
                  description: "Workflow state TODO"
                },
                product: {
                  description: "训练产品",
                  ref: "CourseProductList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    brandId: { type: "number", description: "品牌" },
                    name: { type: "string", description: "课程名称" },
                    enName: { type: "string", description: "英文名称" },
                    usageCn: { type: "string", description: "Usage cn TODO" },
                    forKid: { type: "boolean", description: "For kid TODO" },
                    stageList: {
                      type: "array",
                      items: { type: "string" },
                      description: "Stage list TODO"
                    },
                    provider: { type: "string", description: "产品品牌" },
                    expiryDate: {
                      description: "授权截止",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    category: {
                      type: "string",
                      required: false,
                      enum: ["brand", "platform"],
                      "x-i18n": { brand: "自研产品", platform: "授权产品" },
                      description: "类型"
                    },
                    productType: {
                      type: "string",
                      required: false,
                      enum: [
                        "group_training",
                        "elite_training",
                        "camp",
                        "free_training"
                      ],
                      "x-i18n": {
                        group_training: "团课",
                        elite_training: "私教",
                        camp: "训练营",
                        free_training: "自由训练"
                      },
                      description: "类型"
                    },
                    scheduleRule: {
                      type: "string",
                      description: "Schedule rule TODO"
                    },
                    entryPrice: {
                      type: "string",
                      description: "Entry price TODO"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    }
                  }
                },
                assistantCoaches: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      phone: { type: "string", description: "手机号" },
                      avatar: {
                        description: "头像",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      name: { type: "string", description: "姓名" },
                      displayName: { type: "string", description: "昵称" },
                      note: { type: "string", description: "备注" },
                      active: { type: "boolean", description: "激活" },
                      certified: {
                        type: "boolean",
                        description: "Certified TODO"
                      }
                    }
                  },
                  description: "助教",
                  ref: "CoachCoachEssential"
                },
                zone: {
                  description: "场地",
                  ref: "StudioZoneList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "场地名称" },
                    position: { type: "number", description: "排序" },
                    useHrm: { type: "boolean", description: "使用心率" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    tasExpiried: {
                      type: "boolean",
                      description: "Tas expiried TODO"
                    },
                    studio: {
                      description: "门店",
                      ref: "StudioStudioList",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        fullName: { type: "string", description: "门店全称" },
                        shortName: { type: "string", description: "门店名称" }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        description: "数据",
        ref: "CourseBookingDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CoachApiWorkoutOutlinePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "Serial TODO" },
            note: { type: "string", description: "Note TODO" }
          }
        },
        ref: "CoachApiWorkoutOutlineDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  HrmBookingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      avatarUrl: { type: "string", description: "头像 url" },
      age: { type: "number", description: "年龄" },
      weight: { type: "number", description: "体重" },
      maxHr: { type: "number", description: "最大心率" },
      gender: { type: "string", description: "性别" },
      sensorName: { type: "string", description: "心率表编号" },
      sensorSerial: { type: "string", description: "心率表 AntID" },
      position: { type: "number", description: "位置" },
      workflowState: { type: "number", description: "状态" }
    }
  },
  HrmCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      startAt: { type: "string", description: "开始时间" },
      name: { type: "string", description: "名称" },
      duration: { type: "number", description: "时长" },
      exerciseDuration: { type: "number", description: "训练时长" },
      workflowState: { type: "string", description: "状态" },
      bookings: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            avatarUrl: { type: "string", description: "头像 url" },
            age: { type: "number", description: "年龄" },
            weight: { type: "number", description: "体重" },
            maxHr: { type: "number", description: "最大心率" },
            gender: { type: "string", description: "性别" },
            sensorName: { type: "string", description: "心率表编号" },
            sensorSerial: { type: "string", description: "心率表 AntID" },
            position: { type: "number", description: "位置" },
            workflowState: { type: "number", description: "状态" }
          }
        },
        description: "预约",
        ref: "HrmBookingDefault"
      }
    }
  },
  HrmPlayerDefault: {
    type: "object",
    properties: {
      bookingId: { type: "number", description: "预约" },
      connected: { type: "boolean", description: "已连接" },
      name: { type: "string", description: "名称" },
      avatarUrl: { type: "string", description: "头像 url" },
      age: { type: "number", description: "年龄" },
      weight: { type: "number", description: "体重" },
      maxHr: { type: "number", description: "最大心率" },
      gender: { type: "string", description: "性别" },
      sensorName: { type: "string", description: "心率表编号" },
      sensorSerial: { type: "string", description: "心率表 AntID" },
      position: { type: "number", description: "位置" },
      workflowState: { type: "string", description: "状态" },
      trainingResult: { type: "object", description: "训练结果" }
    }
  },
  HrmTrainingDataDefault: {
    type: "object",
    properties: {
      courseId: { type: "number", description: "课程" },
      duration: { type: "number", description: "时长" },
      exerciseDuration: { type: "number", description: "训练时长" },
      name: { type: "string", description: "名称" },
      connectedAdapterCount: { type: "number", description: "心率接收器数量" },
      state: { type: "string", description: "状态" },
      players: {
        type: "array",
        items: {
          type: "object",
          properties: {
            bookingId: { type: "number", description: "预约" },
            connected: { type: "boolean", description: "已连接" },
            name: { type: "string", description: "名称" },
            avatarUrl: { type: "string", description: "头像 url" },
            age: { type: "number", description: "年龄" },
            weight: { type: "number", description: "体重" },
            maxHr: { type: "number", description: "最大心率" },
            gender: { type: "string", description: "性别" },
            sensorName: { type: "string", description: "心率表编号" },
            sensorSerial: { type: "string", description: "心率表 AntID" },
            position: { type: "number", description: "位置" },
            workflowState: { type: "string", description: "状态" },
            trainingResult: { type: "object", description: "训练结果" }
          }
        },
        description: "用户",
        ref: "HrmPlayerDefault"
      }
    }
  },
  AchievementAchievementDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      category: { type: "string", description: "类型" },
      workoutName: {
        type: "string",
        nullable: true,
        description: "课程产品名称"
      },
      medalBg: { type: "object", nullable: true, description: "勋章背景" },
      target: { type: "number", description: "Target TODO" },
      medalsCount: { type: "number", description: "勋章数量" },
      active: { type: "boolean", description: "激活" }
    }
  },
  AchievementAchievementForm: {
    type: "object",
    properties: {
      category: { type: "string", description: "类型" },
      workoutId: { type: "number", description: "Workout TODO" },
      target: { type: "number", description: "Target TODO" },
      active: { type: "boolean", description: "激活" }
    }
  },
  AchievementPersonalMedalRecordDefault: {
    type: "object",
    properties: {
      unlocked: { type: "boolean", description: "Unlocked TODO" },
      achievement: {
        description: "Achievement TODO",
        ref: "AchievementAchievementDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          category: { type: "string", description: "类型" },
          workoutName: {
            type: "string",
            nullable: true,
            description: "课程产品名称"
          },
          medalBg: { type: "object", nullable: true, description: "勋章背景" },
          target: { type: "number", description: "Target TODO" },
          medalsCount: { type: "number", description: "勋章数量" },
          active: { type: "boolean", description: "激活" }
        }
      },
      booking: { type: "object", nullable: true, description: "预约" }
    }
  },
  AchievementPersonalGroupRecordEssential: {
    type: "object",
    properties: {
      unlocked: { type: "boolean", description: "Unlocked TODO" },
      achievementGroupId: {
        type: "number",
        description: "Achievement group TODO"
      },
      bookingCount: { type: "number", description: "Booking count TODO" },
      shownMedalRecord: {
        description: "Shown medal record TODO",
        ref: "AchievementPersonalMedalRecordDefault",
        type: "object",
        properties: {
          unlocked: { type: "boolean", description: "Unlocked TODO" },
          achievement: {
            description: "Achievement TODO",
            ref: "AchievementAchievementDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              category: { type: "string", description: "类型" },
              workoutName: {
                type: "string",
                nullable: true,
                description: "课程产品名称"
              },
              medalBg: {
                type: "object",
                nullable: true,
                description: "勋章背景"
              },
              target: { type: "number", description: "Target TODO" },
              medalsCount: { type: "number", description: "勋章数量" },
              active: { type: "boolean", description: "激活" }
            }
          },
          booking: { type: "object", nullable: true, description: "预约" }
        }
      }
    }
  },
  AchievementPersonalGroupRecordDefault: {
    type: "object",
    properties: {
      unlocked: { type: "boolean", description: "Unlocked TODO" },
      achievementGroupId: {
        type: "number",
        description: "Achievement group TODO"
      },
      bookingCount: { type: "number", description: "Booking count TODO" },
      shownMedalRecord: {
        description: "Shown medal record TODO",
        ref: "AchievementPersonalMedalRecordDefault",
        type: "object",
        properties: {
          unlocked: { type: "boolean", description: "Unlocked TODO" },
          achievement: {
            description: "Achievement TODO",
            ref: "AchievementAchievementDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              category: { type: "string", description: "类型" },
              workoutName: {
                type: "string",
                nullable: true,
                description: "课程产品名称"
              },
              medalBg: {
                type: "object",
                nullable: true,
                description: "勋章背景"
              },
              target: { type: "number", description: "Target TODO" },
              medalsCount: { type: "number", description: "勋章数量" },
              active: { type: "boolean", description: "激活" }
            }
          },
          booking: { type: "object", nullable: true, description: "预约" }
        }
      },
      medalRecords: {
        type: "array",
        items: {
          type: "object",
          properties: {
            unlocked: { type: "boolean", description: "Unlocked TODO" },
            achievement: {
              description: "Achievement TODO",
              ref: "AchievementAchievementDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                category: { type: "string", description: "类型" },
                workoutName: {
                  type: "string",
                  nullable: true,
                  description: "课程产品名称"
                },
                medalBg: {
                  type: "object",
                  nullable: true,
                  description: "勋章背景"
                },
                target: { type: "number", description: "Target TODO" },
                medalsCount: { type: "number", description: "勋章数量" },
                active: { type: "boolean", description: "激活" }
              }
            },
            booking: { type: "object", nullable: true, description: "预约" }
          }
        },
        description: "Medal records TODO",
        ref: "AchievementPersonalMedalRecordDefault"
      }
    }
  },
  MobileCampCampEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      className: { type: "string", description: "Class name TODO" },
      courseCount: { type: "number", description: "Course count TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      endDate: { type: "object", nullable: true, description: "End date TODO" }
    }
  },
  MobileCampCampDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      className: { type: "string", description: "Class name TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      banner: {
        description: "头图",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      state: { type: "string", description: "状态" },
      stateCn: { type: "string", description: "State cn TODO" },
      capacity: { type: "number", description: "Capacity TODO" },
      coach: {
        description: "教练",
        ref: "CoachCoachEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" }
        }
      },
      price: { type: "number", description: "价格" },
      courseCount: { type: "number", description: "Course count TODO" },
      coursePeriodsText: {
        type: "string",
        description: "Course periods text TODO"
      },
      product: {
        description: "Product TODO",
        ref: "CourseProductDetail",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          brandId: { type: "number", description: "品牌" },
          name: { type: "string", description: "课程名称" },
          enName: { type: "string", description: "英文名称" },
          usageCn: { type: "string", description: "Usage cn TODO" },
          forKid: { type: "boolean", description: "For kid TODO" },
          stageList: {
            type: "array",
            items: { type: "string" },
            description: "Stage list TODO"
          },
          provider: { type: "string", description: "产品品牌" },
          expiryDate: {
            description: "授权截止",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          category: {
            type: "string",
            required: false,
            enum: ["brand", "platform"],
            "x-i18n": { brand: "自研产品", platform: "授权产品" },
            description: "类型"
          },
          productType: {
            type: "string",
            required: false,
            enum: ["group_training", "elite_training", "camp", "free_training"],
            "x-i18n": {
              group_training: "团课",
              elite_training: "私教",
              camp: "训练营",
              free_training: "自由训练"
            },
            description: "类型"
          },
          scheduleRule: {
            type: "string",
            required: false,
            enum: ["default", "follow_zone", "follow_coach"],
            "x-i18n": {
              default: "默认",
              follow_zone: "场地记忆",
              follow_coach: "教练记忆"
            },
            description: "Schedule rule TODO"
          },
          entryPrice: { type: "string", description: "Entry price TODO" },
          active: { type: "boolean", description: "激活" },
          price: { type: "string", description: "价格" },
          prepayPrice: { type: "string", description: "储值卡支付价格" },
          workoutId: { type: "number", description: "训练课程" },
          duration: { type: "number", description: "课程时长" },
          courseFee: { type: "string", description: "主教课时费" },
          assistantCourseFee: { type: "string", description: "助教课时费" },
          trialCourseFee: { type: "string", description: "实习课时费" },
          intro: { type: "string", nullable: true, description: "课程介绍" },
          groundType: {
            type: "string",
            required: false,
            enum: ["indoor", "outdoor"],
            "x-i18n": { indoor: "室内", outdoor: "户外" },
            description: "Ground type TODO"
          },
          academy: { type: "boolean", description: "提供培训" },
          note: { type: "string", description: "授权备注" },
          live: {
            description: "课程Live",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          banners: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            description: "封面图",
            ref: "AttachmentDefault"
          },
          coachProductId: { type: "number", description: "Coach product TODO" },
          tagList: {
            type: "array",
            items: { type: "string", enum: [] },
            description: "标签"
          },
          paymentMethods: {
            type: "array",
            items: {
              type: "string",
              required: false,
              enum: ["wechat", "prepay_card", "membership_card", "coupon"],
              "x-i18n": {
                wechat: "微信支付",
                prepay_card: "储值账户",
                membership_card: "会员卡",
                coupon: "代金券"
              }
            },
            description: "支付方式"
          },
          hasConfirmCourseThreshold: {
            type: "boolean",
            description: "单独设置开课限制"
          },
          confirmCourseThreshold: {
            type: "number",
            description: "最低开课人数"
          },
          confirmCourseDeadline: {
            type: "number",
            description: "开课确认时间"
          },
          defaultCaloriesTarget: {
            type: "number",
            description: "默认卡路里目标"
          },
          tasType: {
            type: "string",
            required: false,
            enum: ["tas", "hrm", "countdown"],
            "x-i18n": {
              tas: "内容辅助",
              hrm: "心率辅助",
              countdown: "时间辅助"
            },
            description: "辅助系统配置"
          },
          multiScreenVersions: {
            type: "array",
            items: { type: "number" },
            description: "多屏幕适配"
          },
          displayed: { type: "boolean", description: "Displayed TODO" },
          useHrm: { type: "boolean", description: "使用心率" },
          position: { type: "number", description: "排序" },
          platformProductEditable: {
            type: "boolean",
            description: "Platform product editable TODO"
          },
          coachingMode: { type: "boolean", description: "Coaching mode TODO" },
          coachingModeTemplate: {
            type: "boolean",
            description: "Coaching mode template TODO"
          },
          enableWod: { type: "boolean", description: "今日训练" },
          showWodOnCourseDate: { type: "boolean", description: "当日展示" },
          studioIds: {
            type: "array",
            items: { type: "number" },
            description: "Studio ids TODO"
          }
        }
      },
      paymentMethods: {
        type: "array",
        items: { type: "string" },
        description: "Payment methods TODO"
      },
      spotsLeft: { type: "number", description: "Spots left TODO" },
      coursePeriods: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            campId: { type: "number", description: "训练营" },
            weekday: { type: "number", description: "星期" },
            startAt: { type: "string", description: "开始时间" }
          }
        },
        description: "Course periods TODO",
        ref: "CampCoursePeriodDefault"
      }
    }
  },
  MobileCampBookingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      workflowState: { type: "string", description: "状态" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  MobileCampBookingCompanion: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "MobileMemberEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          avatar: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/AttachmentDefault" }
            ],
            description: "头像"
          },
          vip: { type: "boolean", description: "vip" }
        }
      },
      meetCount: { type: "number", description: "相遇次数" }
    }
  },
  MobileCampBookingWithOrder: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      workflowState: { type: "string", description: "状态" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      order: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MobileOrderWechatPayment" }
        ],
        description: "订单"
      }
    }
  },
  MobileCampBookingForm: {
    type: "object",
    properties: {
      memberId: { type: "number", description: "Member TODO" },
      accountId: { type: "number", description: "Account TODO" },
      memberCouponId: { type: "number", description: "Member coupon TODO" }
    }
  },
  MobileCampCampDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      className: { type: "string", description: "Class name TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      banner: {
        description: "头图",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      state: { type: "string", description: "状态" },
      stateCn: { type: "string", description: "State cn TODO" },
      capacity: { type: "number", description: "Capacity TODO" },
      coach: { description: "教练" },
      price: { type: "number", description: "价格" },
      courseCount: { type: "number", description: "Course count TODO" },
      coursePeriodsText: {
        type: "string",
        description: "Course periods text TODO"
      },
      product: { description: "Product TODO" },
      paymentMethods: {
        type: "array",
        items: { type: "string" },
        description: "Payment methods TODO"
      },
      spotsLeft: { type: "number", description: "Spots left TODO" },
      coursePeriods: { description: "Course periods TODO" },
      bookEndTime: {
        description: "Book end time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      startDate: {
        description: "Start date TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      endDate: { type: "object", nullable: true, description: "End date TODO" },
      zone: {
        description: "场地",
        ref: "MobileStudioZoneDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          displayName: { type: "string", description: "Display name TODO" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          studio: {
            description: "门店",
            ref: "MobileStudioStudioBrief",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              fullName: { type: "string", description: "名称" },
              shortName: { type: "string", description: "简称" },
              longitude: { type: "object", description: "经度" },
              latitude: { type: "object", description: "维度" },
              address: { type: "string", description: "抵制" }
            }
          }
        }
      },
      companions: {
        serializers: "Mobile::Camp::BookingSerializer",
        version: "companion",
        description: "Companions TODO"
      },
      bookingsCount: { type: "number", description: "Bookings count TODO" },
      booking: {
        anyOf: [
          { type: "null" },
          { $ref: "#/definitions/MobileCampBookingWithOrder" }
        ],
        description: "预约"
      },
      intro: { type: "string", nullable: true, description: "介绍" },
      productId: { type: "number", description: "Product TODO" },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentDefault"
      }
    }
  },
  MobileCampCampBookForm: {
    type: "object",
    properties: {
      accountId: { type: "number", description: "Account TODO" },
      memberCouponId: { type: "number", description: "Member coupon TODO" },
      memberId: { type: "number", description: "Member TODO" }
    }
  },
  MobileCampZoneScheduleDefault: {
    type: "object",
    properties: {
      displayName: { type: "string", description: "展示名称" },
      camps: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            className: { type: "string", description: "Class name TODO" },
            fullName: { type: "string", description: "Full name TODO" },
            banner: {
              description: "头图",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            state: { type: "string", description: "状态" },
            stateCn: { type: "string", description: "State cn TODO" },
            capacity: { type: "number", description: "Capacity TODO" },
            coach: {
              description: "教练",
              ref: "CoachCoachEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" }
              }
            },
            price: { type: "number", description: "价格" },
            courseCount: { type: "number", description: "Course count TODO" },
            coursePeriodsText: {
              type: "string",
              description: "Course periods text TODO"
            },
            product: {
              description: "Product TODO",
              ref: "CourseProductDetail",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  required: false,
                  enum: ["default", "follow_zone", "follow_coach"],
                  "x-i18n": {
                    default: "默认",
                    follow_zone: "场地记忆",
                    follow_coach: "教练记忆"
                  },
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" },
                price: { type: "string", description: "价格" },
                prepayPrice: { type: "string", description: "储值卡支付价格" },
                workoutId: { type: "number", description: "训练课程" },
                duration: { type: "number", description: "课程时长" },
                courseFee: { type: "string", description: "主教课时费" },
                assistantCourseFee: {
                  type: "string",
                  description: "助教课时费"
                },
                trialCourseFee: { type: "string", description: "实习课时费" },
                intro: {
                  type: "string",
                  nullable: true,
                  description: "课程介绍"
                },
                groundType: {
                  type: "string",
                  required: false,
                  enum: ["indoor", "outdoor"],
                  "x-i18n": { indoor: "室内", outdoor: "户外" },
                  description: "Ground type TODO"
                },
                academy: { type: "boolean", description: "提供培训" },
                note: { type: "string", description: "授权备注" },
                live: {
                  description: "课程Live",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                banners: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  description: "封面图",
                  ref: "AttachmentDefault"
                },
                coachProductId: {
                  type: "number",
                  description: "Coach product TODO"
                },
                tagList: {
                  type: "array",
                  items: { type: "string", enum: [] },
                  description: "标签"
                },
                paymentMethods: {
                  type: "array",
                  items: {
                    type: "string",
                    required: false,
                    enum: [
                      "wechat",
                      "prepay_card",
                      "membership_card",
                      "coupon"
                    ],
                    "x-i18n": {
                      wechat: "微信支付",
                      prepay_card: "储值账户",
                      membership_card: "会员卡",
                      coupon: "代金券"
                    }
                  },
                  description: "支付方式"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "单独设置开课限制"
                },
                confirmCourseThreshold: {
                  type: "number",
                  description: "最低开课人数"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "开课确认时间"
                },
                defaultCaloriesTarget: {
                  type: "number",
                  description: "默认卡路里目标"
                },
                tasType: {
                  type: "string",
                  required: false,
                  enum: ["tas", "hrm", "countdown"],
                  "x-i18n": {
                    tas: "内容辅助",
                    hrm: "心率辅助",
                    countdown: "时间辅助"
                  },
                  description: "辅助系统配置"
                },
                multiScreenVersions: {
                  type: "array",
                  items: { type: "number" },
                  description: "多屏幕适配"
                },
                displayed: { type: "boolean", description: "Displayed TODO" },
                useHrm: { type: "boolean", description: "使用心率" },
                position: { type: "number", description: "排序" },
                platformProductEditable: {
                  type: "boolean",
                  description: "Platform product editable TODO"
                },
                coachingMode: {
                  type: "boolean",
                  description: "Coaching mode TODO"
                },
                coachingModeTemplate: {
                  type: "boolean",
                  description: "Coaching mode template TODO"
                },
                enableWod: { type: "boolean", description: "今日训练" },
                showWodOnCourseDate: {
                  type: "boolean",
                  description: "当日展示"
                },
                studioIds: {
                  type: "array",
                  items: { type: "number" },
                  description: "Studio ids TODO"
                }
              }
            },
            paymentMethods: {
              type: "array",
              items: { type: "string" },
              description: "Payment methods TODO"
            },
            spotsLeft: { type: "number", description: "Spots left TODO" },
            coursePeriods: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  campId: { type: "number", description: "训练营" },
                  weekday: { type: "number", description: "星期" },
                  startAt: { type: "string", description: "开始时间" }
                }
              },
              description: "Course periods TODO",
              ref: "CampCoursePeriodDefault"
            }
          }
        },
        description: "训练营",
        ref: "MobileCampCampDefault"
      }
    }
  },
  MobileCampProductList: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      banner: {
        description: "Banner TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      }
    }
  },
  MobileCampProductDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      banner: {
        description: "Banner TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      intro: { type: "string", nullable: true, description: "Intro TODO" },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Banners TODO",
        ref: "AttachmentDefault"
      }
    }
  },
  CourseFootprintPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            image: {
              description: "Image TODO",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            }
          }
        },
        ref: "CourseFootprintDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MobileCouponPackageDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      introduction: {
        type: "string",
        nullable: true,
        description: "Introduction TODO"
      },
      price: { type: "string", description: "价格" },
      coupon: {
        description: "代金券",
        ref: "CouponCouponDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          value: { type: "string", description: "面值" },
          fullDiscount: { type: "boolean", description: "全额代金券" },
          availableTimeRange: {
            type: "array",
            items: { type: "string" },
            description: "可用时段"
          },
          validityPeriod: { type: "number", description: "有效期" },
          usageThreshold: { type: "string", description: "使用门槛" },
          applyToAllStudio: { type: "boolean", description: "所有门店可用" },
          studios: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" }
              }
            },
            description: "应用门店",
            ref: "StudioStudioList"
          },
          applyToAllProduct: {
            type: "boolean",
            description: "所有训练产品可用"
          },
          products: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                brandId: { type: "number", description: "品牌" },
                name: { type: "string", description: "课程名称" },
                enName: { type: "string", description: "英文名称" },
                usageCn: { type: "string", description: "Usage cn TODO" },
                forKid: { type: "boolean", description: "For kid TODO" },
                stageList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Stage list TODO"
                },
                provider: { type: "string", description: "产品品牌" },
                expiryDate: {
                  description: "授权截止",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                category: {
                  type: "string",
                  required: false,
                  enum: ["brand", "platform"],
                  "x-i18n": { brand: "自研产品", platform: "授权产品" },
                  description: "类型"
                },
                productType: {
                  type: "string",
                  required: false,
                  enum: [
                    "group_training",
                    "elite_training",
                    "camp",
                    "free_training"
                  ],
                  "x-i18n": {
                    group_training: "团课",
                    elite_training: "私教",
                    camp: "训练营",
                    free_training: "自由训练"
                  },
                  description: "类型"
                },
                scheduleRule: {
                  type: "string",
                  description: "Schedule rule TODO"
                },
                entryPrice: { type: "string", description: "Entry price TODO" },
                active: { type: "boolean", description: "激活" }
              }
            },
            description: "应用训练产品",
            ref: "CourseProductList"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      availableTimeEnd: {
        description: "Available time end TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      purchaseLimited: {
        type: "boolean",
        description: "Purchase limited TODO"
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "Tag list TODO"
      },
      validityPeriod: { type: "number", description: "有效期" },
      available: { type: "boolean", description: "可用" },
      unavailableReason: {
        type: "string",
        nullable: true,
        description: "Unavailable reason TODO"
      }
    }
  },
  MobileCourseBookingPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            course: {
              description: "课程",
              ref: "MobileCourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                courseName: { type: "string", description: "课程名称" },
                enCourseName: { type: "string", description: "英文课程名称" },
                zone: { description: "Zone TODO" },
                studio: {
                  description: "门店",
                  ref: "StudioStudioEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" },
                    active: { type: "boolean", description: "激活" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    display: { type: "boolean", description: "展示" },
                    address: { type: "string", description: "地址" },
                    contactPhone: { type: "string", description: "联系电话" },
                    longitude: { type: "string", description: "经度" },
                    latitude: { type: "string", description: "维度" },
                    gatePassword: { type: "string", description: "门禁密码" }
                  }
                },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                endTime: {
                  description: "结束时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    },
                    user: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/UserDefault" }
                      ],
                      description: "用户"
                    },
                    userId: { type: "number", description: "用户" },
                    profilePhoto: {
                      description: "形象照",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    wxQrCode: {
                      description: "微信二维码",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    level: {
                      description: "等级",
                      ref: "CoachLevelDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        category: {
                          description: "类型",
                          ref: "CoachCategoryDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" }
                          }
                        },
                        categoryId: { type: "number", description: "类型" }
                      }
                    },
                    dateOfBirth: {
                      description: "生日",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    joinedAt: {
                      description: "入职时间",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    primaryStudio: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/StudioStudioList" }
                      ],
                      description: "绑定门店"
                    },
                    scopeOfAccess: {
                      type: "string",
                      required: true,
                      enum: ["mine", "all"],
                      "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                      description: "查看排课范围"
                    },
                    introduction: { type: "string", description: "介绍" },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "教练风格"
                    },
                    certificationList: {
                      type: "array",
                      items: { type: "string" },
                      description: "技能认证"
                    },
                    styleList: {
                      type: "array",
                      items: { type: "string" },
                      description: "执教风格"
                    },
                    resumeDisplay: {
                      type: "boolean",
                      description: "执教数据展示"
                    },
                    eliteTrainingCapacity: {
                      type: "number",
                      description: "私教人数上限"
                    },
                    primaryStudioId: {
                      type: "number",
                      description: "绑定门店"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "授权所有训练产品"
                    },
                    levelId: { type: "number", description: "等级" },
                    createdAt: {
                      description: "加入时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    }
                  }
                },
                capacityState: { type: "string", description: "容量状态" },
                capacity: { type: "number", description: "容量" },
                bookingCount: { type: "number", description: "预约人数" },
                tags: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                productTags: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品标签"
                },
                productStages: {
                  type: "array",
                  items: { type: "string" },
                  description: "Product stages TODO"
                },
                productType: {
                  type: "string",
                  description: "Product type TODO"
                },
                paymentMethods: {
                  type: "array",
                  items: { type: "string" },
                  description: "Payment methods TODO"
                },
                bookState: { type: "string", description: "预约状态" },
                bookingId: { type: "number", description: "Booking TODO" },
                booking: {
                  description: "Booking TODO",
                  ref: "MobileCourseBookingGift",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    workflowState: { type: "string", description: "状态" },
                    isOwner: { type: "boolean", description: "所有人" },
                    giftState: { type: "string", description: "赠课状态" },
                    token: { type: "string", description: "token" }
                  }
                },
                campId: {
                  type: "number",
                  nullable: true,
                  description: "训练营"
                },
                price: { type: "string", description: "课程价格" },
                prepayPrice: {
                  type: "string",
                  description: "Prepay price TODO"
                },
                positionSelectable: {
                  type: "boolean",
                  description: "可以选位"
                },
                multipleCoach: {
                  type: "boolean",
                  description: "Multiple 项目"
                },
                eliteTrainingMember: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/MobileMemberDefault" }
                  ],
                  description: "Elite training member TODO"
                },
                banner: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头图"
                },
                homework: { type: "string", description: "Homework TODO" },
                confirmCourseThreshold: {
                  type: "number",
                  description: "Confirm course threshold TODO"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "Confirm course deadline TODO"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "Has confirm course threshold TODO"
                },
                useHrm: { type: "boolean", description: "使用心率" }
              }
            },
            member: {
              description: "会员",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            owner: {
              description: "所有人",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            primaryCoachName: { type: "string", description: "教练" },
            studioName: { type: "string", description: "门店" },
            courseDuration: { type: "number", description: "课程时长" },
            hrSensor: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/StudioHrSensorDefault" }
              ],
              description: "心率带"
            },
            workflowState: { type: "string", description: "状态" },
            trainingCount: { type: "number", description: "训练数" },
            cancellable: { type: "boolean", description: "可以取消" },
            cancellationDeadline: { type: "number", description: "取消时间" },
            queuePosition: {
              type: "number",
              nullable: true,
              description: "排位顺序"
            },
            position: { type: "number", nullable: true, description: "位置" },
            coachComments: { type: "string", description: "教练点评" },
            rated: { type: "boolean", description: "已评价" },
            reviewed: { type: "boolean", description: "已评论" },
            isOwner: { type: "boolean", description: "所有人" },
            giftState: { type: "string", description: "赠课状态" },
            token: { type: "string", description: "token" }
          }
        },
        ref: "MobileCourseBookingDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MobileDayScheduleDefault: {
    type: "object",
    properties: {
      date: { type: "string", description: "Date TODO" },
      courses: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            courseName: { type: "string", description: "课程名称" },
            enCourseName: { type: "string", description: "英文课程名称" },
            zone: { description: "Zone TODO" },
            studio: {
              description: "门店",
              ref: "StudioStudioEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" },
                active: { type: "boolean", description: "激活" },
                eliteTraining: { type: "boolean", description: "可上私教" },
                display: { type: "boolean", description: "展示" },
                address: { type: "string", description: "地址" },
                contactPhone: { type: "string", description: "联系电话" },
                longitude: { type: "string", description: "经度" },
                latitude: { type: "string", description: "维度" },
                gatePassword: { type: "string", description: "门禁密码" }
              }
            },
            startTime: {
              description: "开始时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            endTime: {
              description: "结束时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            primaryCoach: {
              description: "教练",
              ref: "CoachCoachDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" },
                user: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/UserDefault" }
                  ],
                  description: "用户"
                },
                userId: { type: "number", description: "用户" },
                profilePhoto: {
                  description: "形象照",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                wxQrCode: {
                  description: "微信二维码",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                level: {
                  description: "等级",
                  ref: "CoachLevelDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    category: {
                      description: "类型",
                      ref: "CoachCategoryDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" }
                      }
                    },
                    categoryId: { type: "number", description: "类型" }
                  }
                },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                joinedAt: {
                  description: "入职时间",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                primaryStudio: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/StudioStudioList" }
                  ],
                  description: "绑定门店"
                },
                scopeOfAccess: {
                  type: "string",
                  required: true,
                  enum: ["mine", "all"],
                  "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                  description: "查看排课范围"
                },
                introduction: { type: "string", description: "介绍" },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "教练风格"
                },
                certificationList: {
                  type: "array",
                  items: { type: "string" },
                  description: "技能认证"
                },
                styleList: {
                  type: "array",
                  items: { type: "string" },
                  description: "执教风格"
                },
                resumeDisplay: { type: "boolean", description: "执教数据展示" },
                eliteTrainingCapacity: {
                  type: "number",
                  description: "私教人数上限"
                },
                primaryStudioId: { type: "number", description: "绑定门店" },
                applyToAllProduct: {
                  type: "boolean",
                  description: "授权所有训练产品"
                },
                levelId: { type: "number", description: "等级" },
                createdAt: {
                  description: "加入时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                }
              }
            },
            capacityState: { type: "string", description: "容量状态" },
            capacity: { type: "number", description: "容量" },
            bookingCount: { type: "number", description: "预约人数" },
            tags: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            productTags: {
              type: "array",
              items: { type: "string" },
              description: "产品标签"
            },
            productStages: {
              type: "array",
              items: { type: "string" },
              description: "Product stages TODO"
            },
            productType: { type: "string", description: "Product type TODO" },
            paymentMethods: {
              type: "array",
              items: { type: "string" },
              description: "Payment methods TODO"
            },
            bookState: { type: "string", description: "预约状态" },
            bookingId: { type: "number", description: "Booking TODO" },
            booking: {
              description: "Booking TODO",
              ref: "MobileCourseBookingGift",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                workflowState: { type: "string", description: "状态" },
                isOwner: { type: "boolean", description: "所有人" },
                giftState: { type: "string", description: "赠课状态" },
                token: { type: "string", description: "token" }
              }
            },
            campId: { type: "number", nullable: true, description: "训练营" },
            price: { type: "string", description: "课程价格" },
            prepayPrice: { type: "string", description: "Prepay price TODO" },
            positionSelectable: { type: "boolean", description: "可以选位" },
            multipleCoach: { type: "boolean", description: "Multiple 项目" },
            eliteTrainingMember: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/MobileMemberDefault" }
              ],
              description: "Elite training member TODO"
            },
            banner: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "头图"
            },
            homework: { type: "string", description: "Homework TODO" },
            confirmCourseThreshold: {
              type: "number",
              description: "Confirm course threshold TODO"
            },
            confirmCourseDeadline: {
              type: "number",
              description: "Confirm course deadline TODO"
            },
            hasConfirmCourseThreshold: {
              type: "boolean",
              description: "Has confirm course threshold TODO"
            },
            useHrm: { type: "boolean", description: "使用心率" }
          }
        },
        description: "Courses TODO",
        ref: "MobileCourseCourseDefault"
      }
    }
  },
  MobileCourseDailyScheduleDefault: {
    type: "object",
    properties: {
      date: {
        description: "日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      courses: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            courseName: { type: "string", description: "课程名称" },
            enCourseName: { type: "string", description: "英文课程名称" },
            zone: { description: "Zone TODO" },
            studio: {
              description: "门店",
              ref: "StudioStudioEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" },
                active: { type: "boolean", description: "激活" },
                eliteTraining: { type: "boolean", description: "可上私教" },
                display: { type: "boolean", description: "展示" },
                address: { type: "string", description: "地址" },
                contactPhone: { type: "string", description: "联系电话" },
                longitude: { type: "string", description: "经度" },
                latitude: { type: "string", description: "维度" },
                gatePassword: { type: "string", description: "门禁密码" }
              }
            },
            startTime: {
              description: "开始时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            endTime: {
              description: "结束时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            primaryCoach: {
              description: "教练",
              ref: "CoachCoachDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" },
                user: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/UserDefault" }
                  ],
                  description: "用户"
                },
                userId: { type: "number", description: "用户" },
                profilePhoto: {
                  description: "形象照",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                wxQrCode: {
                  description: "微信二维码",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                level: {
                  description: "等级",
                  ref: "CoachLevelDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    category: {
                      description: "类型",
                      ref: "CoachCategoryDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" }
                      }
                    },
                    categoryId: { type: "number", description: "类型" }
                  }
                },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                joinedAt: {
                  description: "入职时间",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                primaryStudio: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/StudioStudioList" }
                  ],
                  description: "绑定门店"
                },
                scopeOfAccess: {
                  type: "string",
                  required: true,
                  enum: ["mine", "all"],
                  "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                  description: "查看排课范围"
                },
                introduction: { type: "string", description: "介绍" },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "教练风格"
                },
                certificationList: {
                  type: "array",
                  items: { type: "string" },
                  description: "技能认证"
                },
                styleList: {
                  type: "array",
                  items: { type: "string" },
                  description: "执教风格"
                },
                resumeDisplay: { type: "boolean", description: "执教数据展示" },
                eliteTrainingCapacity: {
                  type: "number",
                  description: "私教人数上限"
                },
                primaryStudioId: { type: "number", description: "绑定门店" },
                applyToAllProduct: {
                  type: "boolean",
                  description: "授权所有训练产品"
                },
                levelId: { type: "number", description: "等级" },
                createdAt: {
                  description: "加入时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                }
              }
            },
            capacityState: { type: "string", description: "容量状态" },
            capacity: { type: "number", description: "容量" },
            bookingCount: { type: "number", description: "预约人数" },
            tags: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            productTags: {
              type: "array",
              items: { type: "string" },
              description: "产品标签"
            },
            productStages: {
              type: "array",
              items: { type: "string" },
              description: "Product stages TODO"
            },
            productType: { type: "string", description: "Product type TODO" },
            paymentMethods: {
              type: "array",
              items: { type: "string" },
              description: "Payment methods TODO"
            },
            bookState: { type: "string", description: "预约状态" },
            bookingId: { type: "number", description: "Booking TODO" },
            booking: {
              description: "Booking TODO",
              ref: "MobileCourseBookingGift",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                workflowState: { type: "string", description: "状态" },
                isOwner: { type: "boolean", description: "所有人" },
                giftState: { type: "string", description: "赠课状态" },
                token: { type: "string", description: "token" }
              }
            },
            campId: { type: "number", nullable: true, description: "训练营" },
            price: { type: "string", description: "课程价格" },
            prepayPrice: { type: "string", description: "Prepay price TODO" },
            positionSelectable: { type: "boolean", description: "可以选位" },
            multipleCoach: { type: "boolean", description: "Multiple 项目" },
            eliteTrainingMember: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/MobileMemberDefault" }
              ],
              description: "Elite training member TODO"
            },
            banner: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/AttachmentDefault" }
              ],
              description: "头图"
            },
            homework: { type: "string", description: "Homework TODO" },
            confirmCourseThreshold: {
              type: "number",
              description: "Confirm course threshold TODO"
            },
            confirmCourseDeadline: {
              type: "number",
              description: "Confirm course deadline TODO"
            },
            hasConfirmCourseThreshold: {
              type: "boolean",
              description: "Has confirm course threshold TODO"
            },
            useHrm: { type: "boolean", description: "使用心率" }
          }
        },
        description: "排课",
        ref: "MobileCourseCourseDefault"
      }
    }
  },
  CourseMemberFootprintPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            image: {
              description: "图片",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            course: {
              description: "课程",
              ref: "MobileCourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                courseName: { type: "string", description: "课程名称" },
                enCourseName: { type: "string", description: "英文课程名称" },
                zone: { description: "Zone TODO" },
                studio: {
                  description: "门店",
                  ref: "StudioStudioEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" },
                    active: { type: "boolean", description: "激活" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    display: { type: "boolean", description: "展示" },
                    address: { type: "string", description: "地址" },
                    contactPhone: { type: "string", description: "联系电话" },
                    longitude: { type: "string", description: "经度" },
                    latitude: { type: "string", description: "维度" },
                    gatePassword: { type: "string", description: "门禁密码" }
                  }
                },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                endTime: {
                  description: "结束时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    },
                    user: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/UserDefault" }
                      ],
                      description: "用户"
                    },
                    userId: { type: "number", description: "用户" },
                    profilePhoto: {
                      description: "形象照",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    wxQrCode: {
                      description: "微信二维码",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    level: {
                      description: "等级",
                      ref: "CoachLevelDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        category: {
                          description: "类型",
                          ref: "CoachCategoryDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" }
                          }
                        },
                        categoryId: { type: "number", description: "类型" }
                      }
                    },
                    dateOfBirth: {
                      description: "生日",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    joinedAt: {
                      description: "入职时间",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    primaryStudio: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/StudioStudioList" }
                      ],
                      description: "绑定门店"
                    },
                    scopeOfAccess: {
                      type: "string",
                      required: true,
                      enum: ["mine", "all"],
                      "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                      description: "查看排课范围"
                    },
                    introduction: { type: "string", description: "介绍" },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "教练风格"
                    },
                    certificationList: {
                      type: "array",
                      items: { type: "string" },
                      description: "技能认证"
                    },
                    styleList: {
                      type: "array",
                      items: { type: "string" },
                      description: "执教风格"
                    },
                    resumeDisplay: {
                      type: "boolean",
                      description: "执教数据展示"
                    },
                    eliteTrainingCapacity: {
                      type: "number",
                      description: "私教人数上限"
                    },
                    primaryStudioId: {
                      type: "number",
                      description: "绑定门店"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "授权所有训练产品"
                    },
                    levelId: { type: "number", description: "等级" },
                    createdAt: {
                      description: "加入时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    }
                  }
                },
                capacityState: { type: "string", description: "容量状态" },
                capacity: { type: "number", description: "容量" },
                bookingCount: { type: "number", description: "预约人数" },
                tags: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                productTags: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品标签"
                },
                productStages: {
                  type: "array",
                  items: { type: "string" },
                  description: "Product stages TODO"
                },
                productType: {
                  type: "string",
                  description: "Product type TODO"
                },
                paymentMethods: {
                  type: "array",
                  items: { type: "string" },
                  description: "Payment methods TODO"
                },
                bookState: { type: "string", description: "预约状态" },
                bookingId: { type: "number", description: "Booking TODO" },
                booking: {
                  description: "Booking TODO",
                  ref: "MobileCourseBookingGift",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    workflowState: { type: "string", description: "状态" },
                    isOwner: { type: "boolean", description: "所有人" },
                    giftState: { type: "string", description: "赠课状态" },
                    token: { type: "string", description: "token" }
                  }
                },
                campId: {
                  type: "number",
                  nullable: true,
                  description: "训练营"
                },
                price: { type: "string", description: "课程价格" },
                prepayPrice: {
                  type: "string",
                  description: "Prepay price TODO"
                },
                positionSelectable: {
                  type: "boolean",
                  description: "可以选位"
                },
                multipleCoach: {
                  type: "boolean",
                  description: "Multiple 项目"
                },
                eliteTrainingMember: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/MobileMemberDefault" }
                  ],
                  description: "Elite training member TODO"
                },
                banner: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头图"
                },
                homework: { type: "string", description: "Homework TODO" },
                confirmCourseThreshold: {
                  type: "number",
                  description: "Confirm course threshold TODO"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "Confirm course deadline TODO"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "Has confirm course threshold TODO"
                },
                useHrm: { type: "boolean", description: "使用心率" }
              }
            },
            favorite: { type: "boolean", description: "收藏" },
            trainingNumber: { type: "number", description: "训练编号" }
          }
        },
        ref: "CourseMemberFootprintDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MobileLeaderboardDefault: {
    type: "object",
    properties: {
      leaderboard: {
        description: "Leaderboard TODO",
        ref: "LeaderboardLeaderboardDefault",
        type: "object",
        properties: {
          name: { type: "string", description: "Name TODO" },
          unit: { type: "string", description: "Unit TODO" },
          tips: { type: "string", description: "Tips TODO" },
          ranks: {
            type: "array",
            items: {
              type: "object",
              properties: {
                member: {
                  description: "Member TODO",
                  ref: "MemberMemberEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "昵称" },
                    userId: { type: "number", description: "用户ID" },
                    realName: {
                      type: "string",
                      nullable: true,
                      description: "姓名"
                    },
                    phone: {
                      type: "string",
                      nullable: true,
                      description: "手机"
                    },
                    gender: {
                      type: "string",
                      required: false,
                      enum: ["male", "female"],
                      "x-i18n": { male: "男", female: "女" },
                      description: "性别"
                    },
                    avatar: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/AttachmentDefault" }
                      ],
                      description: "头像"
                    },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "标签"
                    },
                    memberType: { type: "string", description: "会员类型" }
                  }
                },
                rank: { type: "number", description: "Rank TODO" },
                value: { type: "number", description: "Value TODO" }
              }
            },
            description: "Ranks TODO",
            ref: "LeaderboardRankDefault"
          },
          month: {
            description: "Month TODO",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          year: {
            description: "Year TODO",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          quarter: {
            description: "Quarter TODO",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          maxValue: { type: "number", description: "Max value TODO" }
        }
      },
      myRank: {
        description: "My rank TODO",
        ref: "LeaderboardRankDefault",
        type: "object",
        properties: {
          member: {
            description: "Member TODO",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          rank: { type: "number", description: "Rank TODO" },
          value: { type: "number", description: "Value TODO" }
        }
      }
    }
  },
  MobileCouponEventInvitation: {
    type: "object",
    properties: {
      invitationLimit: { type: "number", description: "Invitation limit TODO" },
      rewardAmount: { type: "number", description: "Reward amount TODO" },
      maxInvitationRewardAmount: {
        type: "number",
        description: "Max invitation reward amount TODO"
      },
      eventCoupons: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            coupon: {
              description: "代金券",
              ref: "CouponCouponDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                value: { type: "string", description: "面值" },
                fullDiscount: { type: "boolean", description: "全额代金券" },
                availableTimeRange: {
                  type: "array",
                  items: { type: "string" },
                  description: "可用时段"
                },
                validityPeriod: { type: "number", description: "有效期" },
                usageThreshold: { type: "string", description: "使用门槛" },
                applyToAllStudio: {
                  type: "boolean",
                  description: "所有门店可用"
                },
                studios: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  },
                  description: "应用门店",
                  ref: "StudioStudioList"
                },
                applyToAllProduct: {
                  type: "boolean",
                  description: "所有训练产品可用"
                },
                products: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      brandId: { type: "number", description: "品牌" },
                      name: { type: "string", description: "课程名称" },
                      enName: { type: "string", description: "英文名称" },
                      usageCn: { type: "string", description: "Usage cn TODO" },
                      forKid: { type: "boolean", description: "For kid TODO" },
                      stageList: {
                        type: "array",
                        items: { type: "string" },
                        description: "Stage list TODO"
                      },
                      provider: { type: "string", description: "产品品牌" },
                      expiryDate: {
                        description: "授权截止",
                        ref: "date",
                        type: "string",
                        format: "date",
                        example: "2018-01-01",
                        properties: {}
                      },
                      category: {
                        type: "string",
                        required: false,
                        enum: ["brand", "platform"],
                        "x-i18n": { brand: "自研产品", platform: "授权产品" },
                        description: "类型"
                      },
                      productType: {
                        type: "string",
                        required: false,
                        enum: [
                          "group_training",
                          "elite_training",
                          "camp",
                          "free_training"
                        ],
                        "x-i18n": {
                          group_training: "团课",
                          elite_training: "私教",
                          camp: "训练营",
                          free_training: "自由训练"
                        },
                        description: "类型"
                      },
                      scheduleRule: {
                        type: "string",
                        description: "Schedule rule TODO"
                      },
                      entryPrice: {
                        type: "string",
                        description: "Entry price TODO"
                      },
                      active: { type: "boolean", description: "激活" }
                    }
                  },
                  description: "应用训练产品",
                  ref: "CourseProductList"
                },
                active: { type: "boolean", description: "激活" }
              }
            },
            couponQuantity: { type: "number", description: "单次发券数量" }
          }
        },
        description: "Event coupons TODO",
        ref: "CouponEventCouponDefault"
      }
    }
  },
  MobileCouponEventRegistration: {
    type: "object",
    properties: {
      rewardAmount: { type: "number", description: "Reward amount TODO" },
      eventCoupons: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            coupon: {
              description: "代金券",
              ref: "CouponCouponDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                value: { type: "string", description: "面值" },
                fullDiscount: { type: "boolean", description: "全额代金券" },
                availableTimeRange: {
                  type: "array",
                  items: { type: "string" },
                  description: "可用时段"
                },
                validityPeriod: { type: "number", description: "有效期" },
                usageThreshold: { type: "string", description: "使用门槛" },
                applyToAllStudio: {
                  type: "boolean",
                  description: "所有门店可用"
                },
                studios: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      fullName: { type: "string", description: "门店全称" },
                      shortName: { type: "string", description: "门店名称" }
                    }
                  },
                  description: "应用门店",
                  ref: "StudioStudioList"
                },
                applyToAllProduct: {
                  type: "boolean",
                  description: "所有训练产品可用"
                },
                products: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      brandId: { type: "number", description: "品牌" },
                      name: { type: "string", description: "课程名称" },
                      enName: { type: "string", description: "英文名称" },
                      usageCn: { type: "string", description: "Usage cn TODO" },
                      forKid: { type: "boolean", description: "For kid TODO" },
                      stageList: {
                        type: "array",
                        items: { type: "string" },
                        description: "Stage list TODO"
                      },
                      provider: { type: "string", description: "产品品牌" },
                      expiryDate: {
                        description: "授权截止",
                        ref: "date",
                        type: "string",
                        format: "date",
                        example: "2018-01-01",
                        properties: {}
                      },
                      category: {
                        type: "string",
                        required: false,
                        enum: ["brand", "platform"],
                        "x-i18n": { brand: "自研产品", platform: "授权产品" },
                        description: "类型"
                      },
                      productType: {
                        type: "string",
                        required: false,
                        enum: [
                          "group_training",
                          "elite_training",
                          "camp",
                          "free_training"
                        ],
                        "x-i18n": {
                          group_training: "团课",
                          elite_training: "私教",
                          camp: "训练营",
                          free_training: "自由训练"
                        },
                        description: "类型"
                      },
                      scheduleRule: {
                        type: "string",
                        description: "Schedule rule TODO"
                      },
                      entryPrice: {
                        type: "string",
                        description: "Entry price TODO"
                      },
                      active: { type: "boolean", description: "激活" }
                    }
                  },
                  description: "应用训练产品",
                  ref: "CourseProductList"
                },
                active: { type: "boolean", description: "激活" }
              }
            },
            couponQuantity: { type: "number", description: "单次发券数量" }
          }
        },
        description: "Event coupons TODO",
        ref: "CouponEventCouponDefault"
      }
    }
  },
  MobileMemberInvitationProgressDefault: {
    type: "object",
    properties: {
      promoCode: { type: "string", description: "Promo code TODO" },
      successCount: { type: "number", description: "Success count TODO" },
      invitationQrCode: {
        anyOf: [{ type: "null" }, { $ref: "#/definitions/AttachmentDefault" }],
        description: "Invitation qr code TODO"
      },
      invitations: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            invitee: {
              description: "Invitee TODO",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            confirmed: { type: "boolean", description: "Confirmed TODO" }
          }
        },
        description: "Invitations TODO",
        ref: "MobileMemberInvitationDefault"
      },
      invitationReward: {
        description: "Invitation reward TODO",
        ref: "MobileCouponEventInvitation",
        type: "object",
        properties: {
          invitationLimit: {
            type: "number",
            description: "Invitation limit TODO"
          },
          rewardAmount: { type: "number", description: "Reward amount TODO" },
          maxInvitationRewardAmount: {
            type: "number",
            description: "Max invitation reward amount TODO"
          },
          eventCoupons: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                coupon: {
                  description: "代金券",
                  ref: "CouponCouponDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    value: { type: "string", description: "面值" },
                    fullDiscount: {
                      type: "boolean",
                      description: "全额代金券"
                    },
                    availableTimeRange: {
                      type: "array",
                      items: { type: "string" },
                      description: "可用时段"
                    },
                    validityPeriod: { type: "number", description: "有效期" },
                    usageThreshold: { type: "string", description: "使用门槛" },
                    applyToAllStudio: {
                      type: "boolean",
                      description: "所有门店可用"
                    },
                    studios: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" },
                          fullName: { type: "string", description: "门店全称" },
                          shortName: {
                            type: "string",
                            description: "门店名称"
                          }
                        }
                      },
                      description: "应用门店",
                      ref: "StudioStudioList"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "所有训练产品可用"
                    },
                    products: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          brandId: { type: "number", description: "品牌" },
                          name: { type: "string", description: "课程名称" },
                          enName: { type: "string", description: "英文名称" },
                          usageCn: {
                            type: "string",
                            description: "Usage cn TODO"
                          },
                          forKid: {
                            type: "boolean",
                            description: "For kid TODO"
                          },
                          stageList: {
                            type: "array",
                            items: { type: "string" },
                            description: "Stage list TODO"
                          },
                          provider: { type: "string", description: "产品品牌" },
                          expiryDate: {
                            description: "授权截止",
                            ref: "date",
                            type: "string",
                            format: "date",
                            example: "2018-01-01",
                            properties: {}
                          },
                          category: {
                            type: "string",
                            required: false,
                            enum: ["brand", "platform"],
                            "x-i18n": {
                              brand: "自研产品",
                              platform: "授权产品"
                            },
                            description: "类型"
                          },
                          productType: {
                            type: "string",
                            required: false,
                            enum: [
                              "group_training",
                              "elite_training",
                              "camp",
                              "free_training"
                            ],
                            "x-i18n": {
                              group_training: "团课",
                              elite_training: "私教",
                              camp: "训练营",
                              free_training: "自由训练"
                            },
                            description: "类型"
                          },
                          scheduleRule: {
                            type: "string",
                            description: "Schedule rule TODO"
                          },
                          entryPrice: {
                            type: "string",
                            description: "Entry price TODO"
                          },
                          active: { type: "boolean", description: "激活" }
                        }
                      },
                      description: "应用训练产品",
                      ref: "CourseProductList"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                couponQuantity: { type: "number", description: "单次发券数量" }
              }
            },
            description: "Event coupons TODO",
            ref: "CouponEventCouponDefault"
          }
        }
      }
    }
  },
  MobileMemberInvitationProgressInviteResult: {
    type: "object",
    properties: {
      success: { type: "boolean", description: "Success TODO" },
      inviter: {
        description: "Inviter TODO",
        ref: "MobileMemberTrainingReport",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          phone: { type: "string", description: "手机" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          age: { type: "number", description: "年龄" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          height: { type: "number", description: "Height TODO" },
          weight: { type: "number", description: "体重" },
          brand: { description: "品牌" },
          wxInfoSynced: { type: "boolean", description: "Wx info synced TODO" },
          personalInfoFilled: { type: "boolean", description: "个人信息已填" },
          phoneFilled: { type: "boolean", description: "手机号已绑定" },
          profileFilled: { type: "boolean", description: "新手履历已填" },
          promoCode: { type: "string", description: "邀请码" },
          newbie: { type: "boolean", description: "Newbie TODO" },
          defaultStudioId: {
            type: "number",
            nullable: true,
            description: "Default studio TODO"
          },
          active: { type: "boolean", description: "激活" },
          vip: { type: "boolean", description: "vip" },
          coach: { description: "Coach TODO" },
          isCoach: { type: "boolean", description: "Is 项目" },
          groupTrainingCount: { type: "number", description: "团课训练次数" },
          groupTrainingDuration: {
            type: "number",
            description: "团课训练时长"
          },
          joinedDays: { type: "number", description: "加入天数" },
          kol: { type: "boolean", description: "KOL" },
          totalCalories: { type: "number", description: "Total calories TODO" }
        }
      },
      invitationReward: {
        description: "Invitation reward TODO",
        ref: "MobileCouponEventInvitation",
        type: "object",
        properties: {
          invitationLimit: {
            type: "number",
            description: "Invitation limit TODO"
          },
          rewardAmount: { type: "number", description: "Reward amount TODO" },
          maxInvitationRewardAmount: {
            type: "number",
            description: "Max invitation reward amount TODO"
          },
          eventCoupons: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                coupon: {
                  description: "代金券",
                  ref: "CouponCouponDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    value: { type: "string", description: "面值" },
                    fullDiscount: {
                      type: "boolean",
                      description: "全额代金券"
                    },
                    availableTimeRange: {
                      type: "array",
                      items: { type: "string" },
                      description: "可用时段"
                    },
                    validityPeriod: { type: "number", description: "有效期" },
                    usageThreshold: { type: "string", description: "使用门槛" },
                    applyToAllStudio: {
                      type: "boolean",
                      description: "所有门店可用"
                    },
                    studios: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" },
                          fullName: { type: "string", description: "门店全称" },
                          shortName: {
                            type: "string",
                            description: "门店名称"
                          }
                        }
                      },
                      description: "应用门店",
                      ref: "StudioStudioList"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "所有训练产品可用"
                    },
                    products: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          brandId: { type: "number", description: "品牌" },
                          name: { type: "string", description: "课程名称" },
                          enName: { type: "string", description: "英文名称" },
                          usageCn: {
                            type: "string",
                            description: "Usage cn TODO"
                          },
                          forKid: {
                            type: "boolean",
                            description: "For kid TODO"
                          },
                          stageList: {
                            type: "array",
                            items: { type: "string" },
                            description: "Stage list TODO"
                          },
                          provider: { type: "string", description: "产品品牌" },
                          expiryDate: {
                            description: "授权截止",
                            ref: "date",
                            type: "string",
                            format: "date",
                            example: "2018-01-01",
                            properties: {}
                          },
                          category: {
                            type: "string",
                            required: false,
                            enum: ["brand", "platform"],
                            "x-i18n": {
                              brand: "自研产品",
                              platform: "授权产品"
                            },
                            description: "类型"
                          },
                          productType: {
                            type: "string",
                            required: false,
                            enum: [
                              "group_training",
                              "elite_training",
                              "camp",
                              "free_training"
                            ],
                            "x-i18n": {
                              group_training: "团课",
                              elite_training: "私教",
                              camp: "训练营",
                              free_training: "自由训练"
                            },
                            description: "类型"
                          },
                          scheduleRule: {
                            type: "string",
                            description: "Schedule rule TODO"
                          },
                          entryPrice: {
                            type: "string",
                            description: "Entry price TODO"
                          },
                          active: { type: "boolean", description: "激活" }
                        }
                      },
                      description: "应用训练产品",
                      ref: "CourseProductList"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                couponQuantity: { type: "number", description: "单次发券数量" }
              }
            },
            description: "Event coupons TODO",
            ref: "CouponEventCouponDefault"
          }
        }
      },
      registraionReward: {
        description: "Registraion reward TODO",
        ref: "MobileCouponEventRegistration",
        type: "object",
        properties: {
          rewardAmount: { type: "number", description: "Reward amount TODO" },
          eventCoupons: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                coupon: {
                  description: "代金券",
                  ref: "CouponCouponDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    value: { type: "string", description: "面值" },
                    fullDiscount: {
                      type: "boolean",
                      description: "全额代金券"
                    },
                    availableTimeRange: {
                      type: "array",
                      items: { type: "string" },
                      description: "可用时段"
                    },
                    validityPeriod: { type: "number", description: "有效期" },
                    usageThreshold: { type: "string", description: "使用门槛" },
                    applyToAllStudio: {
                      type: "boolean",
                      description: "所有门店可用"
                    },
                    studios: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" },
                          fullName: { type: "string", description: "门店全称" },
                          shortName: {
                            type: "string",
                            description: "门店名称"
                          }
                        }
                      },
                      description: "应用门店",
                      ref: "StudioStudioList"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "所有训练产品可用"
                    },
                    products: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          brandId: { type: "number", description: "品牌" },
                          name: { type: "string", description: "课程名称" },
                          enName: { type: "string", description: "英文名称" },
                          usageCn: {
                            type: "string",
                            description: "Usage cn TODO"
                          },
                          forKid: {
                            type: "boolean",
                            description: "For kid TODO"
                          },
                          stageList: {
                            type: "array",
                            items: { type: "string" },
                            description: "Stage list TODO"
                          },
                          provider: { type: "string", description: "产品品牌" },
                          expiryDate: {
                            description: "授权截止",
                            ref: "date",
                            type: "string",
                            format: "date",
                            example: "2018-01-01",
                            properties: {}
                          },
                          category: {
                            type: "string",
                            required: false,
                            enum: ["brand", "platform"],
                            "x-i18n": {
                              brand: "自研产品",
                              platform: "授权产品"
                            },
                            description: "类型"
                          },
                          productType: {
                            type: "string",
                            required: false,
                            enum: [
                              "group_training",
                              "elite_training",
                              "camp",
                              "free_training"
                            ],
                            "x-i18n": {
                              group_training: "团课",
                              elite_training: "私教",
                              camp: "训练营",
                              free_training: "自由训练"
                            },
                            description: "类型"
                          },
                          scheduleRule: {
                            type: "string",
                            description: "Schedule rule TODO"
                          },
                          entryPrice: {
                            type: "string",
                            description: "Entry price TODO"
                          },
                          active: { type: "boolean", description: "激活" }
                        }
                      },
                      description: "应用训练产品",
                      ref: "CourseProductList"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                couponQuantity: { type: "number", description: "单次发券数量" }
              }
            },
            description: "Event coupons TODO",
            ref: "CouponEventCouponDefault"
          }
        }
      }
    }
  },
  MemberTrainingReportDefault: {
    type: "object",
    properties: {
      member: {
        description: "会员",
        ref: "MobileMemberTrainingReport",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          gender: {
            type: "string",
            required: false,
            enum: ["male", "female"],
            "x-i18n": { male: "男", female: "女" },
            description: "性别"
          },
          phone: { type: "string", description: "手机" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          age: { type: "number", description: "年龄" },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          height: { type: "number", description: "Height TODO" },
          weight: { type: "number", description: "体重" },
          brand: { description: "品牌" },
          wxInfoSynced: { type: "boolean", description: "Wx info synced TODO" },
          personalInfoFilled: { type: "boolean", description: "个人信息已填" },
          phoneFilled: { type: "boolean", description: "手机号已绑定" },
          profileFilled: { type: "boolean", description: "新手履历已填" },
          promoCode: { type: "string", description: "邀请码" },
          newbie: { type: "boolean", description: "Newbie TODO" },
          defaultStudioId: {
            type: "number",
            nullable: true,
            description: "Default studio TODO"
          },
          active: { type: "boolean", description: "激活" },
          vip: { type: "boolean", description: "vip" },
          coach: { description: "Coach TODO" },
          isCoach: { type: "boolean", description: "Is 项目" },
          groupTrainingCount: { type: "number", description: "团课训练次数" },
          groupTrainingDuration: {
            type: "number",
            description: "团课训练时长"
          },
          joinedDays: { type: "number", description: "加入天数" },
          kol: { type: "boolean", description: "KOL" },
          totalCalories: { type: "number", description: "Total calories TODO" }
        }
      },
      month: {
        description: "月份",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      lastMonthTrainingCount: { type: "number", description: "上月训练次数" },
      checkedInGroupTrainings: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            course: {
              description: "课程",
              ref: "MobileCourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                courseName: { type: "string", description: "课程名称" },
                enCourseName: { type: "string", description: "英文课程名称" },
                zone: { description: "Zone TODO" },
                studio: {
                  description: "门店",
                  ref: "StudioStudioEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" },
                    active: { type: "boolean", description: "激活" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    display: { type: "boolean", description: "展示" },
                    address: { type: "string", description: "地址" },
                    contactPhone: { type: "string", description: "联系电话" },
                    longitude: { type: "string", description: "经度" },
                    latitude: { type: "string", description: "维度" },
                    gatePassword: { type: "string", description: "门禁密码" }
                  }
                },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                endTime: {
                  description: "结束时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    },
                    user: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/UserDefault" }
                      ],
                      description: "用户"
                    },
                    userId: { type: "number", description: "用户" },
                    profilePhoto: {
                      description: "形象照",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    wxQrCode: {
                      description: "微信二维码",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    level: {
                      description: "等级",
                      ref: "CoachLevelDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        category: {
                          description: "类型",
                          ref: "CoachCategoryDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" }
                          }
                        },
                        categoryId: { type: "number", description: "类型" }
                      }
                    },
                    dateOfBirth: {
                      description: "生日",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    joinedAt: {
                      description: "入职时间",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    primaryStudio: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/StudioStudioList" }
                      ],
                      description: "绑定门店"
                    },
                    scopeOfAccess: {
                      type: "string",
                      required: true,
                      enum: ["mine", "all"],
                      "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                      description: "查看排课范围"
                    },
                    introduction: { type: "string", description: "介绍" },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "教练风格"
                    },
                    certificationList: {
                      type: "array",
                      items: { type: "string" },
                      description: "技能认证"
                    },
                    styleList: {
                      type: "array",
                      items: { type: "string" },
                      description: "执教风格"
                    },
                    resumeDisplay: {
                      type: "boolean",
                      description: "执教数据展示"
                    },
                    eliteTrainingCapacity: {
                      type: "number",
                      description: "私教人数上限"
                    },
                    primaryStudioId: {
                      type: "number",
                      description: "绑定门店"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "授权所有训练产品"
                    },
                    levelId: { type: "number", description: "等级" },
                    createdAt: {
                      description: "加入时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    }
                  }
                },
                capacityState: { type: "string", description: "容量状态" },
                capacity: { type: "number", description: "容量" },
                bookingCount: { type: "number", description: "预约人数" },
                tags: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                productTags: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品标签"
                },
                productStages: {
                  type: "array",
                  items: { type: "string" },
                  description: "Product stages TODO"
                },
                productType: {
                  type: "string",
                  description: "Product type TODO"
                },
                paymentMethods: {
                  type: "array",
                  items: { type: "string" },
                  description: "Payment methods TODO"
                },
                bookState: { type: "string", description: "预约状态" },
                bookingId: { type: "number", description: "Booking TODO" },
                booking: {
                  description: "Booking TODO",
                  ref: "MobileCourseBookingGift",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    workflowState: { type: "string", description: "状态" },
                    isOwner: { type: "boolean", description: "所有人" },
                    giftState: { type: "string", description: "赠课状态" },
                    token: { type: "string", description: "token" }
                  }
                },
                campId: {
                  type: "number",
                  nullable: true,
                  description: "训练营"
                },
                price: { type: "string", description: "课程价格" },
                prepayPrice: {
                  type: "string",
                  description: "Prepay price TODO"
                },
                positionSelectable: {
                  type: "boolean",
                  description: "可以选位"
                },
                multipleCoach: {
                  type: "boolean",
                  description: "Multiple 项目"
                },
                eliteTrainingMember: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/MobileMemberDefault" }
                  ],
                  description: "Elite training member TODO"
                },
                banner: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头图"
                },
                homework: { type: "string", description: "Homework TODO" },
                confirmCourseThreshold: {
                  type: "number",
                  description: "Confirm course threshold TODO"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "Confirm course deadline TODO"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "Has confirm course threshold TODO"
                },
                useHrm: { type: "boolean", description: "使用心率" }
              }
            },
            member: { description: "会员" },
            owner: { description: "所有人" },
            primaryCoachName: { type: "string", description: "教练" },
            studioName: { type: "string", description: "门店" },
            courseDuration: { type: "number", description: "课程时长" },
            hrSensor: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/StudioHrSensorDefault" }
              ],
              description: "心率带"
            },
            workflowState: { type: "string", description: "状态" },
            trainingCount: { type: "number", description: "训练数" },
            cancellable: { type: "boolean", description: "可以取消" },
            cancellationDeadline: { type: "number", description: "取消时间" },
            queuePosition: {
              type: "number",
              nullable: true,
              description: "排位顺序"
            },
            position: { type: "number", nullable: true, description: "位置" },
            coachComments: { type: "string", description: "教练点评" },
            rated: { type: "boolean", description: "已评价" },
            reviewed: { type: "boolean", description: "已评论" },
            isOwner: { type: "boolean", description: "所有人" },
            giftState: { type: "string", description: "赠课状态" },
            token: { type: "string", description: "token" },
            memberFootprint: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/CourseMemberFootprintDefault" }
              ],
              description: "训练照片"
            },
            trainingResult: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/MobileCourseTrainingResultDetail" }
              ],
              description: "训练结果"
            }
          }
        },
        description: "训练数",
        ref: "MobileCourseBookingWithResult"
      },
      calorieAchievement: {
        type: "object",
        properties: {
          food: { type: "string" },
          unit: { type: "string" },
          count: { type: "integer" },
          imageUrl: { type: "string" }
        },
        description: "卡路里成就"
      },
      totalCalories: { type: "number", description: "总卡路里消耗" },
      medalsCount: { type: "number", description: "勋章数量" },
      metMembers: {
        type: "array",
        items: {
          name: "string",
          avatar_url: "string",
          meeting_count: "integer"
        },
        description: "同场伙伴"
      },
      footprints: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            image: {
              description: "图片",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            course: {
              description: "课程",
              ref: "MobileCourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                courseName: { type: "string", description: "课程名称" },
                enCourseName: { type: "string", description: "英文课程名称" },
                zone: { description: "Zone TODO" },
                studio: {
                  description: "门店",
                  ref: "StudioStudioEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" },
                    active: { type: "boolean", description: "激活" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    display: { type: "boolean", description: "展示" },
                    address: { type: "string", description: "地址" },
                    contactPhone: { type: "string", description: "联系电话" },
                    longitude: { type: "string", description: "经度" },
                    latitude: { type: "string", description: "维度" },
                    gatePassword: { type: "string", description: "门禁密码" }
                  }
                },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                endTime: {
                  description: "结束时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    },
                    user: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/UserDefault" }
                      ],
                      description: "用户"
                    },
                    userId: { type: "number", description: "用户" },
                    profilePhoto: {
                      description: "形象照",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    wxQrCode: {
                      description: "微信二维码",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    level: {
                      description: "等级",
                      ref: "CoachLevelDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        category: {
                          description: "类型",
                          ref: "CoachCategoryDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" }
                          }
                        },
                        categoryId: { type: "number", description: "类型" }
                      }
                    },
                    dateOfBirth: {
                      description: "生日",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    joinedAt: {
                      description: "入职时间",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    primaryStudio: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/StudioStudioList" }
                      ],
                      description: "绑定门店"
                    },
                    scopeOfAccess: {
                      type: "string",
                      required: true,
                      enum: ["mine", "all"],
                      "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                      description: "查看排课范围"
                    },
                    introduction: { type: "string", description: "介绍" },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "教练风格"
                    },
                    certificationList: {
                      type: "array",
                      items: { type: "string" },
                      description: "技能认证"
                    },
                    styleList: {
                      type: "array",
                      items: { type: "string" },
                      description: "执教风格"
                    },
                    resumeDisplay: {
                      type: "boolean",
                      description: "执教数据展示"
                    },
                    eliteTrainingCapacity: {
                      type: "number",
                      description: "私教人数上限"
                    },
                    primaryStudioId: {
                      type: "number",
                      description: "绑定门店"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "授权所有训练产品"
                    },
                    levelId: { type: "number", description: "等级" },
                    createdAt: {
                      description: "加入时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    }
                  }
                },
                capacityState: { type: "string", description: "容量状态" },
                capacity: { type: "number", description: "容量" },
                bookingCount: { type: "number", description: "预约人数" },
                tags: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                productTags: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品标签"
                },
                productStages: {
                  type: "array",
                  items: { type: "string" },
                  description: "Product stages TODO"
                },
                productType: {
                  type: "string",
                  description: "Product type TODO"
                },
                paymentMethods: {
                  type: "array",
                  items: { type: "string" },
                  description: "Payment methods TODO"
                },
                bookState: { type: "string", description: "预约状态" },
                bookingId: { type: "number", description: "Booking TODO" },
                booking: {
                  description: "Booking TODO",
                  ref: "MobileCourseBookingGift",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    workflowState: { type: "string", description: "状态" },
                    isOwner: { type: "boolean", description: "所有人" },
                    giftState: { type: "string", description: "赠课状态" },
                    token: { type: "string", description: "token" }
                  }
                },
                campId: {
                  type: "number",
                  nullable: true,
                  description: "训练营"
                },
                price: { type: "string", description: "课程价格" },
                prepayPrice: {
                  type: "string",
                  description: "Prepay price TODO"
                },
                positionSelectable: {
                  type: "boolean",
                  description: "可以选位"
                },
                multipleCoach: {
                  type: "boolean",
                  description: "Multiple 项目"
                },
                eliteTrainingMember: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/MobileMemberDefault" }
                  ],
                  description: "Elite training member TODO"
                },
                banner: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头图"
                },
                homework: { type: "string", description: "Homework TODO" },
                confirmCourseThreshold: {
                  type: "number",
                  description: "Confirm course threshold TODO"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "Confirm course deadline TODO"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "Has confirm course threshold TODO"
                },
                useHrm: { type: "boolean", description: "使用心率" }
              }
            },
            favorite: { type: "boolean", description: "收藏" },
            trainingNumber: { type: "number", description: "训练编号" }
          }
        },
        description: "记忆相册",
        ref: "CourseMemberFootprintDefault"
      },
      inspiringWords: { type: "string", description: "激励语" }
    }
  },
  MobileMembershipRecordConsume: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      consumeType: { type: "string", description: "Consume type TODO" },
      productName: { type: "string", description: "Product name TODO" },
      coupon: { type: "string", description: "代金券" },
      amount: { type: "number", description: "Amount TODO" },
      value: { type: "number", description: "Value TODO" },
      bonus: { type: "number", description: "Bonus TODO" },
      courseTime: {
        description: "Course time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      courseStudio: { type: "string", description: "Course studio TODO" },
      recordTime: {
        description: "Record time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      recordType: { type: "string", description: "Record type TODO" },
      notes: { type: "string", description: "Notes TODO" },
      refunded: { type: "boolean", description: "Refunded TODO" }
    }
  },
  MobileMembershipRecordRecharge: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      rechargeName: { type: "string", description: "Recharge name TODO" },
      price: { type: "string", description: "价格" },
      rechargeAmount: { type: "string", description: "Recharge amount TODO" },
      value: { type: "number", description: "Value TODO" },
      bonus: { type: "number", description: "Bonus TODO" },
      validityPeriod: { type: "number", description: "有效期" },
      recordTime: {
        description: "Record time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      recordType: { type: "string", description: "Record type TODO" },
      notes: { type: "string", description: "Notes TODO" }
    }
  },
  MobileMembershipRecordPagedArrayRecharge: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            rechargeName: { type: "string", description: "Recharge name TODO" },
            price: { type: "string", description: "价格" },
            rechargeAmount: {
              type: "string",
              description: "Recharge amount TODO"
            },
            value: { type: "number", description: "Value TODO" },
            bonus: { type: "number", description: "Bonus TODO" },
            validityPeriod: { type: "number", description: "有效期" },
            recordTime: {
              description: "Record time TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            recordType: { type: "string", description: "Record type TODO" },
            notes: { type: "string", description: "Notes TODO" }
          }
        },
        ref: "MobileMembershipRecordRecharge"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MobileMembershipRecordPagedArrayConsume: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            consumeType: { type: "string", description: "Consume type TODO" },
            productName: { type: "string", description: "Product name TODO" },
            coupon: { type: "string", description: "代金券" },
            amount: { type: "number", description: "Amount TODO" },
            value: { type: "number", description: "Value TODO" },
            bonus: { type: "number", description: "Bonus TODO" },
            courseTime: {
              description: "Course time TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            courseStudio: { type: "string", description: "Course studio TODO" },
            recordTime: {
              description: "Record time TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            recordType: { type: "string", description: "Record type TODO" },
            notes: { type: "string", description: "Notes TODO" },
            refunded: { type: "boolean", description: "Refunded TODO" }
          }
        },
        ref: "MobileMembershipRecordConsume"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  MobileStudioCheckInResultDefault: {
    type: "object",
    properties: {
      success: { type: "boolean", description: "签到成功" },
      welcomeText: { type: "string", description: "欢迎语" },
      gateState: { type: "string", description: "开门状态" },
      booking: {
        description: "预约",
        ref: "MobileCourseBookingDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          course: {
            description: "课程",
            ref: "MobileCourseCourseDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              courseName: { type: "string", description: "课程名称" },
              enCourseName: { type: "string", description: "英文课程名称" },
              zone: { description: "Zone TODO" },
              studio: {
                description: "门店",
                ref: "StudioStudioEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" },
                  active: { type: "boolean", description: "激活" },
                  eliteTraining: { type: "boolean", description: "可上私教" },
                  display: { type: "boolean", description: "展示" },
                  address: { type: "string", description: "地址" },
                  contactPhone: { type: "string", description: "联系电话" },
                  longitude: { type: "string", description: "经度" },
                  latitude: { type: "string", description: "维度" },
                  gatePassword: { type: "string", description: "门禁密码" }
                }
              },
              startTime: {
                description: "开始时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              endTime: {
                description: "结束时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              primaryCoach: {
                description: "教练",
                ref: "CoachCoachDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" },
                  user: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/UserDefault" }
                    ],
                    description: "用户"
                  },
                  userId: { type: "number", description: "用户" },
                  profilePhoto: {
                    description: "形象照",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  wxQrCode: {
                    description: "微信二维码",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  level: {
                    description: "等级",
                    ref: "CoachLevelDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      category: {
                        description: "类型",
                        ref: "CoachCategoryDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" }
                        }
                      },
                      categoryId: { type: "number", description: "类型" }
                    }
                  },
                  dateOfBirth: {
                    description: "生日",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  joinedAt: {
                    description: "入职时间",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  primaryStudio: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/StudioStudioList" }
                    ],
                    description: "绑定门店"
                  },
                  scopeOfAccess: {
                    type: "string",
                    required: true,
                    enum: ["mine", "all"],
                    "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                    description: "查看排课范围"
                  },
                  introduction: { type: "string", description: "介绍" },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "教练风格"
                  },
                  certificationList: {
                    type: "array",
                    items: { type: "string" },
                    description: "技能认证"
                  },
                  styleList: {
                    type: "array",
                    items: { type: "string" },
                    description: "执教风格"
                  },
                  resumeDisplay: {
                    type: "boolean",
                    description: "执教数据展示"
                  },
                  eliteTrainingCapacity: {
                    type: "number",
                    description: "私教人数上限"
                  },
                  primaryStudioId: { type: "number", description: "绑定门店" },
                  applyToAllProduct: {
                    type: "boolean",
                    description: "授权所有训练产品"
                  },
                  levelId: { type: "number", description: "等级" },
                  createdAt: {
                    description: "加入时间",
                    ref: "datetime",
                    type: "string",
                    format: "date-time",
                    example: "2018-08-01T00:00:00.000Z",
                    properties: {}
                  }
                }
              },
              capacityState: { type: "string", description: "容量状态" },
              capacity: { type: "number", description: "容量" },
              bookingCount: { type: "number", description: "预约人数" },
              tags: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              productTags: {
                type: "array",
                items: { type: "string" },
                description: "产品标签"
              },
              productStages: {
                type: "array",
                items: { type: "string" },
                description: "Product stages TODO"
              },
              productType: { type: "string", description: "Product type TODO" },
              paymentMethods: {
                type: "array",
                items: { type: "string" },
                description: "Payment methods TODO"
              },
              bookState: { type: "string", description: "预约状态" },
              bookingId: { type: "number", description: "Booking TODO" },
              booking: {
                description: "Booking TODO",
                ref: "MobileCourseBookingGift",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  workflowState: { type: "string", description: "状态" },
                  isOwner: { type: "boolean", description: "所有人" },
                  giftState: { type: "string", description: "赠课状态" },
                  token: { type: "string", description: "token" }
                }
              },
              campId: { type: "number", nullable: true, description: "训练营" },
              price: { type: "string", description: "课程价格" },
              prepayPrice: { type: "string", description: "Prepay price TODO" },
              positionSelectable: { type: "boolean", description: "可以选位" },
              multipleCoach: { type: "boolean", description: "Multiple 项目" },
              eliteTrainingMember: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/MobileMemberDefault" }
                ],
                description: "Elite training member TODO"
              },
              banner: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头图"
              },
              homework: { type: "string", description: "Homework TODO" },
              confirmCourseThreshold: {
                type: "number",
                description: "Confirm course threshold TODO"
              },
              confirmCourseDeadline: {
                type: "number",
                description: "Confirm course deadline TODO"
              },
              hasConfirmCourseThreshold: {
                type: "boolean",
                description: "Has confirm course threshold TODO"
              },
              useHrm: { type: "boolean", description: "使用心率" }
            }
          },
          member: {
            description: "会员",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          owner: {
            description: "所有人",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          primaryCoachName: { type: "string", description: "教练" },
          studioName: { type: "string", description: "门店" },
          courseDuration: { type: "number", description: "课程时长" },
          hrSensor: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/StudioHrSensorDefault" }
            ],
            description: "心率带"
          },
          workflowState: { type: "string", description: "状态" },
          trainingCount: { type: "number", description: "训练数" },
          cancellable: { type: "boolean", description: "可以取消" },
          cancellationDeadline: { type: "number", description: "取消时间" },
          queuePosition: {
            type: "number",
            nullable: true,
            description: "排位顺序"
          },
          position: { type: "number", nullable: true, description: "位置" },
          coachComments: { type: "string", description: "教练点评" },
          rated: { type: "boolean", description: "已评价" },
          reviewed: { type: "boolean", description: "已评论" },
          isOwner: { type: "boolean", description: "所有人" },
          giftState: { type: "string", description: "赠课状态" },
          token: { type: "string", description: "token" }
        }
      }
    }
  },
  MobileWechatAuthRequest: {
    type: "object",
    properties: {
      code: { type: "string", required: true, description: "code" }
    }
  },
  MobileWechatDevAuthRequest: {
    type: "object",
    properties: {
      openId: { type: "string", required: true, description: "Open TODO" }
    }
  },
  MobileWechatPayRequest: {
    type: "object",
    properties: {
      orderId: { type: "number", required: true, description: "订单" }
    }
  },
  MobileMemberPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            gender: {
              type: "string",
              required: false,
              enum: ["male", "female"],
              "x-i18n": { male: "男", female: "女" },
              description: "性别"
            },
            phone: { type: "string", description: "手机" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            age: { type: "number", description: "年龄" },
            dateOfBirth: {
              description: "生日",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            height: { type: "number", description: "Height TODO" },
            weight: { type: "number", description: "体重" },
            brand: {
              description: "品牌",
              ref: "MobileBrandDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                identifier: { type: "string", description: "标识符" },
                saasType: { type: "string", description: "Saas type TODO" },
                showCourseBookingCount: {
                  type: "boolean",
                  description: "Show course booking count TODO"
                },
                giftBookingEnabled: {
                  type: "boolean",
                  description: "Gift booking enabled TODO"
                },
                bookingQueueEnabled: {
                  type: "boolean",
                  description: "Booking queue enabled TODO"
                },
                groupTrainingCoachBook: {
                  type: "boolean",
                  description: "教练代约团课"
                },
                groupTrainingCoachCheckIn: {
                  type: "boolean",
                  description: "教练代签到团课"
                },
                cancellationDeadline: {
                  type: "number",
                  description: "取消时限"
                },
                vipGroupTrainingDiscount: {
                  type: "number",
                  description: "Vip group training discount TODO"
                },
                vipFreeTrainingDiscount: {
                  type: "number",
                  description: "Vip group training discount TODO"
                },
                vipCampDiscount: {
                  type: "number",
                  description: "Vip camp discount TODO"
                },
                vipCouponPackageDiscount: {
                  type: "number",
                  description: "Vip coupon package discount TODO"
                },
                vipMembershipCardDiscount: {
                  type: "number",
                  description: "Vip membership card discount TODO"
                },
                vipCancellationDeadline: {
                  type: "number",
                  description: "Vip cancellation deadline TODO"
                },
                trainingReport: {
                  type: "boolean",
                  description: "Training report TODO"
                },
                prepayCardName: {
                  type: "string",
                  nullable: true,
                  description: "储值卡名称"
                },
                availableProductTypes: {
                  type: "array",
                  items: { type: "string" },
                  description: "可用产品类型"
                },
                membershipCardAvailableProductTypes: {
                  type: "array",
                  items: { type: "string" },
                  description: "支持会员卡约课的产品类型"
                },
                prepayCardPurchaseAvailable: {
                  type: "boolean",
                  description: "启用储值卡充值"
                },
                couponPurchaseAvailable: {
                  type: "boolean",
                  description: "启用代金券商城"
                },
                mobileCancelEliteTrainingBooking: {
                  type: "boolean",
                  description: "Mobile cancel elite training booking TODO"
                },
                mobileEliteTrainingBooking: {
                  type: "boolean",
                  description: "Mobile elite training booking TODO"
                },
                mobileEliteTrainingBookingDeadline: {
                  type: "number",
                  description: "Mobile elite training booking deadline TODO"
                },
                eliteTrainingScheduleDays: {
                  type: "number",
                  description: "Elite training schedule days TODO"
                },
                eliteTrainingCoachCheckIn: {
                  type: "boolean",
                  description: "私教教练代签到"
                },
                eliteTrainingCoachCancel: {
                  type: "boolean",
                  description: "私教教练端取消预约"
                },
                eliteTrainingCoachLeave: {
                  type: "boolean",
                  description: "私教教练端隐藏时段"
                },
                expiryAlertEntryThreshold: {
                  type: "number",
                  description: "Expiry alert entry threshold TODO"
                },
                expiryAlertEliteEntryThreshold: {
                  type: "number",
                  description: "Expiry alert elite entry threshold TODO"
                },
                expiryAlertPrepayAmountThreshold: {
                  type: "number",
                  description: "Expiry alert prepay amount threshold TODO"
                },
                expiryAlertValidityPeriodThreshold: {
                  type: "number",
                  description: "Expiry alert validity period threshold TODO"
                },
                churnThreshold: {
                  type: "number",
                  description: "Churn threshold TODO"
                },
                newMemberThreshold: {
                  type: "number",
                  description: "New member threshold TODO"
                },
                inactiveMemberThreshold: {
                  type: "number",
                  description: "Inactive member threshold TODO"
                },
                inactiveEliteMemberThreshold: {
                  type: "number",
                  description: "Inactive elite member threshold TODO"
                },
                logo: {
                  description: "Logo",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                qrCode: {
                  description: "Qr code TODO",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                theme: {
                  description: "小程序主题",
                  ref: "BrandThemeDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    primaryColor: { type: "string", description: "主题色" },
                    secondaryColor: { type: "string", description: "辅助色" }
                  }
                },
                wxMessageTemplates: {
                  description: "Wx message templates TODO",
                  ref: "BrandWxConfigMessageTemplates",
                  type: "object",
                  properties: {
                    courseReminder: {
                      type: "string",
                      description: "Course reminder TODO"
                    },
                    bookingCancel: {
                      type: "string",
                      description: "Booking cancel TODO"
                    },
                    bookingQueueStatus: {
                      type: "string",
                      description: "Booking queue status TODO"
                    },
                    trainingResult: {
                      type: "string",
                      description: "Training result TODO"
                    },
                    footprintUploaded: {
                      type: "string",
                      description: "Footprint uploaded TODO"
                    }
                  }
                },
                schedulePublishWday: {
                  type: "number",
                  description: "Schedule publish wday TODO"
                },
                schedulePublishTime: {
                  type: "string",
                  description: "Schedule publish time TODO"
                },
                referalAd: {
                  description: "Referal ad TODO",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                communitySetting: {
                  description: "Community setting TODO",
                  ref: "BrandCommunitySettingDefault",
                  type: "object",
                  properties: {
                    enabled: { type: "number", description: "开启社群" },
                    progressEnabled: {
                      type: "boolean",
                      description: "展示社群统计"
                    },
                    progressType: {
                      type: "string",
                      required: false,
                      enum: ["", "training", "calories"],
                      "x-i18n": {
                        "": "不展示",
                        training: "打卡",
                        calories: "燃脂"
                      },
                      description: "社群统计数据类型"
                    },
                    progressAmplificationFactor: {
                      type: "number",
                      description: "社群统计数据放大系数"
                    },
                    leaderboardEnabled: {
                      type: "boolean",
                      description: "展示排行榜"
                    },
                    leaderboardTypes: {
                      type: "array",
                      items: { type: "string" },
                      description: "展示排行榜类型"
                    },
                    tasLeaderboards: {
                      type: "array",
                      items: { type: "string" },
                      description: "智慧屏榜单展示"
                    },
                    operatingLists: {
                      type: "array",
                      items: { type: "string" },
                      description: "运营列表"
                    },
                    footprintsEnabled: {
                      type: "boolean",
                      description: "展示记忆相册"
                    },
                    footprintMemberCountThreshold: {
                      type: "number",
                      description: "记忆相册展示人数门槛"
                    }
                  }
                },
                userTerms: { type: "string", description: "User terms TODO" },
                prepayRules: {
                  type: "string",
                  description: "Prepay rules TODO"
                }
              }
            },
            wxInfoSynced: {
              type: "boolean",
              description: "Wx info synced TODO"
            },
            personalInfoFilled: {
              type: "boolean",
              description: "个人信息已填"
            },
            phoneFilled: { type: "boolean", description: "手机号已绑定" },
            profileFilled: { type: "boolean", description: "新手履历已填" },
            promoCode: { type: "string", description: "邀请码" },
            newbie: { type: "boolean", description: "Newbie TODO" },
            defaultStudioId: {
              type: "number",
              nullable: true,
              description: "Default studio TODO"
            },
            active: { type: "boolean", description: "激活" },
            vip: { type: "boolean", description: "vip" },
            coach: {
              description: "Coach TODO",
              ref: "MobileCoachList",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "名称" },
                displayName: { type: "string", description: "展示名称" },
                level: {
                  description: "等级",
                  ref: "CoachLevelDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    category: {
                      description: "类型",
                      ref: "CoachCategoryDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" }
                      }
                    },
                    categoryId: { type: "number", description: "类型" }
                  }
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                }
              }
            },
            isCoach: { type: "boolean", description: "Is 项目" }
          }
        },
        ref: "MobileMemberDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  ZpkTrainingReportDefault: {
    type: "object",
    properties: {
      totalTrainingCount: {
        type: "number",
        description: "Total training count TODO"
      },
      totalTrainingDuration: {
        type: "number",
        description: "Total training duration TODO"
      },
      latestExamRecord: {
        description: "Latest exam record TODO",
        ref: "ZpkExamRecordDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          memberId: { type: "number", description: "会员" },
          member: {
            description: "会员",
            ref: "MemberMemberDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              age: { type: "number", description: "年龄" },
              weight: { type: "number", description: "体重" },
              height: { type: "number", description: "Height TODO" },
              maxHr: {
                type: "number",
                nullable: true,
                description: "最大心率"
              },
              parent: {
                description: "家长",
                ref: "MemberMemberEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "昵称" },
                  userId: { type: "number", description: "用户ID" },
                  realName: {
                    type: "string",
                    nullable: true,
                    description: "姓名"
                  },
                  phone: {
                    type: "string",
                    nullable: true,
                    description: "手机"
                  },
                  gender: {
                    type: "string",
                    required: false,
                    enum: ["male", "female"],
                    "x-i18n": { male: "男", female: "女" },
                    description: "性别"
                  },
                  avatar: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/AttachmentDefault" }
                    ],
                    description: "头像"
                  },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "标签"
                  },
                  memberType: { type: "string", description: "会员类型" }
                }
              },
              profilePhotos: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                description: "形象照",
                ref: "AttachmentDefault"
              },
              joinedHof: { type: "boolean", description: "加入名人堂" },
              joinedHofAt: {
                description: "加入名人堂时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              wechatLinked: {
                type: "boolean",
                description: "Wechat linked TODO"
              },
              hofNotes: { type: "string", description: "名人堂备注" },
              vip: { type: "boolean", description: "vip" },
              active: { type: "boolean", description: "激活" }
            }
          },
          age: { type: "number", description: "年龄" },
          date: {
            description: "测试日期",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          displayed: { type: "boolean", description: "展示" },
          coachId: { type: "number", description: "测试人" },
          coach: {
            description: "测试人",
            ref: "CoachCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" },
              user: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/UserDefault" }
                ],
                description: "用户"
              },
              userId: { type: "number", description: "用户" },
              profilePhoto: {
                description: "形象照",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              wxQrCode: {
                description: "微信二维码",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              level: {
                description: "等级",
                ref: "CoachLevelDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  category: {
                    description: "类型",
                    ref: "CoachCategoryDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" }
                    }
                  },
                  categoryId: { type: "number", description: "类型" }
                }
              },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              joinedAt: {
                description: "入职时间",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              primaryStudio: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/StudioStudioList" }
                ],
                description: "绑定门店"
              },
              scopeOfAccess: {
                type: "string",
                required: true,
                enum: ["mine", "all"],
                "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                description: "查看排课范围"
              },
              introduction: { type: "string", description: "介绍" },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "教练风格"
              },
              certificationList: {
                type: "array",
                items: { type: "string" },
                description: "技能认证"
              },
              styleList: {
                type: "array",
                items: { type: "string" },
                description: "执教风格"
              },
              resumeDisplay: { type: "boolean", description: "执教数据展示" },
              eliteTrainingCapacity: {
                type: "number",
                description: "私教人数上限"
              },
              primaryStudioId: { type: "number", description: "绑定门店" },
              applyToAllProduct: {
                type: "boolean",
                description: "授权所有训练产品"
              },
              levelId: { type: "number", description: "等级" },
              createdAt: {
                description: "加入时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              }
            }
          },
          summary: { type: "string", description: "总评" },
          height: { type: "number", description: "形态身高" },
          weight: { type: "number", description: "体重" },
          bmi: { type: "number", description: "BMI" },
          results: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                subjectId: { type: "number", description: "项目" },
                subject: {
                  description: "项目",
                  ref: "ZpkExamSubjectDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "项目名称" },
                    minAge: { type: "number", description: "最小测试年龄" },
                    maxAge: { type: "number", description: "最大测试年龄" },
                    unit: { type: "string", description: "单位" },
                    position: { type: "number", description: "位置" },
                    effect: { type: "string", description: "影响" },
                    trainingMethod: { type: "string", description: "训练方式" }
                  }
                },
                result: { type: "number", description: "测试结果" },
                rank: { type: "string", description: "评级" },
                analysis: { type: "string", description: "解析" }
              }
            },
            description: "测试成绩",
            ref: "ZpkExamResultDefault"
          }
        }
      },
      latestHomework: {
        description: "Latest homework TODO",
        ref: "MobileCourseBookingDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          course: {
            description: "课程",
            ref: "MobileCourseCourseDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              courseName: { type: "string", description: "课程名称" },
              enCourseName: { type: "string", description: "英文课程名称" },
              zone: { description: "Zone TODO" },
              studio: {
                description: "门店",
                ref: "StudioStudioEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  fullName: { type: "string", description: "门店全称" },
                  shortName: { type: "string", description: "门店名称" },
                  active: { type: "boolean", description: "激活" },
                  eliteTraining: { type: "boolean", description: "可上私教" },
                  display: { type: "boolean", description: "展示" },
                  address: { type: "string", description: "地址" },
                  contactPhone: { type: "string", description: "联系电话" },
                  longitude: { type: "string", description: "经度" },
                  latitude: { type: "string", description: "维度" },
                  gatePassword: { type: "string", description: "门禁密码" }
                }
              },
              startTime: {
                description: "开始时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              endTime: {
                description: "结束时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              primaryCoach: {
                description: "教练",
                ref: "CoachCoachDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  phone: { type: "string", description: "手机号" },
                  avatar: {
                    description: "头像",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  name: { type: "string", description: "姓名" },
                  displayName: { type: "string", description: "昵称" },
                  note: { type: "string", description: "备注" },
                  active: { type: "boolean", description: "激活" },
                  certified: { type: "boolean", description: "Certified TODO" },
                  user: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/UserDefault" }
                    ],
                    description: "用户"
                  },
                  userId: { type: "number", description: "用户" },
                  profilePhoto: {
                    description: "形象照",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  wxQrCode: {
                    description: "微信二维码",
                    ref: "AttachmentDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      signedId: { type: "string", description: "Signed ID" },
                      url: { type: "string", description: "URL" },
                      originalUrl: {
                        type: "string",
                        description: "原始文件 URL"
                      },
                      filename: { type: "string", description: "文件名" },
                      contentType: { type: "string", description: "文件类型" }
                    }
                  },
                  level: {
                    description: "等级",
                    ref: "CoachLevelDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" },
                      category: {
                        description: "类型",
                        ref: "CoachCategoryDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" }
                        }
                      },
                      categoryId: { type: "number", description: "类型" }
                    }
                  },
                  dateOfBirth: {
                    description: "生日",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  joinedAt: {
                    description: "入职时间",
                    ref: "date",
                    type: "string",
                    format: "date",
                    example: "2018-01-01",
                    properties: {}
                  },
                  primaryStudio: {
                    anyOf: [
                      { type: "null" },
                      { $ref: "#/definitions/StudioStudioList" }
                    ],
                    description: "绑定门店"
                  },
                  scopeOfAccess: {
                    type: "string",
                    required: true,
                    enum: ["mine", "all"],
                    "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                    description: "查看排课范围"
                  },
                  introduction: { type: "string", description: "介绍" },
                  tagList: {
                    type: "array",
                    items: { type: "string" },
                    description: "教练风格"
                  },
                  certificationList: {
                    type: "array",
                    items: { type: "string" },
                    description: "技能认证"
                  },
                  styleList: {
                    type: "array",
                    items: { type: "string" },
                    description: "执教风格"
                  },
                  resumeDisplay: {
                    type: "boolean",
                    description: "执教数据展示"
                  },
                  eliteTrainingCapacity: {
                    type: "number",
                    description: "私教人数上限"
                  },
                  primaryStudioId: { type: "number", description: "绑定门店" },
                  applyToAllProduct: {
                    type: "boolean",
                    description: "授权所有训练产品"
                  },
                  levelId: { type: "number", description: "等级" },
                  createdAt: {
                    description: "加入时间",
                    ref: "datetime",
                    type: "string",
                    format: "date-time",
                    example: "2018-08-01T00:00:00.000Z",
                    properties: {}
                  }
                }
              },
              capacityState: { type: "string", description: "容量状态" },
              capacity: { type: "number", description: "容量" },
              bookingCount: { type: "number", description: "预约人数" },
              tags: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              productTags: {
                type: "array",
                items: { type: "string" },
                description: "产品标签"
              },
              productStages: {
                type: "array",
                items: { type: "string" },
                description: "Product stages TODO"
              },
              productType: { type: "string", description: "Product type TODO" },
              paymentMethods: {
                type: "array",
                items: { type: "string" },
                description: "Payment methods TODO"
              },
              bookState: { type: "string", description: "预约状态" },
              bookingId: { type: "number", description: "Booking TODO" },
              booking: {
                description: "Booking TODO",
                ref: "MobileCourseBookingGift",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  workflowState: { type: "string", description: "状态" },
                  isOwner: { type: "boolean", description: "所有人" },
                  giftState: { type: "string", description: "赠课状态" },
                  token: { type: "string", description: "token" }
                }
              },
              campId: { type: "number", nullable: true, description: "训练营" },
              price: { type: "string", description: "课程价格" },
              prepayPrice: { type: "string", description: "Prepay price TODO" },
              positionSelectable: { type: "boolean", description: "可以选位" },
              multipleCoach: { type: "boolean", description: "Multiple 项目" },
              eliteTrainingMember: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/MobileMemberDefault" }
                ],
                description: "Elite training member TODO"
              },
              banner: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头图"
              },
              homework: { type: "string", description: "Homework TODO" },
              confirmCourseThreshold: {
                type: "number",
                description: "Confirm course threshold TODO"
              },
              confirmCourseDeadline: {
                type: "number",
                description: "Confirm course deadline TODO"
              },
              hasConfirmCourseThreshold: {
                type: "boolean",
                description: "Has confirm course threshold TODO"
              },
              useHrm: { type: "boolean", description: "使用心率" }
            }
          },
          member: {
            description: "会员",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          owner: {
            description: "所有人",
            ref: "MemberMemberEssential",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "昵称" },
              userId: { type: "number", description: "用户ID" },
              realName: { type: "string", nullable: true, description: "姓名" },
              phone: { type: "string", nullable: true, description: "手机" },
              gender: {
                type: "string",
                required: false,
                enum: ["male", "female"],
                "x-i18n": { male: "男", female: "女" },
                description: "性别"
              },
              avatar: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/AttachmentDefault" }
                ],
                description: "头像"
              },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "标签"
              },
              memberType: { type: "string", description: "会员类型" }
            }
          },
          primaryCoachName: { type: "string", description: "教练" },
          studioName: { type: "string", description: "门店" },
          courseDuration: { type: "number", description: "课程时长" },
          hrSensor: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/StudioHrSensorDefault" }
            ],
            description: "心率带"
          },
          workflowState: { type: "string", description: "状态" },
          trainingCount: { type: "number", description: "训练数" },
          cancellable: { type: "boolean", description: "可以取消" },
          cancellationDeadline: { type: "number", description: "取消时间" },
          queuePosition: {
            type: "number",
            nullable: true,
            description: "排位顺序"
          },
          position: { type: "number", nullable: true, description: "位置" },
          coachComments: { type: "string", description: "教练点评" },
          rated: { type: "boolean", description: "已评价" },
          reviewed: { type: "boolean", description: "已评论" },
          isOwner: { type: "boolean", description: "所有人" },
          giftState: { type: "string", description: "赠课状态" },
          token: { type: "string", description: "token" }
        }
      },
      recentTrainings: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            course: {
              description: "课程",
              ref: "MobileCourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                courseName: { type: "string", description: "课程名称" },
                enCourseName: { type: "string", description: "英文课程名称" },
                zone: { description: "Zone TODO" },
                studio: {
                  description: "门店",
                  ref: "StudioStudioEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" },
                    active: { type: "boolean", description: "激活" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    display: { type: "boolean", description: "展示" },
                    address: { type: "string", description: "地址" },
                    contactPhone: { type: "string", description: "联系电话" },
                    longitude: { type: "string", description: "经度" },
                    latitude: { type: "string", description: "维度" },
                    gatePassword: { type: "string", description: "门禁密码" }
                  }
                },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                endTime: {
                  description: "结束时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    },
                    user: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/UserDefault" }
                      ],
                      description: "用户"
                    },
                    userId: { type: "number", description: "用户" },
                    profilePhoto: {
                      description: "形象照",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    wxQrCode: {
                      description: "微信二维码",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    level: {
                      description: "等级",
                      ref: "CoachLevelDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        category: {
                          description: "类型",
                          ref: "CoachCategoryDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" }
                          }
                        },
                        categoryId: { type: "number", description: "类型" }
                      }
                    },
                    dateOfBirth: {
                      description: "生日",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    joinedAt: {
                      description: "入职时间",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    primaryStudio: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/StudioStudioList" }
                      ],
                      description: "绑定门店"
                    },
                    scopeOfAccess: {
                      type: "string",
                      required: true,
                      enum: ["mine", "all"],
                      "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                      description: "查看排课范围"
                    },
                    introduction: { type: "string", description: "介绍" },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "教练风格"
                    },
                    certificationList: {
                      type: "array",
                      items: { type: "string" },
                      description: "技能认证"
                    },
                    styleList: {
                      type: "array",
                      items: { type: "string" },
                      description: "执教风格"
                    },
                    resumeDisplay: {
                      type: "boolean",
                      description: "执教数据展示"
                    },
                    eliteTrainingCapacity: {
                      type: "number",
                      description: "私教人数上限"
                    },
                    primaryStudioId: {
                      type: "number",
                      description: "绑定门店"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "授权所有训练产品"
                    },
                    levelId: { type: "number", description: "等级" },
                    createdAt: {
                      description: "加入时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    }
                  }
                },
                capacityState: { type: "string", description: "容量状态" },
                capacity: { type: "number", description: "容量" },
                bookingCount: { type: "number", description: "预约人数" },
                tags: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                productTags: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品标签"
                },
                productStages: {
                  type: "array",
                  items: { type: "string" },
                  description: "Product stages TODO"
                },
                productType: {
                  type: "string",
                  description: "Product type TODO"
                },
                paymentMethods: {
                  type: "array",
                  items: { type: "string" },
                  description: "Payment methods TODO"
                },
                bookState: { type: "string", description: "预约状态" },
                bookingId: { type: "number", description: "Booking TODO" },
                booking: {
                  description: "Booking TODO",
                  ref: "MobileCourseBookingGift",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    workflowState: { type: "string", description: "状态" },
                    isOwner: { type: "boolean", description: "所有人" },
                    giftState: { type: "string", description: "赠课状态" },
                    token: { type: "string", description: "token" }
                  }
                },
                campId: {
                  type: "number",
                  nullable: true,
                  description: "训练营"
                },
                price: { type: "string", description: "课程价格" },
                prepayPrice: {
                  type: "string",
                  description: "Prepay price TODO"
                },
                positionSelectable: {
                  type: "boolean",
                  description: "可以选位"
                },
                multipleCoach: {
                  type: "boolean",
                  description: "Multiple 项目"
                },
                eliteTrainingMember: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/MobileMemberDefault" }
                  ],
                  description: "Elite training member TODO"
                },
                banner: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头图"
                },
                homework: { type: "string", description: "Homework TODO" },
                confirmCourseThreshold: {
                  type: "number",
                  description: "Confirm course threshold TODO"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "Confirm course deadline TODO"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "Has confirm course threshold TODO"
                },
                useHrm: { type: "boolean", description: "使用心率" }
              }
            },
            member: {
              description: "会员",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            owner: {
              description: "所有人",
              ref: "MemberMemberEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "昵称" },
                userId: { type: "number", description: "用户ID" },
                realName: {
                  type: "string",
                  nullable: true,
                  description: "姓名"
                },
                phone: { type: "string", nullable: true, description: "手机" },
                gender: {
                  type: "string",
                  required: false,
                  enum: ["male", "female"],
                  "x-i18n": { male: "男", female: "女" },
                  description: "性别"
                },
                avatar: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头像"
                },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                memberType: { type: "string", description: "会员类型" }
              }
            },
            primaryCoachName: { type: "string", description: "教练" },
            studioName: { type: "string", description: "门店" },
            courseDuration: { type: "number", description: "课程时长" },
            hrSensor: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/StudioHrSensorDefault" }
              ],
              description: "心率带"
            },
            workflowState: { type: "string", description: "状态" },
            trainingCount: { type: "number", description: "训练数" },
            cancellable: { type: "boolean", description: "可以取消" },
            cancellationDeadline: { type: "number", description: "取消时间" },
            queuePosition: {
              type: "number",
              nullable: true,
              description: "排位顺序"
            },
            position: { type: "number", nullable: true, description: "位置" },
            coachComments: { type: "string", description: "教练点评" },
            rated: { type: "boolean", description: "已评价" },
            reviewed: { type: "boolean", description: "已评论" },
            isOwner: { type: "boolean", description: "所有人" },
            giftState: { type: "string", description: "赠课状态" },
            token: { type: "string", description: "token" }
          }
        },
        description: "Recent trainings TODO",
        ref: "MobileCourseBookingDefault"
      },
      recentFootprints: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            image: {
              description: "图片",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            course: {
              description: "课程",
              ref: "MobileCourseCourseDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                courseName: { type: "string", description: "课程名称" },
                enCourseName: { type: "string", description: "英文课程名称" },
                zone: { description: "Zone TODO" },
                studio: {
                  description: "门店",
                  ref: "StudioStudioEssential",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" },
                    active: { type: "boolean", description: "激活" },
                    eliteTraining: { type: "boolean", description: "可上私教" },
                    display: { type: "boolean", description: "展示" },
                    address: { type: "string", description: "地址" },
                    contactPhone: { type: "string", description: "联系电话" },
                    longitude: { type: "string", description: "经度" },
                    latitude: { type: "string", description: "维度" },
                    gatePassword: { type: "string", description: "门禁密码" }
                  }
                },
                startTime: {
                  description: "开始时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                endTime: {
                  description: "结束时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                },
                primaryCoach: {
                  description: "教练",
                  ref: "CoachCoachDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    phone: { type: "string", description: "手机号" },
                    avatar: {
                      description: "头像",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    name: { type: "string", description: "姓名" },
                    displayName: { type: "string", description: "昵称" },
                    note: { type: "string", description: "备注" },
                    active: { type: "boolean", description: "激活" },
                    certified: {
                      type: "boolean",
                      description: "Certified TODO"
                    },
                    user: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/UserDefault" }
                      ],
                      description: "用户"
                    },
                    userId: { type: "number", description: "用户" },
                    profilePhoto: {
                      description: "形象照",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    wxQrCode: {
                      description: "微信二维码",
                      ref: "AttachmentDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        signedId: { type: "string", description: "Signed ID" },
                        url: { type: "string", description: "URL" },
                        originalUrl: {
                          type: "string",
                          description: "原始文件 URL"
                        },
                        filename: { type: "string", description: "文件名" },
                        contentType: {
                          type: "string",
                          description: "文件类型"
                        }
                      }
                    },
                    level: {
                      description: "等级",
                      ref: "CoachLevelDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" },
                        category: {
                          description: "类型",
                          ref: "CoachCategoryDefault",
                          type: "object",
                          properties: {
                            id: { type: "number", description: "ID" },
                            name: { type: "string", description: "名称" }
                          }
                        },
                        categoryId: { type: "number", description: "类型" }
                      }
                    },
                    dateOfBirth: {
                      description: "生日",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    joinedAt: {
                      description: "入职时间",
                      ref: "date",
                      type: "string",
                      format: "date",
                      example: "2018-01-01",
                      properties: {}
                    },
                    primaryStudio: {
                      anyOf: [
                        { type: "null" },
                        { $ref: "#/definitions/StudioStudioList" }
                      ],
                      description: "绑定门店"
                    },
                    scopeOfAccess: {
                      type: "string",
                      required: true,
                      enum: ["mine", "all"],
                      "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                      description: "查看排课范围"
                    },
                    introduction: { type: "string", description: "介绍" },
                    tagList: {
                      type: "array",
                      items: { type: "string" },
                      description: "教练风格"
                    },
                    certificationList: {
                      type: "array",
                      items: { type: "string" },
                      description: "技能认证"
                    },
                    styleList: {
                      type: "array",
                      items: { type: "string" },
                      description: "执教风格"
                    },
                    resumeDisplay: {
                      type: "boolean",
                      description: "执教数据展示"
                    },
                    eliteTrainingCapacity: {
                      type: "number",
                      description: "私教人数上限"
                    },
                    primaryStudioId: {
                      type: "number",
                      description: "绑定门店"
                    },
                    applyToAllProduct: {
                      type: "boolean",
                      description: "授权所有训练产品"
                    },
                    levelId: { type: "number", description: "等级" },
                    createdAt: {
                      description: "加入时间",
                      ref: "datetime",
                      type: "string",
                      format: "date-time",
                      example: "2018-08-01T00:00:00.000Z",
                      properties: {}
                    }
                  }
                },
                capacityState: { type: "string", description: "容量状态" },
                capacity: { type: "number", description: "容量" },
                bookingCount: { type: "number", description: "预约人数" },
                tags: {
                  type: "array",
                  items: { type: "string" },
                  description: "标签"
                },
                productTags: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品标签"
                },
                productStages: {
                  type: "array",
                  items: { type: "string" },
                  description: "Product stages TODO"
                },
                productType: {
                  type: "string",
                  description: "Product type TODO"
                },
                paymentMethods: {
                  type: "array",
                  items: { type: "string" },
                  description: "Payment methods TODO"
                },
                bookState: { type: "string", description: "预约状态" },
                bookingId: { type: "number", description: "Booking TODO" },
                booking: {
                  description: "Booking TODO",
                  ref: "MobileCourseBookingGift",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    workflowState: { type: "string", description: "状态" },
                    isOwner: { type: "boolean", description: "所有人" },
                    giftState: { type: "string", description: "赠课状态" },
                    token: { type: "string", description: "token" }
                  }
                },
                campId: {
                  type: "number",
                  nullable: true,
                  description: "训练营"
                },
                price: { type: "string", description: "课程价格" },
                prepayPrice: {
                  type: "string",
                  description: "Prepay price TODO"
                },
                positionSelectable: {
                  type: "boolean",
                  description: "可以选位"
                },
                multipleCoach: {
                  type: "boolean",
                  description: "Multiple 项目"
                },
                eliteTrainingMember: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/MobileMemberDefault" }
                  ],
                  description: "Elite training member TODO"
                },
                banner: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/AttachmentDefault" }
                  ],
                  description: "头图"
                },
                homework: { type: "string", description: "Homework TODO" },
                confirmCourseThreshold: {
                  type: "number",
                  description: "Confirm course threshold TODO"
                },
                confirmCourseDeadline: {
                  type: "number",
                  description: "Confirm course deadline TODO"
                },
                hasConfirmCourseThreshold: {
                  type: "boolean",
                  description: "Has confirm course threshold TODO"
                },
                useHrm: { type: "boolean", description: "使用心率" }
              }
            },
            favorite: { type: "boolean", description: "收藏" },
            trainingNumber: { type: "number", description: "训练编号" }
          }
        },
        description: "Recent footprints TODO",
        ref: "CourseMemberFootprintDefault"
      }
    }
  },
  AdminDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      login: { type: "string", description: "帐号" },
      internal: { type: "boolean", description: "Internal TODO" },
      active: { type: "boolean", description: "Active TODO" },
      brand: {
        description: "品牌",
        ref: "BrandBrandDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          identifier: { type: "string", description: "识别码" },
          serviceProductList: {
            type: "array",
            items: { type: "string" },
            description: "产品类型"
          },
          saasType: {
            type: "string",
            required: false,
            enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
            "x-i18n": {
              zishi_power: "ZISHI POWER",
              zishi_power_kid: "ZISHI POWER KID",
              zishi_power_coach: "ZISHI POWER COACH"
            },
            description: "SaaS类型"
          },
          cancellationDeadline: {
            type: "number",
            description: "默认取消限制（小时）"
          },
          vipGroupTrainingDiscount: {
            type: "number",
            description: "团课储值卡折扣"
          },
          vipFreeTrainingDiscount: {
            type: "number",
            description: "自由训练储值卡折扣"
          },
          vipCampDiscount: { type: "number", description: "训练营储值卡折扣" },
          vipCouponPackageDiscount: {
            type: "number",
            description: "Vip coupon package discount TODO"
          },
          vipMembershipCardDiscount: {
            type: "number",
            description: "Vip membership card discount TODO"
          },
          vipCancellationDeadline: {
            type: "number",
            description: "会员卡约课取消限制（小时）"
          },
          scheduleDisplayStartTime: {
            type: "object",
            nullable: true,
            description: "课表展示开始时间"
          },
          scheduleDisplayEndTime: {
            type: "object",
            nullable: true,
            description: "课表展示结束时间"
          },
          prepayCardName: {
            type: "string",
            nullable: true,
            description: "储值卡名称"
          },
          logo: {
            description: "Logo",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          customType: { type: "string", description: "客户类型" },
          qrCode: {
            description: "小程序码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          role: {
            description: "权限组",
            ref: "PermissionRoleList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              active: { type: "boolean", description: "激活" },
              manageAllBrands: { type: "boolean", description: "管理所有品牌" }
            }
          },
          theme: {
            description: "小程序主题",
            ref: "BrandThemeDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              primaryColor: { type: "string", description: "主题色" },
              secondaryColor: { type: "string", description: "辅助色" }
            }
          },
          payConfig: {
            description: "支付配置",
            ref: "BrandPayConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              mchId: { type: "string", description: "微信商户ID" },
              certUploaded: { type: "boolean", description: "证书已上传" },
              active: { type: "boolean", description: "激活" }
            }
          },
          mpConfig: {
            description: "小程序配置",
            ref: "BrandMpConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              appId: { type: "string", description: "App Id" },
              active: { type: "boolean", description: "激活" }
            }
          },
          wxMessageTemplates: {
            description: "微信通知模板",
            ref: "BrandWxConfigMessageTemplates",
            type: "object",
            properties: {
              courseReminder: {
                type: "string",
                description: "Course reminder TODO"
              },
              bookingCancel: {
                type: "string",
                description: "Booking cancel TODO"
              },
              bookingQueueStatus: {
                type: "string",
                description: "Booking queue status TODO"
              },
              trainingResult: {
                type: "string",
                description: "Training result TODO"
              },
              footprintUploaded: {
                type: "string",
                description: "Footprint uploaded TODO"
              }
            }
          },
          courseMilestone: {
            type: "number",
            reuqired: true,
            description: "课程里程碑"
          },
          coachCourseMilestone: {
            type: "number",
            reuqired: true,
            description: "教练执教里程碑"
          },
          memberBookingMilestone: {
            type: "number",
            reuqired: true,
            description: "用户打卡里程碑"
          },
          communitySetting: {
            description: "社区设置",
            ref: "BrandCommunitySettingDefault",
            type: "object",
            properties: {
              enabled: { type: "number", description: "开启社群" },
              progressEnabled: { type: "boolean", description: "展示社群统计" },
              progressType: {
                type: "string",
                required: false,
                enum: ["", "training", "calories"],
                "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
                description: "社群统计数据类型"
              },
              progressAmplificationFactor: {
                type: "number",
                description: "社群统计数据放大系数"
              },
              leaderboardEnabled: {
                type: "boolean",
                description: "展示排行榜"
              },
              leaderboardTypes: {
                type: "array",
                items: { type: "string" },
                description: "展示排行榜类型"
              },
              tasLeaderboards: {
                type: "array",
                items: { type: "string" },
                description: "智慧屏榜单展示"
              },
              operatingLists: {
                type: "array",
                items: { type: "string" },
                description: "运营列表"
              },
              footprintsEnabled: {
                type: "boolean",
                description: "展示记忆相册"
              },
              footprintMemberCountThreshold: {
                type: "number",
                description: "记忆相册展示人数门槛"
              }
            }
          },
          expiryAlertEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "团课次数不足阈值（低于/次）"
          },
          expiryAlertEliteEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "私教次数不足阈值（低于/次）"
          },
          expiryAlertPrepayAmountThreshold: {
            type: "number",
            reuqired: true,
            description: "余额不足阈值（低于/元）"
          },
          expiryAlertValidityPeriodThreshold: {
            type: "number",
            reuqired: true,
            description: "账户临期阈值（仅剩/天）"
          },
          expiryDate: {
            type: "object",
            nullable: true,
            description: "授权截止"
          },
          newMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "新用户判定阈值（天）"
          },
          inactiveMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "团课不活跃阈值（天）"
          },
          inactiveEliteMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "私教不活跃阈值（天）"
          },
          userTerms: { type: "string", description: "用户协议" },
          hrmEnabled: { type: "boolean", description: "Hrm enabled TODO" },
          grantGateToMemberWithEffectiveAccount: {
            type: "boolean",
            description: "开启用户白名单"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      role: {
        description: "权限组",
        ref: "PermissionRoleList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          active: { type: "boolean", description: "激活" },
          manageAllBrands: { type: "boolean", description: "管理所有品牌" }
        }
      },
      roleId: { type: "number", description: "权限组" }
    }
  },
  AdminWithGrantedFeatures: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      login: { type: "string", description: "帐号" },
      internal: { type: "boolean", description: "Internal TODO" },
      active: { type: "boolean", description: "Active TODO" },
      brand: {
        description: "品牌",
        ref: "BrandBrandDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          identifier: { type: "string", description: "识别码" },
          serviceProductList: {
            type: "array",
            items: { type: "string" },
            description: "产品类型"
          },
          saasType: {
            type: "string",
            required: false,
            enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
            "x-i18n": {
              zishi_power: "ZISHI POWER",
              zishi_power_kid: "ZISHI POWER KID",
              zishi_power_coach: "ZISHI POWER COACH"
            },
            description: "SaaS类型"
          },
          cancellationDeadline: {
            type: "number",
            description: "默认取消限制（小时）"
          },
          vipGroupTrainingDiscount: {
            type: "number",
            description: "团课储值卡折扣"
          },
          vipFreeTrainingDiscount: {
            type: "number",
            description: "自由训练储值卡折扣"
          },
          vipCampDiscount: { type: "number", description: "训练营储值卡折扣" },
          vipCouponPackageDiscount: {
            type: "number",
            description: "Vip coupon package discount TODO"
          },
          vipMembershipCardDiscount: {
            type: "number",
            description: "Vip membership card discount TODO"
          },
          vipCancellationDeadline: {
            type: "number",
            description: "会员卡约课取消限制（小时）"
          },
          scheduleDisplayStartTime: {
            type: "object",
            nullable: true,
            description: "课表展示开始时间"
          },
          scheduleDisplayEndTime: {
            type: "object",
            nullable: true,
            description: "课表展示结束时间"
          },
          prepayCardName: {
            type: "string",
            nullable: true,
            description: "储值卡名称"
          },
          logo: {
            description: "Logo",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          customType: { type: "string", description: "客户类型" },
          qrCode: {
            description: "小程序码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          role: {
            description: "权限组",
            ref: "PermissionRoleList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              active: { type: "boolean", description: "激活" },
              manageAllBrands: { type: "boolean", description: "管理所有品牌" }
            }
          },
          theme: {
            description: "小程序主题",
            ref: "BrandThemeDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              primaryColor: { type: "string", description: "主题色" },
              secondaryColor: { type: "string", description: "辅助色" }
            }
          },
          payConfig: {
            description: "支付配置",
            ref: "BrandPayConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              mchId: { type: "string", description: "微信商户ID" },
              certUploaded: { type: "boolean", description: "证书已上传" },
              active: { type: "boolean", description: "激活" }
            }
          },
          mpConfig: {
            description: "小程序配置",
            ref: "BrandMpConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              appId: { type: "string", description: "App Id" },
              active: { type: "boolean", description: "激活" }
            }
          },
          wxMessageTemplates: {
            description: "微信通知模板",
            ref: "BrandWxConfigMessageTemplates",
            type: "object",
            properties: {
              courseReminder: {
                type: "string",
                description: "Course reminder TODO"
              },
              bookingCancel: {
                type: "string",
                description: "Booking cancel TODO"
              },
              bookingQueueStatus: {
                type: "string",
                description: "Booking queue status TODO"
              },
              trainingResult: {
                type: "string",
                description: "Training result TODO"
              },
              footprintUploaded: {
                type: "string",
                description: "Footprint uploaded TODO"
              }
            }
          },
          courseMilestone: {
            type: "number",
            reuqired: true,
            description: "课程里程碑"
          },
          coachCourseMilestone: {
            type: "number",
            reuqired: true,
            description: "教练执教里程碑"
          },
          memberBookingMilestone: {
            type: "number",
            reuqired: true,
            description: "用户打卡里程碑"
          },
          communitySetting: {
            description: "社区设置",
            ref: "BrandCommunitySettingDefault",
            type: "object",
            properties: {
              enabled: { type: "number", description: "开启社群" },
              progressEnabled: { type: "boolean", description: "展示社群统计" },
              progressType: {
                type: "string",
                required: false,
                enum: ["", "training", "calories"],
                "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
                description: "社群统计数据类型"
              },
              progressAmplificationFactor: {
                type: "number",
                description: "社群统计数据放大系数"
              },
              leaderboardEnabled: {
                type: "boolean",
                description: "展示排行榜"
              },
              leaderboardTypes: {
                type: "array",
                items: { type: "string" },
                description: "展示排行榜类型"
              },
              tasLeaderboards: {
                type: "array",
                items: { type: "string" },
                description: "智慧屏榜单展示"
              },
              operatingLists: {
                type: "array",
                items: { type: "string" },
                description: "运营列表"
              },
              footprintsEnabled: {
                type: "boolean",
                description: "展示记忆相册"
              },
              footprintMemberCountThreshold: {
                type: "number",
                description: "记忆相册展示人数门槛"
              }
            }
          },
          expiryAlertEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "团课次数不足阈值（低于/次）"
          },
          expiryAlertEliteEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "私教次数不足阈值（低于/次）"
          },
          expiryAlertPrepayAmountThreshold: {
            type: "number",
            reuqired: true,
            description: "余额不足阈值（低于/元）"
          },
          expiryAlertValidityPeriodThreshold: {
            type: "number",
            reuqired: true,
            description: "账户临期阈值（仅剩/天）"
          },
          expiryDate: {
            type: "object",
            nullable: true,
            description: "授权截止"
          },
          newMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "新用户判定阈值（天）"
          },
          inactiveMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "团课不活跃阈值（天）"
          },
          inactiveEliteMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "私教不活跃阈值（天）"
          },
          userTerms: { type: "string", description: "用户协议" },
          hrmEnabled: { type: "boolean", description: "Hrm enabled TODO" },
          grantGateToMemberWithEffectiveAccount: {
            type: "boolean",
            description: "开启用户白名单"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      role: {
        description: "权限组",
        ref: "PermissionRoleList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          active: { type: "boolean", description: "激活" },
          manageAllBrands: { type: "boolean", description: "管理所有品牌" }
        }
      },
      roleId: { type: "number", description: "权限组" },
      grantedFeatures: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            text: { type: "string", description: "Text TODO" },
            fullName: { type: "string", description: "Full name TODO" },
            featureType: { type: "string", description: "Feature type TODO" },
            tips: { type: "string", description: "Tips TODO" },
            parentId: { type: "number", description: "Parent TODO" },
            position: { type: "string", description: "Position TODO" }
          }
        },
        description: "授权",
        ref: "PermissionFeatureDefault"
      }
    }
  },
  AdminAsBrandAdmin: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      login: { type: "string", description: "帐号" },
      internal: { type: "boolean", description: "Internal TODO" },
      active: { type: "boolean", description: "Active TODO" },
      brand: {
        description: "品牌",
        ref: "BrandBrandDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          identifier: { type: "string", description: "识别码" },
          serviceProductList: {
            type: "array",
            items: { type: "string" },
            description: "产品类型"
          },
          saasType: {
            type: "string",
            required: false,
            enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
            "x-i18n": {
              zishi_power: "ZISHI POWER",
              zishi_power_kid: "ZISHI POWER KID",
              zishi_power_coach: "ZISHI POWER COACH"
            },
            description: "SaaS类型"
          },
          cancellationDeadline: {
            type: "number",
            description: "默认取消限制（小时）"
          },
          vipGroupTrainingDiscount: {
            type: "number",
            description: "团课储值卡折扣"
          },
          vipFreeTrainingDiscount: {
            type: "number",
            description: "自由训练储值卡折扣"
          },
          vipCampDiscount: { type: "number", description: "训练营储值卡折扣" },
          vipCouponPackageDiscount: {
            type: "number",
            description: "Vip coupon package discount TODO"
          },
          vipMembershipCardDiscount: {
            type: "number",
            description: "Vip membership card discount TODO"
          },
          vipCancellationDeadline: {
            type: "number",
            description: "会员卡约课取消限制（小时）"
          },
          scheduleDisplayStartTime: {
            type: "object",
            nullable: true,
            description: "课表展示开始时间"
          },
          scheduleDisplayEndTime: {
            type: "object",
            nullable: true,
            description: "课表展示结束时间"
          },
          prepayCardName: {
            type: "string",
            nullable: true,
            description: "储值卡名称"
          },
          logo: {
            description: "Logo",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          customType: { type: "string", description: "客户类型" },
          qrCode: {
            description: "小程序码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          role: {
            description: "权限组",
            ref: "PermissionRoleList",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              active: { type: "boolean", description: "激活" },
              manageAllBrands: { type: "boolean", description: "管理所有品牌" }
            }
          },
          theme: {
            description: "小程序主题",
            ref: "BrandThemeDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              primaryColor: { type: "string", description: "主题色" },
              secondaryColor: { type: "string", description: "辅助色" }
            }
          },
          payConfig: {
            description: "支付配置",
            ref: "BrandPayConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              mchId: { type: "string", description: "微信商户ID" },
              certUploaded: { type: "boolean", description: "证书已上传" },
              active: { type: "boolean", description: "激活" }
            }
          },
          mpConfig: {
            description: "小程序配置",
            ref: "BrandMpConfigDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              appId: { type: "string", description: "App Id" },
              active: { type: "boolean", description: "激活" }
            }
          },
          wxMessageTemplates: {
            description: "微信通知模板",
            ref: "BrandWxConfigMessageTemplates",
            type: "object",
            properties: {
              courseReminder: {
                type: "string",
                description: "Course reminder TODO"
              },
              bookingCancel: {
                type: "string",
                description: "Booking cancel TODO"
              },
              bookingQueueStatus: {
                type: "string",
                description: "Booking queue status TODO"
              },
              trainingResult: {
                type: "string",
                description: "Training result TODO"
              },
              footprintUploaded: {
                type: "string",
                description: "Footprint uploaded TODO"
              }
            }
          },
          courseMilestone: {
            type: "number",
            reuqired: true,
            description: "课程里程碑"
          },
          coachCourseMilestone: {
            type: "number",
            reuqired: true,
            description: "教练执教里程碑"
          },
          memberBookingMilestone: {
            type: "number",
            reuqired: true,
            description: "用户打卡里程碑"
          },
          communitySetting: {
            description: "社区设置",
            ref: "BrandCommunitySettingDefault",
            type: "object",
            properties: {
              enabled: { type: "number", description: "开启社群" },
              progressEnabled: { type: "boolean", description: "展示社群统计" },
              progressType: {
                type: "string",
                required: false,
                enum: ["", "training", "calories"],
                "x-i18n": { "": "不展示", training: "打卡", calories: "燃脂" },
                description: "社群统计数据类型"
              },
              progressAmplificationFactor: {
                type: "number",
                description: "社群统计数据放大系数"
              },
              leaderboardEnabled: {
                type: "boolean",
                description: "展示排行榜"
              },
              leaderboardTypes: {
                type: "array",
                items: { type: "string" },
                description: "展示排行榜类型"
              },
              tasLeaderboards: {
                type: "array",
                items: { type: "string" },
                description: "智慧屏榜单展示"
              },
              operatingLists: {
                type: "array",
                items: { type: "string" },
                description: "运营列表"
              },
              footprintsEnabled: {
                type: "boolean",
                description: "展示记忆相册"
              },
              footprintMemberCountThreshold: {
                type: "number",
                description: "记忆相册展示人数门槛"
              }
            }
          },
          expiryAlertEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "团课次数不足阈值（低于/次）"
          },
          expiryAlertEliteEntryThreshold: {
            type: "number",
            reuqired: true,
            description: "私教次数不足阈值（低于/次）"
          },
          expiryAlertPrepayAmountThreshold: {
            type: "number",
            reuqired: true,
            description: "余额不足阈值（低于/元）"
          },
          expiryAlertValidityPeriodThreshold: {
            type: "number",
            reuqired: true,
            description: "账户临期阈值（仅剩/天）"
          },
          expiryDate: {
            type: "object",
            nullable: true,
            description: "授权截止"
          },
          newMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "新用户判定阈值（天）"
          },
          inactiveMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "团课不活跃阈值（天）"
          },
          inactiveEliteMemberThreshold: {
            type: "number",
            reuqired: true,
            description: "私教不活跃阈值（天）"
          },
          userTerms: { type: "string", description: "用户协议" },
          hrmEnabled: { type: "boolean", description: "Hrm enabled TODO" },
          grantGateToMemberWithEffectiveAccount: {
            type: "boolean",
            description: "开启用户白名单"
          },
          active: { type: "boolean", description: "激活" }
        }
      },
      role: {
        description: "权限组",
        ref: "PermissionRoleList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          active: { type: "boolean", description: "激活" },
          manageAllBrands: { type: "boolean", description: "管理所有品牌" }
        }
      },
      roleId: { type: "number", description: "权限组" },
      grantedFeatures: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            text: { type: "string", description: "Text TODO" },
            fullName: { type: "string", description: "Full name TODO" },
            featureType: { type: "string", description: "Feature type TODO" },
            tips: { type: "string", description: "Tips TODO" },
            parentId: { type: "number", description: "Parent TODO" },
            position: { type: "string", description: "Position TODO" }
          }
        },
        description: "授权",
        ref: "PermissionFeatureDefault"
      }
    }
  },
  AdminForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      phone: { type: "string", required: true, description: "手机" },
      roleId: { type: "number", required: true, description: "权限组" }
    }
  },
  AdminPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            login: { type: "string", description: "帐号" },
            internal: { type: "boolean", description: "Internal TODO" },
            active: { type: "boolean", description: "Active TODO" },
            brand: {
              description: "品牌",
              ref: "BrandBrandDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                identifier: { type: "string", description: "识别码" },
                serviceProductList: {
                  type: "array",
                  items: { type: "string" },
                  description: "产品类型"
                },
                saasType: {
                  type: "string",
                  required: false,
                  enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
                  "x-i18n": {
                    zishi_power: "ZISHI POWER",
                    zishi_power_kid: "ZISHI POWER KID",
                    zishi_power_coach: "ZISHI POWER COACH"
                  },
                  description: "SaaS类型"
                },
                cancellationDeadline: {
                  type: "number",
                  description: "默认取消限制（小时）"
                },
                vipGroupTrainingDiscount: {
                  type: "number",
                  description: "团课储值卡折扣"
                },
                vipFreeTrainingDiscount: {
                  type: "number",
                  description: "自由训练储值卡折扣"
                },
                vipCampDiscount: {
                  type: "number",
                  description: "训练营储值卡折扣"
                },
                vipCouponPackageDiscount: {
                  type: "number",
                  description: "Vip coupon package discount TODO"
                },
                vipMembershipCardDiscount: {
                  type: "number",
                  description: "Vip membership card discount TODO"
                },
                vipCancellationDeadline: {
                  type: "number",
                  description: "会员卡约课取消限制（小时）"
                },
                scheduleDisplayStartTime: {
                  type: "object",
                  nullable: true,
                  description: "课表展示开始时间"
                },
                scheduleDisplayEndTime: {
                  type: "object",
                  nullable: true,
                  description: "课表展示结束时间"
                },
                prepayCardName: {
                  type: "string",
                  nullable: true,
                  description: "储值卡名称"
                },
                logo: {
                  description: "Logo",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                customType: { type: "string", description: "客户类型" },
                qrCode: {
                  description: "小程序码",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                role: {
                  description: "权限组",
                  ref: "PermissionRoleList",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    active: { type: "boolean", description: "激活" },
                    manageAllBrands: {
                      type: "boolean",
                      description: "管理所有品牌"
                    }
                  }
                },
                theme: {
                  description: "小程序主题",
                  ref: "BrandThemeDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    primaryColor: { type: "string", description: "主题色" },
                    secondaryColor: { type: "string", description: "辅助色" }
                  }
                },
                payConfig: {
                  description: "支付配置",
                  ref: "BrandPayConfigDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    mchId: { type: "string", description: "微信商户ID" },
                    certUploaded: {
                      type: "boolean",
                      description: "证书已上传"
                    },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                mpConfig: {
                  description: "小程序配置",
                  ref: "BrandMpConfigDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    appId: { type: "string", description: "App Id" },
                    active: { type: "boolean", description: "激活" }
                  }
                },
                wxMessageTemplates: {
                  description: "微信通知模板",
                  ref: "BrandWxConfigMessageTemplates",
                  type: "object",
                  properties: {
                    courseReminder: {
                      type: "string",
                      description: "Course reminder TODO"
                    },
                    bookingCancel: {
                      type: "string",
                      description: "Booking cancel TODO"
                    },
                    bookingQueueStatus: {
                      type: "string",
                      description: "Booking queue status TODO"
                    },
                    trainingResult: {
                      type: "string",
                      description: "Training result TODO"
                    },
                    footprintUploaded: {
                      type: "string",
                      description: "Footprint uploaded TODO"
                    }
                  }
                },
                courseMilestone: {
                  type: "number",
                  reuqired: true,
                  description: "课程里程碑"
                },
                coachCourseMilestone: {
                  type: "number",
                  reuqired: true,
                  description: "教练执教里程碑"
                },
                memberBookingMilestone: {
                  type: "number",
                  reuqired: true,
                  description: "用户打卡里程碑"
                },
                communitySetting: {
                  description: "社区设置",
                  ref: "BrandCommunitySettingDefault",
                  type: "object",
                  properties: {
                    enabled: { type: "number", description: "开启社群" },
                    progressEnabled: {
                      type: "boolean",
                      description: "展示社群统计"
                    },
                    progressType: {
                      type: "string",
                      required: false,
                      enum: ["", "training", "calories"],
                      "x-i18n": {
                        "": "不展示",
                        training: "打卡",
                        calories: "燃脂"
                      },
                      description: "社群统计数据类型"
                    },
                    progressAmplificationFactor: {
                      type: "number",
                      description: "社群统计数据放大系数"
                    },
                    leaderboardEnabled: {
                      type: "boolean",
                      description: "展示排行榜"
                    },
                    leaderboardTypes: {
                      type: "array",
                      items: { type: "string" },
                      description: "展示排行榜类型"
                    },
                    tasLeaderboards: {
                      type: "array",
                      items: { type: "string" },
                      description: "智慧屏榜单展示"
                    },
                    operatingLists: {
                      type: "array",
                      items: { type: "string" },
                      description: "运营列表"
                    },
                    footprintsEnabled: {
                      type: "boolean",
                      description: "展示记忆相册"
                    },
                    footprintMemberCountThreshold: {
                      type: "number",
                      description: "记忆相册展示人数门槛"
                    }
                  }
                },
                expiryAlertEntryThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "团课次数不足阈值（低于/次）"
                },
                expiryAlertEliteEntryThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "私教次数不足阈值（低于/次）"
                },
                expiryAlertPrepayAmountThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "余额不足阈值（低于/元）"
                },
                expiryAlertValidityPeriodThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "账户临期阈值（仅剩/天）"
                },
                expiryDate: {
                  type: "object",
                  nullable: true,
                  description: "授权截止"
                },
                newMemberThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "新用户判定阈值（天）"
                },
                inactiveMemberThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "团课不活跃阈值（天）"
                },
                inactiveEliteMemberThreshold: {
                  type: "number",
                  reuqired: true,
                  description: "私教不活跃阈值（天）"
                },
                userTerms: { type: "string", description: "用户协议" },
                hrmEnabled: {
                  type: "boolean",
                  description: "Hrm enabled TODO"
                },
                grantGateToMemberWithEffectiveAccount: {
                  type: "boolean",
                  description: "开启用户白名单"
                },
                active: { type: "boolean", description: "激活" }
              }
            },
            role: {
              description: "权限组",
              ref: "PermissionRoleList",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                active: { type: "boolean", description: "激活" },
                manageAllBrands: {
                  type: "boolean",
                  description: "管理所有品牌"
                }
              }
            },
            roleId: { type: "number", description: "权限组" }
          }
        },
        ref: "AdminDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  PermissionRolePagedArrayList: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            active: { type: "boolean", description: "激活" },
            manageAllBrands: { type: "boolean", description: "管理所有品牌" }
          }
        },
        ref: "PermissionRoleList"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  PlatformBrandBrandDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      identifier: { type: "string", description: "识别码" },
      serviceProductList: {
        type: "array",
        items: { type: "string" },
        description: "产品类型"
      },
      saasType: {
        type: "string",
        required: false,
        enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
        "x-i18n": {
          zishi_power: "ZISHI POWER",
          zishi_power_kid: "ZISHI POWER KID",
          zishi_power_coach: "ZISHI POWER COACH"
        },
        description: "SaaS类型"
      },
      logo: {
        description: "Logo",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      customType: { type: "string", description: "客户类型" },
      qrCode: {
        description: "小程序码",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      contactName: { type: "string", description: "联系人" },
      contactPhone: { type: "string", description: "联系电话" },
      expiryDate: {
        description: "到期时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      operatingTagList: {
        type: "array",
        items: { type: "string" },
        description: "运营标签"
      },
      industryList: {
        type: "array",
        items: { type: "string" },
        description: "行业"
      },
      provinceAndCity: { type: "string", description: "省/市" },
      province: { type: "string", description: "省" },
      city: { type: "string", description: "市" },
      companyName: { type: "string", description: "公司名称" },
      companyAddress: { type: "string", description: "公司地址" },
      role: {
        description: "权限组",
        ref: "PermissionRoleList",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          active: { type: "boolean", description: "激活" },
          manageAllBrands: { type: "boolean", description: "管理所有品牌" }
        }
      },
      theme: {
        description: "小程序主题",
        ref: "BrandThemeDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          primaryColor: { type: "string", description: "主题色" },
          secondaryColor: { type: "string", description: "辅助色" }
        }
      },
      payConfig: {
        description: "支付配置",
        ref: "BrandPayConfigDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          mchId: { type: "string", description: "微信商户ID" },
          certUploaded: { type: "boolean", description: "证书已上传" },
          active: { type: "boolean", description: "激活" }
        }
      },
      mpConfig: {
        description: "小程序配置",
        ref: "BrandMpConfigDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          appId: { type: "string", description: "App Id" },
          active: { type: "boolean", description: "激活" }
        }
      },
      tasExpiryDate: {
        description: "智慧屏",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  PlatformBrandBrandForm: {
    type: "object",
    properties: {
      name: { type: "string", required: true, description: "名称" },
      logo: {
        description: "Logo",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      identifier: { type: "string", required: true, description: "识别码" },
      serviceProductList: {
        type: "array",
        items: { type: "string" },
        description: "产品类型"
      },
      saasType: {
        type: "string",
        required: false,
        enum: ["zishi_power", "zishi_power_kid", "zishi_power_coach"],
        "x-i18n": {
          zishi_power: "ZISHI POWER",
          zishi_power_kid: "ZISHI POWER KID",
          zishi_power_coach: "ZISHI POWER COACH"
        },
        description: "SaaS类型"
      },
      customType: {
        type: "string",
        required: false,
        enum: ["normal", "internal"],
        "x-i18n": { normal: "普通", internal: "内部" },
        description: "客户类型"
      },
      contactName: { type: "string", required: true, description: "联系人" },
      contactPhone: { type: "string", required: true, description: "联系电话" },
      expiryDate: {
        description: "到期时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      operatingTagList: {
        type: "array",
        items: { type: "string" },
        description: "运营标签"
      },
      industryList: {
        type: "array",
        items: { type: "string" },
        description: "行业"
      },
      provinceAndCity: { type: "string", description: "省/市" },
      companyName: { type: "string", description: "公司名称" },
      companyAddress: { type: "string", description: "公司地址" },
      themeId: { type: "number", description: "小程序主题" },
      mpConfigId: { type: "number", required: true, description: "小程序配置" },
      payConfigId: { type: "number", description: "支付配置" },
      roleId: { type: "number", required: true, description: "权限组" },
      tasExpiryDate: {
        description: "智慧屏",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  PlatformBrandBrandGrantProductsForm: {
    type: "object",
    properties: {
      workoutIds: {
        type: "array",
        items: { type: "string" },
        description: "训练产品"
      },
      expiryDate: { type: "object", nullable: true, description: "到期时间" }
    }
  },
  PlatformBrandBrandOperating: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" }
    }
  },
  BrandMpConfigPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            appId: { type: "string", description: "App Id" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "BrandMpConfigDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  BrandPayConfigPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            mchId: { type: "string", description: "微信商户ID" },
            certUploaded: { type: "boolean", description: "证书已上传" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "BrandPayConfigDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  BrandThemePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            primaryColor: { type: "string", description: "主题色" },
            secondaryColor: { type: "string", description: "辅助色" }
          }
        },
        ref: "BrandThemeDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CoachCoachPagedArrayWithCoachProducts: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" },
            applyToAllProduct: {
              type: "boolean",
              description: "授权所有训练产品"
            },
            workoutVerificationsCount: {
              type: "number",
              description: "认证课程"
            },
            productIds: {
              type: "array",
              items: { type: "number" },
              description: "Product ids TODO"
            },
            operatingTagList: {
              type: "array",
              items: { type: "string" },
              description: "服务标签"
            },
            products: {
              type: "array",
              items: { type: "string" },
              description: "授权项目"
            }
          }
        },
        description: "数据",
        ref: "CoachCoachWithCoachProducts"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  PlatformCourseProductList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      brandId: { type: "number", description: "品牌" },
      name: { type: "string", description: "课程名称" },
      enName: { type: "string", description: "英文名称" },
      usageCn: { type: "string", description: "Usage cn TODO" },
      forKid: { type: "boolean", description: "For kid TODO" },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "Stage list TODO"
      },
      provider: { type: "string", description: "产品品牌" },
      expiryDate: {
        description: "授权截止",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      category: {
        type: "string",
        required: false,
        enum: ["brand", "platform"],
        "x-i18n": { brand: "自研产品", platform: "授权产品" },
        description: "类型"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "类型"
      },
      scheduleRule: { type: "string", description: "Schedule rule TODO" }
    }
  },
  PlatformCourseProductDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      brandId: { type: "number", description: "品牌" },
      name: { type: "string", description: "课程名称" },
      enName: { type: "string", description: "英文名称" },
      usageCn: { type: "string", description: "Usage cn TODO" },
      forKid: { type: "boolean", description: "For kid TODO" },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "Stage list TODO"
      },
      provider: { type: "string", description: "产品品牌" },
      expiryDate: {
        description: "授权截止",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      category: {
        type: "string",
        required: false,
        enum: ["brand", "platform"],
        "x-i18n": { brand: "自研产品", platform: "授权产品" },
        description: "类型"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "类型"
      },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "Schedule rule TODO"
      },
      price: { type: "string", description: "价格" },
      prepayPrice: { type: "string", description: "储值卡支付价格" },
      workoutId: { type: "number", description: "训练课程" },
      duration: { type: "number", description: "课程时长" },
      courseFee: { type: "string", description: "主教课时费" },
      assistantCourseFee: { type: "string", description: "助教课时费" },
      trialCourseFee: { type: "string", description: "实习课时费" },
      intro: { type: "string", nullable: true, description: "课程介绍" },
      groundType: {
        type: "string",
        required: false,
        enum: ["indoor", "outdoor"],
        "x-i18n": { indoor: "室内", outdoor: "户外" },
        description: "Ground type TODO"
      },
      academy: { type: "boolean", description: "提供培训" },
      note: { type: "string", description: "授权备注" },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentDefault"
      },
      coachProductId: { type: "number", description: "Coach product TODO" },
      tagList: {
        type: "array",
        items: { type: "string", enum: [] },
        description: "标签"
      },
      paymentMethods: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["wechat", "prepay_card", "membership_card", "coupon"],
          "x-i18n": {
            wechat: "微信支付",
            prepay_card: "储值账户",
            membership_card: "会员卡",
            coupon: "代金券"
          }
        },
        description: "支付方式"
      },
      hasConfirmCourseThreshold: {
        type: "boolean",
        description: "单独设置开课限制"
      },
      confirmCourseThreshold: { type: "number", description: "最低开课人数" },
      confirmCourseDeadline: { type: "number", description: "开课确认时间" },
      defaultCaloriesTarget: { type: "number", description: "默认卡路里目标" },
      tasType: {
        type: "string",
        required: false,
        enum: ["tas", "hrm", "countdown"],
        "x-i18n": { tas: "内容辅助", hrm: "心率辅助", countdown: "时间辅助" },
        description: "辅助系统配置"
      },
      multiScreenVersions: {
        type: "array",
        items: { type: "number" },
        description: "多屏幕适配"
      },
      displayed: { type: "boolean", description: "Displayed TODO" },
      active: { type: "boolean", description: "激活" },
      outlinePermissionsCount: { type: "number", description: "授权套路数量" },
      position: { type: "number", description: "排序" }
    }
  },
  PlatformCourseProductDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      brandId: { type: "number", description: "品牌" },
      name: { type: "string", description: "课程名称" },
      enName: { type: "string", description: "英文名称" },
      usageCn: { type: "string", description: "Usage cn TODO" },
      forKid: { type: "boolean", description: "For kid TODO" },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "Stage list TODO"
      },
      provider: { type: "string", description: "产品品牌" },
      expiryDate: {
        description: "授权截止",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      category: {
        type: "string",
        required: false,
        enum: ["brand", "platform"],
        "x-i18n": { brand: "自研产品", platform: "授权产品" },
        description: "类型"
      },
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "类型"
      },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "Schedule rule TODO"
      },
      price: { type: "string", description: "价格" },
      prepayPrice: { type: "string", description: "储值卡支付价格" },
      workoutId: { type: "number", description: "训练课程" },
      duration: { type: "number", description: "课程时长" },
      courseFee: { type: "string", description: "主教课时费" },
      assistantCourseFee: { type: "string", description: "助教课时费" },
      trialCourseFee: { type: "string", description: "实习课时费" },
      intro: { type: "string", nullable: true, description: "课程介绍" },
      groundType: {
        type: "string",
        required: false,
        enum: ["indoor", "outdoor"],
        "x-i18n": { indoor: "室内", outdoor: "户外" },
        description: "Ground type TODO"
      },
      academy: { type: "boolean", description: "提供培训" },
      note: { type: "string", description: "授权备注" },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentDefault"
      },
      coachProductId: { type: "number", description: "Coach product TODO" },
      tagList: {
        type: "array",
        items: { type: "string", enum: [] },
        description: "标签"
      },
      paymentMethods: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["wechat", "prepay_card", "membership_card", "coupon"],
          "x-i18n": {
            wechat: "微信支付",
            prepay_card: "储值账户",
            membership_card: "会员卡",
            coupon: "代金券"
          }
        },
        description: "支付方式"
      },
      hasConfirmCourseThreshold: {
        type: "boolean",
        description: "单独设置开课限制"
      },
      confirmCourseThreshold: { type: "number", description: "最低开课人数" },
      confirmCourseDeadline: { type: "number", description: "开课确认时间" },
      defaultCaloriesTarget: { type: "number", description: "默认卡路里目标" },
      tasType: {
        type: "string",
        required: false,
        enum: ["tas", "hrm", "countdown"],
        "x-i18n": { tas: "内容辅助", hrm: "心率辅助", countdown: "时间辅助" },
        description: "辅助系统配置"
      },
      multiScreenVersions: {
        type: "array",
        items: { type: "number" },
        description: "多屏幕适配"
      },
      displayed: { type: "boolean", description: "Displayed TODO" },
      active: { type: "boolean", description: "激活" },
      outlinePermissionsCount: { type: "number", description: "授权套路数量" },
      position: { type: "number", description: "排序" },
      studioIds: {
        type: "array",
        items: { type: "number" },
        description: "Studio ids TODO"
      }
    }
  },
  PlatformCourseProductPlatformForm: {
    type: "object",
    properties: {
      workoutId: { type: "number", description: "训练课程" },
      expiryDate: { type: "string", description: "授权截止" },
      displayed: { type: "boolean", description: "Displayed TODO" },
      position: { type: "number", description: "排序" },
      active: { type: "boolean", description: "激活" }
    }
  },
  PlatformCourseProductBrandForm: {
    type: "object",
    properties: {
      productType: {
        type: "string",
        required: false,
        enum: ["group_training", "elite_training", "camp", "free_training"],
        "x-i18n": {
          group_training: "团课",
          elite_training: "私教",
          camp: "训练营",
          free_training: "自由训练"
        },
        description: "类型"
      },
      groundType: {
        type: "string",
        required: false,
        enum: ["indoor", "outdoor"],
        "x-i18n": { indoor: "室内", outdoor: "户外" },
        description: "Ground type TODO"
      },
      workoutId: { type: "number", description: "训练课程" },
      name: { type: "string", description: "课程名称" },
      enName: { type: "string", description: "英文名称" },
      scheduleRule: {
        type: "string",
        required: false,
        enum: ["default", "follow_zone", "follow_coach"],
        "x-i18n": {
          default: "默认",
          follow_zone: "场地记忆",
          follow_coach: "教练记忆"
        },
        description: "Schedule rule TODO"
      },
      price: { type: "string", description: "价格" },
      duration: { type: "number", description: "课程时长" },
      expiryDate: { type: "string", description: "授权截止" },
      courseFee: { type: "string", description: "主教课时费" },
      assistantCourseFee: { type: "string", description: "助教课时费" },
      trialCourseFee: { type: "string", description: "实习课时费" },
      intro: { type: "string", description: "课程介绍" },
      academy: { type: "boolean", description: "提供培训" },
      tasType: {
        type: "string",
        required: false,
        enum: ["tas", "hrm", "countdown"],
        "x-i18n": { tas: "内容辅助", hrm: "心率辅助", countdown: "时间辅助" },
        description: "辅助系统配置"
      },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            signedId: { type: "string", description: "Signed ID" },
            remoteUrl: {
              type: "string",
              nullable: true,
              description: "文件远程 URL"
            },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "封面图",
        ref: "AttachmentForm"
      },
      stageList: {
        type: "array",
        items: { type: "string" },
        description: "Stage list TODO"
      },
      paymentMethods: {
        type: "array",
        items: {
          type: "string",
          required: false,
          enum: ["wechat", "prepay_card", "membership_card", "coupon"],
          "x-i18n": {
            wechat: "微信支付",
            prepay_card: "储值账户",
            membership_card: "会员卡",
            coupon: "代金券"
          }
        },
        description: "支付方式"
      },
      tagList: {
        type: "array",
        items: { type: "string", enum: [] },
        description: "标签"
      },
      defaultCaloriesTarget: { type: "number", description: "默认卡路里目标" },
      provider: {
        type: "string",
        required: false,
        enum: ["莱美", "其他"],
        "x-i18n": { 莱美: "莱美", 其他: "其他" },
        description: "产品品牌"
      },
      displayed: { type: "boolean", description: "Displayed TODO" },
      position: { type: "number", description: "排序" },
      active: { type: "boolean", description: "激活" }
    }
  },
  PlatformCourseProductOperating: {
    type: "object",
    properties: {
      brandName: { type: "string", description: "品牌名称" },
      contactPhone: { type: "string", description: "负责人电话" },
      name: { type: "string", description: "课程名称" },
      expiryDate: {
        description: "授权截止",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      }
    }
  },
  PlatformCourseProductPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            brandId: { type: "number", description: "品牌" },
            name: { type: "string", description: "课程名称" },
            enName: { type: "string", description: "英文名称" },
            usageCn: { type: "string", description: "Usage cn TODO" },
            forKid: { type: "boolean", description: "For kid TODO" },
            stageList: {
              type: "array",
              items: { type: "string" },
              description: "Stage list TODO"
            },
            provider: { type: "string", description: "产品品牌" },
            expiryDate: {
              description: "授权截止",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            category: {
              type: "string",
              required: false,
              enum: ["brand", "platform"],
              "x-i18n": { brand: "自研产品", platform: "授权产品" },
              description: "类型"
            },
            productType: {
              type: "string",
              required: false,
              enum: [
                "group_training",
                "elite_training",
                "camp",
                "free_training"
              ],
              "x-i18n": {
                group_training: "团课",
                elite_training: "私教",
                camp: "训练营",
                free_training: "自由训练"
              },
              description: "类型"
            },
            scheduleRule: {
              type: "string",
              required: false,
              enum: ["default", "follow_zone", "follow_coach"],
              "x-i18n": {
                default: "默认",
                follow_zone: "场地记忆",
                follow_coach: "教练记忆"
              },
              description: "Schedule rule TODO"
            },
            price: { type: "string", description: "价格" },
            prepayPrice: { type: "string", description: "储值卡支付价格" },
            workoutId: { type: "number", description: "训练课程" },
            duration: { type: "number", description: "课程时长" },
            courseFee: { type: "string", description: "主教课时费" },
            assistantCourseFee: { type: "string", description: "助教课时费" },
            trialCourseFee: { type: "string", description: "实习课时费" },
            intro: { type: "string", nullable: true, description: "课程介绍" },
            groundType: {
              type: "string",
              required: false,
              enum: ["indoor", "outdoor"],
              "x-i18n": { indoor: "室内", outdoor: "户外" },
              description: "Ground type TODO"
            },
            academy: { type: "boolean", description: "提供培训" },
            note: { type: "string", description: "授权备注" },
            banners: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "封面图",
              ref: "AttachmentDefault"
            },
            coachProductId: {
              type: "number",
              description: "Coach product TODO"
            },
            tagList: {
              type: "array",
              items: { type: "string", enum: [] },
              description: "标签"
            },
            paymentMethods: {
              type: "array",
              items: {
                type: "string",
                required: false,
                enum: ["wechat", "prepay_card", "membership_card", "coupon"],
                "x-i18n": {
                  wechat: "微信支付",
                  prepay_card: "储值账户",
                  membership_card: "会员卡",
                  coupon: "代金券"
                }
              },
              description: "支付方式"
            },
            hasConfirmCourseThreshold: {
              type: "boolean",
              description: "单独设置开课限制"
            },
            confirmCourseThreshold: {
              type: "number",
              description: "最低开课人数"
            },
            confirmCourseDeadline: {
              type: "number",
              description: "开课确认时间"
            },
            defaultCaloriesTarget: {
              type: "number",
              description: "默认卡路里目标"
            },
            tasType: {
              type: "string",
              required: false,
              enum: ["tas", "hrm", "countdown"],
              "x-i18n": {
                tas: "内容辅助",
                hrm: "心率辅助",
                countdown: "时间辅助"
              },
              description: "辅助系统配置"
            },
            multiScreenVersions: {
              type: "array",
              items: { type: "number" },
              description: "多屏幕适配"
            },
            displayed: { type: "boolean", description: "Displayed TODO" },
            active: { type: "boolean", description: "激活" },
            outlinePermissionsCount: {
              type: "number",
              description: "授权套路数量"
            },
            position: { type: "number", description: "排序" }
          }
        },
        ref: "PlatformCourseProductDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  KtasCharacterTrainingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      character: {
        type: "string",
        required: false,
        enum: [
          "专注",
          "友善",
          "感恩",
          "守时",
          "责任",
          "主动",
          "宽容",
          "思考",
          "勇敢",
          "尊重",
          "勤劳",
          "独立",
          "规则",
          "诚实守信"
        ],
        "x-i18n": {
          专注: "专注",
          友善: "友善",
          感恩: "感恩",
          守时: "守时",
          责任: "责任",
          主动: "主动",
          宽容: "宽容",
          思考: "思考",
          勇敢: "勇敢",
          尊重: "尊重",
          勤劳: "勤劳",
          独立: "独立",
          规则: "规则",
          诚实守信: "诚实守信"
        },
        description: "品格"
      },
      methods: { type: "object", description: "训练方法" },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasCharacterTrainingForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      character: {
        type: "string",
        required: false,
        enum: [
          "专注",
          "友善",
          "感恩",
          "守时",
          "责任",
          "主动",
          "宽容",
          "思考",
          "勇敢",
          "尊重",
          "勤劳",
          "独立",
          "规则",
          "诚实守信"
        ],
        "x-i18n": {
          专注: "专注",
          友善: "友善",
          感恩: "感恩",
          守时: "守时",
          责任: "责任",
          主动: "主动",
          宽容: "宽容",
          思考: "思考",
          勇敢: "勇敢",
          尊重: "尊重",
          勤劳: "勤劳",
          独立: "独立",
          规则: "规则",
          诚实守信: "诚实守信"
        },
        description: "品格"
      },
      methods: { type: "object", description: "训练方法" },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasCharacterTrainingPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            character: {
              type: "string",
              required: false,
              enum: [
                "专注",
                "友善",
                "感恩",
                "守时",
                "责任",
                "主动",
                "宽容",
                "思考",
                "勇敢",
                "尊重",
                "勤劳",
                "独立",
                "规则",
                "诚实守信"
              ],
              "x-i18n": {
                专注: "专注",
                友善: "友善",
                感恩: "感恩",
                守时: "守时",
                责任: "责任",
                主动: "主动",
                宽容: "宽容",
                思考: "思考",
                勇敢: "勇敢",
                尊重: "尊重",
                勤劳: "勤劳",
                独立: "独立",
                规则: "规则",
                诚实守信: "诚实守信"
              },
              description: "品格"
            },
            methods: { type: "object", description: "训练方法" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "KtasCharacterTrainingDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  KtasPerceptionTrainingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      category: {
        type: "string",
        required: false,
        enum: ["前庭觉", "本体觉", "感知觉", "感知配合"],
        "x-i18n": {
          前庭觉: "前庭觉",
          本体觉: "本体觉",
          感知觉: "感知觉",
          感知配合: "感知配合"
        },
        description: "分类"
      },
      content: { type: "object", description: "训练内容" },
      methods: { type: "object", description: "训练方法" },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasPerceptionTrainingForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      category: {
        type: "string",
        required: false,
        enum: ["前庭觉", "本体觉", "感知觉", "感知配合"],
        "x-i18n": {
          前庭觉: "前庭觉",
          本体觉: "本体觉",
          感知觉: "感知觉",
          感知配合: "感知配合"
        },
        description: "分类"
      },
      content: { type: "object", description: "训练内容" },
      methods: { type: "object", description: "训练方法" },
      active: { type: "boolean", description: "激活" }
    }
  },
  KtasPerceptionTrainingPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            category: {
              type: "string",
              required: false,
              enum: ["前庭觉", "本体觉", "感知觉", "感知配合"],
              "x-i18n": {
                前庭觉: "前庭觉",
                本体觉: "本体觉",
                感知觉: "感知觉",
                感知配合: "感知配合"
              },
              description: "分类"
            },
            content: { type: "object", description: "训练内容" },
            methods: { type: "object", description: "训练方法" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "KtasPerceptionTrainingDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  KtasStageDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      abbr: { type: "string", description: "缩写" },
      desc: { type: "object", description: "介绍" }
    }
  },
  KtasStageForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      abbr: { type: "string", description: "缩写" },
      desc: { type: "object", description: "介绍" }
    }
  },
  KtasStagePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            abbr: { type: "string", description: "缩写" },
            desc: { type: "object", description: "介绍" }
          }
        },
        ref: "KtasStageDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  BrandBrandPagedArrayOperating: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            contactName: { type: "string", description: "联系人" },
            contactPhone: { type: "string", description: "联系电话" },
            productsCount: { type: "number", description: "授权课程数" },
            coachesCount: { type: "number", description: "授权教练人数" },
            productCoursesCount: { type: "number", description: "排课数量" },
            productBookingsCount: { type: "number", description: "上课人次" },
            outlinePermissionsCount: {
              type: "number",
              description: "授权套路"
            },
            tasExpiryDate: { type: "string", description: "智慧屏" },
            serviceProductList: {
              type: "array",
              items: { type: "string" },
              description: "产品类型"
            },
            operatingTagList: {
              type: "array",
              items: { type: "string" },
              description: "服务标签"
            },
            productState: {
              type: "string",
              required: false,
              enum: ["授权中", "有过期"],
              "x-i18n": { 授权中: "授权中", 有过期: "有过期" },
              description: "授权状态"
            }
          }
        },
        ref: "BrandBrandOperating"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CoachCoachPagedArrayOperating: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            brand: {
              description: "所属品牌",
              ref: "BrandBrandBrief",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                identifier: { type: "string", description: "识别码" }
              }
            },
            operatingTagList: {
              type: "array",
              items: { type: "string" },
              description: "服务标签"
            },
            productsCount: { type: "number", description: "授权项目" },
            workoutVerificationsCount: {
              type: "number",
              description: "认证课程"
            },
            outlinePermissionsCount: {
              type: "number",
              description: "授权套路"
            },
            note: { type: "string", description: "备注" },
            firstExpiredDate: {
              description: "First expired date TODO",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            createdAt: {
              description: "加入时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            applyToAllProduct: {
              type: "boolean",
              description: "授权所有训练产品"
            },
            productState: {
              type: "string",
              required: false,
              enum: ["授权中", "有过期"],
              "x-i18n": { 授权中: "授权中", 有过期: "有过期" },
              description: "授权状态"
            }
          }
        },
        description: "数据",
        ref: "CoachCoachOperating"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  PriceInquiryDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      phone: { type: "string", description: "手机号" },
      city: { type: "string", description: "城市" },
      studioName: { type: "string", description: "门店名称" },
      contactMethod: { type: "string", description: "联系方式" },
      processed: { type: "boolean", description: "已处理" },
      createdAt: {
        description: "提交时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  PriceInquiryPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            city: { type: "string", description: "城市" },
            studioName: { type: "string", description: "门店名称" },
            contactMethod: { type: "string", description: "联系方式" },
            processed: { type: "boolean", description: "已处理" },
            createdAt: {
              description: "提交时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "PriceInquiryDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  StudioBlockPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            serial: { type: "string", description: "编号" },
            blockTypeName: { type: "string", description: "名称" },
            zoneId: { type: "number", description: "场地" },
            blockTypeId: { type: "number", description: "训练区类型" }
          }
        },
        ref: "StudioBlockDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasBgmDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      duration: { type: "number", description: "时长" },
      category: {
        type: "string",
        required: false,
        enum: ["暖场", "课间", "训练", "拉伸", "节奏"],
        "x-i18n": {
          暖场: "暖场",
          课间: "课间",
          训练: "训练",
          拉伸: "拉伸",
          节奏: "节奏"
        },
        description: "类型"
      },
      file: {
        description: "文件",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      fileUrl: { type: "string", description: "文件 URL" },
      count: { type: "number", description: "使用次数" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "曲库标签"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasBgmTas: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      duration: { type: "number", description: "时长" },
      category: {
        type: "string",
        required: false,
        enum: ["暖场", "课间", "训练", "拉伸", "节奏"],
        "x-i18n": {
          暖场: "暖场",
          课间: "课间",
          训练: "训练",
          拉伸: "拉伸",
          节奏: "节奏"
        },
        description: "类型"
      },
      fileUrl: { type: "string", description: "文件 URL" }
    }
  },
  TasBgmForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称" },
      category: {
        type: "string",
        required: false,
        enum: ["暖场", "课间", "训练", "拉伸", "节奏"],
        "x-i18n": {
          暖场: "暖场",
          课间: "课间",
          训练: "训练",
          拉伸: "拉伸",
          节奏: "节奏"
        },
        description: "类型"
      },
      file: {
        description: "文件",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      count: { type: "number", description: "使用次数" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "曲库标签"
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasBgmPagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            duration: { type: "number", description: "时长" },
            category: {
              type: "string",
              required: false,
              enum: ["暖场", "课间", "训练", "拉伸", "节奏"],
              "x-i18n": {
                暖场: "暖场",
                课间: "课间",
                训练: "训练",
                拉伸: "拉伸",
                节奏: "节奏"
              },
              description: "类型"
            },
            file: {
              description: "文件",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            fileUrl: { type: "string", description: "文件 URL" },
            count: { type: "number", description: "使用次数" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "曲库标签"
            },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "TasBgmDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasBlockTypeList: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasBlockTypeDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasBlockTypeDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      notes: { type: "object", description: "备注" },
      category: { type: "string", description: "Category TODO" },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasBlockTypeForm: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      notes: { type: "object", description: "备注" },
      category: { type: "string", description: "Category TODO" },
      active: { type: "boolean", description: "激活" }
    }
  },
  TasBlockTypePagedArrayDefault: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "TasBlockTypeDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasSmallCircleDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      restDuration: { type: "number", description: "Rest duration TODO" },
      repeat: { type: "number", description: "Repeat TODO" }
    }
  },
  TasSmallCircleForm: {
    type: "object",
    properties: {
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      restDuration: { type: "number", description: "Rest duration TODO" },
      repeat: { type: "number", description: "Repeat TODO" }
    }
  },
  TasStationDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      tips: { type: "object", description: "Tips TODO" },
      weight: { type: "number", description: "体重" }
    }
  },
  TasStationForm: {
    type: "object",
    properties: { weight: { type: "number", description: "体重" } }
  },
  PlatformTasWorkoutOutlineList: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      note: { type: "object", description: "Note TODO" }
    }
  },
  PlatformTasWorkoutOutlineBrief: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" }
    }
  },
  PlatformTasWorkoutOutlineEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "Workflow state TODO"
      },
      circuitOutline: { type: "boolean", description: "Circuit outline TODO" },
      workoutVersion: {
        description: "Workout version TODO",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      note: { type: "object", description: "Note TODO" }
    }
  },
  PlatformTasWorkoutOutlineDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "Workflow state TODO"
      },
      circuitOutline: { type: "boolean", description: "Circuit outline TODO" },
      workoutVersion: {
        description: "Workout version TODO",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      note: { type: "object", description: "Note TODO" },
      bgmFade: { type: "boolean", description: "Bgm fade TODO" },
      instruction: { type: "object", description: "Instruction TODO" },
      workoutVersionId: { type: "number", description: "Workout version TODO" }
    }
  },
  PlatformTasBigCircleDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      enName: { type: "string", description: "En name TODO" },
      category: { type: "string", description: "Category TODO" },
      displayMode: { type: "string", description: "Display mode TODO" },
      introDuration: { type: "number", description: "Intro duration TODO" },
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      equipmentDuration: {
        type: "number",
        description: "Equipment duration TODO"
      },
      splitRest: { type: "boolean", description: "Split rest TODO" },
      splitTransition: {
        type: "boolean",
        description: "Split transition TODO"
      },
      introMode: { type: "string", description: "Intro mode TODO" },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tag list TODO"
      },
      distributionDuration: {
        type: "number",
        description: "Distribution duration TODO"
      },
      distributionDisplayMode: {
        type: "string",
        description: "Distribution display mode TODO"
      },
      restDuration: { type: "number", description: "Rest duration TODO" },
      circleTraining: { type: "boolean", description: "Circle training TODO" },
      titleDuration: { type: "number", description: "Title duration TODO" },
      screenTransitionDuration: {
        type: "number",
        description: "Screen transition duration TODO"
      },
      screenDuration: { type: "number", description: "Screen duration TODO" },
      countdownDuration: {
        type: "number",
        description: "Countdown duration TODO"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      duration: { type: "number", description: "Duration TODO" },
      trainingModeId: {
        type: "number",
        nullable: true,
        description: "Training mode TODO"
      },
      isCircle: { type: "boolean", description: "Is circle TODO" },
      videoUrl: {
        type: "string",
        nullable: true,
        description: "Video url TODO"
      },
      hideStationNo: { type: "boolean", description: "Hide station no TODO" },
      stationTransitionShowCalories: {
        type: "boolean",
        description: "Station transition show calories TODO"
      },
      guidedBeforeCourse: {
        type: "boolean",
        description: "Guided before course TODO"
      },
      screens: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            stationTransitionDuration: {
              type: "number",
              nullable: true,
              description: "Station transition duration TODO"
            },
            blockTypeName: {
              type: "string",
              nullable: true,
              description: "Block type name TODO"
            },
            blockTypeId: {
              type: "number",
              nullable: true,
              description: "Block type TODO"
            },
            hasSmallCircle: {
              type: "boolean",
              description: "Has small circle TODO"
            },
            smallCircleTransitionDuration: {
              type: "number",
              nullable: true,
              description: "Small circle transition duration TODO"
            },
            smallCircleRepeat: {
              type: "number",
              nullable: true,
              description: "Small circle repeat TODO"
            },
            contentImages: {
              anyOf: [
                { type: "null" },
                {
                  type: "array",
                  items: { $ref: "#/definitions/TasContentImageDefault" }
                }
              ],
              description: "Content images TODO"
            },
            practices: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  instruction: {
                    type: "object",
                    description: "Instruction TODO"
                  },
                  regressInstruction: {
                    type: "object",
                    description: "Regress instruction TODO"
                  },
                  screenInstruction: {
                    type: "object",
                    description: "Screen instruction TODO"
                  },
                  repeat: { type: "number", description: "Repeat TODO" },
                  lor: { type: "string", description: "Lor TODO" },
                  exercisePattern: {
                    type: "number",
                    description: "Exercise pattern TODO"
                  },
                  exercise: {
                    description: "Exercise TODO",
                    ref: "PlatformTasExerciseDetail",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      serial: { type: "string", description: "编号" },
                      name: { type: "string", description: "名称" },
                      brandId: { type: "number", description: "Brand TODO" },
                      mp4: {
                        description: "视频",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      coverPicture: {
                        description: "封面图",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      presentPicture: {
                        description: "缩略图",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      command: {
                        description: "口令",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      positionList: {
                        type: "array",
                        required: false,
                        items: {
                          type: "string",
                          enum: [
                            "travelling",
                            "standing",
                            "sitting",
                            "kneeling",
                            "prone",
                            "supine",
                            "side lying",
                            "support hold",
                            "jump",
                            "hinged",
                            "Quadruped",
                            "other"
                          ],
                          "x-i18n": {
                            travelling: "移动",
                            standing: "站姿",
                            sitting: "坐姿",
                            kneeling: "跪姿",
                            prone: "俯卧",
                            supine: "仰卧",
                            "side lying": "侧卧",
                            "support hold": "支撑",
                            jump: "跳跃",
                            hinged: "髋屈伸",
                            Quadruped: "四足支撑",
                            other: "其他"
                          }
                        },
                        description: "姿态"
                      },
                      primaryEquipment: { description: "主器械" },
                      active: { type: "boolean", description: "激活" },
                      enName: { type: "string", description: "英文名称" },
                      bodyWeight: { type: "boolean", description: "自重" },
                      secondaryEquipment: {
                        description: "Secondary equipment TODO",
                        ref: "TasEquipmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" },
                          enName: {
                            type: "string",
                            description: "En name TODO"
                          },
                          code: { type: "string", description: "Code TODO" }
                        }
                      },
                      tips: { type: "object", description: "备注" },
                      cnCoachingCues: {
                        type: "object",
                        description: "中文教案"
                      },
                      coachingCues: { type: "object", description: "教案" },
                      shot: { type: "string", description: "拍摄视角" },
                      notGuided: { type: "boolean", description: "不纳入指导" }
                    }
                  },
                  guided: { type: "boolean", description: "Guided TODO" },
                  stepType: { type: "string", description: "Step type TODO" },
                  essential: { type: "boolean", description: "Essential TODO" }
                }
              },
              description: "Practices TODO",
              ref: "PlatformTasPracticeDetail"
            },
            hasCoachingStep: {
              type: "boolean",
              description: "Has coaching step TODO"
            }
          }
        },
        description: "Screens TODO",
        ref: "PlatformTasScreenDetail"
      },
      active: { type: "boolean", description: "Active TODO" }
    }
  },
  PlatformTasBigCircleForm: {
    type: "object",
    properties: {
      name: { type: "string", description: "Name TODO" },
      enName: { type: "string", description: "En name TODO" },
      category: { type: "string", description: "Category TODO" },
      displayMode: { type: "string", description: "Display mode TODO" },
      introDuration: { type: "number", description: "Intro duration TODO" },
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      equipmentDuration: {
        type: "number",
        description: "Equipment duration TODO"
      },
      splitRest: { type: "boolean", description: "Split rest TODO" },
      splitTransition: {
        type: "boolean",
        description: "Split transition TODO"
      },
      introMode: { type: "string", description: "Intro mode TODO" },
      note: { type: "string", description: "Note TODO" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tag list TODO"
      },
      distributionDuration: {
        type: "number",
        description: "Distribution duration TODO"
      },
      distributionDisplayMode: {
        type: "string",
        description: "Distribution display mode TODO"
      },
      restDuration: { type: "number", description: "Rest duration TODO" },
      circleTraining: { type: "boolean", description: "Circle training TODO" },
      titleDuration: { type: "number", description: "Title duration TODO" },
      screenTransitionDuration: {
        type: "number",
        description: "Screen transition duration TODO"
      },
      screenDuration: { type: "number", description: "Screen duration TODO" },
      countdownDuration: {
        type: "number",
        description: "Countdown duration TODO"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      duration: { type: "number", description: "Duration TODO" },
      trainingModeId: {
        type: "number",
        nullable: true,
        description: "Training mode TODO"
      },
      isCircle: { type: "boolean", description: "Is circle TODO" },
      video: { type: "string", nullable: true, description: "Video TODO" },
      hideStationNo: { type: "boolean", description: "Hide station no TODO" },
      stationTransitionShowCalories: {
        type: "boolean",
        description: "Station transition show calories TODO"
      },
      guidedBeforeCourse: {
        type: "boolean",
        description: "Guided before course TODO"
      },
      active: { type: "boolean", description: "Active TODO" }
    }
  },
  PlatformTasBigCircleTas: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      enName: { type: "string", description: "En name TODO" },
      category: { type: "string", description: "Category TODO" },
      displayMode: { type: "string", description: "Display mode TODO" },
      introDuration: { type: "number", description: "Intro duration TODO" },
      stationTransitionDuration: {
        type: "number",
        description: "Station transition duration TODO"
      },
      equipmentDuration: {
        type: "number",
        description: "Equipment duration TODO"
      },
      splitRest: { type: "boolean", description: "Split rest TODO" },
      splitTransition: {
        type: "boolean",
        description: "Split transition TODO"
      },
      introMode: { type: "string", description: "Intro mode TODO" },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      bgmTagList: {
        type: "array",
        items: { type: "string" },
        description: "Bgm tag list TODO"
      },
      distributionDuration: {
        type: "number",
        description: "Distribution duration TODO"
      },
      distributionDisplayMode: {
        type: "string",
        description: "Distribution display mode TODO"
      },
      restDuration: { type: "number", description: "Rest duration TODO" },
      circleTraining: { type: "boolean", description: "Circle training TODO" },
      titleDuration: { type: "number", description: "Title duration TODO" },
      screenTransitionDuration: {
        type: "number",
        description: "Screen transition duration TODO"
      },
      screenDuration: { type: "number", description: "Screen duration TODO" },
      countdownDuration: {
        type: "number",
        description: "Countdown duration TODO"
      },
      repeat: { type: "number", description: "Repeat TODO" },
      duration: { type: "number", description: "Duration TODO" },
      trainingModeId: {
        type: "number",
        nullable: true,
        description: "Training mode TODO"
      },
      isCircle: { type: "boolean", description: "Is circle TODO" },
      videoUrl: {
        type: "string",
        nullable: true,
        description: "Video url TODO"
      },
      hideStationNo: { type: "boolean", description: "Hide station no TODO" },
      stationTransitionShowCalories: {
        type: "boolean",
        description: "Station transition show calories TODO"
      },
      guidedBeforeCourse: {
        type: "boolean",
        description: "Guided before course TODO"
      },
      screens: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            stationTransitionDuration: {
              type: "number",
              nullable: true,
              description: "Station transition duration TODO"
            },
            blockTypeName: {
              type: "string",
              nullable: true,
              description: "Block type name TODO"
            },
            blockTypeId: {
              type: "number",
              nullable: true,
              description: "Block type TODO"
            },
            hasSmallCircle: {
              type: "boolean",
              description: "Has small circle TODO"
            },
            smallCircleTransitionDuration: {
              type: "number",
              nullable: true,
              description: "Small circle transition duration TODO"
            },
            smallCircleRepeat: {
              type: "number",
              nullable: true,
              description: "Small circle repeat TODO"
            },
            contentImages: {
              anyOf: [
                { type: "null" },
                {
                  type: "array",
                  items: { $ref: "#/definitions/TasContentImageDefault" }
                }
              ],
              description: "Content images TODO"
            },
            practices: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  instruction: {
                    type: "object",
                    description: "Instruction TODO"
                  },
                  regressInstruction: {
                    type: "object",
                    description: "Regress instruction TODO"
                  },
                  screenInstruction: {
                    type: "object",
                    description: "Screen instruction TODO"
                  },
                  repeat: { type: "number", description: "Repeat TODO" },
                  lor: { type: "string", description: "Lor TODO" },
                  exercisePattern: {
                    type: "number",
                    description: "Exercise pattern TODO"
                  },
                  exercise: {
                    description: "Exercise TODO",
                    ref: "PlatformTasExerciseDetail",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      serial: { type: "string", description: "编号" },
                      name: { type: "string", description: "名称" },
                      brandId: { type: "number", description: "Brand TODO" },
                      mp4: {
                        description: "视频",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      coverPicture: {
                        description: "封面图",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      presentPicture: {
                        description: "缩略图",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      command: {
                        description: "口令",
                        ref: "AttachmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          signedId: {
                            type: "string",
                            description: "Signed ID"
                          },
                          url: { type: "string", description: "URL" },
                          originalUrl: {
                            type: "string",
                            description: "原始文件 URL"
                          },
                          filename: { type: "string", description: "文件名" },
                          contentType: {
                            type: "string",
                            description: "文件类型"
                          }
                        }
                      },
                      positionList: {
                        type: "array",
                        required: false,
                        items: {
                          type: "string",
                          enum: [
                            "travelling",
                            "standing",
                            "sitting",
                            "kneeling",
                            "prone",
                            "supine",
                            "side lying",
                            "support hold",
                            "jump",
                            "hinged",
                            "Quadruped",
                            "other"
                          ],
                          "x-i18n": {
                            travelling: "移动",
                            standing: "站姿",
                            sitting: "坐姿",
                            kneeling: "跪姿",
                            prone: "俯卧",
                            supine: "仰卧",
                            "side lying": "侧卧",
                            "support hold": "支撑",
                            jump: "跳跃",
                            hinged: "髋屈伸",
                            Quadruped: "四足支撑",
                            other: "其他"
                          }
                        },
                        description: "姿态"
                      },
                      primaryEquipment: { description: "主器械" },
                      active: { type: "boolean", description: "激活" },
                      enName: { type: "string", description: "英文名称" },
                      bodyWeight: { type: "boolean", description: "自重" },
                      secondaryEquipment: {
                        description: "Secondary equipment TODO",
                        ref: "TasEquipmentDefault",
                        type: "object",
                        properties: {
                          id: { type: "number", description: "ID" },
                          name: { type: "string", description: "名称" },
                          enName: {
                            type: "string",
                            description: "En name TODO"
                          },
                          code: { type: "string", description: "Code TODO" }
                        }
                      },
                      tips: { type: "object", description: "备注" },
                      cnCoachingCues: {
                        type: "object",
                        description: "中文教案"
                      },
                      coachingCues: { type: "object", description: "教案" },
                      shot: { type: "string", description: "拍摄视角" },
                      notGuided: { type: "boolean", description: "不纳入指导" }
                    }
                  },
                  guided: { type: "boolean", description: "Guided TODO" },
                  stepType: { type: "string", description: "Step type TODO" },
                  essential: { type: "boolean", description: "Essential TODO" }
                }
              },
              description: "Practices TODO",
              ref: "PlatformTasPracticeDetail"
            },
            hasCoachingStep: {
              type: "boolean",
              description: "Has coaching step TODO"
            }
          }
        },
        description: "Screens TODO",
        ref: "PlatformTasScreenDetail"
      },
      active: { type: "boolean", description: "Active TODO" }
    }
  },
  PlatformTasWorkoutOutlineDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "Workflow state TODO"
      },
      circuitOutline: { type: "boolean", description: "Circuit outline TODO" },
      workoutVersion: {
        description: "Workout version TODO",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      note: { type: "object", description: "Note TODO" },
      bgmFade: { type: "boolean", description: "Bgm fade TODO" },
      instruction: { type: "object", description: "Instruction TODO" },
      workoutVersionId: { type: "number", description: "Workout version TODO" },
      bigCircles: {
        type: "array",
        items: { type: "object" },
        description: "Big circles TODO"
      },
      workoutId: { type: "number", description: "Workout TODO" },
      playlistStrategy: {
        type: "string",
        required: false,
        enum: ["default", "custom"],
        "x-i18n": { default: "默认", custom: "自定义" },
        description: "Playlist strategy TODO"
      },
      introImage: {
        description: "Intro image TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      defaultPlaylist: {
        description: "Default playlist TODO",
        ref: "TasPlaylistDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          date: {
            description: "Date TODO",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          }
        }
      },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      bgmZipUrl: { type: "string", description: "Bgm zip url TODO" },
      duration: { type: "number", description: "Duration TODO" },
      updatedAt: {
        description: "Updated at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  PlatformTasWorkoutOutlineWithPlaylist: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "Workflow state TODO"
      },
      circuitOutline: { type: "boolean", description: "Circuit outline TODO" },
      workoutVersion: {
        description: "Workout version TODO",
        ref: "TasWorkoutVersionEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      note: { type: "object", description: "Note TODO" },
      bgmFade: { type: "boolean", description: "Bgm fade TODO" },
      instruction: { type: "object", description: "Instruction TODO" },
      workoutVersionId: { type: "number", description: "Workout version TODO" },
      bigCircles: {
        type: "array",
        items: { type: "object" },
        description: "Big circles TODO"
      },
      workoutId: { type: "number", description: "Workout TODO" },
      playlistStrategy: {
        type: "string",
        required: false,
        enum: ["default", "custom"],
        "x-i18n": { default: "默认", custom: "自定义" },
        description: "Playlist strategy TODO"
      },
      introImage: {
        description: "Intro image TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      defaultPlaylist: {
        description: "Default playlist TODO",
        ref: "TasPlaylistDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          date: {
            description: "Date TODO",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          }
        }
      },
      coachingImages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            signedId: { type: "string", description: "Signed ID" },
            url: { type: "string", description: "URL" },
            originalUrl: { type: "string", description: "原始文件 URL" },
            filename: { type: "string", description: "文件名" },
            contentType: { type: "string", description: "文件类型" }
          }
        },
        description: "Coaching images TODO",
        ref: "AttachmentDefault"
      },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      bgmZipUrl: { type: "string", description: "Bgm zip url TODO" },
      duration: { type: "number", description: "Duration TODO" },
      updatedAt: {
        description: "Updated at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      todayPlaylistItems: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            bgmId: { type: "number", description: "Bgm TODO" },
            bigCircleId: { type: "number", description: "Big circle TODO" },
            bgmName: { type: "string", description: "Bgm name TODO" },
            position: { type: "number", description: "排序" },
            category: { type: "string", description: "类型" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tag list TODO"
            },
            count: { type: "number", description: "Count TODO" },
            duration: { type: "number", description: "时长" },
            file: { type: "string", description: "File TODO" },
            offset: { type: "number", description: "Offset TODO" }
          }
        },
        description: "Today playlist items TODO",
        ref: "TasPlaylistItemDefault"
      }
    }
  },
  PlatformTasWorkoutOutlineStatistic: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      workoutName: { type: "string", description: "Workout name TODO" },
      packageSerial: { type: "string", description: "Package serial TODO" },
      packageDesc: { type: "object", description: "Package desc TODO" }
    }
  },
  PlatformTasWorkoutOutlineCreateForm: {
    type: "object",
    properties: {
      workflowState: {
        type: "string",
        required: false,
        enum: ["new", "certificated", "test", "abandoned"],
        "x-i18n": {
          new: "未认证",
          certificated: "已认证",
          test: "测试",
          abandoned: "未认证"
        },
        description: "Workflow state TODO"
      },
      introImage: {
        description: "Intro image TODO",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      playlistStrategy: {
        type: "string",
        required: false,
        enum: ["default", "custom"],
        "x-i18n": { default: "默认", custom: "自定义" },
        description: "Playlist strategy TODO"
      },
      note: { type: "string", description: "Note TODO" },
      bgmFade: { type: "boolean", description: "Bgm fade TODO" }
    }
  },
  PlatformTasWorkoutOutlineEditForm: {
    type: "object",
    properties: {
      note: { type: "string", description: "Note TODO" },
      introImage: {
        description: "Intro image TODO",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      playlistStrategy: {
        type: "string",
        required: false,
        enum: ["default", "custom"],
        "x-i18n": { default: "默认", custom: "自定义" },
        description: "Playlist strategy TODO"
      },
      coachingImages: {
        description: "Coaching images TODO",
        ref: "AttachmentForm",
        type: "object",
        properties: {
          signedId: { type: "string", description: "Signed ID" },
          remoteUrl: {
            type: "string",
            nullable: true,
            description: "文件远程 URL"
          },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      coachingCue: { type: "string", description: "Coaching cue TODO" },
      videoUrl: { type: "string", description: "Video url TODO" },
      instruction: { type: "string", description: "Instruction TODO" },
      bgmFade: { type: "boolean", description: "Bgm fade TODO" }
    }
  },
  PlatformTasWorkoutOutlinePagedArrayEssential: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            serial: { type: "string", description: "Serial TODO" },
            workflowState: {
              type: "string",
              required: false,
              enum: ["new", "certificated", "test", "abandoned"],
              "x-i18n": {
                new: "未认证",
                certificated: "已认证",
                test: "测试",
                abandoned: "未认证"
              },
              description: "Workflow state TODO"
            },
            circuitOutline: {
              type: "boolean",
              description: "Circuit outline TODO"
            },
            workoutVersion: {
              description: "Workout version TODO",
              ref: "TasWorkoutVersionEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "版本名称" },
                courseDuration: { type: "number", description: "课程时长" },
                workflowState: { type: "string", description: "状态" },
                note: { type: "object", description: "备注" },
                createdAt: {
                  description: "创建时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                }
              }
            },
            createdAt: {
              description: "Created at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            note: { type: "object", description: "Note TODO" }
          }
        },
        ref: "PlatformTasWorkoutOutlineEssential"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      endTime: {
        description: "结束时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      endAt: {
        description: "End at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      studioName: { type: "string", description: "Studio name TODO" },
      circuitOutline: { type: "boolean", description: "Circuit outline TODO" },
      zone: {
        description: "场地",
        ref: "StudioZoneDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "场地名称" },
          screenNum: {
            type: "string",
            required: true,
            enum: ["1", "2", "3", "6"],
            "x-i18n": {
              "1": "单屏幕",
              "2": "双屏幕",
              "3": "三屏幕",
              "6": "六屏幕"
            },
            description: "屏幕数量"
          },
          bgmBlockNo: { type: "string", description: "BGM主机编号" },
          hrmUrl: {
            type: "string",
            nullable: true,
            description: "心率系统 URL"
          },
          useHrm: { type: "boolean", description: "使用心率" },
          hrmDeviceNumber: { type: "string", description: "心率设备编号" },
          blocks: {
            type: "object",
            nullable: true,
            description: "Blocks TODO"
          },
          maxCapacity: {
            type: "number",
            nullable: true,
            description: "最大容量"
          },
          positionSelectable: { type: "boolean", description: "可以选位" },
          position: { type: "number", description: "排序" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          active: { type: "boolean", description: "激活" }
        }
      },
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "En course name TODO" },
      checkInQrCodeUrl: {
        type: "string",
        description: "Check in qr code url TODO"
      },
      coach: {
        description: "教练",
        ref: "CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" },
          user: {
            anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
            description: "用户"
          },
          userId: { type: "number", description: "用户" },
          profilePhoto: {
            description: "形象照",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          wxQrCode: {
            description: "微信二维码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          level: {
            description: "等级",
            ref: "CoachLevelDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              category: {
                description: "类型",
                ref: "CoachCategoryDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" }
                }
              },
              categoryId: { type: "number", description: "类型" }
            }
          },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          joinedAt: {
            description: "入职时间",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          primaryStudio: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/StudioStudioList" }
            ],
            description: "绑定门店"
          },
          scopeOfAccess: {
            type: "string",
            required: true,
            enum: ["mine", "all"],
            "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
            description: "查看排课范围"
          },
          introduction: { type: "string", description: "介绍" },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "教练风格"
          },
          certificationList: {
            type: "array",
            items: { type: "string" },
            description: "技能认证"
          },
          styleList: {
            type: "array",
            items: { type: "string" },
            description: "执教风格"
          },
          resumeDisplay: { type: "boolean", description: "执教数据展示" },
          eliteTrainingCapacity: {
            type: "number",
            description: "私教人数上限"
          },
          primaryStudioId: { type: "number", description: "绑定门店" },
          applyToAllProduct: {
            type: "boolean",
            description: "授权所有训练产品"
          },
          levelId: { type: "number", description: "等级" },
          createdAt: {
            description: "加入时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      assistantCoaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            phone: { type: "string", description: "手机号" },
            avatar: {
              description: "头像",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            name: { type: "string", description: "姓名" },
            displayName: { type: "string", description: "昵称" },
            note: { type: "string", description: "备注" },
            active: { type: "boolean", description: "激活" },
            certified: { type: "boolean", description: "Certified TODO" },
            user: {
              anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
              description: "用户"
            },
            userId: { type: "number", description: "用户" },
            profilePhoto: {
              description: "形象照",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            wxQrCode: {
              description: "微信二维码",
              ref: "AttachmentDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                signedId: { type: "string", description: "Signed ID" },
                url: { type: "string", description: "URL" },
                originalUrl: { type: "string", description: "原始文件 URL" },
                filename: { type: "string", description: "文件名" },
                contentType: { type: "string", description: "文件类型" }
              }
            },
            level: {
              description: "等级",
              ref: "CoachLevelDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                category: {
                  description: "类型",
                  ref: "CoachCategoryDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" }
                  }
                },
                categoryId: { type: "number", description: "类型" }
              }
            },
            dateOfBirth: {
              description: "生日",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            joinedAt: {
              description: "入职时间",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            primaryStudio: {
              anyOf: [
                { type: "null" },
                { $ref: "#/definitions/StudioStudioList" }
              ],
              description: "绑定门店"
            },
            scopeOfAccess: {
              type: "string",
              required: true,
              enum: ["mine", "all"],
              "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
              description: "查看排课范围"
            },
            introduction: { type: "string", description: "介绍" },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "教练风格"
            },
            certificationList: {
              type: "array",
              items: { type: "string" },
              description: "技能认证"
            },
            styleList: {
              type: "array",
              items: { type: "string" },
              description: "执教风格"
            },
            resumeDisplay: { type: "boolean", description: "执教数据展示" },
            eliteTrainingCapacity: {
              type: "number",
              description: "私教人数上限"
            },
            primaryStudioId: { type: "number", description: "绑定门店" },
            applyToAllProduct: {
              type: "boolean",
              description: "授权所有训练产品"
            },
            levelId: { type: "number", description: "等级" },
            createdAt: {
              description: "加入时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        description: "助教",
        ref: "CoachCoachDefault"
      },
      workoutVersion: {
        description: "Workout version TODO",
        ref: "TasWorkoutVersionTas",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          showCalories: { type: "boolean", description: "展示卡路里" },
          desc: { type: "object", description: "描述" },
          logoUrl: { type: "string", description: "Logo URL" },
          courseDuration: { type: "number", description: "课程时长" },
          trainingDuration: { type: "number", description: "训练时长" },
          notUseHrm: { type: "boolean", description: "不使用心率系统" }
        }
      },
      workout: {
        description: "Workout TODO",
        ref: "TasWorkoutVersionDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "版本名称" },
          courseDuration: { type: "number", description: "课程时长" },
          workflowState: { type: "string", description: "状态" },
          note: { type: "object", description: "备注" },
          createdAt: {
            description: "创建时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          groupCount: { type: "number", description: "分组组数" },
          groupSize: { type: "number", description: "每组人数" },
          groupRule: { type: "string", description: "分组规则" },
          desc: { type: "object", description: "描述" },
          logoUrl: { type: "string", description: "Logo URL" },
          introImagesDuration: {
            type: "number",
            description: "介绍图展示时长"
          },
          hasResultStep: { type: "boolean", description: "是否展示结果页" },
          resultImage: { type: "string", description: "结果图" },
          showCalories: { type: "boolean", description: "展示卡路里" },
          appropriateCrowd: { type: "object", description: "适合人群" },
          courseFeature: { type: "object", description: "课程特色" },
          workoutName: { type: "string", description: "课程产品名称" }
        }
      },
      historicalMaxCalories: {
        type: "string",
        description: "Historical max calories TODO"
      },
      christmasCourse: { type: "string", description: "Christmas course TODO" },
      campaignBgImages: {
        type: "string",
        description: "Campaign bg images TODO"
      },
      todayPlaylistItems: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            bgmId: { type: "number", description: "Bgm TODO" },
            bigCircleId: { type: "number", description: "Big circle TODO" },
            bgmName: { type: "string", description: "Bgm name TODO" },
            position: { type: "number", description: "排序" },
            category: { type: "string", description: "类型" },
            bgmTagList: {
              type: "array",
              items: { type: "string" },
              description: "Bgm tag list TODO"
            },
            count: { type: "number", description: "Count TODO" },
            duration: { type: "number", description: "时长" },
            file: { type: "string", description: "File TODO" },
            offset: { type: "number", description: "Offset TODO" }
          }
        },
        description: "Today playlist items TODO",
        ref: "TasPlaylistItemDefault"
      },
      trainingFlowAds: {
        description: "Training flow ads TODO",
        ref: "AdAdDetail",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          active: { type: "boolean", description: "激活" },
          url: { type: "string", nullable: true, description: "URL" },
          image: {
            description: "图片",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          position: { type: "number", description: "Position TODO" },
          locationList: {
            type: "array",
            items: {
              type: "string",
              required: false,
              enum: ["course", "outdoor_course", "training", "community"],
              "x-i18n": {
                course: "约课页面",
                outdoor_course: "户外约课页面",
                training: "训练页面",
                community: "社群页面"
              }
            },
            description: "展示位置"
          },
          studios: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                fullName: { type: "string", description: "门店全称" },
                shortName: { type: "string", description: "门店名称" }
              }
            },
            description: "关联门店",
            ref: "StudioStudioList"
          },
          applyToAllStudio: { type: "boolean", description: "所有门店可用" },
          type: {
            type: "string",
            required: false,
            enum: ["Ad::Banner", "Ad::FrontDeskAd", "Ad::TrainingFlowAd"],
            "x-i18n": {
              "Ad::Banner": "约课平台广告",
              "Ad::FrontDeskAd": "智能广告屏广告",
              "Ad::TrainingFlowAd": "训练辅助系统广告"
            },
            description: "类型"
          },
          duration: { type: "number", description: "时长" },
          studioIds: {
            type: "array",
            items: { type: "number" },
            description: "关联门店"
          },
          imageUrl: { type: "string", description: "图片URL" }
        }
      },
      introImage: {
        description: "Intro image TODO",
        ref: "AttachmentDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          signedId: { type: "string", description: "Signed ID" },
          url: { type: "string", description: "URL" },
          originalUrl: { type: "string", description: "原始文件 URL" },
          filename: { type: "string", description: "文件名" },
          contentType: { type: "string", description: "文件类型" }
        }
      },
      useHrm: { type: "boolean", description: "是否使用心率系统" },
      milestones: {
        description: "Milestones TODO",
        ref: "MilestoneTas",
        type: "object",
        properties: {
          coach: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/CoachCoachEssential" }
            ],
            description: "教练"
          },
          course: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/CourseCourseEssential" }
            ],
            description: "课程"
          },
          member: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/MemberMemberEssential" }
            ],
            description: "会员"
          },
          eventType: { type: "string", description: "类型" },
          tasScreenText: {
            type: "string",
            description: "Tas screen text TODO"
          }
        }
      }
    }
  },
  TasCourseCheckInScreen: {
    type: "object",
    properties: {
      courseName: { type: "string", description: "课程名称" },
      enCourseName: { type: "string", description: "En course name TODO" },
      checkInQrCodeUrl: {
        type: "string",
        description: "Check in qr code url TODO"
      },
      startTime: {
        description: "开始时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      coach: {
        description: "教练",
        ref: "CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          phone: { type: "string", description: "手机号" },
          avatar: {
            description: "头像",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          name: { type: "string", description: "姓名" },
          displayName: { type: "string", description: "昵称" },
          note: { type: "string", description: "备注" },
          active: { type: "boolean", description: "激活" },
          certified: { type: "boolean", description: "Certified TODO" },
          user: {
            anyOf: [{ type: "null" }, { $ref: "#/definitions/UserDefault" }],
            description: "用户"
          },
          userId: { type: "number", description: "用户" },
          profilePhoto: {
            description: "形象照",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          wxQrCode: {
            description: "微信二维码",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          level: {
            description: "等级",
            ref: "CoachLevelDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              category: {
                description: "类型",
                ref: "CoachCategoryDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" }
                }
              },
              categoryId: { type: "number", description: "类型" }
            }
          },
          dateOfBirth: {
            description: "生日",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          joinedAt: {
            description: "入职时间",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          primaryStudio: {
            anyOf: [
              { type: "null" },
              { $ref: "#/definitions/StudioStudioList" }
            ],
            description: "绑定门店"
          },
          scopeOfAccess: {
            type: "string",
            required: true,
            enum: ["mine", "all"],
            "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
            description: "查看排课范围"
          },
          introduction: { type: "string", description: "介绍" },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "教练风格"
          },
          certificationList: {
            type: "array",
            items: { type: "string" },
            description: "技能认证"
          },
          styleList: {
            type: "array",
            items: { type: "string" },
            description: "执教风格"
          },
          resumeDisplay: { type: "boolean", description: "执教数据展示" },
          eliteTrainingCapacity: {
            type: "number",
            description: "私教人数上限"
          },
          primaryStudioId: { type: "number", description: "绑定门店" },
          applyToAllProduct: {
            type: "boolean",
            description: "授权所有训练产品"
          },
          levelId: { type: "number", description: "等级" },
          createdAt: {
            description: "加入时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      zone: {
        description: "场地",
        ref: "StudioZoneDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "场地名称" },
          screenNum: {
            type: "string",
            required: true,
            enum: ["1", "2", "3", "6"],
            "x-i18n": {
              "1": "单屏幕",
              "2": "双屏幕",
              "3": "三屏幕",
              "6": "六屏幕"
            },
            description: "屏幕数量"
          },
          bgmBlockNo: { type: "string", description: "BGM主机编号" },
          hrmUrl: {
            type: "string",
            nullable: true,
            description: "心率系统 URL"
          },
          useHrm: { type: "boolean", description: "使用心率" },
          hrmDeviceNumber: { type: "string", description: "心率设备编号" },
          blocks: {
            type: "object",
            nullable: true,
            description: "Blocks TODO"
          },
          maxCapacity: {
            type: "number",
            nullable: true,
            description: "最大容量"
          },
          positionSelectable: { type: "boolean", description: "可以选位" },
          position: { type: "number", description: "排序" },
          eliteTraining: { type: "boolean", description: "可上私教" },
          active: { type: "boolean", description: "激活" }
        }
      },
      workflowState: { type: "string", description: "状态" },
      christmasCourse: {
        type: "boolean",
        description: "Christmas course TODO"
      }
    }
  },
  TasStaticResourceDetail: {
    type: "object",
    properties: {
      images: {
        type: "array",
        items: {
          type: "object",
          properties: { url: { type: "string" }, type: { type: "string" } }
        },
        description: "图片"
      },
      audios: {
        type: "array",
        items: {
          type: "object",
          properties: { url: { type: "string" }, type: { type: "string" } }
        },
        description: "Audios TODO"
      },
      preparationBgms: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            duration: { type: "number", description: "时长" },
            category: {
              type: "string",
              required: false,
              enum: ["暖场", "课间", "训练", "拉伸", "节奏"],
              "x-i18n": {
                暖场: "暖场",
                课间: "课间",
                训练: "训练",
                拉伸: "拉伸",
                节奏: "节奏"
              },
              description: "类型"
            },
            fileUrl: { type: "string", description: "文件 URL" }
          }
        },
        description: "Preparation bgms TODO",
        ref: "TasBgmTas"
      }
    }
  },
  TasConfigsDefault: {
    type: "object",
    properties: {
      bgmBlockNo: { type: "string", description: "Bgm block no TODO" }
    }
  },
  TasComingCourseTas: {
    type: "object",
    properties: {
      timeline: {
        description: "Timeline TODO",
        ref: "TasTimelineTas",
        type: "object",
        properties: {
          totalDuration: { type: "number", description: "Total duration TODO" },
          weight: { type: "number", description: "体重" },
          resultType: { type: "string", description: "Result type TODO" },
          frames: {
            type: "array",
            items: {
              type: "object",
              properties: {
                duration: { type: "number", description: "时长" },
                offset: { type: "number", description: "Offset TODO" },
                viewType: { type: "string", description: "View type TODO" },
                viewTypeCn: {
                  type: "string",
                  description: "View type cn TODO"
                },
                sourceType: { type: "string", description: "Source type TODO" },
                sourceIds: {
                  type: "array",
                  items: { type: "number" },
                  description: "Source ids TODO"
                },
                sourceAttribute: {
                  type: "string",
                  description: "Source attribute TODO"
                },
                stationNo: { type: "string", description: "Station no TODO" },
                screenInstruction: {
                  type: "string",
                  description: "Screen instruction TODO"
                },
                stationNos: {
                  type: "array",
                  items: { type: "string" },
                  description: "Station nos TODO"
                },
                bigCircleIndex: {
                  type: "number",
                  description: "Big circle index TODO"
                },
                beep: { type: "boolean", description: "Beep TODO" },
                playCommand: {
                  type: "boolean",
                  description: "Play command TODO"
                },
                remains: { type: "number", description: "Remains TODO" },
                trainingIndex: {
                  type: "number",
                  description: "Training index TODO"
                },
                totalTraining: {
                  type: "number",
                  description: "Total training TODO"
                },
                exerciseIndex: {
                  type: "number",
                  description: "Exercise index TODO"
                },
                volume: { type: "number", description: "Volume TODO" },
                totalExercises: {
                  type: "number",
                  description: "Total exercises TODO"
                },
                bgmTags: {
                  type: "array",
                  items: { type: "string" },
                  description: "Bgm tags TODO"
                },
                showNextExercise: {
                  type: "boolean",
                  description: "Show next exercise TODO"
                },
                timelines: {
                  type: "array",
                  items: { type: "object" },
                  description: "Timelines TODO"
                },
                images: {
                  type: "array",
                  items: { type: "string" },
                  description: "图片"
                }
              }
            },
            description: "Frames TODO",
            ref: "TasFrameTas"
          },
          outline: {
            description: "Outline TODO",
            ref: "TasWorkoutOutlineTas",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              serial: { type: "string", description: "编号" },
              workflowState: {
                type: "string",
                required: false,
                enum: ["new", "certificated", "test", "abandoned"],
                "x-i18n": {
                  new: "未认证",
                  certificated: "已认证",
                  test: "测试",
                  abandoned: "未认证"
                },
                description: "状态"
              },
              brandId: { type: "number", description: "Brand TODO" },
              circuitOutline: { type: "boolean", description: "循环训练" },
              workoutVersion: {
                description: "课程版本",
                ref: "TasWorkoutVersionEssential",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "版本名称" },
                  courseDuration: { type: "number", description: "课程时长" },
                  workflowState: { type: "string", description: "状态" },
                  note: { type: "object", description: "备注" },
                  createdAt: {
                    description: "创建时间",
                    ref: "datetime",
                    type: "string",
                    format: "date-time",
                    example: "2018-08-01T00:00:00.000Z",
                    properties: {}
                  }
                }
              },
              createdAt: {
                description: "创建时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              active: { type: "boolean", description: "Active TODO" },
              note: { type: "object", description: "名称" },
              bgmFade: { type: "boolean", description: "音乐淡入淡出" },
              playlistStrategy: {
                type: "string",
                description: "Playlist strategy TODO"
              },
              instruction: { type: "object", description: "说明" },
              workoutVersionId: {
                type: "number",
                description: "Workout version TODO"
              },
              packageId: { type: "number", description: "Package TODO" },
              duration: { type: "number", description: "Duration TODO" },
              essentialExercises: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "Id TODO" },
                    serial: { type: "string", description: "Serial TODO" },
                    name: { type: "string", description: "Name TODO" },
                    stationNo: {
                      type: "string",
                      description: "Station no TODO"
                    },
                    tips: { type: "object", description: "Tips TODO" },
                    cnCoachingCues: {
                      type: "object",
                      description: "Cn coaching cues TODO"
                    },
                    notGuided: {
                      type: "boolean",
                      description: "Not guided TODO"
                    },
                    command: { type: "string", description: "Command TODO" },
                    mp4: { type: "string", description: "Mp4 TODO" },
                    coverPicture: {
                      type: "string",
                      description: "Cover picture TODO"
                    },
                    presentPicture: {
                      type: "string",
                      description: "Present picture TODO"
                    },
                    repeat: { type: "number", description: "Repeat TODO" },
                    lor: { type: "string", description: "Lor TODO" },
                    instruction: {
                      type: "object",
                      description: "Instruction TODO"
                    }
                  }
                },
                description: "Essential exercises TODO",
                ref: "TasExerciseTas"
              }
            }
          },
          useHrm: { type: "boolean", description: "使用心率" }
        }
      },
      course: {
        description: "课程",
        ref: "TasCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          startTime: {
            description: "开始时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          endTime: {
            description: "结束时间",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          endAt: {
            description: "End at TODO",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          studioName: { type: "string", description: "Studio name TODO" },
          circuitOutline: {
            type: "boolean",
            description: "Circuit outline TODO"
          },
          zone: {
            description: "场地",
            ref: "StudioZoneDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "场地名称" },
              screenNum: {
                type: "string",
                required: true,
                enum: ["1", "2", "3", "6"],
                "x-i18n": {
                  "1": "单屏幕",
                  "2": "双屏幕",
                  "3": "三屏幕",
                  "6": "六屏幕"
                },
                description: "屏幕数量"
              },
              bgmBlockNo: { type: "string", description: "BGM主机编号" },
              hrmUrl: {
                type: "string",
                nullable: true,
                description: "心率系统 URL"
              },
              useHrm: { type: "boolean", description: "使用心率" },
              hrmDeviceNumber: { type: "string", description: "心率设备编号" },
              blocks: {
                type: "object",
                nullable: true,
                description: "Blocks TODO"
              },
              maxCapacity: {
                type: "number",
                nullable: true,
                description: "最大容量"
              },
              positionSelectable: { type: "boolean", description: "可以选位" },
              position: { type: "number", description: "排序" },
              eliteTraining: { type: "boolean", description: "可上私教" },
              active: { type: "boolean", description: "激活" }
            }
          },
          courseName: { type: "string", description: "课程名称" },
          enCourseName: { type: "string", description: "En course name TODO" },
          checkInQrCodeUrl: {
            type: "string",
            description: "Check in qr code url TODO"
          },
          coach: {
            description: "教练",
            ref: "CoachCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              phone: { type: "string", description: "手机号" },
              avatar: {
                description: "头像",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              name: { type: "string", description: "姓名" },
              displayName: { type: "string", description: "昵称" },
              note: { type: "string", description: "备注" },
              active: { type: "boolean", description: "激活" },
              certified: { type: "boolean", description: "Certified TODO" },
              user: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/UserDefault" }
                ],
                description: "用户"
              },
              userId: { type: "number", description: "用户" },
              profilePhoto: {
                description: "形象照",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              wxQrCode: {
                description: "微信二维码",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              level: {
                description: "等级",
                ref: "CoachLevelDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  name: { type: "string", description: "名称" },
                  category: {
                    description: "类型",
                    ref: "CoachCategoryDefault",
                    type: "object",
                    properties: {
                      id: { type: "number", description: "ID" },
                      name: { type: "string", description: "名称" }
                    }
                  },
                  categoryId: { type: "number", description: "类型" }
                }
              },
              dateOfBirth: {
                description: "生日",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              joinedAt: {
                description: "入职时间",
                ref: "date",
                type: "string",
                format: "date",
                example: "2018-01-01",
                properties: {}
              },
              primaryStudio: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/StudioStudioList" }
                ],
                description: "绑定门店"
              },
              scopeOfAccess: {
                type: "string",
                required: true,
                enum: ["mine", "all"],
                "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                description: "查看排课范围"
              },
              introduction: { type: "string", description: "介绍" },
              tagList: {
                type: "array",
                items: { type: "string" },
                description: "教练风格"
              },
              certificationList: {
                type: "array",
                items: { type: "string" },
                description: "技能认证"
              },
              styleList: {
                type: "array",
                items: { type: "string" },
                description: "执教风格"
              },
              resumeDisplay: { type: "boolean", description: "执教数据展示" },
              eliteTrainingCapacity: {
                type: "number",
                description: "私教人数上限"
              },
              primaryStudioId: { type: "number", description: "绑定门店" },
              applyToAllProduct: {
                type: "boolean",
                description: "授权所有训练产品"
              },
              levelId: { type: "number", description: "等级" },
              createdAt: {
                description: "加入时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              }
            }
          },
          assistantCoaches: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                phone: { type: "string", description: "手机号" },
                avatar: {
                  description: "头像",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                name: { type: "string", description: "姓名" },
                displayName: { type: "string", description: "昵称" },
                note: { type: "string", description: "备注" },
                active: { type: "boolean", description: "激活" },
                certified: { type: "boolean", description: "Certified TODO" },
                user: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/UserDefault" }
                  ],
                  description: "用户"
                },
                userId: { type: "number", description: "用户" },
                profilePhoto: {
                  description: "形象照",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                wxQrCode: {
                  description: "微信二维码",
                  ref: "AttachmentDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    signedId: { type: "string", description: "Signed ID" },
                    url: { type: "string", description: "URL" },
                    originalUrl: {
                      type: "string",
                      description: "原始文件 URL"
                    },
                    filename: { type: "string", description: "文件名" },
                    contentType: { type: "string", description: "文件类型" }
                  }
                },
                level: {
                  description: "等级",
                  ref: "CoachLevelDefault",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    category: {
                      description: "类型",
                      ref: "CoachCategoryDefault",
                      type: "object",
                      properties: {
                        id: { type: "number", description: "ID" },
                        name: { type: "string", description: "名称" }
                      }
                    },
                    categoryId: { type: "number", description: "类型" }
                  }
                },
                dateOfBirth: {
                  description: "生日",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                joinedAt: {
                  description: "入职时间",
                  ref: "date",
                  type: "string",
                  format: "date",
                  example: "2018-01-01",
                  properties: {}
                },
                primaryStudio: {
                  anyOf: [
                    { type: "null" },
                    { $ref: "#/definitions/StudioStudioList" }
                  ],
                  description: "绑定门店"
                },
                scopeOfAccess: {
                  type: "string",
                  required: true,
                  enum: ["mine", "all"],
                  "x-i18n": { mine: "查看个人排课", all: "查看所有排课" },
                  description: "查看排课范围"
                },
                introduction: { type: "string", description: "介绍" },
                tagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "教练风格"
                },
                certificationList: {
                  type: "array",
                  items: { type: "string" },
                  description: "技能认证"
                },
                styleList: {
                  type: "array",
                  items: { type: "string" },
                  description: "执教风格"
                },
                resumeDisplay: { type: "boolean", description: "执教数据展示" },
                eliteTrainingCapacity: {
                  type: "number",
                  description: "私教人数上限"
                },
                primaryStudioId: { type: "number", description: "绑定门店" },
                applyToAllProduct: {
                  type: "boolean",
                  description: "授权所有训练产品"
                },
                levelId: { type: "number", description: "等级" },
                createdAt: {
                  description: "加入时间",
                  ref: "datetime",
                  type: "string",
                  format: "date-time",
                  example: "2018-08-01T00:00:00.000Z",
                  properties: {}
                }
              }
            },
            description: "助教",
            ref: "CoachCoachDefault"
          },
          workoutVersion: {
            description: "Workout version TODO",
            ref: "TasWorkoutVersionTas",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "版本名称" },
              showCalories: { type: "boolean", description: "展示卡路里" },
              desc: { type: "object", description: "描述" },
              logoUrl: { type: "string", description: "Logo URL" },
              courseDuration: { type: "number", description: "课程时长" },
              trainingDuration: { type: "number", description: "训练时长" },
              notUseHrm: { type: "boolean", description: "不使用心率系统" }
            }
          },
          workout: {
            description: "Workout TODO",
            ref: "TasWorkoutVersionDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "版本名称" },
              courseDuration: { type: "number", description: "课程时长" },
              workflowState: { type: "string", description: "状态" },
              note: { type: "object", description: "备注" },
              createdAt: {
                description: "创建时间",
                ref: "datetime",
                type: "string",
                format: "date-time",
                example: "2018-08-01T00:00:00.000Z",
                properties: {}
              },
              groupCount: { type: "number", description: "分组组数" },
              groupSize: { type: "number", description: "每组人数" },
              groupRule: { type: "string", description: "分组规则" },
              desc: { type: "object", description: "描述" },
              logoUrl: { type: "string", description: "Logo URL" },
              introImagesDuration: {
                type: "number",
                description: "介绍图展示时长"
              },
              hasResultStep: { type: "boolean", description: "是否展示结果页" },
              resultImage: { type: "string", description: "结果图" },
              showCalories: { type: "boolean", description: "展示卡路里" },
              appropriateCrowd: { type: "object", description: "适合人群" },
              courseFeature: { type: "object", description: "课程特色" },
              workoutName: { type: "string", description: "课程产品名称" }
            }
          },
          historicalMaxCalories: {
            type: "string",
            description: "Historical max calories TODO"
          },
          christmasCourse: {
            type: "string",
            description: "Christmas course TODO"
          },
          campaignBgImages: {
            type: "string",
            description: "Campaign bg images TODO"
          },
          todayPlaylistItems: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                bgmId: { type: "number", description: "Bgm TODO" },
                bigCircleId: { type: "number", description: "Big circle TODO" },
                bgmName: { type: "string", description: "Bgm name TODO" },
                position: { type: "number", description: "排序" },
                category: { type: "string", description: "类型" },
                bgmTagList: {
                  type: "array",
                  items: { type: "string" },
                  description: "Bgm tag list TODO"
                },
                count: { type: "number", description: "Count TODO" },
                duration: { type: "number", description: "时长" },
                file: { type: "string", description: "File TODO" },
                offset: { type: "number", description: "Offset TODO" }
              }
            },
            description: "Today playlist items TODO",
            ref: "TasPlaylistItemDefault"
          },
          trainingFlowAds: {
            description: "Training flow ads TODO",
            ref: "AdAdDetail",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              name: { type: "string", description: "名称" },
              active: { type: "boolean", description: "激活" },
              url: { type: "string", nullable: true, description: "URL" },
              image: {
                description: "图片",
                ref: "AttachmentDefault",
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              position: { type: "number", description: "Position TODO" },
              locationList: {
                type: "array",
                items: {
                  type: "string",
                  required: false,
                  enum: ["course", "outdoor_course", "training", "community"],
                  "x-i18n": {
                    course: "约课页面",
                    outdoor_course: "户外约课页面",
                    training: "训练页面",
                    community: "社群页面"
                  }
                },
                description: "展示位置"
              },
              studios: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "number", description: "ID" },
                    name: { type: "string", description: "名称" },
                    fullName: { type: "string", description: "门店全称" },
                    shortName: { type: "string", description: "门店名称" }
                  }
                },
                description: "关联门店",
                ref: "StudioStudioList"
              },
              applyToAllStudio: {
                type: "boolean",
                description: "所有门店可用"
              },
              type: {
                type: "string",
                required: false,
                enum: ["Ad::Banner", "Ad::FrontDeskAd", "Ad::TrainingFlowAd"],
                "x-i18n": {
                  "Ad::Banner": "约课平台广告",
                  "Ad::FrontDeskAd": "智能广告屏广告",
                  "Ad::TrainingFlowAd": "训练辅助系统广告"
                },
                description: "类型"
              },
              duration: { type: "number", description: "时长" },
              studioIds: {
                type: "array",
                items: { type: "number" },
                description: "关联门店"
              },
              imageUrl: { type: "string", description: "图片URL" }
            }
          },
          introImage: {
            description: "Intro image TODO",
            ref: "AttachmentDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "ID" },
              signedId: { type: "string", description: "Signed ID" },
              url: { type: "string", description: "URL" },
              originalUrl: { type: "string", description: "原始文件 URL" },
              filename: { type: "string", description: "文件名" },
              contentType: { type: "string", description: "文件类型" }
            }
          },
          useHrm: { type: "boolean", description: "是否使用心率系统" },
          milestones: {
            description: "Milestones TODO",
            ref: "MilestoneTas",
            type: "object",
            properties: {
              coach: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/CoachCoachEssential" }
                ],
                description: "教练"
              },
              course: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/CourseCourseEssential" }
                ],
                description: "课程"
              },
              member: {
                anyOf: [
                  { type: "null" },
                  { $ref: "#/definitions/MemberMemberEssential" }
                ],
                description: "会员"
              },
              eventType: { type: "string", description: "类型" },
              tasScreenText: {
                type: "string",
                description: "Tas screen text TODO"
              }
            }
          }
        }
      },
      staticResources: {
        description: "Static resources TODO",
        ref: "TasStaticResourceDetail",
        type: "object",
        properties: {
          images: {
            type: "array",
            items: {
              type: "object",
              properties: { url: { type: "string" }, type: { type: "string" } }
            },
            description: "图片"
          },
          audios: {
            type: "array",
            items: {
              type: "object",
              properties: { url: { type: "string" }, type: { type: "string" } }
            },
            description: "Audios TODO"
          },
          preparationBgms: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                duration: { type: "number", description: "时长" },
                category: {
                  type: "string",
                  required: false,
                  enum: ["暖场", "课间", "训练", "拉伸", "节奏"],
                  "x-i18n": {
                    暖场: "暖场",
                    课间: "课间",
                    训练: "训练",
                    拉伸: "拉伸",
                    节奏: "节奏"
                  },
                  description: "类型"
                },
                fileUrl: { type: "string", description: "文件 URL" }
              }
            },
            description: "Preparation bgms TODO",
            ref: "TasBgmTas"
          }
        }
      },
      configs: {
        description: "Configs TODO",
        ref: "TasConfigsDefault",
        type: "object",
        properties: {
          bgmBlockNo: { type: "string", description: "Bgm block no TODO" }
        }
      }
    }
  },
  TasWorkoutVersionPagedArrayEssential: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "版本名称" },
            courseDuration: { type: "number", description: "课程时长" },
            workflowState: { type: "string", description: "状态" },
            note: { type: "object", description: "备注" },
            createdAt: {
              description: "创建时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "TasWorkoutVersionEssential"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasWorkoutPagedArrayEssential: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            abbr: { type: "string", description: "缩写" },
            enName: { type: "string", description: "英文名" },
            category: {
              type: "string",
              enum: [
                { 自研: "自研" },
                { 团操课: "团操课" },
                { 其他: "其他" },
                { 训练营: "训练营" }
              ],
              description: "类型"
            },
            defaultCaloriesTarget: {
              type: "number",
              description: "PowerHeart默认卡路里(Cal)"
            },
            trainingMethod: {
              type: "string",
              enum: [
                { offline: "线下训练" },
                { live: "线上课程" },
                { homework: "家庭作业" }
              ],
              description: "训练方式"
            },
            scheduleRule: {
              type: "string",
              required: false,
              enum: ["default", "follow_zone", "follow_coach"],
              "x-i18n": {
                default: "默认",
                follow_zone: "场地记忆",
                follow_coach: "教练记忆"
              },
              description: "排课规则"
            },
            productType: {
              type: "string",
              required: false,
              enum: ["group_training", "elite_training", "camp"],
              "x-i18n": {
                group_training: "团课",
                elite_training: "私教",
                camp: "训练营"
              },
              description: "产品类型"
            },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            banners: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "ID" },
                  signedId: { type: "string", description: "Signed ID" },
                  url: { type: "string", description: "URL" },
                  originalUrl: { type: "string", description: "原始文件 URL" },
                  filename: { type: "string", description: "文件名" },
                  contentType: { type: "string", description: "文件类型" }
                }
              },
              description: "封面图",
              ref: "AttachmentDefault"
            },
            intro: { type: "object", description: "介绍" },
            provider: {
              type: "string",
              required: false,
              enum: [
                "PowerGroup 精品小团课",
                "Power 45 精品团课",
                "POWER45+",
                "POWER45(2023版)",
                "P45培训",
                "MCPOWER",
                "BodyLight",
                "P45内容库",
                "团操课",
                "燃脂舞蹈",
                "CrossFit",
                "MCFATREBEL",
                "TTT",
                "场馆支持",
                "其他"
              ],
              "x-i18n": {
                "PowerGroup 精品小团课": "PowerGroup 精品小团课",
                "Power 45 精品团课": "Power 45 精品团课",
                "POWER45+": "POWER45+",
                "POWER45(2023版)": "POWER45(2023版)",
                P45培训: "P45培训",
                MCPOWER: "MCPOWER",
                BodyLight: "BodyLight",
                P45内容库: "P45内容库",
                团操课: "团操课",
                燃脂舞蹈: "燃脂舞蹈",
                CrossFit: "CrossFit",
                MCFATREBEL: "MCFATREBEL",
                TTT: "TTT",
                场馆支持: "场馆支持",
                其他: "其他"
              },
              description: "产品品牌"
            },
            duration: { type: "number", description: "时长" },
            usage: {
              type: "string",
              required: false,
              enum: ["default", "template"],
              "x-i18n": { default: "用于授权", template: "用于模板" },
              description: "使用方式"
            },
            usageCn: { type: "string", description: "Usage cn TODO" },
            coachingMode: { type: "boolean", description: "白板模式" },
            coachingModeTemplate: {
              type: "boolean",
              description: "白板课程模板"
            },
            tasType: { type: "string", description: "Tas type TODO" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "TasWorkoutEssential"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  TasStudioDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      theme: {
        description: "Theme TODO",
        ref: "BrandThemeDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          primaryColor: { type: "string", description: "主题色" },
          secondaryColor: { type: "string", description: "辅助色" }
        }
      },
      brandUseHrm: { type: "boolean", description: "Brand use hrm TODO" }
    }
  }
};
const pathRawData = {
  adAdIndex: {
    method: "get",
    url: "/ad/ads",
    operationId: "adAdIndex",
    summary: "广告列表",
    tags: "Ad#Ad",
    parameters: {
      path: {},
      query: {
        type: {
          name: "type",
          in: "query",
          type: "string",
          enum: ["Ad::Banner", "Ad::FrontDeskAd", "Ad::TrainingFlowAd"],
          "x-i18n": {
            "Ad::Banner": "约课平台广告",
            "Ad::FrontDeskAd": "智能广告屏广告",
            "Ad::TrainingFlowAd": "训练辅助系统广告"
          },
          description: "类型"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: { $ref: "AdAdPagedArrayDefault", type: "object" }
  },
  adAdCreate: {
    method: "post",
    url: "/ad/ads",
    operationId: "adAdCreate",
    summary: "新建广告",
    tags: "Ad#Ad",
    parameters: {
      path: {},
      body: {
        json: { name: "json", in: "body", schema: { $ref: "AdAdForm" } }
      }
    },
    responses: null
  },
  adAdShow: {
    method: "get",
    url: "/ad/ads/{id}",
    operationId: "adAdShow",
    summary: "广告详情",
    tags: "Ad#Ad",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "广告ID",
          required: true
        }
      }
    },
    responses: { $ref: "AdAdDetail", type: "object" }
  },
  adAdUpdate: {
    method: "put",
    url: "/ad/ads/{id}",
    operationId: "adAdUpdate",
    summary: "修改广告",
    tags: "Ad#Ad",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "广告ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "AdAdForm" } }
      }
    },
    responses: null
  },
  adAdDestroy: {
    method: "delete",
    url: "/ad/ads/{id}",
    operationId: "adAdDestroy",
    summary: "删除广告",
    tags: "Ad#Ad",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "广告ID",
          required: true
        }
      }
    },
    responses: null
  },
  adAdMoveUp: {
    method: "put",
    url: "/ad/ads/{id}/move_up",
    operationId: "adAdMoveUp",
    summary: "修改广告排序",
    tags: "Ad#Ad",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "广告ID",
          required: true
        }
      }
    },
    responses: null
  },
  adminAdminIndex: {
    method: "get",
    url: "/admin/admins",
    operationId: "adminAdminIndex",
    summary: "品牌管理员列表",
    tags: "Admin#Admin",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "BrandAdminPagedArrayDefault", type: "object" }
  },
  adminAdminCreate: {
    method: "post",
    url: "/admin/admins",
    operationId: "adminAdminCreate",
    summary: "新建品牌管理员",
    tags: "Admin#Admin",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "BrandAdminCreateForm" }
        }
      }
    },
    responses: null
  },
  adminAdminShow: {
    method: "get",
    url: "/admin/admins/{id}",
    operationId: "adminAdminShow",
    summary: "品牌管理员详情",
    tags: "Admin#Admin",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "管理员 ID",
          required: true
        }
      }
    },
    responses: { $ref: "BrandAdminDefault", type: "object" }
  },
  adminAdminUpdate: {
    method: "put",
    url: "/admin/admins/{id}",
    operationId: "adminAdminUpdate",
    summary: "修改品牌管理员",
    tags: "Admin#Admin",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "管理员 ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "BrandAdminUpdateForm" }
        }
      }
    },
    responses: null
  },
  adminAdminResetPassword: {
    method: "patch",
    url: "/admin/admins/{adminId}/reset_password",
    operationId: "adminAdminResetPassword",
    summary: "重置管理员密码",
    tags: "Admin#Admin",
    parameters: {
      path: {
        adminId: {
          name: "adminId",
          in: "path",
          type: "integer",
          description: "管理员账号ID",
          required: true
        }
      }
    },
    responses: null
  },
  adminRoleList: {
    method: "get",
    url: "/admin/roles/list",
    operationId: "adminRoleList",
    summary: "权限组列表",
    tags: "Admin#Role",
    parameters: {
      path: {},
      query: {
        id: {
          name: "id",
          in: "query",
          type: "integer",
          description: "权限组ID"
        }
      }
    },
    responses: { $ref: "PermissionRoleList", type: "array" }
  },
  adminRoleIndex: {
    method: "get",
    url: "/admin/roles",
    operationId: "adminRoleIndex",
    summary: "权限组列表",
    tags: "Admin#Role",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "PermissionRolePagedArrayDefault", type: "object" }
  },
  adminRoleCreate: {
    method: "post",
    url: "/admin/roles",
    operationId: "adminRoleCreate",
    summary: "创建权限组",
    tags: "Admin#Role",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "PermissionRoleForm" }
        }
      }
    },
    responses: null
  },
  adminRoleShow: {
    method: "get",
    url: "/admin/roles/{id}",
    operationId: "adminRoleShow",
    summary: "权限组详情",
    tags: "Admin#Role",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "权限组ID",
          required: true
        }
      }
    },
    responses: { $ref: "PermissionRoleDetail", type: "object" }
  },
  adminRoleUpdate: {
    method: "put",
    url: "/admin/roles/{id}",
    operationId: "adminRoleUpdate",
    summary: "修改权限组",
    tags: "Admin#Role",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "权限组ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "PermissionRoleForm" }
        }
      }
    },
    responses: null
  },
  adminRoleDestroy: {
    method: "delete",
    url: "/admin/roles/{id}",
    operationId: "adminRoleDestroy",
    summary: "删除权限组",
    tags: "Admin#Role",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "权限组ID",
          required: true
        }
      }
    },
    responses: null
  },
  attachmentShow: {
    method: "get",
    url: "/attachments/{signedId}",
    operationId: "attachmentShow",
    summary: "附件详情",
    tags: "Attachment",
    parameters: {
      path: {
        signedId: {
          name: "signedId",
          in: "path",
          type: "string",
          description: "附件 signed_id",
          required: true
        }
      }
    },
    responses: { $ref: "AttachmentDefault", type: "object" }
  },
  brandBookingSettingShow: {
    method: "get",
    url: "/booking_setting",
    operationId: "brandBookingSettingShow",
    summary: "约课设置详情",
    tags: "Brand#BookingSetting",
    parameters: { path: {} },
    responses: { $ref: "BrandBookingSettingDefault", type: "object" }
  },
  brandBookingSettingUpdate: {
    method: "put",
    url: "/booking_setting",
    operationId: "brandBookingSettingUpdate",
    summary: "修改约课设置",
    tags: "Brand#BookingSetting",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "BrandBookingSettingDefault" }
        }
      }
    },
    responses: null
  },
  brandBrandShow: {
    method: "get",
    url: "/brand",
    operationId: "brandBrandShow",
    summary: "品牌详情",
    tags: "Brand#Brand",
    parameters: { path: {} },
    responses: { $ref: "BrandBrandBrandDetail", type: "object" }
  },
  brandBrandUpdate: {
    method: "put",
    url: "/brand",
    operationId: "brandBrandUpdate",
    summary: "修改品牌",
    tags: "Brand#Brand",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "BrandBrandBrandForm" }
        }
      }
    },
    responses: null
  },
  brandCommunitySettingShow: {
    method: "get",
    url: "/community_setting",
    operationId: "brandCommunitySettingShow",
    summary: "社群设置详情",
    tags: "Brand#CommunitySetting",
    parameters: { path: {} },
    responses: { $ref: "BrandCommunitySettingForm", type: "object" }
  },
  brandCommunitySettingUpdate: {
    method: "put",
    url: "/community_setting",
    operationId: "brandCommunitySettingUpdate",
    summary: "修改社群设置",
    tags: "Brand#CommunitySetting",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "BrandCommunitySettingForm" }
        }
      }
    },
    responses: { $ref: "BrandCommunitySettingForm", type: "object" }
  },
  brandLeaderboardShow: {
    method: "get",
    url: "/leaderboard",
    operationId: "brandLeaderboardShow",
    summary: "社群排行榜详情",
    tags: "Brand#Leaderboard",
    parameters: {
      path: {},
      query: {
        type: {
          name: "type",
          in: "query",
          type: "string",
          required: true,
          description: "排行榜类型"
        },
        duration: {
          name: "duration",
          in: "query",
          type: "string",
          required: true,
          description: "排行榜时间"
        }
      }
    },
    responses: { $ref: "LeaderboardLeaderboardDefault", type: "object" }
  },
  brandLeaderboardRefresh: {
    method: "put",
    url: "/leaderboard",
    operationId: "brandLeaderboardRefresh",
    summary: "刷新社群排行榜",
    tags: "Brand#Leaderboard",
    parameters: {
      path: {},
      query: {
        type: {
          name: "type",
          in: "query",
          type: "string",
          required: true,
          description: "排行榜类型"
        },
        duration: {
          name: "duration",
          in: "query",
          type: "string",
          required: true,
          description: "排行榜时间"
        }
      }
    },
    responses: null
  },
  generalCurrentAdmin: {
    method: "get",
    url: "/current_admin",
    operationId: "generalCurrentAdmin",
    summary: "品牌管理员账号详情",
    tags: "General",
    parameters: { path: {} },
    responses: { $ref: "BrandAdminWithGrantedFeatures", type: "object" }
  },
  campBookingIndex: {
    method: "get",
    url: "/camp/camps/{campId}/bookings",
    operationId: "campBookingIndex",
    summary: "训练营预约列表",
    tags: "Camp#Booking",
    parameters: {
      path: {
        campId: {
          name: "campId",
          in: "path",
          type: "integer",
          description: "训练营ID",
          required: true
        }
      },
      query: {
        state: {
          name: "state",
          in: "query",
          type: "string",
          required: true,
          enum: ["normal", "cancelled"],
          description: "预约状态"
        }
      }
    },
    responses: { $ref: "CampBookingPagedArrayDefault", type: "object" }
  },
  campBookingCreate: {
    method: "post",
    url: "/camp/camps/{campId}/bookings",
    operationId: "campBookingCreate",
    summary: "添加训练营预约",
    tags: "Camp#Booking",
    parameters: {
      path: {
        campId: {
          name: "campId",
          in: "path",
          type: "integer",
          description: "训练营ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "CampBookingForm" } }
      }
    },
    responses: null
  },
  campBookingCancel: {
    method: "delete",
    url: "/camp/bookings/{id}/cancel",
    operationId: "campBookingCancel",
    summary: "取消训练营预约",
    tags: "Camp#Booking",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练营预约ID",
          required: true
        }
      }
    },
    responses: null
  },
  campCampIndex: {
    method: "get",
    url: "/camp/camps",
    operationId: "campCampIndex",
    summary: "训练营列表",
    tags: "Camp#Camp",
    parameters: {
      path: {},
      query: {
        productId: {
          name: "productId",
          in: "query",
          type: "integer",
          description: "课程品类ID"
        },
        state: {
          name: "state",
          in: "query",
          required: false,
          type: "string",
          enum: [
            "active",
            "new",
            "book_ended",
            "started",
            "finished",
            "cancelled"
          ],
          "x-i18n": {
            active: "报名和进行中",
            new: "报名中",
            book_ended: "报名结束",
            started: "进行中",
            finished: "已结束",
            cancelled: "已取消"
          },
          description: "训练营状态"
        }
      }
    },
    responses: { $ref: "CampCampPagedArrayDefault", type: "object" }
  },
  campCampCreate: {
    method: "post",
    url: "/camp/camps",
    operationId: "campCampCreate",
    summary: "新建训练营",
    tags: "Camp#Camp",
    parameters: {
      path: {},
      body: {
        json: { name: "json", in: "body", schema: { $ref: "CampCampForm" } }
      }
    },
    responses: null
  },
  campCampShow: {
    method: "get",
    url: "/camp/camps/{id}",
    operationId: "campCampShow",
    summary: "训练营详情",
    tags: "Camp#Camp",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练营ID",
          required: true
        }
      }
    },
    responses: { $ref: "CampCampDefault", type: "object" }
  },
  campCampUpdate: {
    method: "put",
    url: "/camp/camps/{id}",
    operationId: "campCampUpdate",
    summary: "修改训练营",
    tags: "Camp#Camp",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练营ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "CampCampForm" } }
      }
    },
    responses: null
  },
  campCampCourses: {
    method: "get",
    url: "/camp/camps/{id}/courses",
    operationId: "campCampCourses",
    summary: "训练营课程详情",
    tags: "Camp#Camp",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练营ID",
          required: true
        }
      }
    },
    responses: { $ref: "CampCourseDefault", type: "array" }
  },
  campCampUpdateCourses: {
    method: "put",
    url: "/camp/camps/{id}/update_courses",
    operationId: "campCampUpdateCourses",
    summary: "刷新训练营",
    tags: "Camp#Camp",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练营ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CampCampUpdateCoursesForm" },
          description: "刷新范围"
        }
      }
    },
    responses: null
  },
  campCampPostpone: {
    method: "put",
    url: "/camp/camps/{id}/postpone",
    operationId: "campCampPostpone",
    summary: "调整训练营日期",
    tags: "Camp#Camp",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练营ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CampCampPostponeForm" }
        }
      }
    },
    responses: { $ref: "CampCampDefault", type: "object" }
  },
  campCampDuplicate: {
    method: "post",
    url: "/camp/camps/{id}/duplicate",
    operationId: "campCampDuplicate",
    summary: "复制训练营",
    tags: "Camp#Camp",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练营ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CampCampDuplicateForm" }
        }
      }
    },
    responses: { $ref: "CampCampDefault", type: "object" }
  },
  campCampCancel: {
    method: "delete",
    url: "/camp/camps/{id}/cancel",
    operationId: "campCampCancel",
    summary: "取消训练营",
    tags: "Camp#Camp",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练营ID",
          required: true
        }
      }
    },
    responses: null
  },
  campCampCoupons: {
    method: "get",
    url: "/camp/camps/{id}/coupons",
    operationId: "campCampCoupons",
    summary: "可用代金券列表",
    tags: "Camp#Camp",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          required: true,
          description: "训练营ID"
        }
      },
      query: {
        memberId: {
          name: "memberId",
          in: "query",
          type: "integer",
          required: true,
          description: "用户ID"
        }
      }
    },
    responses: { $ref: "MobileCouponMemberCouponDefault", type: "array" }
  },
  coachCategoryIndex: {
    method: "get",
    url: "/coach/categories",
    operationId: "coachCategoryIndex",
    summary: "教练类型列表",
    tags: "Coach#Category",
    parameters: { path: {} },
    responses: { $ref: "CoachCategoryPagedArrayDefault", type: "object" }
  },
  coachCategoryCreate: {
    method: "post",
    url: "/coach/categories",
    operationId: "coachCategoryCreate",
    summary: "新建教练类型",
    tags: "Coach#Category",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CoachCategoryForm" }
        }
      }
    },
    responses: null
  },
  coachCategoryShow: {
    method: "get",
    url: "/coach/categories/{id}",
    operationId: "coachCategoryShow",
    summary: "教练类型详情",
    tags: "Coach#Category",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "CoachCategoryID",
          required: true
        }
      }
    },
    responses: { $ref: "CoachCategoryDefault", type: "object" }
  },
  coachCategoryUpdate: {
    method: "put",
    url: "/coach/categories/{id}",
    operationId: "coachCategoryUpdate",
    summary: "修改教练类型",
    tags: "Coach#Category",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "CoachCategoryID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CoachCategoryForm" }
        }
      }
    },
    responses: null
  },
  coachCategoryDestroy: {
    method: "delete",
    url: "/coach/categories/{id}",
    operationId: "coachCategoryDestroy",
    summary: "删除教练类型",
    tags: "Coach#Category",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "CoachCategoryID",
          required: true
        }
      }
    },
    responses: null
  },
  coachCoachIndex: {
    method: "get",
    url: "/coach/coaches",
    operationId: "coachCoachIndex",
    summary: "教练列表",
    tags: "Coach#Coach",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        levelId: {
          name: "levelId",
          in: "query",
          type: "integer",
          required: false,
          description: "教练等级ID"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: { $ref: "CoachCoachPagedArrayDefault", type: "object" }
  },
  coachCoachCreate: {
    method: "post",
    url: "/coach/coaches",
    operationId: "coachCoachCreate",
    summary: "新建教练",
    tags: "Coach#Coach",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CoachCoachCreateForm" }
        }
      }
    },
    responses: null
  },
  coachCoachList: {
    method: "get",
    url: "/coach/coaches/list",
    operationId: "coachCoachList",
    summary: "教练列表",
    tags: "Coach#Coach",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        levelId: {
          name: "levelId",
          in: "query",
          type: "integer",
          required: false,
          description: "教练等级ID"
        }
      }
    },
    responses: { $ref: "CoachCoachList", type: "array" }
  },
  coachCoachShow: {
    method: "get",
    url: "/coach/coaches/{id}",
    operationId: "coachCoachShow",
    summary: "教练详情",
    tags: "Coach#Coach",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      }
    },
    responses: { $ref: "CoachCoachDefault", type: "object" }
  },
  coachCoachUpdate: {
    method: "put",
    url: "/coach/coaches/{id}",
    operationId: "coachCoachUpdate",
    summary: "修改教练",
    tags: "Coach#Coach",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CoachCoachUpdateForm" }
        }
      }
    },
    responses: null
  },
  coachCoachDestroy: {
    method: "delete",
    url: "/coach/coaches/{id}",
    operationId: "coachCoachDestroy",
    summary: "删除教练",
    tags: "Coach#Coach",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      }
    },
    responses: null
  },
  coachCoachChangeLevel: {
    method: "put",
    url: "/coach/coaches/{id}/change_level",
    operationId: "coachCoachChangeLevel",
    summary: "教练等级变更",
    tags: "Coach#Coach",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CoachCoachChangeLevelForm" }
        }
      }
    },
    responses: null
  },
  coachCoachTags: {
    method: "get",
    url: "/coach/coaches/tags",
    operationId: "coachCoachTags",
    summary: "教练标签",
    tags: "Coach#Coach",
    parameters: {
      path: {},
      query: {
        type: {
          name: "type",
          in: "query",
          type: "string",
          description: "类型"
        }
      }
    },
    responses: { $ref: "CoachCoachCoachTag", type: "array" }
  },
  coachCoachActivate: {
    method: "put",
    url: "/coach/coaches/{id}/activate",
    operationId: "coachCoachActivate",
    summary: "启用教练",
    tags: "Coach#Coach",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      }
    },
    responses: null
  },
  coachCoachDeactivate: {
    method: "put",
    url: "/coach/coaches/{id}/deactivate",
    operationId: "coachCoachDeactivate",
    summary: "停用教练",
    tags: "Coach#Coach",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      }
    },
    responses: null
  },
  coachCourseIndex: {
    method: "get",
    url: "/coach/coaches/{coachId}/courses",
    operationId: "coachCourseIndex",
    summary: "教练排课列表",
    tags: "Coach#Course",
    parameters: {
      path: {
        coachId: {
          name: "coachId",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      },
      query: {
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          enum: ["group_training", "elite_training", "camp", "free_training"],
          required: false,
          description: "课程类型"
        },
        from: {
          name: "from",
          in: "query",
          type: "datetime",
          required: false,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "datetime",
          required: false,
          description: "结束日期"
        },
        state: {
          name: "state",
          in: "query",
          type: "string",
          required: false,
          enum: ["new", "finished"],
          description: "课程状态"
        }
      }
    },
    responses: { $ref: "CourseGroupTrainingPagedArrayDefault", type: "object" }
  },
  coachCourseSummary: {
    method: "get",
    url: "/coach/coaches/{coachId}/courses/summary",
    operationId: "coachCourseSummary",
    summary: "教练排课概览",
    tags: "Coach#Course",
    parameters: {
      path: {
        coachId: {
          name: "coachId",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      },
      query: {
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          enum: ["group_training", "elite_training", "camp", "free_training"],
          required: false,
          description: "课程类型"
        }
      }
    },
    responses: { $ref: "CoachCoachCoursesSummary", type: "object" }
  },
  coachCourseTrends: {
    method: "get",
    url: "/coach/coaches/{coachId}/courses/trends",
    operationId: "coachCourseTrends",
    summary: "教练排课趋势",
    tags: "Coach#Course",
    parameters: {
      path: {
        coachId: {
          name: "coachId",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      },
      query: {
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          enum: ["group_training", "elite_training", "camp", "free_training"],
          required: false,
          description: "课程类型"
        },
        state: {
          name: "state",
          in: "query",
          type: "string",
          required: false,
          enum: ["new", "finished"],
          description: "课程状态"
        },
        from: {
          name: "from",
          in: "query",
          type: "datetime",
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "datetime",
          description: "结束日期"
        },
        interval: {
          name: "interval",
          in: "query",
          type: "string",
          enum: ["week", "month"],
          required: true,
          description: "间隔"
        }
      }
    },
    responses: { $ref: "CoachCoachCoursesTrendsItem", type: "array" }
  },
  coachLevelList: {
    method: "get",
    url: "/coach/levels/list",
    operationId: "coachLevelList",
    summary: "教练等级列表",
    tags: "Coach#Level",
    parameters: { path: {} },
    responses: { $ref: "CoachLevelPagedArrayDefault", type: "object" }
  },
  coachLevelIndex: {
    method: "get",
    url: "/coach/levels",
    operationId: "coachLevelIndex",
    summary: "教练等级列表",
    tags: "Coach#Level",
    parameters: { path: {} },
    responses: { $ref: "CoachLevelPagedArrayDefault", type: "object" }
  },
  coachLevelCreate: {
    method: "post",
    url: "/coach/levels",
    operationId: "coachLevelCreate",
    summary: "新建教练等级",
    tags: "Coach#Level",
    parameters: {
      path: {},
      body: {
        json: { name: "json", in: "body", schema: { $ref: "CoachLevelForm" } }
      }
    },
    responses: null
  },
  coachLevelShow: {
    method: "get",
    url: "/coach/levels/{id}",
    operationId: "coachLevelShow",
    summary: "教练等级详情",
    tags: "Coach#Level",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "教练等级 ID",
          required: true
        }
      }
    },
    responses: { $ref: "CoachLevelDefault", type: "object" }
  },
  coachLevelUpdate: {
    method: "put",
    url: "/coach/levels/{id}",
    operationId: "coachLevelUpdate",
    summary: "修改教练等级",
    tags: "Coach#Level",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "教练等级 ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "CoachLevelForm" } }
      }
    },
    responses: null
  },
  coachLevelDestroy: {
    method: "delete",
    url: "/coach/levels/{id}",
    operationId: "coachLevelDestroy",
    summary: "删除教练等级",
    tags: "Coach#Level",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "教练等级 ID",
          required: true
        }
      }
    },
    responses: null
  },
  coachProductGrant: {
    method: "post",
    url: "/coach/coaches/{coachId}/products/grant",
    operationId: "coachProductGrant",
    summary: "授权课程",
    tags: "Coach#Product",
    parameters: {
      path: {
        coachId: {
          name: "coachId",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CoachCoachProductGrantForm" }
        }
      }
    },
    responses: null
  },
  coachProductIndex: {
    method: "get",
    url: "/coach/coaches/{coachId}/products",
    operationId: "coachProductIndex",
    summary: "课程授权列表",
    tags: "Coach#Product",
    parameters: {
      path: {
        coachId: {
          name: "coachId",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        }
      },
      query: {
        usage: {
          name: "usage",
          in: "query",
          type: "string",
          required: false,
          enum: ["default", "template"],
          "x-i18n": { default: "用于授权", template: "用于模板" },
          description: "使用方式"
        }
      }
    },
    responses: { $ref: "CoachCoachProductDefault", type: "array" }
  },
  coachProductShow: {
    method: "get",
    url: "/coach/coaches/{coachId}/products/{id}",
    operationId: "coachProductShow",
    summary: "课程授权详情",
    tags: "Coach#Product",
    parameters: {
      path: {
        coachId: {
          name: "coachId",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程授权ID",
          required: true
        }
      }
    },
    responses: { $ref: "CoachCoachProductDefault", type: "object" }
  },
  coachProductUpdate: {
    method: "put",
    url: "/coach/coaches/{coachId}/products/{id}",
    operationId: "coachProductUpdate",
    summary: "修改课程授权",
    tags: "Coach#Product",
    parameters: {
      path: {
        coachId: {
          name: "coachId",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "授权课程ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CoachCoachProductForm" }
        }
      }
    },
    responses: null
  },
  coachProductDestroy: {
    method: "delete",
    url: "/coach/coaches/{coachId}/products/{id}",
    operationId: "coachProductDestroy",
    summary: "删除课程授权",
    tags: "Coach#Product",
    parameters: {
      path: {
        coachId: {
          name: "coachId",
          in: "path",
          type: "integer",
          description: "教练ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "授权课程ID",
          required: true
        }
      }
    },
    responses: null
  },
  couponCouponList: {
    method: "get",
    url: "/coupon/coupons/list",
    operationId: "couponCouponList",
    summary: "代金券列表",
    tags: "Coupon#Coupon",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        memberId: {
          name: "memberId",
          in: "query",
          type: "integer",
          required: false,
          description: "会员ID"
        }
      }
    },
    responses: { $ref: "CouponCouponList", type: "array" }
  },
  couponCouponIndex: {
    method: "get",
    url: "/coupon/coupons",
    operationId: "couponCouponIndex",
    summary: "代金券列表",
    tags: "Coupon#Coupon",
    parameters: {
      path: {},
      query: {
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        },
        memberId: {
          name: "memberId",
          in: "query",
          type: "integer",
          required: false,
          description: "会员ID"
        }
      }
    },
    responses: { $ref: "CouponCouponPagedArrayDefault", type: "object" }
  },
  couponCouponCreate: {
    method: "post",
    url: "/coupon/coupons",
    operationId: "couponCouponCreate",
    summary: "新建代金券",
    tags: "Coupon#Coupon",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponCouponForm" }
        }
      }
    },
    responses: null
  },
  couponCouponShow: {
    method: "get",
    url: "/coupon/coupons/{id}",
    operationId: "couponCouponShow",
    summary: "代金券详情",
    tags: "Coupon#Coupon",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "代金券ID",
          required: true
        }
      }
    },
    responses: { $ref: "CouponCouponDefault", type: "object" }
  },
  couponCouponUpdate: {
    method: "put",
    url: "/coupon/coupons/{id}",
    operationId: "couponCouponUpdate",
    summary: "修改代金券",
    tags: "Coupon#Coupon",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "代金券ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponCouponForm" }
        }
      }
    },
    responses: null
  },
  couponCouponDestroy: {
    method: "delete",
    url: "/coupon/coupons/{id}",
    operationId: "couponCouponDestroy",
    summary: "删除代金券",
    tags: "Coupon#Coupon",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "代金券ID",
          required: true
        }
      }
    },
    responses: null
  },
  couponCouponExtendValidityPeriod: {
    method: "post",
    url: "/coupon/coupons/extend_validity_period",
    operationId: "couponCouponExtendValidityPeriod",
    summary: "代金券批量延期",
    tags: "Coupon#Coupon",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponCouponExtendValidityPeriodForm" }
        }
      }
    },
    responses: null
  },
  couponCouponRevoke: {
    method: "post",
    url: "/coupon/coupons/revoke",
    operationId: "couponCouponRevoke",
    summary: "代金券批量撤销",
    tags: "Coupon#Coupon",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponCouponRevokeForm" }
        }
      }
    },
    responses: null
  },
  couponEventCouponIndex: {
    method: "get",
    url: "/coupon/events/{eventId}/event_coupons",
    operationId: "couponEventCouponIndex",
    summary: "营销代金券列表",
    tags: "Coupon#EventCoupon",
    parameters: {
      path: {
        eventId: {
          name: "eventId",
          in: "path",
          type: "integer",
          description: "营销活动ID",
          required: true
        }
      }
    },
    responses: { $ref: "CouponEventCouponDefault", type: "array" }
  },
  couponEventCouponCreate: {
    method: "post",
    url: "/coupon/events/{eventId}/event_coupons",
    operationId: "couponEventCouponCreate",
    summary: "新建营销代金券",
    tags: "Coupon#EventCoupon",
    parameters: {
      path: {
        eventId: {
          name: "eventId",
          in: "path",
          type: "integer",
          description: "营销活动ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponEventCouponForm" }
        }
      }
    },
    responses: null
  },
  couponEventCouponShow: {
    method: "get",
    url: "/coupon/events/{eventId}/event_coupons/{id}",
    operationId: "couponEventCouponShow",
    summary: "营销代金券详情",
    tags: "Coupon#EventCoupon",
    parameters: {
      path: {
        eventId: {
          name: "eventId",
          in: "path",
          type: "integer",
          description: "营销活动ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "营销代金券ID",
          required: true
        }
      }
    },
    responses: { $ref: "CouponEventCouponDefault", type: "object" }
  },
  couponEventCouponUpdate: {
    method: "put",
    url: "/coupon/events/{eventId}/event_coupons/{id}",
    operationId: "couponEventCouponUpdate",
    summary: "修改营销代金券",
    tags: "Coupon#EventCoupon",
    parameters: {
      path: {
        eventId: {
          name: "eventId",
          in: "path",
          type: "integer",
          description: "营销活动ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "营销代金券ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponEventCouponForm" }
        }
      }
    },
    responses: null
  },
  couponEventCouponDestroy: {
    method: "delete",
    url: "/coupon/events/{eventId}/event_coupons/{id}",
    operationId: "couponEventCouponDestroy",
    summary: "删除营销代金券",
    tags: "Coupon#EventCoupon",
    parameters: {
      path: {
        eventId: {
          name: "eventId",
          in: "path",
          type: "integer",
          description: "营销活动ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "营销代金券ID",
          required: true
        }
      }
    },
    responses: null
  },
  couponEventIndex: {
    method: "get",
    url: "/coupon/events",
    operationId: "couponEventIndex",
    summary: "代金券营销列表",
    tags: "Coupon#Event",
    parameters: { path: {} },
    responses: { $ref: "CouponEventDefault", type: "array" }
  },
  couponEventShow: {
    method: "get",
    url: "/coupon/events/{id}",
    operationId: "couponEventShow",
    summary: "代金券营销详情",
    tags: "Coupon#Event",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "代金券营销ID",
          required: true
        }
      }
    },
    responses: { $ref: "CouponEventDefault", type: "object" }
  },
  couponEventUpdate: {
    method: "put",
    url: "/coupon/events/{id}",
    operationId: "couponEventUpdate",
    summary: "修改代金券营销",
    tags: "Coupon#Event",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "代金券营销ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "CouponEventForm" } }
      }
    },
    responses: null
  },
  couponPackageList: {
    method: "get",
    url: "/coupon/packages/list",
    operationId: "couponPackageList",
    summary: "代金券商品列表",
    tags: "Coupon#Package",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        }
      }
    },
    responses: { $ref: "CouponPackageDefault", type: "array" }
  },
  couponPackageIndex: {
    method: "get",
    url: "/coupon/packages",
    operationId: "couponPackageIndex",
    summary: "代金券商品列表",
    tags: "Coupon#Package",
    parameters: {
      path: {},
      query: {
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        }
      }
    },
    responses: { $ref: "CouponPackagePagedArrayDefault", type: "object" }
  },
  couponPackageCreate: {
    method: "post",
    url: "/coupon/packages",
    operationId: "couponPackageCreate",
    summary: "新建代金券商品",
    tags: "Coupon#Package",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponPackageForm" }
        }
      }
    },
    responses: null
  },
  couponPackageShow: {
    method: "get",
    url: "/coupon/packages/{id}",
    operationId: "couponPackageShow",
    summary: "代金券商品详情",
    tags: "Coupon#Package",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "代金券商品ID",
          required: true
        }
      }
    },
    responses: { $ref: "CouponPackageDefault", type: "object" }
  },
  couponPackageUpdate: {
    method: "put",
    url: "/coupon/packages/{id}",
    operationId: "couponPackageUpdate",
    summary: "修改代金券商品",
    tags: "Coupon#Package",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "代金券商品ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponPackageForm" }
        }
      }
    },
    responses: null
  },
  couponPackageDestroy: {
    method: "delete",
    url: "/coupon/packages/{id}",
    operationId: "couponPackageDestroy",
    summary: "删除代金券商品",
    tags: "Coupon#Package",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "代金券商品ID",
          required: true
        }
      }
    },
    responses: null
  },
  courseBookingIndex: {
    method: "get",
    url: "/course/bookings",
    operationId: "courseBookingIndex",
    summary: "团课预约列表",
    tags: "Course#Booking",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "datetime",
          required: false,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "datetime",
          required: false,
          description: "结束日期"
        },
        state: {
          name: "state",
          in: "query",
          required: false,
          type: "string",
          enum: [
            "new",
            "reserved",
            "queued",
            "checked_in",
            "absent",
            "cancelled"
          ],
          "x-i18n": {
            new: "未签到",
            reserved: "未支付",
            queued: "已排号",
            checked_in: "已签到",
            absent: "缺席",
            cancelled: "已取消"
          },
          description: "预约状态"
        },
        consume_type: {
          name: "consume_type",
          in: "query",
          required: false,
          description: "预约方式"
        }
      }
    },
    responses: {
      $ref: "CourseBookingPagedArrayWithCourseAndResult",
      type: "object"
    }
  },
  courseBookingCheckIn: {
    method: "put",
    url: "/course/bookings/{id}/check_in",
    operationId: "courseBookingCheckIn",
    summary: "团课预约签到",
    tags: "Course#Booking",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "预约ID",
          required: true
        }
      }
    },
    responses: null
  },
  courseBookingDestroy: {
    method: "delete",
    url: "/course/bookings/{id}",
    operationId: "courseBookingDestroy",
    summary: "取消团课预约",
    tags: "Course#Booking",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "预约ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseBookingGroupTrainingCancelForm" }
        }
      }
    },
    responses: null
  },
  courseProductList: {
    method: "get",
    url: "/course/products/list",
    operationId: "courseProductList",
    summary: "课程产品列表",
    tags: "Course#Product",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        productType: {
          name: "productType",
          in: "query",
          required: false,
          type: "string",
          enum: ["group_training", "elite_training", "camp", "free_training"],
          "x-i18n": {
            group_training: "团课",
            elite_training: "私教",
            camp: "训练营",
            free_training: "自由训练"
          },
          description: "课程类型"
        },
        usage: {
          name: "usage",
          in: "query",
          type: "string",
          required: false,
          enum: ["default", "template"],
          "x-i18n": { default: "用于授权", template: "用于模板" },
          description: "使用方式"
        },
        provider: {
          name: "provider",
          in: "query",
          required: false,
          type: "string",
          enum: ["莱美", "其他"],
          "x-i18n": { 莱美: "莱美", 其他: "其他" },
          description: "品牌"
        },
        scope: {
          name: "scope",
          in: "query",
          required: false,
          type: "string",
          description: "查询范围"
        },
        for_kid: {
          name: "for_kid",
          in: "query",
          required: false,
          type: "boolean",
          description: "少儿体适能"
        },
        without_ktas_workout: {
          name: "without_ktas_workout",
          in: "query",
          required: false,
          type: "boolean",
          description: "未添加教案"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "关键字"
        }
      }
    },
    responses: { $ref: "CourseProductList", type: "array" }
  },
  courseProductIndex: {
    method: "get",
    url: "/course/products",
    operationId: "courseProductIndex",
    summary: "课程产品列表",
    tags: "Course#Product",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          required: false,
          enum: ["group_training", "elite_training", "camp", "free_training"],
          "x-i18n": {
            group_training: "团课",
            elite_training: "私教",
            camp: "训练营",
            free_training: "自由训练"
          },
          description: "课程类型"
        },
        usage: {
          name: "usage",
          in: "query",
          type: "string",
          required: false,
          enum: ["default", "template"],
          "x-i18n": { default: "用于授权", template: "用于模板" },
          description: "使用方式"
        },
        category: {
          name: "category",
          in: "query",
          type: "string",
          required: false,
          enum: ["brand", "platform"],
          "x-i18n": { brand: "自研产品", platform: "授权产品" },
          description: "产品性质"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "关键字"
        }
      }
    },
    responses: { $ref: "CourseProductPagedArrayDefault", type: "object" }
  },
  courseProductCreate: {
    method: "post",
    url: "/course/products",
    operationId: "courseProductCreate",
    summary: "新建课程产品",
    tags: "Course#Product",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseProductBrandForm" }
        }
      }
    },
    responses: null
  },
  courseProductShow: {
    method: "get",
    url: "/course/products/{id}",
    operationId: "courseProductShow",
    summary: "课程产品详情",
    tags: "Course#Product",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程产品 ID",
          required: true
        }
      }
    },
    responses: { $ref: "CourseProductDefault", type: "object" }
  },
  courseProductUpdate: {
    method: "put",
    url: "/course/products/{id}",
    operationId: "courseProductUpdate",
    summary: "修改课程产品",
    tags: "Course#Product",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程产品 ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseProductBrandForm" }
        }
      }
    },
    responses: null
  },
  courseProductDestroy: {
    method: "delete",
    url: "/course/products/{id}",
    operationId: "courseProductDestroy",
    summary: "删除课程产品",
    tags: "Course#Product",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程产品 ID",
          required: true
        }
      }
    },
    responses: null
  },
  courseProductCreateCoachingModeProduct: {
    method: "post",
    url: "/course/products/create_coaching_mode_product",
    operationId: "courseProductCreateCoachingModeProduct",
    summary: "新建白板课程产品",
    tags: "Course#Product",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseProductBrandForm" }
        }
      }
    },
    responses: null
  },
  courseProductSyncInfoFromWorkout: {
    method: "put",
    url: "/course/products/{id}/sync_info_from_workout",
    operationId: "courseProductSyncInfoFromWorkout",
    summary: "同步课程产品",
    tags: "Course#Product",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程产品 ID",
          required: true
        }
      }
    },
    responses: null
  },
  courseProductMoveUp: {
    method: "put",
    url: "/course/products/{id}/move_up",
    operationId: "courseProductMoveUp",
    summary: "上移课程产品",
    tags: "Course#Product",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程产品 ID",
          required: true
        }
      }
    },
    responses: null
  },
  courseProductProviders: {
    method: "get",
    url: "/course/products/providers",
    operationId: "courseProductProviders",
    summary: "课程产品品牌",
    tags: "Course#Product",
    parameters: { path: {} },
    responses: null
  },
  courseRatingOverview: {
    method: "get",
    url: "/course/ratings/overview",
    operationId: "courseRatingOverview",
    summary: "评价概览",
    tags: "Course#Rating",
    parameters: {
      path: {},
      query: {
        coach_id: {
          name: "coach_id",
          in: "query",
          type: "integer",
          required: false,
          description: "教练ID"
        },
        product_id: {
          name: "product_id",
          in: "query",
          type: "integer",
          required: false,
          description: "课程产品ID"
        },
        studio_id: {
          name: "studio_id",
          in: "query",
          type: "integer",
          required: false,
          description: "门店ID"
        }
      }
    },
    responses: null
  },
  courseRatingIndex: {
    method: "get",
    url: "/course/ratings",
    operationId: "courseRatingIndex",
    summary: "评价列表",
    tags: "Course#Rating",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        coach_id: {
          name: "coach_id",
          in: "query",
          type: "integer",
          required: false,
          description: "教练ID"
        },
        product_id: {
          name: "product_id",
          in: "query",
          type: "integer",
          required: false,
          description: "课程产品ID"
        },
        studio_id: {
          name: "studio_id",
          in: "query",
          type: "integer",
          required: false,
          description: "门店ID"
        }
      }
    },
    responses: { $ref: "CourseRatingPagedArrayDefault", type: "object" }
  },
  courseRatingShow: {
    method: "get",
    url: "/course/ratings/{id}",
    operationId: "courseRatingShow",
    summary: "评价详情",
    tags: "Course#Rating",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "评价ID",
          required: true
        }
      }
    },
    responses: { $ref: "CourseRatingDetail", type: "object" }
  },
  courseRatingClose: {
    method: "put",
    url: "/course/ratings/{id}/close",
    operationId: "courseRatingClose",
    summary: "标记为已处理",
    tags: "Course#Rating",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "评价ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseRatingCloseForm" }
        }
      }
    },
    responses: null
  },
  courseReviewIndex: {
    method: "get",
    url: "/course/reviews",
    operationId: "courseReviewIndex",
    summary: "评论列表",
    tags: "Course#Review",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "CourseReviewPagedArrayDefault", type: "object" }
  },
  courseReviewShow: {
    method: "get",
    url: "/course/reviews/{id}",
    operationId: "courseReviewShow",
    summary: "评论详情",
    tags: "Course#Review",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "评论ID",
          required: true
        }
      }
    },
    responses: { $ref: "CourseReviewDetail", type: "object" }
  },
  courseReviewUpdate: {
    method: "put",
    url: "/course/reviews/{id}",
    operationId: "courseReviewUpdate",
    summary: "修改评论",
    tags: "Course#Review",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "评论ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseReviewUpdateForm" }
        }
      }
    },
    responses: null
  },
  courseReviewPublish: {
    method: "put",
    url: "/course/reviews/{id}/publish",
    operationId: "courseReviewPublish",
    summary: "通过评论",
    tags: "Course#Review",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "评论ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseReviewUpdateForm" }
        }
      }
    },
    responses: null
  },
  courseReviewReject: {
    method: "put",
    url: "/course/reviews/{id}/reject",
    operationId: "courseReviewReject",
    summary: "不通过评论",
    tags: "Course#Review",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "评论ID",
          required: true
        }
      }
    },
    responses: null
  },
  grantedUserIndex: {
    method: "get",
    url: "/granted_users",
    operationId: "grantedUserIndex",
    summary: "门禁白名单列表",
    tags: "GrantedUser",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "GrantedUserPagedArrayDefault", type: "object" }
  },
  grantedUserCreate: {
    method: "post",
    url: "/granted_users",
    operationId: "grantedUserCreate",
    summary: "新建门禁白名单",
    tags: "GrantedUser",
    parameters: {
      path: {},
      body: {
        json: { name: "json", in: "body", schema: { $ref: "GrantedUserForm" } }
      }
    },
    responses: null
  },
  grantedUserShow: {
    method: "get",
    url: "/granted_users/{id}",
    operationId: "grantedUserShow",
    summary: "门禁白名单详情",
    tags: "GrantedUser",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "白名单",
          required: true
        }
      }
    },
    responses: { $ref: "GrantedUserDefault", type: "object" }
  },
  grantedUserUpdate: {
    method: "put",
    url: "/granted_users/{id}",
    operationId: "grantedUserUpdate",
    summary: "修改门禁白名单",
    tags: "GrantedUser",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "白名单",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "GrantedUserForm" } }
      }
    },
    responses: null
  },
  grantedUserDestroy: {
    method: "delete",
    url: "/granted_users/{id}",
    operationId: "grantedUserDestroy",
    summary: "删除门禁白名单",
    tags: "GrantedUser",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "白名单",
          required: true
        }
      }
    },
    responses: null
  },
  groupTrainingBookingIndex: {
    method: "get",
    url: "/group_trainings/{groupTrainingId}/bookings",
    operationId: "groupTrainingBookingIndex",
    summary: "团课预约列表",
    tags: "GroupTraining#Booking",
    parameters: {
      path: {
        groupTrainingId: {
          name: "groupTrainingId",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        }
      },
      query: {
        state: {
          name: "state",
          in: "query",
          required: false,
          type: "string",
          enum: [
            "new",
            "reserved",
            "queued",
            "checked_in",
            "absent",
            "cancelled"
          ],
          "x-i18n": {
            new: "未签到",
            reserved: "未支付",
            queued: "已排号",
            checked_in: "已签到",
            absent: "缺席",
            cancelled: "已取消"
          },
          description: "预约状态"
        }
      }
    },
    responses: { $ref: "CourseBookingPagedArrayGroupTraining", type: "object" }
  },
  groupTrainingBookingCreate: {
    method: "post",
    url: "/group_trainings/{groupTrainingId}/bookings",
    operationId: "groupTrainingBookingCreate",
    summary: "新建团课预约",
    tags: "GroupTraining#Booking",
    parameters: {
      path: {
        groupTrainingId: {
          name: "groupTrainingId",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseBookingGroupTrainingForm" }
        }
      }
    },
    responses: null
  },
  groupTrainingBookingShow: {
    method: "get",
    url: "/group_trainings/{groupTrainingId}/bookings/{id}",
    operationId: "groupTrainingBookingShow",
    summary: "团课预约详情",
    tags: "GroupTraining#Booking",
    parameters: {
      path: {
        groupTrainingId: {
          name: "groupTrainingId",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "预约ID",
          required: true
        }
      }
    },
    responses: { $ref: "CourseBookingGroupTraining", type: "object" }
  },
  groupTrainingBookingUpdate: {
    method: "put",
    url: "/group_trainings/{groupTrainingId}/bookings/{id}",
    operationId: "groupTrainingBookingUpdate",
    summary: "修改团课预约",
    tags: "GroupTraining#Booking",
    parameters: {
      path: {
        groupTrainingId: {
          name: "groupTrainingId",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "预约ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseBookingGroupTrainingForm" }
        }
      }
    },
    responses: null
  },
  groupTrainingBookingDestroy: {
    method: "delete",
    url: "/group_trainings/{groupTrainingId}/bookings/{id}",
    operationId: "groupTrainingBookingDestroy",
    summary: "取消团课预约",
    tags: "GroupTraining#Booking",
    parameters: {
      path: {
        groupTrainingId: {
          name: "groupTrainingId",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "预约ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseBookingGroupTrainingCancelForm" }
        }
      }
    },
    responses: null
  },
  groupTrainingBookingCheckIn: {
    method: "put",
    url: "/group_trainings/{groupTrainingId}/bookings/{id}/check_in",
    operationId: "groupTrainingBookingCheckIn",
    summary: "团课预约签到",
    tags: "GroupTraining#Booking",
    parameters: {
      path: {
        groupTrainingId: {
          name: "groupTrainingId",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "预约ID",
          required: true
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingIndex: {
    method: "get",
    url: "/group_trainings",
    operationId: "groupTrainingGroupTrainingIndex",
    summary: "团课列表",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      query: {
        studioId: {
          name: "studioId",
          in: "query",
          type: "integer",
          required: false,
          description: "门店ID"
        },
        zoneId: {
          name: "zoneId",
          in: "query",
          type: "integer",
          required: false,
          description: "场地ID"
        },
        from: {
          name: "from",
          in: "query",
          type: "datetime",
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "datetime",
          description: "结束日期"
        }
      }
    },
    responses: { $ref: "CourseGroupTrainingDefault", type: "array" }
  },
  groupTrainingGroupTrainingCreate: {
    method: "post",
    url: "/group_trainings",
    operationId: "groupTrainingGroupTrainingCreate",
    summary: "新建团课",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseGroupTrainingForm" }
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingShow: {
    method: "get",
    url: "/group_trainings/{id}",
    operationId: "groupTrainingGroupTrainingShow",
    summary: "团课详情",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        }
      }
    },
    responses: { $ref: "CourseGroupTrainingDefault", type: "object" }
  },
  groupTrainingGroupTrainingUpdate: {
    method: "put",
    url: "/group_trainings/{id}",
    operationId: "groupTrainingGroupTrainingUpdate",
    summary: "修改团课",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseGroupTrainingForm" }
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingDestroy: {
    method: "delete",
    url: "/group_trainings/{id}",
    operationId: "groupTrainingGroupTrainingDestroy",
    summary: "删除团课",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingUpdateOutline: {
    method: "patch",
    url: "/group_trainings/{id}/update_outline",
    operationId: "groupTrainingGroupTrainingUpdateOutline",
    summary: "修改团课套路",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "团课ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseGroupTrainingUpdateOutlineForm" }
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingCloneSchedule: {
    method: "post",
    url: "/group_trainings/clone_schedule",
    operationId: "groupTrainingGroupTrainingCloneSchedule",
    summary: "复制课表",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseGroupTrainingCloneScheduleForm" }
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingMultiUpdate: {
    method: "put",
    url: "/group_trainings/multi_update",
    operationId: "groupTrainingGroupTrainingMultiUpdate",
    summary: "批量修改",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseGroupTrainingMultiUpdateForm" }
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingPublishSchedule: {
    method: "post",
    url: "/group_trainings/publish_schedule",
    operationId: "groupTrainingGroupTrainingPublishSchedule",
    summary: "发布课表",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseGroupTrainingPublishScheduleForm" }
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingOutlineSchedule: {
    method: "get",
    url: "/group_trainings/outline_schedule",
    operationId: "groupTrainingGroupTrainingOutlineSchedule",
    summary: "团课训练流排课情况",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      query: {
        zoneId: {
          name: "zoneId",
          in: "query",
          type: "integer",
          required: false,
          description: "场地ID"
        },
        from: {
          name: "from",
          in: "query",
          type: "datetime",
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "datetime",
          description: "结束日期"
        }
      }
    },
    responses: { $ref: "GroupTrainingOutlineScheduleDefault", type: "array" }
  },
  groupTrainingGroupTrainingBatchUpdateOutline: {
    method: "put",
    url: "/group_trainings/batch_update_outline",
    operationId: "groupTrainingGroupTrainingBatchUpdateOutline",
    summary: "批量设置训练流",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          properties: {
            type: "object",
            properties: {
              zone_id: { type: "integer" },
              from: { type: "string", format: "date", example: "2018-01-01" },
              to: { type: "string", format: "date", example: "2018-01-01" },
              outlines: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    product_id: { type: "integer" },
                    outline_id: { type: "integer" }
                  }
                }
              }
            }
          }
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingBatchCancelCourses: {
    method: "patch",
    url: "/group_trainings/batch_cancel_courses",
    operationId: "groupTrainingGroupTrainingBatchCancelCourses",
    summary: "批量取消课程",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          properties: {
            type: "object",
            properties: {
              zone_id: { type: "integer" },
              from: { type: "string", format: "date", example: "2018-01-01" },
              to: { type: "string", format: "date", example: "2018-01-01" }
            }
          }
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingReviewSchedule: {
    method: "get",
    url: "/group_trainings/review_schedule",
    operationId: "groupTrainingGroupTrainingReviewSchedule",
    summary: "课程总览",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      query: {
        coachId: {
          name: "coachId",
          in: "query",
          type: "integer",
          required: false,
          description: "教练ID"
        },
        workoutId: {
          name: "workoutId",
          in: "query",
          type: "integer",
          required: false,
          description: "课程产品ID"
        },
        from: {
          name: "from",
          in: "query",
          type: "date",
          required: false,
          description: "开始时间"
        },
        to: {
          name: "to",
          in: "query",
          type: "date",
          required: false,
          description: "结束时间"
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingCoachStatistic: {
    method: "get",
    url: "/group_trainings/coach_statistic",
    operationId: "groupTrainingGroupTrainingCoachStatistic",
    summary: "教练排课统计",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      query: {
        coachId: {
          name: "coachId",
          in: "query",
          type: "integer",
          required: false,
          description: "教练ID"
        },
        workoutId: {
          name: "workoutId",
          in: "query",
          type: "integer",
          required: false,
          description: "课程产品ID"
        },
        from: {
          name: "from",
          in: "query",
          type: "date",
          required: false,
          description: "开始时间"
        },
        to: {
          name: "to",
          in: "query",
          type: "date",
          required: false,
          description: "结束时间"
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingWorkoutStatistic: {
    method: "get",
    url: "/group_trainings/workout_statistic",
    operationId: "groupTrainingGroupTrainingWorkoutStatistic",
    summary: "团课产品排课统计",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      query: {
        workoutId: {
          name: "workoutId",
          in: "query",
          type: "integer",
          required: false,
          description: "课程产品ID"
        },
        coachId: {
          name: "coachId",
          in: "query",
          type: "integer",
          required: false,
          description: "教练ID"
        },
        from: {
          name: "from",
          in: "query",
          type: "date",
          required: false,
          description: "开始时间"
        },
        to: {
          name: "to",
          in: "query",
          type: "date",
          required: false,
          description: "结束时间"
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingOccupancyRate: {
    method: "get",
    url: "/group_trainings/course_slot_time_occupancy_rate",
    operationId: "groupTrainingGroupTrainingOccupancyRate",
    summary: "时段上座率统计",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {},
      query: {
        groupTrainingId: {
          name: "groupTrainingId",
          in: "query",
          type: "integer",
          required: true,
          description: "课程ID"
        }
      }
    },
    responses: null
  },
  groupTrainingGroupTrainingCoupons: {
    method: "get",
    url: "/group_trainings/{id}/coupons",
    operationId: "groupTrainingGroupTrainingCoupons",
    summary: "团课可用代金券列表",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          required: true,
          description: "课程ID"
        }
      },
      query: {
        memberId: {
          name: "memberId",
          in: "query",
          type: "integer",
          required: true,
          description: "用户ID"
        }
      }
    },
    responses: { $ref: "MobileCouponMemberCouponDefault", type: "array" }
  },
  groupTrainingGroupTrainingCancel: {
    method: "patch",
    url: "/group_trainings/{id}/cancel",
    operationId: "groupTrainingGroupTrainingCancel",
    summary: "团课取消",
    tags: "GroupTraining#GroupTraining",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          required: true,
          description: "课程ID"
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CourseGroupTrainingCancelForm" }
        }
      }
    },
    responses: null
  },
  ktasGameList: {
    method: "get",
    url: "/ktas/games/list",
    operationId: "ktasGameList",
    summary: "游戏库列表",
    tags: "Ktas#Game",
    parameters: {
      path: {},
      query: {
        id: {
          name: "id",
          in: "query",
          type: "integer",
          required: false,
          description: "游戏库ID"
        }
      }
    },
    responses: { $ref: "KtasGameList", type: "array" }
  },
  ktasGameTags: {
    method: "get",
    url: "/ktas/games/tags",
    operationId: "ktasGameTags",
    summary: "游戏标签",
    tags: "Ktas#Game",
    parameters: {
      path: {},
      query: {
        tagName: {
          name: "tagName",
          in: "query",
          type: "string",
          required: true,
          description: "标签名称"
        }
      }
    },
    responses: null
  },
  ktasGameIndex: {
    method: "get",
    url: "/ktas/games",
    operationId: "ktasGameIndex",
    summary: "游戏库列表",
    tags: "Ktas#Game",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "根据名称搜索"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          required: false,
          description: "状态"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "KtasGamePagedArrayDefault", type: "object" }
  },
  ktasGameCreate: {
    method: "post",
    url: "/ktas/games",
    operationId: "ktasGameCreate",
    summary: "创建游戏库",
    tags: "Ktas#Game",
    parameters: {
      path: {},
      body: {
        json: { name: "json", in: "body", schema: { $ref: "KtasGameForm" } }
      }
    },
    responses: null
  },
  ktasGameShow: {
    method: "get",
    url: "/ktas/games/{id}",
    operationId: "ktasGameShow",
    summary: "游戏库详情",
    tags: "Ktas#Game",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "游戏库ID",
          required: true
        }
      }
    },
    responses: { $ref: "KtasGameDetail", type: "object" }
  },
  ktasGameUpdate: {
    method: "put",
    url: "/ktas/games/{id}",
    operationId: "ktasGameUpdate",
    summary: "修改游戏库",
    tags: "Ktas#Game",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "游戏库ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "KtasGameForm" } }
      }
    },
    responses: null
  },
  ktasGameDestroy: {
    method: "delete",
    url: "/ktas/games/{id}",
    operationId: "ktasGameDestroy",
    summary: "删除游戏库",
    tags: "Ktas#Game",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "游戏库ID",
          required: true
        }
      }
    },
    responses: null
  },
  ktasOutlineList: {
    method: "get",
    url: "/ktas/outlines/list",
    operationId: "ktasOutlineList",
    summary: "课程教案列表",
    tags: "Ktas#Outline",
    parameters: {
      path: {},
      query: {
        productId: {
          name: "productId",
          in: "query",
          type: "integer",
          description: "产品ID"
        },
        id: {
          name: "id",
          in: "query",
          type: "integer",
          required: false,
          description: "课程教案ID"
        }
      }
    },
    responses: { $ref: "KtasOutlineDefault", type: "array" }
  },
  ktasOutlineTags: {
    method: "get",
    url: "/ktas/outlines/tags",
    operationId: "ktasOutlineTags",
    summary: "课程教案标签",
    tags: "Ktas#Outline",
    parameters: {
      path: {},
      query: {
        tagName: {
          name: "tagName",
          in: "query",
          type: "string",
          required: true,
          description: "标签名称"
        }
      }
    },
    responses: null
  },
  ktasOutlineIndex: {
    method: "get",
    url: "/ktas/workouts/{workoutId}/outlines",
    operationId: "ktasOutlineIndex",
    summary: "课程教案列表",
    tags: "Ktas#Outline",
    parameters: {
      path: {
        workoutId: {
          name: "workoutId",
          in: "path",
          type: "integer",
          required: true,
          description: "产品ID"
        }
      },
      query: {
        state: {
          name: "state",
          in: "query",
          type: "string",
          required: false,
          enum: ["new", "certificated", "abandoned"],
          "x-i18n": {
            new: "待认证",
            certificated: "已认证",
            abandoned: "已废弃"
          },
          description: "状态"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "KtasOutlinePagedArrayDefault", type: "object" }
  },
  ktasOutlineCreate: {
    method: "post",
    url: "/ktas/workouts/{workoutId}/outlines",
    operationId: "ktasOutlineCreate",
    summary: "创建课程教案",
    tags: "Ktas#Outline",
    parameters: {
      path: {
        workoutId: {
          name: "workoutId",
          in: "path",
          type: "integer",
          required: true,
          description: "产品ID"
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "KtasOutlineForm" } }
      }
    },
    responses: null
  },
  ktasOutlineShow: {
    method: "get",
    url: "/ktas/outlines/{id}",
    operationId: "ktasOutlineShow",
    summary: "课程教案详情",
    tags: "Ktas#Outline",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程教案ID",
          required: true
        }
      }
    },
    responses: { $ref: "KtasOutlineDetail", type: "object" }
  },
  ktasOutlineUpdate: {
    method: "put",
    url: "/ktas/outlines/{id}",
    operationId: "ktasOutlineUpdate",
    summary: "修改课程教案",
    tags: "Ktas#Outline",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程教案ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "KtasOutlineForm" } }
      }
    },
    responses: null
  },
  ktasOutlineDestroy: {
    method: "delete",
    url: "/ktas/outlines/{id}",
    operationId: "ktasOutlineDestroy",
    summary: "删除课程教案",
    tags: "Ktas#Outline",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程教案ID",
          required: true
        }
      }
    },
    responses: null
  },
  ktasOutlineCertificate: {
    method: "put",
    url: "/ktas/outlines/{id}/certificate",
    operationId: "ktasOutlineCertificate",
    summary: "认证课程模板",
    tags: "Ktas#Outline",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "Ktas::OutlineID",
          required: true
        }
      }
    },
    responses: null
  },
  ktasOutlineAbandon: {
    method: "put",
    url: "/ktas/outlines/{id}/abandon",
    operationId: "ktasOutlineAbandon",
    summary: "废弃课程模板",
    tags: "Ktas#Outline",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "Ktas::OutlineID",
          required: true
        }
      }
    },
    responses: null
  },
  ktasSectionTags: {
    method: "get",
    url: "/ktas/sections/tags",
    operationId: "ktasSectionTags",
    summary: "小节标签",
    tags: "Ktas#Section",
    parameters: {
      path: {},
      query: {
        tagName: {
          name: "tagName",
          in: "query",
          type: "string",
          required: true,
          description: "标签名称"
        }
      }
    },
    responses: null
  },
  ktasSectionIndex: {
    method: "get",
    url: "/ktas/outlines/{outlineId}/sections",
    operationId: "ktasSectionIndex",
    summary: "小节列表",
    tags: "Ktas#Section",
    parameters: {
      path: {
        outlineId: {
          name: "outlineId",
          in: "path",
          type: "integer",
          description: "教案ID",
          required: true
        }
      },
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "KtasSectionPagedArrayDefault", type: "object" }
  },
  ktasSectionCreate: {
    method: "post",
    url: "/ktas/outlines/{outlineId}/sections",
    operationId: "ktasSectionCreate",
    summary: "创建小节",
    tags: "Ktas#Section",
    parameters: {
      path: {
        outlineId: {
          name: "outlineId",
          in: "path",
          type: "integer",
          description: "教案ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "KtasSectionForm" } }
      }
    },
    responses: null
  },
  ktasSectionShow: {
    method: "get",
    url: "/ktas/sections/{id}",
    operationId: "ktasSectionShow",
    summary: "小节详情",
    tags: "Ktas#Section",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "小节ID",
          required: true
        }
      }
    },
    responses: { $ref: "KtasSectionDetail", type: "object" }
  },
  ktasSectionUpdate: {
    method: "put",
    url: "/ktas/sections/{id}",
    operationId: "ktasSectionUpdate",
    summary: "修改小节",
    tags: "Ktas#Section",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "小节ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "KtasSectionForm" } }
      }
    },
    responses: null
  },
  ktasSectionDestroy: {
    method: "delete",
    url: "/ktas/sections/{id}",
    operationId: "ktasSectionDestroy",
    summary: "删除小节",
    tags: "Ktas#Section",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "小节ID",
          required: true
        }
      }
    },
    responses: null
  },
  ktasTrainingMethodList: {
    method: "get",
    url: "/ktas/training_methods/list",
    operationId: "ktasTrainingMethodList",
    summary: "训练方式列表",
    tags: "Ktas#TrainingMethod",
    parameters: {
      path: {},
      query: {
        id: {
          name: "id",
          in: "query",
          type: "integer",
          description: "训练方式ID"
        }
      }
    },
    responses: { $ref: "KtasTrainingMethodList", type: "array" }
  },
  ktasTrainingMethodTags: {
    method: "get",
    url: "/ktas/training_methods/tags",
    operationId: "ktasTrainingMethodTags",
    summary: "训练方式标签",
    tags: "Ktas#TrainingMethod",
    parameters: {
      path: {},
      query: {
        tagName: {
          name: "tagName",
          in: "query",
          type: "string",
          required: true,
          description: "标签名称"
        }
      }
    },
    responses: null
  },
  ktasTrainingMethodIndex: {
    method: "get",
    url: "/ktas/training_methods",
    operationId: "ktasTrainingMethodIndex",
    summary: "训练方式列表",
    tags: "Ktas#TrainingMethod",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "根据名称搜索"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          required: false,
          description: "状态"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "KtasTrainingMethodPagedArrayDefault", type: "object" }
  },
  ktasTrainingMethodCreate: {
    method: "post",
    url: "/ktas/training_methods",
    operationId: "ktasTrainingMethodCreate",
    summary: "创建训练方式",
    tags: "Ktas#TrainingMethod",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "KtasTrainingMethodForm" }
        }
      }
    },
    responses: null
  },
  ktasTrainingMethodShow: {
    method: "get",
    url: "/ktas/training_methods/{id}",
    operationId: "ktasTrainingMethodShow",
    summary: "训练方式详情",
    tags: "Ktas#TrainingMethod",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练方式ID",
          required: true
        }
      }
    },
    responses: { $ref: "KtasTrainingMethodDetail", type: "object" }
  },
  ktasTrainingMethodUpdate: {
    method: "put",
    url: "/ktas/training_methods/{id}",
    operationId: "ktasTrainingMethodUpdate",
    summary: "修改训练方式",
    tags: "Ktas#TrainingMethod",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练方式ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "KtasTrainingMethodForm" }
        }
      }
    },
    responses: null
  },
  ktasTrainingMethodDestroy: {
    method: "delete",
    url: "/ktas/training_methods/{id}",
    operationId: "ktasTrainingMethodDestroy",
    summary: "删除训练方式",
    tags: "Ktas#TrainingMethod",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练方式ID",
          required: true
        }
      }
    },
    responses: null
  },
  ktasWorkoutList: {
    method: "get",
    url: "/ktas/workouts/list",
    operationId: "ktasWorkoutList",
    summary: "课程品类列表",
    tags: "Ktas#Workout",
    parameters: {
      path: {},
      query: {
        id: {
          name: "id",
          in: "query",
          type: "integer",
          description: "课程品类ID"
        }
      }
    },
    responses: { $ref: "KtasWorkoutList", type: "array" }
  },
  ktasWorkoutTags: {
    method: "get",
    url: "/ktas/workouts/tags",
    operationId: "ktasWorkoutTags",
    summary: "课程品类标签",
    tags: "Ktas#Workout",
    parameters: {
      path: {},
      query: {
        tagName: {
          name: "tagName",
          in: "query",
          type: "string",
          required: true,
          description: "标签名称"
        }
      }
    },
    responses: null
  },
  ktasWorkoutIndex: {
    method: "get",
    url: "/ktas/workouts",
    operationId: "ktasWorkoutIndex",
    summary: "课程品类列表",
    tags: "Ktas#Workout",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "KtasWorkoutPagedArrayDefault", type: "object" }
  },
  ktasWorkoutCreate: {
    method: "post",
    url: "/ktas/workouts",
    operationId: "ktasWorkoutCreate",
    summary: "创建课程品类",
    tags: "Ktas#Workout",
    parameters: {
      path: {},
      body: {
        json: { name: "json", in: "body", schema: { $ref: "KtasWorkoutForm" } }
      }
    },
    responses: null
  },
  ktasWorkoutShow: {
    method: "get",
    url: "/ktas/workouts/{id}",
    operationId: "ktasWorkoutShow",
    summary: "课程品类详情",
    tags: "Ktas#Workout",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程品类ID",
          required: true
        }
      }
    },
    responses: { $ref: "KtasWorkoutDetail", type: "object" }
  },
  ktasWorkoutUpdate: {
    method: "put",
    url: "/ktas/workouts/{id}",
    operationId: "ktasWorkoutUpdate",
    summary: "修改课程品类",
    tags: "Ktas#Workout",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程品类ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "KtasWorkoutForm" } }
      }
    },
    responses: null
  },
  ktasWorkoutDestroy: {
    method: "delete",
    url: "/ktas/workouts/{id}",
    operationId: "ktasWorkoutDestroy",
    summary: "删除课程品类",
    tags: "Ktas#Workout",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程品类ID",
          required: true
        }
      }
    },
    responses: null
  },
  memberAccountAccountMemberIndex: {
    method: "get",
    url: "/members/{memberId}/accounts/{accountId}/account_members",
    operationId: "memberAccountAccountMemberIndex",
    summary: "共享卡会员列表",
    tags: "Member#Account#AccountMember",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        accountId: {
          name: "accountId",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: {
      $ref: "MembershipAccountMemberPagedArrayDefault",
      type: "object"
    }
  },
  memberAccountAccountMemberCreate: {
    method: "post",
    url: "/members/{memberId}/accounts/{accountId}/account_members",
    operationId: "memberAccountAccountMemberCreate",
    summary: "新建共享卡会员",
    tags: "Member#Account#AccountMember",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        accountId: {
          name: "accountId",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountMemberCreateForm" }
        }
      }
    },
    responses: null
  },
  memberAccountAccountMemberAddChild: {
    method: "post",
    url: "/members/{memberId}/accounts/{accountId}/account_members/add_child",
    operationId: "memberAccountAccountMemberAddChild",
    summary: "新建家庭卡成员",
    tags: "Member#Account#AccountMember",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        accountId: {
          name: "accountId",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountMemberAddChildForm" }
        }
      }
    },
    responses: null
  },
  memberAccountAccountMemberUpdate: {
    method: "put",
    url: "/members/{memberId}/accounts/{accountId}/account_members/{id}",
    operationId: "memberAccountAccountMemberUpdate",
    summary: "修改共享卡会员备注",
    tags: "Member#Account#AccountMember",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        accountId: {
          name: "accountId",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "共享卡会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountMemberUpdateForm" }
        }
      }
    },
    responses: null
  },
  memberAccountAccountMemberDestroy: {
    method: "delete",
    url: "/members/{memberId}/accounts/{accountId}/account_members/{id}",
    operationId: "memberAccountAccountMemberDestroy",
    summary: "删除共享卡会员",
    tags: "Member#Account#AccountMember",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        accountId: {
          name: "accountId",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "共享卡会员ID",
          required: true
        }
      }
    },
    responses: null
  },
  memberAccountSummary: {
    method: "get",
    url: "/members/{memberId}/accounts/summary",
    operationId: "memberAccountSummary",
    summary: "账户概览",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        accountType: {
          name: "accountType",
          in: "query",
          type: "string",
          enum: ["prepay", "entry", "duration", "entry_and_duration"],
          description: "账户类型"
        }
      }
    },
    responses: { $ref: "MembershipAccountSummary", type: "object" }
  },
  memberAccountIndex: {
    method: "get",
    url: "/members/{memberId}/accounts",
    operationId: "memberAccountIndex",
    summary: "账户列表",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        accountType: {
          name: "accountType",
          in: "query",
          type: "string",
          enum: ["prepay", "entry", "duration", "entry_and_duration"],
          description: "账户类型"
        }
      }
    },
    responses: { $ref: "MembershipAccountDefault", type: "array" }
  },
  memberAccountGranted: {
    method: "get",
    url: "/members/{memberId}/accounts/granted",
    operationId: "memberAccountGranted",
    summary: "可用账户列表",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          required: true,
          description: "会员ID"
        }
      },
      query: {
        courseId: {
          name: "courseId",
          in: "query",
          type: "integer",
          required: false,
          description: "课程ID"
        },
        type: {
          name: "type",
          in: "query",
          type: "string",
          required: false,
          description: "账号类型"
        }
      }
    },
    responses: { $ref: "MembershipAccountDefault", type: "array" }
  },
  memberAccountShow: {
    method: "get",
    url: "/members/{memberId}/accounts/{id}",
    operationId: "memberAccountShow",
    summary: "账户详情",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      }
    },
    responses: { $ref: "MembershipAccountDefault", type: "object" }
  },
  memberAccountPause: {
    method: "put",
    url: "/members/{memberId}/accounts/{id}/pause",
    operationId: "memberAccountPause",
    summary: "暂停账户",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountPauseForm" }
        }
      }
    },
    responses: null
  },
  memberAccountPunish: {
    method: "put",
    url: "/members/{memberId}/accounts/{id}/punish",
    operationId: "memberAccountPunish",
    summary: "惩罚账户",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountPunishForm" }
        }
      }
    },
    responses: null
  },
  memberAccountActivate: {
    method: "put",
    url: "/members/{memberId}/accounts/{id}/activate",
    operationId: "memberAccountActivate",
    summary: "激活账户",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountActivateForm" }
        }
      }
    },
    responses: null
  },
  memberAccountResume: {
    method: "put",
    url: "/members/{memberId}/accounts/{id}/resume",
    operationId: "memberAccountResume",
    summary: "恢复账户",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountActivateForm" }
        }
      }
    },
    responses: null
  },
  memberAccountExtendValidityPeriod: {
    method: "post",
    url: "/members/{memberId}/accounts/{id}/extend_validity_period",
    operationId: "memberAccountExtendValidityPeriod",
    summary: "延长有效期/恢复过期权限",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountExtendValidityPeriodForm" }
        }
      }
    },
    responses: null
  },
  memberAccountRechargeByCard: {
    method: "post",
    url: "/members/{memberId}/accounts/recharge",
    operationId: "memberAccountRechargeByCard",
    summary: "后台储值",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountRechargeForm" }
        }
      }
    },
    responses: null
  },
  memberAccountRecharge: {
    method: "post",
    url: "/members/{memberId}/accounts/{id}/recharge",
    operationId: "memberAccountRecharge",
    summary: "后台储值",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountRechargeForm" }
        }
      }
    },
    responses: null
  },
  memberAccountConsume: {
    method: "post",
    url: "/members/{memberId}/accounts/{id}/consume",
    operationId: "memberAccountConsume",
    summary: "后台扣次",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountConsumeForm" }
        }
      }
    },
    responses: null
  },
  memberAccountConsumeProductWithPrepay: {
    method: "post",
    url: "/members/{memberId}/accounts/consume_product_with_prepay",
    operationId: "memberAccountConsumeProductWithPrepay",
    summary: "后台购买",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountConsumeProductWithPrepayForm" }
        }
      }
    },
    responses: null
  },
  memberAccountTransfer: {
    method: "post",
    url: "/members/{memberId}/accounts/{id}/transfer",
    operationId: "memberAccountTransfer",
    summary: "转赠权限",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountTransferForm" }
        }
      }
    },
    responses: null
  },
  memberAccountChangeCoach: {
    method: "put",
    url: "/members/{memberId}/accounts/{id}/change_coach",
    operationId: "memberAccountChangeCoach",
    summary: "更换私教教练",
    tags: "Member#Account",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipAccountChangeCoachForm" }
        }
      }
    },
    responses: null
  },
  memberBookingSummary: {
    method: "get",
    url: "/members/{memberId}/bookings/summary",
    operationId: "memberBookingSummary",
    summary: "会员训练概览",
    tags: "Member#Booking",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          enum: ["group_training", "elite_training", "camp", "free_training"],
          required: false,
          description: "课程类型"
        }
      }
    },
    responses: { $ref: "CourseBookingMemberSummary", type: "object" }
  },
  memberBookingReport: {
    method: "get",
    url: "/members/{memberId}/bookings/report",
    operationId: "memberBookingReport",
    summary: "会员训练报告",
    tags: "Member#Booking",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          enum: ["group_training", "elite_training", "camp", "free_training"],
          required: false,
          description: "课程类型"
        },
        from: {
          name: "from",
          in: "query",
          type: "datetime",
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "datetime",
          description: "结束日期"
        },
        interval: {
          name: "interval",
          in: "query",
          type: "string",
          enum: ["week", "month"],
          required: true,
          description: "间隔"
        }
      }
    },
    responses: { $ref: "CourseBookingMemberReportItem", type: "array" }
  },
  memberBookingExport: {
    method: "post",
    url: "/members/{memberId}/bookings/export",
    operationId: "memberBookingExport",
    summary: "会员训练导出",
    tags: "Member#Booking",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      }
    },
    responses: { type: "file" }
  },
  memberBookingIndex: {
    method: "get",
    url: "/members/{memberId}/bookings",
    operationId: "memberBookingIndex",
    summary: "会员训练列表",
    tags: "Member#Booking",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          enum: ["group_training", "elite_training", "camp", "free_training"],
          required: false,
          description: "课程类型"
        },
        from: {
          name: "from",
          in: "query",
          type: "datetime",
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "datetime",
          description: "结束日期"
        }
      }
    },
    responses: {
      $ref: "CourseBookingPagedArrayWithCourseAndResult",
      type: "object"
    }
  },
  memberBookingShow: {
    method: "get",
    url: "/members/{memberId}/bookings/{id}",
    operationId: "memberBookingShow",
    summary: "会员训练列表",
    tags: "Member#Booking",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "预约ID",
          required: true
        }
      }
    },
    responses: { $ref: "CourseBookingDefault", type: "object" }
  },
  memberCommunicationIndex: {
    method: "get",
    url: "/members/{memberId}/communications",
    operationId: "memberCommunicationIndex",
    summary: "沟通记录列表",
    tags: "Member#Communication",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "MemberCommunicationPagedArrayDefault", type: "object" }
  },
  memberCommunicationCreate: {
    method: "post",
    url: "/members/{memberId}/communications",
    operationId: "memberCommunicationCreate",
    summary: "新建沟通记录",
    tags: "Member#Communication",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MemberCommunicationForm" }
        }
      }
    },
    responses: null
  },
  memberCommunicationDestroy: {
    method: "delete",
    url: "/members/{memberId}/communications/{id}",
    operationId: "memberCommunicationDestroy",
    summary: "删除沟通记录",
    tags: "Member#Communication",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "沟通记录ID",
          required: true
        }
      }
    },
    responses: null
  },
  memberExamRecordList: {
    method: "get",
    url: "/members/{memberId}/exam_records/list",
    operationId: "memberExamRecordList",
    summary: "体测记录列表",
    tags: "Member#ExamRecord",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        id: {
          name: "id",
          in: "query",
          type: "integer",
          description: "体测记录ID"
        }
      }
    },
    responses: { $ref: "ZpkExamRecordList", type: "array" }
  },
  memberExamRecordIndex: {
    method: "get",
    url: "/members/{memberId}/exam_records",
    operationId: "memberExamRecordIndex",
    summary: "体测记录列表",
    tags: "Member#ExamRecord",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "ZpkExamRecordPagedArrayDefault", type: "object" }
  },
  memberExamRecordCreate: {
    method: "post",
    url: "/members/{memberId}/exam_records",
    operationId: "memberExamRecordCreate",
    summary: "创建体测记录",
    tags: "Member#ExamRecord",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "ZpkExamRecordForm" }
        }
      }
    },
    responses: null
  },
  memberExamRecordShow: {
    method: "get",
    url: "/members/{memberId}/exam_records/{id}",
    operationId: "memberExamRecordShow",
    summary: "体测记录详情",
    tags: "Member#ExamRecord",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "体测记录ID",
          required: true
        }
      }
    },
    responses: { $ref: "ZpkExamRecordDefault", type: "object" }
  },
  memberExamRecordUpdate: {
    method: "put",
    url: "/members/{memberId}/exam_records/{id}",
    operationId: "memberExamRecordUpdate",
    summary: "修改体测记录",
    tags: "Member#ExamRecord",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "体测记录ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "ZpkExamRecordForm" }
        }
      }
    },
    responses: null
  },
  memberExamRecordDestroy: {
    method: "delete",
    url: "/members/{memberId}/exam_records/{id}",
    operationId: "memberExamRecordDestroy",
    summary: "删除体测记录",
    tags: "Member#ExamRecord",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "体测记录ID",
          required: true
        }
      }
    },
    responses: null
  },
  memberInvitationOverview: {
    method: "get",
    url: "/invitations/overview",
    operationId: "memberInvitationOverview",
    summary: "邀请概览",
    tags: "Member#Invitation",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          description: "开始时间"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          description: "结束时间"
        }
      }
    },
    responses: { $ref: "MemberInvitationPagedArrayDefault", type: "object" }
  },
  memberInvitationIndex: {
    method: "get",
    url: "/invitations",
    operationId: "memberInvitationIndex",
    summary: "邀请列表",
    tags: "Member#Invitation",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        from: {
          name: "from",
          in: "query",
          type: "string",
          description: "开始时间"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          description: "结束时间"
        },
        confirmed: {
          name: "confirmed",
          in: "query",
          type: "boolean",
          required: false,
          description: "是否邀请成功"
        }
      }
    },
    responses: { $ref: "MemberInvitationPagedArrayDefault", type: "object" }
  },
  memberInvitationShow: {
    method: "get",
    url: "/invitations/{id}",
    operationId: "memberInvitationShow",
    summary: "邀请详情",
    tags: "Member#Invitation",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "邀请ID",
          required: true
        }
      }
    },
    responses: { $ref: "MemberInvitationDetail", type: "object" }
  },
  memberKidIndex: {
    method: "get",
    url: "/members/{memberId}/kids",
    operationId: "memberKidIndex",
    summary: "儿童列表",
    tags: "Member#Kid",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "MemberMemberPagedArrayDefault", type: "object" }
  },
  memberKidAdd: {
    method: "post",
    url: "/members/{memberId}/kids",
    operationId: "memberKidAdd",
    summary: "添加儿童",
    tags: "Member#Kid",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MemberMemberKidForm" }
        }
      }
    },
    responses: null
  },
  memberMemberKids: {
    method: "get",
    url: "/members/kids",
    operationId: "memberMemberKids",
    summary: "会员列表",
    tags: "Member#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        }
      }
    },
    responses: { $ref: "MemberMemberPagedArrayDefault", type: "object" }
  },
  memberKidCreate: {
    method: "post",
    url: "/members/kids",
    operationId: "memberKidCreate",
    summary: "创建儿童",
    tags: "Member#Kid",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MemberMemberKidForm" }
        }
      }
    },
    responses: null
  },
  memberKidDestroy: {
    method: "delete",
    url: "/members/{memberId}/kids/{id}",
    operationId: "memberKidDestroy",
    summary: "删除儿童",
    tags: "Member#Kid",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "儿童ID",
          required: true
        }
      }
    },
    responses: null
  },
  memberMemberCouponIndex: {
    method: "get",
    url: "/members/{memberId}/member_coupons",
    operationId: "memberMemberCouponIndex",
    summary: "用户代金券列表",
    tags: "Member#MemberCoupon",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        workflowState: {
          name: "workflowState",
          in: "query",
          type: "string",
          description: "状态"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "CouponMemberCouponPagedArrayDefault", type: "object" }
  },
  memberMemberCouponCreate: {
    method: "post",
    url: "/members/{memberId}/member_coupons",
    operationId: "memberMemberCouponCreate",
    summary: "新建用户代金券",
    tags: "Member#MemberCoupon",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponMemberCouponForm" }
        }
      }
    },
    responses: null
  },
  memberMemberCouponDestroy: {
    method: "delete",
    url: "/members/{memberId}/member_coupons/{id}",
    operationId: "memberMemberCouponDestroy",
    summary: "删除用户代金券",
    tags: "Member#MemberCoupon",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "用户代金券ID",
          required: true
        }
      }
    },
    responses: null
  },
  memberMemberCouponRestore: {
    method: "post",
    url: "/members/{memberId}/member_coupons/{id}/restore",
    operationId: "memberMemberCouponRestore",
    summary: "",
    tags: "Member#MemberCoupon",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "用户代金券ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponMemberCouponRestoreForm" }
        }
      }
    },
    responses: null
  },
  memberMemberList: {
    method: "get",
    url: "/members/list",
    operationId: "memberMemberList",
    summary: "会员列表",
    tags: "Member#Member",
    parameters: {
      path: {},
      query: {
        id: {
          name: "id",
          in: "query",
          type: "integer",
          required: false,
          description: "会员ID"
        },
        userId: {
          name: "userId",
          in: "query",
          type: "integer",
          required: false,
          description: "账户ID"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        }
      }
    },
    responses: { $ref: "MemberMemberEssential", type: "array" }
  },
  memberMemberIndex: {
    method: "get",
    url: "/members",
    operationId: "memberMemberIndex",
    summary: "会员列表",
    tags: "Member#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        tag: {
          name: "tag",
          in: "query",
          type: "string",
          required: false,
          description: "会员标签"
        }
      }
    },
    responses: { $ref: "MemberMemberPagedArrayDefault", type: "object" }
  },
  memberMemberCreate: {
    method: "post",
    url: "/members",
    operationId: "memberMemberCreate",
    summary: "新建会员",
    tags: "Member#Member",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MemberMemberForm" }
        }
      }
    },
    responses: { $ref: "MemberMemberDefault", type: "object" }
  },
  memberMemberShow: {
    method: "get",
    url: "/members/{id}",
    operationId: "memberMemberShow",
    summary: "会员详情",
    tags: "Member#Member",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      }
    },
    responses: { $ref: "MemberMemberDefault", type: "object" }
  },
  memberMemberUpdate: {
    method: "put",
    url: "/members/{id}",
    operationId: "memberMemberUpdate",
    summary: "修改会员",
    tags: "Member#Member",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MemberMemberForm" }
        }
      }
    },
    responses: null
  },
  memberMemberUnlinkWechat: {
    method: "put",
    url: "/members/{id}/unlink_wechat",
    operationId: "memberMemberUnlinkWechat",
    summary: "解绑微信",
    tags: "Member#Member",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      }
    },
    responses: null
  },
  memberMemberUpdateHof: {
    method: "put",
    url: "/members/{id}/hof",
    operationId: "memberMemberUpdateHof",
    summary: "名人堂设置",
    tags: "Member#Member",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MemberMemberHofForm" }
        }
      }
    },
    responses: null
  },
  memberMemberActivate: {
    method: "put",
    url: "/members/{id}/activate",
    operationId: "memberMemberActivate",
    summary: "激活会员",
    tags: "Member#Member",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      }
    },
    responses: null
  },
  memberMemberDeactivate: {
    method: "put",
    url: "/members/{id}/deactivate",
    operationId: "memberMemberDeactivate",
    summary: "暂停会员",
    tags: "Member#Member",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      }
    },
    responses: null
  },
  memberMemberInvitations: {
    method: "get",
    url: "/members/{id}/invitations",
    operationId: "memberMemberInvitations",
    summary: "邀请",
    tags: "Member#Member",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      }
    },
    responses: {
      $ref: "MobileMemberInvitationPagedArrayDefault",
      type: "object"
    }
  },
  memberMemberUpdateTrainingProfile: {
    method: "put",
    url: "/members/{id}/training_profile",
    operationId: "memberMemberUpdateTrainingProfile",
    summary: "修改会员训练履历",
    tags: "Member#Member",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MemberTrainingProfileDefault" }
        }
      }
    },
    responses: null
  },
  memberMemberTags: {
    method: "get",
    url: "/members/tags",
    operationId: "memberMemberTags",
    summary: "会员标签",
    tags: "Member#Member",
    parameters: {
      path: {},
      query: {
        memberType: {
          name: "memberType",
          in: "query",
          type: "string",
          required: false,
          description: "会员类型"
        },
        displayTagCount: {
          name: "displayTagCount",
          in: "query",
          type: "boolean",
          required: false,
          description: "是否显示标签数量"
        }
      }
    },
    responses: { $ref: "MemberMemberMemberTag", type: "array" }
  },
  memberOrderSummary: {
    method: "get",
    url: "/members/{memberId}/orders/summary",
    operationId: "memberOrderSummary",
    summary: "会员订单概览",
    tags: "Member#Order",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      }
    },
    responses: { $ref: "OrderMemberSummary", type: "object" }
  },
  memberOrderReport: {
    method: "get",
    url: "/members/{memberId}/orders/report",
    operationId: "memberOrderReport",
    summary: "会员订单报告",
    tags: "Member#Order",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        from: {
          name: "from",
          in: "query",
          type: "datetime",
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "datetime",
          description: "结束日期"
        },
        interval: {
          name: "interval",
          in: "query",
          type: "string",
          enum: ["week", "month"],
          required: true,
          description: "间隔"
        }
      }
    },
    responses: { $ref: "OrderMemberReportItem", type: "array" }
  },
  memberOrderIndex: {
    method: "get",
    url: "/members/{memberId}/orders",
    operationId: "memberOrderIndex",
    summary: "会员订单列表",
    tags: "Member#Order",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        }
      },
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        from: {
          name: "from",
          in: "query",
          type: "datetime",
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "datetime",
          description: "结束日期"
        }
      }
    },
    responses: { $ref: "OrderPagedArrayDefault", type: "object" }
  },
  memberOrderShow: {
    method: "get",
    url: "/members/{memberId}/orders/{id}",
    operationId: "memberOrderShow",
    summary: "会员订单列表",
    tags: "Member#Order",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "订单ID",
          required: true
        }
      }
    },
    responses: { $ref: "OrderDefault", type: "object" }
  },
  memberAccountRecordIndex: {
    method: "get",
    url: "/members/{memberId}/accounts/{accountId}/records",
    operationId: "memberAccountRecordIndex",
    summary: "会员卡使用记录列表",
    tags: "Member#Account#Record",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        accountId: {
          name: "accountId",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        }
      },
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "MembershipRecordPagedArrayDefault", type: "object" }
  },
  memberAccountRecordDestroy: {
    method: "delete",
    url: "/members/{memberId}/accounts/{accountId}/records/{id}",
    operationId: "memberAccountRecordDestroy",
    summary: "删除会员卡使用记录",
    tags: "Member#Account#Record",
    parameters: {
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "会员ID",
          required: true
        },
        accountId: {
          name: "accountId",
          in: "path",
          type: "integer",
          description: "账户ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员卡使用记录ID",
          required: true
        }
      }
    },
    responses: null
  },
  membershipCardOptionCouponIndex: {
    method: "get",
    url: "/membership/prepay_card_options/{cardOptionId}/card_option_coupons",
    operationId: "membershipCardOptionCouponIndex",
    summary: "储值卡代金券列表",
    tags: "Membership#CardOptionCoupon",
    parameters: {
      path: {
        cardOptionId: {
          name: "cardOptionId",
          in: "path",
          type: "integer",
          description: "储值卡卡项ID",
          required: true
        }
      }
    },
    responses: { $ref: "CouponCardOptionCouponDefault", type: "array" }
  },
  membershipCardOptionCouponCreate: {
    method: "post",
    url: "/membership/prepay_card_options/{cardOptionId}/card_option_coupons",
    operationId: "membershipCardOptionCouponCreate",
    summary: "新建储值卡代金券",
    tags: "Membership#CardOptionCoupon",
    parameters: {
      path: {
        cardOptionId: {
          name: "cardOptionId",
          in: "path",
          type: "integer",
          description: "储值卡卡项ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponCardOptionCouponForm" }
        }
      }
    },
    responses: null
  },
  membershipCardOptionCouponShow: {
    method: "get",
    url: "/membership/prepay_card_options/{cardOptionId}/card_option_coupons/{id}",
    operationId: "membershipCardOptionCouponShow",
    summary: "储值卡代金券详情",
    tags: "Membership#CardOptionCoupon",
    parameters: {
      path: {
        cardOptionId: {
          name: "cardOptionId",
          in: "path",
          type: "integer",
          description: "储值卡卡项ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "储值卡代金券ID",
          required: true
        }
      }
    },
    responses: { $ref: "CouponCardOptionCouponDefault", type: "object" }
  },
  membershipCardOptionCouponUpdate: {
    method: "put",
    url: "/membership/prepay_card_options/{cardOptionId}/card_option_coupons/{id}",
    operationId: "membershipCardOptionCouponUpdate",
    summary: "修改储值卡代金券",
    tags: "Membership#CardOptionCoupon",
    parameters: {
      path: {
        cardOptionId: {
          name: "cardOptionId",
          in: "path",
          type: "integer",
          description: "储值卡卡项ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "储值卡代金券ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "CouponCardOptionCouponForm" }
        }
      }
    },
    responses: null
  },
  membershipCardOptionCouponDestroy: {
    method: "delete",
    url: "/membership/prepay_card_options/{cardOptionId}/card_option_coupons/{id}",
    operationId: "membershipCardOptionCouponDestroy",
    summary: "删除储值卡代金券",
    tags: "Membership#CardOptionCoupon",
    parameters: {
      path: {
        cardOptionId: {
          name: "cardOptionId",
          in: "path",
          type: "integer",
          description: "储值卡卡项ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "储值卡代金券ID",
          required: true
        }
      }
    },
    responses: null
  },
  membershipCardOptionList: {
    method: "get",
    url: "/membership/cards/card_options/list",
    operationId: "membershipCardOptionList",
    summary: "充值选项列表",
    tags: "Membership#CardOption",
    parameters: {
      path: {},
      query: {
        card_type: {
          name: "card_type",
          in: "query",
          type: "string",
          description: "会员卡类型"
        }
      }
    },
    responses: { $ref: "MembershipCardOptionDefault", type: "array" }
  },
  membershipCardOptionIndex: {
    method: "get",
    url: "/membership/cards/{cardId}/card_options",
    operationId: "membershipCardOptionIndex",
    summary: "充值选项列表",
    tags: "Membership#CardOption",
    parameters: {
      path: {
        cardId: {
          name: "cardId",
          in: "path",
          type: "integer",
          description: "会员卡ID",
          required: true
        }
      },
      query: {
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: {
      $ref: "MembershipCardOptionPagedArrayDefault",
      type: "object"
    }
  },
  membershipCardOptionCreate: {
    method: "post",
    url: "/membership/cards/{cardId}/card_options",
    operationId: "membershipCardOptionCreate",
    summary: "新建充值选项",
    tags: "Membership#CardOption",
    parameters: {
      path: {
        cardId: {
          name: "cardId",
          in: "path",
          type: "integer",
          description: "会员卡ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipCardOptionForm" }
        }
      }
    },
    responses: null
  },
  membershipCardOptionShow: {
    method: "get",
    url: "/membership/cards/{cardId}/card_options/{id}",
    operationId: "membershipCardOptionShow",
    summary: "充值选项详情",
    tags: "Membership#CardOption",
    parameters: {
      path: {
        cardId: {
          name: "cardId",
          in: "path",
          type: "integer",
          description: "会员卡ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "充值选项ID",
          required: true
        }
      }
    },
    responses: { $ref: "MembershipCardOptionDefault", type: "object" }
  },
  membershipCardOptionUpdate: {
    method: "put",
    url: "/membership/cards/{cardId}/card_options/{id}",
    operationId: "membershipCardOptionUpdate",
    summary: "修改充值选项",
    tags: "Membership#CardOption",
    parameters: {
      path: {
        cardId: {
          name: "cardId",
          in: "path",
          type: "integer",
          description: "会员卡ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "充值选项ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipCardOptionForm" }
        }
      }
    },
    responses: null
  },
  membershipCardOptionDestroy: {
    method: "delete",
    url: "/membership/cards/{cardId}/card_options/{id}",
    operationId: "membershipCardOptionDestroy",
    summary: "删除充值选项",
    tags: "Membership#CardOption",
    parameters: {
      path: {
        cardId: {
          name: "cardId",
          in: "path",
          type: "integer",
          description: "会员卡ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "充值选项ID",
          required: true
        }
      }
    },
    responses: null
  },
  membershipCardOptionMoveUp: {
    method: "patch",
    url: "/membership/cards/{cardId}/card_options/{id}/move_up",
    operationId: "membershipCardOptionMoveUp",
    summary: "上移充值选项",
    tags: "Membership#CardOption",
    parameters: {
      path: {
        cardId: {
          name: "cardId",
          in: "path",
          type: "integer",
          description: "会员卡ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "充值选项ID",
          required: true
        }
      }
    },
    responses: null
  },
  membershipCardProductIndex: {
    method: "get",
    url: "/membership/cards/{cardId}/card_products",
    operationId: "membershipCardProductIndex",
    summary: "产品扣次列表",
    tags: "Membership#CardProduct",
    parameters: {
      path: {
        cardId: {
          name: "cardId",
          in: "path",
          type: "integer",
          description: "会员卡ID",
          required: true
        }
      },
      query: {
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: {
      $ref: "MembershipCardProductPagedArrayDefault",
      type: "object"
    }
  },
  membershipCardProductShow: {
    method: "get",
    url: "/membership/cards/{cardId}/card_products/{id}",
    operationId: "membershipCardProductShow",
    summary: "产品扣次详情",
    tags: "Membership#CardProduct",
    parameters: {
      path: {
        cardId: {
          name: "cardId",
          in: "path",
          type: "integer",
          description: "会员卡ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "产品扣次ID",
          required: true
        }
      }
    },
    responses: { $ref: "MembershipCardProductDefault", type: "object" }
  },
  membershipCardProductUpdate: {
    method: "put",
    url: "/membership/cards/{cardId}/card_products/{id}",
    operationId: "membershipCardProductUpdate",
    summary: "修改产品扣次",
    tags: "Membership#CardProduct",
    parameters: {
      path: {
        cardId: {
          name: "cardId",
          in: "path",
          type: "integer",
          description: "会员卡ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "充值选项ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipCardProductForm" }
        }
      }
    },
    responses: null
  },
  membershipCardList: {
    method: "get",
    url: "/membership/cards/list",
    operationId: "membershipCardList",
    summary: "会员卡列表",
    tags: "Membership#Card",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "根据名称搜索"
        },
        include_prepay: {
          name: "include_prepay",
          in: "query",
          type: "boolean",
          required: false,
          description: "是否包含储值卡"
        },
        productType: {
          name: "productType",
          in: "query",
          required: true,
          type: "string",
          enum: ["elite_training", "group_training", "free_training"],
          "x-i18n": {
            elite_training: "私教",
            group_training: "团课",
            free_training: "自由训练"
          },
          description: "训练产品类型"
        },
        cardType: {
          name: "cardType",
          in: "query",
          required: true,
          type: "string",
          enum: ["duration", "entry"],
          "x-i18n": { duration: "时段卡", entry: "次卡" },
          description: "会员卡类型"
        }
      }
    },
    responses: { $ref: "MembershipCardEssential", type: "array" }
  },
  membershipCardIndex: {
    method: "get",
    url: "/membership/cards",
    operationId: "membershipCardIndex",
    summary: "会员卡列表",
    tags: "Membership#Card",
    parameters: {
      path: {},
      query: {
        cardType: {
          name: "cardType",
          in: "query",
          required: true,
          type: "string",
          enum: ["duration", "entry"],
          "x-i18n": { duration: "时段卡", entry: "次卡" },
          description: "会员卡类型"
        },
        productType: {
          name: "productType",
          in: "query",
          required: true,
          type: "string",
          enum: ["elite_training", "group_training", "free_training"],
          "x-i18n": {
            elite_training: "私教",
            group_training: "团课",
            free_training: "自由训练"
          },
          description: "训练产品类型"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: { $ref: "MembershipCardPagedArrayEssential", type: "object" }
  },
  membershipCardCreate: {
    method: "post",
    url: "/membership/cards",
    operationId: "membershipCardCreate",
    summary: "新建会员卡",
    tags: "Membership#Card",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipCardForm" }
        }
      }
    },
    responses: null
  },
  membershipCardShow: {
    method: "get",
    url: "/membership/cards/{id}",
    operationId: "membershipCardShow",
    summary: "会员卡详情",
    tags: "Membership#Card",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员卡 ID",
          required: true
        }
      }
    },
    responses: { $ref: "MembershipCardDefault", type: "object" }
  },
  membershipCardUpdate: {
    method: "put",
    url: "/membership/cards/{id}",
    operationId: "membershipCardUpdate",
    summary: "修改会员卡",
    tags: "Membership#Card",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员卡 ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipCardForm" }
        }
      }
    },
    responses: null
  },
  membershipCardDestroy: {
    method: "delete",
    url: "/membership/cards/{id}",
    operationId: "membershipCardDestroy",
    summary: "删除会员卡",
    tags: "Membership#Card",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员卡 ID",
          required: true
        }
      }
    },
    responses: null
  },
  membershipCardMoveUp: {
    method: "patch",
    url: "/membership/cards/{id}/move_up",
    operationId: "membershipCardMoveUp",
    summary: "上移会员卡",
    tags: "Membership#Card",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "会员卡 ID",
          required: true
        }
      }
    },
    responses: null
  },
  membershipPrepayCardOptionIndex: {
    method: "get",
    url: "/membership/prepay_card/card_options",
    operationId: "membershipPrepayCardOptionIndex",
    summary: "储值选项列表",
    tags: "Membership#PrepayCardOption",
    parameters: {
      path: {},
      query: {
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: {
      $ref: "MembershipCardOptionPagedArrayDefault",
      type: "object"
    }
  },
  membershipPrepayCardOptionCreate: {
    method: "post",
    url: "/membership/prepay_card/card_options",
    operationId: "membershipPrepayCardOptionCreate",
    summary: "新建储值选项",
    tags: "Membership#PrepayCardOption",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipCardOptionPrepayForm" }
        }
      }
    },
    responses: null
  },
  membershipPrepayCardOptionShow: {
    method: "get",
    url: "/membership/prepay_card/card_options/{id}",
    operationId: "membershipPrepayCardOptionShow",
    summary: "储值选项详情",
    tags: "Membership#PrepayCardOption",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "充值选项ID",
          required: true
        }
      }
    },
    responses: { $ref: "MembershipCardOptionDefault", type: "object" }
  },
  membershipPrepayCardOptionUpdate: {
    method: "put",
    url: "/membership/prepay_card/card_options/{id}",
    operationId: "membershipPrepayCardOptionUpdate",
    summary: "修改储值选项",
    tags: "Membership#PrepayCardOption",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "充值选项ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipCardOptionPrepayForm" }
        }
      }
    },
    responses: null
  },
  membershipPrepayCardOptionDestroy: {
    method: "delete",
    url: "/membership/prepay_card/card_options/{id}",
    operationId: "membershipPrepayCardOptionDestroy",
    summary: "删除储值选项",
    tags: "Membership#PrepayCardOption",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "充值选项ID",
          required: true
        }
      }
    },
    responses: null
  },
  membershipPrepayCardOptionMoveUp: {
    method: "put",
    url: "/membership/prepay_card/card_options/{id}/move_up",
    operationId: "membershipPrepayCardOptionMoveUp",
    summary: "上移课程产品",
    tags: "Membership#PrepayCardOption",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "充值选项ID",
          required: true
        }
      }
    },
    responses: null
  },
  membershipPrepayCardShow: {
    method: "get",
    url: "/membership/prepay_card",
    operationId: "membershipPrepayCardShow",
    summary: "储值卡详情",
    tags: "Membership#PrepayCard",
    parameters: { path: {} },
    responses: { $ref: "MembershipPrepayCardDefault", type: "object" }
  },
  membershipPrepayCardUpdate: {
    method: "put",
    url: "/membership/prepay_card",
    operationId: "membershipPrepayCardUpdate",
    summary: "修改储值卡",
    tags: "Membership#PrepayCard",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipPrepayCardForm" }
        }
      }
    },
    responses: null
  },
  membershipPrepayCardCreate: {
    method: "post",
    url: "/membership/prepay_card",
    operationId: "membershipPrepayCardCreate",
    summary: "新建储值卡",
    tags: "Membership#PrepayCard",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "MembershipPrepayCardForm" }
        }
      }
    },
    responses: null
  },
  operatingCouponIndex: {
    method: "get",
    url: "/operating/coupons",
    operationId: "operatingCouponIndex",
    summary: "代金券列表",
    tags: "Operating#Coupon",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "代金券名称"
        },
        source_type: {
          name: "source_type",
          in: "query",
          type: "string",
          required: false,
          enum: ["order", "free_order", "recharge", "event", "manual"],
          "x-i18n": {
            order: "购买券包",
            free_order: "免费领取",
            recharge: "充值赠送",
            event: "营销事件",
            manual: "后台赠送"
          },
          description: "获取方式"
        },
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: {
      $ref: "BrandOperatingCouponPagedArrayDefault",
      type: "object"
    }
  },
  operatingCouponMemberCoupons: {
    method: "get",
    url: "/operating/coupons/{couponId}/member_coupons",
    operationId: "operatingCouponMemberCoupons",
    summary: "代金券发放列表",
    tags: "Operating#Coupon",
    parameters: {
      path: {
        couponId: {
          name: "couponId",
          in: "path",
          type: "integer",
          description: "代金券ID",
          required: true
        }
      },
      query: {
        source_type: {
          name: "source_type",
          in: "query",
          type: "string",
          required: false,
          enum: ["order", "free_order", "recharge", "event", "manual"],
          "x-i18n": {
            order: "购买券包",
            free_order: "免费领取",
            recharge: "充值赠送",
            event: "营销事件",
            manual: "后台赠送"
          },
          description: "获取方式"
        },
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: {
      $ref: "BrandOperatingMemberCouponPagedArrayDefault",
      type: "object"
    }
  },
  operatingGiftBookingOverview: {
    method: "get",
    url: "/operating/gift_bookings/overview",
    operationId: "operatingGiftBookingOverview",
    summary: "赠送课程概览",
    tags: "Operating#GiftBooking",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        }
      }
    },
    responses: null
  },
  operatingGiftBookingIndex: {
    method: "get",
    url: "/operating/gift_bookings",
    operationId: "operatingGiftBookingIndex",
    summary: "赠送课程列表",
    tags: "Operating#GiftBooking",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "CourseBookingPagedArrayGiftBooking", type: "object" }
  },
  operatingMemberNewMembers: {
    method: "get",
    url: "/operating/members/new_members",
    operationId: "operatingMemberNewMembers",
    summary: "新会员列表",
    tags: "Operating#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        tag: {
          name: "tag",
          in: "query",
          type: "string",
          required: false,
          description: "用户标签"
        },
        duration: {
          name: "duration",
          in: "query",
          type: "array",
          item: { type: "string" },
          required: false,
          description: "时间区间"
        }
      }
    },
    responses: { $ref: "OperatingMemberPagedArrayDefault", type: "object" }
  },
  operatingMemberNewMemberBookings: {
    method: "get",
    url: "/operating/members/new_member_bookings",
    operationId: "operatingMemberNewMemberBookings",
    summary: "新会员到店列表",
    tags: "Operating#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        tag: {
          name: "tag",
          in: "query",
          type: "string",
          required: false,
          description: "用户标签"
        }
      }
    },
    responses: { $ref: "OperatingBookingPagedArrayDefault", type: "object" }
  },
  operatingMemberActiveMembers: {
    method: "get",
    url: "/operating/members/active_members",
    operationId: "operatingMemberActiveMembers",
    summary: "活跃列表",
    tags: "Operating#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        tag: {
          name: "tag",
          in: "query",
          type: "string",
          required: false,
          description: "用户标签"
        },
        studio_id: {
          name: "studio_id",
          in: "query",
          type: "integer",
          required: false,
          description: "门店ID"
        },
        duration: {
          name: "duration",
          in: "query",
          type: "array",
          item: { type: "string" },
          required: false,
          description: "时间区间"
        }
      }
    },
    responses: { $ref: "OperatingMemberPagedArrayDefault", type: "object" }
  },
  operatingMemberInactiveMembers: {
    method: "get",
    url: "/operating/members/inactive_members",
    operationId: "operatingMemberInactiveMembers",
    summary: "不活跃列表",
    tags: "Operating#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        tag: {
          name: "tag",
          in: "query",
          type: "string",
          required: false,
          description: "用户标签"
        }
      }
    },
    responses: { $ref: "OperatingMemberPagedArrayDefault", type: "object" }
  },
  operatingMemberNotActivatedMembers: {
    method: "get",
    url: "/operating/members/not_activated_members",
    operationId: "operatingMemberNotActivatedMembers",
    summary: "待激活列表",
    tags: "Operating#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        tag: {
          name: "tag",
          in: "query",
          type: "string",
          required: false,
          description: "用户标签"
        }
      }
    },
    responses: { $ref: "OperatingMemberPagedArrayDefault", type: "object" }
  },
  operatingMemberProspects: {
    method: "get",
    url: "/operating/members/prospects",
    operationId: "operatingMemberProspects",
    summary: "线索列表",
    tags: "Operating#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        tag: {
          name: "tag",
          in: "query",
          type: "string",
          required: false,
          description: "用户标签"
        }
      }
    },
    responses: { $ref: "OperatingMemberPagedArrayDefault", type: "object" }
  },
  operatingMemberExpiryAlert: {
    method: "get",
    url: "/operating/members/expiry_alert",
    operationId: "operatingMemberExpiryAlert",
    summary: "过期提醒列表",
    tags: "Operating#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        },
        expiry_type: {
          name: "expiry_type",
          in: "query",
          type: "string",
          required: false,
          description: "过期类型"
        },
        tag: {
          name: "tag",
          in: "query",
          type: "string",
          required: false,
          description: "用户标签"
        }
      }
    },
    responses: { $ref: "OperatingMemberPagedArrayDefault", type: "object" }
  },
  operatingMemberHallOfFame: {
    method: "get",
    url: "/operating/members/hall_of_fame",
    operationId: "operatingMemberHallOfFame",
    summary: "名人堂列表",
    tags: "Operating#Member",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "查询关键字"
        }
      }
    },
    responses: { $ref: "OperatingMemberPagedArrayDefault", type: "object" }
  },
  operatingMemberSendCoupons: {
    method: "post",
    url: "/operating/members/send_coupons",
    operationId: "operatingMemberSendCoupons",
    summary: "批量发放代金券",
    tags: "Operating#Member",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "OperatingMemberSendCouponsForm" }
        }
      }
    },
    responses: null
  },
  operatingMemberExtendCouponValidityPeriod: {
    method: "post",
    url: "/operating/members/extend_coupon_validity_period",
    operationId: "operatingMemberExtendCouponValidityPeriod",
    summary: "批量代金券延期",
    tags: "Operating#Member",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "OperatingMemberExtendCouponValidityPeriodForm" }
        }
      }
    },
    responses: null
  },
  milestoneIndex: {
    method: "get",
    url: "/operating/milestones",
    operationId: "milestoneIndex",
    summary: "Milestone列表",
    tags: "Milestone",
    parameters: {
      path: {},
      query: {
        category: {
          name: "category",
          in: "query",
          type: "string",
          required: false,
          enum: ["course", "coach", "member"],
          "x-i18n": {
            course: "课程事件",
            coach: "教练事件",
            member: "用户事件"
          },
          description: "类型"
        },
        from: {
          name: "from",
          in: "query",
          type: "date",
          required: false,
          description: "开始时间"
        },
        to: {
          name: "to",
          in: "query",
          type: "date",
          required: false,
          description: "结束时间"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "MilestonePagedArrayDefault", type: "object" }
  },
  milestoneShow: {
    method: "get",
    url: "/operating/milestones/{id}",
    operationId: "milestoneShow",
    summary: "Milestone详情",
    tags: "Milestone",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "纪念时刻ID",
          required: true
        }
      }
    },
    responses: { $ref: "MilestoneDefault", type: "object" }
  },
  milestoneBirthdayMembers: {
    method: "get",
    url: "/operating/milestones/birthday_members",
    operationId: "milestoneBirthdayMembers",
    summary: "会员生日列表",
    tags: "Milestone",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        from: {
          name: "from",
          in: "query",
          type: "date",
          required: false,
          description: "开始时间"
        },
        to: {
          name: "to",
          in: "query",
          type: "date",
          required: false,
          description: "结束时间"
        }
      }
    },
    responses: { $ref: "MemberMemberPagedArrayDefault", type: "object" }
  },
  orderIndex: {
    method: "get",
    url: "/orders",
    operationId: "orderIndex",
    summary: "订单列表",
    tags: "Order",
    parameters: {
      path: {},
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "根据单号、用户昵称、手机号搜索"
        },
        orderType: {
          name: "orderType",
          in: "query",
          required: false,
          type: "string",
          enum: [
            "coupon",
            "direct_purchase",
            "membership_card",
            "prepay_card",
            "camp"
          ],
          "x-i18n": {
            coupon: "代金券",
            direct_purchase: "微信购课",
            membership_card: "会员卡",
            prepay_card: "储值卡",
            camp: "训练营"
          },
          description: "订单类型"
        },
        paymentMethod: {
          name: "paymentMethod",
          in: "query",
          required: false,
          type: "string",
          enum: [
            "微信钱包",
            "大众点评/美团",
            "抖音",
            "微信转账",
            "支付宝转账",
            "现金",
            "POS机"
          ],
          "x-i18n": {
            微信钱包: "微信钱包",
            "大众点评/美团": "大众点评/美团",
            抖音: "抖音",
            微信转账: "微信转账",
            支付宝转账: "支付宝转账",
            现金: "现金",
            POS机: "POS机"
          },
          description: "支付方式"
        },
        timeRange: {
          name: "timeRange",
          in: "query",
          type: "array",
          items: { type: "string", format: "date", example: "2018-01-01" }
        }
      }
    },
    responses: { $ref: "OrderPagedArrayDefault", type: "object" }
  },
  orderCreate: {
    method: "post",
    url: "/orders",
    operationId: "orderCreate",
    summary: "添加订单",
    tags: "Order",
    parameters: {
      path: {},
      body: {
        json: { name: "json", in: "body", schema: { $ref: "OrderForm" } }
      }
    },
    responses: null
  },
  orderShow: {
    method: "get",
    url: "/orders/{id}",
    operationId: "orderShow",
    summary: "订单详情",
    tags: "Order",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "订单ID",
          required: true
        }
      }
    },
    responses: { $ref: "OrderDefault", type: "object" }
  },
  orderUpdate: {
    method: "put",
    url: "/orders/{id}",
    operationId: "orderUpdate",
    summary: "修改订单",
    tags: "Order",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "订单ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "OrderUpdateForm" } }
      }
    },
    responses: null
  },
  orderRefund: {
    method: "delete",
    url: "/orders/{id}",
    operationId: "orderRefund",
    summary: "撤销订单",
    tags: "Order",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "订单ID",
          required: true
        }
      }
    },
    responses: null
  },
  permissionFeatureIndex: {
    method: "get",
    url: "/permission/features",
    operationId: "permissionFeatureIndex",
    summary: "功能列表",
    tags: "Permission#Feature",
    parameters: { path: {} },
    responses: { $ref: "PermissionFeatureIncludeChildren", type: "array" }
  },
  qrCodeShow: {
    method: "get",
    url: "/qr_codes",
    operationId: "qrCodeShow",
    summary: "查看小程序码",
    tags: "QrCode",
    parameters: {
      path: {},
      query: {
        scene_params: {
          name: "scene_params",
          in: "query",
          type: "string",
          description: "场景参数"
        }
      }
    },
    responses: null
  },
  qrCodeCreate: {
    method: "post",
    url: "/qr_codes",
    operationId: "qrCodeCreate",
    summary: "创建小程序码",
    tags: "QrCode",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          properties: {
            type: "object",
            properties: { scene_params: { type: "object" } }
          }
        }
      }
    },
    responses: null
  },
  qrCodeDestroy: {
    method: "delete",
    url: "/qr_codes/{scene}",
    operationId: "qrCodeDestroy",
    summary: "删除小程序码",
    tags: "QrCode",
    parameters: {
      path: {
        scene: {
          name: "scene",
          in: "path",
          type: "string",
          description: "场景ID",
          required: true
        }
      }
    },
    responses: null
  },
  qrCodeLimtedCode: {
    method: "post",
    url: "/limited_qr_code",
    operationId: "qrCodeLimtedCode",
    summary: "永久小程序码",
    tags: "QrCode",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          properties: {
            type: "object",
            properties: {
              page: { type: "string" },
              params: { type: "object" },
              width: { type: "integer" }
            }
          }
        }
      }
    },
    responses: null
  },
  qrCodeUnlimtedCode: {
    method: "post",
    url: "/unlimited_qr_code",
    operationId: "qrCodeUnlimtedCode",
    summary: "临时小程序码",
    tags: "QrCode",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          properties: {
            type: "object",
            properties: {
              page: { type: "string" },
              params: { type: "object" },
              width: { type: "integer" }
            }
          }
        }
      }
    },
    responses: null
  },
  reportFinanceOrdersSummary: {
    method: "get",
    url: "/report/finance/orders_summary",
    operationId: "reportFinanceOrdersSummary",
    summary: "销售报告概览",
    tags: "Report#Finance",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        }
      }
    },
    responses: null
  },
  reportFinanceOrdersReport: {
    method: "get",
    url: "/report/finance/orders_report",
    operationId: "reportFinanceOrdersReport",
    summary: "销售报告",
    tags: "Report#Finance",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        }
      }
    },
    responses: null
  },
  reportFinanceExportOrders: {
    method: "get",
    url: "/report/finance/export_orders",
    operationId: "reportFinanceExportOrders",
    summary: "导出销售报告",
    tags: "Report#Finance",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        }
      }
    },
    responses: null
  },
  reportFinanceCoachCoursesReport: {
    method: "get",
    url: "/report/finance/coach_courses_report",
    operationId: "reportFinanceCoachCoursesReport",
    summary: "教练课时报告",
    tags: "Report#Finance",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        },
        studioId: {
          name: "studioId",
          in: "query",
          type: "integer",
          required: false,
          description: "门店"
        }
      }
    },
    responses: null
  },
  reportFinanceExportCoachCourses: {
    method: "get",
    url: "/report/finance/export_coach_courses",
    operationId: "reportFinanceExportCoachCourses",
    summary: "导出教练课时报告",
    tags: "Report#Finance",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        }
      }
    },
    responses: null
  },
  reportFinanceStudioRevenueSummary: {
    method: "get",
    url: "/report/finance/studio_revenue_summary",
    operationId: "reportFinanceStudioRevenueSummary",
    summary: "门店结算报告",
    tags: "Report#Finance",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          enum: ["group_training", "elite_training"],
          required: false,
          description: "课程产品"
        }
      }
    },
    responses: null
  },
  reportFinanceStudioRevenueReport: {
    method: "get",
    url: "/report/finance/studio_revenue_report",
    operationId: "reportFinanceStudioRevenueReport",
    summary: "门店结算报告",
    tags: "Report#Finance",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          enum: ["group_training", "elite_training"],
          required: false,
          description: "课程产品"
        }
      }
    },
    responses: null
  },
  reportFinanceExportStudioRevenue: {
    method: "get",
    url: "/report/finance/export_studio_revenue",
    operationId: "reportFinanceExportStudioRevenue",
    summary: "导出门店结算报告",
    tags: "Report#Finance",
    parameters: {
      path: {},
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          required: true,
          description: "起始日期"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          required: true,
          description: "结束日期"
        }
      }
    },
    responses: null
  },
  reportFinanceDebtsSummary: {
    method: "get",
    url: "/report/finance/debts_summary",
    operationId: "reportFinanceDebtsSummary",
    summary: "负债概览",
    tags: "Report#Finance",
    parameters: { path: {} },
    responses: null
  },
  reportFinanceDebtsReport: {
    method: "get",
    url: "/report/finance/debts_report",
    operationId: "reportFinanceDebtsReport",
    summary: "负债报告",
    tags: "Report#Finance",
    parameters: {
      path: {},
      query: {
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          enum: ["group_training", "elite_training"],
          required: false,
          description: "课程产品"
        }
      }
    },
    responses: null
  },
  reportFinanceCouponDebtsReport: {
    method: "get",
    url: "/report/finance/coupon_debts_report",
    operationId: "reportFinanceCouponDebtsReport",
    summary: "代金券负债报告",
    tags: "Report#Finance",
    parameters: { path: {} },
    responses: null
  },
  reportFinanceExportDebts: {
    method: "get",
    url: "/report/finance/export_debts",
    operationId: "reportFinanceExportDebts",
    summary: "导出负债报告",
    tags: "Report#Finance",
    parameters: { path: {} },
    responses: null
  },
  studioBlockIndex: {
    method: "get",
    url: "/studios/{studioId}/zones/{zoneId}/blocks",
    operationId: "studioBlockIndex",
    summary: "训练区列表",
    tags: "Studio#Block",
    parameters: {
      path: {
        zoneId: {
          name: "zoneId",
          in: "path",
          type: "integer",
          description: "场地ID",
          required: true
        },
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      }
    },
    responses: { $ref: "StudioBlockPagedArrayBrief", type: "object" }
  },
  studioBlockCreate: {
    method: "post",
    url: "/studios/{studioId}/zones/{zoneId}/blocks",
    operationId: "studioBlockCreate",
    summary: "新建训练区",
    tags: "Studio#Block",
    parameters: {
      path: {
        zoneId: {
          name: "zoneId",
          in: "path",
          type: "integer",
          description: "场地ID",
          required: true
        },
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "StudioBlockForm" } }
      }
    },
    responses: null
  },
  studioBlockShow: {
    method: "get",
    url: "/studios/{studioId}/zones/{zoneId}/blocks/{id}",
    operationId: "studioBlockShow",
    summary: "训练区详情",
    tags: "Studio#Block",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练区ID",
          required: true
        },
        zoneId: {
          name: "zoneId",
          in: "path",
          type: "integer",
          description: "场地ID",
          required: true
        },
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      }
    },
    responses: { $ref: "StudioBlockDefault", type: "object" }
  },
  studioBlockUpdate: {
    method: "put",
    url: "/studios/{studioId}/zones/{zoneId}/blocks/{id}",
    operationId: "studioBlockUpdate",
    summary: "修改训练区",
    tags: "Studio#Block",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练区ID",
          required: true
        },
        zoneId: {
          name: "zoneId",
          in: "path",
          type: "integer",
          description: "场地ID",
          required: true
        },
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "StudioBlockForm" } }
      }
    },
    responses: null
  },
  studioBlockDestroy: {
    method: "delete",
    url: "/studios/{studioId}/zones/{zoneId}/blocks/{id}",
    operationId: "studioBlockDestroy",
    summary: "删除训练区",
    tags: "Studio#Block",
    parameters: {
      path: {
        zoneId: {
          name: "zoneId",
          in: "path",
          type: "integer",
          description: "场地ID",
          required: true
        },
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练区ID",
          required: true
        }
      }
    },
    responses: null
  },
  studioHrSensorIndex: {
    method: "get",
    url: "/studios/{studioId}/hr_sensors",
    operationId: "studioHrSensorIndex",
    summary: "心率带列表",
    tags: "Studio#HrSensor",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      },
      query: {
        zoneId: {
          name: "zoneId",
          in: "query",
          type: "integer",
          required: false,
          description: "场地ID"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "是否激活"
        }
      }
    },
    responses: { $ref: "StudioHrSensorPagedArrayDefault", type: "object" }
  },
  studioHrSensorCreate: {
    method: "post",
    url: "/studios/{studioId}/hr_sensors",
    operationId: "studioHrSensorCreate",
    summary: "新建心率带",
    tags: "Studio#HrSensor",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "StudioHrSensorForm" }
        }
      }
    },
    responses: null
  },
  studioHrSensorShow: {
    method: "get",
    url: "/studios/{studioId}/hr_sensors/{id}",
    operationId: "studioHrSensorShow",
    summary: "心率带详情",
    tags: "Studio#HrSensor",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "\b心率带ID",
          required: true
        }
      }
    },
    responses: { $ref: "StudioHrSensorDefault", type: "object" }
  },
  studioHrSensorUpdate: {
    method: "put",
    url: "/studios/{studioId}/hr_sensors/{id}",
    operationId: "studioHrSensorUpdate",
    summary: "修改心率带",
    tags: "Studio#HrSensor",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "\b心率带ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "StudioHrSensorForm" }
        }
      }
    },
    responses: null
  },
  studioHrSensorDestroy: {
    method: "delete",
    url: "/studios/{studioId}/hr_sensors/{id}",
    operationId: "studioHrSensorDestroy",
    summary: "删除心率带",
    tags: "Studio#HrSensor",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "\b心率带ID",
          required: true
        }
      }
    },
    responses: null
  },
  studioImageIndex: {
    method: "get",
    url: "/studios/{studioId}/images",
    operationId: "studioImageIndex",
    summary: "图片列表",
    tags: "Studio#Image",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      }
    },
    responses: { $ref: "AttachmentPagedArrayDefault", type: "object" }
  },
  studioImageCreate: {
    method: "post",
    url: "/studios/{studioId}/images",
    operationId: "studioImageCreate",
    summary: "新建图片",
    tags: "Studio#Image",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "AttachmentForm" } }
      }
    },
    responses: null
  },
  studioImageDestroy: {
    method: "delete",
    url: "/studios/{studioId}/images/{id}",
    operationId: "studioImageDestroy",
    summary: "删除图片",
    tags: "Studio#Image",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "图片ID",
          required: true
        }
      }
    },
    responses: null
  },
  studioImagePrimary: {
    method: "put",
    url: "/studios/{studioId}/images/{id}/primary",
    operationId: "studioImagePrimary",
    summary: "设置头图",
    tags: "Studio#Image",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "图片ID",
          required: true
        }
      }
    },
    responses: null
  },
  studioStudioIndex: {
    method: "get",
    url: "/studios",
    operationId: "studioStudioIndex",
    summary: "门店列表",
    tags: "Studio#Studio",
    parameters: {
      path: {},
      query: {
        granted_only: {
          name: "granted_only",
          in: "query",
          required: false,
          type: "boolean",
          description: "只展示可管理门店"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "是否激活"
        }
      }
    },
    responses: { $ref: "StudioStudioPagedArrayDefault", type: "object" }
  },
  studioStudioCreate: {
    method: "post",
    url: "/studios",
    operationId: "studioStudioCreate",
    summary: "新建门店",
    tags: "Studio#Studio",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "StudioStudioForm" }
        }
      }
    },
    responses: null
  },
  studioStudioList: {
    method: "get",
    url: "/studios/list",
    operationId: "studioStudioList",
    summary: "门店列表",
    tags: "Studio#Studio",
    parameters: {
      path: {},
      query: {
        granted_only: {
          name: "granted_only",
          in: "query",
          required: false,
          type: "boolean",
          description: "只展示可管理门店"
        }
      }
    },
    responses: { $ref: "StudioStudioList", type: "array" }
  },
  studioStudioShow: {
    method: "get",
    url: "/studios/{id}",
    operationId: "studioStudioShow",
    summary: "门店详情",
    tags: "Studio#Studio",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      }
    },
    responses: { $ref: "StudioStudioDefault", type: "object" }
  },
  studioStudioUpdate: {
    method: "put",
    url: "/studios/{id}",
    operationId: "studioStudioUpdate",
    summary: "修改门店",
    tags: "Studio#Studio",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "StudioStudioForm" }
        }
      }
    },
    responses: null
  },
  studioStudioDestroy: {
    method: "delete",
    url: "/studios/{id}",
    operationId: "studioStudioDestroy",
    summary: "删除门店",
    tags: "Studio#Studio",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      }
    },
    responses: null
  },
  studioStudioOpenGates: {
    method: "post",
    url: "/studios/{id}/open_gates",
    operationId: "studioStudioOpenGates",
    summary: "门店开门",
    tags: "Studio#Studio",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      }
    },
    responses: { $ref: "StudioStudioOpenGates", type: "object" }
  },
  studioZoneIndex: {
    method: "get",
    url: "/studios/{studioId}/zones",
    operationId: "studioZoneIndex",
    summary: "场地列表",
    tags: "Studio#Zone",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      },
      query: {
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "是否激活"
        }
      }
    },
    responses: { $ref: "StudioZonePagedArrayDefault", type: "object" }
  },
  studioZoneCreate: {
    method: "post",
    url: "/studios/{studioId}/zones",
    operationId: "studioZoneCreate",
    summary: "新建场地",
    tags: "Studio#Zone",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "StudioZoneForm" } }
      }
    },
    responses: null
  },
  studioZoneListwithoutstudioid: {
    method: "get",
    url: "/zones/list",
    operationId: "studioZoneListwithoutstudioid",
    summary: "场地列表",
    tags: "Studio#Zone",
    parameters: {
      path: {},
      query: {
        id: {
          name: "id",
          in: "query",
          type: "integer",
          required: false,
          description: "场地ID"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "StudioZonePagedArrayList", type: "object" }
  },
  studioZoneList: {
    method: "get",
    url: "/studios/{studioId}/zones/list",
    operationId: "studioZoneList",
    summary: "场地列表",
    tags: "Studio#Zone",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          required: true,
          description: "门店ID"
        }
      },
      query: {
        id: {
          name: "id",
          in: "query",
          type: "integer",
          required: false,
          description: "场地ID"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "StudioZonePagedArrayList", type: "object" }
  },
  studioZoneShow: {
    method: "get",
    url: "/studios/{studioId}/zones/{id}",
    operationId: "studioZoneShow",
    summary: "场地详情",
    tags: "Studio#Zone",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "场地ID",
          required: true
        }
      }
    },
    responses: { $ref: "StudioZoneDefault", type: "object" }
  },
  studioZoneUpdate: {
    method: "put",
    url: "/studios/{studioId}/zones/{id}",
    operationId: "studioZoneUpdate",
    summary: "修改场地",
    tags: "Studio#Zone",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "场地ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "StudioZoneForm" } }
      }
    },
    responses: null
  },
  studioZoneDestroy: {
    method: "delete",
    url: "/studios/{studioId}/zones/{id}",
    operationId: "studioZoneDestroy",
    summary: "删除场地",
    tags: "Studio#Zone",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "门店ID",
          required: true
        },
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "场地ID",
          required: true
        }
      }
    },
    responses: null
  },
  tasBigCircleShow: {
    method: "get",
    url: "/tas/big_circles/{id}",
    operationId: "tasBigCircleShow",
    summary: "主题训练详情",
    tags: "Tas#BigCircle",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "主题训练ID",
          required: true
        }
      }
    },
    responses: { $ref: "TasBigCircleDetail", type: "object" }
  },
  tasBigCircleUpdate: {
    method: "put",
    url: "/tas/big_circles/{id}",
    operationId: "tasBigCircleUpdate",
    summary: "修改主题训练",
    tags: "Tas#BigCircle",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "主题训练ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "TasBigCircleForm" }
        }
      }
    },
    responses: null
  },
  tasBigCircleScreens: {
    method: "get",
    url: "/tas/big_circles/{id}/screens",
    operationId: "tasBigCircleScreens",
    summary: "主题训练详情",
    tags: "Tas#BigCircle",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "主题训练ID",
          required: true
        }
      }
    },
    responses: { $ref: "TasScreenPracticesWithScreen", type: "array" }
  },
  tasBigCircleUpdatePractices: {
    method: "put",
    url: "/tas/big_circles/{id}/update_practices",
    operationId: "tasBigCircleUpdatePractices",
    summary: "设置主题训练动作",
    tags: "Tas#BigCircle",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "主题训练ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          properties: {
            type: "object",
            properties: { exercise_pattern: "integer", exercise_id: "integer" }
          }
        }
      }
    },
    responses: null
  },
  tasEquipmentIndex: {
    method: "get",
    url: "/tas/equipment",
    operationId: "tasEquipmentIndex",
    summary: "器械列表",
    tags: "Tas#Equipment",
    parameters: { path: {} },
    responses: { $ref: "TasEquipmentDefault", type: "array" }
  },
  tasEquipmentCreate: {
    method: "post",
    url: "/tas/equipment",
    operationId: "tasEquipmentCreate",
    summary: "创建器械",
    tags: "Tas#Equipment",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "TasEquipmentForm" }
        }
      }
    },
    responses: null
  },
  tasEquipmentShow: {
    method: "get",
    url: "/tas/equipment/{id}",
    operationId: "tasEquipmentShow",
    summary: "器械详情",
    tags: "Tas#Equipment",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "器械ID",
          required: true
        }
      }
    },
    responses: { $ref: "TasEquipmentDetail", type: "object" }
  },
  tasEquipmentUpdate: {
    method: "put",
    url: "/tas/equipment/{id}",
    operationId: "tasEquipmentUpdate",
    summary: "修改器械",
    tags: "Tas#Equipment",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "器械ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "TasEquipmentForm" }
        }
      }
    },
    responses: null
  },
  tasExerciseIndex: {
    method: "get",
    url: "/tas/exercises",
    operationId: "tasExerciseIndex",
    summary: "训练动作列表",
    tags: "Tas#Exercise",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "根据动作名称或编号搜素"
        },
        owner: {
          name: "owner",
          in: "query",
          type: "string",
          required: false,
          description: "动作创建者"
        },
        primaryEquipmentId: {
          name: "primaryEquipmentId",
          in: "query",
          type: "integer",
          required: false,
          description: "主器械ID"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          required: false,
          description: "激活"
        }
      }
    },
    responses: { $ref: "PlatformTasExercisePagedArrayDefault", type: "object" }
  },
  tasExerciseCreate: {
    method: "post",
    url: "/tas/exercises",
    operationId: "tasExerciseCreate",
    summary: "新建训练动作",
    tags: "Tas#Exercise",
    parameters: {
      path: {},
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "PlatformTasExerciseForm" }
        }
      }
    },
    responses: null
  },
  tasExerciseShow: {
    method: "get",
    url: "/tas/exercises/{id}",
    operationId: "tasExerciseShow",
    summary: "训练动作详情",
    tags: "Tas#Exercise",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "动作ID",
          required: true
        }
      }
    },
    responses: { $ref: "PlatformTasExerciseDetail", type: "object" }
  },
  tasExerciseUpdate: {
    method: "put",
    url: "/tas/exercises/{id}",
    operationId: "tasExerciseUpdate",
    summary: "修改训练动作",
    tags: "Tas#Exercise",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "动作ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "PlatformTasExerciseForm" }
        }
      }
    },
    responses: null
  },
  tasExerciseDestroy: {
    method: "delete",
    url: "/tas/exercises/{id}",
    operationId: "tasExerciseDestroy",
    summary: "删除训练动作",
    tags: "Tas#Exercise",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "动作ID",
          required: true
        }
      }
    },
    responses: null
  },
  tasExerciseTags: {
    method: "get",
    url: "/tas/exercises/tags",
    operationId: "tasExerciseTags",
    summary: "训练动作标签列表",
    tags: "Tas#Exercise",
    parameters: { path: {} },
    responses: null
  },
  tasPackageList: {
    method: "get",
    url: "/tas/packages/list",
    operationId: "tasPackageList",
    summary: "Tas::Package列表",
    tags: "Tas#Package",
    parameters: {
      path: {},
      query: {
        productId: {
          name: "productId",
          in: "query",
          type: "integer",
          required: false,
          description: "训练产品ID"
        }
      }
    },
    responses: { $ref: "TasPackageEssential", type: "array" }
  },
  tasPackageIndex: {
    method: "get",
    url: "/tas/workouts/{workoutId}/packages",
    operationId: "tasPackageIndex",
    summary: "课程包列表",
    tags: "Tas#Package",
    parameters: {
      path: {
        workoutId: {
          name: "workoutId",
          in: "path",
          type: "integer",
          description: "课程品类ID",
          required: true
        }
      },
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "TasPackagePagedArrayEssential", type: "object" }
  },
  tasPackageShow: {
    method: "get",
    url: "/tas/packages/{id}",
    operationId: "tasPackageShow",
    summary: "课程包详情",
    tags: "Tas#Package",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "课程包ID",
          required: true
        }
      }
    },
    responses: { $ref: "TasPackageDefault", type: "object" }
  },
  tasScreenUpdatePractices: {
    method: "put",
    url: "/tas/screens/{id}/update_practices",
    operationId: "tasScreenUpdatePractices",
    summary: "修改训练屏幕动作",
    tags: "Tas#Screen",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练屏幕ID",
          required: true
        }
      },
      body: {
        json: { name: "json", in: "body", schema: { $ref: "TasPracticeForm" } }
      }
    },
    responses: null
  },
  tasWorkoutOutlineIndex: {
    method: "get",
    url: "/tas/workout_outlines",
    operationId: "tasWorkoutOutlineIndex",
    summary: "训练流列表",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "训练流编号"
        },
        productId: {
          name: "productId",
          in: "query",
          type: "integer",
          required: true,
          description: "产品ID"
        },
        workflowState: {
          name: "workflowState",
          in: "query",
          type: "string",
          required: false,
          enum: ["new", "certificated", "test", "abandoned"],
          "x-i18n": {
            new: "未认证",
            certificated: "已认证",
            test: "测试",
            abandoned: "未认证"
          },
          description: "状态"
        },
        owner: {
          name: "owner",
          in: "query",
          type: "string",
          required: false,
          enum: ["brand", "platform"],
          "x-i18n": { brand: "自建套路", platform: "授权套路" },
          description: "所有者"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "TasWorkoutOutlinePagedArrayEssential", type: "object" }
  },
  tasWorkoutOutlineList: {
    method: "get",
    url: "/tas/workout_outlines/list",
    operationId: "tasWorkoutOutlineList",
    summary: "训练流列表",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          required: false,
          description: "根据编号或备注搜索"
        },
        productId: {
          name: "productId",
          in: "query",
          type: "integer",
          required: true,
          description: "产品ID"
        },
        packageId: {
          name: "packageId",
          in: "query",
          type: "integer",
          required: false,
          description: "团课包ID"
        },
        state: {
          name: "state",
          in: "query",
          type: "string",
          required: false,
          enum: ["new", "certificated", "test", "abandoned"],
          "x-i18n": {
            new: "未认证",
            certificated: "已认证",
            test: "测试",
            abandoned: "未认证"
          },
          description: "状态"
        },
        id: {
          name: "id",
          in: "query",
          type: "integer",
          required: false,
          description: "训练流ID"
        },
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "TasWorkoutOutlinePagedArrayList", type: "object" }
  },
  tasWorkoutOutlineCopy: {
    method: "post",
    url: "/tas/workout_outlines/{workoutOutlineId}/copy",
    operationId: "tasWorkoutOutlineCopy",
    summary: "复制训练流",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {
        workoutOutlineId: {
          name: "workoutOutlineId",
          in: "path",
          type: "integer",
          required: true,
          description: "训练流ID"
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "TasWorkoutOutlineCreateForm" }
        }
      }
    },
    responses: { $ref: "TasWorkoutOutlineBrief", type: "object" }
  },
  tasWorkoutOutlineShow: {
    method: "get",
    url: "/tas/workout_outlines/{workoutOutlineId}",
    operationId: "tasWorkoutOutlineShow",
    summary: "训练流详情",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {
        workoutOutlineId: {
          name: "workoutOutlineId",
          in: "path",
          type: "integer",
          required: true,
          description: "训练流ID"
        }
      }
    },
    responses: { $ref: "TasWorkoutOutlineDetail", type: "object" }
  },
  tasWorkoutOutlineUpdate: {
    method: "patch",
    url: "/tas/workout_outlines/{workoutOutlineId}",
    operationId: "tasWorkoutOutlineUpdate",
    summary: "修改训练流",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {
        workoutOutlineId: {
          name: "workoutOutlineId",
          in: "path",
          type: "integer",
          required: true,
          description: "训练流ID"
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "TasWorkoutOutlineEditForm" }
        }
      }
    },
    responses: { $ref: "TasWorkoutOutlineDefault", type: "object" }
  },
  tasWorkoutOutlineExercises: {
    method: "get",
    url: "/tas/workout_outlines/{workoutOutlineId}/exercises",
    operationId: "tasWorkoutOutlineExercises",
    summary: "训练流动作",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {
        workoutOutlineId: {
          name: "workoutOutlineId",
          in: "path",
          type: "integer",
          required: true,
          description: "训练流ID"
        }
      }
    },
    responses: { $ref: "TasWorkoutOutlineWithExercises", type: "object" }
  },
  tasWorkoutOutlineTimeline: {
    method: "get",
    url: "/tas/workout_outlines/{workoutOutlineId}/timeline",
    operationId: "tasWorkoutOutlineTimeline",
    summary: "训练流时间线",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {
        workoutOutlineId: {
          name: "workoutOutlineId",
          in: "path",
          type: "integer",
          required: true,
          description: "训练流ID"
        }
      }
    },
    responses: { $ref: "TasTimelineTas", type: "object" }
  },
  tasWorkoutOutlinePlaylistItems: {
    method: "get",
    url: "/tas/workout_outlines/{workoutOutlineId}/playlist_items",
    operationId: "tasWorkoutOutlinePlaylistItems",
    summary: "训练流歌单",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {
        workoutOutlineId: {
          name: "workoutOutlineId",
          in: "path",
          type: "integer",
          required: true,
          description: "训练流ID"
        }
      }
    },
    responses: { $ref: "TasWorkoutOutlineWithPlaylistItems", type: "object" }
  },
  tasWorkoutOutlineRefreshPlaylist: {
    method: "put",
    url: "/tas/workout_outlines/{id}/refresh_playlist",
    operationId: "tasWorkoutOutlineRefreshPlaylist",
    summary: "刷新训练流歌单",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "训练流ID",
          required: true
        }
      }
    },
    responses: null
  },
  tasWorkoutOutlineUpdateFrame: {
    method: "patch",
    url: "/tas/workout_outlines/{workoutOutlineId}/update_frame",
    operationId: "tasWorkoutOutlineUpdateFrame",
    summary: "修改训练流时间",
    tags: "Tas#WorkoutOutline",
    parameters: {
      path: {
        workoutOutlineId: {
          name: "workoutOutlineId",
          in: "path",
          type: "integer",
          required: true,
          description: "训练流ID"
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "TasWorkoutOutlineUpdateFrameForm" }
        }
      }
    },
    responses: null
  },
  tasWorkoutList: {
    method: "get",
    url: "/tas/workouts/list",
    operationId: "tasWorkoutList",
    summary: "Tas::Workout列表",
    tags: "Tas#Workout",
    parameters: {
      path: {},
      query: {
        id: {
          name: "id",
          in: "query",
          type: "integer",
          required: false,
          description: "训练产品ID"
        }
      }
    },
    responses: { $ref: "TasWorkoutEssential", type: "array" }
  },
  tasWorkoutShow: {
    method: "get",
    url: "/tas/workouts/{workoutId}",
    operationId: "tasWorkoutShow",
    summary: "Tas::Workout详情",
    tags: "Tas#Workout",
    parameters: {
      path: {
        workoutId: {
          name: "workoutId",
          in: "path",
          type: "integer",
          required: true,
          description: "训练产品ID"
        }
      }
    },
    responses: { $ref: "TasWorkoutEssential", type: "array" }
  },
  userIndex: {
    method: "get",
    url: "/users",
    operationId: "userIndex",
    summary: "账号列表",
    tags: "User",
    parameters: {
      path: {},
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "手机号模糊匹配"
        }
      }
    },
    responses: { $ref: "UserDefault", type: "array" }
  }
};
const idMaps = {};
